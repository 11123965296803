// @ts-ignore
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
// @ts-ignore
import { createContainer, createFieldBlockForDetails } from '@UIkit/components/panels';
import { createNamedDocumentBlockForDetail } from '../documentBlock/createDocumentBlockForDetail';
import { SvLitsPer } from '../definitions/svLitsPer';
import { HANDING_GOODS_TYPE } from '../definitions/commonsDef';

interface PersonHandingGoodsFieldsProps {
	prefix?: string;
}

export interface PersonHandingGoodsBlockProps {
	data: AnyObject;
	[key: string]: any;
}
export class PersonHandingGoodsBlock {
	props: PersonHandingGoodsBlockProps;

	personHandingGoodsPersonalDataForm: ExtComponent;

	cacheValues: AnyObject;

	path: string = 'dokument.svProdPer.svPer.svLitsPer';

	constructor(props: PersonHandingGoodsBlockProps, overrides?: Partial<PersonHandingGoodsBlock>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: PersonHandingGoodsBlockProps, overrides?: Partial<PersonHandingGoodsBlock>) {
		const me = this;
		Ext.merge(me, overrides);
		me.props = props ?? { data: {} };

		me.cacheValues = Ext.clone(me.props.data);
	}

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		return [me.path, ...name].filter(Boolean).join('.');
	}

	createFirstNameField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('first.name'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'fio.imya'))
		};
	}

	createLastNameField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('last.name'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'fio.familiya'))
		};
	}

	createPatronymicNameField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('patronymic.name'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'fio.otchestvo'))
		};
	}

	createPositionField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('job.title'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'dolzhnost')),
			isNewLine: true
		};
	}

	createInnField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('company.inn.short'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'innflPer'))
		};
	}

	createOrgNameField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('organization'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'naimOrgPer')),
			isNewLine: true
		};
	}

	createOrgInnField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('company.innle'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'innyulPer')),
			isNewLine: true
		};
	}

	createOtherInformationField({ prefix }: PersonHandingGoodsFieldsProps = {}): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('company.type.legalForeigner.header.info'),
			text: edi.utils.getObjectProperty(me.cacheValues, me.createFieldName(prefix, 'inieSved')),
			isNewLine: true
		};
	}

	createAuthorityDocumentBlockHolder({ prefix }: PersonHandingGoodsFieldsProps = {}): ExtComponent | null {
		const me = this;
		const name = me.createFieldName(prefix, 'osnDoverFL');
		return createNamedDocumentBlockForDetail(
			{
				data: edi.utils.getObjectProperty(me.cacheValues, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd.individualPersonShipsGoogs.authority')
			},
			{
				isSingleDocument: true,
				isReadOnly: true
			},
			{
				allowBlank: false,
				readOnly: true
			}
		);
	}

	createOrgAuthorityDocumentBlockHolder({ prefix }: PersonHandingGoodsFieldsProps = {}): ExtComponent | null {
		const me = this;
		const name = me.createFieldName(prefix, 'osnDoverOrgPer');
		return createNamedDocumentBlockForDetail(
			{
				data: edi.utils.getObjectProperty(me.cacheValues, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.sellerOrganizationWorker.org.authority')
			},
			{
				isSingleDocument: true,
				isReadOnly: true
			},
			{
				allowBlank: false,
				readOnly: true
			}
		);
	}

	createOrgWorkerAuthorityDocumentBlockHolder({ prefix }: PersonHandingGoodsFieldsProps = {}): ExtComponent | null {
		const me = this;
		const name = me.createFieldName(prefix, 'osnPolnPredPer');
		return createNamedDocumentBlockForDetail(
			{
				data: edi.utils.getObjectProperty(me.cacheValues, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.sellerOrganizationWorker.org.worker.authority')
			},
			{
				isSingleDocument: true,
				isReadOnly: true
			},
			{ readOnly: true }
		);
	}

	createIndividualPersonShipsHandingGoodsView(): ExtComponent {
		const me = this;

		const prefix = 'inLitso.flPer';

		const firstName = me.createFirstNameField({ prefix });
		const lastName = me.createLastNameField({ prefix });
		const patronymicName = me.createPatronymicNameField({ prefix });
		const inn = me.createInnField({ prefix });
		const otherInformation = me.createOtherInformationField({ prefix });
		const authority = me.createAuthorityDocumentBlockHolder({ prefix });

		return createContainer({
			layout: {
				type: 'grid'
			},
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [lastName, firstName, patronymicName, inn, otherInformation]
				}),
				authority
			]
		}) as ExtComponent;
	}

	createSellerOrganizationWorkerHandingGoodsView(): ExtComponent {
		const me = this;

		const prefix = 'rabOrgProd';

		const firstName = me.createFirstNameField({ prefix });
		const lastName = me.createLastNameField({ prefix });
		const patronymicName = me.createPatronymicNameField({ prefix });
		const position = me.createPositionField({ prefix });
		const otherInformation = me.createOtherInformationField({ prefix });

		return createContainer({
			layout: {
				type: 'grid'
			},
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [lastName, firstName, patronymicName, position, otherInformation]
				})
			]
		}) as ExtComponent;
	}

	createShippedGoodsWorkerHandingGoodsView(): ExtComponent {
		const me = this;

		const prefix = 'inLitso.predOrgPer';

		const firstName = me.createFirstNameField({ prefix });
		const lastName = me.createLastNameField({ prefix });
		const patronymicName = me.createPatronymicNameField({ prefix });
		const position = me.createPositionField({ prefix });
		const otherInformation = me.createOtherInformationField({ prefix });
		const orgInn = me.createOrgInnField({ prefix });
		const orgName = me.createOrgNameField({ prefix });
		const orgAuthority = me.createOrgAuthorityDocumentBlockHolder({ prefix });
		const orgWorkerAuthority = me.createOrgWorkerAuthorityDocumentBlockHolder({ prefix });

		return createContainer({
			layout: {
				type: 'grid'
			},
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [lastName, firstName, patronymicName, position, orgName, orgInn, otherInformation]
				}),
				orgAuthority,
				orgWorkerAuthority
			]
		}) as ExtComponent;
	}

	createPersonHandingGoodsBlock(): ExtComponent {
		const me = this;
		let svLitsPer;
		const handingGoodsData: SvLitsPer | undefined = edi.utils.getObjectProperty(
			me.cacheValues,
			'dokument.svProdPer.svPer.svLitsPer'
		);
		const isSellerOrganizationWorker = !!(
			handingGoodsData &&
			'rabOrgProd' in handingGoodsData &&
			handingGoodsData.rabOrgProd?.fio
		);
		const isShippedGoodsWorker = !!(
			handingGoodsData &&
			'inLitso' in handingGoodsData &&
			handingGoodsData.inLitso &&
			'predOrgPer' in handingGoodsData.inLitso &&
			handingGoodsData.inLitso.predOrgPer?.fio
		);
		const isPersonHanding = !!(
			handingGoodsData &&
			'inLitso' in handingGoodsData &&
			handingGoodsData.inLitso &&
			'flPer' in handingGoodsData.inLitso &&
			handingGoodsData.inLitso.flPer?.fio
		);
		if (isSellerOrganizationWorker) svLitsPer = '1';
		if (isShippedGoodsWorker) svLitsPer = '2';
		if (isPersonHanding) svLitsPer = '3';

		let keySvLitsPer;

		switch (svLitsPer) {
			case HANDING_GOODS_TYPE.sellerOrganizationWorker:
				keySvLitsPer = 'sellerOrganizationWorker';
				me.personHandingGoodsPersonalDataForm = me.createSellerOrganizationWorkerHandingGoodsView();
				break;
			case HANDING_GOODS_TYPE.shippedGoodsWorker:
				keySvLitsPer = 'shippedGoodsWorker';
				me.personHandingGoodsPersonalDataForm = me.createShippedGoodsWorkerHandingGoodsView();
				break;
			case HANDING_GOODS_TYPE.individualPersonShipsGoogs:
				keySvLitsPer = 'individualPersonShipsGoogs';
				me.personHandingGoodsPersonalDataForm = me.createIndividualPersonShipsHandingGoodsView();
				break;
		}

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.personHandingGoods'),
			layout: {
				type: 'grid',
				gap: 8
			},
			items: [
				createLabelForDetails({
					text: keySvLitsPer ? edi.i18n.getMessage('documents.fns_upd.' + keySvLitsPer) : '',
					isBlock: true
				}),
				me.personHandingGoodsPersonalDataForm
			]
		}) as ExtComponent;
	}
}

export const createPersonHandingGoodsBlockForDetail = function (
	props: PersonHandingGoodsBlockProps,
	overrides?: Partial<PersonHandingGoodsBlock>
): ExtComponent {
	const personHandingGoodsBlock = new PersonHandingGoodsBlock(props, overrides);
	return personHandingGoodsBlock.createPersonHandingGoodsBlock() as ExtComponent;
};
