/**
 * Shared stores configuration for fns documents
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.stores');
new (function () {
	var onBeforeInit = edi.stores.onBeforeInit;
	edi.stores.onBeforeInit = function () {
		'function' == typeof onBeforeInit ? onBeforeInit() : null;
		Object.assign(edi.stores.data, {
			fullAuthorityAreas: [
				{
					id: 0,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.0')
				},
				{
					id: 1,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.1')
				},
				{
					id: 2,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.2')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.4')
				},
				{
					id: 5,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.5')
				},
				{
					id: 6,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.6')
				},
				{
					id: 7,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.7')
				},
				{
					id: 8,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.8')
				},
				{
					id: 21,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.21')
				},
				{
					id: 22,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.22')
				},
				{
					id: 23,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.23')
				},
				{
					id: 29,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.29')
				}
			],
			authorityAreas: [
				{
					id: 0,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.0')
				},
				{
					id: 1,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.1')
				},
				{
					id: 2,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.2')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.4')
				},
				{
					id: 5,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.5')
				},
				{
					id: 6,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.6')
				},
				{
					id: 29,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.29')
				}
			],
			authorityAreasUKD: [
				{
					id: 0,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.0')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.3')
				},
				{
					id: 6,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.6')
				},
				{
					id: 29,
					name: edi.i18n.getMessage('documents.fns_upd.authorityArea.29')
				}
			],
			signerStatuses: [
				{
					id: 1,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.1')
				},
				{
					id: 2,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.2')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.4')
				}
			],
			baseOfCorrectionsStore: [
				{
					id: 0,
					name: edi.i18n.getMessage('receiptPartDoc.transferInformation.baseShipmentOfGoods.withoutBaseDoc')
				}
			],
			signerBuyerStatuses: [
				{
					id: 1,
					name: edi.i18n.getMessage('documents.fns_upd.signer.buyer.status.1')
				},
				{
					id: 2,
					name: edi.i18n.getMessage('documents.fns_upd.signer.buyer.status.2')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.4')
				},
				{
					id: 5,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.5')
				},
				{
					id: 6,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.6')
				}
			],
			signerBuyerUPD501NStatuses: [
				{
					id: 1,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.1')
				},
				{
					id: 2,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.2')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.4')
				},
				{
					id: 5,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.5')
				},
				{
					id: 6,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.6')
				}
			],
			signerInboxStatuses: [
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.4')
				},
				{
					id: 5,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.5')
				},
				{
					id: 6,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.6')
				}
			],
			signerOutboxStatuses: [
				{
					id: 1,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.1')
				},
				{
					id: 2,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.2')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.signer.status.4')
				}
			],
			torg2AuthorityAreas: {
				OUTBOX: [
					{
						id: 1,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.1')
					},
					{
						id: 2,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.2')
					},
					{
						id: 3,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.3')
					},
					{
						id: 7,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.7')
					},
					{
						id: 8,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.8')
					}
				],
				INBOX: [
					{
						id: 21,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.21')
					},
					{
						id: 22,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.22')
					},
					{
						id: 23,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.23')
					},
					{
						id: 29,
						name: edi.i18n.getMessage('documents.torg2.authorityArea.29')
					}
				]
			},
			torg2SignerStatuses: {
				OUTBOX: [
					{
						id: '3',
						name: edi.i18n.getMessage('documents.torg2.signer.status.3')
					},
					{
						id: '4',
						name: edi.i18n.getMessage('documents.torg2.signer.status.4')
					},
					{
						id: '5',
						name: edi.i18n.getMessage('documents.torg2.signer.status.5')
					},
					{
						id: '6',
						name: edi.i18n.getMessage('documents.torg2.signer.status.6')
					}
				],
				INBOX: [
					{
						id: '1',
						name: edi.i18n.getMessage('documents.torg2.signer.status.1')
					},
					{
						id: '3',
						name: edi.i18n.getMessage('documents.torg2.signer.status.3')
					},
					{
						id: '4',
						name: edi.i18n.getMessage('documents.torg2.signer.status.4')
					},
					{
						id: '5',
						name: edi.i18n.getMessage('documents.torg2.signer.status.5')
					},
					{
						id: '6',
						name: edi.i18n.getMessage('documents.torg2.signer.status.6')
					}
				]
			},
			infoInvoices: [
				{
					id: 1,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.1')
				},
				{
					id: 2,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.2')
				},
				{
					id: 3,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.3')
				},
				{
					id: 4,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.4')
				},
				{
					id: 5,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.5')
				},
				{
					id: 6,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.6')
				},
				{
					id: 7,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.7')
				},
				{
					id: 8,
					name: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.8')
				}
			]
		});
		Ext.merge(edi.stores.data.docTypesTree, {
			FNS: {
				order: 150,
				items: [
					{
						id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
						name: edi.i18n.getMessage(`documents.doctype.${edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD}`),
						items: [
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
						]
					},
					{
						id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
						name: edi.i18n.getMessage(`documents.doctype.${edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD}`),
						items: [
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
							edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
						]
					},
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
				]
			}
		});

		edi.stores.data.docTypesTree.DSF.items = edi.stores.data.docTypesTree.DSF.items.concat([
			edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
		]);
		edi.stores.createInitStoreMethods([
			{
				methodName: 'initAuthorityAreasStore',
				storeName: 'authorityAreasStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.authorityAreas, 'AUTHORITY_AREA', onload);
				}
			},
			{
				methodName: 'initAuthorityAreasFullStore',
				storeName: 'authorityAreasFullStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.fullAuthorityAreas, 'AUTHORITY_AREA', onload);
				}
			},
			{
				methodName: 'initSignerStatusesStore',
				storeName: 'signerStatusesStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.signerStatuses, 'AUTHORITY_AREA', onload);
				}
			},
			{
				methodName: 'initSignerBuyerStatusesStore',
				storeName: 'signerBuyerStatusesStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.signerBuyerStatuses, 'AUTHORITY_AREA', onload);
				}
			},
			{
				methodName: 'initSignerBuyerStatusesUPD501NStore',
				storeName: 'signerBuyerStatusesUPD501NStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(
						edi.stores.data.signerBuyerUPD501NStatuses,
						'AUTHORITY_AREA',
						onload
					);
				}
			},
			{
				methodName: 'initSignerInboxStore',
				storeName: 'signerInboxStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.signerInboxStatuses, 'AUTHORITY_AREA', onload);
				}
			},
			{
				methodName: 'initSignerOutboxStore',
				storeName: 'signerOutboxStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.signerOutboxStatuses, 'AUTHORITY_AREA', onload);
				}
			}
		]);
	};
})();
