import { powerOfAttorneyServices } from '../services';
// @ts-ignore
import { showError } from '@UIkit/components/modal/MessageBox';
import { ChainPoaPayload, PoaChain } from '../difinitions';

export interface chainUploadHandlerProps {
	poaChainRowBlock: ExtComponent;
	setLoading: (load: boolean) => void;
	isChainValid: () => boolean;
	onPoaLoaded: (poaChain: PoaChain) => void;
}

export const chainUploadHandler = async ({
	poaChainRowBlock,
	setLoading,
	isChainValid,
	onPoaLoaded
}: chainUploadHandlerProps) => {
	const retrustPoaRows: ExtComponent[] = poaChainRowBlock.getRows();

	if (isChainValid() && retrustPoaRows.length) {
		const retrustPoaData: ChainPoaPayload = retrustPoaRows.map((retrustPoaRow) => {
			const rowContentFieldsPanel = retrustPoaRow.contentFieldsPanel;
			const { poaNumber, principalInn, representativeInn } = rowContentFieldsPanel.getValues();
			return { poaNumber, principalInn, representativeInn };
		});

		setLoading(true);

		const { data, success } = await powerOfAttorneyServices.loadPoaChain({ retrustPoaData });

		if (!success) {
			showError({ msgText: edi.utils.formatComplexServerError(data, 'power.of.attorney.chain.error') });
			setLoading(false);
			return;
		}

		const result = data.data;
		switch (result.state) {
			//PROCESSING - надо мониторить статус
			case 'PROCESSING':
				const response = await powerOfAttorneyServices.checkPoaChainState({
					correlationId: result.correlationId
				});
				const { data, success } = response;
				if (success && data) {
					onPoaLoaded(data);
				} else {
					showError({ msgText: edi.i18n.getMessage('power.of.attorney.chain.error') });
				}
				break;
			//DONE - все МЧД уже есть на платформе
			case 'DONE':
				onPoaLoaded(result);
				break;
			//ERROR - ответ пришел, но возникла ошибка чтения ответа, скорее всего программная, процесс завершен
			case 'ERROR':
				showError(edi.utils.formatComplexServerError(result, 'power.of.attorney.chain.error'));
				break;
		}

		setLoading(false);
	}
};
