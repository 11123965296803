import { createModulePanel, createPanel, createTabPanel } from '@Components/panels';
import { createLabel } from '@Components/fields';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel,
	createToolBar
} from '@Components/grid';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import { createModuleFilterForm } from '@Components/moduleFilterForm';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createContainer, createModalForm } from '@UIkit/components/panels';
import { showMessageBox } from '@UIkit/components/modal/MessageBox';
import { createSwitch } from '@UIkit/components/fields';
import { sharingGridMethods } from './document.sharing';
import { errorAndWarningsConfig, errorsAndWarningsMethods } from './warningAndErrorGrid/methods';
import { filterMethods } from './filter';
import {
	GRID_COMMON_COLUMN_CONFIG_NAME,
	GRID_ERROR_AND_WARNING_COLUMN_CONFIG_NAME,
	GRID_REJECTED_COLUMN_CONFIG_NAME,
	GRID_REMOVED_COLUMN_CONFIG_NAME
} from './columns';
import { showApprovalModal } from '@Ediweb/modules/APPROVAL_DOCUMENTS/methods';
import { createCertificateHandler } from '@App/js/signature/handlers/createCertificateHandler';
import { createPoaHandler } from '@App/js/signature/handlers/createPoaHandler';
import { TAB_PANEL_CLS } from '@UIkit/components/tab';
import { asyncDownloadTask } from '@Edi/methods/asyncDownloadTask';
import { MassDocumentActions } from '@Edi/methods/massDocumentActions';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';

export class DocumentsGridModule {
	moduleData;
	massDocumentActions;
	grids = {};
	filterForms = {};
	tabPanel;
	filterForm;
	filterObjects = {};
	filterObjectSharing;
	currentTab;
	gridsWithCustomFilter = ['sharing', 'warningAndErrorDocs'];

	constructor() {
		const me = this;
		//Биндим методы, т.к. они будут передаваться как колбэки и вызываться опосредовано без контекста
		me._gridDataChangeHandler = me.getGridDataChangeHandler().bind(this);
		me._gridActiveTabHandler = me.gridActiveTabHandler.bind(this);
		me._fireSearchCommon = me.fireSearchCommon.bind(this);
	}

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data, initCallBack) {
		const me = this;
		me.moduleData = data;
		me.massDocumentActions = new MassDocumentActions({
			moduleData: me.moduleData,
			moduleTab: me.moduleData.tab,
			setModuleLoading: me.setModuleLoading.bind(me)
		});
		me._beforeSignStart = me.massDocumentActions.beforeSignStart.bind(me.massDocumentActions);
		me._signActionHandler = me.massDocumentActions.signActionHandler.bind(me.massDocumentActions);
		me._reprocessDocument = me.massDocumentActions.reprocessDocument.bind(me.massDocumentActions);
		me._beforeSignActionHandler = me.massDocumentActions.beforeSignActionHandler.bind(me.massDocumentActions);
		me._signCompleteCallback = me.massDocumentActions.signCompleteCallback.bind(me.massDocumentActions);
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.documents.on('create', me._gridDataChangeHandler);
		edi.events.documents.on('activeTab', me._gridActiveTabHandler);
		edi.events.documents.on('change', me._gridDataChangeHandler);
	}

	setModuleLoading(loader) {
		const me = this;
		if (me.moduleData.tab && !me.moduleData.tab.isDestroyed) {
			me.moduleData.tab.setLoading(loader);
		}
	}

	warningAndErrorDocsFilterFunction() {
		const me = this;
		return edi.filters.createGridFilter(
			edi.rest.services.DOCUMENTS.GET,
			me.filterForms['warningAndErrorDocs'],
			me.grids['warningAndErrorDocs'],
			filterMethods.createArgsWarningAndError,
			{
				gridName: 'warningAndErrorDocs'
			},
			{
				persistence: {
					enabled: true,
					name: `${me.moduleData.modName}_warningAndErrorDocs`
				}
			}
		);
	}

	fieldsToSync = [
		'number',
		'numberVal',
		'fromDocTime',
		'toDocTime',
		'creationDateFrom',
		'creationDateTo',
		'dateFrom',
		'dateTo',
		'partners',
		'SELLER_ILNs',
		'BUYER_ILNs',
		'buyerCodes',
		'includeDocTypes',
		'orderNumber',
		'orderNumberVal',
		'deliveryGLN',
		'deliveryGLNVal',
		'desadvNumber',
		'desadvNumberVal',
		'INVOICE_NUMBER',
		'INVOICE_NUMBERVal',
		'transportDocNumber',
		'transportDocNumberVal',
		'factureNumber',
		'factureNumberVal',
		'RECEIVING_ADVICE_NUMBER',
		'RECEIVING_ADVICE_NUMBERVal'
	];

	syncFilterPanels(initiatorGridName) {
		const me = this;
		const sourceName = initiatorGridName === 'warningAndErrorDocs' ? 'warningAndErrorDocs' : 'default';
		const valuesToSync = me.filterForms[sourceName].getForm().getValues(false, false, false, true);
		const targetForm = me.filterForms[sourceName === 'warningAndErrorDocs' ? 'default' : 'warningAndErrorDocs'];
		const targetFields = targetForm
			.getForm()
			.getFields()
			.items.filter((f) => me.fieldsToSync.includes(f.name));
		targetFields.forEach((field) => {
			const name = field.name;
			if (name && valuesToSync.hasOwnProperty(name)) {
				field.setValue(valuesToSync[name]);
				if (field.isXType('datefield')) {
					const sourceForm = me.filterForms[sourceName];
					const sourceDateRange = sourceForm.down(`[name="UI Platform Maven Webapp"]`).up('daterangefield');
					const targetDateRange = field.up('daterangefield');
					if (
						typeof sourceDateRange?.getCurrentPeriod === 'function' &&
						typeof targetDateRange?.setCurrentPeriod === 'function' &&
						sourceDateRange.getCurrentPeriod()
					) {
						//TODO заменить на setCurrentPeriod когда будет пофикшен баг при котором
						// при изменении даты вручную в input не сбрасывается период
						targetDateRange.setCurrentPeriod(sourceDateRange.getCurrentPeriod());
					}
				}
			}
		});
	}

	fireSearchCommon() {
		const me = this;
		const commonGrids = Object.values(me.grids).filter(
			(grid) => !!grid && !me.gridsWithCustomFilter.includes(grid.gridName)
		);
		commonGrids.forEach((grid) => {
			const name = grid.gridName;
			if (!me.filterObjects[name]) {
				me.filterObjects[name] = edi.filters.createGridFilter(
					edi.rest.services.DOCUMENTS.GET,
					me.filterForm,
					me.grids[name],
					filterMethods.createArgsCommon,
					{ gridName: name },
					{
						persistence: {
							enabled: true,
							name: `${me.moduleData.modName}_all_grids`
						}
					}
				);
			}
		});

		if (me.filterForms['warningAndErrorDocs'] && !me.filterObjects['warningAndErrorDocs']) {
			me.filterObjects['warningAndErrorDocs'] = me.warningAndErrorDocsFilterFunction();
		}

		const initiatorGridName = me.currentTab;
		if (initiatorGridName && me.filterForms['warningAndErrorDocs'] && me.filterForms['default']) {
			me.syncFilterPanels(initiatorGridName);
		}

		commonGrids.forEach((grid) => {
			const name = grid.gridName;
			me.filterObjects[name]?.filter();
		});
		me.filterObjects['warningAndErrorDocs']?.filter();
	}

	/**
	 * Initialization of module form
	 */
	createFilterForm() {
		const me = this;
		const { formItemsMap, items } = filterMethods.createCommonFormItems();

		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${me.moduleData.modName}_all_grids`
				},
				toggleAutoSearch: true,
				autosearchEnabled: false,
				searchOnFilterCollapse: true,
				bodyPadding: 0,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultFieldName = edi.utils.getUserDefaultSearchPeriodField(
						edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE
					);
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap[defaultFieldName]?.activatePeriod(defaultModifyDateRange);
				}
			},
			me._fireSearchCommon,
			undefined,
			{
				afterDefault: [
					createButton({
						text: edi.i18n.getMessage('deferred.reports.result.export'),
						tooltip: edi.i18n.getMessage('deferred.reports.result.export.tooltip'),
						cls: BUTTON_CLS.secondary,
						disabled: !edi.permissions.hasPermissions([
							'CREATE_EDI_REPORT_REQUEST_PROCESSING',
							'READ_TEMPLATES_REST_SERVICES'
						]),
						handler: function () {
							edi.methods.createDocumentListReport(
								edi.constants.REPORT_FORMATS.XLS,
								me.tabPanel.activeTab.tabName,
								form.getValues(),
								me.moduleData
							);
						}
					})
				]
			}
		);
		return form;
	}

	createDetailActionColumn() {
		const me = this;
		return {
			glyph: edi.constants.ICONS.DETAILS,
			tooltip: edi.i18n.getMessage('columns.action.details'),
			iconCls: 'edi-grid-row-button-details',
			testCls: 'test-action-icon-details',
			handler: function (_grid, _rowIndex, _colindex, _actionItem, _event, record) {
				me.rowClick(null, record);
			},
			isActionDisabled: function (_view, _rowIndex, _colIndex, _item, record) {
				return !(
					edi.document.actions.checkDocumentActionPermission(
						record.get('type'),
						edi.constants.DOCUMENT_ACTIONS.DETAILS
					) &&
					edi.constants.STATE.DELETED !== record.get('state') &&
					!record.get('archived') &&
					me.canPerformAction(record, edi.constants.DOCUMENT_ACTIONS.DETAILS)
				);
			}
		};
	}

	/**
	 * get actions for colunm
	 * @returns    {Array}   actions array
	 */
	getActionsForActionColumn(gridName) {
		const me = this;
		const items = [];

		items.push(me.createDetailActionColumn());

		return items;
	}

	/**
	 * create action column
	 * @returns    {Object}            action column config
	 */
	createActionColumn(gridName) {
		const me = this;

		return createActionsColumnConfig({
			align: 'center',
			items: me.getActionsForActionColumn(gridName)
		});
	}

	createActionColumnForWarningAndErrorDocs() {
		const me = this;
		return createActionsColumnConfig({
			align: 'center',
			items: [
				me.createDetailActionColumn(),
				errorsAndWarningsMethods.getShowErrorDocumentAction(me.moduleData),
				errorsAndWarningsMethods.getShowWarningDocumentAction(me.moduleData)
			]
		});
	}

	openWarningDetailsModal(totals) {
		let gridData = Object.entries(totals).map(([_, t]) => ({
			type: t.type,
			totalSumm: edi.i18n.getMessage('documents.selected.totals.and.sum.totals', {
				selectedRows: t.count,
				totalSumm: Math.round(t.totalSumm * 100) / 100
			})
		}));

		return createModalPanel({
			autoShow: true,
			width: MODAL_SIZE.widthLarge,
			title: edi.i18n.getMessage('documents.warning.selected.different.types'),
			items: [
				createModalForm({
					items: [
						createGrid({
							proxyConfig: {
								type: 'memory',
								data: gridData
							},
							storeConfig: {
								model: edi.models.getModel('DOCUMENTS_ALL_WARNING_GRID'),
								autoLoad: true
							},
							gridConfig: {
								columns: edi.columns.get('documents_all_warning_grid'),
								disableSelection: true,
								maxHeight: 400
							},
							viewConfig: {
								enableTextSelection: true
							}
						})
					]
				})
			]
		});
	}

	updateTotalsLabel(grid, selected) {
		const me = this;
		if (!grid || !grid.totalsLabel || !Array.isArray(selected)) {
			return;
		}
		let totals = {};
		selected.forEach((doc) => {
			let type = doc.get('type');
			let total = totals[type] || {
				type: null,
				totalSumm: 0,
				count: 0
			};
			total.type = type;
			total.totalSumm += +doc.get('totalSumm');
			total.count++;
			totals[type] = total;
		});

		let totalSum = 0;
		Object.entries(totals).forEach(([_, total]) => {
			totalSum += total.totalSumm;
		});
		totalSum = Math.round(totalSum * 100) / 100;
		grid.totalsLabel.actualTotals = totals;

		let showWarning =
			!edi.constants.DISABLE_DIFFERENT_DOCUMENTS_SELECTION_WARNING && Object.keys(totals).length > 1;

		grid.totalsLabel.removeAll();
		grid.totalsLabel.add(
			createLabel({
				text: showWarning
					? edi.i18n.getMessage('documents.warning.selected.different.types')
					: edi.i18n.getMessage('documents.selected.totals.and.sum.totals.new', {
							selectedRows: selected.length,
							totalSumm: totalSum
					  }),
				cls:
					'edi-tbar-total-label-right' +
					(showWarning ? ' edi-documents-grid__total-warning-label' : '') +
					(!selected.length ? ' edi-paging-bar-total-label-empty' : '')
			})
		);
		if (showWarning) {
			grid.totalsLabel.add(
				createButton({
					cls: `${BUTTON_CLS.light} edi-documents-grid__total-warning-details-button`,
					glyph: edi.constants.ICONS.WARNING,
					text: edi.i18n.getMessage('documents.warning.selected.different.types.details'),
					minWidth: 120,
					handler() {
						me.openWarningDetailsModal(totals);
					}
				})
			);
		}
	}

	/**
	 * Checks if document can be exported (ORIGINAL export)
	 * @param	{Object}	doc
	 * @return	{boolean}	true=can be exported
	 */
	isDocAllowOriginalExport(doc) {
		return (
			edi.constants.DOCS_ALLOW_ORIGINAL_EXPORT.includes(doc.type) &&
			edi.utils.getAttributeByName(doc.attributes, 'SOURCE') === 'INTEGRATION'
		);
	}

	/**
	 * Checks if document can be exported (TRANSIT export)
	 * @param	{Object}	doc
	 * @return	{boolean}	true=can be exported
	 */
	isDocAllowTransitExport(doc) {
		return edi.constants.DOCS_ALLOW_TRANSIT_EXPORT.includes(doc.type);
	}

	addDocumentsToPackage(grid) {
		const docs = (grid.getSelectionModel().selected?.items ?? [])
			.filter((docRecord) =>
				edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.PACKAGING, edi.action.getDocumentData(docRecord))
			)
			.filter((docRecord) => !docRecord.get('packageId'))
			.map((docRecord) => docRecord.getData());
		if (docs.length > 0) {
			documentPackageMethods.addDocsToPackage(
				docs.map((doc) => doc.id),
				docs[0].toOrg.id,
				() => {
					docs.forEach((doc) => edi.events.documents.fireEvent('change', doc));
				},
				edi.rest.getErrorHandler()
			);
		}
	}

	createNewPackageFromDocuments(grid) {
		const docs = (grid.getSelectionModel().selected?.items ?? [])
			.filter((docRecord) =>
				edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.PACKAGING, edi.action.getDocumentData(docRecord))
			)
			.filter((docRecord) => !docRecord.get('packageId'))
			.map((docRecord) => docRecord.getData());
		if (docs.length > 0) {
			documentPackageMethods.createPackageWithDocuments(docs);
		}
	}

	addPackagingActions() {
		const packagingMenu = [];
		if (edi.permissions.hasPermission('LINK_PACKAGE_OBJECT')) {
			packagingMenu.push({
				name: edi.i18n.getMessage('document.mark.packaging.add'),
				itemId: 'PACKAGING_ADD'
			});
		}
		if (edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')) {
			packagingMenu.push({
				name: edi.i18n.getMessage('document.mark.packaging.create'),
				itemId: 'PACKAGING_CREATE'
			});
		}
		return packagingMenu.length > 0
			? [
					{
						id: edi.constants.DOCUMENT_ACTIONS.PACKAGING,
						name: edi.i18n.getMessage('document.action.PACKAGING'),
						menu: packagingMenu
					}
			  ]
			: [];
	}

	/**
	 * Initialization of module grid
	 */
	createGridFn(gridName, columnsName, props) {
		const me = this;
		const defaultSorting = edi.core.getExtraData('user.defaultSorting');
		let columns = edi.columns.get(columnsName);
		let sortableColumn = 'modifyDate';
		let setHiddenColumn = function (nameColumn) {
			let modifyDateColumn = columns.find((column) => {
				return column.dataIndex === nameColumn;
			});
			if (modifyDateColumn) {
				modifyDateColumn.hidden = false;
				sortableColumn = nameColumn;
			}
		};
		switch (defaultSorting) {
			case 'creationDate':
				setHiddenColumn('creationDate');
				break;
			case 'modifyDate':
				setHiddenColumn('modifyDate');
				break;
			case 'doctime':
				setHiddenColumn('doctime');
				break;
			default:
				break;
		}
		let actionItems = [];
		let totalsLabel = createPanel({
			layout: {
				type: 'hbox',
				align: 'middle'
			},
			items: []
		});

		if ('deleted' !== gridName) {
			if ('rejected' !== gridName) {
				if (
					!edi.constants.IS_EDIWEB_CLIENT &&
					edi.permissions.hasPermission('CLIENT_LEGACY_DOCUMENT_REPROCESS')
				) {
					actionItems.push({
						id: edi.constants.DOCUMENT_ACTIONS.REPROCESS,
						name: edi.i18n.getMessage('document.reprocess.document')
					});
				}
				if ('warningAndErrorDocs' !== gridName) {
					actionItems.push({
						id: edi.constants.DOCUMENT_ACTIONS.SIGN,
						name: edi.i18n.getMessage('document.sign.document')
					});
				}
			}
			if ('warningAndErrorDocs' === gridName) {
				if (edi.permissions.hasPermission('CLIENT_LEGACY_DOCUMENT_REPROCESS')) {
					actionItems.push({
						id: edi.constants.DOCUMENT_ACTIONS.REPROCESS_MANUALLY,
						name: edi.i18n.getMessage('document.manually.reprocess.document')
					});
				}
			}
			if (!edi.constants.IS_EDIWEB_CLIENT && ('outgoing' === gridName || 'notarchived' === gridName)) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.SEND,
					name: edi.i18n.getMessage('document.send.document')
				});
			}
			if (!edi.constants.IS_EDIWEB_CLIENT && ('incoming' === gridName || 'notarchived' === gridName)) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.READ_AND_COMPLETE,
					name: edi.i18n.getMessage('document.mark.read_and_complete')
				});
			}
			//Нужно мултону для глобального завершения PACKING_LIST
			if (
				!edi.constants.IS_EDIWEB_CLIENT &&
				edi.permissions.hasPermission('CLIENT_COMPLETE_PACKING_LIST') &&
				('incoming' === gridName || 'notarchived' === gridName)
			) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.COMPLETE,
					name: edi.i18n.getMessage('action.complete')
				});
			}
			if (!edi.constants.IS_EDIWEB_CLIENT && 'outgoing' === gridName) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.COPY,
					name: edi.i18n.getMessage('document.copy.document')
				});
			}
			if (
				'outgoing' === gridName ||
				'notarchived' === gridName ||
				'rejected' === gridName ||
				'warningAndErrorDocs' === gridName
			) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.DELETE,
					name: edi.i18n.getMessage('document.delete.document')
				});
			}

			// EW start
			actionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS,
				name: edi.i18n.getMessage('document.send.to.approve.document')
			});
			actionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_EMPLOYEES,
				name: edi.i18n.getMessage('ediweb.send.to.approval.employees.btn')
			});
			// EW end

			if ('incoming' === gridName || 'notarchived' === gridName) {
				if (edi.permissions.hasPermission('CLIENT_APPLY_QUICK_ANSWERS') && !edi.constants.SKIP_QUICK_ANSWER) {
					const settingsStore = edi.stores.initQuickAnswerSettingsStore();
					if (settingsStore.getCount()) {
						actionItems.push({
							id: edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_CONFIRM,
							name: edi.i18n.getMessage('quick.answer.apply.action.confirm'),
							handler: function () {
								edi.document.actions.documentGridActionProcess({
									grid: grid,
									moduleData: me.moduleData,
									groupProcessedUrl: edi.rest.services.QUICK_ANSWER.APPLY.CONFIRM.PUT,
									action: edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_CONFIRM,
									genParamsMethod: function (data) {
										const ids = [];
										for (let i = 0, len = data.length; i < len; i++) {
											const row = data[i].getData();
											ids.push(row.id);
										}
										return ids;
									},
									completeEvent: false,
									completeCallback: function (_docs, resp) {
										let type, i, title, subType;
										let totalMsg = edi.i18n.getMessage(
											'quick.answer.apply.action.confirm.success.total',
											{ total: resp.data.total }
										);

										if (resp && resp.data && resp.data.errorTypes && resp.data.errorTypes.length) {
											const errTypes = resp.data.errorTypes;
											let msg = edi.i18n.getMessage(
												'quick.answer.apply.action.confirm.error.types'
											);

											for (i = 0; i < errTypes.length; i++) {
												const objType = errTypes[i];
												for (type in objType) {
													if (objType.hasOwnProperty(type)) {
														title = edi.i18n.getMessage('documents.doctype.' + type);
														if (
															objType[type] &&
															edi.constants.DOCUMENT_TYPES.LEGACY_ORDER === type
														) {
															subType = edi.i18n.getMessage(
																'quick.answer.type.document.order.' + objType[type]
															);
															title += ' (' + subType + ')';
														}
														msg += '<b>' + title + '</b><br>';
													}
												}
											}
											msg += totalMsg;
											edi.core.showInfo(msg);
										} else {
											edi.core.showInfo(totalMsg);
										}
									},
									processMethod: 'PUT'
								});
							}
						});
					}
				}
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.REJECT,
					name: edi.i18n.getMessage('document.reject.document')
				});
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.CLARIFY,
					name: edi.i18n.getMessage('document.mark.clarify')
				});
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.RENOUNCEMENT,
					name: edi.i18n.getMessage('document.renouncement')
				});
			}
			if ('outgoing' !== gridName) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.COPY,
					name: edi.i18n.getMessage('document.copy.document')
				});
			}
			if (!edi.constants.IS_EDIWEB_CLIENT) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.PRINT,
					name: edi.i18n.getMessage('document.pdf.document.print'),
					menu: [
						{
							id: edi.constants.DOCUMENT_ACTIONS.PRINT,
							name: edi.i18n.getMessage('document.pdf.document'),
							notDisabled: true,
							handler: function () {
								edi.methods.documentsGroupPrint(grid, me.moduleData);
							}
						}
					]
				});
			}

			if (edi.permissions.hasPermission('CREATE_EDI_GROUP_EXPORT_REQUEST')) {
				let exportMenu = [
					{
						id: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP_XML,
						name: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
						itemId: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP_XML,
						handler: function () {
							me.exportGroupHandler(grid, edi.constants.EXPORT_GROUP_REQUEST.TYPE.XML);
						}
					},
					{
						name: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
						itemId: edi.constants.EXPORT_GROUP_REQUEST.TYPE.SIMPLE,
						handler: function () {
							me.exportGroupHandler(grid, edi.constants.EXPORT_GROUP_REQUEST.TYPE.SIMPLE);
						}
					},
					{
						name: edi.i18n.getMessage('export.group.request.menu.btn.all'),
						itemId: edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON,
						handler: function () {
							me.exportGroupHandler(grid, edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON);
						}
					},
					{
						name: edi.i18n.getMessage('action.export.original'),
						itemId: edi.constants.EXPORT_GROUP_REQUEST.TYPE.ORIGINAL,
						handler: function () {
							me.exportGroupHandler(grid, edi.constants.EXPORT_GROUP_REQUEST.TYPE.ORIGINAL);
						}
					},
					{
						name: edi.i18n.getMessage('action.export.transit'),
						itemId: edi.constants.EXPORT_GROUP_REQUEST.TYPE.TRANSIT,
						handler: function () {
							me.exportGroupHandler(grid, edi.constants.EXPORT_GROUP_REQUEST.TYPE.TRANSIT);
						}
					}
				];
				if (edi.constants.IS_EDIWEB_CLIENT) {
					exportMenu = exportMenu.filter((item) => {
						return (
							item.itemId !== edi.constants.EXPORT_GROUP_REQUEST.TYPE.ORIGINAL &&
							item.itemId !== edi.constants.EXPORT_GROUP_REQUEST.TYPE.TRANSIT
						);
					});
				}
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP,
					name: edi.i18n.getMessage('export.group.request.menu.tooltip'),
					menu: exportMenu
				});
			}
		}
		if ('deleted' !== gridName) {
			const packagingActions = me.addPackagingActions();
			packagingActions.length > 0 && actionItems.push(...packagingActions);
		}

		let actionColumn;
		if ('deleted' !== gridName) {
			actionColumn = me.createActionColumn(gridName);
		}
		if (gridName === 'warningAndErrorDocs') {
			actionColumn = me.createActionColumnForWarningAndErrorDocs(gridName);
		}

		actionColumn && columns.push(actionColumn);

		var createDocumentBtn = function () {
			let hasPermissions =
				edi.permissions.hasPermission('CREATE_EDI_FNS_UPD') ||
				edi.permissions.hasPermission('CREATE_DSF') ||
				edi.permissions.hasPermission('WEB_UPLOAD_EDI_FILES');

			var menu = new Ext.menu.Menu({
				plain: true,
				items: [
					edi.permissions.hasPermission('CREATE_EDI_FNS_UPD')
						? {
								text: edi.i18n.getMessage('document.structured_document_upd.ediweb'),
								handler: function () {
									edi.core.openModule('document.create.fns_upd_5_01n', null, false, false);
								}
						  }
						: null,
					edi.permissions.hasPermission('CREATE_EDI_FNS_UPD')
						? {
								text: edi.i18n.getMessage('document.structured_document_upd_5_02n.ediweb'),
								handler: function () {
									edi.core.openModule('document.create.fns_upd_5_02n', null, false, false);
								}
						  }
						: null,
					edi.permissions.hasPermission('CREATE_DSF')
						? {
								text: edi.i18n.getMessage('document.dsf'),
								handler: function () {
									edi.core.openModule('document.create.dsf', null, false, false);
								}
						  }
						: null,
					edi.permissions.hasPermission('WEB_UPLOAD_EDI_FILES')
						? {
								text: edi.i18n.getMessage('navigation.document.web.upload.file'),
								handler: function () {
									edi.methods.showUploadDocumentDialog();
								}
						  }
						: null
				]
			});

			return createButton({
				text: edi.i18n.getMessage('form.btn.create'),
				cls: [BUTTON_CLS.primary],
				padding: '8 0 0 8',
				glyph: edi.constants.ICONS.PLUS,
				menu: menu,
				disabled: !hasPermissions,
				handler: function (comp) {
					if (!edi.constants.IS_CAN_CREATE_DOCUMENT || edi.constants.IS_EXPIRED_DOCUMENT_TARIFF) {
						comp.hideMenu();
						edi.methods.tariffs.isCanCreateDocHandler();
					}
				}
			});
		};

		let topBar = undefined;
		if (actionItems.length) {
			const onMenuItemClick = function (id, actionCfg) {
				if (actionCfg.itemId === 'PACKAGING_CREATE') {
					me.createNewPackageFromDocuments(grid);
				} else if (actionCfg.itemId === 'PACKAGING_ADD') {
					me.addDocumentsToPackage(grid);
				} else {
					const options = {
						grid: grid,
						moduleData: me.moduleData,
						action: id
					};
					if (edi.constants.DOCUMENT_ACTIONS.SIGN === id) {
						Object.assign(options, {
							handler: me._signActionHandler,
							beforeStart: me._beforeSignStart,
							loadingMessage: 'document.sign.started',
							forceCompleteEvent: true,
							completeCallback: me._signCompleteCallback
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.REPROCESS === id) {
						Object.assign(options, {
							loadingMessage: 'document.reprocess.started',
							confirmTitle: 'document.reprocess.document',
							confirmAction: true,
							handler: me._reprocessDocument
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.REPROCESS_MANUALLY === id) {
						Object.assign(options, {
							loadingMessage: 'document.reprocess.started',
							confirmTitle: 'document.reprocess.document',
							confirmAction: true,
							actionCheckCallback: function (record) {
								const errorProcessingState = edi.utils.getAttributeByName(
									record.get('attributes'),
									errorAndWarningsConfig.ERROR_PROCESSING_STATE_ATTR_NAME
								);
								return errorProcessingState !== 'processed';
							},
							handler: errorsAndWarningsMethods.reprocessManuallyDocument
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.COPY === id) {
						Object.assign(options, {
							loadingMessage: 'document.copy.started',
							confirmTitle: 'document.copy.document',
							confirmAction: true,
							handler: edi.document.actions.copyDocumentHandler
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.DELETE === id) {
						Object.assign(options, {
							processProperties: (function () {
								const props = {};
								props[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE] = true;
								return props;
							})(),
							processEvent: {
								object: 'documents',
								event: 'delete'
							},
							loadingMessage: 'document.delete.started',
							confirmTitle: 'document.delete.document',
							confirmAction: true
						});
					} else if (
						edi.constants.DOCUMENT_ACTIONS.REJECT === id ||
						edi.constants.DOCUMENT_ACTIONS.ROUTE_REJECT === id
					) {
						Object.assign(options, me.massDocumentActions.getRejectActionConfig(grid));
					} else if (edi.constants.DOCUMENT_ACTIONS.CLARIFY === id) {
						Object.assign(options, me.massDocumentActions.getClarifyActionConfig(grid));
					} else if (edi.constants.DOCUMENT_ACTIONS.RENOUNCEMENT === id) {
						let utochReason = '';
						let inputReason = function (val) {
							utochReason = val;
						};
						Object.assign(options, {
							processDocumentCallback: me._beforeSignActionHandler,
							processUrl: edi.rest.services.DOCUMENTS.CREATE_UTOCH.POST,
							processMethod: 'POST',
							processUrlParamCollector: function (record) {
								return {
									parentId: record.get('id')
								};
							},
							processProperties: function () {
								const props = {};
								props['reason'] = utochReason;
								props['RENOUNCEMENT'] = true;
								return props;
							},
							loadingMessage: 'document.mark.renouncement.started',
							confirmTitle: 'document.renouncement',
							confirmAction: true,
							beforeStart: function (data, afterStart) {
								edi.document.actions.createGridRejectReasonDialog(data, afterStart, {
									setReason: inputReason,
									fieldTitle: 'document.renouncement.reason',
									buttonOkTitle: 'form.btn.send',
									title: 'document.renouncement'
								});
							},
							completeCallback: function () {
								me.massDocumentActions.clearSelectedCertAndPoa();
							}
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.SEND === id) {
						Object.assign(options, {
							processProperties: function (record) {
								const props = {};
								if (
									record.data &&
									record.data.type === edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT
								) {
									props['PUSH_TO_SENT'] = true;
								}
								return props;
							},
							beforeStart: function (data, afterStart) {
								let numbers = data.map(function (item) {
									return item.data.type === edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT
										? item.data.number
										: undefined;
								});
								numbers = numbers.filter((it) => !!it);

								if (numbers.length) {
									edi.core.confirm(
										undefined,
										edi.i18n.getMessage('document.formalized.action.send.list.confirm', [
											numbers.join(', ')
										]),
										afterStart
									);
								} else {
									afterStart();
								}
							},
							loadingMessage: 'document.send.started',
							confirmTitle: 'document.send.document',
							confirmAction: true
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.READ_AND_COMPLETE === id) {
						Object.assign(options, {
							loadingMessage: 'document.read_and_complete.started',
							confirmTitle: 'document.mark.read_and_complete',
							confirmAction: true,
							handler: edi.document.actions.readAndCompleteDocumentHandler
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.COMPLETE === id) {
						Object.assign(options, {
							confirmAction: true,
							confirmTitle: 'action.complete',
							handler: edi.document.actions.completeDocumentHandler
						});
					}
					// EW start
					else if (edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS === id) {
						Object.assign(options, {
							handler: function (record, grid, callback, failure) {
								var recordData = record.getData();
								const bpName = edi.utils.getAttributeByName(recordData.attributes, 'bpName');
								var sendData = recordData;
								sendData.status = recordData.state;
								sendData.date = recordData.creationDate;
								sendData.bpName = bpName;
								sendData.versionId = recordData.versionId;
								showApprovalModal(
									sendData,
									me.moduleData,
									null,
									edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
								);
							},
							completeCallback: function () {}
						});
					} else if (edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_EMPLOYEES === id) {
						Object.assign(options, {
							handler: function (record, grid, callback, failure) {
								var recordData = record.getData();
								const bpName = edi.utils.getAttributeByName(recordData.attributes, 'bpName');
								var sendData = recordData;
								sendData.status = recordData.state;
								sendData.date = recordData.creationDate;
								sendData.bpName = bpName;
								sendData.versionId = recordData.versionId;
								showApprovalModal(
									sendData,
									me.moduleData,
									null,
									edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
								);
							},
							completeCallback: function () {}
						});
					}
					// EW end
					edi.document.actions.documentGridActionProcess(options);
				}
			};
			topBar = createGridActionBar({
				actionCfgs: actionItems,
				defaultHandler: onMenuItemClick,
				totalsLabel: totalsLabel
			});
		}

		/**
		 * Checkbox selection model processor linked with grid top bar action items
		 * @param model
		 * @param selected
		 * @param actionItems
		 * @param isProcessible
		 */
		let selectionProcessor = function (model, selected, actionItems, isProcessible) {
			let i, groupActionBtn, actionConf;
			for (i = 0; i < actionItems.length; i++) {
				groupActionBtn = actionItems[i];
				actionConf = groupActionBtn.rulesData;
				if (isProcessible(actionConf)) {
					let allowedDocs,
						allowFromGridConf = edi.document.actions.availableFromGrid[actionConf.id] || {},
						isAvailableFromGrid = true;
					let absentPermissions = [];
					allowedDocs = selected.filter(function (docRecord) {
						isAvailableFromGrid = true;
						const document = docRecord.getData();

						if (!Ext.isEmpty(allowFromGridConf) && Array.isArray(allowFromGridConf)) {
							Ext.each(allowFromGridConf, function (config) {
								if ('function' == typeof config.allow) {
									//Check action for allow performing from grid if doctypes  aren't specified in allowFromGrid conf
									//Or if  doctype exists in specified doctypes
									if (
										!config.documents ||
										(Array.isArray(config.documents) &&
											config.documents.indexOf(document.type) > -1)
									) {
										isAvailableFromGrid = config.allow(document);
									}
								}
							});
						}

						let { result, rulePassed, permissionsPassed, actionPermission } =
							edi.action.isAvailableExtended(actionConf.id, edi.action.getDocumentData(docRecord));
						if (rulePassed && !permissionsPassed && actionPermission) {
							absentPermissions.push(actionPermission);
						}

						return isAvailableFromGrid && result;
					});

					if ('PACKAGING_CREATE' === actionConf.itemId || 'PACKAGING_ADD' === actionConf.itemId) {
						const hasDocsWithoutPackage = allowedDocs.some((doc) => !doc.get('packageId'));
						groupActionBtn.setDisabled(!hasDocsWithoutPackage);
						// EW override
					} else if (
						(edi.constants.DOCUMENT_ACTIONS.COPY === actionConf.id ||
							edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS === actionConf.id ||
							edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_EMPLOYEES === actionConf.id) &&
						(allowedDocs.length > 1 || allowedDocs.length !== selected.length)
					) {
						groupActionBtn.setDisabled(true);
					} else if (!actionConf.notDisabled) {
						let actionIsDisabled = !allowedDocs.length;
						groupActionBtn.setDisabled(actionIsDisabled);

						let tooltip =
							actionIsDisabled && absentPermissions.length
								? edi.i18n.getMessage('documents.action.tooltip.not_enough_permissions')
								: '';
						if (tooltip && window.isDevelopment === true) {
							tooltip += ':<br/>' + absentPermissions.join(',<br/>');
						}
						groupActionBtn.setTooltip(tooltip);
					}
					if (edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP === actionConf.id) {
						let allDocsAllowOnlyFullExport = true;
						let canFullExport = false;
						let canXmlExport = false;
						let canOriginalExportCount = 0;
						let canTransitExportCount = 0;
						selected.forEach(function (doc) {
							const docType = doc.get('type');
							if (
								!edi.constants.DSF_CHECK_PERMISSION_FULL_EXPORT.some((it) => it === docType) ||
								edi.permissions.hasPermission('EXPORT_EDI_DSF_EXTENDED')
							) {
								canFullExport = true;
							}
							if (!edi.constants.DOCS_ALLOW_ONLY_FULL_EXPORT.some((it) => it === docType)) {
								allDocsAllowOnlyFullExport = false;
							}
							if (
								docType === edi.constants.DOCUMENT_TYPES.DP_CONTAINER ||
								docType === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR ||
								docType === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE
							) {
								if (
									edi.utils.getObjectProperty(groupActionBtn, 'rulesData.itemId') ===
									edi.constants.EXPORT_GROUP_REQUEST.TYPE.SIMPLE
								) {
									groupActionBtn.setDisabled(true);
								}
								if (
									edi.utils.getObjectProperty(groupActionBtn, 'rulesData.itemId') ===
									edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON
								) {
									groupActionBtn.setDisabled(
										!(
											edi.permissions.hasPermission('CREATE_EDI_GROUP_EXPORT_REQUEST') &&
											edi.permissions.hasPermission('EXPORT_EDI_GROUP_EXPORT_REQUEST') &&
											edi.permissions.hasPermission('REJECT_EDI_GROUP_EXPORT_REQUEST') &&
											edi.permissions.hasPermission('READ_EDI_GROUP_EXPORT_REQUEST')
										)
									);
								}
							} else if (
								edi.constants.DOCS_ALLOW_ONLY_FULL_EXPORT_SHOW_BUTTON.some((it) => it === docType)
							) {
								if (
									edi.utils.getObjectProperty(groupActionBtn, 'rulesData.itemId') ===
									edi.constants.EXPORT_GROUP_REQUEST.TYPE.SIMPLE
								) {
									groupActionBtn.setDisabled(true);
								}
							}
							canXmlExport =
								canXmlExport ||
								edi.action.isAvailable(
									edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP_XML,
									edi.action.getDocumentData(doc)
								);

							if (me.isDocAllowOriginalExport(doc.getData())) {
								canOriginalExportCount++;
							}
							if (me.isDocAllowTransitExport(doc.getData())) {
								canTransitExportCount++;
							}
						});

						if (groupActionBtn.rulesData.itemId === edi.constants.EXPORT_GROUP_REQUEST.TYPE.SIMPLE) {
							groupActionBtn.setVisible(!allDocsAllowOnlyFullExport);
						}
						if (groupActionBtn.rulesData.itemId === edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON) {
							groupActionBtn.setVisible(canFullExport);
						}
						if (groupActionBtn.rulesData.itemId === edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP_XML) {
							groupActionBtn.setVisible(canXmlExport);
						}
						if (groupActionBtn.rulesData.itemId === edi.constants.EXPORT_GROUP_REQUEST.TYPE.ORIGINAL) {
							groupActionBtn.setDisabled(!canOriginalExportCount);
						}
						if (groupActionBtn.rulesData.itemId === edi.constants.EXPORT_GROUP_REQUEST.TYPE.TRANSIT) {
							groupActionBtn.setDisabled(!canTransitExportCount);
						}
					}
				}
			}
			me.updateTotalsLabel(grid, selected);
		};

		const listeners = {};
		if ('deleted' !== gridName) {
			listeners.celldblclick = function (view, _td, cellIndex, record) {
				if (
					cellIndex &&
					edi.document.actions.checkDocumentActionPermission(
						record.get('type'),
						edi.constants.DOCUMENT_ACTIONS.DETAILS
					) &&
					me.canPerformAction(record, edi.constants.DOCUMENT_ACTIONS.DETAILS)
				) {
					me.rowClick(view, record);
				}
			};
		}

		let outgoingOnlySwitch;
		const grid = createGrid(
			Ext.merge(
				{
					saveSorters: true,
					savedSortersName: `${me.moduleData.modName}_${gridName}`,
					proxyConfig: {
						type: 'ajax'
					},
					storeConfig: {
						pageSize: edi.constants.ITEMS_PER_PAGE,
						model: edi.models.getModel('DOCUMENT'),
						groupField: null,
						sortOnLoad: true,
						sorters: {
							property: sortableColumn,
							direction: 'DESC'
						},
						autoLoad: false
					},
					gridConfig: {
						columns: columns,
						cls: 'edi-documents-grid edi-grid-with-toolbar-buttons',
						listeners: listeners,
						showNewPaging: true,
						settingsBtnConf: {
							checkBoxRowsCount: 9
						},
						selModel:
							topBar && topBar.menuActionItemsCnt
								? createGridCheckboxSelectionModel({
										topBar: topBar,
										selectionProcessor: selectionProcessor
								  })
								: undefined,
						dockedItems: [
							// EW start
							createContainer({
								layout: 'hbox',
								items: [createDocumentBtn(), topBar]
							}),
							// EW end
							'warningAndErrorDocs' === gridName
								? createToolBar({
										docked: 'top',
										items: [
											(outgoingOnlySwitch = createSwitch({
												margin: '0 0 0 12',
												boxLabel: edi.i18n.getMessage('show.only.outgoing.documents'),
												listeners: {
													change: function () {
														me.filterObjects['warningAndErrorDocs'].filter();
													}
												}
											}))
										]
								  })
								: null
						]
					},
					viewConfig: {
						getRowClass: errorsAndWarningsMethods.getRowDocumentClass,
						emptyTextTplOptions: {
							enabled: true,
							title: edi.i18n.getMessage('grid.empty.documents'),
							buttonText: edi.i18n.getMessage('grid.empty.expand.filter'),
							buttonClickHandler: function () {
								me.filterForm.toggleHandler();
							}
						}
					},
					pagingBarConfig: {
						simpleMode: true,
						changeModeBtn: true,
						addButtonsConfig:
							'deleted' !== gridName
								? createButton({
										tooltip: edi.i18n.getMessage('document.group.print.documents'),
										cls: `${BUTTON_CLS.light} edi-label-button test-grid-paginator-group-print`,
										margin: '0 0 0 35',
										glyph: edi.document.actions.getActionIcon(
											edi.constants.DOCUMENT_ACTIONS.GROUP_PRINT
										),
										handler: function () {
											edi.methods.documentsGroupPrint(grid, me.moduleData);
										}
								  })
								: null,
						itemsPerPage: edi.constants.ITEMS_PER_PAGE,
						totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
					}
				},
				props?.gridCfg
			)
		);
		grid.outgoingOnlySwitch = outgoingOnlySwitch;
		grid.totalsLabel = topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined;
		me.updateTotalsLabel(grid, []);
		grid.gridName = gridName;
		return grid;
	}

	/**
	 * Retrieves from backend list of docs allowed for "transit" export
	 * @param	{number[]}	ids	selected record's ids
	 * @returns	{Promise<number[]>}	ids allowed for "transit" export
	 */
	getTransitExportDocsList(ids) {
		return new Promise((resolve) => {
			if (!Array.isArray(ids) || !ids.length) {
				resolve([]);
				return;
			}
			const success = (response) => resolve(response.items || []);
			const fail = edi.rest.getErrorHandler(null, () => resolve([]));
			edi.rest.sendRequest(
				edi.rest.services.INTEGRATION_CHANNELS.PUT_FILTER_DOCS_BY_IDS,
				'PUT',
				Ext.encode(ids),
				success,
				fail
			);
		});
	}

	/**
	 * Downloads documents selected in grid
	 * @param	{Object}	grid
	 * @param	{String}	exportType
	 */
	exportGroupHandler(grid, exportType) {
		const me = this;
		let transitExportIds = [];
		const action =
			exportType === edi.constants.EXPORT_GROUP_REQUEST.TYPE.XML
				? edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP_XML
				: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP;
		edi.document.actions.documentGridActionProcess({
			grid: grid,
			moduleData: me.moduleData,
			groupProcessedUrl: edi.rest.services.EXPORT.GROUP_REQUEST.POST,
			action,
			genParamsMethod(records) {
				let allowedDocs = records.map((rec) => rec.getData());
				//если тип экспорта НЕ "документооборот целиком", то нужно убрать неподдерживаемые документы
				if (exportType !== edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON) {
					allowedDocs = allowedDocs.filter(
						(doc) => !edi.constants.DOCS_ALLOW_ONLY_FULL_EXPORT.some((it) => it === doc.type)
					);
				}
				return {
					exportType,
					docIds: allowedDocs.map((doc) => doc.id)
				};
			},
			beforeStart:
				exportType === edi.constants.EXPORT_GROUP_REQUEST.TYPE.TRANSIT
					? async function (data, afterStart) {
							me.setModuleLoading(true);
							transitExportIds = await me.getTransitExportDocsList(data.map((rec) => rec.data.id));
							me.setModuleLoading(false);
							afterStart();
					  }
					: undefined,
			actionCheckCallback: function (docRecord) {
				const docData = docRecord.data;
				switch (exportType) {
					case edi.constants.EXPORT_GROUP_REQUEST.TYPE.ORIGINAL:
						return me.isDocAllowOriginalExport(docData);
					case edi.constants.EXPORT_GROUP_REQUEST.TYPE.TRANSIT:
						return me.isDocAllowTransitExport(docData) && transitExportIds.includes(docData.id);
					default:
						//условие скопировано из дефолтного actionCheckCallback
						return edi.action.isAvailable(action, edi.action.getDocumentData(docRecord));
				}
			},
			completeEvent: false,
			completeCallback(_docs, resp) {
				if (resp?.data?.id) {
					asyncDownloadTask.check({
						taskId: resp.data.id,
						afterClose: () => me.setModuleLoading(false)
					});
				} else {
					edi.core.showInfo('accept.registry.nothing.to.search');
					me.setModuleLoading(false);
				}
			},
			processMethod: 'POST'
		});
	}

	/**
	 * Grid row click handler
	 * @param    {Object}    view    grid view data object
	 * @param    {Object}    record    store record of clicked row
	 */
	rowClick(view, record) {
		const me = this;
		const data = record.getData();
		const direction = edi.utils.getDocumentDirection(record.get('toOrg'), record.get('fromOrg'));
		const openDetailsModule = function () {
			if (edi.constants.STATE.DELETED !== record.get('state') && !record.get('archived')) {
				edi.document.actions.openDetailsModule(data.type, data);
			}
		};
		if (
			data.hasOwnProperty('viewedByReceiver') &&
			!data.viewedByReceiver &&
			edi.constants.DIRECTIONS.INCOMING === direction
		) {
			me.markDocAsViewed(data.id);
			openDetailsModule();
		} else {
			openDetailsModule();
		}
	}

	/**
	 * Mark document as viewed by receiver
	 * @param docId
	 */
	markDocAsViewed(docId) {
		const me = this;
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.VIEWED.PUT, {
				docId: docId
			}),
			'PUT',
			undefined,
			function () {
				edi.events.documents.fireEvent('change', {
					id: docId
				});
			},
			edi.document.actions.defaultFailureHandler(me.moduleData.tab, 'error.getting.data')
		);
	}

	/**
	 * Additional conditions to verify that an action can be performed
	 * @param record
	 * @param action
	 * @returns {boolean}
	 */
	canPerformAction = function (record, action) {
		let result = true;
		const direction = edi.utils.getDocumentDirection(record.get('toOrg'), record.get('fromOrg'));
		if (edi.constants.DOCUMENT_TYPES.EDI_INVRPT === record.get('type')) {
			if (
				edi.constants.DIRECTIONS.INCOMING === direction &&
				edi.constants.DOCUMENT_ACTIONS.DETAILS === action &&
				edi.constants.STATE.SENT !== record.get('state') &&
				edi.constants.STATE.COMPLETED !== record.get('state')
			) {
				result = false;
			}
		}
		return result;
	};

	//нужен для того чтобы оверрайдить конфиги гридов
	createGrids() {
		const me = this;
		return {
			notarchived: me.createGridFn('notarchived', GRID_COMMON_COLUMN_CONFIG_NAME),
			incoming: me.createGridFn('incoming', GRID_COMMON_COLUMN_CONFIG_NAME),
			outgoing: me.createGridFn('outgoing', GRID_COMMON_COLUMN_CONFIG_NAME),
			deleted: me.createGridFn('deleted', GRID_REMOVED_COLUMN_CONFIG_NAME),
			rejected: me.createGridFn('rejected', GRID_REJECTED_COLUMN_CONFIG_NAME)
		};
	}
	/**
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	renderData(initCallBack) {
		const me = this;
		let filterFormSharing;
		const modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});
		me.filterForm = me.createFilterForm();
		me.filterForms.default = me.filterForm;
		modulePanel.add(me.filterForm);
		// tab panel
		me.tabPanel = createTabPanel(
			{
				cls: TAB_PANEL_CLS.simpleWithoutPadding
			},
			true
		);

		me.grids = me.createGrids();
		if (edi.permissions.hasPermission('CLIENT_READ_CONTEXT_SHARED_ACCESS_DOCUMENT')) {
			const fireSearchSharing = function () {
				if (!me.filterObjectSharing) {
					me.filterObjectSharing = edi.filters.createGridFilter(
						edi.rest.services.DOCUMENTS.SHARING.FILTER.GET,
						filterFormSharing,
						me.grids.sharing,
						filterMethods.createArgsSharing,
						{},
						{
							persistence: {
								enabled: true,
								name: `${me.moduleData.modName}_sharing_grid`
							}
						}
					);
				}
				me.filterObjectSharing.filter();
			};

			/**
			 * Creates form for grid filtering
			 * @returns {Object}                Instance of form
			 */
			const sharingFormItemsMap = filterMethods.createSharingFormItems();
			filterFormSharing = createModuleFilterForm({
				saveFilter: {
					name: `${me.moduleData.modName}_sharing_grid`
				},
				items: [
					createPanel({
						layout: {
							type: 'grid',
							area: [[6, 6], [6, 6], 6]
						},
						padding: '0 16',
						items: [
							sharingFormItemsMap.fromOrgName,
							sharingFormItemsMap.toOrgName,
							sharingFormItemsMap.docId,
							sharingFormItemsMap.docType,
							sharingFormItemsMap.modifyDate
						]
					})
				],
				toggleAutoSearch: true,
				autosearchEnabled: false,
				searchOnFilterCollapse: true,
				bodyPadding: 0,
				formItemsMap: sharingFormItemsMap
			});
			me.filterForms.sharing = filterFormSharing;
			filterFormSharing.fireSearch = fireSearchSharing;
			modulePanel.add(filterFormSharing);

			me.grids.sharing = sharingGridMethods.createGridCmp(me.moduleData, fireSearchSharing, {
				saveSorters: true,
				savedSortersName: `${me.moduleData.modName}_sharing_grid`,
				filterForm: filterFormSharing
			});
			me.grids.sharing.gridName = 'sharing';
		}

		if (edi.permissions.hasPermission('CLIENT_DOCUMENT_ERRORS_WARNINGS_READ')) {
			me.grids.warningAndErrorDocs = me.createGridFn(
				'warningAndErrorDocs',
				GRID_ERROR_AND_WARNING_COLUMN_CONFIG_NAME,
				{
					gridCfg: {
						pagingBarConfig: {
							collectExportData: errorsAndWarningsMethods.errorWarningCollectExportData
						}
					}
				}
			);
			const { formItemsMap: warningAndErrorFormItemsMap, items: warningAndErrorItems } =
				filterMethods.createWarningAndErrorFormItems();
			const filterFormWarningAndErrorDocs = createModuleFilterForm(
				{
					saveFilter: {
						name: `${me.moduleData.modName}_warningAndErrorDocs`
					},
					formItemsMap: warningAndErrorFormItemsMap,
					items: warningAndErrorItems,
					toggleAutoSearch: true,
					autosearchEnabled: false,
					searchOnFilterCollapse: true,
					bodyPadding: 0,
					setFormDefaults: function () {
						const defaultDateFieldName = edi.utils.getUserDefaultSearchPeriodField(
							edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE
						);
						const defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod(
							edi.constants.DEFAULT.FILTER.PERIODS.WEEK
						);
						warningAndErrorFormItemsMap[defaultDateFieldName]?.activatePeriod(defaultModifyDateRange);
						warningAndErrorFormItemsMap['errorProcessingState']?.setValue('notProcessed');
					}
				},
				me._fireSearchCommon
			);
			modulePanel.add(filterFormWarningAndErrorDocs);
			me.filterForms.warningAndErrorDocs = filterFormWarningAndErrorDocs;
		}

		let isFirst = true;
		const getOnActivate = function (gridName) {
			return function () {
				const activeFilter = me.filterForms[gridName] ?? me.filterForms.default;
				for (let filterName in me.filterForms) {
					const filterForm = me.filterForms[filterName];
					me.currentTab = gridName;
					filterForm.updateAddFilterMenu();
					filterForm.setVisible(filterForm === activeFilter);
				}
			};
		};
		const activeTab = edi.utils.getObjectProperty(me.moduleData, 'initData.data.activeTab');
		if (activeTab && me.grids.hasOwnProperty(activeTab)) {
			isFirst = false;
		}
		for (let i in me.grids) {
			if (me.grids.hasOwnProperty(i)) {
				initTabWithGrid(
					i,
					me.grids[i],
					isFirst || activeTab === i,
					me.tabPanel,
					{ callback: getOnActivate(i) },
					{ badgeCount: true }
				);

				isFirst = false;
			}
		}
		modulePanel.add(me.tabPanel);
		me.moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}

		Object.values(me.filterForms).forEach((filterForm) => {
			filterForm?.fireSearch();
		});
	}

	gridActiveTabHandler(tabName) {
		const me = this;
		if (me.grids.hasOwnProperty(tabName)) {
			me.tabPanel.setActiveTab(me.grids[tabName].tab);
		}
	}

	getGridDataChangeHandler() {
		const me = this;
		return Ext.Function.createThrottled(function () {
			for (let i in me.grids) {
				if (me.grids.hasOwnProperty(i)) {
					const store = me.grids[i].getStore();
					const gridSelModel = me.grids[i].getSelectionModel();
					if (gridSelModel) {
						gridSelModel.deselectAll();
					}
					store.reload();
				}
			}
		}, 3000);
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;
		for (let i in me.filterObjects) {
			if (me.filterObjects.hasOwnProperty(i) && me.filterObjects[i].searchTimeout) {
				clearTimeout(me.filterObjects[i].searchTimeout);
			}
		}
		if (me.filterObjectSharing && me.filterObjectSharing.searchTimeout) {
			clearTimeout(me.filterObjectSharing.searchTimeout);
		}
		edi.events.documents.un('create', me._gridDataChangeHandler);
		edi.events.documents.un('activeTab', me._gridActiveTabHandler);
		edi.events.documents.un('change', me._gridDataChangeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

Ext.namespace('edi.modules');
edi.modules['documents'] = DocumentsGridModule;
