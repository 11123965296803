const PACKAGE_ATTACH_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_DOCUMENTS';
const PACKAGE_STATUTORY_MODEL_CONFIG_NAME = 'PACKAGE_STATUTORY_DOCUMENTS';
const PACKAGE_ATTACH_PRIL02OSTRB_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_PRIL02OSTRB';
const PACKAGE_ATTACH_PRILUVIZMCEN_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_PRILUVIZMCEN';
const PACKAGE_ATTACH_PRIL06KTOV_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_PRIL06KTOV';
const PACKAGE_ATTACH_PRILKTOVDR_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_PRILKTOVDR';
const PACKAGE_ATTACH_PRILPROTSOGL_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_PRILPROTSOGL';
const PACKAGE_ATTACH_PRILCOMMUSL_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_PRILCOMMUSL';
const PACKAGE_ATTACH_PRILCOMMUSL_ONLINE_PRODUCTS_MODEL_CONFIG_NAME = 'PACKAGE_ATTACH_PRILCOMMUSL_ONLINE_PRODUCTS';

edi.models.setModels({
	[PACKAGE_ATTACH_MODEL_CONFIG_NAME]: {
		extend: 'edi.models.DOCUMENT_BASE',
		//это нужно, что бы создавались рекорды с каким-нибудь айди,
		//т.к. изначально он null у всех строк в новом пакете
		idProperty: 'type',
		fields: [
			{
				name: 'isAttach',
				type: 'boolean'
			}
		]
	},
	[PACKAGE_STATUTORY_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			}
		]
	},
	[PACKAGE_ATTACH_PRIL02OSTRB_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'addressEntrance',
				type: 'string'
			},
			{
				name: 'addressReturn',
				type: 'string'
			}
		]
	},
	[PACKAGE_ATTACH_PRILUVIZMCEN_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'serialNumber',
				type: 'string'
			},
			{
				name: 'productOfCode',
				type: 'string'
			},
			{
				name: 'productOfName',
				type: 'string'
			},
			{
				name: 'activePrice',
				type: 'string'
			},
			{
				name: 'newPrice',
				type: 'string'
			},
			{
				name: 'percentPrice',
				type: 'string'
			}
		]
	},
	[PACKAGE_ATTACH_PRIL06KTOV_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'positionCode',
				type: 'string'
			},
			{
				name: 'productName',
				type: 'string'
			},
			{
				name: 'trademark',
				type: 'string'
			},
			{
				name: 'tax',
				type: 'string'
			},
			{
				name: 'taxRate',
				type: 'string'
			},
			{
				name: 'unitOfMeasureMeter',
				type: 'string'
			},
			{
				name: 'itemWeight',
				type: 'string'
			},
			{
				name: 'vendorCode',
				type: 'string'
			},
			{
				name: 'manufacturer',
				type: 'string'
			},
			{
				name: 'countryManufacturer',
				type: 'string'
			},
			{
				name: 'unitOfMeasure',
				type: 'string'
			},
			{
				name: 'batch',
				type: 'string'
			},
			{
				name: 'shelfLife',
				type: 'string'
			},
			{
				name: 'things',
				type: 'string'
			},
			{
				name: 'boxes',
				type: 'string'
			},
			{
				name: 'block',
				type: 'string'
			},
			{
				name: 'storageTemperature',
				type: 'string'
			},
			{
				name: 'bulk',
				type: 'string'
			},
			{
				name: 'props',
				type: 'string'
			},
			{
				name: 'delay',
				type: 'string'
			},
			{
				name: 'weightOfUnit',
				type: 'string'
			}
		]
	},
	[PACKAGE_ATTACH_PRILKTOVDR_MODEL_CONFIG_NAME]: {
		extend: `edi.models.${PACKAGE_ATTACH_PRIL06KTOV_MODEL_CONFIG_NAME}`,
		fields: [
			{
				name: 'productionCode',
				type: 'string'
			},
			{
				name: 'productionName',
				type: 'string'
			}
		]
	},
	[PACKAGE_ATTACH_PRILPROTSOGL_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'serialNumber',
				type: 'string'
			},
			{
				name: 'productOfCode',
				type: 'string'
			},
			{
				name: 'productOfName',
				type: 'string'
			},
			{
				name: 'price',
				type: 'string'
			}
		]
	},
	[PACKAGE_ATTACH_PRILCOMMUSL_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'productOfCode',
				type: 'string'
			},
			{
				name: 'productOfName',
				type: 'string'
			},
			{
				name: 'premium',
				type: 'string'
			}
		]
	},
	[PACKAGE_ATTACH_PRILCOMMUSL_ONLINE_PRODUCTS_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'onlineProductOfCode',
				type: 'string'
			},
			{
				name: 'onlineProductOfName',
				type: 'string'
			},
			{
				name: 'onlineProductPremium',
				type: 'string'
			}
		]
	}
});

let getPackageAttachModelName = () => edi.models.getModel(PACKAGE_ATTACH_MODEL_CONFIG_NAME);
let getPackageStatutoryModelName = () => edi.models.getModel(PACKAGE_STATUTORY_MODEL_CONFIG_NAME);

export {
	getPackageAttachModelName,
	getPackageStatutoryModelName,
	PACKAGE_ATTACH_MODEL_CONFIG_NAME,
	PACKAGE_STATUTORY_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRIL02OSTRB_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILUVIZMCEN_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRIL06KTOV_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILKTOVDR_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILPROTSOGL_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILCOMMUSL_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILCOMMUSL_ONLINE_PRODUCTS_MODEL_CONFIG_NAME
};
