Ext.namespace('edi.stores');
new (function () {
	var onBeforeInit = edi.stores.onBeforeInit;
	edi.stores.onBeforeInit = function () {
		'function' == typeof onBeforeInit ? onBeforeInit() : null;

		Object.assign(edi.stores.data, {
			project_list: [],
			product_list: [],
			product_urls: [],
			container: [],
			identifyProductToolNumbers: (function () {
				var items = [];
				Object.entries(['kiz', 'packNumber']).forEach(function ([key, value]) {
					items.push({
						id: value,
						name: edi.i18n.getMessage('line.item.identifyProductToolNumbers.' + value)
					});
				});
				return items;
			})()
		});

		edi.stores.data.docTypesTree.DSF.items = edi.stores.data.docTypesTree.DSF.items.concat([
			edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
		]);

		Object.assign(edi.stores.dataLoaders, {
			projectList: function (callback) {
				var success = function (data) {
					edi.stores.data.project_list = data.items;
					edi.stores.data.project_list.forEach((prod) => {
						prod.label = edi.i18n.getMessage(`ediweb.product.label.${prod.name}`);
						prod.description = edi.i18n.getMessage(`ediweb.product.description.${prod.name}`);
					});
					var products = edi.utils.getObjectProperty(edi.core.getUserData().org, 'attributes.products.value');
					var mainPageCards =
						edi.utils.getObjectProperty(edi.core.getUserData().org, 'attributes.mainPageCards.value') ===
						'EDI';

					if (products) {
						var productsArray = products.split(',');
						edi.stores.data.project_list = edi.stores.data.project_list.map(function (item) {
							item.connected = productsArray.some((it) => it === item.name);
							if (item.name === 'edi') {
								item.connected = !!mainPageCards;
							}
							return item;
						});
					}

					edi.stores.data.project_list = edi.stores.data.project_list.map(function (item) {
						if (products) {
							var productsArray = products.split(',');
							item.connected = productsArray.some((it) => it === item.name);
							if (item.name === 'edi') {
								item.connected = !!mainPageCards;
							}
						} else {
							item.connected = false;
						}
						return item;
					});
				};
				var failure = function () {
					edi.core.handleException('Not load project');
				};
				edi.rest.sendRequest(edi.rest.services.PROJECT.LIST.GET, 'GET', null, success, failure, callback);
			},
			containerForDocuments: function (callback) {
				var success = function (data) {
					if (data && data.items) {
						edi.stores.data.container = data.items;
					}
				};
				var failure = function () {
					edi.core.handleException('Not load project');
				};
				edi.rest.sendRequest(
					edi.utils.compileURL(edi.rest.services.DOCUMENTS.GET, {
						includeDocTypes: Ext.encode(['ONBOARDING_CONTAINER'])
					}),
					'GET',
					null,
					success,
					failure,
					callback
				);
			},
			productUrls: function (callback) {
				var success = function (data) {
					if (data && data.data) {
						edi.stores.data.product_urls = data.data;
					}
				};
				var failure = function (data) {
					edi.core.handleException(data);
				};
				edi.rest.sendRequest(edi.rest.services.PRODUCT.URLS.GET, 'GET', null, success, failure, callback);
			}
		});

		edi.stores.createInitStoreMethods([
			{
				methodName: 'initProjectList',
				storeName: 'projectList',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.project_list, 'PROJECT_LIST', onload);
				},
				autoInit: true
			},
			{
				methodName: 'initProductList',
				storeName: 'productList',
				constructor: function (onload) {
					var products = edi.utils.getObjectProperty(edi.core.getUserData().org, 'attributes.products.value');
					if (products) {
						var productsArray = products.split(',');
						var project_list = [...edi.stores.data.project_list];
						project_list.push({
							id: products.length + 1,
							name: 'ediweb',
							label: 'Ediweb',
							description: '',
							url: '#'
						});
						project_list.forEach(function (item) {
							if (
								productsArray.some((it) => it === item.name) &&
								item.name !== 'coreplat' &&
								item.name !== 'factorplat'
							) {
								edi.stores.data.product_list.push(item);
							}
						});
					}
					return edi.stores.createInlineStore(edi.stores.data.product_list, 'PROJECT_LIST', onload);
				},
				autoInit: true
			},
			{
				methodName: 'initAllProductList',
				storeName: 'allProductList',
				constructor: function (onload) {
					var products = [...edi.stores.data.project_list];
					products.push({
						id: products.length + 1,
						name: 'ediweb',
						label: 'Ediweb',
						description: '',
						url: '#'
					});
					return edi.stores.createInlineStore(products, 'PROJECT_LIST', onload);
				},
				autoInit: true
			},
			{
				methodName: 'initDefaultProductList',
				storeName: 'defaultProductList',
				constructor: function (onload) {
					var defaultProducts = ['ediweb', 'dropcat', 'coreplat', 'factorplat'];
					var products = [...edi.stores.data.project_list];

					products.push({
						id: products.length + 1,
						name: 'ediweb',
						label: 'Ediweb',
						description: '',
						url: '#'
					});

					products = products.filter(function (product) {
						return defaultProducts.some((it) => it === product.name);
					});
					products.sort(function (a, b) {
						return defaultProducts.indexOf(a.name) - defaultProducts.indexOf(b.name);
					});
					return edi.stores.createInlineStore(products, 'PROJECT_LIST', onload);
				},
				autoInit: true
			},
			{
				methodName: 'initCompanyTypeStore',
				storeName: 'companyTypesStore',
				constructor: function (onload) {
					return edi.stores.createDisplayingStore(
						['individual', 'company', 'person'],
						'COMPANY_TYPE',
						'type',
						function (id) {
							return edi.i18n.getMessage('company.type.' + id);
						},
						null,
						onload
					);
				},
				autoInit: true
			},
			{
				methodName: 'initContainerForDocuments',
				storeName: 'containerForDocuments',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.container, 'CONTAINER', onload);
				},
				autoInit: true
			},
			{
				methodName: 'initRelatedOrganizationsStore',
				storeName: 'relatedOrganizationsStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.relations.getRelations(), 'ORGANIZATION', onload);
				}
			}
		]);
	};
})();
