import tpl from './CheckLabel.ext.tpl';
import './CheckLabel.scss';

const baseCls = 'ui-check-label';
Ext.define('UI.components.CheckLabel', {
	extend: 'Ext.Component',
	baseCls,
	glyph: edi.constants.ICONS.DONE,
	renderTpl: tpl,
	renderSelectors: {
		textEl: `span.${baseCls}-text`,
		iconEl: `span.${baseCls}-icon`
	},
	typography: 'caption_01',
	_active: false,

	beforeInit: function () {},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	afterInit: function () {},

	afterRender: function () {
		const me = this;
		me.callParent();
		me.setText(me.text);
		me.setGlyph(me.glyph);
		me.setTypography(me.typography);
	},

	getText: function () {
		const me = this;
		return me.textEl.getText();
	},

	setText: function (text = '') {
		const me = this;
		me.textEl.setText(text);
	},

	setTextColor: function (color) {
		const me = this;
		if (color) me.textEl.setStyle('color', color);
	},

	setTypography: function (typography) {
		const me = this;
		if (typography) me.textEl.addCls(typography);
	},

	setGlyph: function (glyph = edi.constants.ICONS.DONE) {
		const me = this;
		const { character } = new Ext.Glyph(glyph);
		me.iconEl.setHtml(character);
	},

	setGlyphColor: function (color) {
		const me = this;
		if (color) me.iconEl.setStyle('color', color);
	},

	setActive: function (isActive) {
		const me = this;
		const activeCls = 'active';
		me._active = !!isActive;
		isActive ? me.addCls(activeCls) : me.removeCls(activeCls);
	},

	isActive: function () {
		const me = this;
		return me._active;
	},

	setError: function (isError) {
		const me = this;
		const errorCls = 'error';
		isError ? me.addCls(errorCls) : me.removeCls(errorCls);
	},

	validate: function (isActive) {
		const me = this;
		me.setActive(isActive);
		me.setError(!isActive);
		if (isActive) {
			me.setGlyph(edi.constants.ICONS.DONE);
		} else {
			me.setGlyph(edi.constants.ICONS.CLOSE);
		}
	}
});

const createCheckLabel = (config) => new UI.components.CheckLabel(config);

export { createCheckLabel };
