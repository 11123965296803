import { createCertificateHandler } from '@App/js/signature/handlers/createCertificateHandler';
import { createPoaHandler } from '@App/js/signature/handlers/createPoaHandler';
import { CertificateHandler, PoaHandler } from '@App/js/signature/difinitions';
import { containerCommonPricatSign } from '@Edi/modules/documents/CONTAINER_COMMON_PRICAT/containerCommonPricatSign.js';
import { dpContainerSign } from '@Edi/modules/documents/AGREEMENT_PACKAGES/methods';
import { isDSFV3 } from '@Edi/methods/methods';
import { DocumentCollection } from '@Fns/methods/createDocumentPart2';

export interface MassDocumentActionsProps {
	moduleData: MassDocumentActions['moduleData'];
	moduleTab: MassDocumentActions['moduleTab'];
	setModuleLoading: MassDocumentActions['setModuleLoading'];
}

export class MassDocumentActions {
	props: MassDocumentActionsProps;
	moduleData: ModuleData;
	moduleTab: ModuleData['tab'];
	setModuleLoading: (loading: boolean | string | AnyObject) => void;

	certificateHandler = createCertificateHandler();
	poaHandler = createPoaHandler();
	createdPart2DocIds: number[] = [];
	notHaveActiveCertType: DocumentHeader['type'][] = [];
	allActiveCerts: AnyObject = {};
	useActiveCerts: boolean = false;
	signProperties: AnyObject = {};
	_signActionHandler: MassDocumentActions['signActionHandler'];
	_beforeSignStart: MassDocumentActions['beforeSignStart'];
	_signCompleteCallback: MassDocumentActions['signCompleteCallback'];
	_annulReasonText: string = '';

	constructor(props: MassDocumentActionsProps) {
		const me = this;
		me.props = props;
		me.moduleData = me.props.moduleData;
		me.moduleTab = me.props.moduleTab;
		me.setModuleLoading = me.props.setModuleLoading;
		me._signActionHandler = me.signActionHandler.bind(me);
		me._beforeSignStart = me.beforeSignStart.bind(me);
		me._signCompleteCallback = me.signCompleteCallback.bind(me);
	}

	/**
	 * Before sing action
	 * @param	documents	{Object[]}	array of documents
	 * @param	callback	{Function}	finish callback
	 * @param	options		{Object}	options object
	 */
	beforeSignStart(documents: ExtRecord<DocumentHeader>[], callback: Function, options: AnyObject) {
		const me = this;
		//before start docFilter
		const processDocs: ExtRecord<DocumentHeader>[] = [];
		const noProcessDocs: ExtRecord<DocumentHeader>[] = [];
		documents?.forEach(function (doc) {
			const docData = doc.getData();
			const direction = edi.utils.getDocumentDirection(docData.toOrg, docData.fromOrg);
			if (
				edi.constants.DIRECTIONS.INCOMING === direction &&
				(docData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
					docData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD)
			) {
				const versionId = docData.versionId;
				if (edi.constants.ACTIVE_EDI_FNS_UPD_VERSIONS?.includes(versionId)) {
					processDocs.push(doc);
				} else {
					doc.tooltip = 'documents.error.upd.old.version';
					noProcessDocs.push(doc);
				}
			} else {
				processDocs.push(doc);
			}
		});
		documents = processDocs;
		options.noProcessDocs = noProcessDocs;
		options.data = processDocs;

		Object.assign(me.signProperties, {
			showActiveCertConfirm: !edi.constants.CERTIFICATE.ONLY_ACTIVE,
			doNotUseDefaultCert: false
		});

		const continueStart = function () {
			const docsNeedPart2: DocumentCollection =
				edi.methods.createDocumentPart2.createDocumentsCollectionFofSign();
			const continueMethod = function (part2Docs?: DocumentHeader[], successDocumentIds?: number[]) {
				if (Array.isArray(successDocumentIds) && successDocumentIds.length) {
					me.createdPart2DocIds = successDocumentIds;
				}
				edi.document.actions.defaultGridBeforeSignStart(documents, callback);
			};
			for (let i = 0; i < documents.length; i++) {
				const recordData = documents[i].getData();
				const direction = edi.utils.getDocumentDirection(recordData.toOrg, recordData.fromOrg);
				let totalNeedSignatures = 0;
				Object.values(edi.constants.CONTRACT_SIDES).forEach((side) => {
					let cnt = +edi.utils.getAttributeByName(recordData.attributes, `countSignature_${side}`) || 0;
					totalNeedSignatures += cnt;
				});
				const needSignatures = recordData.countSignatures < totalNeedSignatures;
				if (
					edi.constants.CREATE_PART2_BY_ACTION.some((it: string) => it === recordData.type) &&
					edi.constants.DIRECTIONS.INCOMING === direction &&
					recordData.state !== edi.constants.STATE.RECEIVER_REVIEW &&
					needSignatures
				) {
					const { type, versionId } = recordData;
					docsNeedPart2.addDocument({
						type,
						version: versionId,
						document: recordData
					});
				}
			}

			if (docsNeedPart2.size() > 0) {
				edi.methods.createDocumentPart2.start(
					docsNeedPart2,
					function (
						part2Docs: DocumentHeader[],
						successDocumentIds: number[],
						cert: CertificateHandler['_selectedCertificate'],
						poa: PoaHandler['_selectedPoa']
					) {
						me.certificateHandler.set(cert);
						me.poaHandler.set(poa);
						continueMethod(part2Docs, successDocumentIds);
					},
					options?.additionalCheckOptions
				);
			} else {
				continueMethod();
			}
		};

		//if signing several documents and allow active certs - looking for at least one doc with active cert
		if (documents.length > 1 && edi.constants.CERTIFICATE.ALLOW_ACTIVE) {
			me.getAllActiveCerts(function () {
				if (me.checkExistsActiveCertsForDocs(documents)) {
					let doNotUseNotificationDialogActCertPoa =
						edi.utils.getObjectProperty(
							edi.core.getUserData(),
							'userData.user.doNotUseNotificationDialogActCertPoa'
						) === 'true';
					if (!edi.constants.CERTIFICATE.ONLY_ACTIVE && !doNotUseNotificationDialogActCertPoa) {
						edi.core.confirm(
							null,
							'use.active.certificate.and.poa.to.sign.confirmation',
							function () {
								Object.assign(me.signProperties, { showActiveCertConfirm: false });
								me.useActiveCerts = true;
								continueStart();
							},
							function () {
								Object.assign(me.signProperties, {
									showActiveCertConfirm: false,
									doNotUseDefaultCert: true
								});
								me.useActiveCerts = false;
								continueStart();
							},
							undefined,
							function () {
								me.useActiveCerts = false;
								return false;
							}
						);
					} else {
						me.useActiveCerts = true;
						continueStart();
					}
				} else {
					continueStart();
				}
			});
		} else {
			continueStart();
		}
	}

	/**
	 * Creates callback for sign
	 * @param    {Object}    record      grid row record
	 * @param    {Object}    grid        grid where from we receive records
	 * @param    {Function}  callback    callback on success
	 * @param    {Function}  failure     callback on failure
	 * @param	 {Object}	 options	 additional options
	 */

	signActionHandler(
		record: ExtRecord<DocumentHeader>,
		grid: ExtComponent,
		callback: Function,
		failure: Function,
		options: AnyObject,
		defaultEventObj?: unknown,
		additionalHandler?: unknown,
		getProcessorState?: () => {
			signedDocs: ExtRecord<DocumentHeader>[];
			notSignedDocs: ExtRecord<DocumentHeader>[];
		}
	) {
		const me = this;
		return function () {
			const recordData = record.getData();
			let realType = recordData.type;
			const direction = edi.utils.getDocumentDirection(recordData.toOrg, recordData.fromOrg);
			const beforeSign = function (success: Function, failure: Function) {
				const data = {};
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.SEND.PUT,
						{
							documentId: recordData.id
						},
						true
					),
					'PUT',
					Ext.encode(data),
					success,
					failure
				);
			};

			const signCallback = function (
				failed?: boolean,
				errorData?: AnyObject,
				cert?: CertificateHandler['_selectedCertificate'],
				poa?: PoaHandler['_selectedPoa'],
				silent?: boolean
			) {
				//silent==true значит, что юзер нажал отмену в окне заявления в ФНС
				//значит нам надо отменить весь процесс подписания, т.е. просто не вызываем колбэки и снимаем лоадер
				if (silent === true) {
					me.setModuleLoading(false);
					me.clearSelectedCertAndPoa();
					return;
				}

				const gridSelModel = grid.getSelectionModel();
				const continueMethod = function () {
					edi.events.documents.fireEvent('sign', recordData);
					//по этому флагу можно понимать какие рекорды были реально подписаны (по аналогии с наличием errorData)
					//актуальные данные на каждом подписании можно получить хуком из хэндлера см. document.actions.js
					record.successfullySigned = true;
					gridSelModel?.deselect(record);
					callback && 'function' == typeof callback ? callback() : null;
				};
				if (failed) {
					edi.core.logMessage(
						'Error occurred during signing of document ' +
							edi.i18n.getMessage('documents.doctype.' + recordData.type) +
							' ' +
							recordData.number,
						'warn'
					);
					record.errorData = errorData;
					failure(record, errorData);
					gridSelModel?.deselect(record);
					callback && 'function' == typeof callback ? callback() : null;
				} else {
					if (
						'object' == typeof edi.constants.PUSH_AFTER_SIGN_RULES &&
						edi.constants.PUSH_AFTER_SIGN_RULES.DOCUMENT_TYPES.some(
							(it: string) => it === recordData.type
						) &&
						edi.constants.PUSH_AFTER_SIGN_RULES.STATES.some((it: string) => it === recordData.state)
					) {
						beforeSign(continueMethod, failure);
					} else {
						continueMethod();
					}
				}
			};
			const startSign = function (contentId?: number, beforeSign?: Function | null) {
				Object.assign(me.signProperties, {
					signObjectProcessor: function (signature: string) {
						const signObj: AnyObject = {};
						signObj[edi.constants.BUSINESS_PROCESS_PROPERTIES.SIGNATURE] = signature;
						if (edi.methods.documents.needToAddTaskId(recordData)) {
							signObj[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = recordData.state;
						}
						return signObj;
					},
					signRefuse: function () {
						me.setModuleLoading(false);
						grid.getStore().reload();
						grid.getSelectionModel()?.deselectAll();
					}
				});

				const nextActionStartSign = function () {
					const sign = function () {
						const signProperties = Ext.clone(me.signProperties);
						if (recordData.type === edi.constants.DOCUMENT_TYPES.COMMON_PRICAT) {
							signProperties.signContentUrl = edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SIGN.SIGN_TRANSFORM_CONTENT.PUT,
								{
									documentId: recordData.id
								}
							);
							signProperties.signContentUrlMethod = 'PUT';
						}
						if (
							recordData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD &&
							recordData.versionId === '5.02-N'
						) {
							signProperties.sendPoaForSignContent = true;
						}
						if (options.signingPricatRejectDocs) {
							edi.utils.sign(
								recordData,
								me.moduleTab,
								signCallback,
								undefined,
								undefined,
								true,
								me.certificateHandler,
								{
									signContentUrl: options.signUrl,
									signRefuse: () => me.setModuleLoading(false)
								},
								me.poaHandler
							);
						} else {
							edi.utils.sign(
								recordData,
								me.moduleTab,
								signCallback,
								contentId,
								beforeSign,
								false,
								me.certificateHandler,
								me.signProperties,
								me.poaHandler
							);
						}
					};
					//case when we are signing a several docs and using an active certificates
					if (me.useActiveCerts) {
						const currentCert = me.findActiveCertForDocType(recordData.type);
						if (currentCert) {
							edi.sign.getCertificates({
								callback: function (data: AnyObject) {
									const cert = edi.utils.findObjectInArray(
										data.data,
										edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.STORE,
										currentCert.data[edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.DB]
									);
									if (cert) {
										me.certificateHandler.set(cert, currentCert.data);
										if (currentCert?.poaData?.header) {
											me.poaHandler.set(currentCert.poaData.header);
										}
										sign();
									} else {
										signCallback();
									}
								}
							});
						} else {
							me.notHaveActiveCertType.push(recordData.type);
							signCallback();
						}
					} else {
						sign();
					}
				};

				if (
					'object' == typeof edi.constants.BEFORE_SIGN_PARENT_DOC &&
					edi.constants.BEFORE_SIGN_PARENT_DOC.some((it: string) => it === recordData.type)
				) {
					const signIzv = function (id: DocumentHeader['id']) {
						Object.assign(me.signProperties, {
							signUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, { documentId: id })
						});
						nextActionStartSign();
					};
					const getParentDocId = function (parentData: AnyObject) {
						const parentDocId = parentData.data.parent.id;
						signIzv(parentDocId);
					};
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
							documentId: recordData.id
						}),
						'GET',
						undefined,
						getParentDocId
					);
				} else if (
					'object' == typeof edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES &&
					edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.DOCUMENT_TYPES.some(
						(it: string) => it === recordData.type
					) &&
					edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.STATES.some((it: string) => it === recordData.state)
				) {
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: recordData.id
							},
							true
						),
						'PUT',
						Ext.encode({}),
						nextActionStartSign
					);
				} else if (isDSFV3(recordData)) {
					me.signIzvPol(
						recordData,
						function (
							cert: CertificateHandler['_selectedCertificate'],
							poa: PoaHandler['_selectedPoa'],
							poaConfirmChecked: PoaHandler['_poaConfirmChecked']
						) {
							if (!!cert) {
								me.certificateHandler.set(cert);
								if (poaConfirmChecked) {
									me.poaHandler.setPoaConfirmCheck(poaConfirmChecked);
								}
							}
							nextActionStartSign();
						}
					);
				} else {
					nextActionStartSign();
				}
			};

			if (edi.constants.PARTS_DOC_TYPES.hasOwnProperty(recordData.type)) {
				const objKey =
					edi.constants.STATE.SENDER_REVIEW === recordData.state ||
					edi.constants.STATE.DRAFT === recordData.state
						? 'P1'
						: [
								edi.constants.STATE.RECEIVER_REVIEW,
								edi.constants.STATE.DEBTOR_REVIEW,
								edi.constants.STATE.READ
						  ].some((it) => it === recordData.state) ||
						  me.createdPart2DocIds.some((it) => it === recordData.id)
						? 'P2'
						: null;

				if (objKey) {
					realType = edi.constants.PARTS_DOC_TYPES[recordData.type][objKey];
				}
			}

			const loading = edi.utils.formatString(edi.i18n.getMessage('document.sign.loading.message'), {
				type: edi.i18n.getMessage('documents.doctype.' + recordData.type),
				number: recordData.number
			});
			me.setModuleLoading(loading);

			if (
				!options.signingPricatRejectDocs &&
				Array.isArray(edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES) &&
				edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES.some((it: string) => it === recordData.type)
			) {
				if (edi.constants.PARTS_DOC_TYPES.hasOwnProperty(recordData.type)) {
					const startSignPart = function (docPartType: DocumentHeader['type']) {
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
								documentId: recordData.id
							}),
							'GET',
							undefined,
							function (data: AnyObject) {
								if (data && data.data && data.data.children && data.data.children.length) {
									let headData;
									for (let i = 0; i < data.data.children.length; i++) {
										if (data.data.children[i].type === docPartType) {
											headData = data.data.children[i];
											break;
										}
									}
									if (headData) {
										startSign(
											headData.id,
											recordData.state === edi.constants.STATE.DRAFT ||
												recordData.state === edi.constants.STATE.READ ||
												me.createdPart2DocIds.some((it) => it === recordData.id)
												? beforeSign
												: null
										);
									} else {
										signCallback(true);
									}
								} else {
									signCallback(true);
								}
							},
							function () {
								signCallback(true);
							}
						);
					};
					if (
						edi.constants.DIRECTIONS.OUTGOING === direction ||
						recordData.hasPart2 ||
						me.createdPart2DocIds.some((it) => it === recordData.id)
					) {
						startSignPart(realType);
					} else {
						signCallback(true);
					}
				} else if (
					recordData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER ||
					recordData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR ||
					recordData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE
				) {
					dpContainerSign(recordData, me.moduleTab);
				} else if (recordData.type === edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT) {
					containerCommonPricatSign(recordData, me.moduleTab);
				} else {
					let useBeforeSign =
						recordData.state === edi.constants.STATE.DRAFT ||
						recordData.state === edi.constants.STATE.READ ||
						me.checkBeforeSignRules(recordData.type, recordData.state);
					if (
						[edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION].includes(
							edi.utils.getAttributeByName(recordData.attributes, 'bpName')
						)
					) {
						useBeforeSign = false;
					}
					startSign(undefined, useBeforeSign ? beforeSign : null);
				}
			} else {
				startSign();
			}
		};
	}

	checkBeforeSignRules(type: DocumentHeader['type'], state: DocumentHeader['state']) {
		let isAllow = function (rule: AnyObject) {
			let typeIsOk =
				rule.DOCUMENT_TYPES === undefined ||
				(typeof rule.DOCUMENT_TYPES === 'string' && rule.DOCUMENT_TYPES === type) ||
				(Array.isArray(rule.DOCUMENT_TYPES) && rule.DOCUMENT_TYPES.some((it: string) => it === type));

			let stateIsOk =
				rule.STATES === undefined ||
				(typeof rule.STATES === 'string' && rule.STATES === state) ||
				(Array.isArray(rule.STATES) && rule.STATES.some((it: string) => it === state));

			return typeIsOk && stateIsOk;
		};

		let rules = edi.constants.USE_BEFORE_SIGN_RULES;
		if (Array.isArray(rules)) {
			return rules.some(isAllow);
		} else if ('object' == typeof rules) {
			return isAllow(rules);
		} else {
			return true;
		}
	}

	/**
	 * Check that any active certificate contains at least one document type from records
	 * @param {Array} records
	 * @returns {boolean}
	 */
	checkExistsActiveCertsForDocs(records: ExtRecord<DocumentHeader>[]) {
		const me = this;
		for (let i = 0; i < records.length; i++) {
			const recordData = records[i].getData();
			if (me.findActiveCertForDocType(recordData.type)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Find all active certificates for current user
	 * @param {function} callback
	 */
	getAllActiveCerts(callback: Function) {
		const me = this;
		edi.sign.getCertificates({
			callback: function (resp: AnyObject) {
				edi.sign.prefetchedCertificates = resp.data;

				me.allActiveCerts = {};
				edi.rest.sendRequest(
					edi.rest.services.USER.SELF.CERTIFICATE.LIST.GET,
					'GET',
					{ gridName: 'without_deactivated' },
					function (data: AnyObject) {
						if (data && data.items && data.items.length) {
							const certs = data.items;
							for (let i = 0; i < certs.length; i++) {
								if (certs[i].header.state === 'ACTIVE') {
									certs[i].data.availableDocTypesForSignature.forEach(function (
										docType: DocumentHeader['type']
									) {
										me.allActiveCerts[docType] = certs[i];
									});
								}
							}
						}
						callback();
					},
					edi.document.actions.defaultFailureHandler(me.moduleTab, 'error.getting.data')
				);
			}
		});
	}

	/**
	 * Find active certificate for document type
	 * @param {String} docType
	 * @returns {null|Object}
	 */
	findActiveCertForDocType(docType: DocumentHeader['type']) {
		const me = this;
		if (me.allActiveCerts.hasOwnProperty(docType)) {
			return me.allActiveCerts[docType];
		}
		if (me.allActiveCerts.hasOwnProperty('ALL')) {
			return me.allActiveCerts['ALL'];
		}
		return null;
	}

	/**
	 * Подписывает уведомления для ДСФ
	 * @param {*} record
	 * @param {*} signData
	 * @param {*} callback
	 */
	beforeSignActionHandler(record: ExtRecord<DocumentHeader>, signData: AnyObject, callback: Function) {
		const me = this;
		edi.utils.sign(
			signData.data,
			me.moduleTab,
			function (failed: boolean, data: AnyObject) {
				if (!failed) {
					edi.events.documents.fireEvent('sign', record.getData());
				}
				callback(failed, data);
			},
			undefined,
			undefined,
			false,
			me.certificateHandler,
			undefined,
			me.poaHandler
		);
	}

	/**
	 * Получаем ИоП и подписываем
	 * @param {object} document
	 * @param {function} callback
	 */
	signIzvPol(document: DocumentHeader, callback: Function) {
		const me = this;
		let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.TREE.GET, {
			depth: edi.constants.DEFAULT.TREE_DEPTH,
			documentId: document.id
		});
		let sign = (document: DocumentHeader, izvPol: DocumentHeader) => {
			edi.utils.sign(
				document,
				me.moduleTab,
				function (
					failed: boolean,
					responseData: AnyObject,
					certificate: CertificateHandler['_selectedCertificate'],
					poa: PoaHandler['_selectedPoa'],
					silent: boolean,
					poaConfirmChecked: boolean
				) {
					if (failed) {
						callback(certificate, poa, poaConfirmChecked);
						edi.core.showError(edi.utils.formatComplexServerError(responseData));
					} else {
						callback(certificate, poa, poaConfirmChecked);
					}
				},
				undefined,
				undefined,
				undefined,
				me.certificateHandler,
				{
					signContentUrl: edi.utils.formatString(
						'AB' === edi.login.getAuthType()
							? edi.rest.services.DOCUMENTS.SIGN_AB.GET
							: edi.rest.services.DOCUMENTS.SIGN.GET,
						{
							documentId: izvPol.id
						}
					),
					signContentUrlMethod: 'PUT'
				},
				me.poaHandler
			);
		};
		let success = (responseData: AnyObject) => {
			if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
				let linkedDocs = responseData.items,
					izvPol = linkedDocs.find(function (d) {
						return (
							d.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER ||
							d.state === edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL
						);
					});
				if (izvPol) {
					sign(document, izvPol);
				} else {
					callback();
				}
			} else {
				callback();
			}
		};
		edi.rest.sendRequest(url, 'GET', undefined, success, success);
	}

	clearSelectedCertAndPoa() {
		const me = this;
		me.certificateHandler.set(null, null);
		me.poaHandler.set(null);
		me.poaHandler.setPoaConfirmCheck(false);
		me.onClearSelectedCertAndPoa();
	}

	onClearSelectedCertAndPoa() {}

	/**
	 * Sign action complete callback
	 */
	signCompleteCallback() {
		const me = this;
		if (me.notHaveActiveCertType.length > 0) {
			let msgTypes = '';
			for (let i = 0; i < me.notHaveActiveCertType.length; i++) {
				if (i + 1 === me.notHaveActiveCertType.length) {
					msgTypes += edi.i18n.getMessage('documents.doctype.' + me.notHaveActiveCertType[i]) + ' ';
				} else {
					msgTypes += edi.i18n.getMessage('documents.doctype.' + me.notHaveActiveCertType[i]) + ', ';
				}
			}

			edi.core.showInfo(
				edi.i18n.getMessage('sign.document.types.not.available', {
					types: msgTypes
				}),
				undefined,
				undefined,
				{
					closable: false
				}
			);
			me.notHaveActiveCertType = [];
		}
		me.useActiveCerts = false;
		me.clearSelectedCertAndPoa();
	}

	getClarifyActionConfig(initialReasonText?: string, opts?: { additionalPostProps?: AnyObject }) {
		const me = this;
		let utochReason = initialReasonText ?? '';
		let inputReason = function (val: string) {
			utochReason = val;
		};
		return {
			processDocumentCallback: me.beforeSignActionHandler.bind(me),
			processUrl: edi.rest.services.DOCUMENTS.CREATE_UTOCH.POST,
			processMethod: 'POST',
			processUrlParamCollector: function (record: ExtRecord<DocumentHeader>) {
				return {
					parentId: record.get('id')
				};
			},
			processProperties: function () {
				return Object.assign(
					{
						reason: utochReason
					},
					opts?.additionalPostProps
				);
			},
			loadingMessage: 'document.mark.clarify.started',
			confirmTitle: 'document.mark.clarify',
			confirmAction: true,
			beforeStart: function (data: ExtRecord<DocumentHeader>[], afterStart: Function) {
				if (utochReason) {
					afterStart();
				} else {
					edi.document.actions.createGridRejectReasonDialog(data, afterStart, {
						setReason: inputReason,
						fieldTitle: 'document.mark.clarify.reason',
						buttonOkTitle: 'form.btn.send',
						title: 'document.mark.clarify'
					});
				}
			},
			completeCallback: function (
				signedDocs: ExtRecord<DocumentHeader>[],
				notSignedDocs: ExtRecord<DocumentHeader>[]
			) {
				me.clearSelectedCertAndPoa();
			}
		};
	}

	/**
	 * Reject action complete callback
	 * sign all EDI_PRICAT_EANCOM_DP_UVUTOCH
	 */
	rejectCompleteCallback(grid: ExtComponent, records: ExtRecord<DocumentHeader>[]) {
		const me = this;
		const pricatEancomDocs = records.filter(function (r) {
			return r.get('type') === edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM;
		});
		if (!pricatEancomDocs.length) {
			me.clearSelectedCertAndPoa();
			return;
		}

		const signRejectDocs = function () {
			edi.document.actions.documentGridActionProcess({
				grid: grid,
				moduleData: me.moduleData,
				action: edi.constants.DOCUMENT_ACTIONS.SIGN,
				initialData: pricatEancomDocs,
				actionCheckCallback: function () {
					return true;
				},
				handler: function (
					record: ExtRecord<DocumentHeader>,
					grid: ExtComponent,
					callback: Function,
					failure: Function
				) {
					const options = {
						signingPricatRejectDocs: true,
						signUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.GET, {
							documentId: rejectDocIdsMap[record.get('id')]
						})
					};
					return me._signActionHandler(record, grid, callback, failure, options);
				},
				beforeStart: me._beforeSignStart,
				loadingMessage: 'document.sign.started',
				forceCompleteEvent: true,
				completeCallback: me._signCompleteCallback
			});
		};

		const rejectDocIdsMap: { [parentId: DocumentHeader['id']]: DocumentHeader['id'] } = {}; //{parentId: rejectDocId}
		let finishedCount = 0;
		const addDoc = function (parentId?: DocumentHeader['id'], rejectDocId?: DocumentHeader['id']) {
			if (parentId && rejectDocId) {
				rejectDocIdsMap[parentId] = rejectDocId;
			}
			finishedCount++;
			if (finishedCount === pricatEancomDocs.length) {
				signRejectDocs();
			}
		};
		pricatEancomDocs.forEach(function (d) {
			if (edi.utils.getAttributeByName(d.get('attributes'), 'INTEGRATION') === 'true') {
				const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.TREE.GET, {
					depth: edi.constants.DEFAULT.TREE_DEPTH,
					documentId: d.get('id')
				});
				edi.rest.sendRequest(
					url,
					'GET',
					{},
					function (responseData: AnyObject) {
						const linkedDocs: DocumentHeader[] = (responseData && responseData.items) || [];
						const rejectDoc = linkedDocs.find(function (d) {
							return d.type === edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_UVUTOCH;
						});
						if (rejectDoc && rejectDoc.id) {
							addDoc(d.get('id'), rejectDoc.id);
						}
					},
					function () {
						addDoc(undefined, undefined);
					}
				);
			}
		});
	}

	getRejectActionConfig(grid: ExtComponent): AnyObject {
		const me = this;
		let reasonValue = '';
		const inputReason = (val: string) => (reasonValue = val);

		return {
			getReason: function () {
				return reasonValue;
			},
			processDocumentCallback: function (
				record: ExtRecord<DocumentHeader>,
				signData: AnyObject,
				callback: Function
			) {
				let recordData = record.getData();
				if (isDSFV3(recordData)) {
					me.signIzvPol(
						recordData,
						function (
							cert: CertificateHandler['_selectedCertificate'],
							poa: PoaHandler['_selectedPoa'],
							poaConfirmChecked: PoaHandler['_poaConfirmChecked']
						) {
							if (!!cert) {
								me.certificateHandler.set(cert);
								if (poaConfirmChecked) {
									me.poaHandler.setPoaConfirmCheck(poaConfirmChecked);
								}
							}
							me.beforeSignActionHandler(record, signData, callback);
						}
					);
				} else {
					callback();
				}
			},
			beforeStart: function (data: ExtRecord<DocumentHeader>[], afterStart: Function) {
				for (let i = 0; i < data.length; i++) {
					const recordData = data[i].getData();
					if (recordData.type === edi.constants.DOCUMENT_TYPES.DELFOR) {
						afterStart();
					}
					if (i === data.length - 1 && recordData.type !== edi.constants.DOCUMENT_TYPES.DELFOR) {
						edi.document.actions.createGridRejectReasonDialog(data, afterStart, {
							setReason: inputReason,
							title: 'document.reject.reason.title.grid'
						});
					}
				}
			},
			processUrl: function (record: ExtRecord<DocumentHeader>) {
				return isDSFV3(record.getData())
					? edi.rest.services.DOCUMENTS.DSF_REJECT.POST
					: edi.rest.services.DOCUMENTS.SEND.PUT;
			},
			processMethod: function (record: ExtRecord<DocumentHeader>) {
				return isDSFV3(record.getData()) ? 'POST' : 'PUT';
			},
			processUrlParamCollector: function (record: ExtRecord<DocumentHeader>) {
				return isDSFV3(record.getData())
					? {
							parentId: record.get('id')
					  }
					: {
							documentId: record.get('id')
					  };
			},
			processProperties: function (record: ExtRecord<DocumentHeader>) {
				const props: AnyObject = {};
				if (isDSFV3(record.getData())) {
					props['reason'] = reasonValue;
				} else if (record.get('type') === edi.constants.DOCUMENT_TYPES.DELFOR) {
					const direction = edi.utils.getDocumentDirection(record.get('toOrg'), record.get('fromOrg'));
					if (edi.constants.DIRECTIONS.OUTGOING === direction) {
						props[edi.constants.BUSINESS_PROCESS_PROPERTIES.SENDER_REJECT] = true;
					} else {
						props[edi.constants.BUSINESS_PROCESS_PROPERTIES.RECEIVER_REJECT] = true;
					}
				} else {
					props[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT] = true;
					props[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT_REASON] = reasonValue;
				}
				return props;
			},
			processEvent: {
				object: 'documents',
				event: 'reject'
			},
			loadingMessage: 'document.reject.started',
			completeCallback: function (
				signedDocs: ExtRecord<DocumentHeader>[],
				notSignedDocs: ExtRecord<DocumentHeader>[]
			) {
				signedDocs = signedDocs.filter(function (r) {
					return isDSFV3(r.getData());
				});
				if (signedDocs.length) {
					me._signCompleteCallback();
				} else {
					me.rejectCompleteCallback(grid, signedDocs);
				}
				reasonValue = '';
			}
		};
	}

	reprocessDocument(
		record: ExtRecord<DocumentHeader>,
		grid: ExtComponent,
		callback: Function,
		failure: Function,
		_options: AnyObject
	) {
		return function () {
			let gridSelModel = grid.getSelectionModel();
			const recordData = record.getData();
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.REPROCESS.PUT,
					{
						documentId: recordData.id
					},
					true
				),
				'PUT',
				undefined,
				function () {
					edi.events.documents.fireEvent('change', {
						id: recordData.id
					});
					gridSelModel?.deselect(record);
				},
				(error: AnyObject) => failure(record, error),
				callback
			);
		};
	}

	createAnnulActionHandler(
		record: ExtRecord<DocumentHeader>,
		grid: ExtComponent,
		callback: Function,
		failure: Function,
		moduleData: ModuleData
	) {
		const me = this;
		return function () {
			edi.document.actions.handlerCreateAnnulDocument({
				tab: me.moduleTab,
				record,
				docHeader: record.getData(),
				success: function (resp: AnyObject, docId: DocumentHeader['id']) {
					callback();
				},
				failure: function (resp: AnyObject, docId: DocumentHeader['id']) {
					record.errorData = resp;
					failure(record, resp);
					callback();
				},
				options: {
					closeOnCancel: true,
					certificateHandler: me.certificateHandler,
					poaHandler: me.poaHandler,
					getReasonText: () => me._annulReasonText,
					setReasonText: (reasonText: string) => (me._annulReasonText = reasonText)
				}
			});
		};
	}

	approveAnnulActionHandler(
		record: ExtRecord<DocumentHeader>,
		grid: ExtComponent,
		callback: Function,
		failure: Function,
		moduleData: ModuleData
	) {
		const me = this;
		return function () {
			edi.document.actions.handlerApproveAnnulDocument({
				record,
				docHeader: record.getData(),
				success: function (resp: AnyObject, docId: DocumentHeader['id']) {
					callback();
				},
				failure: function (resp: AnyObject, docId: DocumentHeader['id']) {
					record.errorData = resp;
					failure(record, resp);
					callback();
				},
				options: {
					certificateHandler: me.certificateHandler,
					poaHandler: me.poaHandler
				}
			});
		};
	}

	rejectAnnulActionHandler(
		record: ExtRecord<DocumentHeader>,
		grid: ExtComponent,
		callback: Function,
		failure: Function,
		moduleData: ModuleData
	) {
		const me = this;
		return function () {
			edi.document.actions.handlerRejectAnnulDocument({
				record,
				docHeader: record.getData(),
				success: function (resp: AnyObject, docId: DocumentHeader['id']) {
					callback();
				},
				failure: function (resp: AnyObject, docId: DocumentHeader['id']) {
					record.errorData = resp;
					failure(record, resp);
					callback();
				},
				options: {
					certificateHandler: me.certificateHandler,
					poaHandler: me.poaHandler,
					getReasonText: () => me._annulReasonText,
					setReasonText: (reasonText: string) => (me._annulReasonText = reasonText)
				}
			});
		};
	}

	annulCompleteCallback() {
		const me = this;
		me._annulReasonText = '';
		me.clearSelectedCertAndPoa();
	}
}
