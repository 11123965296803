import { filterMethods } from './filter';
import { DOCUMENT_PACKAGES_MODEL_CONFIG_NAME } from './models';
import { DOCUMENT_PACKAGES_LINK_WINDOW_COLUMNS_NAME } from './columns';
import { documentPackageApi, DocumentPackageHeader, DocumentPackageParty } from './entities';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';

export interface LinkDocumentsToPackageWindowProps {
	documents: number[];
	toOrgId: DocumentPackageParty['id'];
	success?: (resp: AnyObject) => void;
	failure?: (resp: AnyObject) => void;
}

export class LinkDocumentsToPackageWindow {
	props: LinkDocumentsToPackageWindowProps;
	modal: ExtComponent;
	modalTitleKey = 'document.packages';

	constructor(props: LinkDocumentsToPackageWindowProps) {
		const me = this;
		me.props = props;
		me.createModal();
	}

	getErrorCallback(): LinkDocumentsToPackageWindowProps['failure'] {
		const me = this;
		return me.props.failure ?? edi.rest.getErrorHandler();
	}

	async selectHandler(record: DocumentPackageHeader) {
		const me = this;
		const packageId = record.id;
		const { success, response } = await documentPackageApi.linkDocuments(packageId, me.props.documents);
		if (success) {
			documentPackageMethods.eventsObserver.fireEvent('changeDocuments', { packageId });
			me.props.success?.(response);
		} else {
			me.getErrorCallback()?.(response);
		}
	}

	getUrl(): string {
		return edi.utils.compileURL(documentPackageApi.endpoints.GET_PACKAGES, {
			gridName: 'forlink'
		});
	}

	createModal(): ExtComponent {
		const me = this;
		const { formItemsMap, items } = filterMethods.createPackagesFormItems();
		me.modal = createModalRemoteSelect(me.getUrl(), me.selectHandler.bind(me), {
			title: me.modalTitleKey,
			width: MODAL_SIZE.widthLarge,
			model: DOCUMENT_PACKAGES_MODEL_CONFIG_NAME,
			columns: DOCUMENT_PACKAGES_LINK_WINDOW_COLUMNS_NAME,
			storeConfig: {
				autoLoad: true
			},
			proxyConfig: {
				extraParams: {
					toOrg: me.props.toOrgId
				}
			},
			createFilterFormItems: items,
			createFormItemsMap: formItemsMap,
			createArgs: filterMethods.createArgsPackages
		}) as ExtComponent;
		return me.modal;
	}

	show() {
		const me = this;
		me.modal.show();
	}
}
