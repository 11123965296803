// @ts-ignore
import { createModalPanel, MODAL_CLS, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createContainer, createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createTextField } from '@Components/fields';
import { POA_CONST } from '@Edi/modules/power_of_attorney/configuration';
// @ts-ignore
import { BUTTON_CLS, createButton, createTool } from '@UIkit/components/buttons';
// @ts-ignore
import { createGrid } from '@UIkit/components/grid';
import { createProxyConfig } from '@Components/storeComponents';
// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
import { SignProcess } from '@App/js/signature/signProcess';
import { showInfoToast } from '@Ediweb/core';
import { CertificateHandler, PoaHandler } from '@App/js/signature/difinitions';

export class Acceptance {
	url: string;
	isShowFromDocs: Boolean | undefined = false;
	isShowFromMain: Boolean | undefined = false;
	isShowFromTpl: Boolean | undefined = false;
	successClose: boolean;
	isSignDoc: boolean;
	modal: ExtComponent | null;
	acceptanceData: AnyObject;
	certificateData: AnyObject;
	options: AnyObject = {};
	callback: (data?: AnyObject) => void; //глобальный callback из BEFORE_LOAD_MODULES
	guidFormPanel: ExtComponent;
	guidModal: ExtComponent;
	signData: AnyObject;
	signProcessContext: SignProcess;

	refuse: (b: boolean) => void;

	constructor(callback: (data?: AnyObject) => void, url: string | undefined, options: AnyObject) {
		const me = this;

		//edi.core.setExtraData('user.acceptance', true);

		me.modifyConfig(callback, url, options);
	}

	modifyConfig(callback: (data?: AnyObject) => void, url: string | undefined, options: AnyObject) {
		const me = this;
		me.callback = callback;
		me.options = options;
		if (options.isShowFromDocs) {
			me.isShowFromDocs = options.isShowFromDocs;
		}

		if (options.isShowFromMain) {
			me.isShowFromMain = options.isShowFromMain;
		}

		if (options.isShowFromTpl) {
			me.isShowFromTpl = options.isShowFromTpl;
		}

		if (options.isShowFromMain) {
			me.url = url ?? edi.rest.services.ACCEPTANCE.CLIENT.GET;
		}
		me.url = url ?? edi.rest.services.ACCEPTANCE.GET;
	}

	fail(response: AnyObject) {
		const me = this;
		edi.document.actions.defaultFailureHandler(me.modal, 'error.getting.data')(response);
	}

	failure(data: AnyObject) {
		const me = this;

		edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
			'function' == typeof me.refuse ? me.refuse(me.successClose) : null;
		});
	}

	success(resp: AnyObject) {
		const me = this;
		if (resp.data !== true) {
			me.acceptanceData = resp.data;
		}
		if (typeof me.options.onSuccess === 'function') {
			me.options.onSuccess(resp, me);
		} else {
			me.onSuccess(resp);
		}
	}

	onSuccess(resp: AnyObject) {
		const me = this;

		if (resp.data === true) {
			if ('function' == typeof me.callback) {
				me.callback();
			}
		} else {
			me.showAcceptanceFromTpl();
		}
	}

	showAcceptanceFromTpl() {
		const me = this;

		const showWindow = function (htmlContent: String) {
			const buttons = [];

			const htmlContainer = createContainer({
				html: htmlContent
			});

			let btn = createButton({
				text: edi.i18n.getMessage('form.btn.sign'),
				cls: BUTTON_CLS.primary,
				handler: me.signAcceptance.bind(me)
			});

			let cancelBtn = createButton({
				text: edi.i18n.getMessage('form.btn.close'),
				cls: BUTTON_CLS.secondary,
				handler: function () {
					me.modal?.close();
				}
			});

			if (!me.isShowFromDocs) {
				buttons.push(btn);
			}

			if (me.isShowFromDocs) {
				buttons.push(cancelBtn);
			}

			me.modal = createModalPanel(
				{
					cls: me.isShowFromDocs ? [MODAL_CLS.withShadows] : '',
					title: me.isShowFromDocs ? edi.i18n.getMessage('documents.doctype.REGISTRATION_ACCEPTANCE') : null,
					bodyPadding: '40 4 0 0',
					hideTitle: !me.isShowFromDocs,
					closable: me.isShowFromDocs,
					draggable: false,
					modal: false,
					verticalMargin: 72,
					width: MODAL_SIZE.widthLarge,
					items: [htmlContainer],
					buttons: !me.isShowFromDocs ? buttons : null
				},
				true
			);
			me.modal?.show();
		};

		const fail = edi.rest.getErrorHandler('ediweb.personal.consent.error');

		const success = function (resp: AnyObject) {
			if (resp.data) {
				showWindow(resp.data);
			} else {
				fail(resp);
			}
		};

		const url = edi.utils.formatString(edi.rest.services.ACCEPTANCE.TEMPLATE.GET, {
			id: me.acceptanceData.header,
			type: 'REGISTRATION_ACCEPTANCE_MESSAGE'
		});
		edi.rest.sendRequest(url, 'GET', null, success, fail);
	}

	uploadButtonHandler() {
		const me = this;
		let form = me.guidFormPanel.getForm();
		if (form.isValid()) {
			let values = edi.utils.collectFormValues(form);
			const url = me.options.importByGuidUrl ?? edi.rest.services.ACCEPTANCE.IMPORT_BY_GUID.POST;
			edi.rest.sendRequest(
				edi.utils.formatString(url, {
					headerId: me.acceptanceData.header
				}),
				'POST',
				Ext.encode({
					poaNumber: values.poaNumber,
					poaIssuerInn: me.acceptanceData.inn,
					kpp: me.acceptanceData.kpp,
					gln: null,
					orgType: edi.constants.ORGANIZATION_TYPES.PERSON,
					content: 'test',
					sign: me.signData
				}),
				function () {
					showInfoToast(
						edi.i18n.getMessage('acceptance.poa.loaded.toast.title'),
						edi.i18n.getMessage('acceptance.poa.loaded.toast.content')
					);
					me.guidModal.close();
				},
				function (data: AnyObject) {
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'), null, null, {
						okBtnConfig: {
							text: edi.i18n.getMessage('btn.understand'),
							glyph: edi.constants.ICONS.DONE
						},
						title: 'poa.not.loaded'
					});
				},
				function () {
					'function' == typeof me.refuse ? me.refuse(false) : null;
				}
			);
		}
	}

	guidImportHandler() {
		const me = this;

		me.guidModal = createModalPanel({
			title: edi.i18n.getMessage('power.of.attorney.import.by.guid'),
			width: MODAL_SIZE.widthSmall,
			items: [
				(me.guidFormPanel = createModalForm({
					submitEmptyText: false,
					items: [
						createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							items: [
								createTextField({
									fieldLabel: edi.i18n.getMessage('power.of.attorney.import.by.guid.label'),
									regex: POA_CONST.NUMBER_VALIDATOR,
									name: 'poaNumber',
									allowBlank: false
								})
							]
						})
					]
				}))
			],
			buttonsBefore: [
				createButton({
					text: edi.i18n.getMessage('add.power.of.attorney.btn'),
					glyph: edi.constants.ICONS.SAVE,
					cls: BUTTON_CLS.primary,
					bindToForm: me.guidFormPanel,
					handler: me.uploadButtonHandler.bind(me)
				})
			],
			closeButtonOptions: {
				hidden: true
			}
		});

		me.guidFormPanel.isValid();
		me.guidModal.show();
	}

	signCallback(
		failed: Boolean,
		data: AnyObject,
		selectedCertificate: AnyObject,
		selectedPoa: AnyObject,
		silent: Boolean
	) {
		const me = this;
		if (failed) {
			me.fail(data);
		} else {
			edi.constants.ENABLE_CERTIFICATE_APPLICATION_CHECKING = true;
			edi.methods.application_to_fns.signApplicationAndCert(
				{
					_selectedCertificate: selectedCertificate,
					get: function () {
						return this._selectedCertificate;
					},
					set: function (certificate: AnyObject) {
						this._selectedCertificate = certificate;
					}
				},
				false,
				function (
					failed: boolean,
					data?: AnyObject,
					cert?: CertificateHandler['_selectedCertificate'],
					poa?: PoaHandler['_selectedPoa'],
					silent?: boolean
				) {
					if (!failed) {
						me.callback(me.acceptanceData);
					}
				},
				{
					isSilentSign: true,
					maskEl: me.modal,
					skipMaskHandling: true,
					selectedPoa: selectedPoa
				}
			);
			me.modal?.close();
		}
	}

	onEmptyGridPoA() {
		const me = this;

		return edi.sign.setSignature({
			content: 'test',
			certificate: me.signProcessContext.certificateHandler.get(),
			callback: function (signObj: AnyObject) {
				if (!signObj.data) {
					me.failure(signObj.error);
					return;
				}
				me.signData = signObj.data;
				me.guidImportHandler();
			}
		});
	}

	onHasPoAsInGrid(data: AnyObject, callback: (poa: AnyObject) => void) {
		const me = this;

		let poas = data.data.result;
		let modal: ExtComponent,
			gridData = [];

		let selectPoa = function (data: AnyObject, callback: (poa: AnyObject) => void) {
			'function' == typeof callback ? callback(data) : null;
			me.successClose = true;
		};

		let linkedPoa = poas.find(
			(item: AnyObject) =>
				edi.utils.getAttributeByName(item.attributes, 'POA_HAS_LINK') === 'true' && !!item.poaHasLinked
		);
		if (linkedPoa) {
			selectPoa(linkedPoa, callback);
		} else {
			for (let i = 0; i < poas.length; i++) {
				gridData.push(edi.models.createInstance('POA_SELECT_LIST', poas[i]));
			}

			let grid = createGrid({
				storeConfig: {
					proxy: createProxyConfig({
						type: 'pagingmemory'
					}),
					data: gridData || [],
					remoteSort: false,
					model: edi.models.getModel('POA_SELECT_LIST'),
					listeners: {
						datachanged: function () {
							modal && !modal.isDestroyed ? modal.setLoading(false) : null;
						}
					}
				},
				gridConfig: {
					columns: edi.columns.get('poa_select_list'),
					listeners: {
						select: function (comp: ExtComponent, record: ExtRecord<AnyObject>) {
							var data = record.getData();
							selectPoa(data, callback);
							modal.close();
						}
					},
					disablePaging: true, //OVERRIDE Ediweb
					height: 400
				}
			});

			modal = createModalPanel({
				cls: 'edi-modal-certificate-selector',
				width: MODAL_SIZE.widthLarge,
				title: edi.i18n.getMessage('poa.select.title'),
				items: [
					createModalForm({
						items: [grid]
					})
				],
				listeners: {
					close: function () {
						'function' == typeof me.refuse ? me.refuse(me.successClose) : null;
					}
				}
			});
			modal.show();
		}
	}

	onSuccessSelectPoA(data: AnyObject, callback: (poa: AnyObject) => void) {
		const me = this;

		if (data?.data?.result) {
			// найдено ни одной МЧД
			if (!data.data.result.length) {
				if (me.isSignDoc) {
					me.onEmptyGridPoA();
				} else {
					edi.core.showError('not.find.poa', function () {
						'function' == typeof me.refuse ? me.refuse(me.successClose) : null;
					});
				}
			}
			//выбор МЧД из списка
			else {
				me.onHasPoAsInGrid(data, callback);
			}
		} else {
			me.failure(data);
		}
	}

	selectPoA(
		certificateData: AnyObject,
		isSignDoc: boolean,
		callback: (poa: AnyObject) => void,
		refuse: (b: boolean) => void
	) {
		const me = this;

		me.certificateData = certificateData;
		me.isSignDoc = isSignDoc;
		me.refuse = refuse;
		let url = edi.rest.services.ACCEPTANCE.SEARCH_BY_CERT.POST;
		me.successClose = false;
		edi.rest.sendRequest(
			url,
			'POST',
			Ext.encode(me.certificateData),
			function (data: AnyObject) {
				me.onSuccessSelectPoA.apply(me, [data, callback]);
			},
			function (data: AnyObject) {
				me.failure(data);
			}
		);
	}

	signAcceptance() {
		const me = this;

		me.acceptanceData.type = 'REGISTRATION_ACCEPTANCE';
		edi.constants.ENABLE_CERTIFICATE_APPLICATION_CHECKING = false;
		const signContentUrl = me.options.signContentUrl ?? edi.rest.services.ACCEPTANCE.SIGN.GET;
		const signUrl = me.options.signUrl ?? edi.rest.services.ACCEPTANCE.SIGN.PUT;
		edi.utils.sign(
			me.acceptanceData,
			me.modal,
			me.signCallback.bind(me),
			undefined,
			undefined,
			undefined,
			undefined,
			{
				signContentUrl: edi.utils.formatString(signContentUrl, {
					docId: me.acceptanceData.header
				}),
				signUrl: edi.utils.formatString(signUrl, {
					docId: me.acceptanceData.header
				}),
				signUrlMethod: 'PUT',
				customSelectPoA: me.isShowFromMain
					? null
					: function (
							certificateData: AnyObject,
							isSignDoc: boolean,
							callback: (poa: AnyObject) => void,
							refuse: (b: boolean) => void
					  ) {
							// @ts-ignore
							me.signProcessContext = this;
							me.selectPoA(certificateData, isSignDoc, callback, refuse);
					  }
			}
		);
	}

	getFields() {
		const me = this;

		const modalLabels = me.options.modalLabels ?? {};

		let rightHeader = createContainer({
			cls: 'edi-acceptance-window-rightHeader-container',
			layout: 'border',
			region: 'center',
			height: 38,
			items: [
				createLabel({
					typography: 'body-long_01',
					region: 'east',
					html: edi.i18n.getMessage('ediweb.acceptance.window.rightHeader')
				})
			]
		});
		let titleLabel = createLabel({
			typography: 'heading_02',
			style: {
				display: 'block',
				textAlign: 'center'
			},
			html: modalLabels.title ?? edi.i18n.getMessage('ediweb.acceptance.window.title')
		});

		let subtitleInfoContainer = createContainer({
			cls: 'edi-acceptance-window-subtitle-info-container',
			layout: 'border',
			region: 'center',
			height: 30,
			items: [
				createContainer({
					region: 'west',
					items: [
						createLabel({
							typography: 'heading_01',
							text: edi.i18n.getMessage('ediweb.acceptance.window.city')
						})
					]
				}),
				createContainer({
					region: 'east',
					layout: 'grid',
					items: [
						createLabel({
							typography: 'heading_01',
							text: edi.i18n.getDateWithStringMonth(me.acceptanceData.creationDate)
						})
					]
				})
			]
		});

		const OrgInfoContainerData = [
			{
				name: edi.i18n.getMessage('organization'),
				value: me.acceptanceData.orgName ?? '-'
			},
			{
				name: edi.i18n.getMessage('ediweb.acceptance.window.content.org.info.address'),
				value: me.acceptanceData.address ?? '-'
			},
			{
				name: edi.i18n.getMessage('column.register.number'),
				value: me.acceptanceData.ogrn ?? '-'
			},
			{
				name: edi.i18n.getMessage('company.inn.short'),
				value: me.acceptanceData.inn ?? '-'
			},
			{
				name: edi.i18n.getMessage('company.kpp.short'),
				value: me.acceptanceData.kpp ?? '-'
			},
			{
				name: edi.i18n.getMessage('Email'),
				value: me.acceptanceData.email ?? '-'
			}
		];

		const OrgInfoContainerTpl = new Ext.XTemplate(
			'<tpl for=".">',
			`<div class="line"><span class='typography body-short_02'>{name}</span>: {value}</div>`,
			'</tpl>'
		);
		let OrgInfoContainer = createContainer({
			items: [
				{
					xtype: 'component',
					cls: 'edi-acceptance-window-content edi-acceptance-window-content-org-info',
					html: OrgInfoContainerTpl.apply(OrgInfoContainerData)
				}
			]
		});

		const basedOnMap: AnyObject = {
				LEGAL_ENTITY: 'ediweb.acceptance.window.content.based.on.charter',
				INDIVIDUAL: 'ediweb.acceptance.window.content.based.on.ogrnip',
				PERSON: 'ediweb.acceptance.window.content.based.on.power.of.attorney'
			},
			basedOn = basedOnMap[me.acceptanceData.typeCertificate];

		let htmlContainer = createContainer({
			padding: '0 0 24 0',
			items: [
				{
					xtype: 'component',
					cls: 'edi-acceptance-window-content',
					html: edi.i18n.getMessage(modalLabels.content ?? 'ediweb.acceptance.window.content', [
						me.acceptanceData.orgName ?? '',
						me.acceptanceData.position ?? '',
						me.acceptanceData.head ?? '',
						basedOn ? edi.i18n.getMessage(basedOn) : ''
					])
				},
				{
					xtype: 'component',
					cls: 'edi-acceptance-window-content',
					padding: '0 0 8 46',
					html: edi.i18n.getMessage('ediweb.acceptance.window.content.list')
				},
				OrgInfoContainer
			]
		});

		let btn = createButton({
			text: edi.i18n.getMessage('form.btn.sign'),
			cls: BUTTON_CLS.primary,
			handler: me.signAcceptance.bind(me)
		});

		let cancelBtn = createButton({
			text: edi.i18n.getMessage('form.btn.close'),
			cls: BUTTON_CLS.secondary,
			handler: function () {
				me.modal?.close();
			}
		});

		return [
			rightHeader,
			titleLabel,
			subtitleInfoContainer,
			htmlContainer,
			me.isShowFromDocs ? null : btn,
			me.isShowFromMain ? cancelBtn : null
		];
	}

	showModal() {
		const me = this;

		let panel = createContainer({
			layout: {
				type: 'grid',
				area: [12, 12, 12, 12, [2, 2]]
			},
			padding: '0 36 40 40',
			items: me.getFields()
		});
		me.modal = createModalPanel(
			{
				cls: me.isShowFromDocs ? [MODAL_CLS.withShadows] : '',
				title: me.isShowFromDocs ? edi.i18n.getMessage('documents.doctype.REGISTRATION_ACCEPTANCE') : null,
				bodyPadding: '40 4 0 0',
				hideTitle: !me.isShowFromDocs,
				closable: me.isShowFromDocs,
				draggable: false,
				modal: false,
				verticalMargin: 72,
				width: MODAL_SIZE.widthLarge,
				//это нужно будет в дальнейшем для скачивания ПФ
				items: [panel]
			},
			true
		);
		me.modal?.show();
	}

	sendRequest() {
		const me = this;

		const urlMethod = me.options.urlMethod ?? 'GET';
		const urlParams = me.options.urlParams;

		return edi.rest.sendRequest(me.url, urlMethod, urlParams, me.success.bind(me), me.fail.bind(me));
	}
}

export const createAcceptance = (
	callback: (data?: AnyObject) => void,
	url?: string,
	options: AnyObject = {}
): Acceptance => new Acceptance(callback, url, options).sendRequest();

export const getAcceptance = (data: AnyObject, options: AnyObject = {}, callback?: (data?: AnyObject) => void) => {
	let callbackFn = () => {};
	if (typeof callback === 'function') {
		callbackFn = callback;
	}
	const me = new Acceptance(callbackFn, undefined, options);
	me.acceptanceData = data;
	me.isShowFromDocs = !!options?.isShowFromDocs;
	me.isShowFromTpl = !!options?.isShowFromTpl;
	if (options?.isShowFromTpl) {
		me.showAcceptanceFromTpl();
	} else {
		me.showModal();
	}
};
