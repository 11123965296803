import { createArgs, createFormItems, handleRowClick } from '@Edi/modules/catalog/methods';
import { createModulePanel, createPanel, createTabPanel } from '@Components/panels';
import { createActionsColumnConfig, createGrid, createToolBar } from '@Components/grid';
import { getCatalogsV2Columns } from '@Edi/modules/catalog/columns';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import './style.scss';
import { TAB_PANEL_CLS } from '@UIkit/components/tab';

class CatalogV2 {
	moduleData;
	filterForm;
	filterObjects = {};
	grids;
	lastOperation = {};
	_fireSearchAll;

	init(data, initCallBack) {
		const me = this;
		me._fireSearchAll = me.fireSearchAll.bind(me);
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.catalog.on('change', function () {
			me._fireSearchAll();
		});
	}

	getFilterUrl() {
		return edi.rest.services.CATALOGS.V2.FILTER.GET;
	}

	fireSearchAll() {
		const me = this;
		Object.keys(me.grids).forEach((grid) => {
			if (!me.filterObjects[grid]) {
				me.filterObjects[grid] = edi.filters.createGridFilter(
					me.getFilterUrl,
					me.filterForm,
					me.grids[grid],
					createArgs,
					grid === 'actual'
						? {
								state: 'ACTIVE'
						  }
						: undefined,
					{
						persistence: {
							enabled: true,
							name: `${me.moduleData.modName}_${grid}`
						}
					}
				);
			}
			me.filterObjects[grid].filter();
		});
	}

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	renderData(initCallBack) {
		const me = this;
		const modulePanel = createModulePanel({
			layout: 'fit',
			region: 'center',
			items: [me.createLayout()]
		});
		me.moduleData.tab.add(modulePanel);

		me.fireSearchAll();

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	}

	canShowDetails({ type, state, data }) {
		const businessState = edi.utils.getAttributeByName(data?.attributes, 'businessState');
		switch (type) {
			case edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN:
				return (
					edi.permissions.hasPermission('CLIENT_LEGACY_PARTIN_READ') &&
					(state === edi.constants.CATALOG_STATES.ACTIVE ||
						state === edi.constants.CATALOG_STATES.DRAFT ||
						state === edi.constants.CATALOG_STATES.COMPLETED)
				);
			default:
				return (
					state === edi.constants.CATALOG_STATES.ACTIVE &&
					businessState !== edi.constants.CATALOG_STATES.RELOADING
				);
		}
	}

	getActionColumnConfig() {
		const me = this;
		return {
			align: 'center',
			items: [
				{
					glyph: edi.constants.ICONS.DETAILS,
					isActionDisabled: function (view, rowIndex, colIndex, item, record) {
						return !me.canShowDetails({
							data: record.getData(),
							state: record.get('state'),
							type: record.get('type')
						});
					},
					handler: function (grid, rowIndex, colIndex, item, e, record) {
						me.rowClickHandler({ data: record.getData() });
					}
				},
				{
					glyph: edi.constants.ICONS.WARNING,
					tooltip: edi.i18n.getMessage('catalog.download.errors'),
					isActionDisabled: function (view, rowIndex, colIndex, item, record) {
						return !edi.utils.getAttributeByName(record.get('attributes'), 'hasErrors');
					},
					handler: function (grid, rowIndex) {
						let record = grid.getStore().getAt(rowIndex).getData();
						edi.rest.downloadFile(
							edi.utils.formatString(edi.rest.services.CATALOGS.V2.ERRORS.GET, {
								type: edi.constants.CATALOG_TYPES[record.type],
								id: record.id
							})
						);
					}
				}
			]
		};
	}

	showUploadCatalogDialog(uploadCatalogProps) {
		const me = this;
		edi.methods.catalog.showUploadCatalogDialog(me.moduleData, undefined, uploadCatalogProps);
	}

	createGridAddButton() {
		const me = this;
		return createButton({
			text: edi.i18n.getMessage('form.btn.add'),
			cls: [BUTTON_CLS.primary],
			glyph: edi.constants.ICONS.PLUS,
			menu: [
				{
					text: edi.i18n.getMessage('load.catalog.partin'),
					handler: () =>
						me.showUploadCatalogDialog({
							catalogType: edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN,
							modalConf: {
								title: edi.i18n.getMessage('catalog.partin.upload.title')
							},
							saveBtnConf: {
								text: edi.i18n.getMessage('form.btn.add')
							}
						})
				},
				{
					text: edi.i18n.getMessage('create.catalog.partin'),
					handler: () => edi.core.openModule('partin.create')
				},
				{
					cls: 'menu-separator',
					xtype: 'menuseparator',
					emptyText: '',
					text: null
				},
				{
					text: edi.i18n.getMessage('load.catalog.new'),
					handler: () => me.showUploadCatalogDialog()
				}
			]
		});
	}

	createGridToolBar() {
		const me = this;
		return createToolBar({
			padding: 8,
			items: [me.createGridAddButton()]
		});
	}

	rowClickHandler({ data }) {
		handleRowClick({
			data
		});
	}

	getColumns() {
		return getCatalogsV2Columns();
	}

	getCatalogGridProxyConfig() {
		return {
			type: 'ajax',
			url: null
		};
	}

	getModel() {
		return edi.models.getModel('CATALOG_V2');
	}

	getCatalogGridStoreConfig({ key }) {
		const me = this;
		return {
			model: me.getModel(),
			autoLoad: false,
			remoteSort: true,
			sortOnLoad: true,
			sorters: {
				property: 'creationDate',
				direction: 'DESC'
			},
			listeners: {
				beforeload(store, operation) {
					me.lastOperation[key] = operation;
				}
			}
		};
	}

	getCatalogGridGridConfig() {
		const me = this;
		const columns = me.getColumns();
		columns.push(createActionsColumnConfig(me.getActionColumnConfig()));
		return {
			cls: 'tab-grid with-filter',
			columns: columns,
			region: 'center',
			border: 0,
			autoScroll: true,
			disableSelection: true,
			dockedItems: [me.createGridToolBar()],
			listeners: {
				celldblclick: function (view, td, cellIndex, record) {
					const isShow = me.canShowDetails({
						data: record.getData(),
						state: record.get('state'),
						type: record.get('type')
					});
					if (isShow) {
						me.rowClickHandler({ data: record.getData() });
					}
				}
			}
		};
	}

	getCatalogGridViewConfig() {
		const me = this;
		return {
			emptyTextTplOptions: {
				enabled: true,
				iconName: 'catalog',
				contentText: edi.i18n.getMessage('catalog.empty.text.tpl.contentText'),
				beforeButtonText: edi.i18n.getMessage('catalog.empty.text.tpl.beforeButtonText'),
				buttonText: edi.i18n.getMessage('catalog.empty.text.tpl.buttonText'),
				buttonClickHandler: function () {
					me.filterForm?.toggleHandler();
				}
			}
		};
	}

	/**
	 *
	 * Create catalog grid
	 * @returns {Object}
	 */
	createCatalogGrid(key) {
		const me = this;
		return createGrid({
			saveSorters: true,
			savedSortersName: `catalogs_v2_${key}`,
			proxyConfig: me.getCatalogGridProxyConfig(),
			storeConfig: me.getCatalogGridStoreConfig({ key }),
			gridConfig: me.getCatalogGridGridConfig(),
			viewConfig: me.getCatalogGridViewConfig()
		});
	}

	createFilterFormItems() {
		return createFormItems();
	}

	/**
	 * Create module filter and tab
	 * @returns {Object}
	 */
	createLayout() {
		const me = this;
		const { formItemsMap, items } = me.createFilterFormItems();
		me.filterForm = createModuleFilterForm(
			{
				formItemsMap,
				items
			},
			me._fireSearchAll
		);
		const tabPanel = createTabPanel(
			{
				cls: TAB_PANEL_CLS.simpleWithoutPadding
			},
			true
		);
		me.grids = {
			actual: me.createCatalogGrid('actual'),
			all: me.createCatalogGrid('all')
		};

		let isFirst = true;
		for (let i in me.grids) {
			if (me.grids.hasOwnProperty(i)) {
				initTabWithGrid(i, me.grids[i], isFirst, tabPanel, {
					title: 'catalog.tab.' + i,
					glyphName: null
				});

				isFirst = false;
			}
		}
		return createPanel({
			layout: 'border',
			items: [me.filterForm, tabPanel]
		});
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;
		for (let i in me.filterObjects) {
			if (me.filterObjects.hasOwnProperty(i) && me.filterObjects[i].searchTimeout) {
				clearTimeout(me.filterObjects[i].searchTimeout);
			}
		}
		edi.events.catalog.un('change', me._fireSearchAll);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

/**
 * Class for catalogs v2
 */
class ClientCatalogV2 extends CatalogV2 {}

Ext.namespace('edi.modules');
edi.modules['catalogs.v2'] = ClientCatalogV2;

export { CatalogV2 };
