import { UploadDocToPackageWindow } from '@Edi/modules/documentPackages/uploadDocToPackageWindow';

export class FnsUploadDocToPackageWindow extends UploadDocToPackageWindow {
	getTypes() {
		const types = super.getTypes();
		types.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1);
		return types;
	}
	getPayload() {
		const me = this;
		const payload = super.getPayload();
		if (me.doctypeCombo.getValue() === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
			payload.version = '5.01-N';
		}
		return payload;
	}
}
