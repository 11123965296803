const actionRulesForTorg2Module = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {}
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.SIGNED_BY_CONSUMER
			]
		}
	},
	REJECT: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.WAIT_SIGNATURE,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.SIGNED_BY_CONSUMER
			]
		},
		OUTGOING: {}
	},
	CLARIFY: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived');
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		}
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		}
	},
	COMPLETE: {},
	READ: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		}
	},
	SEND: {},
	CREATE_FROM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		}
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived');
				}
			}
		]
	},
	CREATE_UKD: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		}
	}
};

const actionRulesForTorg2Statuses = {
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.WAIT_SIGNATURE_STATUS10]
		}
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2]: actionRulesForTorg2Module,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_DP_UVUTOCH]: actionRulesForTorg2Statuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_DP_IZVPOL]: actionRulesForTorg2Statuses
});

export { actionRulesForTorg2Module, actionRulesForTorg2Statuses };
