import { documentPackageSelectors } from '@Edi/modules/documentPackages/selectors';

const oldGetFieldsMap = documentPackageSelectors.getFieldsMap;
documentPackageSelectors.getFieldsMap = function (fieldsMapPrefix: string): AnyObject {
	const map = oldGetFieldsMap(fieldsMapPrefix);
	map.fns_id = `${fieldsMapPrefix}.fnsCode`;
	map.fnsCode = `${fieldsMapPrefix}.fnsId`;
	return map;
};

const oldGetModalConf = documentPackageSelectors.getModalConf;
documentPackageSelectors.getModalConf = function (): AnyObject {
	const modalConf = oldGetModalConf();
	modalConf.tabs.main.fns_id = {};
	modalConf.tabs.main.fnsCode = {};
	return modalConf;
};
