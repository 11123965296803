/**
 * @author Vsevolod Liapin
 * Column configurations for grids
 */

edi.columns.addColumns({
	documents_all: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		number: {
			text: 'documents.column.number',
			sortable: true,
			flex: 1,
			dataIndex: 'number'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			sortable: true,
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		direction: {
			text: 'documents.column.direction',
			sortable: false,
			align: 'center',
			flex: 1,
			renderer: 'direction',
			htmlEncode: true
		},
		partner: {
			text: 'documents.column.document.partner',
			sortable: false,
			flex: 2,
			renderer: 'documentPartner'
		},
		businessState: {
			text: 'documents.column.status',
			dataIndex: 'businessState',
			renderer: 'columnStatus',
			flex: 1.5,
			htmlEncode: true
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		orderNumber: {
			text: 'documents.column.orderNumber',
			dataIndex: 'orderNumber',
			sortable: true,
			flex: 1.5
		},
		desadvNumber: {
			text: 'documents.column.desadvNumber',
			dataIndex: 'desadvNumber',
			sortable: true,
			hidden: true,
			flex: 1.5
		},
		contractNumber: {
			text: 'documents.column.contractNumber',
			dataIndex: 'contractNumber',
			sortable: true,
			hidden: true,
			flex: 1.5
		},
		documentFunctionCode: {
			text: 'document.function.code',
			renderer: 'documentFunctionCode',
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'documentFunctionCode'
		},
		documentVersion: {
			text: 'document.version',
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'documentVersion',
			renderer: function (value) {
				return value && value != 'null' ? value : '';
			}
		},
		pointName: {
			text: 'document.delivery.point.name',
			flex: 1,
			sortable: false,
			hidden: true,
			dataIndex: 'deliveryName',
			renderer: function (value, metaData, record) {
				var deliveryPointName = edi.utils.getAttributeByName(record.get('attributes'), 'deliveryName'),
					retData = '';
				if (deliveryPointName) {
					retData = edi.renderers.valueAsToolTip(deliveryPointName, metaData);
				}
				return retData;
			}
		},
		deliveryGLN: {
			text: 'document.delivery.point.gln',
			flex: 1,
			sortable: true,
			hidden: true,
			dataIndex: 'deliveryGLN',
			renderer: function (value, metaData, record) {
				var deliveryPointGLN = edi.utils.getAttributeByName(record.get('attributes'), 'deliveryGLN');
				return deliveryPointGLN ? edi.renderers.valueAsToolTip(deliveryPointGLN, metaData) : '';
			}
		},
		pointAddress: {
			text: 'document.delivery.point.address',
			flex: 1.5,
			sortable: false,
			hidden: true,
			renderer: function (value, metaData, record) {
				var deliveryPointAddress = edi.utils.getAttributeByName(record.get('attributes'), 'deliveryAddress'),
					retData = '';
				if (deliveryPointAddress) {
					retData = edi.renderers.valueAsToolTip(deliveryPointAddress, metaData);
				}
				return retData;
			}
		},
		totalSumm: {
			text: 'documents.column.summ',
			renderer: 'displayCurrencyDecimals',
			sortable: false,
			flex: 1,
			dataIndex: 'totalSumm'
		}
	},
	documents_all_client: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		number: {
			text: 'documents.column.number',
			sortable: true,
			flex: 1,
			dataIndex: 'number'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			sortable: true,
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		removeDate: {
			text: 'documents.column.removeDate',
			dataIndex: 'removeDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		documentSender: {
			text: 'documents.column.document.sender',
			sortable: false,
			align: 'center',
			flex: 1,
			dataIndex: 'fromOrg',
			renderer: 'organization'
		},
		documentRecipient: {
			text: 'documents.column.document.recipient',
			sortable: false,
			align: 'center',
			flex: 1,
			dataIndex: 'toOrg',
			renderer: 'organization'
		},
		transporter: {
			text: 'documents.column.document.transporter',
			sortable: false,
			align: 'center',
			flex: 1,
			hidden: true,
			dataIndex: 'toOrg',
			renderer: function (val, meta, record) {
				return edi.utils.getAttributeByName(record.get('attributes'), 'transporter') || '';
			}
		},
		businessState: {
			text: 'documents.column.status',
			dataIndex: 'businessState',
			renderer: 'columnStatus',
			flex: 1.5,
			htmlEncode: true
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		orderNumber: {
			text: 'documents.column.orderNumber',
			dataIndex: 'orderNumber',
			sortable: true,
			flex: 1.5
		},
		desadvNumber: {
			text: 'documents.column.desadvNumber',
			dataIndex: 'desadvNumber',
			sortable: true,
			hidden: true,
			flex: 1.5
		},
		contractNumber: {
			text: 'documents.column.contractNumber',
			dataIndex: 'contractNumber',
			sortable: true,
			hidden: true,
			flex: 1.5
		},
		documentFunctionCode: {
			text: 'document.function.code',
			renderer: 'documentFunctionCode',
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'documentFunctionCode'
		},
		documentVersion: {
			text: 'document.version',
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'documentVersion',
			renderer: function (value) {
				return value && value != 'null' ? value : '';
			}
		},
		documentWarnings: {
			text: 'document.warnings',
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'attributes',
			order: 25,
			htmlEncode: true,
			renderer: function (value, metaData) {
				const errorNomSredIdentTovAndSvedProslezh = edi.utils.getAttributeByName(
					value,
					'errorNomSredIdentTovAndSvedProslezh'
				);
				const warningMessage = edi.utils.getAttributeByName(value, 'warningMessage');
				//если предупреждение прослеживаемости
				if (errorNomSredIdentTovAndSvedProslezh) {
					metaData
						? (metaData.tdAttr =
								'data-qtip="' +
								edi.i18n.getMessage('document.warning.' + errorNomSredIdentTovAndSvedProslezh) +
								'"')
						: null;
					return Ext.String.format(
						'<span style="color:#ffc000;">{0}</span>',
						edi.i18n.getMessage('document.warning.' + errorNomSredIdentTovAndSvedProslezh)
					);
				}
				//если предупреждение бизнес валидации
				else if (warningMessage) {
					return warningMessage;
				} else {
					return '';
				}
			}
		},
		errors: {
			text: 'document.column.errors',
			dataIndex: 'attributes',
			flex: 1,
			hidden: true,
			renderer: edi.renderers.errorMessage
		},
		pointName: {
			text: 'document.delivery.point.name',
			flex: 1,
			sortable: false,
			hidden: true,
			dataIndex: 'deliveryName',
			renderer: function (value, metaData, record) {
				var deliveryPointName = edi.utils.getAttributeByName(record.get('attributes'), 'deliveryName'),
					retData = '';
				if (deliveryPointName) {
					retData = edi.renderers.valueAsToolTip(deliveryPointName, metaData);
				}
				return retData;
			}
		},
		deliveryGLN: {
			text: 'document.delivery.point.gln',
			flex: 1,
			sortable: true,
			hidden: true,
			dataIndex: 'deliveryGLN',
			renderer: function (value, metaData, record) {
				var deliveryPointGLN = edi.utils.getAttributeByName(record.get('attributes'), 'deliveryGLN');
				return deliveryPointGLN ? edi.renderers.valueAsToolTip(deliveryPointGLN, metaData) : '';
			}
		},
		pointAddress: {
			text: 'document.delivery.point.address',
			flex: 1.5,
			sortable: false,
			hidden: true,
			renderer: function (value, metaData, record) {
				var deliveryPointAddress = edi.utils.getAttributeByName(record.get('attributes'), 'deliveryAddress'),
					retData = '';
				if (deliveryPointAddress) {
					retData = edi.renderers.valueAsToolTip(deliveryPointAddress, metaData);
				}
				return retData;
			}
		},
		totalSumm: {
			text: 'documents.column.summ.with.NDS',
			renderer: 'displayCurrencyDecimals',
			sortable: false,
			flex: 1,
			dataIndex: 'totalSumm',
			hidden: true
		},
		totalNetAmount: {
			text: 'documents.column.summ.without.NDS',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'totalNetAmount',
			sortable: true,
			flex: 1,
			hidden: true
		},
		totalTaxAmount: {
			text: 'documents.column.summ.NDS',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'totalTaxAmount',
			sortable: true,
			flex: 1,
			hidden: true
		},
		remark: {
			text: 'documents.column.remark',
			dataIndex: 'attributes',
			flex: 1.5,
			order: 24,
			renderer: function (value) {
				return edi.utils.getObjectProperty(value, 'remark.value');
			},
			sortable: false,
			hidden: true
		}
	},
	documents_available: {
		number: {
			text: 'documents.column.number',
			flex: 1.5,
			dataIndex: 'number'
		},
		partner: {
			text: 'documents.column.document.partner',
			sortable: false,
			flex: 3,
			renderer: 'documentPartner'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'dateFromMs'
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		totalSumm: {
			text: 'documents.column.summ',
			renderer: 'displayCurrencyDecimals',
			sortable: false,
			flex: 1,
			dataIndex: 'totalSumm'
		}
	},
	documents_all_linked: {
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			sortable: false,
			xtype: 'treecolumn',
			flex: 1.5,
			htmlEncode: true,
			renderer: function (value, metaData, record) {
				metaData.tdAttr =
					'data-qtip="' + edi.utils.replaceQuotes(edi.i18n.getMessage('documents.doctype.' + value)) + '"';
				value = edi.renderers.doctype(value, metaData, record);
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			}
		},
		number: {
			text: 'documents.column.number',
			sortable: false,
			flex: 1.5,
			dataIndex: 'number',
			renderer: function (value, metaData, record) {
				value = value;
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			}
		},
		creationDate: {
			text: 'documents.column.date',
			dataIndex: 'creationDate',
			sortable: false,
			flex: 1,
			renderer: function (value, metaData, record) {
				const doctime = edi.renderers.dateTimeFromMs(value);
				metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(doctime) + '"';
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return doctime;
			}
		},
		fromOrg: {
			text: 'documents.column.fromOrg',
			dataIndex: 'fromOrgName',
			sortable: false,
			flex: 1,
			renderer: function (value, metaData, record) {
				value = value;
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			}
		},
		toOrg: {
			text: 'documents.column.toOrg',
			dataIndex: 'toOrgName',
			sortable: false,
			flex: 1,
			renderer: function (value, metaData, record) {
				value = value;
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			}
		},
		businessState: {
			text: 'documents.column.status',
			dataIndex: 'businessState',
			flex: 1.5,
			htmlEncode: true,
			renderer: function (value, metaData, record) {
				value = edi.renderers.columnStatus(value, metaData, record);
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			}
		},
		totalSumm: {
			text: 'documents.column.summ.with.NDS',
			renderer: function (value, metaData, record) {
				value = edi.renderers.displayCurrencyDecimals(value, metaData, record);
				metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(value) + '"';
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			},
			sortable: false,
			flex: 1,
			dataIndex: 'totalSumm'
		}
	},
	documents_all_linked_receipts: {
		number: {
			text: 'receipt.number',
			flex: 0.5,
			dataIndex: 'number',
			sortable: false,
			renderer: function (value, meta, record) {
				var docType = record.get('type');
				var splitValue = value.split('.');
				if (
					docType == edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
					splitValue[splitValue.length - 1] === '26'
				) {
					return `${splitValue[splitValue.length - 2]}-${splitValue[splitValue.length - 1]}`;
				} else {
					return splitValue[splitValue.length - 1];
				}
			}
		},
		sender: {
			text: 'documents.sender',
			flex: 1,
			sortable: false,
			dataIndex: 'fromOrg',
			renderer: 'organization'
		},
		receiver: {
			text: 'documents.receiver',
			flex: 1,
			sortable: false,
			dataIndex: 'toOrg',
			renderer: 'organization'
		},
		dateCreate: {
			text: 'document.date.create',
			flex: 1,
			dataIndex: 'creationDate',
			sortable: false,
			renderer: function (value) {
				value = edi.renderers.dateTimeFromMs(value);
				return value;
			}
		},
		dateModify: {
			text: 'document.date.modify',
			flex: 1,
			dataIndex: 'modifyDate',
			sortable: false,
			renderer: function (value) {
				value = edi.renderers.dateTimeFromMs(value);
				return value;
			}
		},
		state: {
			text: 'receipt.status',
			dataIndex: 'state',
			sortable: false,
			renderer: function (value, metaData, record) {
				value = edi.renderers.status(value, metaData, record);
				metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(value) + '"';
				metaData.tdAttr += ` data-qtipFormatted="true"`;
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			},
			flex: 1,
			htmlEncode: true
		},
		type: {
			text: 'receipt.type',
			flex: 1,
			dataIndex: 'type',
			sortable: false,
			htmlEncode: true,
			renderer: function (value, metaData, record) {
				metaData.tdAttr =
					'data-qtip="' + edi.utils.replaceQuotes(edi.i18n.getMessage('documents.doctype.' + value)) + '"';
				value = edi.renderers.doctype(value, metaData, record);
				record.get('isCurrent') ? (metaData.tdCls += ' edi-linked-current-element') : null;
				return value;
			}
		}
	},
	accept_registry_line_base: {
		registryNumber: {
			text: 'accept.registry.line.column.register.id',
			dataIndex: 'registryNumber',
			sortable: false,
			flex: 1
		},
		doctype: {
			text: 'accept.registry.line.column.type',
			dataIndex: 'amountCertificationDoc.type',
			sortable: true,
			renderer: 'doctype',
			flex: 1,
			htmlEncode: true
		},
		number: {
			text: 'accept.registry.line.column.number',
			dataIndex: 'amountCertificationDoc.number',
			sortable: true,
			flex: 1
		},
		receiptAcknowledgementDocs: {
			text: 'accept.registry.line.column.secondary.number',
			dataIndex: 'receiptAcknowledgementDocs',
			sortable: false,
			hidden: true,
			flex: 2,
			renderer: function (val) {
				var lines = [],
					i,
					data = '';
				if (Array.isArray(val)) {
					for (i = 0; i < val.length; i++) {
						lines.push(val[i].number + '/' + edi.i18n.getMessage('documents.doctype.' + val[i].type));
					}
					data = lines.join('<br/>');
				}
				return data;
			}
		},
		creationDate: {
			text: 'accept.registry.line.column.loadDate',
			dataIndex: 'creationDate',
			renderer: 'dateFromMs',
			sortable: true,
			flex: 1
		},
		date: {
			text: 'accept.registry.line.column.date',
			dataIndex: 'amountCertificationDoc.date',
			renderer: 'dateFromMs',
			sortable: true,
			flex: 1
		},
		duedate: {
			text: 'accept.registry.line.column.duedate',
			renderer: function (value, column, record) {
				var retVal = '',
					amountDoc = record.get('amountCertificationDoc');
				value = amountDoc.firstDueDate || amountDoc.dueDate;
				if (value) {
					retVal = edi.utils.formatDate(
						value,
						edi.constants.DATE_FORMAT.SERVER,
						edi.constants.DATE_FORMAT.CLIENT
					);
				}
				return edi.renderers.dateFromMs(retVal);
			},
			sortable: false,
			hidden: true,
			flex: 1
		},
		signDate: {
			text: 'accept.registry.line.column.sign.date',
			dataIndex: 'signDate',
			renderer: 'dateFromMs',
			sortable: true,
			flex: 1,
			hidden: true
		},
		correction: {
			text: 'accept.registry.line.column.correction',
			dataIndex: 'correction',
			sortable: false,
			flex: 1,
			hidden: true
		},
		amount: {
			text: 'accept.registry.line.column.amount',
			dataIndex: 'amountCertificationDoc.amount',
			sortable: true,
			flex: 1
		},
		agreementNumber: {
			text: 'accept.registry.line.column.agreement',
			dataIndex: 'agreementNumber',
			sortable: true,
			flex: 1
		},
		consumerTin: {
			text: 'accept.registry.line.column.customer',
			dataIndex: 'consumerTin',
			renderer: 'orgNameWithInnFromInn',
			sortable: true,
			flex: 1
		},
		supplierTin: {
			text: 'accept.registry.line.column.supplier',
			dataIndex: 'supplierTin',
			renderer: 'orgNameWithInnFromInn',
			sortable: true,
			flex: 1
		},
		headerState: {
			text: 'accept.registry.line.column.status',
			dataIndex: 'headerState',
			renderer: function (value, column, record) {
				var retVal, state;
				if (value == edi.constants.STATE.BANK_ACCEPTED || value == edi.constants.STATE.REJECTED) {
					state = edi.i18n.getTr(['STATE', value, 'ACCEPTED_REGISTRY_LINE']);
					retVal = Ext.String.format('<span class="status-{0}">{1}</span>', value, state);
					column.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(state.replace('<br/>', ' ')) + '"';
				} else {
					retVal = edi.renderers.stateWithTooltip(value, column, record);
				}
				return retVal;
			},
			sortable: true,
			flex: 1.5
		},
		documentSAPcodes: {
			text: 'accept.registry.line.column.sap.code',
			dataIndex: 'documentSAPcodes',
			renderer: function (value, column) {
				column.tdAttr = 'data-qtip="' + value.join(', ') + '"';
				column.tdAttr += ` data-qtipFormatted="true"`;
				return value.join('<br/>');
			},
			sortable: false,
			hidden: true,
			flex: 1
		},
		fnsId: {
			text: 'accept.registry.line.column.fnsId',
			dataIndex: 'fnsId',
			sortable: false,
			hidden: true,
			flex: 1
		}
	},
	addresscatalog_lines: {
		id: {
			text: 'column.line.number',
			sortable: false,
			hidden: true,
			dataIndex: 'id',
			flex: 0.5
		},
		name: {
			text: 'column.name',
			dataIndex: 'name', // O X(175)
			flex: 2
		},
		iln: {
			text: 'company.gln',
			dataIndex: 'iln', // C1 [0-9](13)
			flex: 0.75
		},
		taxID: {
			text: 'company.inn',
			hidden: true,
			dataIndex: 'taxID', // O X(35)
			flex: 0.75
		},
		codeBySender: {
			text: 'column.code.by.sender',
			sortable: false,
			dataIndex: 'codeBySender', // C1 X(35)
			flex: 0.75
		},
		codeByReceiver: {
			text: 'column.code.by.receiver',
			sortable: false,
			dataIndex: 'codeByReceiver', // C1 X(35)
			hidden: true,
			flex: 0.75
		},
		streetAndNumber: {
			text: 'column.street.and.number',
			sortable: false,
			hidden: true,
			dataIndex: 'streetAndNumber', // O X(140)
			flex: 1
		},
		postalCode: {
			text: 'column.postal.code',
			sortable: false,
			dataIndex: 'postalCode', // O X(9)
			hidden: true,
			flex: 1
		},
		cityName: {
			text: 'column.city.name',
			sortable: false,
			hidden: true,
			dataIndex: 'cityName', // O X(140)
			flex: 1
		},
		state: {
			text: 'company.state.code',
			sortable: false,
			hidden: true,
			dataIndex: 'state', // O X(9)
			flex: 0.5
		},
		country: {
			text: 'column.country',
			sortable: false,
			dataIndex: 'country', // O X(3)
			flex: 0.5
		},
		activityStatus: {
			text: 'column.activity.status',
			hidden: true,
			dataIndex: 'activityStatus',
			flex: 0.5,
			renderer: (value) => {
				if (value) return edi.i18n.getMessage('activity.status.' + value);
			}
		}
	},
	invitations: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true
		},
		initiator: {
			text: 'column.initiator.org',
			dataIndex: 'initiator.name',
			flex: 2,
			hideable: false
		},
		acceptor: {
			text: 'column.acceptor.org',
			dataIndex: 'acceptor.name',
			flex: 2,
			hideable: false
		},
		direction: {
			text: 'documents.column.direction',
			sortable: false,
			flex: 1.5,
			renderer: 'invitationDirection',
			htmlEncode: true
		},
		status: {
			text: 'column.status',
			dataIndex: 'status',
			renderer: 'invitationStatus',
			htmlEncode: true,
			sortable: true,
			flex: 1
		},
		creationDate: {
			text: 'column.creation.date',
			dataIndex: 'creationDate',
			flex: 1,
			hideable: true,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		modifyDate: {
			text: 'column.modify.date',
			dataIndex: 'modifyDate',
			flex: 1,
			hideable: false,
			renderer: 'dateTimeFromMs'
		}
	},
	documents_sign_info: {
		subjectDN: {
			text: 'column.certificate.subject',
			dataIndex: 'sertData',
			renderer(value, meta, record) {
				let certData = value || {};
				let name = certData['subject-CN'];
				let serialNumber = record.get('serialNumber');

				let inn = certData['subject-1.2.643.3.131.1.1'];
				if (inn) {
					inn = `${edi.i18n.getMessage('company.inn.short')} ${inn}`;
				}

				let innle = certData['subject-1.2.643.100.4'];
				if (innle) {
					innle = `${edi.i18n.getMessage('company.innle.short')} ${innle}`;
				}

				let retVal = [name, inn, innle, serialNumber].filter((item) => !!item).join(', ');

				let subjectObj = edi.utils.certificateObject(record.get('subjectName'));
				let qtip =
					edi.renderers.certificateSubjectQtip(subjectObj) || record.get('subjectName') || String(retVal);

				meta.tdAttr = `data-qtip="${qtip}"`;
				meta.tdAttr += ` data-qtipFormatted="true"`;
				return retVal;
			},
			flex: 2
		},
		fullName: {
			text: 'full.name.abbr',
			dataIndex: 'sertData',
			renderer: function (value, meta) {
				var retVal = edi.utils.getObjectProperty(value, 'subject-SURNAME');

				if (value['subject-GIVENNAME']) {
					retVal += ' ' + edi.utils.getObjectProperty(value, 'subject-GIVENNAME');
				}
				if (value['subject-T']) {
					retVal += ', ' + edi.utils.getObjectProperty(value, 'subject-T');
				}

				meta.tdAttr = 'data-qtip="' + String(retVal) + '"';
				return retVal;
			},
			flex: 2
		},
		issuer: {
			text: 'column.certificate.issuer',
			dataIndex: 'sertData',
			renderer(value, meta, record) {
				let name = edi.utils.getObjectProperty(value, 'issuer-CN');
				let issuer = record.get('issuer') || {};

				let inn = issuer['1.2.643.3.131.1.1'];
				if (inn) {
					inn = `${edi.i18n.getMessage('company.inn.short')} ${inn}`;
				}

				let innle = issuer['1.2.643.100.4'];
				if (innle) {
					innle = `${edi.i18n.getMessage('company.innle.short')} ${innle}`;
				}

				let retVal = [name, inn, innle].filter((item) => !!item).join(', ');

				let issuerObj = edi.utils.certificateObject(record.get('issuerName'));
				let qtip = edi.renderers.certificateIssuerQtip(issuerObj) || record.get('issuerName') || String(retVal);

				meta.tdAttr = `data-qtip="${qtip}"`;
				meta.tdAttr += ` data-qtipFormatted="true"`;
				return retVal;
			},
			flex: 2
		},
		role: {
			text: 'column.role',
			dataIndex: 'role',
			hidden: true,
			sortable: false,
			renderer: function (value, meta, record) {
				return !!value ? edi.i18n.getMessage('position.role.' + value) : '';
			},
			flex: 1
		},
		beginDate: {
			text: 'column.certificate.dateFrom',
			dataIndex: 'beginDate',
			renderer: 'dateFromMs',
			flex: 1
		},
		endDate: {
			text: 'column.certificate.dateTo',
			dataIndex: 'endDate',
			renderer: 'dateFromMs',
			flex: 1
		},
		signDate: {
			text: 'document.sign.date',
			dataIndex: 'signDate',
			renderer: 'dateTimeFromMs',
			flex: 1
		},
		doverRegNumber: {
			text: 'power.of.attorney.external.number',
			dataIndex: 'doverRegNumber',
			flex: 1,
			sortable: false,
			hidden: true,
			htmlEncode: true,
			renderer: function (value) {
				return value ? ' <a href="#">' + value + '</a>' : '';
			}
		},
		naimOrgDover: {
			text: 'power.of.attorney.principal.orgname',
			dataIndex: 'naimOrgDover',
			flex: 1,
			sortable: false,
			hidden: true
		},
		poaStatus: {
			text: 'power.of.attorney.status',
			dataIndex: 'poaStatus',
			flex: 1,
			sortable: false,
			hidden: true,
			renderer: function (value, metadata, rec) {
				const statusesOverwritten = ['EXPIRED', 'ON_REGISTRATION'];
				const poaBusinessState = rec.get('poaBusinessState');

				// Перекрашивание статусов, специфических для МЧД - см. файл ext-74-common-styles.scss
				if (statusesOverwritten.indexOf(value) !== -1) {
					value = 'POWER_OF_ATTORNEY_' + value;
				}
				if (poaBusinessState === edi.constants.STATE.NOT_DEFINED) value = poaBusinessState;

				return edi.renderers.status(value, metadata, rec);
			},
			htmlEncode: true
		}
	},
	documents_poa_errors: {
		doverRegNumber: {
			text: 'power.of.attorney.external.number',
			dataIndex: 'doverRegNumber',
			sortable: false,
			hideable: false,
			flex: 1,
			renderer(value, meta) {
				if (value) {
					meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(value) + '"';
				}
				return value;
			}
		},
		docType: {
			text: 'documents.column.type',
			dataIndex: 'docType',
			renderer: function (value) {
				return edi.i18n.getMessage('documents.doctype.' + value);
			},
			flex: 1,
			sortable: false
		},
		naimOrgDover: {
			text: 'power.of.attorney.principal.org.column',
			dataIndex: 'naimOrgDover',
			sortable: false,
			flex: 1,
			renderer: function (value, meta) {
				if (value) {
					meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(value) + '"';
				}
				return value;
			}
		},
		poaError: {
			text: 'error',
			dataIndex: 'poaError',
			flex: 1,
			sortable: false,
			renderer(value, meta) {
				if (value) {
					try {
						const { type, additionalData } = JSON.parse(value) || {};
						const errorMsg = edi.utils.formatComplexServerError(
							{ typeError: type, additionalData },
							'controller.poa.error.unclassifiable.error'
						);
						meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(errorMsg) + '"';
						return errorMsg;
					} catch (e) {
						return edi.i18n.getMessage('controller.poa.error.unclassifiable.error');
					}
				}
				return value;
			}
		}
	},
	partners: {
		parents: 'organization',
		config: {
			name: {
				renderer: 'partnerWithFiliationsTooltip'
			},
			kpp: undefined,
			iln: {
				text: 'column.org.iln',
				dataIndex: 'iln',
				flex: 1,
				hideable: false
			}
		}
	},
	numeration_settings_partners: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true
		},
		name: {
			text: 'column.org.name',
			dataIndex: 'name',
			flex: 2,
			hideable: false
		},
		iln: {
			text: 'column.org.iln',
			dataIndex: 'iln',
			flex: 1
		}
	},
	partnersGrid: {
		parents: 'organization',
		config: {
			name: {
				renderer: 'partnerWithFiliationsTooltip',
				hideable: true
			},
			kpp: undefined,
			iln: {
				text: 'column.org.iln',
				dataIndex: 'iln',
				flex: 1
			},
			fnsId: {
				text: 'company.fns.id',
				dataIndex: 'fnsId',
				flex: 1
			},
			legalAddress: {
				text: 'column.org.legalAddress',
				dataIndex: 'addresses',
				flex: 1,
				getSortParam: function () {
					return 'zip';
				},
				renderer: function (value) {
					var legalAddress = value.find(function (element) {
						return element.type === 'LEGAL' || element.type === 'RU_LEGAL';
					});
					if (legalAddress) {
						var address = [];
						if (legalAddress.zip) {
							address.push(legalAddress.zip);
						}
						if (legalAddress.area) {
							address.push(legalAddress.area);
						}
						if (legalAddress.city) {
							address.push(edi.i18n.getMessage('address.city') + ' ' + legalAddress.city);
						}
						if (legalAddress.street) {
							address.push(edi.i18n.getMessage('address.street') + ' ' + legalAddress.street);
						}
						if (legalAddress.building) {
							address.push(edi.i18n.getMessage('address.building') + ' ' + legalAddress.building);
						}
						return address.join(', ');
					}
					return '';
				}
			}
		}
	},
	delivery_coordination: {
		deliveryDateTime: {
			text: 'column.delivery.date.time',
			dataIndex: 'DeliveryDateTime',
			flex: 1,
			renderer: function (value) {
				return edi.utils.formatDate(
					value,
					edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
					edi.constants.DATE_FORMAT.XS_DATE_TIME
				);
			},
			hideable: false
		},
		endOfLoadingTime: {
			text: 'column.end.of.loading.time',
			dataIndex: 'EndOfLoadingTime',
			flex: 1,
			hideable: false
		}
	},
	documents_legacy_aperak: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		number: {
			text: 'documents.column.number',
			sortable: false,
			flex: 1,
			dataIndex: 'number'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		partner: {
			text: 'documents.column.document.partner',
			sortable: false,
			flex: 2,
			renderer: 'documentPartner'
		},
		referenceDocType: {
			text: 'documents.column.document.reference.doc.type',
			dataIndex: 'referenceDocType',
			sortable: false,
			flex: 2,
			renderer: function (value) {
				if ('' == value) {
					return '';
				}
				return edi.i18n.getMessage('documents.doctype.' + edi.constants.APPERAK_DOCUMENT_TYPES_MAP[value]);
			}
		},
		referenceDocNum: {
			text: 'documents.column.document.reference.doc.num',
			dataIndex: 'referenceDocNum',
			sortable: false,
			flex: 2
		},
		includeReferenceDocCode: {
			text: 'documents.column.document.reference.doc.code',
			dataIndex: 'includeReferenceDocCode',
			sortable: false,
			flex: 2,
			renderer: function (value) {
				var msg = '',
					cls = Ext.isNumeric(value) ? 'status-APERAK_CODE_' + value : null;

				switch (Number(value)) {
					case edi.constants.APERAK_CODES.CODE_34:
						msg = edi.i18n.getMessage('documents.status.APERAK_CODE_34');
						break;
					case edi.constants.APERAK_CODES.CODE_29:
						msg = edi.i18n.getMessage('documents.status.APERAK_CODE_29');
						break;
					case edi.constants.APERAK_CODES.CODE_27:
						msg = edi.i18n.getMessage('documents.status.APERAK_CODE_27_SHORT');
						break;
					case edi.constants.APERAK_CODES.CODE_23:
						msg = edi.i18n.getMessage('documents.status.APERAK_CODE_23');
						break;
					case edi.constants.APERAK_CODES.CODE_13:
						msg = edi.i18n.getMessage('documents.status.APERAK_CODE_13');
						break;
					case edi.constants.APERAK_CODES.CODE_10:
						msg = edi.i18n.getMessage('documents.status.APERAK_CODE_10');
						break;
					case edi.constants.APERAK_CODES.CODE_6:
						msg = edi.i18n.getMessage('documents.status.APERAK_CODE_6');
						break;
				}
				return Ext.String.format('<span class="{0}">{1}</span>', cls, msg);
			}
		}
	},
	documents_dsf_protocol_price: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		number: {
			text: 'documents.column.number',
			sortable: false,
			flex: 1,
			dataIndex: 'number'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		center: {
			text: 'documents.column.direction',
			sortable: false,
			align: 'center',
			flex: 1,
			renderer: 'direction',
			htmlEncode: true
		},
		partner: {
			text: 'documents.column.document.partner',
			sortable: false,
			flex: 2,
			renderer: 'documentPartner'
		},
		state: {
			text: 'documents.column.status',
			dataIndex: 'state',
			renderer: 'status',
			flex: 1.5,
			htmlEncode: true
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		typeDsf: {
			text: 'document.dsf.price.catalogue.type',
			renderer: function (value) {
				return edi.i18n.getMessage('document.dsf.price.catalogue.type.' + (value ? value : ''));
			},
			sortable: false,
			flex: 1.5,
			dataIndex: 'typeDsf'
		}
	},
	product: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'lineNumber',
			sortable: true,
			flex: 0.5
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'ean',
			sortable: false,
			flex: 0.8
		},
		buyerItemCode: {
			text: 'pricat.product.buyerItemCode',
			dataIndex: 'buyerItemCode',
			sortable: true,
			flex: 1
		},
		supplierItemCode: {
			text: 'pricat.product.supplierItemCode',
			dataIndex: 'supplierItemCode',
			sortable: true,
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'taxRate',
			sortable: true,
			hidden: true,
			flex: 0.8
		},
		itemDescription: {
			text: 'pricat.product.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'itemDescription',
			sortable: false,
			flex: 1.5
		},
		unitPacksize: {
			text: 'pricat.product.quantity.unitPacksize',
			dataIndex: 'unitPacksize',
			sortable: true,
			hidden: true,
			flex: 1
		},
		unitNetPrice: {
			text: 'column.unit.price.without.nds',
			dataIndex: 'unitNetPrice',
			sortable: true,
			flex: 1
		},
		unitOfMeasure: {
			text: 'pricat.product.unitOfMeasure',
			dataIndex: 'unitOfMeasure',
			renderer: 'UnitOfMeasure',
			sortable: true,
			hidden: true,
			flex: 1
		}
	},
	product_extended: {
		number: {
			text: 'column.line.number',
			dataIndex: 'number',
			flex: 1
		},
		productData: {
			text: 'column.params.description',
			dataIndex: 'productData',
			renderer: function (value, column, record) {
				return edi.converters.processTorg12ProductData(record);
			},
			flex: 10
		},
		name: {
			text: 'column.name',
			dataIndex: 'name',
			hidden: true,
			flex: 7
		},
		characterProduct: {
			text: 'column.description',
			dataIndex: 'characterProduct',
			hidden: true,
			flex: 7
		},
		articleCode: {
			text: 'column.art.code',
			dataIndex: 'articleCode',
			hidden: true,
			flex: 3
		},
		code: {
			text: 'column.prod.code',
			dataIndex: 'code',
			hidden: true,
			flex: 3
		},
		unitName: {
			text: 'column.unit',
			dataIndex: 'unitName',
			flex: 3
		},
		unitCode: {
			text: 'column.unit.code',
			dataIndex: 'unitCode',
			hidden: true,
			flex: 3
		},
		packageName: {
			text: 'column.package.name',
			dataIndex: 'packageName',
			hidden: true,
			flex: 2
		},
		unitsInPackage: {
			text: 'column.package.size',
			dataIndex: 'unitsInPackage',
			hidden: true,
			flex: 2
		},
		packagesAmount: {
			text: 'column.package.qnt',
			dataIndex: 'packagesAmount',
			hidden: true,
			flex: 2
		},
		grossWeight: {
			text: 'column.gross.weight',
			dataIndex: 'grossWeight',
			hidden: true,
			flex: 2
		},
		nettoWeight: {
			text: 'column.netto.weight.amount',
			dataIndex: 'nettoWeight',
			hideable: false,
			flex: 3,
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount10', // used 10 instead of 12 to avoid convertation problems
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 1
			}
		},
		unitPrice: {
			text: 'column.net.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'unitPrice',
			flex: 3
		},
		totalWithoutVAT: {
			text: 'column.net.summ',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'totalWithoutVAT',
			flex: 3
		},
		vatDisplay: {
			text: 'column.vat',
			dataIndex: 'vat_display',
			hideable: false,
			renderer: function (value, column, record) {
				var vat = record.get('vat');
				return '' != vat && null != vat ? parseFloat(vat) * 100 + '%' : '0%';
			},
			flex: 2
		},
		vat: {
			dataIndex: 'vat',
			hideable: false,
			hidden: true
		},
		totalVat: {
			text: 'column.vat.summ',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'totalVat',
			flex: 3
		},
		totalWithVAT: {
			text: 'column.summ.with.vat',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'totalWithVAT',
			hideable: false,
			flex: 3
		}
	},
	product_extended_total: {
		totalRec: {
			text: 'column.total.lines',
			dataIndex: 'totalRec',
			menuDisabled: true,
			flex: 1
		},
		totalGrossWeight: {
			text: 'column.total.gross.weight',
			dataIndex: 'totalGrossWeight',
			menuDisabled: true,
			renderer: 'zeroAsEmpty',
			flex: 2
		},
		totalNettoWeight: {
			text: 'column.total.netto.weight.amount',
			dataIndex: 'totalNettoWeight',
			menuDisabled: true,
			flex: 2
		},
		totalWithoutVAT: {
			text: 'column.total.net.summ',
			dataIndex: 'totalWithoutVAT',
			menuDisabled: true,
			renderer: 'displayCurrencyDecimals',
			flex: 3
		},
		totalInvoiceVAT: {
			text: 'column.total.vat.summ',
			dataIndex: 'totalInvoice_VAT',
			menuDisabled: true,
			renderer: 'displayCurrencyDecimals',
			flex: 2.5
		},
		totalWithVAT: {
			text: 'column.total.summ.with.vat',
			dataIndex: 'totalWithVAT',
			menuDisabled: true,
			renderer: 'displayCurrencyDecimals',
			flex: 3
		}
	},
	document_lines_base: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 1
		},
		itemDescription: {
			text: 'column.item.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2
		},
		originCountry: {
			text: 'line.item.country.of.origin',
			dataIndex: 'OriginCountry',
			flex: 1,
			hidden: true
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		quantityOrdered: {
			text: 'column.quantity.ordered',
			dataIndex: 'QuantityOrdered',
			flex: 1,
			hidden: true
		},
		quantityDespatched: {
			text: 'column.quantity.despatched',
			dataIndex: 'QuantityDespatched',
			flex: 1,
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount10',
				decimalPrecision: 3,
				allowDecimals: true,
				allowBlank: false,
				minValue: 1
			}
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitNetPrice',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 0.3
		},
		unitGrossPrice: {
			text: 'column.unit.gross.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitGrossPrice',
			flex: 1
		},
		unitPacksize: {
			text: 'column.unit.packsize',
			dataIndex: 'UnitPacksize',
			flex: 1,
			hidden: true
		},
		netAmount: {
			text: 'column.net.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'NetAmount',
			flex: 1
		},
		taxAmount: {
			text: 'column.tax.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'TaxAmount',
			flex: 1
		},
		grossAmount: {
			text: 'column.gross.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'GrossAmount',
			flex: 1
		}
	},
	document_locks: {
		id: {
			text: 'column.lock.id',
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		businessModule: {
			text: 'document.businessModule',
			dataIndex: 'businessModule',
			flex: 1
		},
		active: {
			text: 'document.active.deactive',
			dataIndex: 'active',
			flex: 1,
			renderer: function (value) {
				value =
					value == 'true'
						? edi.i18n.getMessage('document.lock.deactive')
						: edi.i18n.getMessage('document.lock.active');
				return value;
			}
		},
		typeLock: {
			text: 'column.lock.type',
			flex: 1,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('document.lock.' + value) : null;
			},
			dataIndex: 'typeLock'
		},
		code: {
			text: 'document.code.lock',
			dataIndex: 'code',
			hidden: true,
			flex: 1
		},
		additionalInfo: {
			text: 'column.additional.info',
			dataIndex: 'additionalInfo',
			flex: 1
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		}
	},
	document_attributes: {
		attributesName: {
			text: 'document.attributes.name',
			dataIndex: 'name',
			flex: 1
		},
		attributesValue: {
			text: 'column.value',
			dataIndex: 'value',
			flex: 1
		}
	},
	document_passport_attributes: {
		attributesName: {
			text: 'document.attributes.name',
			dataIndex: 'name',
			flex: 1,
			renderer: function (value) {
				switch (value) {
					case 'DocFlowID':
						return edi.i18n.getMessage('documents.column.doc.flow.id');
					case 'DocumentReferenceNumber':
						return edi.i18n.getMessage('document.number');
					case 'DocumentReferenceType':
						return edi.i18n.getMessage('document.type');
					case 'DocumentReferenceDate':
						return edi.i18n.getMessage('documents.column.date');
					default:
						return value;
				}
			}
		},
		attributesValue: {
			text: 'column.value',
			dataIndex: 'value',
			flex: 1
		}
	},
	documents_passport_mark: {
		systemID: {
			text: 'document.passport.system.id',
			dataIndex: 'SystemID',
			hidden: true,
			flex: 0.5
		},
		systemDocumentID: {
			text: 'column.scheme.matching.element.rule.condition.entityId',
			dataIndex: 'SystemDocumentID',
			flex: 0.5
		},
		documentSource: {
			text: 'column.doc.passport.doc.source',
			dataIndex: 'DocumentSource',
			flex: 1
		},
		sourceProtocol: {
			text: 'column.doc.passport.source.protocol',
			hidden: true,
			dataIndex: 'SourceProtocol',
			flex: 1
		},
		readByReceiver: {
			text: 'column.doc.passport.read.by.receiver',
			dataIndex: 'ReadByReceiver',
			flex: 1
		},
		readByReceiverProtocol: {
			text: 'column.doc.passport.read.by.receiver.protocol',
			hidden: true,
			dataIndex: 'ReadByReceiverProtocol',
			flex: 1
		},
		inboxIntergrationChannelID: {
			text: 'column.doc.passport.inbox.intergration.channel',
			hidden: true,
			dataIndex: 'InboxIntergrationChannelID',
			htmlEncode: true,
			flex: 1
		},
		outboxIntergrationChannelID: {
			text: 'column.doc.passport.outbox.intergration.channel',
			dataIndex: 'OutboxIntergrationChannelID',
			htmlEncode: true,
			flex: 1
		}
	},
	fns_document_summary_base: {
		totalWithoutNDS: {
			text: 'column.total.net.amount',
			dataIndex: 'TotalWithoutNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		mustPaid: {
			text: 'column.total.tax.amount',
			dataIndex: 'MustPaid',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'cost'
		},
		totalWithNDS: {
			text: 'column.total.gross.amount',
			dataIndex: 'TotalWithNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		}
	},
	document_tax_summary_base: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		}
	},
	document_generation_numbers: {
		id: {
			text: 'document.generation.numbers.ID',
			dataIndex: 'id',
			sortable: false,
			flex: 1
		},
		numberPart: {
			text: 'document.generation.numbers.startNr',
			dataIndex: 'numberPart',
			sortable: false,
			flex: 1
		},
		maxNumber: {
			text: 'document.generation.numbers.nr.max',
			dataIndex: 'maxNumber',
			sortable: false,
			flex: 1
		},
		stringFormat: {
			text: 'document.generation.numbers.formatString',
			dataIndex: 'stringFormat',
			sortable: false,
			flex: 2,
			renderer: (value) => value.replace(/%06d/gi, value === '%06d' ? '№' : '+ № +')
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 2,
			renderer: 'dateTimeFromMs'
		},
		typeDoc: {
			text: 'documents.column.type',
			dataIndex: 'typeDoc',
			sortable: false,
			flex: 2,
			renderer: 'doctype',
			htmlEncode: true
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 2,
			renderer: 'dateTimeFromMs'
		},
		policy: {
			text: 'document.generation.numbers.policy',
			dataIndex: 'policy',
			sortable: false,
			flex: 2,
			renderer: function (value) {
				value =
					value == 'ALLOW'
						? edi.i18n.getMessage('document.generation.numbers.ACTIVE')
						: edi.i18n.getMessage('document.generation.numbers.DEACTIVATED');
				return value;
			}
		}
	},
	documents_group_reports: {
		documents: {
			text: 'column.item.description',
			dataIndex: 'requestList',
			sortable: false,
			htmlEncode: true,
			renderer: function (value) {
				var textArray = [];
				for (var i = 0; i < value.length; i++) {
					if (!i || i == value.length - 1) {
						textArray.push(
							(value.length > 1 ? i + 1 + '. ' : '') +
								value[i].docNumber +
								', ' +
								edi.utils.formatDate(parseFloat(value[i].docDate), edi.constants.DATE_FORMAT.FNS) +
								', ' +
								edi.i18n.getMessage('documents.doctype.' + value[i].docType)
						);
					} else if (value.length > 2 && textArray.length == 1) {
						textArray.push('. . . ( ' + (value.length - 2) + ' ) . . .');
					}
				}
				return textArray.join('<br />');
			},
			flex: 3
		},
		modifyDate: {
			text: 'column.modify.date',
			dataIndex: 'modifyDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			sortable: true
		},
		reportStatus: {
			text: 'column.report.format.status',
			dataIndex: 'status',
			renderer: 'reportStatus',
			flex: 1
		}
	},
	documents_in_print_report: {
		rowNumber: {
			xtype: 'rownumberer',
			text: 'column.line.number',
			width: 40,
			minWidth: 40,
			maxWidth: 40,
			sortable: false
		},
		number: {
			text: 'documents.column.number',
			sortable: false,
			flex: 1,
			dataIndex: 'docNumber'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'docDate',
			flex: 1.5,
			renderer: 'docTime'
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'docType',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		state: {
			text: 'column.report.format.status',
			dataIndex: 'state',
			renderer: 'reportStatus',
			flex: 1
		}
	},
	documents_notify: {
		name: {
			text: 'column.name',
			dataIndex: 'name',
			renderer: function (value) {
				return edi.i18n.getMessage('document.mark.text.' + value);
			},
			flex: 2
		},
		creationDate: {
			text: 'column.creation.date',
			dataIndex: 'creationDate',
			renderer: 'dateTimeFromMs',
			flex: 1
		},
		value: {
			text: 'column.text',
			dataIndex: 'value',
			flex: 1
		}
	}
});

edi.columns.addColumns({
	documents_available_factoring: {
		parents: 'documents_available',
		config: {}
	},
	documents_all_statuses: {
		parents: 'documents_all_client',
		config: {
			modifyDate: {
				order: 5.5,
				text: 'documents.column.date'
			},
			creationDate: undefined,
			orderNumber: undefined,
			desadvNumber: undefined,
			contractNumber: undefined,
			doctime: undefined,
			documentFunctionCode: undefined,
			documentVersion: undefined,
			pointName: undefined,
			pointAddress: undefined,
			totalSumm: undefined,
			deliveryGLN: undefined,
			totalTaxAmount: undefined,
			totalNetAmount: undefined
		}
	},
	documents_all_warning_grid: {
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1,
			renderer: 'doctype',
			htmlEncode: true
		},
		totalSumm: {
			text: 'documents.column.summ',
			dataIndex: 'totalSumm',
			flex: 1
		}
	},
	edi_delfor_lines: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		buyerCode: {
			text: 'column.artikul.buyer',
			dataIndex: 'BuyerCode',
			flex: 1
		},
		supplierComment: {
			text: 'column.supplier.comment',
			dataIndex: 'SupplierComment',
			flex: 1
		},
		orderCalenderCode: {
			text: 'column.order.calendar.code',
			dataIndex: 'OrderCalenderCode',
			flex: 1,
			renderer: 'delfor.calendarCode'
		},
		deliveryCalenderCode: {
			text: 'column.delivery.calendar.code',
			dataIndex: 'DeliveryCalenderCode',
			flex: 1,
			renderer: 'delfor.calendarCode'
		},
		exeptionCalenderCode: {
			text: 'column.exeption.calendar.code',
			dataIndex: 'ExeptionCalenderCode',
			flex: 1,
			renderer: 'delfor.calendarCode'
		},
		deliveryTerms: {
			text: 'column.delivery.terms',
			dataIndex: 'DeliveryTerms',
			flex: 1
		}
	},
	edi_delfor_shipmentpoint: {
		iln: {
			text: 'column.shipment.point.iln',
			dataIndex: 'iln',
			flex: 1
		}
	},
	iftmbf_lines: {
		iln: {
			text: 'column.iln',
			dataIndex: 'iln',
			flex: 1
		},
		Name: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'name',
			flex: 1
		},
		StreetAndNumber: {
			text: 'line.item.iftmbf.address',
			dataIndex: 'streetAndNumber',
			flex: 1
		},
		CityName: {
			text: 'column.city.name',
			dataIndex: 'cityName',
			flex: 1
		},
		estimatedDispatchDate: {
			text: 'line.item.iftmbc.estimatedDispatchDate',
			dataIndex: 'estimatedDispatchDate',
			flex: 1,
			renderer: 'fnsDateFromClient'
		},
		estimatedDispatchTime: {
			text: 'line.item.iftmbc.estimatedDispatchTime',
			dataIndex: 'estimatedDispatchTime',
			flex: 1
		}
	},
	iftmbf_routes: {
		ilnFrom: {
			text: 'column.point.ilnFrom',
			dataIndex: 'ilnFrom',
			flex: 1
		},
		ilnTo: {
			text: 'column.point.ilnTo',
			dataIndex: 'ilnTo',
			flex: 1
		},
		dateInterval: {
			text: 'column.delivery.dateInterval',
			renderer: function (value, metaData, record) {
				var dateTo = record.get('dateTo');
				return (
					edi.utils.formatDate(
						value,
						edi.constants.DATE_FORMAT.DATE_TIME_NO_S,
						edi.constants.DATE_FORMAT.XS_DATE_TIME
					) +
					' - ' +
					edi.utils.formatDate(
						dateTo,
						edi.constants.DATE_FORMAT.DATE_TIME_NO_S,
						edi.constants.DATE_FORMAT.XS_DATE_TIME
					)
				);
			},
			dataIndex: 'dateFrom',
			flex: 1
		},
		timing: {
			text: 'column.timing',
			dataIndex: 'timing',
			flex: 1
		}
	},
	iftmbc_lines: {
		iln: {
			text: 'column.iln',
			dataIndex: 'iln',
			flex: 1
		},
		Type: {
			text: 'line.item.iftmbf.type',
			dataIndex: 'type',
			flex: 1
		},
		Name: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'name',
			flex: 1
		},
		StreetAndNumber: {
			text: 'column.address',
			dataIndex: 'streetAndNumber',
			flex: 1
		},
		CityName: {
			text: 'column.city.name',
			dataIndex: 'cityName',
			flex: 1
		},
		estimatedDispatchDate: {
			text: 'line.item.iftmbc.estimatedDispatchDate',
			dataIndex: 'estimatedDispatchDate',
			flex: 1,
			renderer: 'fnsDateFromClient'
		},
		estimatedDispatchTime: {
			text: 'line.item.iftmbc.estimatedDispatchTime',
			dataIndex: 'estimatedDispatchTime',
			flex: 1
		}
	},
	iftmbf_approval_route: {
		ilnFrom: {
			text: 'column.point.ilnFrom',
			dataIndex: 'routeFrom',
			flex: 1
		},
		addressFrom: {
			text: 'document.iftmbf_approval.routes.addressFrom',
			dataIndex: 'addressfrom',
			flex: 1,
			renderer: function (value, meta) {
				meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(value) + '"';
				return value;
			}
		},

		ilnTo: {
			text: 'column.point.ilnTo',
			dataIndex: 'routeTo',
			flex: 1
		},

		addressTo: {
			text: 'document.iftmbf_approval.routes.addressTo',
			dataIndex: 'addressto',
			flex: 1,
			renderer: function (value, meta) {
				meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(value) + '"';
				return value;
			}
		},
		dateInterval: {
			text: 'column.delivery.dateInterval',
			dataIndex: 'dateFrom',
			flex: 1,
			renderer: function (value, metaData, record) {
				var dateFrom = record.get('dateFrom'),
					dateTo = record.get('dateTo'),
					strFrom = edi.utils.formatDate(
						dateFrom,
						edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
						edi.constants.DATE_FORMAT.DOCUMENT
					),
					strTo = edi.utils.formatDate(
						dateTo,
						edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
						edi.constants.DATE_FORMAT.DOCUMENT
					);

				return strFrom + ' - ' + strTo;
			}
		},
		timing: {
			text: 'column.timing',
			dataIndex: 'timing',
			flex: 1
		}
	},
	deferred_report: {
		id: {
			text: 'column.deferred.report.id',
			dataIndex: 'reportRequest',
			renderer: function (value) {
				return value ? value.id : '';
			},
			flex: 1,
			sortable: false,
			hidden: true
		},
		state: {
			text: 'column.deferred.report.state',
			dataIndex: 'state',
			renderer: function (value) {
				return value ? edi.i18n.getMessage('deferred.report.state.' + value) : '';
			},
			flex: 1
		},
		requestType: {
			text: 'column.deferred.report.request.type',
			dataIndex: 'requestType',
			renderer: function (value) {
				return value ? edi.i18n.getMessage('deferred.report.type.' + value) : '';
			},
			flex: 1
		},
		format: {
			text: 'column.deferred.report.format',
			dataIndex: 'attributes',
			renderer: function (value) {
				return value && value.reportFormat ? value.reportFormat.value : '';
			},
			flex: 1,
			sortable: false
		},
		creationDate: {
			text: 'documents.column.creationDate',
			flex: 1,
			dataIndex: 'creationDate',
			renderer: 'dateTimeNoS'
		}
	},
	x5_agreement_container: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			hidden: true,
			sortable: false
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			sortable: false,
			flex: 2,
			renderer: 'doctype',
			htmlEncode: true
		},
		modifyDate: {
			text: 'column.fhp.reports.modifyDate',
			dataIndex: 'modifyDate',
			renderer: edi.renderers.dateTimeFromMs,
			flex: 1,
			sortable: true
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		fromOrg: {
			text: 'documents.column.fromOrg',
			dataIndex: 'fromOrg',
			renderer: 'organization',
			flex: 2
		},
		toOrg: {
			text: 'documents.column.toOrg',
			dataIndex: 'toOrg',
			renderer: 'organization',
			flex: 2
		}
	},
	user_email_notifications: {
		orgPartner_id: {
			text: 'documents.column.partner',
			dataIndex: 'orgPartner.id',
			sortable: false,
			order: 30,
			flex: 1.5,
			renderer: function (val, meta, record) {
				if (record.get('allPartners')) {
					return edi.i18n.getMessage('email.notifications.partners.all');
				}

				let partners = record.get('orgPartners') || [];
				return partners
					.map(function (p) {
						return p.name;
					})
					.join(', ');
			}
		},
		emailList: {
			text: 'user_email_notification.emailList',
			dataIndex: 'notificationEmails',
			sortable: false,
			order: 40,
			flex: 1.5
		},
		creationDate: {
			text: 'documents.column.creationDate',
			order: 50,
			flex: 1,
			dataIndex: 'creationDate',
			renderer: 'dateTimeNoS'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			order: 60,
			flex: 1,
			renderer: 'dateTimeFromMs'
		}
	},
	delcat_lines: {
		parents: 'addresscatalog_lines',
		config: {
			activityStatus: undefined
		}
	}
});

edi.columns.addColumns({
	bank_account: {
		bik: {
			text: 'column.bik',
			dataIndex: 'bik',
			flex: 1,
			sortable: false
		},
		corrAccNumber: {
			text: 'column.corr.acc.number',
			dataIndex: 'corrAccNumber',
			flex: 1,
			sortable: false
		},
		number: {
			text: 'company.bank.account',
			dataIndex: 'number',
			flex: 1,
			sortable: false
		},
		preferredOwnerName: {
			text: 'column.preferred.owner.name',
			dataIndex: 'preferredOwnerName',
			flex: 1,
			sortable: false
		}
	},
	on_service_container: {
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 2,
			renderer: 'doctype',
			htmlEncode: true
		},
		state: {
			text: 'documents.column.status',
			dataIndex: 'state',
			flex: 1,
			renderer: 'columnStatus',
			htmlEncode: true
		},
		senderSignatureExpected: {
			text: 'document.create.on.service.container.need.signatures',
			dataIndex: 'senderSignatureExpected',
			flex: 1,
			renderer: function (val) {
				return edi.renderers.yesNo(typeof val === 'boolean' ? val : val == 'true');
			},
			sortable: false
		},
		number: {
			text: 'documents.column.number',
			renderer: function (value, metaData, record) {
				var file = record.get('file');
				if (file && file.fileName) {
					return file.fileName;
				}
				var number = record.get('number');
				return number;
			},
			flex: 1,
			hidden: true
		}
	},
	retail_networks: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true,
			sortable: true
		},
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 2
		},
		gln: {
			text: 'company.gln.short',
			dataIndex: 'gln',
			flex: 1
		}
	},
	report_templates: {
		displayName: {
			text: 'column.name',
			dataIndex: 'displayName',
			flex: 3
		},
		docType: {
			text: 'documents.column.type',
			dataIndex: 'docType',
			renderer: 'doctype',
			flex: 2,
			htmlEncode: true
		}
	},
	file_attributes: {
		displayName: {
			text: 'file.storage.attributes.grid.name',
			dataIndex: 'attrName',
			flex: 2
		},
		docType: {
			text: 'file.storage.attributes.grid.value',
			dataIndex: 'attrValue',
			flex: 3
		}
	},
	export_group_request: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		state: {
			text: 'column.fhp.reports.lines.state',
			dataIndex: 'state',
			renderer: function (value) {
				return [
					edi.constants.EXPORT_GROUP_REQUEST.STATE.DRAFT,
					edi.constants.EXPORT_GROUP_REQUEST.STATE.IN_PROGRESS,
					edi.constants.EXPORT_GROUP_REQUEST.STATE.READY,
					edi.constants.EXPORT_GROUP_REQUEST.STATE.COMPLETED,
					edi.constants.EXPORT_GROUP_REQUEST.STATE.REJECTED
				].some((it) => it === value)
					? edi.i18n.getMessage('export.group.request.state.' + value)
					: '';
			},
			flex: 1,
			sortable: true
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		user: {
			text: 'column.log.user',
			dataIndex: 'user',
			flex: 2,
			renderer: 'user'
		}
	},
	docunent_dsf_recadv_diff_act: {
		parents: 'document_lines_base',
		config: {
			lineNumber: {
				flex: 0.2
			},
			plu: {
				text: 'document.dsf.plu',
				dataIndex: 'plu',
				flex: 1
			},
			name: {
				text: 'line.item.product.description',
				dataIndex: 'name',
				flex: 1
			},
			acceptedAmount: {
				text: 'document.dsf.acceptedAmount',
				dataIndex: 'acceptedAmount',
				flex: 1
			},
			unitOfMeasure: {
				text: 'product.unit.name',
				dataIndex: 'unitOfMeasure',
				flex: 1,
				hidden: false,
				renderer: function (value) {
					return edi.i18n.getMessage('document.dsf.unitOfMeasure.' + value);
				}
			},
			inadequateQuality: {
				text: 'document.dsf.inadequateQuality',
				dataIndex: 'inadequateQuality',
				flex: 1
			},
			expired: {
				text: 'document.dsf.expired',
				dataIndex: 'expired',
				flex: 1
			},
			damage: {
				text: 'document.dsf.damage',
				dataIndex: 'damage',
				flex: 1
			},
			overSpecified: {
				text: 'document.dsf.overSpecified',
				dataIndex: 'overSpecified',
				flex: 1
			},
			discrepanciesReason: {
				text: 'document.dsf.discrepanciesReason',
				dataIndex: 'discrepanciesReason',
				flex: 1
			},
			deficit: {
				text: 'document.dsf.deficit',
				dataIndex: 'deficit',
				flex: 1
			},
			ean: undefined,
			itemDescription: undefined,
			originCountry: undefined,
			buyerItemCode: undefined,
			supplierItemCode: undefined,
			quantityOrdered: undefined,
			quantityDespatched: undefined,
			unitNetPrice: undefined,
			taxRate: undefined,
			unitGrossPrice: undefined,
			unitPacksize: undefined,
			netAmount: undefined,
			taxAmount: undefined,
			grossAmount: undefined
		}
	},
	document_logset: {
		parents: 'document_lines_base',
		config: {
			lineNumber: {
				flex: 0.2
			},
			deliveryPointIln: {
				text: 'document.logset.delivery.point.iln',
				dataIndex: 'deliveryPointIln',
				flex: 1
			},
			branch: {
				text: 'document.logset.branch',
				dataIndex: 'branch',
				flex: 1
			},
			conditionStorage: {
				text: 'document.logset.condition.storage',
				dataIndex: 'conditionStorage',
				flex: 1
			},
			hierarchyLevel: {
				text: 'document.logset.hierarchy.level',
				dataIndex: 'hierarchyLevel',
				flex: 1
			},
			multiplicity: {
				text: 'document.logset.multiplicity',
				dataIndex: 'multiplicity',
				hidden: true,
				flex: 1,
				renderer: function (value) {
					return edi.i18n.getMessage('document.logset.multiplicity.' + value);
				}
			},
			minimumWeight: {
				text: 'document.logset.minimum.weight',
				dataIndex: 'minimumWeight',
				hidden: true,
				flex: 1,
				renderer: function (value) {
					if (!value) {
						return '';
					}
					var store = edi.stores.initWeightOfOrder();
					var item = store.findRecord('id', value, 0, false, true, true);
					return item ? item.get('name') : value;
				}
			},
			deliveryTime: {
				text: 'document.logset.delivery.time',
				dataIndex: 'deliveryTime',
				hidden: true,
				flex: 1
			},
			postTime: {
				text: 'document.logset.post.time',
				dataIndex: 'postTime',
				hidden: true,
				flex: 1,
				renderer: function (value) {
					if (!value) {
						return '';
					}
					var store = edi.stores.initDocumentLogsetPostTimeStore();
					var item = store.findRecord('id', value, 0, false, true, true);
					return item ? item.get('name') : value;
				}
			},
			condition: {
				text: 'document.logset.post.condition',
				dataIndex: 'condition',
				flex: 1,
				renderer: function (value) {
					if (!value) {
						return '';
					}
					return edi.i18n.getMessage('document.logset.condition.' + value);
				}
			},
			deliveryType: {
				text: 'document.logset.delivery.type',
				dataIndex: 'deliveryType',
				flex: 1,
				renderer: function (value) {
					if (!value) {
						return '';
					}
					return edi.i18n.getMessage('document.logset.delivery.type.' + value);
				}
			},
			schedule: {
				text: 'document.logset.schedule',
				dataIndex: 'schedule',
				flex: 1,
				renderer: function (value) {
					if (!value) {
						return '';
					}
					return edi.i18n.getMessage('document.logset.schedule.' + value);
				}
			},
			minPallets: {
				text: 'document.logset.min.pallets',
				dataIndex: 'minPallets',
				flex: 1
			},
			pallets: {
				text: 'document.logset.pallets',
				dataIndex: 'pallets',
				hidden: true,
				flex: 1
			},
			minPurchase: {
				text: 'document.logset.min.purchase',
				dataIndex: 'minPurchase',
				flex: 1
			},
			deliveryTimeFromSending: {
				text: 'document.logset.delivery.time.from.sending',
				dataIndex: 'deliveryTimeFromSending',
				hidden: true,
				flex: 1
			},
			frequency: {
				text: 'document.logset.frequency',
				dataIndex: 'frequency',
				flex: 1
			},
			confirmation: {
				text: 'document.logset.confirmation',
				dataIndex: 'confirmation',
				hidden: true,
				flex: 1
			},
			ean: undefined,
			itemDescription: undefined,
			originCountry: undefined,
			buyerItemCode: undefined,
			supplierItemCode: undefined,
			quantityOrdered: undefined,
			quantityDespatched: undefined,
			unitNetPrice: undefined,
			taxRate: undefined,
			unitGrossPrice: undefined,
			unitPacksize: undefined,
			netAmount: undefined,
			taxAmount: undefined,
			grossAmount: undefined
		}
	},
	partin_selection_modal: {
		iln: {
			text: 'column.iln',
			dataIndex: 'iln',
			flex: 0.5,
			sortable: false
		},
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 1,
			sortable: true
		},
		state: {
			text: 'company.region',
			dataIndex: 'state',
			flex: 1,
			sortable: false
		},
		district: {
			text: 'company.district',
			dataIndex: 'district',
			flex: 1,
			sortable: false
		},
		cityName: {
			text: 'column.city.name',
			dataIndex: 'cityName',
			flex: 1,
			sortable: false
		},
		locality: {
			text: 'company.community',
			dataIndex: 'locality',
			flex: 1
		},
		streetAndNumber: {
			text: 'company.street',
			dataIndex: 'streetAndNumber',
			flex: 1,
			sortable: false
		},
		houseNumber: {
			text: 'company.home',
			dataIndex: 'houseNumber',
			sortable: false,
			flex: 1
		},
		housing: {
			text: 'column.housing',
			dataIndex: 'housing',
			hidden: true,
			sortable: false,
			flex: 1
		},
		roomNumber: {
			text: 'company.flat.location',
			dataIndex: 'roomNumber',
			hidden: true,
			flex: 1,
			sortable: false
		},
		headquarterName: {
			text: 'catalog.partin.headquarter.name',
			dataIndex: 'headquarter.name',
			flex: 1,
			hidden: true,
			sortable: false,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('headquarter') && record.get('headquarter').name
					? record.get('headquarter').name
					: '';
			}
		},
		headquarterIln: {
			text: 'catalog.partin.headquarter.iln',
			dataIndex: 'headquarter.iln',
			flex: 1,
			hidden: true,
			sortable: false,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('headquarter') && record.get('headquarter').iln ? record.get('headquarter').iln : '';
			}
		},
		branchName: {
			text: 'catalog.partin.branches.name',
			dataIndex: 'branch.name',
			flex: 1,
			hidden: true,
			sortable: false,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('branch') && record.get('branch').name ? record.get('branch').name : '';
			}
		},
		branchIln: {
			text: 'catalog.partin.branches.iln',
			dataIndex: 'branch.iln',
			flex: 1,
			hidden: true,
			sortable: false,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('branch') && record.get('branch').iln ? record.get('branch').iln : '';
			}
		}
	},
	partin_delivery_place: {
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 2
		},
		iln: {
			text: 'company.gln.short',
			dataIndex: 'iln',
			flex: 1
		}
	},
	documents_modal: {
		number: {
			text: 'column.license.number',
			dataIndex: 'number',
			sortable: false,
			flex: 1
		},
		type: {
			text: 'column.type',
			dataIndex: 'type',
			htmlEncode: true,
			renderer: 'doctype',
			sortable: false,
			flex: 1
		},
		businessState: {
			text: 'documents.column.status',
			dataIndex: 'businessState',
			htmlEncode: true,
			renderer: 'columnStatus',
			sortable: false,
			flex: 1
		}
	},
	documents_modal_with_error: {
		number: {
			text: 'column.license.number',
			dataIndex: 'number',
			sortable: false,
			flex: 1
		},
		type: {
			text: 'column.type',
			dataIndex: 'type',
			htmlEncode: true,
			renderer: 'doctype',
			sortable: false,
			flex: 1
		},
		state: {
			text: 'column.status',
			dataIndex: 'state',
			htmlEncode: true,
			renderer: 'status',
			sortable: false,
			flex: 1
		},
		error: {
			text: 'documents.list.document.error',
			dataIndex: 'error',
			htmlEncode: true,
			sortable: false,
			flex: 2,
			variableRowHeight: true,
			renderer: function (value, metaData) {
				metaData.tdCls = 'column-error-description';
				return value;
			}
		}
	},
	poa_select_list: {
		internalPoaNumber: {
			text: 'power.of.attorney.internal.number',
			dataIndex: 'internalPoaNumber',
			flex: 1,
			sortable: false
		},
		poaNumber: {
			text: 'power.of.attorney.external.number',
			dataIndex: 'poaNumber',
			flex: 1,
			sortable: false
		},
		validFrom: {
			text: 'power.of.attorney.valid.from',
			dataIndex: 'validFrom',
			flex: 1,
			renderer: 'dateFromMs',
			sortable: true
		},
		validTo: {
			text: 'power.of.attorney.valid.to',
			dataIndex: 'validTo',
			flex: 1,
			renderer: 'dateFromMs',
			sortable: true
		},
		orgName: {
			text: 'power.of.attorney.principal.org.column',
			dataIndex: 'orgName',
			flex: 1,
			sortable: false
		},
		confidants: {
			text: 'power.of.attorney.confidants.column',
			dataIndex: 'confidants',
			renderer: function (value, meta) {
				let result = '';

				if (Array.isArray(value)) {
					let fioList = value.map(function (elem) {
						elem = elem || {};
						let lastName = elem.surname || '';

						let firstName = (elem.name || '').substring(0, 1);
						firstName = firstName ? firstName + '.' : '';

						let patronymic = (elem.middleName || '').substring(0, 1);
						patronymic = patronymic ? patronymic + '.' : '';

						return `${lastName} ${firstName} ${patronymic}`;
					});

					result = fioList.filter((str) => !!str.trim()).join(', ');
				}

				meta.tdAttr = 'data-qtip="' + result + '"';
				return result;
			},
			flex: 1,
			sortable: false
		},
		origFromOrg: {
			text: 'power.of.attorney.orig.from.org.column',
			dataIndex: 'origFromOrg',
			flex: 1,
			sortable: false,
			renderer: function (value, meta) {
				return value || edi.i18n.getMessage('power.of.attorney.without.trust');
			}
		}
	}
});
