import { AsyncRequestResponseData } from '@App/js/definitions/request';

export enum PackageType {
	OPEN = 'OPEN',
	ENCLOSED = 'ENCLOSED'
}

export interface DocumentPackageParty {
	id: number;
	name: string;
	inn: string;
	iln: string;
	kpp: string;
	regNr: string;
	fnsId: string;
	fnsCode: string;
}

export interface DocumentPackageHeader {
	number: number;
	docTime: number;
	documents: number[];
	remark: string;
	id: number;
	guid: string;
	state: string;
	type: PackageType;
	creationDate: number;
	modifyDate: number;
	fromOrg: DocumentPackageParty;
	toOrg: DocumentPackageParty;
}

export interface CreatePackageValues {
	type: DocumentPackageHeader['type'];
	number: DocumentPackageHeader['number'];
	docTime: DocumentPackageHeader['docTime'];
	remark: DocumentPackageHeader['remark'];
	documents: DocumentPackageHeader['documents'];
	fromOrg: DocumentPackageParty['id'];
	toOrg: DocumentPackageParty['id'];
}

export const documentPackageApi = {
	endpoints: {
		GET_PACKAGES: edi.rest.prfx.c + 'documents/package',
		GET_PACKAGE_BY_ID: edi.rest.prfx.c + 'documents/package/{packageId}',
		POST_CREATE_PACKAGE: edi.rest.prfx.c + 'documents/package',
		PUT_EDIT_PACKAGE: edi.rest.prfx.c + 'documents/package/{packageId}/push',
		PUT_DELETE_PACKAGE: edi.rest.prfx.c + 'documents/package/{packageId}/push',
		PUT_PUSH_PACKAGE: edi.rest.prfx.c + 'documents/package/{packageId}/push',
		PUT_SEND_ENCLOSED_PACKAGE: edi.rest.prfx.c + 'roseu/integration/create/logMsg/pkgresponse/{packageId}',
		PUT_MERGE_PACKAGES: edi.rest.prfx.c + 'documents/package/merge',
		GET_DOCUMENTS_IN_PACKAGE: edi.rest.prfx.c + 'documents/package/{packageId}/docs',
		PUT_LINK_DOCUMENTS: edi.rest.prfx.c + 'documents/package/link/{packageId}',
		PUT_UNLINK_DOCUMENTS: edi.rest.prfx.c + 'documents/package/unlink/{packageId}'
	},
	fetchFullPackageData: async function (
		packageId: number
	): Promise<
		| { packageData: DocumentPackageHeader; packageItems: DocumentHeader[]; error: null }
		| { packageData: null; packageItems: null; error: AnyObject }
	> {
		if (!packageId) {
			return { packageData: null, packageItems: null, error: {} };
		}

		let packageData: DocumentPackageHeader | undefined;
		let packageItems: DocumentHeader[] | undefined;
		let error: AnyObject | undefined;

		const dataPromise = documentPackageApi.fetchPackageHeader(packageId);
		dataPromise.then(({ success, header, errorResponse }) => {
			if (success) {
				packageData = header;
			} else {
				error = errorResponse;
			}
		});

		const itemsPromise = documentPackageApi.fetchPackageItems(packageId);
		itemsPromise.then(({ success, items, errorResponse }) => {
			if (success) {
				packageItems = items;
			} else {
				error = errorResponse;
			}
		});

		await Promise.allSettled([dataPromise, itemsPromise]);
		return packageData && packageItems
			? { packageData, packageItems, error: null }
			: { packageData: null, packageItems: null, error: error ?? {} };
	},
	async fetchPackageHeader(
		packageId: number
	): Promise<
		| { success: true; header: DocumentPackageHeader; errorResponse: null }
		| { success: false; header: null; errorResponse: AnyObject }
	> {
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.utils.formatString(documentPackageApi.endpoints.GET_PACKAGE_BY_ID, { packageId }),
			method: 'GET'
		});
		if (success) {
			return { success: true, header: data.data as DocumentPackageHeader, errorResponse: null };
		} else {
			return { success: false, header: null, errorResponse: data as AnyObject };
		}
	},
	async fetchPackageItems(
		packageId: number
	): Promise<
		| { success: true; items: DocumentHeader[]; errorResponse: null }
		| { success: false; items: null; errorResponse: AnyObject }
	> {
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.utils.formatString(documentPackageApi.endpoints.GET_DOCUMENTS_IN_PACKAGE, { packageId }),
			method: 'GET'
		});
		if (success) {
			return { success: true, items: data.items as DocumentHeader[], errorResponse: null };
		} else {
			return { success: false, items: null, errorResponse: data as AnyObject };
		}
	},
	createPackage: function (packageData: CreatePackageValues): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: documentPackageApi.endpoints.POST_CREATE_PACKAGE,
			method: 'POST',
			params: Ext.encode(packageData)
		});
	},
	updatePackage: function (
		packageId: DocumentPackageHeader['id'],
		packageData: CreatePackageValues
	): Promise<AsyncRequestResponseData> {
		const payload = Object.assign({}, packageData, { UPDATE: true, action: 'update' });
		return edi.rest.asyncSendRequest({
			url: edi.utils.formatString(documentPackageApi.endpoints.PUT_EDIT_PACKAGE, { packageId }),
			method: 'PUT',
			params: Ext.encode(payload)
		});
	},
	deletePackage: function (packageId: number): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: edi.utils.formatString(documentPackageApi.endpoints.PUT_DELETE_PACKAGE, { packageId }),
			method: 'PUT',
			params: Ext.encode({
				DELETE: true
			})
		});
	},
	linkDocuments: function (packageId: number, docIds: number[]): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: edi.utils.formatString(documentPackageApi.endpoints.PUT_LINK_DOCUMENTS, { packageId }),
			method: 'PUT',
			params: Ext.encode(docIds)
		});
	},
	unlinkDocuments: function (packageId: number, docIds: number[]): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: edi.utils.formatString(documentPackageApi.endpoints.PUT_UNLINK_DOCUMENTS, { packageId }),
			method: 'PUT',
			params: Ext.encode(docIds)
		});
	},
	mergePackages: function (packageIds: number[]): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: documentPackageApi.endpoints.PUT_MERGE_PACKAGES,
			method: 'PUT',
			params: Ext.encode(packageIds)
		});
	}
};
