import { SendRequestResponse } from '@App/js/definitions/request';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import {
	createContainer,
	createFieldBlockForDetails,
	createMaxWidthContainerForDetail
	// @ts-ignore
} from '@UIkit/components/panels';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
// @ts-ignore
import { ATTACHED_ELEMENTS_PLUGIN_INJECT_PROPS, PTYPE_ATTACHED_ELEMENTS } from '@UIkit/plugins';
import { getAcceptance } from '@Ediweb/modules/ACCEPTANCE/acceptance';
import { createActionsButton } from '@Components/buttons';

export class DocumentDetailsAcceptance {
	[key: string]: any;

	moduleData: ModuleData<DocumentHeader>;
	headerData: DocumentHeader;
	documentContent: AnyObject;

	constructor() {
		const me = this;
		//Биндим методы, т.к. они будут передаваться как колбэки и вызываться опосредовано без контекста
		me._changeHandler = me.changeHandler.bind(this);
		me._detailsPanel = me.createDetailsPanel.bind(this);
	}

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data: ModuleData<DocumentHeader>, initCallBack: Function) {
		const me = this;
		me.moduleData = data;
		me.headerData = me.moduleData.initData.data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.documents.on('change', me._changeHandler);
	}

	changeHandler(data: ModuleData) {
		const me = this;

		edi.document.actions.changeHandler(
			data,
			me.moduleData,
			function (headerData: DocumentHeader) {
				me.moduleData.initData.data = headerData.data;
			},
			me.renderData.bind(me)
		);
	}

	createOS(selectorConfig: AnyObject): ExtComponent {
		const me = this;

		Ext.applyIf(selectorConfig, {
			cls: '',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			is_valid: true,
			readOnly: true,
			valuesByMap: true,
			disableCommonAddressRenderer: true
		});
		return createOrgSelector(selectorConfig) as ExtComponent;
	}

	createSender() {
		const me = this;

		var organizationFieldsMap = {
			company_iln: 'company_iln',
			company_inn: 'company_inn',
			company_kpp: 'company_kpp',
			company_name: 'company_name',
			contact_phone: 'contact_phone',
			contact_email: 'contact_email',
			contact_fax: 'contact_fax',
			addr_category: 'addr_category',
			addr_zip: 'addr_zip',
			addr_locality: 'addr_locality',
			addr_block: 'addr_block',
			addr_addInf: 'additionalInformation',
			addr_area: 'addr_area',
			addr_flat: 'addr_flat',
			addr_home: 'addr_home',
			addr_region_code: 'addr_region_code',
			addr_city: 'addr_city',
			addr_country: 'addr_country',
			addr_country_name: 'addr_country_name',
			addr_region_name: 'addr_region_name',
			addr_street: 'addr_street'
		};

		const sellerValues = edi.converters.convertOrgToLegacyPartie(me.headerData.fromOrg);

		return me.createOS({
			fieldsMap: organizationFieldsMap,
			fieldValues: sellerValues
		});
	}

	createReceiver() {
		const me = this;

		return me.createOS({
			fieldValues: me.documentContent,
			fieldsMap: {
				company_name: 'orgName',
				company_inn: 'inn',
				company_kpp: 'kpp',
				address: 'address',
				id: 'id'
			}
		});
	}

	getParties(): ExtComponent {
		const me = this;

		return createContainer({
			layout: {
				type: 'grid',
				gap: 24,
				area: [[6, 6]]
			},
			items: [
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.sender'),
					items: [me.createSender()]
				}),
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.receiver'),
					items: [me.createReceiver()]
				})
			]
		}) as ExtComponent;
	}

	getFile() {
		const me = this;

		return createContainer({
			layout: {
				type: 'grid',
				gap: [24, 16]
			},
			items: [
				createLabel({
					typography: 'heading_01',
					showIcon: true,
					plugins: [PTYPE_ATTACHED_ELEMENTS],
					[ATTACHED_ELEMENTS_PLUGIN_INJECT_PROPS.isInlineProp]: false,
					iconGlyph: edi.constants.ICONS.DETAILS,
					clickableIconHandler: function () {
						let acceptanceOptions = {
							isShowFromDocs: true,
							isShowFromTpl: true
						};
						if (me.headerData?.type === edi.constants.DOCUMENT_TYPES.EDI_ACCEPTANCE) {
							Object.assign(acceptanceOptions, {
								importByGuidUrl: edi.rest.services.ACCEPTANCE.CLIENT.IMPORT_BY_GUID.POST,
								signContentUrl: edi.rest.services.ACCEPTANCE.CLIENT.SIGN.GET,
								signUrl: edi.rest.services.ACCEPTANCE.CLIENT.SIGN.PUT,
								isShowFromTpl: false,
								modalLabels: {
									title: edi.i18n.getMessage('ediweb.acceptance.window.title.cp'),
									content: 'ediweb.acceptance.window.content.cp'
								}
							});
						}
						getAcceptance(me.documentContent, acceptanceOptions);
					},
					text: edi.i18n.getMessage('document.file')
				})
			]
		}) as ExtComponent;
	}

	createMaxContainer(items: ExtComponent[]): ExtComponent {
		return createMaxWidthContainerForDetail({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: items as ExtComponent[]
		}) as ExtComponent;
	}

	createDetailsPanel() {
		const me = this;

		const headPanel = createDocumentHeaderPanel(me.moduleData.initData, me.createDocumentHeaderConfig());

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [headPanel, me.createMaxContainer([me.getParties(), me.getFile()].filter(Boolean) as ExtComponent[])]
		});
	}

	createModuleActionsPanel() {
		const me = this;

		const data = me.headerData;

		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);

		let actionsPanel = createActionsPanel();

		const actionProps = {
			REFRESH: {
				showInFirstHalf: true,
				handler: function () {
					me._changeHandler(data);
				}
			},
			EXPORT: {
				showInFirstHalf: true,
				component: createActionsButton({
					text: edi.i18n.getMessage('action.export.document'),
					glyph: edi.constants.ICONS.FILE_DOWNLOAD,
					handler: function () {
						var url = edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
							documentId: me.documentContent.header
						});
						edi.rest.downloadFile(url, me.documentContent.header);
					}
				}),
				isAvailable: () => true
			}
		};

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: me.moduleData,
			infoTitle: edi.i18n.getMessage(`documents.doctype.${me.headerData?.type}`),
			infoSubTitle: `№${me.headerData?.number}`,
			actionProps: actionProps
		});

		return actionsPanel;
	}

	createDocumentHeaderConfig() {
		const me = this;
		const mainData = Ext.clone(me?.moduleData?.initData?.data) ?? {};

		return {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			noLinked: true,
			noUsage: true,
			noLog: true
		};
	}

	addModuleActionsPanel() {
		const me = this;
		me.moduleData.tab.add(me.createModuleActionsPanel());
	}

	failure(responseData: SendRequestResponse) {
		const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
		edi.core.showWarn(errorMsg);
	}

	async continueWithRendering(initCallBack?: Function) {
		const me = this;

		let modulePanel = createDetailsModulePanel() as ExtComponent;

		if (me.moduleData.tab.isDestroyed) {
			return;
		}

		modulePanel.add(me.createDetailsPanel());
		me.moduleData.tab.removeAll();

		me.addModuleActionsPanel();
		me.moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		} else {
			me.moduleData.tab.setLoading(false);
		}
	}
	async renderData(initCallBack?: Function) {
		const me = this;

		me.moduleData.tab.setLoading();

		const url = edi.document.actions.formatHeaderUri(me.moduleData.initData);
		edi.rest.sendRequest(
			url,
			'GET',
			undefined,
			function (data: SendRequestResponse) {
				if (data && data.data) {
					me.moduleData.initData.data = data.data;
					me.headerData = data.data;
				}
			},
			me.failure,
			function () {
				edi.rest.sendRequest(
					edi.document.actions.formatDetailsUri(me.moduleData.initData),
					'GET',
					{},
					async function (data: SendRequestResponse) {
						if (data && data.data) {
							me.documentContent = data.data;
							await me.continueWithRendering(initCallBack);
						} else {
							me.failure(data);
						}
					},
					me.failure
				);
			}
		);
	}

	onDestroy() {
		const me = this;

		edi.events.documents.un('change', me._changeHandler);

		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

Ext.namespace('edi.modules');
edi.modules['acceptance.details'] = DocumentDetailsAcceptance;
