// @ts-ignore
import { createCombo, createTextField } from '@UIkit/components/fields';
import { createNamedDocumentBlock } from '../documentBlock/createDocumentBlock';
// @ts-ignore
import { createContainer, createFieldBlock, createForm, createMaxWidthContainer } from '@UIkit/components/panels';
import { SvLitsPer } from '../definitions/svLitsPer';
import { HANDING_GOODS_TYPE } from '../definitions/commonsDef';

export interface PersonHandingGoodsFieldsProps {
	prefix?: string;
	name?: string;
}

export interface PersonHandingGoodsBlockProps {
	data: AnyObject;
	[key: string]: any;
}

export class PersonHandingGoodsBlock {
	props: PersonHandingGoodsBlockProps;

	fields: {
		firstName?: ExtComponent;
		lastName?: ExtComponent;
		patronymicName?: ExtComponent;
		position?: ExtComponent;
		inn?: ExtComponent;
		orgName?: ExtComponent;
		orgInn?: ExtComponent;
		otherInformation?: ExtComponent;
		handingGoodsTypeField?: ExtComponent;
		[fieldName: string]: ExtComponent | undefined;
	};
	blocks: {
		authority?: ExtComponent;
		orgAuthority?: ExtComponent;
		orgWorkerAuthority?: ExtComponent;
		[fieldName: string]: ExtComponent | undefined;
	};

	isHasWorkers = edi.stores.initPositionsStore().data.items.length > 0;

	personHandingGoodsPersonalDataForm: ExtComponent;

	cacheValues: AnyObject;

	path: string = 'dokument.svProdPer.svPer.svLitsPer';

	constructor(props: PersonHandingGoodsBlockProps, overrides?: Partial<PersonHandingGoodsBlock>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: PersonHandingGoodsBlockProps, overrides?: Partial<PersonHandingGoodsBlock>) {
		const me = this;
		me.fields = {};
		me.blocks = {};
		Ext.merge(me, overrides);
		me.props = props ?? { data: {} };

		me.cacheValues = Ext.clone(me.props.data);
	}

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		return [me.path, ...name].filter(Boolean).join('.');
	}

	setCacheValues(values: AnyObject) {
		const me = this;
		Ext.merge(me.cacheValues, values);
	}

	createFirstNameField({ prefix, name = 'fio.imya' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		return (me.fields.firstName = createTextField({
			allowBlank: false,
			maxLength: 60,
			fieldLabel: edi.i18n.getMessage('first.name'),
			name: me.createFieldName(prefix, name),
			valueSrc: me.cacheValues
		}));
	}

	createLastNameField({ prefix, name = 'fio.familiya' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		return (me.fields.lastName = createTextField({
			allowBlank: false,
			maxLength: 60,
			fieldLabel: edi.i18n.getMessage('last.name'),
			name: me.createFieldName(prefix, name),
			valueSrc: me.cacheValues
		}));
	}

	createPatronymicNameField({ prefix, name = 'fio.otchestvo' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		return (me.fields.patronymicName = createTextField({
			allowBlank: true,
			maxLength: 60,
			fieldLabel: edi.i18n.getMessage('patronymic.name'),
			name: me.createFieldName(prefix, name),
			valueSrc: me.cacheValues
		}));
	}

	createPositionField({ prefix, name = 'dolzhnost' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const resultName = me.createFieldName(prefix, name);
		return (me.fields.position = me.isHasWorkers
			? createCombo({
					store: edi.stores.initPositionsStore(),
					displayField: 'shortName',
					forceSelection: false,
					maxLength: 255,
					allowBlank: false,
					fieldLabel: edi.i18n.getMessage('job.title'),
					name: resultName,
					value: edi.utils.getObjectProperty(me.cacheValues, resultName)
			  })
			: createTextField({
					maxLength: 255,
					allowBlank: false,
					fieldLabel: edi.i18n.getMessage('job.title'),
					name: resultName,
					valueSrc: me.cacheValues
			  }));
	}

	createInnField({ prefix, name = 'innflPer' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const validateAuthority = (value: string) => {
			if (me.blocks.authority?.blockHolder) {
				//<РеквДокТип> обязателен при отсутствии <ИННФЛПер>
				me.blocks.authority.blockHolder.allowBlank = !!value;
				me.blocks.authority.blockHolder.markAllowBlank();
			}
		};
		return (me.fields.inn = createTextField({
			maxLength: 128,
			fieldLabel: edi.i18n.getMessage('company.inn.short'),
			name: me.createFieldName(prefix, name),
			valueSrc: me.cacheValues,
			title: 'company.inn.individual',
			emptyText: edi.i18n.getMessage('company.inn.individual.error'),
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.INDI_INN,
			regexText: edi.i18n.getMessage('company.inn.individual.error'),
			allowBlank: false,
			listeners: {
				change: (cmp: ExtComponent, value: string) => validateAuthority(value),
				afterrender: (cmp: ExtComponent) => validateAuthority(cmp.getValue())
			}
		}));
	}

	createOrgNameField({ prefix, name = 'naimOrgPer' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		return (me.fields.orgName = createTextField({
			maxLength: 255,
			allowBlank: false,
			fieldLabel: edi.i18n.getMessage('organization'),
			name: me.createFieldName(prefix, name),
			valueSrc: me.cacheValues
		}));
	}

	createOrgInnField({ prefix, name = 'innyulPer' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const validateOrgAuthority = (orgInn: string) => {
			if (me.blocks.orgAuthority?.blockHolder) {
				//<РеквДокТип> обязателен при отсутствии <ИННЮЛПер>
				me.blocks.orgAuthority.blockHolder.allowBlank = !!orgInn;
				me.blocks.orgAuthority.blockHolder.markAllowBlank();
			}
		};
		return (me.fields.orgInn = createTextField({
			maxLength: 128,
			fieldLabel: edi.i18n.getMessage('company.innle'),
			name: me.createFieldName(prefix, name),
			valueSrc: me.cacheValues,
			allowBlank: false,
			title: 'company.inn.individual',
			emptyText: edi.i18n.getMessage('company.inn.error'),
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.ORG_INN,
			regexText: edi.i18n.getMessage('company.inn.error'),
			listeners: {
				change: (cmp: ExtComponent, value: string) => validateOrgAuthority(value),
				afterrender: (cmp: ExtComponent) => validateOrgAuthority(cmp.getValue())
			}
		}));
	}

	createOtherInformationField({ prefix, name = 'inieSved' }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		return (me.fields.otherInformation = createTextField({
			valueSrc: me.cacheValues,
			maxLength: 255,
			fieldLabel: edi.i18n.getMessage('company.type.legalForeigner.header.info'),
			name: me.createFieldName(prefix, name)
		}));
	}

	createAuthorityDocumentBlockHolder({
		prefix,
		name = 'osnDoverFL'
	}: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const fieldName = me.createFieldName(prefix, name);
		return (me.blocks.authority = createNamedDocumentBlock(
			{
				data: edi.utils.getObjectProperty(me.cacheValues, fieldName, true),
				name: fieldName,
				title: edi.i18n.getMessage('documents.fns_upd.individualPersonShipsGoogs.authority')
			},
			{
				isSingleDocument: true,
				onChange: (values) => {
					if (me.fields.inn) {
						//<ИННФЛТип> обязателен при отсутствии <ОснДоверФЛ>
						me.fields.inn.allowBlank = !!values;
						me.fields.inn.isValid();
					}
				}
			},
			{
				allowBlank: false
			}
		));
	}

	createOrgAuthorityDocumentBlockHolder({
		prefix,
		name = 'osnDoverOrgPer'
	}: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const fieldName = me.createFieldName(prefix, name);
		return (me.blocks.orgAuthority = createNamedDocumentBlock(
			{
				data: edi.utils.getObjectProperty(me.cacheValues, fieldName, true),
				name: fieldName,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.sellerOrganizationWorker.org.authority')
			},
			{
				isSingleDocument: true,
				onChange: (values) => {
					if (me.fields.orgInn) {
						//<ИННЮЛТип> обязателен при отсутствии <ОснДоверОргПер>
						me.fields.orgInn.allowBlank = !!values;
						me.fields.orgInn.isValid();
					}
				}
			},
			{
				allowBlank: false
			}
		));
	}

	createOrgWorkerAuthorityDocumentBlockHolder({
		prefix,
		name = 'osnPolnPredPer'
	}: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const fieldName = me.createFieldName(prefix, name);
		return (me.blocks.orgWorkerAuthority = createNamedDocumentBlock(
			{
				data: edi.utils.getObjectProperty(me.cacheValues, fieldName, true),
				name: fieldName,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.sellerOrganizationWorker.org.worker.authority')
			},
			{
				isSingleDocument: true
			}
		));
	}

	changeHandingGoodsFields(type: HANDING_GOODS_TYPE | string | null) {
		const me = this;

		me.setCacheValues(edi.utils.collectFormValues(me.personHandingGoodsPersonalDataForm));
		me.personHandingGoodsPersonalDataForm.removeAll();
		me.fields = {
			handingGoodsTypeField: me.fields.handingGoodsTypeField
		};
		me.blocks = {};

		switch (type) {
			case HANDING_GOODS_TYPE.sellerOrganizationWorker:
				me.personHandingGoodsPersonalDataForm.add(me.createSellerOrganizationWorkerHandingGoodsView());
				break;
			case HANDING_GOODS_TYPE.shippedGoodsWorker:
				me.personHandingGoodsPersonalDataForm.add(me.createShippedGoodsWorkerHandingGoodsView());
				break;
			case HANDING_GOODS_TYPE.individualPersonShipsGoogs:
				me.personHandingGoodsPersonalDataForm.add(me.createIndividualPersonShipsHandingGoodsView());
				break;
		}

		me.personHandingGoodsPersonalDataForm
			.getFieldBlock?.()
			?.setVisible(!!Object.values(HANDING_GOODS_TYPE).some((t) => t === type));
	}

	createHandingGoodsTypeField({ prefix }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const storeItems = Object.entries(HANDING_GOODS_TYPE).map(([key, value]) => ({
			id: value,
			name: edi.i18n.getMessage('documents.fns_upd.' + key)
		}));

		let value;
		const handingGoodsData: SvLitsPer | undefined = me.props.data?.dokument?.svProdPer?.svPer?.svLitsPer;
		const isSellerOrganizationWorker = !!(
			handingGoodsData &&
			'rabOrgProd' in handingGoodsData &&
			handingGoodsData.rabOrgProd?.fio
		);
		const isShippedGoodsWorker = !!(
			handingGoodsData &&
			'inLitso' in handingGoodsData &&
			handingGoodsData.inLitso &&
			'predOrgPer' in handingGoodsData.inLitso &&
			handingGoodsData.inLitso.predOrgPer?.fio
		);
		const isPersonHanding = !!(
			handingGoodsData &&
			'inLitso' in handingGoodsData &&
			handingGoodsData.inLitso &&
			'flPer' in handingGoodsData.inLitso &&
			handingGoodsData.inLitso.flPer?.fio
		);
		if (isSellerOrganizationWorker) value = '1';
		if (isShippedGoodsWorker) value = '2';
		if (isPersonHanding) value = '3';

		const handingGoodsType = (me.fields.handingGoodsTypeField = createCombo({
			store: edi.stores.createInlineStore(
				[
					{
						id: 'notSelected',
						name: edi.i18n.getMessage('form.combo.not.selected')
					}
				].concat(storeItems)
			),
			matchFieldWidth: false,
			forceSelection: false,
			editable: false,
			submitValue: false,
			value,
			listeners: {
				change: function (comp: ExtComponent, type: HANDING_GOODS_TYPE | string) {
					me.changeHandingGoodsFields(type);
				},
				afterrender: function (comp: ExtComponent) {
					me.changeHandingGoodsFields(comp.getValue());
				}
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.personHandingGoods'),
			items: handingGoodsType
		});
	}

	getFields() {
		const me = this;
		return me.fields ?? {};
	}

	getBlocks() {
		const me = this;
		return me.blocks ?? {};
	}

	createIndividualPersonShipsHandingGoodsView(): ExtComponent {
		const me = this;

		const prefix = 'inLitso.flPer';

		const firstName = me.createFirstNameField({ prefix });
		const lastName = me.createLastNameField({ prefix });
		const patronymicName = me.createPatronymicNameField({ prefix });
		const inn = me.createInnField({ prefix });
		const otherInformation = me.createOtherInformationField({ prefix });
		const authority = me.createAuthorityDocumentBlockHolder({ prefix });

		return createContainer({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						area: [
							[3, 3, 3],
							[3, 6]
						]
					},
					items: [lastName, firstName, patronymicName, inn, otherInformation]
				}),
				authority
			]
		}) as ExtComponent;
	}

	createSellerOrganizationWorkerHandingGoodsView(): ExtComponent {
		const me = this;

		const prefix = 'rabOrgProd';

		const firstName = me.createFirstNameField({ prefix });
		const lastName = me.createLastNameField({ prefix });
		const patronymicName = me.createPatronymicNameField({ prefix });
		const position = me.createPositionField({ prefix });
		const otherInformation = me.createOtherInformationField({ prefix });

		return createContainer({
			layout: {
				type: 'grid',
				area: [[3, 3, 3], [3, 6], 12]
			},
			items: [lastName, firstName, patronymicName, position, otherInformation]
		}) as ExtComponent;
	}

	createShippedGoodsWorkerHandingGoodsView(): ExtComponent {
		const me = this;

		const prefix = 'inLitso.predOrgPer';

		const firstName = me.createFirstNameField({ prefix });
		const lastName = me.createLastNameField({ prefix });
		const patronymicName = me.createPatronymicNameField({ prefix });
		const position = me.createPositionField({ prefix });
		const otherInformation = me.createOtherInformationField({ prefix });
		const orgInn = me.createOrgInnField({ prefix });
		const orgName = me.createOrgNameField({ prefix });
		const orgAuthority = me.createOrgAuthorityDocumentBlockHolder({ prefix });
		const orgWorkerAuthority = me.createOrgWorkerAuthorityDocumentBlockHolder({ prefix });

		return createContainer({
			layout: {
				type: 'grid'
			},
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						area: [[3, 3, 3], [3, 4, 3], 6]
					},
					items: [lastName, firstName, patronymicName, position, orgName, orgInn, otherInformation]
				}),
				orgAuthority,
				orgWorkerAuthority
			]
		}) as ExtComponent;
	}

	createPersonHandingGoodsBlock(): ExtComponent {
		const me = this;

		me.personHandingGoodsPersonalDataForm = createForm();

		return createContainer({
			layout: {
				type: 'grid',
				gap: 24,
				area: [6, 12]
			},
			items: [
				me.createHandingGoodsTypeField(),
				createFieldBlock({
					title: edi.i18n.getMessage('documents.fns_upd.sellerOrganizationWorker.personal.data'),
					items: me.personHandingGoodsPersonalDataForm
				})
			]
		}) as ExtComponent;
	}
}

export const createPersonHandingGoodsBlock = function (
	props: PersonHandingGoodsBlockProps,
	overrides?: Partial<PersonHandingGoodsBlock>
): ExtComponent {
	const personHandingGoodsBlock = new PersonHandingGoodsBlock(props, overrides);
	return personHandingGoodsBlock.createPersonHandingGoodsBlock() as ExtComponent;
};
