import { ActionRules } from '@App/js/action';
import { DocumentPackageHeader, PackageType } from './entities';

const actionRulesForDocumentPackageModule: ActionRules = {
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		permissionChanging: [
			{
				change: function () {
					return 'CREATE_EDI_GROUP_EXPORT_REQUEST';
				}
			}
		],
		conditions: []
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		permissionChanging: [],
		conditions: []
	},
	DELETE: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			STATE: []
		},
		conditions: []
	},
	EDIT: {
		OUTGOING: {},
		INCOMING: {
			STATE: []
		},
		permissionChanging: [
			{
				change: function () {
					return 'CREATE_PACKAGE_OBJECT';
				}
			}
		],
		conditions: [
			{
				allow: function (checkOptions) {
					const isOpen = checkOptions.packageHeader.type === PackageType.OPEN;
					return isOpen ? true : checkOptions.packageHeader.state === edi.constants.STATE.DRAFT;
				}
			}
		]
	},
	SIGN: {
		OUTGOING: {},
		INCOMING: {},
		permissionChanging: [
			{
				change: function () {
					return 'CREATE_PACKAGE_OBJECT';
				}
			}
		],
		conditions: [
			{
				allow: function (checkOptions) {
					const hasAvailableDos = (checkOptions.packageDocs as DocumentHeader[]).some((docHeader) => {
						const docRecord = edi.models.createInstance('DOCUMENT', docHeader);
						const docCheckOptions = edi.action.getDocumentData(docRecord, null, {
							actionFromPackageModule: true
						});
						return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.SIGN, docCheckOptions);
					});
					if ((checkOptions.packageHeader as DocumentPackageHeader).type === PackageType.ENCLOSED) {
						const hasRejectedDsf = (checkOptions.packageDocs as DocumentHeader[]).some(
							(doc) => doc.businessState === edi.constants.STATE.REJECTED
						);
						return !hasRejectedDsf && hasAvailableDos;
					} else {
						return hasAvailableDos;
					}
				}
			}
		]
	},
	REJECT: {
		OUTGOING: {
			STATE: []
		},
		INCOMING: {},
		permissionChanging: [
			{
				change: function () {
					return 'CREATE_PACKAGE_OBJECT';
				}
			}
		],
		conditions: [
			{
				allow: function (checkOptions) {
					return (checkOptions.packageDocs as DocumentHeader[]).some((docHeader) => {
						const docRecord = edi.models.createInstance('DOCUMENT', docHeader);
						const docCheckOptions = edi.action.getDocumentData(docRecord, null, {
							actionFromPackageModule: true
						});
						const rejectAllowed = edi.action.isAvailable(
							edi.constants.DOCUMENT_ACTIONS.REJECT,
							docCheckOptions
						);
						const clarifyAllowed = edi.action.isAvailable(
							edi.constants.DOCUMENT_ACTIONS.CLARIFY,
							docCheckOptions
						);
						return rejectAllowed || clarifyAllowed;
					});
				}
			}
		]
	},
	ANNUL: {
		OUTGOING: {},
		INCOMING: {},
		permissionChanging: [
			{
				change: function () {
					return 'CREATE_PACKAGE_OBJECT';
				}
			}
		],
		conditions: [
			{
				allow: function (checkOptions) {
					return (checkOptions.packageDocs as DocumentHeader[]).some((docHeader) => {
						const docRecord = edi.models.createInstance('DOCUMENT', docHeader);
						const docCheckOptions = edi.action.getDocumentData(docRecord, null, {
							actionFromPackageModule: true
						});
						return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.ANNUL, docCheckOptions);
					});
				}
			}
		]
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE]: actionRulesForDocumentPackageModule
});
