import { createForm } from '@Components/panels';
import { createContainer } from '@Components/miscComponents';
import { createLabel } from '@Components/fields';
import { createLink } from '@Components/buttons';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

Ext.namespace('edi.constants.CHECK_CRPT_STATUS');
edi.constants.CHECK_CRPT_STATUS.UPDATE_INTERVAL = 3000;
edi.constants.CHECK_CRPT_STATUS.PRINT_ERROR_REPORT_INTERVAL = 1000;

/**
 * Alias for edi.i18n.getMessage(key)
 * @param	{String}	key
 * @returns	{String}	translation or key itself
 */
const i18n = function (key) {
	return edi.i18n.getMessage(key, undefined, undefined, undefined);
};

/**
 * Creates marking service auth form
 * @param	{Object}	modal
 * @param	{Number}	taskId
 * @param	{Function}	onSuccess
 */
const createCrptAuthForm = function (modal, taskId, onSuccess) {
	if (modal.authForm) {
		return modal.authForm;
	}

	let authBtn = createButton({
		cls: [BUTTON_CLS.primary, 'crpt-check-modal__marking-auth-form__enter-button'],
		text: i18n('documents.fns_upd.crpt_check.btn.login'),
		margin: '0 0 0 20',
		handler() {
			edi.sign.selectCertificate(
				(cert) => {
					let errorHandler = edi.rest.getErrorHandler('error.getting.data', function () {
						authForm.setLoading(false);
					});

					authForm.setLoading(true);
					edi.sign.setSignature({
						content: modal.contentForSign,
						certificate: cert,
						options: {
							contentEncoding: 0,
							detachedSignature: false
						},
						callback(signResult) {
							if (signResult.error) {
								errorHandler(signResult.error);
							}
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.CHECK_CRPT_STATUS.MARKING_AUTH.POST, {
									taskId
								}),
								'POST',
								Ext.encode({ signature: signResult.data }),
								() => onSuccess(cert),
								errorHandler
							);
						}
					});
				},
				() => {},
				undefined,
				modal.__doc.type,
				true
			);
		}
	});

	let contentContainer = createContentContainer({
		layout: 'vbox',
		items: [
			createLabel({
				text: i18n('documents.fns_upd.contains.marked.products')
			}),
			createLabel({
				text: i18n('documents.fns_upd.crpt_check.marking_auth.header'),
				margin: '20 0 0 0'
			}),
			createContainer({
				layout: 'hbox',
				width: '100%',
				margin: '20 0 20 0',
				items: [
					createLabel({
						text: i18n('documents.fns_upd.crpt_check.marking_auth.not_registered')
					}),
					createLink({
						cls: 'crpt-check-modal__crpt_site_link',
						margin: '0 0 0 20',
						text: i18n('documents.fns_upd.crpt_check.marking_auth.register'),
						href: 'https://xn--80ajghhoc2aj1c8b.xn--p1ai/business/#tab-1-form'
					}),
					createLink({
						cls: 'crpt-check-modal__crpt_site_link',
						margin: '0 0 0 20',
						text: i18n('documents.fns_upd.crpt_check.marking_auth.more_info'),
						href: 'https://xn--80ajghhoc2aj1c8b.xn--p1ai/o-chestnom-znake/'
					})
				]
			})
		]
	});

	let buttonsContainer = createButtonsContainer({
		items: [
			createCancelBtn(modal),
			createSkipBtn(modal),
			{
				xtype: 'tbspacer',
				flex: 1
			},
			authBtn
		]
	});

	let authForm = createForm({
		cls: 'crpt-check-modal__marking-auth-form',
		layout: {
			type: 'vbox',
			align: 'center'
		},
		width: '100%',
		items: [contentContainer, buttonsContainer]
	});

	modal.authForm = authForm;
	authForm.authBtn = authBtn;
	return authForm;
};

/**
 * Creates form with error report download button
 * @param	{Object}		modal
 * @param	{Number|String}	printFormId
 * @returns	{Object}	Ext.container.Container instance
 */
const createCrptVerifyingErrorForm = function (modal, printFormId) {
	let contentContainer = createContentContainer({
		items: [
			createLabel({
				cls: 'crpt-check-error-icon',
				width: 96
			}),
			createContainer({
				layout: {
					type: 'vbox',
					align: 'left',
					pack: 'center'
				},
				items: [
					createLabel({
						text: i18n('documents.fns_upd.crpt_check.state.verify_failed')
					})
				]
			})
		]
	});

	let buttonsContainer = createButtonsContainer({
		items: [
			createSkipBtn(modal),
			{
				xtype: 'tbspacer',
				flex: 1
			},
			createButton({
				cls: [BUTTON_CLS.secondary, 'crpt-check-modal__download-error-report'],
				glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
				text: i18n('documents.fns_upd.crpt_check.action.download_error_report'),
				margin: '0 0 0 40',
				handler() {
					edi.rest.downloadFile(edi.document.actions.formatDownloadReportUrl(undefined, printFormId));
				}
			}),
			createButton({
				cls: [BUTTON_CLS.secondary, 'crpt-check-modal__download-error-report'],
				text: edi.i18n.getMessage('action.close'),
				margin: '0 0 0 20',
				handler(btn) {
					btn.setDisabled(true);
					modal.cancelChecking();
				}
			})
		]
	});

	return createForm({
		layout: {
			type: 'vbox',
			align: 'center'
		},
		width: '100%',
		items: [contentContainer, buttonsContainer]
	});
};

/**
 * Creates form with error report download button
 */
const createAuthErrorContainer = function () {
	return createContainer({
		layout: {
			type: 'vbox',
			align: 'left',
			pack: 'center'
		},
		flex: 1,
		width: '100%',
		items: [
			createLabel({
				text: i18n('error.checking.crpt.auth_error'),
				width: '100%'
			}),
			createLabel({
				text: i18n('documents.fns_upd.crpt_check.hint.send_without_checking'),
				margin: '10 0 0 0'
			})
		]
	});
};

/**
 * Send checking requests and return status in callback
 * @param	{Number}	docId
 * @param	{Number}	taskId
 * @param	{Object}	modal
 * @param	{Function}	onVerifySuccess
 */
const updateCheckingStatus = function (docId, taskId, modal, onVerifySuccess) {
	let fail = function (resp) {
		modal.setError(edi.renderers.complexErrorObject(resp));
	};

	let success = function (resp) {
		let taskState = resp?.data?.state;
		if (!taskState) {
			fail('error.checking.crpt.state');
			return;
		}

		//заменяет содержимое модалки на форму авторизации
		let createAuthForm = function () {
			if (!modal.authForm) {
				createCrptAuthForm(modal, taskId, function (selectedCertificate) {
					//после авторизации восстанавливаем изначальное содержимое и запускаем проверку статуса
					modal.selectedCertificate = selectedCertificate;
					modal.removeAll();
					modal.setWaiting();
					sendReq();
				});

				modal.setWidth(MODAL_SIZE.widthLarge);
				modal.removeAll();
				modal.add(modal.authForm);
			}
		};

		if (taskState === 'WAIT_AUTH_INFO') {
			//заранее покажем форму авторизации, но пока ждем ответа бэка блокируем кнопку авторизации
			createAuthForm();
			modal.authForm.authBtn.setDisabled(true);
			modal.authForm.authBtn.setTooltip(i18n('documents.fns_upd.crpt_check.state.connecting'));
			modal.authForm.authBtn.addCls('crpt-check-modal__marking-auth-form__enter-button__loading');
			//перезапустим опрос, что бы дождаться следующего статуса
			modal.autoUpdateTimer = setTimeout(sendReq, edi.constants.CHECK_CRPT_STATUS.UPDATE_INTERVAL);
		} else if (taskState === 'WAIT_SIGN' && resp.data?.dataForSign) {
			createAuthForm();
			modal.contentForSign = resp.data.dataForSign;
			modal.authForm.authBtn.setDisabled(false);
			modal.authForm.authBtn.setTooltip('');
			modal.authForm.authBtn.removeCls('crpt-check-modal__marking-auth-form__enter-button__loading');
		} else if (taskState === 'DONE') {
			if (resp.data.resultStatus === 'VERIFIED') {
				onVerifySuccess();
			} else {
				modal.setWidth(MODAL_SIZE.widthLarge);
				modal.removeAll();
				modal.add(createCrptVerifyingErrorForm(modal, resp.data?.printFormId));
			}
		} else if (taskState === 'AUTHENTICATION_ERROR') {
			modal.setError(createAuthErrorContainer());
		} else {
			modal.setWaiting();
			modal.autoUpdateTimer = setTimeout(sendReq, edi.constants.CHECK_CRPT_STATUS.UPDATE_INTERVAL);
		}
	};

	let url = edi.utils.formatString(edi.rest.services.CHECK_CRPT_STATUS.GET, { taskId });
	let sendReq = () => edi.rest.sendRequest(url, 'GET', null, success, fail);
	sendReq();
};

/**
 * Cancel update crpt status task on the backend
 * @param	{Number}	taskId
 * @returns	{Promise<void>}
 */
const cancelStatusUpdateTaskOnBackend = (taskId) =>
	new Promise((resolve) => {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.CHECK_CRPT_STATUS.CANCEL.GET, { taskId }),
			'GET',
			null,
			() => resolve(),
			() => edi.core.showError('documents.fns_upd.crpt_check.error.canceling.async.task', resolve),
			null,
			{ suppressDefaultError: true }
		);
	});

let createContentContainer = (cfg) =>
	createContainer(
		Ext.merge(
			{
				cls: 'crpt-check-modal__content-container',
				layout: {
					type: 'hbox',
					align: 'stretch'
				},
				width: '100%',
				items: []
			},
			cfg
		)
	);

let createButtonsContainer = (cfg) =>
	createContainer(
		Ext.merge(
			{
				cls: 'crpt-check-modal__buttons-container',
				layout: {
					type: 'hbox',
					align: 'stretch'
				},
				width: '100%',
				items: []
			},
			cfg
		)
	);

let createCancelBtn = (modal) =>
	createLink({
		text: i18n('documents.fns_upd.crpt_check.action.cancel'),
		margin: '0 16 0 0',
		handler(btn) {
			btn.setDisabled(true);
			modal.cancelChecking();
		}
	});

let createSkipBtn = (modal) =>
	createLink({
		text: i18n('documents.fns_upd.crpt_check.action.skip'),
		handler(btn) {
			btn.setDisabled(true);
			modal.skipChecking();
		}
	});

/**
 * Creates modal with info and cancel\skip buttons and starts checking
 * @param	{Object}	doc
 * @returns	{Promise<{success: Boolean, skipped: Boolean, canceled: Boolean, selectedCertificate: Object|undefined ,[error]: String|Object}>}
 */
const checkCrptValid = (doc) =>
	new Promise((resolve) => {
		let taskId = null;

		let modal = createModalPanel(
			{
				cls: 'crpt-check-modal',
				header: false,
				autoShow: true,
				minHeight: edi.constants.DEFAULT.MODAL.HEIGHT_MINIMUM,
				width: MODAL_SIZE.widthLarge,
				closable: false,
				autoUpdateTimer: undefined,
				__doc: doc,
				listeners: {
					beforeclose() {
						clearTimeout(modal.autoUpdateTimer);
						return true;
					}
				},
				layout: 'fit',
				items: [],
				async cancelChecking() {
					await cancelStatusUpdateTaskOnBackend(taskId);
					modal.close();
					resolve({ canceled: true });
				},
				async skipChecking() {
					await cancelStatusUpdateTaskOnBackend(taskId);
					modal.close();
					resolve({ skipped: true });
				},
				setWaiting(items) {
					let modalContentContainer = createContentContainer({
						items: [
							createLabel({
								cls: 'crpt-check-waiting-icon',
								width: 96
							}),
							createContainer({
								layout: {
									type: 'vbox',
									align: 'left',
									pack: 'center'
								},
								flex: 1,
								items: Array.isArray(items)
									? items
									: [
											createLabel({
												text: i18n('documents.fns_upd.contains.marked.products')
											}),
											createLabel({
												text: i18n('documents.fns_upd.crpt_check.state.waiting'),
												margin: '10 0 0 0'
											})
									  ]
							})
						]
					});

					let modalButtonsContainer = createButtonsContainer({
						items: [
							createCancelBtn(modal),
							{
								xtype: 'tbspacer',
								flex: 1
							},
							createSkipBtn(modal)
						]
					});

					modal.setWidth(MODAL_SIZE.widthLarge);
					modal.removeAll();
					modal.add(
						createForm({
							items: [modalContentContainer, modalButtonsContainer]
						})
					);
				},
				setError(content) {
					let modalContentContainer = createContentContainer({
						items: [
							createLabel({
								cls: 'crpt-check-error-icon',
								width: 96
							}),
							typeof content === 'string'
								? createLabel({
										cls: 'crpt-check-modal__content-container__error-label',
										text: content,
										flex: 1
								  })
								: content
						]
					});

					let modalButtonsContainer = createButtonsContainer({
						items: [
							createCancelBtn(modal),
							{
								xtype: 'tbspacer',
								flex: 1
							},
							createSkipBtn(modal)
						]
					});

					modal.setWidth(MODAL_SIZE.widthLarge);
					modal.removeAll();
					modal.add(
						createForm({
							items: [modalContentContainer, modalButtonsContainer]
						})
					);
				}
			},
			true
		);

		modal.setWaiting([
			createLabel({
				text: i18n('documents.fns_upd.crpt_check.state.connecting'),
				width: '100%'
			})
		]);

		let fail = function (resp) {
			modal.setError(edi.renderers.complexErrorObject(resp));
		};

		let success = function (resp) {
			taskId = resp?.data?.id;
			if (taskId) {
				updateCheckingStatus(doc.id, taskId, modal, function () {
					modal.close();
					resolve({
						success: true,
						selectedCertificate: modal.selectedCertificate
					});
				});
			} else {
				fail('error.checking.crpt.state');
			}
		};

		edi.rest.sendRequest(
			edi.rest.services.CHECK_CRPT_STATUS.POST,
			'POST',
			Ext.encode({ id: doc.id }),
			success,
			fail
		);
	});

export { checkCrptValid };
