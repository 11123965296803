import {
	createDateField,
	createHiddenField,
	createLabel,
	createLabelBlockForDetails,
	createTextField
	// @ts-ignore
} from '@UIkit/components/fields';
// @ts-ignore
import { createContainer, createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
// @ts-ignore
import { createEditableBlock } from '@UIkit/components/blocks/EditableBlock';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createBlocksHolder } from '@UIkit/components/blocks/BlocksHolder';
import { createSideBlockHolder } from '../createAddSideBlock';

export interface DocumentBlockProps {
	data: AnyObject[];
	title: string;
	[key: string]: any;
}

export class DocumentBlock {
	props: DocumentBlockProps;
	fields: {
		[fieldName: string]: ExtComponent;
	};
	sideValueName = 'rekvIdRekSost';
	blocksHolder: ExtComponent;
	isSingleDocument: boolean = false;
	isReadOnly: boolean = false;

	constructor(props: DocumentBlockProps, overrides?: Partial<DocumentBlock>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: DocumentBlockProps, overrides?: Partial<DocumentBlock>) {
		const me = this;
		me.fields = {};
		Ext.merge(me, overrides);
		me.props = props;
	}

	createNameField(values: AnyObject): ExtComponent {
		const me = this;
		const name = (me.fields.name = createTextField({
			allowBlank: false,
			maxLength: 255,
			//РеквНаимДок
			valueSrc: values,
			name: 'rekvNaimDok'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('column.doc.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: name
		});
	}

	createNumberField(values: AnyObject): ExtComponent {
		const me = this;

		const number = (me.fields.number = createTextField({
			allowBlank: false,
			maxLength: 255,
			//РеквНомерДок
			valueSrc: values,
			name: 'rekvNomerDok'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('field.name.number'),
			cls: FIELD_BLOCK_CLS.small,
			items: number
		});
	}

	createDateField(values: AnyObject): ExtComponent {
		const me = this;

		const date = (me.fields.date = createDateField({
			allowBlank: false,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			//РеквДатаДок
			valueSrc: values,
			name: 'rekvDataDok'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('date'),
			cls: FIELD_BLOCK_CLS.small,
			items: date
		});
	}

	createReqIdFileField(values: AnyObject): ExtComponent {
		const me = this;

		const reqIdFile = (me.fields.reqIdFile = createTextField({
			maxLength: 255,
			//РеквИдФайлДок
			valueSrc: values,
			name: 'rekvIdFaylDok'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.exchange.file.id'),
			cls: FIELD_BLOCK_CLS.small,
			items: reqIdFile
		});
	}

	createDocIdField(values: AnyObject): ExtComponent {
		const me = this;

		const docId = (me.fields.docId = createTextField({
			maxLength: 255,
			//РеквИдДок
			valueSrc: values,
			name: 'rekvIdDok'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.docId'),
			cls: FIELD_BLOCK_CLS.small,
			items: docId
		});
	}

	createInfoSystemURLField(values: AnyObject): ExtComponent {
		const me = this;

		const infoSystemURL = (me.fields.infoSystemURL = createTextField({
			maxLength: 500,
			//РеквУРЛСистДок
			valueSrc: values,
			name: 'rekvURLSistDok'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.information.system.url'),
			cls: FIELD_BLOCK_CLS.small,
			items: infoSystemURL
		});
	}

	createInfoSystemIDField(values: AnyObject): ExtComponent {
		const me = this;

		const informationSystemID = (me.fields.infoSystemID = createTextField({
			maxLength: 500,
			//РИдСистХранД
			valueSrc: values,
			name: 'rIdSistHranD'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.information.system.id'),
			cls: FIELD_BLOCK_CLS.small,
			items: informationSystemID
		});
	}

	createAdditionalField(values: AnyObject): ExtComponent {
		const me = this;

		const additional = (me.fields.additional = createTextField({
			maxLength: 2000,
			isTextarea: true,
			rowsHtmlAttributeValue: 4,
			//РеквДопСведДок
			valueSrc: values,
			name: 'rekvDopSvedDok'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.additional'),
			cls: FIELD_BLOCK_CLS.small,
			items: additional
		});
	}

	createTitle(): ExtComponent {
		return createLabel({
			text: edi.i18n.getMessage('document.document.add.block.document.details'),
			typography: 'heading_01'
		});
	}

	createDetailsView(values: AnyObject): ExtComponent {
		const me = this;
		return createLabelBlockForDetails({
			contents: [
				{
					title: edi.i18n.getMessage('column.doc.name'),
					text: values.rekvNaimDok
				},
				{
					title: edi.i18n.getMessage('field.name.number'),
					text: values.rekvNomerDok,
					isNewLine: true
				},
				{
					title: edi.i18n.getMessage('date'),
					text: values.rekvDataDok
				},
				{
					title: edi.i18n.getMessage('column.identifier'),
					text: values.idFajl
				}
			]
		}) as ExtComponent;
	}

	createSideBlockHolder(values: AnyObject): ExtComponent {
		const me = this;
		return createSideBlockHolder({
			data: values?.[me.sideValueName] ?? []
		});
	}

	createViewArea() {
		return [12, 8, [6, 4], [6, 6], [6, 6], 8, 12];
	}

	createViewItems(values: AnyObject): ExtComponent[] {
		const me = this;
		return [
			me.createTitle(),
			me.createNameField(values),
			me.createNumberField(values),
			me.createDateField(values),
			me.createReqIdFileField(values),
			me.createDocIdField(values),
			me.createInfoSystemURLField(values),
			me.createInfoSystemIDField(values),
			me.createAdditionalField(values)
		];
	}

	createView(values: AnyObject): ExtComponent {
		const me = this;
		return createContainer({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [
				createContainer({
					layout: {
						type: 'grid',
						area: me.createViewArea()
					},
					items: me.createViewItems(values)
				}),
				createContainer({
					items: [
						createLabel({
							typography: 'heading_01',
							text: edi.i18n.getMessage('document.parties.header')
						}),
						(me.fields.sideBlock = me.createSideBlockHolder(values))
					]
				})
			]
		}) as ExtComponent;
	}

	getValuesDocumentForm(form: ExtComponent): AnyObject {
		const me = this;
		const formValues = edi.utils.collectFormValues(form);
		if (me.fields.sideBlock) {
			const parties = Object.values(me.fields.sideBlock.getValues()).flat();
			edi.utils.setObjectProperty(formValues, me.sideValueName, parties);
		}
		return formValues;
	}

	blockConstructor(_entityName: string, values: AnyObject): ExtComponent {
		const me = this;

		return createEditableBlock({
			modal: true,
			modalConf: {
				width: MODAL_SIZE.widthMedium,
				title: values
					? edi.i18n.getMessage('document.edit.block.title')
					: edi.i18n.getMessage('document.add.block.title')
			},
			readOnly: me.isReadOnly,
			// Нужно указывать т.к. setVisible для actionsEl делается в afterRender
			// и есть видимый промежуток когда кнопки отображаются и потом скрываются
			actionsConfig: {
				edit: {
					hidden: me.isReadOnly
				},
				remove: {
					hidden: me.isReadOnly
				}
			},
			values: values,
			getItems: (editableBlock: ExtComponent, values: AnyObject) => me.createView(values),
			createView: (values: AnyObject) => me.createDetailsView(values),
			collectFormValues: me.getValuesDocumentForm.bind(me),
			onDataChange: function (editableBLock: ExtComponent, originalValues: AnyObject) {
				const blockHolderValues: AnyObject = me.blocksHolder.getValues();
				const values = Object.values(blockHolderValues).flat();
				me.onChange(me.isSingleDocument ? values[0] : values);
			}
		});
	}

	onChange(values: AnyObject[]) {}

	createBlockHolder(config?: AnyObject): ExtComponent {
		const me = this;

		me.blocksHolder = createBlocksHolder(
			Object.assign(
				{
					title: me.props.title,
					allowBlank: me.props.allowBlank,
					values: [
						{
							entityModel: 'addDocument',
							values: me.props.data ?? []
						}
					],
					entityModels: [
						{
							name: 'addDocument',
							blockConstructor: (
								_entityName: string,
								values: AnyObject,
								blockInternalName: string
							): ExtComponent => {
								const block = me.blockConstructor(_entityName, values);
								if (me.blocksHolder) me.blocksHolder.lastAddedBlock = block;
								return block;
							}
						}
					],
					addButtonConf: {
						hidden: config?.readOnly,
						text: edi.i18n.getMessage('document.fns_upd_5_02n.document.add'),
						handler: function () {
							me.blocksHolder.createNewBlockInWindow('addDocument');
						}
					},
					onChange: function (__self: ExtComponent, items: ExtComponent) {
						if (me.isSingleDocument) {
							__self.addButton.setVisible(!items.items.length);
						}

						const blockHolderValues: AnyObject = me.blocksHolder?.getValues() ?? {};
						const values = Object.values(blockHolderValues).flat();
						me.onChange(me.isSingleDocument ? values[0] : values);
					}
				},
				config
			)
		);
		return me.blocksHolder;
	}
}

export const createDocumentBlockHolder = function (
	props: DocumentBlockProps,
	overrides?: Partial<DocumentBlock>
): ExtComponent {
	const documentBlock = new DocumentBlock(props, overrides);
	return documentBlock.createBlockHolder() as ExtComponent;
};

interface NamedDocumentBlockProps extends DocumentBlockProps {
	name: string;
}
export const createNamedDocumentBlock = function (
	props: NamedDocumentBlockProps,
	overrides?: Partial<DocumentBlock>,
	blockHolderCfg?: AnyObject
): ExtComponent {
	const documentBlock = new DocumentBlock(props, {
		...overrides,
		onChange(values: AnyObject[]) {
			value = values;
			if (typeof overrides?.onChange === 'function') {
				overrides.onChange(values);
			}
		}
	});
	let value: AnyObject | null = null;
	const documentBlockHolder = documentBlock.createBlockHolder(blockHolderCfg);
	const hiddenField = createHiddenField({
		name: props.name,
		//в Ext значение приводится к строке т.к. хранится в инпуте, поэтому храним объект в переменной из замыкания
		getRawValue: () => value
	});
	return createContainer({
		blockHolder: documentBlockHolder,
		hiddenField: hiddenField,
		items: [documentBlockHolder, hiddenField]
	}) as ExtComponent;
};
