import { createFieldSet, createMaxWidthContainer } from '@UIkit/components/panels';
import { createLabel } from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createDateField, createFile, createTextField, createFieldsForProductGrid } from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createFormForModule } from '@Components/panels';
import { createActionsButton, createCreateSaveButton, createTool } from '@Components/buttons';
import { createEditableGrid } from '@Core/specialComponents/editableGrid';
import { agreementPackagesAPI } from '../rest';
import { convertFieldValueToTextInfos, convertOrgToPackageDocumentsPartie, filterGridDataByModel } from '../methods';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createButtonContainer, createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createRowsBlock } from '@Components/createRows/create.rows';

edi.modules['package.ne.document.form'] = function () {
	var moduleData,
		formConfig,
		formValues,
		docType,
		isDogPost,
		isPrilProtSogl,
		isNumberAutoGenerated = false,
		parentId,
		contendId,
		userData = edi.core.getUserData(),
		isEdit = false,
		isCreate = false,
		buyerOrg,
		seller,
		buyer,
		containerDataId,
		isReadOnly,
		createDocumentUri,
		form,
		createBtn,
		documentHasCreated,
		documentData = {},
		senderInfo = {},
		dogFileId,
		dogPostData,
		gridTextInfos = [],
		gridTextInfosData = [],
		utochContainer,
		utochChaild,
		signerOrigin = {},
		signerNew = {},
		isPrilOstrb,
		isPrilCommusl,
		isPrilKPost,
		isPrilKTov,
		isEditRowBtn = true,
		deliveryGridTextInfosData = [],
		docNumberByType = null,
		isCreateFromPdfAttachment = false,
		isCreateFromBackAttachment = false,
		isCreateFromBackOrPdf = false,
		deliverPointPanel,
		deliverPointColumns,
		twoColumnsLayout,
		createRowWithAddRemoveButtons;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.packageId ? moduleData.initData.packageId : null;
		docType = moduleData.initData.docType;
		isDogPost = docType == edi.constants.DOCUMENT_TYPES.DP_DOGPOSTNE;
		isPrilProtSogl = docType == edi.constants.DOCUMENT_TYPES.DP_PRILPROTSOGLNE;
		isPrilOstrb = docType == edi.constants.DOCUMENT_TYPES.DP_PRILOSTRBNE;
		isPrilCommusl = docType == edi.constants.DOCUMENT_TYPES.DP_PRILCOMMUSLNE;
		isPrilKPost = docType == edi.constants.DOCUMENT_TYPES.DP_PRILKPOSTNE;
		isPrilKTov = docType == edi.constants.DOCUMENT_TYPES.DP_PRILKTOVNE;
		formConfig = moduleData.initData.formConfig;
		formValues = moduleData.initData.formValues;
		buyerOrg = moduleData.initData.buyerOrg;
		dogFileId = moduleData.initData.dogFileId;
		dogPostData = moduleData.initData.dogPostData;
		isReadOnly = moduleData.initData.isReadOnly;
		documentHasCreated = moduleData.initData.documentHasCreated;
		createDocumentUri = moduleData.initData.createUri;
		switch (docType) {
			case edi.constants.DOCUMENT_TYPES.DP_PRILPROTSOGLNE:
				docNumberByType = '1';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILORGEDOCNE:
				docNumberByType = '2';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILUVIZMCENNE:
				docNumberByType = '3';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILOSTRBNE:
				docNumberByType = '4';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILCOMMUSLNE:
				docNumberByType = '5';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILSANKNE:
				docNumberByType = '6';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILKPOSTNE:
				docNumberByType = '7';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILKTOVNE:
				docNumberByType = '8';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILSHSVERNE:
				docNumberByType = '9';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILFORMZVTNE:
				docNumberByType = '10';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILFAOPTUZNE:
				docNumberByType = '11';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILANTIKORNE:
				docNumberByType = '12';
				break;
		}
		isCreateFromPdfAttachment =
			docType === edi.constants.DOCUMENT_TYPES.DP_PRILUVIZMCENNE ||
			docType === edi.constants.DOCUMENT_TYPES.DP_PRILSHSVERNE ||
			docType === edi.constants.DOCUMENT_TYPES.DP_PRILFORMZVTNE ||
			docType === edi.constants.DOCUMENT_TYPES.DP_PRILFAOPTUZNE;
		isCreateFromBackAttachment =
			docType === edi.constants.DOCUMENT_TYPES.DP_PRILSANKNE ||
			docType === edi.constants.DOCUMENT_TYPES.DP_PRILORGEDOCNE ||
			docType === edi.constants.DOCUMENT_TYPES.DP_PRILANTIKORNE;
		isCreateFromBackOrPdf = isCreateFromPdfAttachment || isCreateFromBackAttachment;
		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			documentData = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			buyerOrg = moduleData.initData.data.buyerOrg;
			isEdit = true;
			isCreate = true;
		}
		edi.document.actions.addPostalAddressForOrg(buyerOrg);
		edi.document.actions.addPostalAddressForOrg(userData.org);

		if (!documentData || ('object' == typeof documentData && Object.keys(documentData).length === 0)) {
			documentData = formValues;
			senderInfo = formValues && 'object' == typeof formValues ? formValues.senderInfo : {};
			if (
				!isDogPost &&
				(!senderInfo || Object.keys(senderInfo).length === 0) &&
				formValues &&
				formValues.documentInfo &&
				formValues.documentInfo.seller
			) {
				setSignerData(edi.utils.getObjectProperty(formValues, 'documentInfo.seller.signer'));
			}
		}
		if (moduleData.initData.isCreateUtoch) {
			formValues = formValues.document;
			documentData = formValues;
		}
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documentsPackage.on('changeSigner', changeSignerHandler);
	};

	/**
	 * Get uniform structure for signer data object
	 * @returns {{name: {lastName: null, firstName: null, middleName: null}, baseAuthory: null, position: null}}
	 */
	var getSignerDataMap = function () {
		return {
			baseAuthory: null,
			position: null,
			name: {
				lastName: null,
				firstName: null,
				middleName: null
			}
		};
	};

	var setSignerData = function (data) {
		signerOrigin = Ext.Object.merge(getSignerDataMap(), data);
		senderInfo = Ext.clone(signerOrigin);
		delete senderInfo.baseAuthory;
	};

	var setSellerSignerData = function (old_values, new_values) {
		old_values.signer_baseAuthory = new_values.baseAuthory;
		old_values.signer_firstname = new_values.name.firstName;
		old_values.signer_lastname = new_values.name.lastName;
		old_values.signer_patronymicname = new_values.name.middleName;
		old_values.signer_position = new_values.position;
	};

	var showSuccessSignerChangeModal = function () {
		edi.core.showInfo(edi.i18n.getMessage('documents.package.change.signer.info.success'));
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = buyer ? buyer.isValid() : true,
			isSellerValid = seller ? seller.isValid() : true;
		return isBuyerValid && isSellerValid;
	};
	var checkValid = function () {
		if (!form) {
			return false;
		}

		//form.isValid();
		var validForm = !form.hasInvalidField();
		var validCompanies = validateCompanySelectors();
		var textInfoGridsValid = isPrilProtSogl ? gridTextInfos.length > 0 : true;
		for (var i = 0; i < gridTextInfos.length; i++) {
			if ('function' === typeof gridTextInfos[i].isValid && !gridTextInfos[i].isValid()) {
				textInfoGridsValid = false;
				break;
			}
		}
		var isValid = validForm && validCompanies && textInfoGridsValid;

		if (createBtn && !createBtn.isDestroyed) {
			createBtn.setDisabled(!isValid);
		}
		form.updateLayout();
		return isValid;
	};

	var createMainDataBlock = function () {
		let numberDocument = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			emptyText: isNumberAutoGenerated ? edi.i18n.getMessage('document.generation.numbers.text') : '',
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			maxLength: 35,
			readOnly: true,
			value: docNumberByType || edi.utils.getObjectProperty(documentData, 'documentInfo.documentNumber'),
			name: 'documentInfo.documentNumber'
		});

		let documentDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			readOnly: true,
			value:
				edi.utils.getObjectProperty(dogPostData, 'documentInfo.documentDate') ||
				edi.utils.getObjectProperty(documentData, 'documentInfo.documentDate') ||
				new Date(),
			name: 'documentInfo.documentDate',
			submitFormat: edi.constants.DATE_FORMAT.FNS
		});

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				selectedRelationByMap: true,
				valuesByMap: true,
				useHiddenFields: true,
				disableAutoValidation: true,
				downloadBankAccountsData: true,
				disableCommonAddressRenderer: true,
				processValues: edi.converters.convertOrgToLegacyPartie,
				forceChangeFields: {
					bank_id: true,
					bank_corr_acc: true,
					bank_name: true,
					bank_acc: true
				}
			});
			return createOrgSelector(selectorConfig);
		};

		var getOrganizationFieldsMap = function (fieldsMapPrefix) {
			fieldsMapPrefix = fieldsMapPrefix ? fieldsMapPrefix + '.' : '';
			var legalEntity = 'member.memberLegalEntity.';
			if (isDogPost) {
				legalEntity = 'identification.legalEntity.';
			} else if (isPrilProtSogl || isPrilOstrb || isPrilCommusl || isPrilKPost || isPrilKTov) {
				legalEntity = 'member.legalEntity.';
			}

			var company = fieldsMapPrefix + legalEntity,
				individual = fieldsMapPrefix + (isDogPost ? 'identification.individualPerson.' : 'member.'),
				rusAddress = fieldsMapPrefix + 'legalAddress.addressRu.',
				rusAddressPost = fieldsMapPrefix + 'address.addressRu.',
				contact = fieldsMapPrefix + 'contact.',
				bank = fieldsMapPrefix + 'bankInfo.',
				signerPrefix = fieldsMapPrefix + 'signer.';

			var docPostMaps = {
				company_okpo: fieldsMapPrefix + 'okpo',
				company_okato: fieldsMapPrefix + 'okato',

				addr_rus_zip: rusAddress + 'index',
				addr_rus_region: rusAddress + 'regionCode',
				addr_rus_county: rusAddress + 'area',
				addr_rus_city: rusAddress + 'city',
				addr_rus_street: rusAddress + 'street',
				addr_rus_community: rusAddress + 'settlement',
				addr_rus_home: rusAddress + 'house',
				addr_rus_block: rusAddress + 'housing',
				addr_rus_flat: rusAddress + 'flat',

				addr_rus_zip_post: rusAddressPost + 'index',
				addr_rus_region_post: rusAddressPost + 'regionCode',
				addr_rus_county_post: rusAddressPost + 'area',
				addr_rus_city_post: rusAddressPost + 'city',
				addr_rus_street_post: rusAddressPost + 'street',
				addr_rus_community_post: rusAddressPost + 'settlement',
				addr_rus_home_post: rusAddressPost + 'house',
				addr_rus_block_post: rusAddressPost + 'housing',
				addr_rus_flat_post: rusAddressPost + 'flat',

				contact_phone: contact + 'telephone',
				contact_email: contact + 'mail',

				bank_corr_acc: bank + 'bank.coreAccountNumber',
				bank_acc: bank + 'accountNumber',
				bank_name: bank + 'bank.bankName',
				bank_id: bank + 'bank.bik'
			};

			return Object.assign(docPostMaps, {
				company_name: company + 'orgName',
				company_inn:
					company +
					(isDogPost || isPrilProtSogl || isPrilOstrb || isPrilCommusl || isPrilKPost || isPrilKTov
						? 'tin'
						: 'inn'),
				company_kpp: company + 'kpp',
				company_register_number: company + 'ogrn',

				individual_lastname: individual + (isDogPost ? 'name.lastName' : 'name.fullName.lastName'),
				individual_firstname: individual + (isDogPost ? 'name.firstName' : 'name.fullName.firstName'),
				individual_patronymicname: individual + (isDogPost ? 'name.middleName' : 'name.fullName.middleName'),
				individual_inn: individual + (isDogPost ? 'tin' : 'name.tin'),
				individual_certificateRegistrationIP:
					individual + (isDogPost ? 'certificateRegistrationIP' : 'name.certificateRegistrationIP'),

				signer_lastname: signerPrefix + 'name.lastName',
				signer_firstname: signerPrefix + 'name.firstName',
				signer_patronymicname: signerPrefix + 'name.middleName',
				signer_baseAuthory: signerPrefix + 'baseAuthory',
				signer_position: signerPrefix + 'position',

				address_type: true,
				type: true
			});
		};

		/**
		 * Checks company data validation
		 */
		var isValidOrganization = function (params) {
			params = params || {};

			var organization = params.organization;
			var useOrganizationMap = params.useOrganizationMap;

			function getPropertyName(keyName) {
				return useOrganizationMap ? getOrganizationFieldsMap()[keyName] : keyName;
			}

			var checkField = function (fieldName) {
				return !!edi.utils.getObjectProperty(organization, getPropertyName(fieldName));
			};
			var isCompany =
				checkField('company_okpo') && checkField('company_okato') && checkField('company_register_number');
			var isContact = checkField('contact_phone') && checkField('contact_email');
			var isSigner =
				params.organizationType == 'buyer' ||
				(checkField('signer_lastname') &&
					checkField('signer_firstname') &&
					checkField('signer_baseAuthory') &&
					checkField('signer_position'));
			var isAddress =
				(checkField('addr_rus_region') || (checkField('addr_for_country') && checkField('addr_for_text'))) &&
				(checkField('addr_rus_region_post') ||
					(checkField('addr_for_country_post') && checkField('addr_for_text_post')));
			var isBank = checkField('bank_acc');
			return isDogPost ? isCompany && isContact && isAddress && isBank && isSigner : isSigner;
		};

		var modalConf = edi.selectors.getFNSModalConf({
			main: {
				company_okpo: isDogPost
					? {
							allowBlank: false
					  }
					: null,
				company_gln: null,
				individual_gln: null,
				certificateRegistrationIP: {
					allowBlank: false
				},
				individual_inn: {
					allowBlank: false
				}
			},
			contact: isDogPost
				? {
						phone: {
							allowBlank: false
						},
						email: {
							allowBlank: false,
							regex: edi.constants.VALIDATORS.EMAIL,
							invalidText: edi.i18n.getMessage('invalid.email.format')
						},
						fax: null
				  }
				: null,
			bank: isDogPost
				? {
						bank_name: {
							allowBlank: false
						},
						bank_corr_acc: {
							allowBlank: false,
							regex: edi.constants.VALIDATORS.BANK_ACC,
							regexText: 'invalid.value.bank.acc'
						},
						bank_acc_select: {
							labelConfig: {
								mandatory: true
							},
							allowBlank: false,
							regex: edi.constants.VALIDATORS.BANK_ACC,
							regexText: 'invalid.value.bank.acc'
						},
						bank_id: {
							allowBlank: false,
							regex: edi.constants.VALIDATORS.BIK,
							regexText: 'invalid.value.bank.id',
							listeners: {
								change: function (cmp, newValue) {
									edi.methods.fillBankNameByBik(cmp, newValue);
								}
							}
						}
				  }
				: null,
			address: isDogPost
				? {
						region: {
							hidden: function () {
								return false;
							}
						}
				  }
				: null
		});
		if (modalConf.tabs.address) {
			modalConf.tabs.address.addressType = undefined;
			modalConf.tabs.address.foreignAddress = undefined;
		}

		var modalConfiguration = Ext.merge(Ext.clone(modalConf), {
			tabs: {
				main: {
					company_data: {
						fields: {
							company_register_number: Ext.applyIf(
								{ allowBlank: false },
								edi.selectors.defaults.company_register_number
							)
						}
					},
					company_okato: isDogPost
						? {
								title: 'company.okato',
								emptyText: 'company.okato',
								name: 'company_okato',
								allowBlank: false,
								regex: edi.constants.VALIDATORS.OKATO,
								regexText: 'company.okato.error'
						  }
						: undefined
				},
				addressPost: isDogPost
					? {
							title: 'column.email2edi.email',
							rusAddress: {
								type: 'fieldset',
								panelConf: {
									layout: 'grid'
								},
								title: 'column.email2edi.email',
								fields: {
									zip: Ext.applyIf(
										{ name: 'addr_rus_zip_post' },
										edi.selectors.defaultsFNS.address.zip
									),
									region: Ext.merge(Ext.clone(edi.selectors.defaults.region), {
										fieldConfig: {
											name: 'addr_rus_region_post',
											validator: function () {
												return (
													!!this.getValue() || edi.i18n.getMessage('error.value.mandatory')
												);
											}
										},
										hidden: function () {
											return false;
										}
									}),
									area: Ext.applyIf(
										{ name: 'addr_rus_county_post' },
										edi.selectors.defaultsFNS.address.area
									),
									city: Ext.applyIf(
										{ name: 'addr_rus_city_post' },
										edi.selectors.defaultsFNS.address.city
									),
									locality: Ext.applyIf(
										{ name: 'addr_rus_community_post' },
										edi.selectors.defaultsFNS.address.locality
									),
									street: Ext.applyIf(
										{ name: 'addr_rus_street_post' },
										edi.selectors.defaultsFNS.address.street
									),
									home: Ext.applyIf(
										{ name: 'addr_rus_home_post' },
										edi.selectors.defaultsFNS.address.home
									),
									block: Ext.applyIf(
										{ name: 'addr_rus_block_post' },
										edi.selectors.defaultsFNS.address.block
									),
									flat: Ext.applyIf(
										{ name: 'addr_rus_flat_post' },
										edi.selectors.defaultsFNS.address.flat
									)
								}
							}
					  }
					: undefined,
				signer: {
					title: 'company.signer',
					signer_lastName: {
						name: 'signer_lastname',
						title: 'last.name',
						emptyText: 'company.last.name.comment',
						allowBlank: false
					},
					signer_firstName: {
						name: 'signer_firstname',
						title: 'first.name',
						emptyText: 'company.first.name.comment',
						allowBlank: false
					},
					signer_patronymicName: {
						name: 'signer_patronymicname',
						title: 'patronymic.name',
						emptyText: 'company.patronymic.name.comment'
					},
					signer_baseAuthory: {
						name: 'signer_baseAuthory',
						title: 'company.baseAuthory',
						emptyText: 'company.baseAuthory',
						allowBlank: false
					},
					signer_position: {
						name: 'signer_position',
						title: 'job.title',
						emptyText: 'job.title',
						allowBlank: false
					}
				}
			}
		});

		var userOrganization = convertOrgToPackageDocumentsPartie(userData.org);
		userOrganization = Ext.clone(userOrganization);
		userOrganization.addr_for_country = '';
		userOrganization.addr_for_country_name = '';

		var sellerInfo = edi.utils.getObjectProperty(documentData, 'documentInfo.seller');
		if (!isDogPost && sellerInfo) {
			var identificationValue = edi.utils.getObjectProperty(sellerInfo, 'identification');
			if (identificationValue) {
				var legalEntityValue = edi.utils.getObjectProperty(sellerInfo, 'identification.legalEntity');
				if (legalEntityValue) {
					if (!isReadOnly) {
						if (legalEntityValue.hasOwnProperty('tin')) {
							edi.utils.setObjectProperty(legalEntityValue, 'name.innFl', legalEntityValue.tin);
						}
					}
					if (isPrilProtSogl || isPrilOstrb || isPrilCommusl || isPrilKPost || isPrilKTov) {
						edi.utils.setObjectProperty(sellerInfo, 'member.legalEntity', legalEntityValue);
					} else {
						edi.utils.setObjectProperty(sellerInfo, 'member.memberLegalEntity', legalEntityValue);
					}
				} else {
					var individualValue = edi.utils.getObjectProperty(sellerInfo, 'identification.individualPerson');
					if (individualValue) {
						if (individualValue.hasOwnProperty('name')) {
							edi.utils.setObjectProperty(
								individualValue,
								'name.fullName',
								Ext.clone(individualValue.name)
							);
						}
						if (individualValue.hasOwnProperty('tin')) {
							edi.utils.setObjectProperty(individualValue, 'name.tin', individualValue.tin);
						}
						if (individualValue.hasOwnProperty('certificateRegistrationIP')) {
							edi.utils.setObjectProperty(
								individualValue,
								'name.certificateRegistrationIP',
								individualValue.certificateRegistrationIP
							);
						}
						edi.utils.setObjectProperty(sellerInfo, 'member', individualValue);
					}
				}
				delete sellerInfo.identification;
				edi.utils.setObjectProperty(documentData, 'documentInfo.seller', sellerInfo);
			}
		}
		if (edi.utils.getObjectProperty(sellerInfo, 'member.memberLegalEntity.tin')) {
			edi.utils.setObjectProperty(
				sellerInfo,
				'member.memberLegalEntity.inn',
				edi.utils.getObjectProperty(sellerInfo, 'member.memberLegalEntity.tin')
			);
		}
		if (edi.utils.getObjectProperty(sellerInfo, 'member.legalEntity.tin')) {
			edi.utils.setObjectProperty(
				sellerInfo,
				'member.legalEntity.inn',
				edi.utils.getObjectProperty(sellerInfo, 'member.legalEntity.tin')
			);
		}
		var isSellerDefined = !!sellerInfo;
		var isValidSeller = isValidOrganization({
			organization: sellerInfo || userOrganization,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});

		var getSignerDataFromFormValues = function (values) {
			var isFilled = function (key) {
				return values.hasOwnProperty(key) && values[key];
			};
			var signerData = getSignerDataMap();
			signerData.baseAuthory = isFilled('signer_baseAuthory') ? values.signer_baseAuthory : null;
			signerData.position = isFilled('signer_position') ? values.signer_position : null;
			signerData.name.firstName = isFilled('signer_firstname') ? values.signer_firstname : null;
			signerData.name.lastName = isFilled('signer_lastname') ? values.signer_lastname : null;
			signerData.name.middleName = isFilled('signer_patronymicname') ? values.signer_patronymicname : null;
			return signerData;
		};

		seller = createCS({
			selectedRelationByMap: false,
			useHiddenFields: true,
			fieldValues: isSellerDefined ? documentData : userOrganization,
			valuesByMap: isSellerDefined,
			manualChangeDisabled: !isDogPost,
			selectedOrgValues: userOrganization,
			selectedOrg: userData.org,
			readOnly: isCreateFromBackOrPdf ? isCreateFromBackOrPdf : isReadOnly,
			is_valid: isCreateFromBackOrPdf ? isCreateFromBackOrPdf : isValidSeller || isEdit,
			disableAutoValidation: true,
			fieldsMap: getOrganizationFieldsMap('documentInfo.seller'),
			modalConf: Ext.merge(Ext.clone(modalConfiguration), { title: edi.i18n.getMessage('document.seller') }),
			callback: function (values) {
				signerNew = getSignerDataFromFormValues(values);

				var sellerCallback = function () {
						seller.is_valid = isValidOrganization({
							organization: values,
							organizationType: 'seller'
						});
						checkValid();
					},
					isSignerWasChanged = function () {
						var objToStr = function (obj) {
							edi.utils.clearEmptyValues(obj);
							return JSON.stringify(obj);
						};
						return objToStr(Ext.clone(signerNew)) !== objToStr(Ext.clone(signerOrigin));
					};

				if (isSignerWasChanged() && !(isDogPost && !isEdit)) {
					var confirmModal = edi.core.confirm(
						edi.i18n.getMessage('warn'),
						edi.i18n.getMessage('documents.package.change.signer.info.confirm'),
						undefined,
						undefined,
						undefined,
						undefined,
						{
							closable: false,
							buttons: [
								createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('form.btn.replace'),
									handler: function () {
										moduleData.tab.setLoading(true);
										sellerCallback();
										var params = Ext.clone(signerNew.name);
										params['pisition'] = signerNew.position;
										params['baseAuthory'] = signerNew.baseAuthory;
										edi.utils.clearEmptyValues(params);

										var success = function (data) {
											moduleData.tab.setLoading(false);
											setSignerData(signerNew);
											edi.events.documentsPackage.fireEvent('change', {
												id: moduleData.initData.packageId
											});
											edi.events.documentsPackage.fireEvent('changeSigner', {
												sellerId: seller.id,
												signerNew: signerNew,
												packageId: moduleData.initData.packageId
											});
											signerNew = {};
											showSuccessSignerChangeModal();
										};
										var failure = edi.document.actions.createSaveErrorHandler(
											isEdit,
											moduleData,
											docType
										);

										edi.rest.sendRequest(
											edi.utils.formatString(agreementPackagesAPI.SIGNER_DATA_UPDATE.PUT, {
												docId: moduleData.initData.packageId,
												containerType:
													edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE.toLowerCase()
											}),
											'PUT',
											Ext.encode(params),
											success,
											failure
										);
										confirmModal.close();
									}
								}),
								createButton({
									cls: BUTTON_CLS.secondary,
									text: edi.i18n.getMessage('btn.cancel'),
									handler: function () {
										setSellerSignerData(values, signerOrigin);
										sellerCallback();
										confirmModal.close();
									}
								})
							]
						}
					);
				} else {
					sellerCallback();
				}
			}
		});
		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.seller'),
			items: [seller]
		});

		if (isDogPost) {
			var buyerInfo = convertOrgToPackageDocumentsPartie(buyerOrg),
				buyerOrgValues;
			var buyerInfoData = edi.utils.getObjectProperty(documentData, 'documentInfo.buyer');
			var isBuyerDefined = !!buyerInfoData;
			if (buyerOrg) {
				if (!buyerOrg.hasOwnProperty('fnsId')) {
					buyerOrg = edi.utils.getOrg({
						orgId: buyerOrg.id
					});
				}
				buyerOrgValues = convertOrgToPackageDocumentsPartie(buyerOrg);
				buyerOrgValues = Ext.clone(buyerOrgValues);
				buyerOrgValues.addr_for_country = '';
				buyerOrgValues.addr_for_country_name = '';
			}
			var isValidBuyer = isValidOrganization({
				organization: buyerInfo,
				useOrganizationMap: isBuyerDefined,
				organizationType: 'buyer'
			});

			buyer = createCS({
				valuesByMap: isBuyerDefined,
				relationsOnly: true,
				allowBlank: false,
				is_valid: isCreateFromBackOrPdf ? isCreateFromBackOrPdf : isValidBuyer || isEdit,
				fieldValues: isBuyerDefined ? documentData : buyerOrgValues,
				readOnly: isCreateFromBackOrPdf ? isCreateFromBackOrPdf : isReadOnly,
				selectedOrgValues: buyerInfo,
				selectedOrg: buyerOrg ? buyerOrg : null,
				selectedRelationByMap: buyerOrg,
				callback: function (values, org) {
					var orgId = org ? org.id : isCreate && buyerOrg ? buyerOrg.id : null,
						sameOrgSelected = buyerOrg && buyerOrg.id == orgId;

					if (orgId && !sameOrgSelected) {
						buyerOrg = org;
					} else if (!orgId) {
						buyerOrg = null;
					}

					buyer.is_valid = isValidOrganization({
						organization: values,
						organizationType: 'buyer'
					});

					checkValid();
				},
				fieldsMap: getOrganizationFieldsMap('documentInfo.buyer'),
				modalConf: Ext.merge(Ext.clone(modalConfiguration), {
					tabs: {
						signer: {
							signer_lastName: {
								allowBlank: true
							},
							signer_firstName: {
								allowBlank: true
							},
							signer_baseAuthory: {
								allowBlank: true
							},
							signer_position: {
								allowBlank: true
							}
						}
					},
					title: edi.i18n.getMessage('document.buyer')
				})
			});
			const buyerBlock = createFieldBlock({
				title: edi.i18n.getMessage('document.buyer'),
				items: [buyer]
			});
			let city = createFieldBlock({
				title: edi.i18n.getMessage('documents.package.dogpost.city'),
				items: [
					createTextField({
						readOnly: isReadOnly,
						maxLength: 30,
						value: edi.utils.getObjectProperty(documentData, 'documentContent.textInfos.city')
							? edi.utils.getObjectProperty(documentData, 'documentContent.textInfos.city')
							: edi.i18n.getMessage('documents.package.dogpost.city.default'),
						name: 'documentContent.textInfos.city'
					})
				]
			});
		}
		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				area: [[3, 2], [6, 6], 6]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [numberDocument, documentDate, sellerBlock, buyerBlock, city]
		});
	};

	var createDataToSave = function (values) {
		edi.utils.clearEmptyValues(values);

		var convertTextInfoValues = function (valuesObject, line, group) {
			var convertedValues = [];
			for (var infoKey in valuesObject) {
				if (valuesObject.hasOwnProperty(infoKey)) {
					var infoKeyLang = 'document.package.agreement.textInfos.key.' + infoKey,
						convertInfoKey = edi.i18n.getMessage(infoKeyLang);
					if (convertInfoKey != infoKeyLang) {
						var objectValue = {},
							groupField = [
								'ПОРЯДКОВЫЙ_НОМЕР',
								'КОД_ТОВАРА',
								'НАИМЕНОВАНИЕ_ТОВАРА',
								'ЦЕНА',
								'КОД_ТОВАРА2',
								'НАИМЕНОВАНИЕ_ТОВАРА2'
							];
						if (convertInfoKey === 'ТОЧКА_ДОСТАВКИ' && Array.isArray(valuesObject[infoKey])) {
							valuesObject[infoKey].forEach(function (item, index) {
								objectValue = {
									infoId: '#' + convertInfoKey + '#',
									infoValue: item,
									groupValue: index + 1
								};
								convertedValues.push(objectValue);
							});
						} else {
							objectValue = {
								infoId: '#' + convertInfoKey + '#',
								infoValue: valuesObject[infoKey]
							};
							if (line) {
								objectValue.line = line;
							}
							if (groupField.some((it) => it === convertInfoKey)) {
								objectValue.groupValue = group;
							}
							convertedValues.push(objectValue);
						}
					}
				}
			}
			return convertedValues;
		};

		var textInfosObj = edi.utils.getObjectProperty(values, 'documentContent.textInfos');

		if (!textInfosObj && !Ext.isObject(textInfosObj)) {
			textInfosObj = {};
		}
		convertFieldValueToTextInfos(docType, values, textInfosObj, dogPostData, edi.utils.getFormFields(form));
		var gridTextInfoValues = [];
		if (gridTextInfos.length) {
			for (var i = 0; i < gridTextInfos.length; i++) {
				var textInfoStore = gridTextInfos[i].getStore();
				if (textInfoStore.getCount()) {
					var storeRange = textInfoStore.getRange();
					if (storeRange && storeRange.length) {
						for (var j = 0; j < storeRange.length; j++) {
							gridTextInfoValues = gridTextInfoValues.concat(
								convertTextInfoValues(storeRange[j].getData(), j + 1, i + 1)
							);
						}
					}
				}
			}
		}
		if (values) {
			var textInfosData = [];
			if (textInfosObj) {
				textInfosData = textInfosData.concat(convertTextInfoValues(textInfosObj));
			}
			if (gridTextInfoValues.length) {
				textInfosData = textInfosData.concat(gridTextInfoValues);
			}
			if (textInfosData.length) {
				edi.utils.setObjectProperty(values, 'documentContent.textInfos', textInfosData);
			}
		}
		edi.utils.setObjectProperty(values, 'docType', isDogPost ? 'Договор' : 'Приложение к договору');

		if (values['true']) {
			if (values['true'].some((it) => it === 'individual')) {
				if (isDogPost) {
					if (edi.utils.getObjectProperty(values, 'documentInfo.seller.identification.legalEntity')) {
						delete values.documentInfo.seller.identification.legalEntity;
					}
				} else if (edi.utils.getObjectProperty(values, 'documentInfo.seller.member.memberLegalEntity')) {
					delete values.documentInfo.seller.member.memberLegalEntity;
				} else if (
					isPrilProtSogl ||
					isPrilOstrb ||
					isPrilCommusl ||
					isPrilKPost ||
					(isPrilKTov && edi.utils.getObjectProperty(values, 'documentInfo.seller.member.legalEntity'))
				) {
					delete values.documentInfo.seller.member.legalEntity;
				}
			}
			delete values['true'];
		}

		var documentData = {
			document: Ext.clone(values)
		};

		if (isDogPost) {
			documentData.document.documentInfo.linkDoc = null;
		} else {
			var linkDoc = edi.utils.getObjectProperty(isEdit ? values : formValues, 'documentInfo.linkDoc');
			if (linkDoc) {
				documentData.document.documentInfo.linkDoc = linkDoc;
			} else {
				documentData.document.documentInfo.linkDoc = {
					documentLinkDate: edi.utils.getObjectProperty(
						isEdit ? values : formValues,
						'documentInfo.documentDate'
					),
					documentLinkNumber: edi.utils.getObjectProperty(
						isEdit ? values : formValues,
						'documentInfo.documentNumber'
					),
					documentLinkId: dogFileId
				};
			}

			edi.utils.clearEmptyValues(senderInfo);
			if (senderInfo) {
				documentData.senderInfo = Ext.clone(senderInfo);
				if (edi.utils.getObjectProperty(documentData, 'document.senderInfo')) {
					delete documentData.document.senderInfo;
				}
			}
			if (!documentData.document.documentContent) {
				documentData.document.documentContent = {};
			}
		}
		return documentData;
	};

	var save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = edi.utils.collectFormValues(form);

		var headerData = {
			date: edi.utils.getObjectProperty(values, 'documentInfo.documentDate'),
			number: edi.utils.getObjectProperty(values, 'documentInfo.documentNumber')
		};
		if (!isCreateFromPdfAttachment && !isCreateFromBackAttachment) {
			var documentData = createDataToSave(values);
			headerData.data = Ext.encode(documentData);
		}

		var success = function (responseData) {
			var finishSaving = function () {
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				if (moduleData.initData.isCreateUtoch) {
					edi.modulesHandler.removeModule(moduleData);
				} else {
					edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
				}
				if (containerDataId) {
					edi.events.documents.fireEvent('change', {
						id: containerDataId
					});
				}
				if (moduleData.initData.packageId) {
					edi.events.documentsPackage.fireEvent('change', { id: moduleData.initData.packageId });
				}
			};

			if (!containerDataId) {
				containerDataId = responseData.data.id;
			}
			if (documentHasCreated && 'function' == typeof documentHasCreated && containerDataId) {
				documentHasCreated(
					values,
					containerDataId,
					function () {
						edi.document.actions.getDocumentHeaderData(containerDataId, function (headerData) {
							headerData ? (responseData.data = headerData) : null;
							finishSaving();
						});
					},
					finishSaving
				);
			} else {
				finishSaving();
			}
		};

		var failure = edi.document.actions.createSaveErrorHandler(isEdit, moduleData, docType);

		var createUaiDocument = function (createUri, buyerOrg, docType, parentId) {
			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				docType + '_P1',
				parentId,
				undefined,
				success,
				failure,
				headerData,
				{
					postData: {
						containerDocType: docType
					},
					createUri: createUri
				}
			);
		};

		var createPdfDocument = function (createUri, buyerOrg, docType, parentId) {
			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				docType,
				parentId,
				undefined,
				success,
				failure,
				headerData,
				{
					postData: {
						containerDocType: edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE
					},
					createUri: createUri
				}
			);
		};

		var createBeckDocument = function (createUri, buyerOrg, docType) {
			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				docType,
				undefined,
				undefined,
				success,
				failure,
				{},
				{
					createUri: createUri
				}
			);
		};

		if (isEdit) {
			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				docType + '_P1',
				undefined,
				containerDataId,
				success,
				failure,
				headerData
			);
		} else {
			var createUri = createDocumentUri ? createDocumentUri : edi.rest.services.DOCUMENTS.WITH_CONTAINER.POST;
			if (isCreateFromPdfAttachment) {
				createPdfDocument(createUri, buyerOrg, docType, parentId);
				return;
			}
			if (isCreateFromBackAttachment) {
				createBeckDocument(createUri, buyerOrg, docType);
				return;
			}
			createUaiDocument(createUri, buyerOrg, docType, parentId);
		}
	};

	var preConvertTextInfoData = function (textInfos) {
		var fieldsKeys = edi.stores.initDpAttachDocsFields(),
			convertFieldValues,
			gridData = [],
			result = {},
			deliveryEditableGridData = [];
		var additionalFields = {
			amountProcurementsNe: 'document.package.agreement.textInfos.key.amountProcurementsNe'
		};
		fieldsKeys = edi.stores.addDataToStoreWithTranslate(fieldsKeys, additionalFields);

		if (textInfos && Array.isArray(textInfos) && textInfos.length) {
			convertFieldValues = {};
			textInfos.map(function (item) {
				var findedFieldValue = fieldsKeys.findRecord('name', item.infoId.slice(1, -1), 0, false, false, true);
				if (findedFieldValue) {
					if (item.line) {
						if ('object' != typeof gridData[item.line - 1]) {
							gridData[item.line - 1] = {};
						}
						gridData[item.line - 1][findedFieldValue.getId()] = item.infoValue;
					} else {
						convertFieldValues[findedFieldValue.getId()] = item.infoValue;
					}
					if (item.groupValue) {
						if ('object' != typeof deliveryEditableGridData[item.groupValue - 1]) {
							deliveryEditableGridData[item.groupValue - 1] = {};
							deliveryEditableGridData[item.groupValue - 1]['editableGrid'] = [];
						}
						if (!!item.line && !!item.groupValue) {
							if ('object' != typeof deliveryEditableGridData[item.groupValue - 1]) {
								deliveryEditableGridData[item.groupValue - 1] = {};
							}

							if (
								'object' !=
								typeof deliveryEditableGridData[item.groupValue - 1]['editableGrid'][item.line - 1]
							) {
								deliveryEditableGridData[item.groupValue - 1]['editableGrid'][item.line - 1] = {};
							}
							deliveryEditableGridData[item.groupValue - 1]['editableGrid'][item.line - 1][
								findedFieldValue.getId()
							] = item.infoValue;
						} else {
							deliveryEditableGridData[item.groupValue - 1][findedFieldValue.getId()] = item.infoValue;
						}
					}
				}
			});
		}

		result = {
			mainForm: convertFieldValues,
			gridData: gridData
		};
		if (deliveryEditableGridData.length > 0) {
			result.deliveryGridData = deliveryEditableGridData;
		}
		return result;
	};

	var addRow = function () {
		var rows = deliverPointColumns.rowsContainer.getRows();
		var newItem = createRowWithAddRemoveButtons(deliverPointPanel, null, twoColumnsLayout.rowsContainer, {
			indexRow: rows.length
		});
		var editableGrid = newItem.query('gridpanel')[0];
		if (edi.utils.getObjectProperty(editableGrid.getStore(), 'data.items', true).length) {
			editableGrid.getStore().removeAll();
		}
		twoColumnsLayout.rowsContainer.add(newItem);
		twoColumnsLayout.rowsContainer.layout.redoLayout();
		isEditRowBtn = false;
	};

	var editRow = function (value, rowPanel) {
		var row = deliverPointColumns.rowsContainer.getRows(),
			thisItemIndex = row.indexOf(rowPanel);
		if (thisItemIndex > -1) {
			row[thisItemIndex].query('textfield')[0].setValue(value);
			deliverPointColumns.rowsContainer.layout.redoLayout();
		}
	};

	let editRowEventHandler = function (data) {
		editRow(data.deliverPoint, data.rowPanel);
		isEditRowBtn = true;
	};

	var createModuleForm = function () {
		createBtn = isReadOnly
			? null
			: createCreateSaveButton(
					{
						formBind: true,
						disabled: false,
						handler: function () {
							var form = this.up('form').getForm();
							if (form.isValid()) {
								save();
							}
						}
					},
					isEdit
			  );

		var createFieldsForm = function (fieldsConfig, recordData) {
			var fieldsForm = [];

			fieldsConfig.forEach(function (fieldConfig) {
				if (!fieldConfig) {
					return;
				}

				if (fieldConfig.type != 'editableGrid' && fieldConfig.type != 'deliveryGrid') {
					fieldsForm = fieldsForm.concat(
						createFieldsForProductGrid([fieldConfig], recordData, null, {
							readOnly: isReadOnly
						})
					);
				} else if (fieldConfig.type === 'deliveryGrid') {
					/**
					 * Creates form for delivery grid
					 */
					var deliverPointInput, textInfosEditableGrid;

					const createDeliveryLineField = function (isFirstRow, values) {
						var deliveryTextInfoRow;
						var gridData =
							isEdit && isEditRowBtn && edi.utils.getObjectProperty(values, 'editableGrid', true).length
								? values.editableGrid
								: undefined;
						var deliverPointField = createFieldBlock({
							title: edi.i18n.getMessage('document.package.agreement.textInfos.title.deliverPoint'),
							items: [
								(deliverPointInput = createTextField({
									maxLength: 140,
									isTextarea: true,
									allowBlank: !isFirstRow,
									readOnly: isReadOnly,
									name: 'documentContent.textInfos.deliverPoint',
									value:
										values && edi.utils.getObjectProperty(values, 'deliverPoint')
											? edi.utils.getObjectProperty(values, 'deliverPoint')
											: undefined,
									listeners: {
										render: function (comp) {
											//если deliverPoint нет в values его нужно подставить из documentContent.textInfos.deliverPoint когда 1 строка
											//но не нужно подставляет если создание новой строки, те пустой грид
											if (
												values &&
												!edi.utils.getObjectProperty(values, 'deliverPoint') &&
												edi.utils.getObjectProperty(
													documentData,
													'documentContent.textInfos.deliverPoint'
												) &&
												gridData
											) {
												comp.setValue(
													edi.utils.getObjectProperty(
														documentData,
														'documentContent.textInfos.deliverPoint'
													)
												);
											}
										}
									}
								}))
							]
						});
						var gridStore,
							gridConfig = Ext.clone(fieldConfig.editableGridConfig);
						var uploadBtnConfig = {
							gridStore: undefined,
							uploadUrl: gridConfig.uploadFileUrl,
							uploadFileDocType: gridConfig.uploadFileDocType
						};
						if ('string' == typeof gridConfig.uploadFileUrl && gridConfig.gridConfig && !isReadOnly) {
							gridConfig.gridConfig.tools = [createUploadButton(uploadBtnConfig)];
						}

						textInfosEditableGrid = createEditableGrid(
							Ext.merge(gridConfig, {
								editRowBtn: {
									tooltip: edi.i18n.getMessage('form.btn.edit.row')
								},
								deleteRowBtn: {
									tooltip: edi.i18n.getMessage('form.btn.delete')
								},
								deleteManyBtn: {
									text: '',
									tooltip: edi.i18n.getMessage('form.btn.delete')
								},
								readOnly: isReadOnly,
								modalWidth: MODAL_SIZE.widthMedium,
								gridConfig: {
									title: edi.i18n.getMessage('document.package.agreement.products.title')
								},
								storeConfig:
									'string' == typeof gridConfig.uploadFileUrl
										? {
												remoteSort: false,
												model: edi.models.getModel(gridConfig.model),
												data: {
													items: gridData
												}
										  }
										: {
												remoteSort: false
										  }
							}),
							gridData
						);
						gridStore = textInfosEditableGrid.getStore();
						uploadBtnConfig.gridStore = gridStore;
						gridStore.on('datachanged', checkValid);
						gridStore.on('update', checkValid);
						gridTextInfos.push(textInfosEditableGrid);

						deliveryTextInfoRow = createFieldSet({
							layout: {
								type: 'grid',
								area: [12, 12]
							},
							items: [deliverPointField, textInfosEditableGrid]
						});

						deliveryTextInfoRow.deliverPointField = deliverPointField;
						deliveryTextInfoRow.textInfosEditableGrid = textInfosEditableGrid;

						return deliveryTextInfoRow;
					};

					const delivery = createRowsBlock({
						createContentFieldsFn: createDeliveryLineField,
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						disableRemoveButton: isReadOnly,
						hideAddButton: isReadOnly,
						initialData: edi.utils.getObjectProperty(
							documentData,
							'documentContent.textInfos.deliveryGridData',
							true
						),
						onAddRow() {
							checkValid();
						},
						onRemoveRow() {
							Object.entries(gridTextInfos)
								.filter(([_, grid]) => grid.isDestroyed)
								.forEach(([index]) => delete gridTextInfos.splice(index, 1));
							checkValid();
						}
					});

					fieldsForm.push(delivery);
				} else {
					var grid,
						gridStore,
						gridConfig = Ext.clone(fieldConfig.config);
					var uploadBtnConfig = {
						gridStore: undefined,
						uploadUrl: gridConfig.uploadFileUrl,
						uploadFileDocType: gridConfig.uploadFileDocType
					};
					if ('string' == typeof gridConfig.uploadFileUrl && gridConfig.gridConfig && !isReadOnly) {
						gridConfig.gridConfig.tools = [createUploadButton(uploadBtnConfig)];
					}
					const gridData = isEdit && gridTextInfosData.length ? gridTextInfosData : [];
					const gridDataFilteredByModel = filterGridDataByModel(gridData, gridConfig.model);
					grid = createEditableGrid(
						Ext.merge(gridConfig, {
							readOnly: isReadOnly,
							modalWidth: MODAL_SIZE.widthMedium,
							storeConfig:
								'string' == typeof gridConfig.uploadFileUrl
									? {
											remoteSort: false,
											model: edi.models.getModel(gridConfig.model),
											data: {
												items: gridDataFilteredByModel
											}
									  }
									: {
											remoteSort: false
									  }
						}),
						gridDataFilteredByModel
					);
					gridStore = grid.getStore();
					uploadBtnConfig.gridStore = gridStore;
					gridStore.on('datachanged', checkValid);
					gridStore.on('update', checkValid);
					gridTextInfos.push(grid);
					fieldsForm.push(grid);
				}
			});

			return fieldsForm;
		};

		let items = [];
		if (isReadOnly) {
			items.push(
				createDocumentHeaderPanel(moduleData.initData, {
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					noSignatures: true,
					noUsage: true
				})
			);
		}

		if (isDogPost) {
			var itemNumber = edi.utils.getObjectProperty(documentData, 'documentContent.textInfos.itemNumber');
			if (itemNumber) {
				edi.utils.setObjectProperty(
					documentData,
					'documentContent.textInfos.itemNumber',
					itemNumber.split(', ')
				);
			}
		}

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: items.concat(
				[
					!isReadOnly
						? createLabel({
								typography: 'heading_02',
								text: edi.i18n.getMessage('documents.doctype.' + docType)
						  })
						: null,
					createMainDataBlock()
				],
				createFieldsForm(Ext.clone(formConfig), documentData ? documentData : formValues)
			),
			buttons: createButtonContainer({
				items: [createBtn]
			})
		});
		form.isValid();
		form.on('validitychange', checkValid);

		return form;
	};

	/**
	 * Creates upload button
	 * @param	{Object}	config
	 * @param	{Object}	config.gridStore			grid store to add records from uploaded file
	 * @param	{string}	config.uploadUrl			url to upload file
	 * @param	{string}	config.uploadFileDocType	file docType
	 * @return	{Object}	upload button config
	 */
	var createUploadButton = function (config) {
		var uploadUrl = config.uploadUrl,
			uploadFileDocType = config.uploadFileDocType;
		return createTool({
			tooltip: edi.i18n.getMessage('file.upload'),
			padding: '2 4 0 0',
			glyph: edi.constants.ICONS.CLOUD_UPLOAD,
			handler: function () {
				var success = function () {
					edi.core.showInfo('file.upload.success');
				};

				var formPanel = createModalForm({
					submitEmptyText: false,
					items: [
						createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage('document.package.attachment.form.file.input'),
							items: [
								createFile(
									{},
									{
										name: 'attachment',
										allowBlank: false
									}
								)
							]
						})
					]
				});

				var modalConf = {
					title: edi.i18n.getMessage('file.create.title'),
					width: MODAL_SIZE.widthMedium,
					items: [formPanel],
					buttonsBefore: [
						createButton({
							cls: BUTTON_CLS.primary,
							text: edi.i18n.getMessage('form.btn.upload'),
							handler: function () {
								var form = formPanel.getForm();
								if (!form.isValid()) {
									edi.core.showError('document.upload.error.select.file');
									return;
								}

								edi.core.submitUploadForm(
									form,
									uploadUrl,
									'file.upload.processing',
									function (data) {
										if (data.success && data.data) {
											var decodeData = Ext.decode(data.data);
											if (decodeData && 'object' == typeof decodeData && decodeData.textInfos) {
												var convertedFileData = preConvertTextInfoData(decodeData.textInfos);
												if (config.gridStore && convertedFileData.gridData.length) {
													config.gridStore.loadData(convertedFileData.gridData);
												}
											}
										}
										'function' == typeof success ? success() : null;
										modal.close();
									},
									function (data) {
										edi.core.logMessage('Error uploading file', 'warn');
										edi.core.showError(
											edi.utils.formatComplexServerError(data, 'error.server'),
											function () {
												moduleData.tab.setLoading(false);
											}
										);
										modal.close();
									}
								);
							}
						}),
						createButton({
							cls: BUTTON_CLS.secondary,
							text: edi.i18n.getMessage('form.btn.download_template'),
							glyph: edi.constants.ICONS.FILE_DOWNLOAD,
							handler: function () {
								var url = edi.utils.formatString(agreementPackagesAPI.TEMPLATE_DOWNLOAD, {
									docType: uploadFileDocType
								});
								edi.rest.downloadFile(url);
							}
						})
					]
				};
				var modal = createModalPanel(modalConf);
				formPanel.isValid();
				modal.show();
			}
		});
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		var actionsPanel = createActionsPanel();
		let customButtons = [];
		if (isReadOnly) {
			customButtons.push(
				createActionsButton({
					text: edi.i18n.getMessage('action.print'),
					glyph: edi.constants.ICONS.PRINT,
					showInFirstHalf: true,
					order: 200, //оставляем такой же как у оригинальной кнопки печать
					handler: function () {
						edi.document.actions.createPrintModal(
							moduleData.initData.data,
							{
								usingReport: true
							},
							moduleData.initData.data.id
						);
					}
				})
			);
		}

		if (utochContainer && (utochChaild || isCreateFromPdfAttachment)) {
			customButtons.push(
				createActionsButton({
					text: edi.i18n.getMessage('form.btn.sign'),
					glyph: edi.constants.ICONS.SIGN,
					showInFirstHalf: true,
					order: 340, //оставляем такой же как у оригинальной кнопки подписать
					handler: function () {
						var failure = function (data) {
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.mark.sign.error.process'
							)(data);
						};

						var pushContainerToBp = function (containerId, callback, failure) {
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: containerId
									},
									true
								),
								'PUT',
								Ext.encode({}),
								callback,
								failure
							);
						};

						var signAttachDoc = function () {
							var id = isCreateFromPdfAttachment ? moduleData.initData.data.id : utochChaild.id;
							edi.utils.sign(
								moduleData.initData.data,
								moduleData.tab,
								function (failed) {
									if (failed) {
										failure();
									} else {
										pushContainerToBp(
											utochContainer.id,
											function () {
												edi.modulesHandler.removeModule(moduleData);
												edi.events.documentsPackage.fireEvent('change', {
													id: parentId
												});
											},
											failure
										);
									}
								},
								id,
								undefined,
								true,
								null,
								{
									signRefuse: () => moduleData.tab?.setLoading(false)
								}
							);
						};

						if (utochContainer.state == edi.constants.STATE.DRAFT) {
							pushContainerToBp(utochContainer.id, signAttachDoc, failure);
						} else {
							signAttachDoc();
						}
					}
				})
			);
		}
		const data = moduleData.initData.data;
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			moduleData: moduleData,
			excludeActions: [],
			actionProps: {
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});
		return actionsPanel;
	};

	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var buildForm = function () {
			if (documentData) {
				var convertedData = preConvertTextInfoData(
					edi.utils.getObjectProperty(documentData, 'documentContent.textInfos')
				);
				if (convertedData.mainForm) {
					edi.utils.setObjectProperty(documentData, 'documentContent.textInfos', convertedData.mainForm);
				}
				if (convertedData.gridData.length) {
					gridTextInfosData = convertedData.gridData;
				}
				if (edi.utils.getObjectProperty(convertedData, 'deliveryGridData', true).length) {
					edi.utils.setObjectProperty(
						documentData,
						'documentContent.textInfos.deliveryGridData',
						convertedData.deliveryGridData
					);
					deliveryGridTextInfosData = convertedData.deliveryGridData;
				}
			}

			var continueBuildForm = function () {
				modulePanel.add(createModuleForm());
				if (isReadOnly) {
					moduleData.tab.add(createModuleActionsPanel());
				}
				moduleData.tab.add(modulePanel);
				moduleData.tab.setLoading(false);
				if ('function' == typeof initCallBack) {
					initCallBack();
				}
			};

			if (isDogPost && buyerOrg && !Array.isArray(buyerOrg.accounts)) {
				var url = edi.utils.formatString(edi.rest.services.USER.BANK_ACCOUNTS.GET, {
					orgId: buyerOrg.id
				});
				edi.rest.sendRequest(
					url,
					'GET',
					undefined,
					function (data) {
						if (data && data.items) {
							buyerOrg.accounts = data.items;
						}
					},
					undefined,
					function () {
						continueBuildForm();
					}
				);
			} else {
				continueBuildForm();
			}
		};

		if (data && data.id) {
			isEdit = true;
			containerDataId = data.id;
			var getParentData = function () {
				var documentType = isCreateFromBackOrPdf ? docType : docType + '_P1';
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.FOR_PARENT.GET, {
						parentId: isCreateFromBackOrPdf ? parentId : data.id,
						docType: documentType
					}),
					'GET',
					{},
					function (data) {
						if (data && data.data) {
							contendId = data.data.id;
							documentData = edi.utils.getObjectProperty(data.data, 'document');
							setSignerData(edi.utils.getObjectProperty(documentData, 'documentInfo.seller.signer'));
							buildForm();
						} else {
							failure(data);
						}
					},
					failure
				);
			};

			if (
				moduleData.initData.containerNotDraft &&
				(data.state == edi.constants.STATE.DRAFT || data.state == edi.constants.STATE.SENDER_REVIEW)
			) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.PARENTS.GET, {
						documentId: data.id
					}),
					'GET',
					{},
					function (parents) {
						if (parents && parents.items && Array.isArray(parents.items)) {
							utochContainer = parents.items.find(function (parent) {
								return (
									parent.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE &&
									parentId != parent.id &&
									(parent.state === edi.constants.STATE.DRAFT ||
										parent.state === edi.constants.STATE.SENDER_REVIEW)
								);
							});
						}

						if (utochContainer && !isCreateFromPdfAttachment) {
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
									documentId: data.id
								}),
								'GET',
								{},
								function (linkedData) {
									if (linkedData && linkedData.data && Array.isArray(linkedData.data.children)) {
										utochChaild = linkedData.data.children.find(function (item) {
											return item.type === data.type + '_P1';
										});
									}
								},
								failure,
								getParentData
							);
						} else {
							getParentData();
						}
					},
					failure
				);
			} else {
				getParentData();
			}
		} else {
			buildForm();
		}
	};

	/**
	 * Refresh seller data, when signer has been changed
	 * @param data
	 */
	var changeSignerHandler = function (data) {
		if (parentId == data.packageId && seller.id != data.sellerId) {
			setSellerSignerData(seller.fieldValues, data.signerNew);
			setSignerData(data.signerNew);
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		var closeCallback = moduleData.initData.onClose;
		if ('function' === typeof closeCallback) {
			closeCallback(containerDataId);
		}
		edi.events.deliveryGrid.un('add', addRow);
		edi.events.deliveryGrid.un('edit', editRowEventHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
