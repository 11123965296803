import { createActionsColumnConfig, createCheckboxSelectionModel, createGrid, createToolBar } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createAddButton, createDeleteButton } from '@Components/buttons';
import { createForm, createTab, createTabPanel, TAB_PANEL_CLS } from '@Components/panels';
import { createFields, createFieldsForProductGrid } from '@Components/fields';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createEditableGrid } from '@Components/editableGrid/EditableGrid';
import { getPartinLicensesModel, PARTIN_LICENSES_MODEL_CONFIG_NAME } from '@Edi/modules/catalog/LEGACY_PARTIN/models';
import { BUTTON_CLS } from '@UIkit/components/buttons';

const PHONE_VALIDATOR = /^(\+7|7|8)?(\s|-)?\(?[3489][0-9]{2}\)?(\s|-)?[0-9]{3}(\s|-)?[0-9]{2}(\s|-)?[0-9]{2}$/;
const DATE_FORMAT = 'd.m.Y';
const SERVER_DATE_FORMAT = 'Y-m-d';
const TIME_FORMAT = 'H:i';
const TIME_FORMAT_WITH_SEC = 'H:i:s';

const findOrgByValues = function (searchValues) {
	if (!searchValues) {
		return null;
	}
	let organizations = edi.relations.getRelations({ self: true });
	let orgData = organizations.find(function (o) {
		return o.iln === searchValues.iln;
	});
	return orgData || null;
};

const convertOrgBeforeRender = function (orgValues) {
	let values = orgValues || {};
	values = Ext.clone(values);
	return values;
};

const checkOrgValid = function (selectorComp) {
	if (!selectorComp) {
		return false;
	}
	return selectorComp.checkOrgDataValid();
};

const getPartiesFieldsMap = function (fieldsMapPrefix) {
	let prefix = fieldsMapPrefix ? `${fieldsMapPrefix}.` : '';
	return {
		company_name: prefix + 'name',
		company_iln: prefix + 'iln'
	};
};

const getPartiesModalConf = function (additionalConf, options) {
	let props = Ext.merge(
		{
			glnReadOnly: true
		},
		options
	);
	let defaults = edi.selectors.defaults;
	let defaultConf = {
		tabs: {
			main: {
				company_name: Object.assign({}, defaults.company_name, {
					maxLength: 175,
					allowBlank: true
				}),
				company_gln: Object.assign({}, defaults.company_gln, {
					allowBlank: false,
					readOnly: props.glnReadOnly
				})
			}
		}
	};
	return Ext.merge(Ext.clone(defaultConf), additionalConf || {});
};

const getLicenseTypeText = function (typeId) {
	return edi.i18n.getMessage(`catalog.partin.license.type.${typeId}`);
};

const createLicenseTypeStore = () =>
	edi.stores.createSimpleInlineStore(['IP', 'TL', 'EX', 'AV'], (typeId) => getLicenseTypeText(typeId), true);

const getLocalizationStatusText = function (typeId) {
	return edi.i18n.getMessage(`catalog.partin.localization.status.${typeId}`);
};

const createLocalizationStatusStore = () =>
	edi.stores.createSimpleInlineStore(['O', 'C', 'R'], (typeId) => getLocalizationStatusText(typeId), true);

const createCatalogGrid = function (config, data, editWindow) {
	let columns = config.columns;
	let selectedRecords = [],
		deleteBtn;
	if (!config.readOnly) {
		columns.push(
			createActionsColumnConfig({
				width: edi.utils.getActionColumnWidth(1),
				items: [
					{
						glyph: edi.constants.ICONS.EDIT,
						testCls: 'test-action-column-edit',
						handler: function (grid, rowIndex) {
							let record = grid.getStore().getAt(rowIndex);
							if (record) {
								editWindow(record);
							}
						}
					}
				]
			})
		);
	} else {
		columns.push(
			createActionsColumnConfig({
				width: edi.utils.getActionColumnWidth(1),
				items: [
					{
						glyph: edi.constants.ICONS.DETAILS,
						testCls: 'test-action-column-edit',
						handler: function (grid, rowIndex) {
							let record = grid.getStore().getAt(rowIndex);
							if (record) {
								editWindow(record, true);
							}
						}
					}
				]
			})
		);
	}
	let storeConfig = Object.assign(
		config.isEdit || config.readOnly
			? {
					model: config.model,
					autoLoad: true
			  }
			: {
					proxy: createProxyConfig({
						type: 'memory',
						data: data
					}),
					model: config.model,
					remoteSort: true
			  },
		config.store
	);
	let proxyConfig =
		config.isEdit || config.readOnly
			? config.proxy ?? {
					type: 'ajax',
					url: edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.GET, {
						id: config.docId,
						type: config.type
					})
			  }
			: null;
	const tbar = !config.readOnly
		? [
				createAddButton(function () {
					editWindow();
				}),
				(deleteBtn = edi.permissions.hasPermission('CLIENT_DELETE_CATALOG_V2_LINES')
					? createDeleteButton(
							function () {
								deleteRowCatalog(grid, config, selectedRecords);
							},
							{
								cls: [BUTTON_CLS.light, BUTTON_CLS.small],
								tooltip: edi.i18n.getMessage('catalog.delete.row.btn'),
								text: edi.i18n.getMessage('catalog.delete.row.btn')
							}
					  )
					: null)
		  ]
		: null;
	let selModel = !config.readOnly
		? createCheckboxSelectionModel({
				listeners: {
					selectionchange: function (model, selected) {
						if (!config.readOnly) {
							selectedRecords = selected.map(function (record) {
								return record;
							});
							deleteBtn?.setDisabled(!selected.length);
						}
					}
				}
		  })
		: null;
	let grid = createGrid({
		proxyConfig: proxyConfig,
		storeConfig: storeConfig,
		gridConfig: {
			selModel: selModel,
			cls: 'tab-grid with-filter',
			columns: columns,
			region: 'center',
			border: 0,
			minHeight: '40',
			emptyText: !config.allowBlank ? edi.i18n.getMessage('grid.empty.mandatory') : '',
			listeners: {
				celldblclick: function (view, td, cellIndex, record) {
					editWindow(record, config.readOnly);
				}
			},
			autoScroll: true,
			disableSelection: false,
			dockedItems: [
				createToolBar({
					cls: 'ui-table-top-bar',
					dock: 'top',
					items: tbar
				})
			],
			disablePaging: !config.readOnly && !config.isEdit
		}
	});
	return grid;
};

const deleteRowCatalog = function (grid, config, selectedRecords) {
	if (config.isEdit) {
		let moduleData = edi.modulesHandler.getActiveModule();
		moduleData.tab.setLoading(true);
		let itemsIds = selectedRecords.map(function (item) {
			return item.getData().id;
		});
		let failure = function () {
			edi.core.showError('error.server');
		};
		let typeForDelete = {
			localization: 'localizations',
			branch: 'branches',
			headquarter: 'headquarters'
		};

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.ROW.DELETE, {
				id: config.docId,
				type: typeForDelete[config.type]
			}),
			'DELETE',
			Ext.encode(itemsIds),
			function () {
				moduleData.tab.setLoading(false);
				edi.events.catalog.fireEvent('change', {
					id: config.docId,
					catalogType: edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN,
					subCatalogType: config.type
				});
			},
			failure,
			function () {
				moduleData.tab.setLoading(false);
			}
		);
	} else {
		grid.getStore().remove(selectedRecords);
		let recindex = store.getProxy().data.findIndex((item) => {
			return item.id === selectedRecords;
		});
		store.getProxy().data.splice(recindex, i);
		grid.getStore().load();
	}
};

const createRowCatalog = function (record, docId, type, values, modal) {
	modal.setLoading();
	edi.utils.clearEmptyValues(values);
	let failure = function (responseData) {
		edi.core.showError(
			edi.i18n.getMessage(responseData?.typeError, [responseData.additionalData.join(', ')]) || 'error.server',
			function () {
				modal.setLoading(false);
			}
		);
	};
	let success = function (responseData) {
		edi.events.catalog.fireEvent('change', {
			id: docId,
			catalogType: edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN,
			subCatalogType: type
		});
		modal.setLoading(false);
		modal.close();
	};
	if (record) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.ROW.PUT, {
				id: docId,
				type: type,
				lineItemId: record.getData().id
			}),
			'PUT',
			Ext.encode(values),
			success,
			failure
		);
	} else {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.ROW.POST, {
				id: docId,
				type: type
			}),
			'POST',
			Ext.encode(values),
			success,
			failure
		);
	}
};

/**
 * Displays modal catalog data form Localizations
 * @param    {Object}    record      record from existent catalog row in grid, used for editing values
 * @param    {Object}    readOnly    boolean
 */
const showModalLocalizationsForm = function (record, readOnly) {
	let form;
	let objectData = record ? record.getData() : null;
	let inputConf = {
		readOnly: readOnly ? readOnly : false
	};

	let modal = createModalPanel({
		title: edi.i18n.getMessage('catalog.partin.localization'),
		width: MODAL_SIZE.widthLarge,
		layout: 'fit',
		items: [
			(form = createForm({
				layout: 'fit',
				items: [
					createTabPanel({
						cls: TAB_PANEL_CLS.simpleWithoutPadding,
						items: [
							createTab({
								title: edi.i18n.getMessage('catalog.partin.tab.localization.info'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.iln',
											type: 'text',
											name: 'iln',
											regex: edi.constants.VALIDATORS.ILN,
											maskRe: /\d/i,
											enforceMaxLength: true,
											allowBlank: false,
											minLength: 13,
											maxLength: 13
										},
										{
											title: 'column.org.inn',
											name: 'taxId',
											allowBlank: false,
											maxLength: 35
										},
										{
											title: 'column.org.kpp',
											name: 'taxRegistrationReasonCode',
											allowBlank: false,
											maxLength: 35
										},
										{
											title: 'column.register.number',
											name: 'utilizationRegisterNumber',
											allowBlank: false,
											maxLength: 35
										},
										{
											title: 'company.okpo.short',
											name: 'enterpriseClassificationCode',
											maxLength: 35
										},
										{
											title: 'column.code.by.sender',
											name: 'codeBySender',
											maxLength: 35
										},
										{
											title: 'column.code.by.receiver',
											name: 'codeByReceiver',
											maxLength: 35
										},
										{
											title: 'column.name',
											name: 'name',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('catalog.partin.tab.localization.adress'),
								closable: false,
								autoScroll: true,
								bodyPadding: '16 24 0 24',
								layout: {
									type: 'grid',
									gap: [24, 16],
									area: [
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6]
									]
								},
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.country',
											name: 'country',
											maxLength: 175,
											type: 'combo',
											store: edi.stores.initValidCountryFullStore(),
											valueField: 'iso_2',
											displayField: 'name'
										},
										{
											title: 'company.zip',
											name: 'postalCode',
											maxLength: 9
										},

										{
											title: edi.i18n.getMessage('column.state.code'),
											name: 'stateCode',
											maxLength: 9
										},
										{
											title: 'company.street',
											name: 'streetAndNumber',
											maxLength: 255
										},

										{
											title: edi.i18n.getMessage('company.region'),
											name: 'state',
											maxLength: 175
										},
										{
											title: 'company.home',
											name: 'houseNumber',
											maxLength: 20
										},

										{
											title: 'company.district',
											name: 'district',
											maxLength: 140
										},
										{
											title: 'column.housing',
											name: 'housing',
											maxLength: 20
										},

										{
											title: 'column.city.name',
											name: 'cityName',
											maxLength: 140
										},
										{
											title: 'company.flat.location',
											name: 'roomNumber',
											maxLength: 20
										},

										{
											title: 'company.community',
											name: 'locality',
											maxLength: 140
										},
										{
											title: 'catalog.partin.additionalInformation',
											name: 'additionalInformation',
											maxLength: 140
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('catalog.partin.localization.tradeFormat'),
								closable: false,
								autoScroll: true,
								bodyPadding: '16 24 0 24',
								layout: {
									type: 'grid',
									gap: [24, 16],
									area: [[6, 6], [6, 6], [6, 6], [6, 6], [6, 6], 6, 6]
								},
								items: createFields(
									[
										{
											title: 'catalog.partin.localization.tradeFormat',
											name: 'tradeFormat',
											maxLength: 70
										},
										{
											title: 'catalog.partin.localization.contactPerson',
											name: 'contactPerson',
											maxLength: 175
										},

										{
											title: 'catalog.partin.localization.format',
											name: 'format',
											maxLength: 70
										},
										{
											title: 'catalog.partin.localization.phoneNumber',
											name: 'phoneNumber',
											maxLength: 35
										},

										{
											title: 'catalog.partin.localization.formatArea',
											name: 'formatArea',
											maxLength: 35
										},
										{
											title: 'catalog.partin.localization.eMail',
											name: 'eMail',
											regex: edi.constants.VALIDATORS.EMAIL
										},

										{
											title: 'catalog.partin.localization.numberOfCashDesks',
											name: 'numberOfCashDesks',
											regex: /^[0-9]{3}$/
										},
										{
											title: 'catalog.partin.localization.latitude',
											name: 'latitude',
											maxLength: 20
										},

										{
											title: 'catalog.partin.localization.status',
											name: 'status',
											type: 'combo',
											store: createLocalizationStatusStore()
										},
										{
											title: 'catalog.partin.localization.longitude',
											name: 'longitude',
											maxLength: 20
										},

										{
											title: 'catalog.partin.localization.storeOpeningDate',
											name: 'storeOpeningDate',
											type: 'date',
											submitFormat: SERVER_DATE_FORMAT,
											format: SERVER_DATE_FORMAT
										},

										{
											title: 'catalog.partin.localization.dateNewOrders',
											name: 'dateNewOrders',
											type: 'date',
											submitFormat: SERVER_DATE_FORMAT,
											format: SERVER_DATE_FORMAT
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('company.type.company'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.iln',
											name: 'headquarter.iln',
											regex: edi.constants.VALIDATORS.ILN,
											maskRe: /\d/i,
											enforceMaxLength: true,
											minLength: 13,
											maxLength: 13
										},
										{
											title: 'column.name',
											name: 'headquarter.name',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('catalog.partin.branche'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.iln',
											name: 'branch.iln',
											regex: edi.constants.VALIDATORS.ILN,
											maskRe: /\d/i,
											enforceMaxLength: true,
											minLength: 13,
											maxLength: 13
										},
										{
											title: 'column.name',
											name: 'branch.name',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('catalog.partin.franchise'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.iln',
											name: 'franchisee.iln',
											regex: edi.constants.VALIDATORS.ILN,
											maskRe: /\d/i,
											enforceMaxLength: true,
											minLength: 13,
											maxLength: 13
										},
										{
											title: 'column.name',
											name: 'franchisee.name',
											maxLength: 175
										},
										{
											title: 'catalog.partin.franchisees.codeBySender',
											name: 'franchisee.codeBySender',
											maxLength: 175
										},
										{
											title: 'catalog.partin.franchisees.codeByReceiver',
											name: 'franchisee.codeByReceiver',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							})
						]
					})
				]
			}))
		]
	});
	modal.show();
	form.isValid();
};

/**
 * Displays modal catalog data form Headquarters
 * @param    {Object}    record      record from existent catalog row in grid, used for editing values
 * @param    {Object}    readOnly    boolean
 */
const showModalHeadquartersForm = function (record, readOnly) {
	let form;
	let objectData = record ? record.getData() : null;
	let inputConf = {
		readOnly: readOnly ? readOnly : false
	};
	let modal = createModalPanel({
		title: edi.i18n.getMessage('catalog.partin.headquarter'),
		width: MODAL_SIZE.widthLarge,
		layout: 'fit',
		items: [
			(form = createForm({
				layout: 'fit',
				items: [
					createTabPanel({
						cls: TAB_PANEL_CLS.simpleWithoutPadding,
						items: [
							createTab({
								title: edi.i18n.getMessage('catalog.partin.localization.info'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.iln',
											name: 'iln',
											regex: edi.constants.VALIDATORS.ILN,
											maskRe: /\d/i,
											enforceMaxLength: true,
											allowBlank: false,
											minLength: 13,
											maxLength: 13
										},
										{
											title: 'column.org.inn',
											allowBlank: false,
											name: 'taxId',
											maxLength: 35
										},
										{
											title: 'column.org.kpp',
											allowBlank: false,
											name: 'taxRegistrationReasonCode',
											maxLength: 35
										},
										{
											title: 'column.register.number',
											allowBlank: false,
											name: 'utilizationRegisterNumber',
											maxLength: 35
										},
										{
											title: 'company.okpo.short',
											name: 'enterpriseClassificationCode',
											maxLength: 35
										},
										{
											title: 'column.code.by.sender',
											name: 'codeBySender',
											maxLength: 35
										},
										{
											title: 'column.code.by.receiver',
											name: 'codeByReceiver',
											maxLength: 35
										},
										{
											title: 'column.name',
											name: 'name',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('catalog.partin.localization.adress'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16],
									area: [
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6]
									]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.country',
											name: 'country',
											maxLength: 175,
											type: 'combo',
											store: edi.stores.initValidCountryFullStore(),
											valueField: 'iso_2',
											displayField: 'name'
										},
										{
											title: 'company.zip',
											name: 'postalCode',
											maxLength: 9
										},

										{
											title: edi.i18n.getMessage('column.state.code'),
											name: 'stateCode',
											maxLength: 9
										},
										{
											title: 'company.street',
											name: 'streetAndNumber',
											maxLength: 255
										},

										{
											title: edi.i18n.getMessage('company.region'),
											name: 'state',
											maxLength: 175
										},
										{
											title: 'company.home',
											name: 'houseNumber',
											maxLength: 20
										},

										{
											title: 'company.district',
											name: 'district',
											maxLength: 140
										},
										{
											title: 'column.housing',
											name: 'housing',
											maxLength: 20
										},

										{
											title: 'column.city.name',
											name: 'cityName',
											maxLength: 140
										},
										{
											title: 'company.flat.location',
											name: 'roomNumber',
											maxLength: 20
										},

										{
											title: 'company.community',
											name: 'locality',
											maxLength: 140
										},
										{
											title: 'catalog.partin.additionalInformation',
											name: 'additionalInformation',
											maxLength: 140
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('company.details'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'company.bank.account',
											name: 'checkingAccount',
											regex: edi.constants.VALIDATORS.BANK_ACC,
											regexText: 'invalid.value.bank.acc'
										},
										{
											title: 'column.corr.acc.number',
											name: 'correspondentAccount',
											regex: edi.constants.VALIDATORS.BANK_ACC,
											regexText: 'invalid.value.bank.acc'
										},
										{
											title: 'company.bank.id',
											name: 'bankId',
											regexText: 'invalid.value.bank.id',
											regex: edi.constants.VALIDATORS.BIK
										},
										{
											title: 'company.bank.name',
											name: 'bankName',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('catalog.partin.licenses'),
								closable: false,
								autoScroll: true,
								layout: 'grid',
								bodyPadding: '16 24 0 24',
								items: createEditableGrid(
									{
										name: 'licenses.license',
										fields: [
											{
												title: 'catalog.partin.license.type',
												name: 'type',
												type: 'combo',
												store: createLicenseTypeStore(),
												renderer: function (value) {
													return value ? getLicenseTypeText(value) : '';
												}
											},
											{
												title: 'catalog.partin.license.name',
												name: 'name',
												maxLength: 175
											},
											{
												title: 'catalog.partin.license.number',
												name: 'number',
												maxLength: 35
											},
											{
												title: 'catalog.partin.license.issuingAuthority',
												name: 'issuingAuthority',
												maxLength: 70
											},
											{
												title: 'catalog.partin.license.dateOfIssue',
												name: 'dateOfIssue',
												type: 'date',
												submitFormat: SERVER_DATE_FORMAT,
												format: SERVER_DATE_FORMAT
											},
											{
												title: 'catalog.partin.license.expirationDate',
												name: 'expirationDate',
												type: 'date',
												submitFormat: SERVER_DATE_FORMAT,
												format: SERVER_DATE_FORMAT
											}
										],
										model: PARTIN_LICENSES_MODEL_CONFIG_NAME,
										modalWidth: MODAL_SIZE.widthLarge,
										readOnly: readOnly,
										gridConfig: {
											cls: 'modal-grid',
											maxHeight: 400,
											title: edi.i18n.getMessage('catalog.partin.license')
										},
										storeConfig: {
											model: getPartinLicensesModel(),
											data: edi.utils.getObjectProperty(objectData, 'licenses.license', true)
										}
									},
									objectData
								)
							})
						]
					})
				]
			}))
		]
	});
	modal.show();
	form.isValid();
};

/**
 * Displays modal catalog data form Branches
 * @param    {Object}    record      record from existent catalog row in grid, used for editing values
 * @param    {Object}    readOnly    boolean
 */
const showModalBranchesForm = function (record, readOnly) {
	let form;
	let objectData = record ? record.getData() : null;
	let inputConf = {
		readOnly: readOnly ? readOnly : false
	};
	let modal = createModalPanel({
		title: edi.i18n.getMessage('catalog.partin.branche'),
		width: MODAL_SIZE.widthLarge,
		layout: 'fit',
		items: [
			(form = createForm({
				layout: 'fit',
				items: [
					createTabPanel({
						cls: TAB_PANEL_CLS.simpleWithoutPadding,
						items: [
							createTab({
								title: edi.i18n.getMessage('catalog.partin.localization.info'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.iln',
											allowBlank: false,
											name: 'iln',
											regex: edi.constants.VALIDATORS.ILN,
											maskRe: /\d/i,
											enforceMaxLength: true,
											minLength: 13,
											maxLength: 13
										},
										{
											title: 'column.code.by.sender',
											name: 'codeBySender',
											maxLength: 35
										},
										{
											title: 'column.code.by.receiver',
											name: 'codeByReceiver',
											maxLength: 35
										},
										{
											title: 'column.name',
											name: 'name',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('catalog.partin.localization.adress'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16],
									area: [
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6],
										[6, 6]
									]
								},
								bodyPadding: '16 24 0 24',
								items: createFields(
									[
										{
											title: 'column.country',
											name: 'country',
											maxLength: 175,
											type: 'combo',
											store: edi.stores.initValidCountryFullStore(),
											valueField: 'iso_2',
											displayField: 'name'
										},
										{
											title: 'company.zip',
											name: 'postalCode',
											maxLength: 9
										},

										{
											title: edi.i18n.getMessage('column.state.code'),
											name: 'stateCode',
											maxLength: 9
										},
										{
											title: 'company.street',
											name: 'streetAndNumber',
											maxLength: 255
										},

										{
											title: edi.i18n.getMessage('company.region'),
											name: 'state',
											maxLength: 175
										},
										{
											title: 'company.home',
											name: 'houseNumber',
											maxLength: 20
										},

										{
											title: 'company.district',
											name: 'district',
											maxLength: 140
										},
										{
											title: 'column.housing',
											name: 'housing',
											maxLength: 20
										},

										{
											title: 'column.city.name',
											name: 'cityName',
											maxLength: 140
										},
										{
											title: 'company.flat.location',
											name: 'roomNumber',
											maxLength: 20
										},

										{
											title: 'company.community',
											name: 'locality',
											maxLength: 140
										},
										{
											title: 'catalog.partin.additionalInformation',
											name: 'additionalInformation',
											maxLength: 140
										}
									],
									objectData,
									{},
									inputConf
								)
							}),
							createTab({
								title: edi.i18n.getMessage('company.type.company'),
								closable: false,
								autoScroll: true,
								layout: {
									type: 'grid',
									gap: [24, 16]
								},
								bodyPadding: '16 24 0 24',
								items: createFieldsForProductGrid(
									[
										{
											title: 'column.iln',
											name: 'headquarter.iln',
											regex: edi.constants.VALIDATORS.ILN,
											maskRe: /\d/i,
											enforceMaxLength: true,
											minLength: 13,
											maxLength: 13
										},
										{
											title: 'column.name',
											name: 'headquarter.name',
											maxLength: 175
										}
									],
									objectData,
									{},
									inputConf
								)
							})
						]
					})
				]
			}))
		]
	});
	modal.show();
	form.isValid();
};

export {
	PHONE_VALIDATOR,
	DATE_FORMAT,
	TIME_FORMAT,
	SERVER_DATE_FORMAT,
	TIME_FORMAT_WITH_SEC,
	createLicenseTypeStore,
	createCatalogGrid,
	deleteRowCatalog,
	createRowCatalog,
	getPartiesModalConf,
	findOrgByValues,
	getPartiesFieldsMap,
	checkOrgValid,
	convertOrgBeforeRender,
	createLocalizationStatusStore,
	getLicenseTypeText,
	showModalLocalizationsForm,
	showModalHeadquartersForm,
	showModalBranchesForm
};
