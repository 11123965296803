edi.navigation.addNavigationConfig({
	name: 'ediweb_client',
	items: {
		control_center: {
			title: 'control.center',
			modName: 'control.center.module.panel',
			glyph: edi.constants.ICONS.CENTER_FOCUS_STRONG,
			order: 2
		},
		documents: {
			title: 'navigation.documents',
			modName: 'documents',
			glyph: edi.constants.ICONS.FILE,
			order: 3
		},
		documentsForSign: {
			title: 'navigation.documents_for_sign',
			modName: 'documentsForSign',
			glyph: edi.constants.ICONS.SIGN_NEW,
			permissions: ['SIGN_EXTENDED'],
			order: 3.5
		},
		document_packages: {
			order: 3.6,
			glyph: edi.constants.ICONS.BOOK_OPEN,
			title: 'navigation.document.packages',
			modName: 'document.packages'
		},
		invitations: {
			title: 'navigation.ediweb.invitations',
			modName: 'invitations',
			glyph: edi.constants.ICONS.ENVELOPE,
			order: 4
		},
		settings: {
			title: 'org.profile',
			modName: 'org.profile',
			glyph: edi.constants.ICONS.SETTINGS,
			order: 6,
			options: {
				startTab: 'settings'
			}
		},
		approvals: {
			title: 'approval.documents',
			modName: 'approval.documents',
			glyph: edi.constants.ICONS.APPROVAL_DOCUMENTS,
			order: 7,
			id: 'approval.documents'
		},
		archive: {
			title: 'archive',
			modName: 'document.archive',
			glyph: edi.constants.ICONS.ARCHIVE,
			order: 8,
			id: 'archive'
		},
		help_desk: {
			delimiter: true,
			order: 500,
			title: 'navigation.help.desk',
			url: 'https://support.ediweb.com',
			glyph: edi.constants.ICONS.HELP
		}
	}
});
