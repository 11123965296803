import { POA_CONST } from '@Edi/modules/power_of_attorney/configuration';
// @ts-ignore
import { createLabel, createTextField } from '@UIkit/components/fields';
// @ts-ignore
import { createContainer, createModalForm } from '@UIkit/components/panels';
import { createRowsBlock } from '@Components/createRows/create.rows.v2';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { PoaChainRowContainer, PoaFromChain, Poas, Unknowns } from '@Edi/modules/power_of_attorney/difinitions';
import { poaFeatures } from '@Edi/modules/power_of_attorney/features';
// @ts-ignore
import { createToast } from '@UIkit/components/toast/Toast';
import { powerOfAttorneyServices } from '@Edi/modules/power_of_attorney/services';
// @ts-ignore
import { showError, showInfo } from '@UIkit/components/modal/MessageBox';
import { getPoaErrorReasonByType } from '@Edi/modules/power_of_attorney/methods';

interface onPoaLoadedProps {
	poas: Poas;
	unknowns: Unknowns;
	state: string;
}
interface validateRetrustPoaRowProps {
	poaList: PoaFromChain[];
}
export const chainImportHandler = function () {
	const setLoading = function (load: boolean) {
		if (modal && !modal.isDestroyed) {
			modal.setLoading(load);
		}
	};
	const onPoaLoaded = function ({ poas, unknowns, state }: onPoaLoadedProps) {
		const { poaList, poaWithErrors, poaWithoutErrors } = powerOfAttorneyServices.convertChainPoaData({ poas });
		const isPoaWithErrors = !!poaWithErrors.length;

		if (state === 'DONE') {
			const isProcessedPoa = !!poaList.length;
			const isMissingPoaInChain = !!unknowns.length;
			if (!isProcessedPoa && !isMissingPoaInChain) {
				showInfo({ msgText: edi.i18n.getMessage('power.of.attorney.chain.already.exists') });
			} else if (isPoaWithErrors) {
				poaFeatures.showPoAChainError({ poaList, poaWithErrors, poaWithoutErrors });
				validateRetrustPoaRow({ poaList });
			} else if (isMissingPoaInChain) {
				showError({
					msgText: edi.i18n.getMessage('power.of.attorney.chain.not.complete')
				});
				validateRetrustPoaRow({ poaList });
				// unknowns - перечень номеров МЧД, которые есть в цепочке, но их надо докачать
				unknowns.forEach((unknownPoaNumber) => {
					const newRow = poaChainRowBlock.createRow({ poaNumber: unknownPoaNumber });
					poaChainRowBlock.add(newRow);
				});
				poaChainRowBlock.updateRowsBlock();
				poaChainRowBlock.rowChange();
			} else {
				createToast({
					type: 'success',
					content: edi.i18n.getMessage('power.of.attorney.success.chain.import.count', [
						poaWithoutErrors.length,
						poaList.length
					])
				});
				modal.close();
				edi.events.powerOfAttorney.fireEvent('change');
			}
		}
	};
	const validateRetrustPoaRow = ({ poaList }: validateRetrustPoaRowProps) => {
		const retrustPoaRows = poaChainRowBlock.getRows();
		retrustPoaRows.forEach((retrustPoaRow: ExtComponent) => {
			const poaContainer = retrustPoaRow.contentFieldsPanel as PoaChainRowContainer;
			const { poaNumberField, principalInnField, representativeInnField, errorLabel } = poaContainer.getFields();
			const { poaNumber } = poaContainer.getValues();
			const poaData = poaList.find((poa) => poa.number === poaNumber);
			if (!poaData) return;

			const isErrorPoa = poaData.state === edi.constants.STATE.ERROR;
			if (isErrorPoa) {
				const errorType = typeof poaData.errorMessage === 'object' ? poaData.errorMessage.type : null;
				const errorText = getPoaErrorReasonByType(errorType, poaNumber);
				const errorMessage = edi.utils.safeString(poaData.errorMessage, true);
				poaContainer.errorMessage = poaData.errorMessage;
				errorLabel.setText(errorText || errorMessage);
				poaNumberField.isValid();
				principalInnField.isValid();
				representativeInnField.isValid();
			} else if (poaData) {
				poaNumberField.setDisabled(true);
				principalInnField.setDisabled(true);
				representativeInnField.setDisabled(true);
			}
		});
	};
	const createRetrustPoaRow = (
		isFirstRow: boolean,
		rowData: {
			poaNumber?: string;
		}
	) => {
		const onChangeHandler = () => {
			poaContainer.errorMessage = null;
			errorLabel.setText();
			poaNumber.isValid();
			principalInn.isValid();
			representativeInn.isValid();
		};
		const poaNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.external.number'),
			allowBlank: false,
			value: rowData?.poaNumber,
			regex: POA_CONST.NUMBER_VALIDATOR,
			validator: () => {
				const error = poaContainer.errorMessage;
				return !error || '';
			},
			listeners: {
				change: onChangeHandler
			}
		}) as ExtComponent;
		const principalInn = createTextField({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.inn'),
			allowBlank: false,
			regex: edi.constants.VALIDATORS.INN,
			regexText: edi.i18n.getMessage('company.inn.error.full'),
			maskRe: edi.constants.VALIDATORS.MASK_RE_INN,
			validator: () => {
				const error = poaContainer.errorMessage;
				return !error || '';
			},
			listeners: {
				change: onChangeHandler
			}
		}) as ExtComponent;
		const representativeInn = createTextField({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.inn.short'),
			allowBlank: false,
			regex: edi.constants.VALIDATORS.INN,
			regexText: edi.i18n.getMessage('company.inn.error.full'),
			maskRe: edi.constants.VALIDATORS.MASK_RE_INN,
			validator: () => {
				const error = poaContainer.errorMessage;
				return !error || '';
			},
			listeners: {
				change: onChangeHandler
			}
		}) as ExtComponent;
		const errorLabel = createLabel({
			cls: 'poa-error-details-text'
		}) as ExtComponent;
		const rowNumberLabel = createLabel({
			width: 28,
			margin: '16 0 0 0',
			typography: 'body-short_02'
		}) as ExtComponent;
		const poaContainer = createContainer({
			layout: 'hbox',
			getFields: () => ({
				poaNumberField: poaNumber,
				principalInnField: principalInn,
				representativeInnField: representativeInn,
				errorLabel,
				rowNumberLabel
			}),
			getValues: () => {
				const poaNumberValue: string = poaNumber.getValue();
				const principalInnValue: string = principalInn.getValue();
				const representativeInnValue: string = representativeInn.getValue();
				return {
					poaNumber: poaNumberValue,
					principalInn: principalInnValue,
					representativeInn: representativeInnValue
				};
			},
			items: [
				rowNumberLabel,
				createContainer({
					layout: {
						type: 'grid',
						gap: 8
					},
					flex: 1,
					items: [
						createContainer({
							layout: {
								type: 'grid',
								area: [[6, 3, 3]]
							},
							items: [poaNumber, principalInn, representativeInn]
						}),
						errorLabel
					]
				})
			]
		}) as PoaChainRowContainer;
		return poaContainer;
	};
	const poaChainRowBlock = createRowsBlock({
		createContentFieldsFn: createRetrustPoaRow,
		//При открытии окна сразу должно быть доступно две строки
		initialData: [null, null],
		addRowBtnConf: {
			text: edi.i18n.getMessage('form.btn.poa.add'),
			tooltip: edi.i18n.getMessage('form.btn.poa.add')
		},
		rowChange: function () {
			const me = this;
			const rows = me.getRows();
			rows.forEach((row: ExtComponent, i: number) => {
				const { rowNumberLabel } = row.contentFieldsPanel.getFields();
				rowNumberLabel.setText(`${i + 1}.`);
			});
		}
	}) as ExtComponent;
	const form = createModalForm({
		items: [
			createLabel({
				text: edi.i18n.getMessage('power.of.attorney.chain.upload.subtitle')
			}),
			poaChainRowBlock
		]
	}) as ExtComponent;

	const modal = createModalPanel({
		title: edi.i18n.getMessage('power.of.attorney.chain.upload.title'),
		width: MODAL_SIZE.widthLarge,
		items: form,
		buttonsBefore: [
			createButton({
				text: edi.i18n.getMessage('add.power.of.attorney.btn'),
				cls: BUTTON_CLS.primary,
				handler: () =>
					poaFeatures.chainUploadHandler({
						poaChainRowBlock,
						setLoading,
						isChainValid: () => form.isValid(),
						onPoaLoaded
					})
			})
		]
	}) as ExtComponent;

	modal.show();
};
