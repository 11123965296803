import { createCompanySelector } from '@Components/company.selector.js';
import {
	createCheckbox,
	createCombo,
	createFieldsForProductGrid,
	createHiddenField,
	createLabel as createOldLabel,
	createNumberField,
	createTextField,
	getInputMethodByType
} from '@Components/fields';
import { createContainer } from '@Components/miscComponents';
import {
	createAddButton,
	createCancelButton,
	createCreateSaveButton,
	createSaveButton,
	createSendButton
} from '@Components/buttons';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel,
	createToolBar,
	ROW_COLOR_CLS
} from '@Components/grid';
import { createForm, createPanel } from '@Components/panels';
import { createProxyConfig, createSimpleStore, createStore } from '@Components/storeComponents';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createProductCatalogItemModal } from './specialComponents/productCatalogItemModal';
import { createLocationCatalogItemModal } from './specialComponents/locationCatalogItemModal';
import { createPanelWithModalSelect } from '@Core/specialComponents/miscComponents';
import { realtimeEvents } from '../realtime_messages';
import { createModalPanel, showMessageBox, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createDetailLabel, createLabelForDetails, createLabel } from '@UIkit/components/fields';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';
import { powerOfAttorneyAPI } from '@Edi/modules/power_of_attorney/rest';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createToast } from '@UIkit/components/toast/Toast';
import { createInputOrgSelector } from '@Components/InputOrgSelector/InputOrgSelector';
import { createCertificateHandler } from '@App/js/signature/handlers/createCertificateHandler';
import { createPoaHandler } from '@App/js/signature/handlers/createPoaHandler';
import { showInfo } from '@UIkit/components/modal/MessageBox';

/**
 * Created by Дарья on 09.03.2017.
 */
Ext.namespace('edi.methods');
Object.assign(edi.methods, {
	sscc: {
		get: function (type, callback) {
			var value;
			type = undefined !== type ? type : edi.SKU.PALLET;
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.SSCC.GET,
					{
						sku: type
					},
					true
				),
				'GET',
				null,
				function (responseData) {
					value = responseData.data;
				},
				edi.rest.getErrorHandler(),
				function () {
					callback ? callback(value) : null;
				}
			);
		}
	},
	quick_answer: {
		checkDocument: function (docData, nameCode) {
			if (edi.constants.STATE.COMPLETED === docData.state) {
				var settingsStore = edi.stores.initQuickAnswerSettingsStore(),
					docFromOrg = docData.record.get('fromOrg'),
					docToOrg = docData.record.get('toOrg');
				if (settingsStore.getCount() && docToOrg.id === edi.core.getUserData().org.id) {
					var partnerSettings = settingsStore.getById(docFromOrg.id);
					return partnerSettings && partnerSettings.typeAllowed(docData.docType, nameCode);
				}
			}
			return false;
		},
		notAllowedFlowTypes: function (docData) {
			var types = [],
				settingsStore = edi.stores.initQuickAnswerSettingsStore(),
				docFromOrg = docData.fromOrg,
				docToOrg = docData.toOrg,
				docNameCode = edi.utils.getAttributeByName(docData.attributes, 'docNameCode');

			if (settingsStore && docFromOrg && docToOrg) {
				if (settingsStore.getCount() && docToOrg.id === edi.core.getUserData().org.id) {
					var partnerSettings = settingsStore.getById(docFromOrg.id);
					if (partnerSettings) {
						return partnerSettings.flowTypesForHide(docData.type, docNameCode);
					}
				}
			}
			return types;
		}
	},
	numberDocumentGen: {
		create: function (origin, docAllowTypes) {
			const __self = this;
			var buttons = [];

			var stringFormat = origin ? origin.stringFormat.split('%06d') : null;

			var prefixInput = createTextField({
				columnWidth: 0.361,
				name: 'prefix',
				value: origin ? stringFormat[0] : null,
				listeners: {
					change: function (comp, value) {
						const number = numberPartInput.getValue();
						let after = postfixInput.getValue();
						if (!after && after != 0) {
							after = '';
						}
						exampleGenLabel?.setText(__self.methods.dynamicExample(value, number, after));
					}
				},
				enforceMaxLength: true
			});

			var prefix = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.formatString'),
				items: [prefixInput]
			});

			var postfixInput = createTextField({
				name: 'postfix',
				value: origin ? stringFormat[1] : null,
				listeners: {
					change: function (comp, value) {
						const number = numberPart.getInput().getValue();
						let before = prefixInput.getValue();
						if (!before && before != 0) {
							before = '';
						}
						exampleGenLabel?.setText(__self.methods.dynamicExample(before, number, value));
					}
				},
				enforceMaxLength: true
			});

			var postfix = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: '+ ' + edi.i18n.getMessage('document.generation.numbers.nr') + ' +',
				items: [postfixInput]
			});

			var valueNumberPart = origin ? origin.numberPart : 0;

			var numberPartInput = createNumberField({
				name: 'numberPart',
				value: valueNumberPart,
				allowBlank: false,
				validator: function (value) {
					if (value < maxNumberInput.value) {
						return true;
					} else {
						return edi.i18n.getMessage('document.generation.numbers.text.valid');
					}
				},
				listeners: {
					change: function (comp, value) {
						maxNumberInput.validate();

						let before = prefixInput.getValue();
						let after = postfixInput.getValue();
						if (!before && before != 0) {
							before = '';
						}
						if (!after && after != 0) {
							after = '';
						}

						exampleGenLabel?.setText(__self.methods.dynamicExample(before, value, after));
					}
				},
				minLength: 1,
				maxLength: 6,
				enforceMaxLength: true
			});

			var numberPart = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.startNr'),
				items: [numberPartInput]
			});

			var exampleText,
				examplePrefix = '',
				lengthNumber = String(++valueNumberPart).length;
			while (lengthNumber++ < 6) {
				examplePrefix += '0';
			}

			exampleText = stringFormat
				? stringFormat[0] + examplePrefix + valueNumberPart + stringFormat[1]
				: examplePrefix + valueNumberPart;

			var exampleGenLabel;
			var exampleGen = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.text.example'),
				items: [
					(exampleGenLabel = createDetailLabel({
						name: 'exampleText',
						text: exampleText,
						allowBlank: false,
						enforceMaxLength: true
					}))
				]
			});

			var maxNumberInput;
			var maxNumber = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.nr.max'),
				items: [
					(maxNumberInput = createNumberField({
						name: 'maxNumber',
						value: origin ? origin.maxNumber : 999999,
						allowBlank: false,
						validator: function (value) {
							numberPartInput.validate();

							if (value > numberPartInput.value) {
								return true;
							} else {
								return edi.i18n.getMessage('document.generation.numbers.text.valid');
							}
						},
						minLength: 1,
						maxLength: 6,
						enforceMaxLength: true
					}))
				]
			});

			var store = edi.stores.createMemoryStore(docAllowTypes, 'SIMPLE');

			var typeDoc = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.typeDoc'),
				items: [
					createCombo({
						allowBlank: false,
						name: 'typeDoc',
						store: store
					})
				]
			});

			buttons.push(
				createCreateSaveButton({
					handler: function () {
						var failure = function (data) {
							modal.setLoading(false);
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
						};

						var form = formPanel.getForm();
						if (form.isValid()) {
							modal.setLoading();
							var values = edi.utils.collectFormValues(form);
							edi.rest.sendRequest(
								edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.POST,
								'POST',
								Ext.encode({
									numberPart: values.numberPart,
									typeDoc: values.typeDoc,
									maxNumber: values.maxNumber,
									stringFormat: values.prefix + '%06d' + values.postfix,
									needToFixCurrentNumber: values.needToFixCurrentNumber,
									orgPartners: partnersGrid.getStore().collect('id')
								}),
								function (data) {
									if (data.data) {
										edi.events.numberDocGen.fireEvent('change');
										modal.setLoading(false);
										modal.close();
									} else {
										failure(data);
									}
								},
								failure
							);
						} else {
							edi.core.showError('document.generation.numbers.fill.fields');
						}
					}
				})
			);

			var needToFixCurrentNumber = createCheckbox({
				boxLabel: edi.i18n.getMessage('document.generation.numbers.needToFixCurrentNumber'),
				name: 'needToFixCurrentNumber',
				inputValue: true
			});
			var partnerColumns = edi.columns.get('numeration_settings_partners');
			partnerColumns = partnerColumns.concat(
				createActionsColumnConfig({
					align: 'center',
					items: [
						{
							glyph: edi.constants.ICONS.REMOVE,
							testCls: 'test-action-column-remove',
							handler: function (grid, rowIndex) {
								var gridStore = grid.getStore();
								gridStore.removeAt(rowIndex);
								gridStore.commitChanges();
							}
						}
					]
				})
			);
			var partnerAddButton = createAddButton(
				() => {
					const urlModal = edi.rest.services.ORGANIZATION.NOT_INCLUDED_PARTNERS.POST;
					const { formItemsMap, items } = edi.filters.config.partners.createFormItems();
					var modal = createModalRemoteSelect(
						urlModal,
						function (orgsData) {
							var store = partnersGrid.getStore();
							orgsData?.forEach(function (orgData) {
								if (-1 === store.find('id', orgData.id)) {
									store.add(orgData);
									store.commitChanges();
								}
							});
						},
						{
							checkboxes: true,
							groupOperation: true,
							model: 'ORGANIZATIONS',
							columns: 'partners',
							sorters: {
								property: 'name',
								direction: 'ASC'
							},
							proxyConfig: {
								type: 'ajax',
								url: urlModal,
								extraJsonData: {
									orgIds: [edi.core.getUserData().org.id],
									notIncludePartners: []
								},
								actionMethods: {
									read: 'POST'
								},
								reader: {
									totalProperty: function (data) {
										return data?.data?.totalCount;
									},
									rootProperty: function (data) {
										return data?.data?.result;
									}
								}
							},
							createFilterFormItems: items,
							createFormItemsMap: formItemsMap,
							createArgs: edi.filters.config.organizations.createArgs,
							title: edi.i18n.getMessage('organization.select.title')
						}
					);
					modal.show();
				},
				{
					margin: '0 0 0 3',
					disabled: false
				}
			);
			var partnersGridCnf = {
				storeConfig: {
					model: edi.models.getModel('ORGANIZATIONS'),
					sorters: {
						property: 'name',
						direction: 'ASC'
					},
					autoLoad: true
				},
				gridConfig: {
					maxHeight: 240,
					columns: partnerColumns,
					dockedItems: createToolBar({
						items: [partnerAddButton]
					}),
					title: edi.i18n.getMessage('org.partners'),
					disablePaging: true
				}
			};
			var partnersGrid = createGrid(partnersGridCnf);
			var formPanel = createModalForm({
				submitEmptyText: false,
				layout: {
					type: 'grid',
					gap: [24, 16],
					area: [[6, 6], [6, 6], [6, 6], 12, 12]
				},
				items: [
					numberPart,
					maxNumber,

					prefix,
					postfix,

					typeDoc,
					exampleGen,

					needToFixCurrentNumber,

					partnersGrid
				]
			});

			var modal = createModalPanel({
				title: edi.i18n.getMessage('document.generation.numbers.add'),
				width: MODAL_SIZE.widthLarge,
				items: [formPanel],
				buttonsBefore: buttons
			});
			modal.show();
			formPanel.isValid();
		},
		edit: function (objectData) {
			const __self = this;
			var buttons = [];
			var formPanel;

			buttons.push(
				createCreateSaveButton({
					text: edi.i18n.getMessage('form.btn.save'),
					handler: function () {
						var failure = function (data) {
							modal.setLoading(false);
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
						};

						var form = formPanel.getForm();
						if (form.isValid()) {
							modal.setLoading();
							var values = edi.utils.collectFormValues(form);
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.PUT, {
									id: objectData.id
								}),
								'PUT',
								Ext.encode({
									numberPart: values.numberPart,
									policy: values.active ? 'ALLOW' : 'DENY',
									stringFormat: values.prefix + '%06d' + values.postfix,
									maxNumber: values.maxNumber,
									needToFixCurrentNumber: values.needToFixCurrentNumber,
									orgPartners: partnersGrid.getStore().collect('id')
								}),
								function (data) {
									if (data.data) {
										edi.events.numberDocGen.fireEvent('change');
										modal.setLoading(false);
										modal.close();
									} else {
										failure(data);
									}
								},
								failure
							);
						} else {
							edi.core.showError('document.generation.numbers.fill.fields');
						}
					}
				})
			);
			var partnerColumns = edi.columns.get('numeration_settings_partners');
			partnerColumns = partnerColumns.concat(
				createActionsColumnConfig({
					align: 'center',
					items: [
						{
							glyph: edi.constants.ICONS.REMOVE,
							testCls: 'test-action-column-remove',
							handler: function (grid, rowIndex) {
								var gridStore = grid.getStore();
								var record = gridStore.getAt(rowIndex);
								if (record) {
									edi.rest.sendRequest(
										edi.utils.formatString(
											edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.PARTNERS.DELETE,
											{
												partnerId: record.getId(),
												id: objectData.id
											}
										),
										'DELETE',
										undefined,
										undefined,
										function (errorData) {
											edi.core.showError(
												edi.utils.formatComplexServerError(errorData, 'error.getting.data')
											);
										},
										function () {
											gridStore.reload();
										}
									);
								}
							}
						}
					]
				})
			);
			var partnerAddButton = createAddButton(
				() => {
					const urlModal = edi.rest.services.ORGANIZATION.NOT_INCLUDED_PARTNERS.POST;
					const { formItemsMap, items } = edi.filters.config.partners.createFormItems();
					var modal = createModalRemoteSelect(
						urlModal,
						function (orgsData) {
							var store = partnersGrid.getStore();
							var ids = [];
							orgsData?.forEach(function (orgData) {
								ids.push(orgData.id);
							});
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.PARTNERS.PUT, {
									id: objectData.id
								}),
								'PUT',
								Ext.encode(ids),
								undefined,
								function (errorData) {
									edi.core.showError(
										edi.utils.formatComplexServerError(errorData, 'error.getting.data')
									);
								},
								function () {
									store.reload();
								}
							);
						},
						{
							checkboxes: true,
							groupOperation: true,
							model: 'ORGANIZATIONS',
							columns: 'partners',
							sorters: {
								property: 'name',
								direction: 'ASC'
							},
							proxyConfig: {
								type: 'ajax',
								url: urlModal,
								extraJsonData: {
									orgIds: [edi.core.getUserData().org.id],
									notIncludePartners: partnersGrid.getStore().collect('id')
								},
								actionMethods: {
									read: 'POST'
								},
								reader: {
									totalProperty: function (data) {
										return data?.data?.totalCount;
									},
									rootProperty: function (data) {
										return data?.data?.result;
									}
								}
							},
							createFilterFormItems: items,
							createFormItemsMap: formItemsMap,
							createArgs: edi.filters.config.organizations.createArgs,
							title: edi.i18n.getMessage('organization.select.title')
						}
					);
					modal.show();
				},
				{
					disabled: false
				}
			);
			var partnersGridCnf = {
				storeConfig: {
					model: edi.models.getModel('ORGANIZATIONS'),
					sorters: {
						property: 'name',
						direction: 'ASC'
					},
					autoLoad: true
				},
				gridConfig: {
					maxHeight: 240,
					columns: partnerColumns,
					title: edi.i18n.getMessage('org.partners'),
					dockedItems: createToolBar({
						items: [partnerAddButton]
					}),
					disablePaging: true
				}
			};
			partnersGridCnf.proxyConfig = {
				type: 'ajax',
				url: edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.PARTNERS.GET_BY_ID, {
					id: objectData.id
				})
			};
			var partnersGrid = createGrid(partnersGridCnf);
			formPanel = createModalForm({
				submitEmptyText: false,
				layout: {
					type: 'grid',
					gap: [24, 16],
					area: [[6, 6], [6, 6], [6, 6], [6, 6], 12]
				},
				items: [...__self.methods.getNumberDocumentFields(objectData), partnersGrid]
			});

			var modal = createModalPanel({
				title: [edi.i18n.getMessage('document.generation.numbers.pattern'), objectData.id].join(' '),
				width: MODAL_SIZE.widthLarge,
				items: [formPanel],
				buttonsBefore: buttons
			});
			modal.show();
			formPanel.isValid();
		},
		detail: function (objectData) {
			var valueNumberPart = objectData.numberPart;
			var numberPart = createFieldBlock({
				cls: MODAL_SIZE.widthSmall,
				title: edi.i18n.getMessage('document.generation.numbers.startNr'),
				items: [
					createDetailLabel({
						text: valueNumberPart
					})
				]
			});
			var stringFormat = objectData.stringFormat.split('%06d');
			var prefix = createFieldBlock({
				cls: MODAL_SIZE.widthSmall,
				title: edi.i18n.getMessage('document.generation.numbers.formatString'),
				items: [
					createDetailLabel({
						text: stringFormat[0]
					})
				]
			});
			var postfix = createFieldBlock({
				cls: MODAL_SIZE.widthSmall,
				title: '+ ' + edi.i18n.getMessage('document.generation.numbers.nr') + ' +',
				items: [
					createDetailLabel({
						text: stringFormat[1]
					})
				]
			});

			var maxNumber = createFieldBlock({
				cls: MODAL_SIZE.widthSmall,
				title: edi.i18n.getMessage('document.generation.numbers.nr.max'),
				items: [
					createDetailLabel({
						text: objectData.maxNumber
					})
				]
			});
			var autogenCheckbox = createCheckbox({
				boxLabel: edi.i18n.getMessage('document.generation.numbers.is.active'),
				inputValue: true,
				checked: objectData.policy === 'ALLOW',
				name: 'active',
				readOnly: true
			});

			var formPanel,
				exampleText,
				examplePrefix = '',
				lengthNumber = String(++valueNumberPart).length;
			while (lengthNumber++ < 6) {
				examplePrefix += '0';
			}
			exampleText = stringFormat
				? stringFormat[0] + examplePrefix + valueNumberPart + stringFormat[1]
				: examplePrefix + valueNumberPart;

			var exampleGen = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.text.example'),
				items: [
					createDetailLabel({
						name: 'exampleText',
						text: exampleText,
						allowBlank: false,
						enforceMaxLength: true
					})
				]
			});

			var docType = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.typeDoc'),
				items: [
					createDetailLabel({
						name: 'typeDoc',
						text: edi.i18n.getMessage(`documents.doctype.${objectData.typeDoc}`),
						allowBlank: false,
						enforceMaxLength: true
					})
				]
			});

			var needToFixCurrentNumber = createCheckbox({
				boxLabel: edi.i18n.getMessage('document.generation.numbers.needToFixCurrentNumber'),
				name: 'needToFixCurrentNumber',
				checked: objectData.needToFixCurrentNumber,
				inputValue: true,
				readOnly: true
			});
			var partnerColumns = edi.columns.get('numeration_settings_partners');
			var partnersGridCnf = {
				storeConfig: {
					model: edi.models.getModel('ORGANIZATIONS'),
					sorters: {
						property: 'name',
						direction: 'ASC'
					},
					autoLoad: true
				},
				gridConfig: {
					columns: partnerColumns,
					title: edi.i18n.getMessage('org.partners'),
					disablePaging: true
				}
			};
			partnersGridCnf.proxyConfig = {
				type: 'ajax',
				url: edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.PARTNERS.GET_BY_ID, {
					id: objectData.id
				})
			};
			var partnersGrid = createGrid(partnersGridCnf);
			formPanel = createModalForm({
				submitEmptyText: false,
				layout: {
					type: 'grid',
					gap: [24, 16],
					area: [[6, 6], [6, 6], [6, 6], [6, 6], 12]
				},
				items: [
					numberPart,
					maxNumber,

					prefix,
					postfix,

					docType,
					exampleGen,

					autogenCheckbox,
					needToFixCurrentNumber,

					partnersGrid
				]
			});

			var modal = createModalPanel({
				title: [edi.i18n.getMessage('document.generation.numbers.pattern'), objectData.id].join(' '),
				width: MODAL_SIZE.widthLarge,
				items: [formPanel]
			});
			modal.show();
		},
		copy: function (objectData, docAllowTypes) {
			const __self = this;
			let partnersGrid;
			let typeDocInput;
			const typeDoc = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.generation.numbers.typeDoc'),
				items: [
					(typeDocInput = createCombo({
						allowBlank: false,
						name: 'typeDoc',
						store: edi.stores.createMemoryStore(docAllowTypes, 'SIMPLE')
					}))
				]
			});
			const selectDocTypeModal = createModalPanel({
				title: edi.i18n.getMessage('document.generation.numbers.select.doctype'),
				width: MODAL_SIZE.widthSmall,
				items: [
					createModalForm({
						items: [typeDoc]
					})
				],
				buttonsBefore: [
					createSendButton(
						() => {
							const typeDocValue = typeDocInput.getValue();

							const url = edi.utils.compileURL(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.COPY, {
								id: objectData.id,
								docType: typeDocValue
							});
							const success = function (response) {
								const responseData = response.data;
								selectDocTypeModal.close();
								const formPanel = createFormPanel(responseData);
								const copyModal = createModalPanel({
									title: `${edi.i18n.getMessage('document.generation.numbers.pattern')} ${
										responseData.id || ''
									}`,
									width: MODAL_SIZE.widthLarge,
									items: [formPanel],
									buttonsBefore: [
										createCreateSaveButton({
											handler: function () {
												var failure = function (data) {
													modal.setLoading(false);
													edi.core.showError(
														edi.utils.formatComplexServerError(data, 'error.getting.data')
													);
												};

												const form = formPanel.getForm();
												if (form.isValid()) {
													copyModal.setLoading();
													const values = edi.utils.collectFormValues(form);
													edi.rest.sendRequest(
														edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.POST,
														'POST',
														Ext.encode({
															numberPart: values.numberPart,
															typeDoc: responseData.typeDoc,
															maxNumber: values.maxNumber,
															stringFormat: values.prefix + '%06d' + values.postfix,
															needToFixCurrentNumber: values.needToFixCurrentNumber,
															orgPartners: partnersGrid.getStore().collect('id')
														}),
														function (data) {
															if (data.data) {
																edi.events.numberDocGen.fireEvent('change');
																copyModal.setLoading(false);
																copyModal.close();
															} else {
																failure(data);
															}
														},
														failure
													);
												} else {
													edi.core.showError('document.generation.numbers.fill.fields');
												}
											}
										})
									]
								});
								selectDocTypeModal.setLoading(false);
								copyModal.show();
							};
							const failure = function (data) {
								edi.core.showError(
									edi.utils.formatComplexServerError(data, 'error.server'),
									function () {
										selectDocTypeModal.setLoading(false);
									}
								);
							};
							selectDocTypeModal.setLoading();
							edi.rest.sendRequest(url, 'POST', undefined, success, failure);
						},
						{
							disabled: false
						}
					)
				]
			});
			const createFormPanel = (data) => {
				let partnerColumns = edi.columns.get('numeration_settings_partners');
				partnerColumns = partnerColumns.concat(
					createActionsColumnConfig({
						align: 'center',
						items: [
							{
								glyph: edi.constants.ICONS.REMOVE,
								testCls: 'test-action-column-remove',
								handler: function (grid, rowIndex) {
									const gridStore = grid.getStore();
									gridStore.removeAt(rowIndex);
									gridStore.commitChanges();
								}
							}
						]
					})
				);
				const partnerAddButton = createAddButton(
					() => {
						const urlModal = edi.rest.services.ORGANIZATION.NOT_INCLUDED_PARTNERS.POST;
						const { formItemsMap, items } = edi.filters.config.partners.createFormItems();
						const modal = createModalRemoteSelect(
							urlModal,
							function (orgsData) {
								const store = partnersGrid.getStore();
								orgsData?.forEach(function (orgData) {
									if (-1 === store.find('id', orgData.id)) {
										store.add(orgData);
										store.commitChanges();
									}
								});
							},
							{
								checkboxes: true,
								groupOperation: true,
								model: 'ORGANIZATIONS',
								columns: 'partners',
								sorters: {
									property: 'name',
									direction: 'ASC'
								},
								proxyConfig: {
									type: 'ajax',
									url: urlModal,
									extraJsonData: {
										orgIds: [edi.core.getUserData().org.id],
										notIncludePartners: partnersGrid.getStore().collect('id')
									},
									actionMethods: {
										read: 'POST'
									},
									reader: {
										totalProperty: function (data) {
											return data?.data?.totalCount;
										},
										rootProperty: function (data) {
											return data?.data?.result;
										}
									}
								},
								createFilterFormItems: items,
								createFormItemsMap: formItemsMap,
								createArgs: edi.filters.config.organizations.createArgs,
								title: edi.i18n.getMessage('organization.select.title')
							}
						);
						modal.show();
					},
					{
						disabled: false
					}
				);

				partnersGrid = createGrid({
					storeConfig: {
						model: edi.models.getModel('ORGANIZATIONS'),
						sorters: {
							property: 'name',
							direction: 'ASC'
						},
						data: {
							items: edi.utils.getObjectProperty(data, 'orgPartners', true)
						},
						autoLoad: true
					},
					gridConfig: {
						maxHeight: 240,
						columns: partnerColumns,
						title: edi.i18n.getMessage('org.partners'),
						dockedItems: createToolBar({
							items: [partnerAddButton]
						}),
						disablePaging: true
					}
				});

				return createModalForm({
					submitEmptyText: false,
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [[6, 6], [6, 6], [6, 6], [6, 6], 12]
					},
					items: [...__self.methods.getNumberDocumentFields(data), partnersGrid]
				});
			};
			selectDocTypeModal.show();
		},
		methods: {
			dynamicExample: function (before, number, after) {
				if (number || number == 0) {
					let numberPrefix = '',
						numberLength = String(++number).length;

					while (numberLength++ < 6) {
						numberPrefix += '0';
					}
					number = numberPrefix + number;
				} else {
					number = '';
				}
				return before + number + after;
			},
			getNumberDocumentFields: function (objectData) {
				const __self = this;
				let valueNumberPart = objectData.numberPart;

				let numberPartInput;
				const numberPart = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.generation.numbers.startNr'),
					items: [
						(numberPartInput = createNumberField({
							name: 'numberPart',
							value: valueNumberPart,
							allowBlank: false,
							validator: function (value) {
								if (value < maxNumberInput.value) {
									return true;
								} else {
									return edi.i18n.getMessage('document.generation.numbers.text.valid');
								}
							},
							listeners: {
								change: function (comp, value) {
									maxNumberInput.validate();

									let before = prefixInput.getValue();
									let after = postfixTitle.getValue();
									if (!before && before != 0) {
										before = '';
									}
									if (!after && after != 0) {
										after = '';
									}
									exampleGenLabel?.setText(__self.dynamicExample(before, value, after));
								}
							},
							minLength: 1,
							maxLength: 6,
							enforceMaxLength: true
						}))
					]
				});

				const stringFormat = objectData.stringFormat?.split('%06d');

				let prefixInput;
				const prefix = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.generation.numbers.formatString'),
					items: [
						(prefixInput = createTextField({
							name: 'prefix',
							value: stringFormat[0],
							listeners: {
								change: function (comp, value) {
									const number = numberPartInput.getValue();
									let after = postfixTitle.getValue();
									if (!after && after != 0) {
										after = '';
									}
									exampleGenLabel?.setText(__self.dynamicExample(value, number, after));
								}
							},
							enforceMaxLength: true
						}))
					]
				});

				let postfixTitle;
				const postfix = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: '+ ' + edi.i18n.getMessage('document.generation.numbers.nr') + ' +',
					items: [
						(postfixTitle = createTextField({
							name: 'postfix',
							value: stringFormat[1],
							listeners: {
								change: function (comp, value) {
									const number = numberPartInput.getValue();
									var before = prefixInput.getValue();
									if (!before && before != 0) {
										before = '';
									}
									exampleGenLabel?.setText(__self.dynamicExample(before, number, value));
								}
							},
							enforceMaxLength: true
						}))
					]
				});

				const docType = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.generation.numbers.typeDoc'),
					items: [
						createDetailLabel({
							name: 'typeDoc',
							text: edi.i18n.getMessage(`documents.doctype.${objectData.typeDoc}`),
							allowBlank: false,
							enforceMaxLength: true
						})
					]
				});

				createOldLabel({
					columnWidth: 0.585,
					name: 'typeDoc',
					text: edi.i18n.getMessage(`documents.doctype.${objectData.typeDoc}`),
					allowBlank: false,
					enforceMaxLength: true
				});

				let maxNumberInput;
				const maxNumber = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.generation.numbers.nr.max'),
					items: [
						(maxNumberInput = createNumberField({
							name: 'maxNumber',
							value: objectData.maxNumber,
							allowBlank: false,
							validator: function (value) {
								numberPartInput.validate();
								return value > numberPartInput.value
									? true
									: edi.i18n.getMessage('document.generation.numbers.text.valid');
							},
							minLength: 1,
							maxLength: 6,
							enforceMaxLength: true
						}))
					]
				});

				const autogenCheckbox = createCheckbox({
					boxLabel: edi.i18n.getMessage('document.generation.numbers.is.active'),
					inputValue: true,
					checked: objectData.policy === 'ALLOW',
					name: 'active'
				});

				let examplePrefix = '',
					lengthNumber = String(++valueNumberPart).length;
				while (lengthNumber++ < 6) {
					examplePrefix += '0';
				}

				const exampleText = stringFormat
					? stringFormat[0] + examplePrefix + valueNumberPart + stringFormat[1]
					: examplePrefix + valueNumberPart;

				let exampleGenLabel;
				const exampleGen = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.generation.numbers.text.example'),
					items: [
						(exampleGenLabel = createOldLabel({
							name: 'exampleText',
							text: exampleText,
							allowBlank: false,
							enforceMaxLength: true
						}))
					]
				});

				const needToFixCurrentNumber = createCheckbox({
					boxLabel: edi.i18n.getMessage('document.generation.numbers.needToFixCurrentNumber'),
					name: 'needToFixCurrentNumber',
					columnWidth: 1,
					checked: objectData.needToFixCurrentNumber,
					inputValue: true
				});

				return [
					numberPart,
					maxNumber,

					prefix,
					postfix,

					docType,
					exampleGen,

					autogenCheckbox,
					needToFixCurrentNumber
				];
			}
		}
	},
	lockDocument: {
		details: function (objectData) {
			var columns = edi.columns.get('document_locks');

			var updateActiveLock = function (rActive, nameModule) {
				var activeModule = edi.modulesHandler.getActiveModule();
				activeModule.tab.setLoading();

				var url = rActive === 'true' ? edi.rest.services.LOCK.DEACTIVE.PUT : edi.rest.services.LOCK.ACTIVE.PUT;

				url = edi.utils.formatString(url, {
					docID: objectData,
					nameModule: nameModule
				});

				edi.rest.sendRequest(url, 'PUT', Ext.encode(), function () {
					activeModule.tab.setLoading(false);

					var gridSelModel = grid.getSelectionModel();
					if (gridSelModel) {
						gridSelModel.deselectAll();
					}
					grid.getStore().reload();
				});
			};

			columns.push(
				createActionsColumnConfig({
					items: [
						{
							getTip: function (value, meta, record) {
								return record.data.active
									? edi.i18n.getMessage('document.lock.active.set')
									: edi.i18n.getMessage('document.lock.deactive.set');
							},
							handler: function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex).getData();
								if (record.typeLock === 'TEMPORALY') {
									updateActiveLock(record.active, record.businessModule);
									grid.removeRowCls(grid.getNode(rowIndex), 'line-through');
									grid.getStore().getAt(rowIndex).set('clicked', true);
									grid.getSelectionModel().select(rowIndex);
								}
							},
							getGlyph: function (value, metadata, record) {
								var iconRender = function () {
									if (record.data.active === 'true') {
										return edi.constants.ICONS.LOCK_OUTLINE;
									} else {
										return edi.constants.ICONS.LOCK_OPEN;
									}
								};
								return iconRender();
							},
							isHidden: function (view, rowIndex, colIndex, item, record) {
								return record.data.typeLock !== 'TEMPORALY';
							}
						}
					]
				})
			);

			var grid = createGrid({
				proxyConfig: {
					type: 'ajax',
					url: edi.utils.formatString(edi.rest.services.LOCK.GET, {
						id: objectData
					})
				},
				storeConfig: {
					model: edi.models.getModel('DOCUMENT_LOCK'),
					sortOnLoad: true,
					sorters: {
						property: 'modifyDate',
						direction: 'DESC'
					},
					autoLoad: true
				},
				gridConfig: {
					columns: columns,
					maxHeight: 480
				}
			});

			var modal = createModalPanel({
				width: MODAL_SIZE.widthLarge,
				title: edi.i18n.getMessage('document.lock') + ' ' + objectData,
				items: [grid]
			});
			modal.show();
		}
	},
	expenditure: {
		showModalAccountSelectionForm: function (record, expenditureValues, moduleData) {
			var selectedAccount = '';
			var lineNumber = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('column.line.number'),
				items: [
					createDetailLabel({
						text: record.get('LineNumber')
					})
				]
			});
			var productName = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('column.item.description'),
				items: [
					createDetailLabel({
						text: record.get('ItemDescription')
					})
				]
			});
			var productEan = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('column.ean'),
				items: [
					createDetailLabel({
						text: record.get('EAN')
					})
				]
			});
			var productBuyerCode = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('column.buyer.item.code'),
				items: [
					createDetailLabel({
						text: record.get('BuyerItemCode')
					})
				]
			});

			var account = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('column.invoice.line.account'),
				items: [
					createCombo({
						store: createStore({
							model: edi.models.getModel('EXPENDITURE_TYPE'),
							pageSize: edi.constants.MAX_PAGE_SIZE,
							remoteSort: false,
							proxy: createProxyConfig({
								type: 'ajax',
								pageParam: undefined,
								limitParam: undefined,
								startParam: undefined,
								url: edi.rest.services.EE.EXPENDITURE.FILTER.GET
							})
						}),
						allowBlank: false,
						forceSelection: true,
						anyMatch: true,
						displayField: 'name',
						valueField: 'name',
						name: 'value',
						value: record.get('account'),
						emptyText: edi.i18n.getMessage('form.combo.not.selected'),
						listeners: {
							select: function (comp) {
								if (!comp.getValue()) {
									comp.setValue(null);
									selectedAccount = '';
								} else {
									var data = comp.valueModels[0].data;
									selectedAccount = data.name;
								}
								formPanel.isValid();
							}
						}
					})
				]
			});

			var formPanel = createModalForm({
				submitEmptyText: false,
				items: [lineNumber, productEan, productName, productBuyerCode, account]
			});

			var modalConf = {
				title: edi.i18n.getMessage('invoice.line.account.modal.title'),
				width: MODAL_SIZE.widthMedium,
				items: [formPanel],
				buttonsBefore: [
					createCreateSaveButton(
						{
							handler: function () {
								if (formPanel.isValid()) {
									modal.setLoading();
									var success = function () {
										record.set('account', selectedAccount);
										record.commit();
										modal.close();
									};
									var failure = function (responseData) {
										edi.core.showError(
											edi.utils.formatComplexServerError(responseData, 'error.server'),
											function () {
												modal.setLoading(false);
											}
										);
									};
									var expId = null;
									for (var j = 0; j < expenditureValues.length; j++) {
										var value = expenditureValues[j];
										if (value.lineId == record.get('LineNumber')) {
											expId = value.id;
											break;
										}
									}
									if (expId) {
										edi.rest.sendRequest(
											edi.utils.formatString(edi.rest.services.EE.EXPENDITURE.VALUE.PUT, {
												expId: expId
											}),
											'PUT',
											Ext.encode({
												value: selectedAccount,
												doc: moduleData.initData.data.id,
												lineId: record.get('LineNumber')
											}),
											success,
											failure
										);
									} else {
										edi.rest.sendRequest(
											edi.rest.services.EE.EXPENDITURE.VALUE.POST,
											'POST',
											Ext.encode({
												value: selectedAccount,
												doc: moduleData.initData.data.id,
												lineId: record.get('LineNumber')
											}),
											success,
											failure
										);
									}
								}
							}
						},
						true
					)
				]
			};
			var modal = createModalPanel(modalConf);
			formPanel.isValid();
			modal.show();
		}
	},
	catalog: {
		item: {
			create: function (id, catalogType) {
				if (edi.permissions.hasPermission('EDIT_CATALOG_V2')) {
					var modalMethod;
					if (catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT) {
						modalMethod = createProductCatalogItemModal;
					} else {
						modalMethod = createLocationCatalogItemModal;
					}
					var modal = modalMethod(null, function (data) {
						modal.setLoading();
						edi.utils.clearEmptyValues(data);
						var failure = function () {
							edi.core.showError('error.server', function () {
								modal.setLoading(false);
							});
						};
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.CATALOGS.ITEM.POST, {
								id: id,
								type: edi.constants.CATALOG_TYPES[catalogType]
							}),
							'POST',
							Ext.encode(data),
							function (responseData) {
								edi.events.catalog.fireEvent('change', {
									id: id,
									catalogType: catalogType,
									data: responseData.data
								});
								modal.setLoading(false);
							},
							failure
						);
					});
				}
			},
			remove: function (id, items, catalogType) {
				if (edi.permissions.hasPermission('EDIT_CATALOG_V2')) {
					edi.core.confirm('confirmation.title', 'catalog.items.remove.confirmation', function () {
						var moduleData = edi.modulesHandler.getActiveModule();
						var itemsIds = items.map(function (item) {
							return item.id;
						});
						var failure = function () {
							edi.core.showError('error.server');
						};

						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.CATALOGS.ITEM.GROUP.DELETE, {
								id: id,
								type: edi.constants.CATALOG_TYPES[catalogType]
							}),
							'DELETE',
							Ext.encode(itemsIds),
							function () {
								edi.events.catalog.fireEvent('change', {
									id: id,
									catalogType: catalogType
								});
							},
							failure,
							function () {
								moduleData.tab.setLoading(false);
							}
						);
					});
				}
			},
			update: function (id, item, catalogType) {
				if (edi.permissions.hasPermission('EDIT_CATALOG_V2')) {
					var modalMethod;
					if (catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT) {
						modalMethod = createProductCatalogItemModal;
					} else {
						modalMethod = createLocationCatalogItemModal;
					}

					var putCatalogEditData = function (data) {
						modal.setLoading();
						edi.utils.clearEmptyValues(data);
						var failure = function () {
							edi.core.showError('error.server', function () {
								modal.setLoading(false);
							});
						};
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.CATALOGS.ITEM.PUT, {
								id: id,
								type: edi.constants.CATALOG_TYPES[catalogType],
								lineItemId: item.id
							}),
							'PUT',
							Ext.encode(data),
							function (responseData) {
								edi.events.catalog.fireEvent('change', {
									id: id,
									catalogType: catalogType,
									data: responseData.data
								});
								modal.setLoading(false);
							},
							failure
						);
					};
					var modal = modalMethod(
						item,
						putCatalogEditData,
						null,
						catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT
					);
				}
			},
			details: function (item, catalogType) {
				if (edi.permissions.hasPermission('READ_CATALOG')) {
					var modalMethod;
					if (catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT) {
						modalMethod = createProductCatalogItemModal;
					} else {
						modalMethod = createLocationCatalogItemModal;
					}
					modalMethod(
						item,
						null,
						{
							readOnly: true
						},
						catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT
					);
				}
			}
		},
		remove: function (id, catalogType) {
			if (edi.permissions.hasPermission('DELETE_CATALOG')) {
				edi.core.confirm('confirmation.title', 'document.delete.question', function () {
					var moduleData = edi.modulesHandler.getActiveModule();
					moduleData.tab.setLoading();
					var failure = function () {
						edi.core.showError('error.server', function () {
							moduleData.tab.setLoading(false);
						});
					};
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.CATALOGS.V2.DELETE, {
							type: edi.constants.CATALOG_TYPES[catalogType],
							id: id
						}),
						'DELETE',
						null,
						function () {
							moduleData.tab.setLoading(false);
							edi.events.catalog.fireEvent('change', {
								id: id,
								catalogType: catalogType,
								deleted: true
							});
						},
						failure
					);
				});
			}
		},
		createUploadFieldsByType: {
			[edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT]: function (moduleData, isReloadCatalog) {
				isReloadCatalog = isReloadCatalog || false;
				var userData = edi.core.getUserData();
				var toNetworkInput,
					toPartnerField,
					fromOrgValue,
					fromOrgStore,
					defaultToPartnerFieldInput,
					formFields = [];
				var retailNetwork =
					Ext.isObject(userData.org.retailNetwork) && userData.org.retailNetwork
						? userData.org.retailNetwork
						: null;
				var setAllPartner = function (value) {
					toPartnerField.getFieldBlock().setVisible(!value);
					toPartnerField.setDisabled(value);

					toNetworkInput.getFieldBlock().setVisible(!value);
					toNetworkInput.setDisabled(value);
					if (value) {
						toPartnerField.setValue('');
						toNetworkInput.setValue('');
					}
					toPartnerField.allowBlank = value;
					toPartnerField.isValid();

					defaultToPartnerFieldInput.setDisabled(!value);
				};
				var fromOrgData = [
					{
						id: userData.org.id,
						name: userData.org.name,
						isOrg: true
					}
				];
				if (retailNetwork) {
					fromOrgData.push({
						id: retailNetwork.id,
						name: retailNetwork.name,
						isOrg: false
					});
				}
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.column.fromOrg'),
						items: [
							createCombo({
								name: 'fromOrg',
								readOnly: isReloadCatalog,
								value: isReloadCatalog
									? edi.utils.getObjectProperty(moduleData, 'initData.data.fromOrg.id')
									: userData.org.id,
								store: (fromOrgStore = edi.stores.createMemoryStore(
									fromOrgData,
									'CATALOG_FROM_ORG_COMBOBOX'
								)),
								forceSelection: true,
								allowBlank: isReloadCatalog,
								listeners: {
									select: function (combo, record) {
										var recordData;
										if (record && record.data) {
											recordData = record.getData();
										}

										if (recordData) {
											fromOrgValue = recordData;
										}
									}
								}
							})
						]
					})
				);
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('catalogs.form.upload.toNetWork'),
						items: [
							(toNetworkInput = createPanelWithModalSelect({
								isOnlyInput: true,
								fieldName: 'to_network_id',
								valueField: 'id',
								readOnly: isReloadCatalog,
								displayField: 'name',
								value: isReloadCatalog
									? edi.utils.getObjectProperty(moduleData, 'initData.data.toNetwork.id')
									: '',
								modalURL: edi.rest.services.RETAIL_NETWORKS.PARTNERS.GET,
								modalTitle: 'catalogs.form.upload.toNetWork',
								model: 'PARTNER_RETAIL_NETWORKS',
								columns: 'retail_networks',
								isClearValue: true,
								allowBlank: true,
								createArgs: edi.filters.config.generic.createArgs,
								filterItemsMethod: function () {
									const formItemsMap = {
										name: createTextField({
											fieldLabel: edi.i18n.getMessage('column.short.name'),
											name: 'name'
										}),
										iln: createNumberField({
											fieldLabel: edi.i18n.getMessage('company.gln.short'),
											name: 'iln'
										})
									};
									const items = [
										createPanel({
											layout: {
												type: 'grid'
											},
											items: [formItemsMap.name, formItemsMap.iln]
										})
									];
									return {
										formItemsMap,
										items
									};
								},
								afterSelect: function (data) {
									if (toPartnerField) {
										toPartnerField.setDisabled(!!data);
									}
								}
							}))
						]
					})
				);
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('catalogs.form.upload.toPartnerOrg'),
						items: [
							(toPartnerField = createPanelWithModalSelect({
								isOnlyInput: true,
								fieldName: 'to_org_id',
								valueField: 'id',
								displayField: 'name',
								readOnly: isReloadCatalog,
								value: isReloadCatalog
									? edi.utils.getObjectProperty(moduleData, 'initData.data.toOrg.id')
									: '',
								modalURL: edi.rest.services.USER.PARTNERS.GET,
								modalTitle: 'catalogs.form.upload.toOrg',
								model: 'ORGANIZATION',
								columns: 'organization',
								isClearValue: true,
								test1Cls: 'test-action-form-search',
								test2Cls: 'test-action-form-close',
								allowBlank: false,
								createArgs: edi.filters.config.organizations.createArgs,
								filterItemsMethod: edi.filters.config.organizations.createFormItems,
								afterSelect: function (data) {
									if (toNetworkInput) {
										toNetworkInput.setDisabled(!!data);
									}
								}
							}))
						]
					})
				);
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('catalogs.form.upload.toPartnerOrg'),
						hidden: true,
						items: [
							(defaultToPartnerFieldInput = createCombo({
								disabled: true,
								name: 'to_org_id',
								store: (fromOrgStore = edi.stores.createMemoryStore(
									fromOrgData,
									'CATALOG_FROM_ORG_COMBOBOX'
								)),
								forceSelection: true,
								value: userData.org.id
							}))
						]
					})
				);
				formFields.push(
					createCheckbox({
						boxLabel: edi.i18n.getMessage('document.upload.catalog.default.partner'),
						inputValue: true,
						listeners: {
							change: function (cbx, value) {
								setAllPartner(value);
							}
						}
					})
				);
				fromOrgStore.filter('isOrg', true);
				return formFields;
			},
			[edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT]: function (moduleData, isReloadCatalog) {
				isReloadCatalog = isReloadCatalog || false;
				var userData = edi.core.getUserData(),
					formFields = [];
				var retailNetwork =
					Ext.isObject(userData.org.retailNetwork) && userData.org.retailNetwork
						? userData.org.retailNetwork
						: null;
				var toOrgFieldLabel, fromOrgValue;
				var fromOrgData = [
					{
						id: userData.org.id,
						name: userData.org.name,
						isOrg: true
					}
				];
				if (retailNetwork) {
					fromOrgData.push({
						id: retailNetwork.id,
						name: retailNetwork.name,
						isOrg: false
					});
				}
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.column.fromOrg'),
						items: [
							createCombo({
								name: 'fromOrg',
								readOnly: isReloadCatalog,
								value: isReloadCatalog
									? edi.utils.getObjectProperty(moduleData, 'initData.data.fromOrg.id')
									: userData.org.id,
								store: edi.stores.createMemoryStore(fromOrgData, 'CATALOG_FROM_ORG_COMBOBOX'),
								forceSelection: true,
								allowBlank: isReloadCatalog,
								listeners: {
									select: function (combo, record) {
										var recordData;
										if (record && record.data) {
											recordData = record.getData();
										}

										if (recordData) {
											fromOrgValue = recordData;
											toOrgFieldLabel.setText(recordData.name || ' ');
										}
									}
								}
							})
						]
					})
				);
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.column.toOrg'),
						items: [
							(toOrgFieldLabel = createOldLabel({
								text: edi.i18n.getMessage('form.combo.not.selected')
							}))
						]
					})
				);
				return formFields;
			},
			[edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT]: function (moduleData, isReloadCatalog) {
				isReloadCatalog = isReloadCatalog || false;
				var userData = edi.core.getUserData(),
					formFields = [];
				var retailNetwork =
					Ext.isObject(userData.org.retailNetwork) && userData.org.retailNetwork
						? userData.org.retailNetwork
						: null;
				var toOrgFieldLabel, fromOrgValue;
				var fromOrgData = [
					{
						id: userData.org.id,
						name: userData.org.name,
						isOrg: true
					}
				];
				if (retailNetwork) {
					fromOrgData.push({
						id: retailNetwork.id,
						name: retailNetwork.name,
						isOrg: false
					});
				}
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.column.fromOrg'),
						items: [
							createCombo({
								name: 'fromOrg',
								readOnly: isReloadCatalog,
								value: isReloadCatalog
									? edi.utils.getObjectProperty(moduleData, 'initData.data.fromOrg.id')
									: userData.org.id,
								store: edi.stores.createMemoryStore(fromOrgData, 'CATALOG_FROM_ORG_COMBOBOX'),
								forceSelection: true,
								allowBlank: isReloadCatalog,
								listeners: {
									select: function (combo, record) {
										var recordData;
										if (record && record.data) {
											recordData = record.getData();
										}

										if (recordData) {
											fromOrgValue = recordData;
											toOrgFieldLabel.setText(recordData.name);
										}
									}
								}
							})
						]
					})
				);
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.column.toOrg'),
						items: [
							(toOrgFieldLabel = createOldLabel({
								text: edi.i18n.getMessage('form.combo.not.selected')
							}))
						]
					})
				);
				return formFields;
			},
			[edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN]: function (moduleData, isReloadCatalog) {
				isReloadCatalog = isReloadCatalog || false;
				const userData = edi.core.getUserData();
				let formFields = [];
				formFields.push(
					createHiddenField({
						name: 'fromOrg',
						value: isReloadCatalog
							? edi.utils.getObjectProperty(moduleData, 'initData.data.fromOrg.id')
							: userData.org.id
					})
				);
				formFields.push(
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('catalogs.form.upload.toOrg'),
						items: [
							createInputOrgSelector({
								//name: 'partinToOrg',
								itemId: 'partinToOrg',
								allowBlank: false,
								is_valid: false,
								allowReset: true,
								fieldsMapOnly: true,
								selectedRelationByMap: true,
								relationsOnly: true,
								relations: edi.relations.getRelations(),
								ownOrg: true,
								// forceChangeAnyField: true,
								manualChangeDisabled: true,
								//useHiddenFields: true,
								valuesByMap: true,
								fieldsMap: {
									company_name: 'partinToOrg.name',
									company_inn: 'partinToOrg.inn',
									company_kpp: 'partinToOrg.kpp',
									individual_inn: 'partinToOrg.inn',
									individual_lastname: 'partinToOrg.individualOrgAdditionalData.lastName',
									individual_firstname: 'partinToOrg.individualOrgAdditionalData.firstname',
									id: 'partinToOrg.id'
								},
								listeners: {
									afterrender: function (cmp) {
										cmp.isValid();
									}
								}
							})
						]
					})
				);
				return formFields;
			}
		},
		getFormValueByType: {
			[edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT]: function (values, form) {
				var params = {
					fromOrgId: values.fromOrg,
					toOrgId: values.to_org_id,
					//не смогла разобрать какие параметры верные, раньше уходили и те и те, я так же оставила
					to_org_id: values.to_org_id
				};
				let fromOrgField = form.down('[itemId="fromOrg"]');
				let fromOrgStore = fromOrgField ? fromOrgField.getStore() : null;
				let fromOrgRecord = fromOrgStore ? fromOrgStore.findRecord('id', values.fromOrg) : null;
				let fromOrgValue = fromOrgRecord ? fromOrgRecord.getData() : null;
				if (fromOrgValue && 'object' == typeof fromOrgValue) {
					params[fromOrgValue.isOrg ? 'from_org_id' : 'from_network_id'] = fromOrgValue.id;
				}
				return params;
			},
			[edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT]: function (values, form) {
				let params = {
					fromOrgId: values.fromOrg,
					toOrgId: values.fromOrg,
					//не смогла разобрать какие параметры верные, раньше уходили и те и те, я так же оставила
					from_org_id: values.fromOrg,
					to_org_id: values.fromOrg
				};
				let fromOrgField = form.down('[itemId="fromOrg"]');
				let fromOrgStore = fromOrgField ? fromOrgField.getStore() : null;
				let fromOrgRecord = fromOrgStore ? fromOrgStore.findRecord('id', values.fromOrg) : null;
				let fromOrgValue = fromOrgRecord ? fromOrgRecord.getData() : null;
				if (fromOrgValue && 'object' == typeof fromOrgValue) {
					params[fromOrgValue.isOrg ? 'from_org_id' : 'from_network_id'] = fromOrgValue.id;
					params[fromOrgValue.isOrg ? 'to_org_id' : 'to_network_id'] = fromOrgValue.id;
				}
				return params;
			},
			[edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT]: function (values, form) {
				let params = {
					fromOrgId: values.fromOrg,
					toOrgId: values.fromOrg,
					//не смогла разобрать какие параметры верные, раньше уходили и те и те, я так же оставила
					from_org_id: values.fromOrg,
					to_org_id: values.fromOrg
				};
				let fromOrgField = form.down('[itemId="fromOrg"]');
				let fromOrgStore = fromOrgField ? fromOrgField.getStore() : null;
				let fromOrgRecord = fromOrgStore ? fromOrgStore.findRecord('id', values.fromOrg) : null;
				let fromOrgValue = fromOrgRecord ? fromOrgRecord.getData() : null;
				if (fromOrgValue && 'object' == typeof fromOrgValue) {
					params[fromOrgValue.isOrg ? 'from_org_id' : 'from_network_id'] = fromOrgValue.id;
					params[fromOrgValue.isOrg ? 'to_org_id' : 'to_network_id'] = fromOrgValue.id;
				}
				return params;
			},
			[edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN]: function (values, form) {
				const partinToOrg = form.down('[itemId="partinToOrg"]');
				return {
					fromOrgId: values.fromOrg,
					toOrgId: partinToOrg.selectedOrg.id
				};
			}
		},
		/**
		 * Displays modal catalog upload dialog
		 * @param	{Object}	moduleData    currently active module data
		 * @param	{Boolean}	[isReloadCatalog]
		 * @param	{Object}	props
		 */
		showUploadCatalogDialog: function (moduleData, isReloadCatalog, props = {}) {
			const { catalogType, modalConf = {}, saveBtnConf = {} } = props;
			isReloadCatalog = isReloadCatalog || false;
			let formFieldsByType;
			moduleData && moduleData.tab
				? moduleData.tab.setLoading(edi.i18n.getMessage('documents.uploading.catalog'))
				: null;
			const success = function () {
				if (edi.realtime?.messages) {
					if (!edi.realtime.messages.isConnected()) {
						edi.realtime.messages.showConnectionError('document.upload.websocket.error');
					}
				} else {
					edi.events.catalog.fireEvent('change');
				}
			};
			const failure = edi.document.actions.defaultFailureHandler(
				moduleData.tab,
				'documents.upload.catalog.error'
			);
			const callback = function () {
				moduleData && moduleData.tab ? moduleData.tab.setLoading(false) : null;
			};

			const fieldAttach = createFieldBlock({
				title: edi.i18n.getMessage('file.attachment'),
				items: [
					createFileDropper({
						name: 'attachment',
						allowBlank: false,
						getAllowedFileTypesText: function () {
							return edi.i18n.getMessage('fileDropper.allow.file.types.catalog', {
								limit: 10
							});
						}
					})
				]
			});

			const catalogTypes = edi.constants.CATALOG_TYPES_UPLOAD;
			let types = [];
			for (let i = 0; i < catalogTypes.length; i++) {
				types.push({
					id: catalogTypes[i],
					name: edi.i18n.getMessage('documents.doctype.' + catalogTypes[i])
				});
			}
			const typeValue = isReloadCatalog ? edi.utils.getObjectProperty(moduleData, 'initData.data.type') : null;
			const type = catalogType
				? createHiddenField({
						name: 'type',
						value: catalogType
				  })
				: createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('document.upload.catalog.type'),
						items: [
							createCombo({
								name: 'type',
								store: edi.stores.createInlineStore(types),
								queryMode: 'local',
								displayField: 'name',
								valueField: 'id',
								readOnly: isReloadCatalog,
								value: typeValue || '',
								forceSelection: true,
								anyMatch: true,
								allowBlank: isReloadCatalog,
								emptyText: edi.i18n.getMessage('form.combo.not.selected'),
								listeners: {
									select: function (comp) {
										//Нужно чтобы formFieldsByType успел отрендерится, иначе removeAll не сработает
										setTimeout(() => {
											var value = comp.getValue();
											if (!value) {
												comp.setValue(null);
											} else {
												if (
													typeof edi.methods.catalog.createUploadFieldsByType[value] ===
													'function'
												) {
													formFieldsByType.removeAll();
													formFieldsByType.add(
														edi.methods.catalog.createUploadFieldsByType[value](
															moduleData,
															isReloadCatalog
														)
													);
													modal.center();
													formPanel.isValid();
												}
											}
										}, 1);
									}
								}
							})
						]
				  });

			const formFields = [type, fieldAttach];
			formFields.push(
				(formFieldsByType = createContainer({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: []
				}))
			);

			if (typeof edi.methods.catalog.createUploadFieldsByType[catalogType ?? typeValue] === 'function') {
				formFieldsByType.removeAll();
				formFieldsByType.add(edi.methods.catalog.createUploadFieldsByType[catalogType ?? typeValue]());
			}

			const formPanel = createModalForm({
				layout: {
					type: 'grid',
					gap: [24, 16]
				},
				submitEmptyText: false,
				items: formFields
			});

			const defaultModalConf = {
				title: edi.i18n.getMessage('document.upload.catalog.title'),
				width: MODAL_SIZE.widthSmall,
				items: [formPanel],
				listeners: {
					close: function () {
						'function' == typeof callback ? callback() : null;
					}
				},
				buttonsBefore: [
					createButton(
						Object.assign(
							{
								cls: BUTTON_CLS.primary,
								text: edi.i18n.getMessage('form.btn.save'),
								bindToForm: formPanel,
								handler: function () {
									const form = formPanel.getForm();
									if (form.isValid()) {
										let values = edi.utils.collectFormValues(form);
										const catType = values.type;
										const params = {
											docType: catType
										};
										if (isReloadCatalog) {
											params['objectId'] = edi.utils.getObjectProperty(
												moduleData,
												'initData.data.id'
											);
										}
										if (typeof edi.methods.catalog.getFormValueByType[catType] === 'function') {
											Ext.merge(
												params,
												edi.methods.catalog.getFormValueByType[catType](values, formPanel)
											);
										}

										const submitForm = function (respData) {
											edi.core.submitUploadForm(
												form,
												edi.utils.formatString(
													edi.rest.services.CATALOGS.V2.UPLOAD_FROM_WEB.POST,
													{
														type: edi.constants.CATALOG_TYPES[catType]
													}
												),
												'documents.uploading.catalog',
												function () {
													'function' == typeof success ? success() : null;
													modal.close();
												},
												function (responseData) {
													edi.core.logMessage('Error uploading ' + catType, 'warn');
													'function' == typeof failure ? failure(responseData) : null;
													modal.close();
												},
												{
													params: params
												}
											);
										};

										const urlParams = Ext.clone(params);
										urlParams.type = catType;
										if (catType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT) {
											values = edi.utils.collectFormValues(form);
											if (values.to_org_id) {
												urlParams.to_org_id = values.to_org_id;
											} else if (values.to_network_id) {
												urlParams.to_network_id = values.to_network_id;
											}
										}
										if (
											edi.constants.CATALOG_TYPES_UPLOAD_WITHOUT_CONFIRMATION.some(
												(it) => it === catType
											)
										) {
											submitForm();
										} else {
											edi.rest.sendRequest(
												edi.utils.compileURL(
													edi.rest.services.CATALOGS.V2.FILTER.GET,
													urlParams
												),
												'GET',
												null,
												function (responseData) {
													if (responseData.success) {
														if (!responseData.total) {
															submitForm(responseData);
														} else {
															if (!isReloadCatalog) {
																edi.core.confirm(
																	undefined,
																	edi.i18n.getMessage(
																		'catalogs.form.upload.confirm',
																		[
																			edi.i18n.getMessage(
																				'documents.doctype.' + catType
																			)
																		]
																	),
																	function () {
																		submitForm(responseData);
																	}
																);
															} else {
																submitForm(responseData);
															}
														}
													} else {
														submitForm();
													}
												}
											);
										}
									} else {
										edi.core.showError('document.upload.catalog.error.select.file');
									}
								}
							},
							saveBtnConf
						)
					)
				]
			};
			const modal = createModalPanel(Object.assign(defaultModalConf, modalConf));
			formPanel.isValid();
			modal.show();
		}
	},
	product: {
		grid: {
			roundValues: function (values, valuesProps, costDecimals) {
				costDecimals = costDecimals ? costDecimals : 3;
				if (Array.isArray(valuesProps) && valuesProps.length) {
					for (var i = 0; i < valuesProps.length; i++) {
						if ('string' == typeof valuesProps[i]) {
							values[valuesProps[i]] = Ext.isNumeric(values[valuesProps[i]])
								? edi.utils.roundTo(values[valuesProps[i]], costDecimals)
								: '';
						} else if ('object' == typeof valuesProps[i]) {
							values[valuesProps[i].name] = Ext.isNumeric(values[valuesProps[i].name])
								? edi.utils.roundTo(
										values[valuesProps[i].name],
										edi.utils.getAttributeByName(
											valuesProps[i]?.org?.attributes,
											'costDecimals',
											undefined,
											true
										)
								  )
								: '';
						}
					}
				}
				return values;
			},
			gatherValuesRow: function (values, docType) {
				var rate = edi.utils.getObjectProperty(values, 'Tax.Rate') || values.TaxRate;
				rate = edi.methods.calculateTaxRate(rate === edi.constants.taxRates.taxNoVat ? 0 : rate);
				let result = {
					rate: rate,
					rateVal: rate / 100
				};

				if (!docType) {
					// блок старой логики, оставлен для обратной совместимости
					var getValuesFromArrayKeys = function (names) {
						for (var i = 0; i < names.length; i++) {
							var value = parseFloat(values[names[i]]);
							if (!!value || value === 0) {
								return value;
							}
						}
						return 0;
					};
					Ext.merge(result, {
						excise: parseFloat(values.Excise) || parseFloat(values.ExciseCost) || 0,
						amount: getValuesFromArrayKeys([
							'Count',
							'QuantityDespatched',
							'AllocatedDelivered',
							'OrderedQuantity',
							'InvoiceQuantity',
							'QuantityReceived'
						]),
						price: getValuesFromArrayKeys([
							'Cost',
							'OrderedUnitNetPrice',
							'InvoiceUnitNetPrice',
							'UnitNetPrice'
						]),
						grossPrice: getValuesFromArrayKeys([
							'UnitGrossPrice',
							'OrderedUnitGrossPrice',
							'InvoiceUnitGrossPrice'
						])
					});
				} else {
					let amount, netPrice, grossPrice, excise;
					switch (docType) {
						case edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT:
						case edi.constants.DOCUMENT_TYPES.LEGACY_ORDER:
						case edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP:
							amount = parseFloat(values.OrderedQuantity);
							netPrice = parseFloat(values.OrderedUnitNetPrice);
							grossPrice = parseFloat(values.OrderedUnitGrossPrice);
							excise = parseFloat(values.Excise);
							break;
						case edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE:
							amount = parseFloat(values.InvoiceQuantity);
							netPrice = parseFloat(values.InvoiceUnitNetPrice);
							grossPrice = parseFloat(values.InvoiceUnitGrossPrice);
							excise = parseFloat(values.ExciseCost);
							break;
						case edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV:
						case edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES:
							amount = parseFloat(values.QuantityDespatched);
							netPrice = parseFloat(values.UnitNetPrice);
							grossPrice = parseFloat(values.UnitGrossPrice);
							excise = parseFloat(values.Excise);
							break;
						case edi.constants.DOCUMENT_TYPES.LEGACY_RETANN:
							amount = parseFloat(values.ReturnedQuantity);
							netPrice = parseFloat(values.UnitNetPrice);
							grossPrice = parseFloat(values.UnitGrossPrice);
							excise = parseFloat(values.Excise);
							break;
						case edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD:
							amount = parseFloat(values.countAfter);
							netPrice = parseFloat(values.costAfter);
							grossPrice = parseFloat(values.taxAfter);
							excise = parseFloat(values.ExciseCost);
							break;
						case edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV:
							amount = parseFloat(values.QuantityReceived);
							netPrice = parseFloat(values.UnitNetPrice);
							grossPrice = parseFloat(values.UnitGrossPrice);
							excise = parseFloat(values.Excise);
							break;
						default:
							console.log('!! Unknown document type ' + docType + 'in gatherValuesRow');
							amount = parseFloat(values.Count);
							netPrice = parseFloat(values.UnitNetPrice);
							grossPrice = parseFloat(values.UnitGrossPrice);
							excise = parseFloat(values.Excise);
							break;
					}
					Ext.merge(result, {
						amount: amount,
						price: netPrice,
						grossPrice: grossPrice,
						excise: excise
					});
				}

				return result;
			},
			calculateOrder: function (values) {
				var total = 0,
					tax = 0,
					unitGross = 0;

				if (values.amount && values.price) {
					total = (values.amount || 0) * (values.price || 0);
					if (values.rate) {
						tax = (total * values.rate) / 100;
					}
				}
				if (values.price) {
					unitGross = values.price + 0;
					if (values.rate) {
						unitGross += (values.price * values.rate) / 100;
					}
				}

				return {
					total: total,
					tax: tax,
					unitGross: unitGross
				};
			},
			setValuesDeljit: function (values, costDecimals) {
				var data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT);
				var calculated = edi.methods.product.grid.calculateOrder(data);
				costDecimals = costDecimals ? costDecimals : edi.constants.DEFAULT.SUMM_DISPLAY_PRECISION_NUMBER;

				values.OrderedUnitGrossPrice = edi.utils.roundTo(calculated.unitGross, costDecimals);
				values.OrderedNetAmount = edi.utils.roundTo(calculated.total, costDecimals);
				values.OrderedGrossAmount = edi.utils.roundTo(calculated.total + calculated.tax, costDecimals);
				values.OrderedTaxAmount = edi.utils.roundTo(calculated.tax, costDecimals);

				return values;
			},
			setFieldsOrder: function (values, fields, costDecimals) {
				var data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_ORDER);
				var calculated = edi.methods.product.grid.calculateOrder(data);
				costDecimals = costDecimals ? costDecimals : edi.constants.DEFAULT.SUMM_DISPLAY_PRECISION_NUMBER;

				fields.OrderedNetAmount
					? fields.OrderedNetAmount.setValue(edi.utils.roundTo(calculated.total, costDecimals))
					: null;

				return fields;
			},
			calculateInvoice: function (values) {
				var total = 0;
				var tax = 0,
					unitGross = 0;

				if (values.amount && values.price) {
					total = values.amount * values.price;
					if (values.rate) {
						tax = (total * values.rate) / 100;
					}
				}
				if (values.price) {
					unitGross = values.price + 0;
					if (values.rate) {
						unitGross += (values.price * values.rate) / 100;
					}
				}

				return {
					total: total,
					tax: tax,
					unitGross: unitGross
				};
			},
			setValuesInvoice: function (values, calcData, costDecimals) {
				var data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES);
				if (calcData && 'object' == typeof calcData) {
					Ext.merge(data, calcData);
				}
				var calculated = edi.methods.product.grid.calculateInvoice(data);
				costDecimals = costDecimals ? costDecimals : edi.constants.DEFAULT.SUMM_DISPLAY_PRECISION_NUMBER;

				values.NetAmount = edi.utils.roundTo(calculated.total, costDecimals);
				values.GrossAmount = edi.utils.roundTo(calculated.total + calculated.tax, costDecimals);
				values.TaxAmount = edi.utils.roundTo(calculated.tax, costDecimals);
				values.UnitGrossPrice = edi.utils.roundTo(calculated.unitGross, costDecimals);

				return values;
			},
			setFieldsInvoice: function (values, fields, costDecimals) {
				var data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT);
				var calculated = edi.methods.product.grid.calculateInvoice(data);
				costDecimals = costDecimals ? costDecimals : edi.constants.DEFAULT.SUMM_DISPLAY_PRECISION_NUMBER;

				fields.NetAmount ? fields.NetAmount.setValue(edi.utils.roundTo(calculated.total, costDecimals)) : null;
				fields.TaxAmount ? fields.TaxAmount.setValue(edi.utils.roundTo(calculated.tax, costDecimals)) : null;

				return fields;
			}
		},
		price: {
			/**
			 * Calculates prices according to taxCalculationMethod
			 * @param    {Object}     data                    contains all data needed for calculations(taxRate, amount, netPrice, grossPrice, netSum, taxSum, grossSum)
			 * @param    {String}     taxCalculationMethod    calculation method
			 * @param    {Boolean}    keepBaseData            true to not recalculate values(used when created from another document)
			 * @param    {Boolean}    isAmountZero            true to amount can be zero
			 */
			recalculation: function (data, taxCalculationMethod, keepBaseData, isAmountZero, costDecimals) {
				costDecimals = costDecimals ? costDecimals : 3;

				var netPriceBased = function (keepData) {
						data.grossPrice =
							keepBaseData && Ext.isNumeric(data.grossPrice)
								? data.grossPrice
								: edi.utils.roundTo(data.netPrice * (1 + data.taxRate), costDecimals);
						data.netSum =
							keepData && keepBaseData && '' !== data.netSum
								? data.netSum
								: edi.utils.roundTo(data.netPrice * data.amount, costDecimals);
						data.taxSum =
							keepData && keepBaseData && '' !== data.taxSum
								? data.taxSum
								: edi.utils.roundTo(data.netPrice * data.amount * data.taxRate, costDecimals);
						data.grossSum =
							keepData && keepBaseData && '' !== data.grossSum
								? data.grossSum
								: edi.utils.roundTo(data.netSum + data.taxSum, costDecimals);
					},
					grossPriceBased = function (grossPrice) {
						data.grossPrice = Ext.isNumeric(grossPrice)
							? grossPrice
							: edi.utils.roundTo(data.netPrice * (1 + data.taxRate), costDecimals);
						data.grossSum = edi.utils.roundTo(data.grossPrice * data.amount, costDecimals);
						data.taxSum = edi.utils.roundTo(
							(data.grossSum / (1 + data.taxRate)) * data.taxRate,
							costDecimals
						);
						data.netSum = edi.utils.roundTo(data.grossSum - data.taxSum, costDecimals);
						data.netPrice =
							keepBaseData && data.netPrice
								? data.netPrice
								: '' !== grossPrice
								? edi.utils.roundTo(data.netSum / data.amount, costDecimals)
								: data.netPrice;
					};
				if (data && (data.amount || isAmountZero) && '' !== data.taxRate) {
					if (
						!taxCalculationMethod ||
						(taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE &&
							Ext.isNumeric(data.netPrice))
					) {
						netPriceBased();
					} else if (
						taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT &&
						Ext.isNumeric(data.netPrice)
					) {
						netPriceBased(true);
					} else if (
						taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE &&
						(Ext.isNumeric(data.grossPrice) || Ext.isNumeric(data.netPrice))
					) {
						grossPriceBased(data.grossPrice);
					}
				}
				return data;
			}
		}
	},
	grid: {
		/**
		 * Display totals for selected rows
		 * @param    {Object}    grid             grid where we are working
		 * @param    {Number}    totalSumm        number of total summ
		 * @param    {Number}    selectedRows     number of selected rows in grid
		 * @param    {String}    labelText        text to display on label
		 */
		displaySelectedTotals: function (grid, totalSumm, selectedRows, labelText) {
			if (grid && grid.totalsLabel) {
				if (selectedRows >= 0) {
					grid.totalsLabel.setText(
						edi.utils.formatString(edi.i18n.getMessage(labelText), {
							totalSumm: edi.renderers.displayCurrencyDecimals(totalSumm),
							selectedRows: edi.renderers.currencyWithSeparators(selectedRows)
						})
					);
				}
			}
		}
	},
	organization: {
		createFullAddressString: function (org) {
			var address = edi.utils.getAddressFromOrganization(org, edi.constants.ADDRESS_TYPES.LEGAL) || {},
				values = [];

			if (address.hasOwnProperty('countryCode')) {
				var country = edi.utils.getCountryFULLByISO(address.countryCode);
				if (country && country.name) {
					values.push(country.name);
				}
			}

			['zip', 'area', 'city'].forEach(function (fieldName) {
				address.hasOwnProperty(fieldName) ? values.push(address[fieldName]) : null;
			});

			//Concat localityType & locality to one string
			if (address.hasOwnProperty('locality') && address.hasOwnProperty('localityType')) {
				values.push(address['localityType'] + ' ' + address['locality']);
			} else {
				address.hasOwnProperty('localityType') ? values.push(address['localityType']) : null;
				address.hasOwnProperty('locality') ? values.push(address['locality']) : null;
			}

			//Concat streetType & street to one string
			if (address.hasOwnProperty('street') && address.hasOwnProperty('streetType')) {
				values.push(address['streetType'] + ' ' + address['street']);
			} else {
				address.hasOwnProperty('streetType') ? values.push(address['streetType']) : null;
				address.hasOwnProperty('street') ? values.push(address['street']) : null;
			}

			['building', 'addInf', 'flat'].forEach(function (fieldName) {
				address.hasOwnProperty(fieldName) ? values.push(address[fieldName]) : null;
			});

			return values.join(', ');
		}
	},
	loadReportTemplates: function (docType, success, failure, callback) {
		edi.document.actions.loadDocumentTemplatesData(
			docType,
			function (data) {
				var items = [],
					templates = [];
				if (data && data.data) {
					items = data.data.result;
				} else if (data && data.items) {
					items = data.items;
				}

				for (var i = 0; i < items.length; i++) {
					templates.push({
						name: items[i].displayName,
						id: items[i].id
					});
				}

				if ('function' == typeof success) {
					success(templates);
				}
			},
			failure,
			callback
		);
	},
	createDocumentListReport: function (reportFormat, gridName, formValues, moduleData, successMethod) {
		var template;
		const docDateFromAttrName = 'docTimeFrom';
		const docDateToAttrName = 'docTimeTo';
		var getReport = function () {
			var failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'deferred.report.create.error'));
			};

			var success = successMethod
				? successMethod
				: function (data) {
						if (data && data.data) {
							edi.events.deferredReport.fireEvent('create');
							edi.core.confirm('deferred.report.request', 'deferred.report.requested', function () {
								edi.core.openModule('deferred.reports');
							});
						} else {
							failure(data);
						}
				  };

			formValues = edi.filters.config.documents.createArgs(formValues);
			//Modify date
			if (formValues.dateFrom) {
				formValues.modifyDateFrom = +formValues.dateFrom;
				delete formValues.dateFrom;
			}
			if (formValues.dateTo) {
				formValues.modifyDateTo = +formValues.dateTo;
				delete formValues.dateTo;
			}
			//Document date
			if (formValues.fromDocTime) {
				formValues[docDateFromAttrName] = +formValues.fromDocTime;
				delete formValues.fromDocTime;
			}
			if (formValues.toDocTime) {
				formValues[docDateToAttrName] = +formValues.toDocTime;
				delete formValues.toDocTime;
			}
			//Creation date
			if (formValues.creationDateFrom) {
				formValues.creationDateFrom = +formValues.creationDateFrom;
			}
			if (formValues.creationDateTo) {
				formValues.creationDateTo = +formValues.creationDateTo;
			}

			const isFilledModifyDate = !!(formValues.modifyDateFrom && formValues.modifyDateTo),
				isFilledCreationDate = !!(formValues.creationDateFrom && formValues.creationDateTo),
				isFilledDocumentDate = !!(formValues[docDateFromAttrName] && formValues[docDateToAttrName]);

			if (isFilledModifyDate || isFilledCreationDate || isFilledDocumentDate) {
				const addAttributes = [
						docDateFromAttrName,
						docDateToAttrName,
						'modifyDateFrom',
						'modifyDateTo',
						'creationDateFrom',
						'creationDateTo',
						'number',
						'includeNumbers',
						'includeDocTypes',
						'businessProcess',
						'partner',
						'deliveryGLN',
						'orderNumber',
						'desadvNumber',
						'contractNumber',
						'deliveryGLNLikeRight',
						'orderNumberLikeRight',
						'desadvNumberLikeRight',
						'contractNumberLikeRight',
						'states'
					],
					attributesResult = {},
					attributes = {
						reportFormat: reportFormat,
						templateId: template.toString(),
						gridName: gridName
					};

				for (var i = 0; i < addAttributes.length; i++) {
					if (formValues[addAttributes[i]]) {
						attributes[addAttributes[i]] = formValues[addAttributes[i]];
					}
				}

				var arrayAttrType = ['includeStates', 'includeDocTypes'];
				arrayAttrType.forEach(function (attrName) {
					if (Array.isArray(formValues[attrName]) && formValues[attrName].length) {
						attributes[attrName] = Ext.encode(formValues[attrName]);
					}
				});

				for (i in attributes) {
					if (attributes.hasOwnProperty(i)) {
						attributesResult[i] = {
							name: i,
							value: attributes[i]
						};
					}
				}

				edi.rest.sendRequest(
					edi.rest.services.REPORTS.POST,
					'POST',
					Ext.encode({
						orgId: edi.core.getUserOrgID(),
						requestType: 'DOCUMENT_REPORT_LIST',
						attributes: attributesResult
					}),
					success,
					failure
				);
			} else {
				edi.core.showError('deferred.report.select.dates');
			}
		};

		moduleData && moduleData.tab ? moduleData.tab.setLoading() : null;
		edi.methods.loadReportTemplates(
			'DOCUMENT_REPORT_LIST',
			function (templates) {
				if (templates.length === 1) {
					template = templates[0].id;
					getReport();
				} else if (templates.length > 1) {
					var modalTemplates = createModalPanel({
						title: edi.i18n.getMessage('users.group.add.title'),
						items: createModalForm({
							submitEmptyText: false,
							items: [
								createFieldBlock({
									cls: FIELD_BLOCK_CLS.small,
									title: edi.i18n.getMessage('deferred.report.template.type'),
									items: [
										createCombo({
											store: edi.stores.createMemoryStore(templates, 'SIMPLE'),
											displayField: 'name',
											valueField: 'id',
											forceSelection: true,
											allowBlank: false,
											name: 'template',
											emptyText: edi.i18n.getMessage('form.combo.not.selected'),
											listeners: {
												change: function (comp) {
													var value = comp.getValue();
													template = value && comp.valueModels ? value : null;
													getReport();
													modalTemplates.close();
												}
											}
										})
									]
								})
							]
						})
					});
					modalTemplates.show();
				} else {
					edi.core.showError('deferred.report.no.template.loaded.error');
				}
			},
			function (error) {
				edi.core.showError(edi.utils.formatComplexServerError(error, 'deferred.report.template.load.error'));
			},
			function () {
				moduleData && moduleData.tab ? moduleData.tab.setLoading(false) : null;
			}
		);
	},
	loadSettingsAndContinue: function (continueCallback, onFailure = null, code) {
		let maxFileSize;

		const failure =
			typeof onFailure === 'function'
				? onFailure
				: function (data) {
						edi.core.logMessage(
							'Error get limit size of file for upload ' +
								(data && data.status ? ' status - ' + data.status : ''),
							'warn'
						);
						edi.core.showError(
							edi.utils.formatComplexServerError(
								data,
								data && data.status ? 'error.server.' + data.status : 'dsf.get.limit.file.size.failure'
							),
							function () {
								maxFileSize = 1;
								continueCallback(maxFileSize);
							}
						);
				  };
		const success = function (data) {
			maxFileSize = parseInt(data.data);
			if (!isNaN(maxFileSize)) {
				continueCallback(maxFileSize);
			} else {
				failure();
			}
		};
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.SERVER.SETTING.GET, {
				configuration_code: code ?? 'LIMIT_SIZE_DSF'
			}),
			'GET',
			{},
			success,
			failure
		);
	},
	showUploadDocumentDialog: function () {
		var userData = edi.core.getUserData(),
			form,
			modal,
			documentFileBlock,
			fileDropper,
			saveBtn,
			partnerSelector,
			docType = null,
			docTypeInput,
			maxFileSize,
			partners = edi.relations.getRelations(),
			hasOnePartner = partners.length === 1,
			partner = hasOnePartner ? partners[0] : null,
			partnerId = hasOnePartner ? partners[0].id : null,
			preSelectedPartner = hasOnePartner ? partners[0] : null,
			preSelectedValues = hasOnePartner ? edi.converters.convertOrgToPartie(preSelectedPartner) : null;

		var checkValid = function () {
			const selectorValid = partnerSelector?.isValid();
			const isValid = form?.isValid() && selectorValid;
			saveBtn?.setDisabled(!isValid);
		};

		const isCurrentUserOrgWorkWithUPD_502_N = edi.utils.getAttributeByName(
			userData.org.attributes,
			'workingWithUPD_502-N',
			undefined,
			true
		);

		//Enables "load file" button & save button
		var allowAttachmentUpload = function (allow) {
			if (saveBtn && !saveBtn.isDestroyed) {
				saveBtn.setDisabled(!allow);
			}
		};

		let versionInput, docFlowTypeInput;

		const continueRendering = function (maxFileSizeValue) {
			maxFileSize = maxFileSizeValue;

			documentFileBlock = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.file'),
				items: [
					createForm({
						submitEmptyText: false,
						bodyPadding: 0,
						items: [
							(fileDropper = createFileDropper({
								name: 'attachment',
								itemId: 'fileDropper',
								allowBlank: false,
								maxFileSize,
								checkMaxFileSize: true,
								getAllowedFileTypesText: function () {
									return edi.i18n.getMessage('fileDropper.allow.file.types.dsf', {
										limit: maxFileSize
									});
								},
								markInvalid: function (invalid) {
									const __self = this;
									if (invalid) {
										__self._fileDropPanel.addCls(__self.invalidClass);
									} else {
										__self._fileDropPanel.removeCls(__self.invalidClass);
									}
									checkValid();
								}
							}))
						]
					})
				]
			});

			form = createModalForm({
				submitEmptyText: false,
				items: [
					createHiddenField({
						name: 'byUserId',
						value: userData.id
					}),
					createHiddenField({
						name: 'byOrgId',
						value: userData.org.id
					}),
					createHiddenField({
						name: 'org_id_receiver'
					}),
					createHiddenField({
						name: 'fileName'
					}),
					(versionInput = createHiddenField({
						name: 'version',
						hidden: true
					})),
					(docTypeInput = createHiddenField({
						name: 'docType'
					})),
					(docFlowTypeInput = createHiddenField({
						name: 'docFlowType',
						hidden: true
					})),
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('web.upload.document.docType'),
						items: [
							createCombo({
								store: createSimpleStore({
									data: edi.constants.WEB_UPLOAD_DOCTYPES.map((type) => {
										if (Ext.isObject(type)) {
											const { docType, version } = type;
											const name = version
												? edi.i18n.getMessage(`documents.doctype.${docType}_1.0.19.0-RC40`)
												: edi.i18n.getMessage(`documents.doctype.${docType}}`);
											return {
												id: Ext.id(),
												docType,
												name,
												version: version
											};
										}
										return {
											id: Ext.id(),
											docType: type,
											name: edi.i18n.getMessage('documents.doctype.' + type)
										};
									}),
									model: edi.models.getModel('WEB_UPLOAD_DOCTYPES')
								}),
								allowBlank: false,
								mandatory: true,
								submitValue: false,
								validator: function (val) {
									if (partner && docType) {
										const isPartnerOrgWorkWithUPD_502_N = edi.utils.getAttributeByName(
											partner.attributes,
											'workingWithUPD_502-N',
											undefined,
											true
										);
										const version = versionInput.getValue();
										const isUPD_502N =
											docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1 &&
											version === '5.02-N';

										if (isUPD_502N && !isPartnerOrgWorkWithUPD_502_N) {
											return edi.i18n.getMessage('web.upload.document.upd_5_02n.error');
										}
									}
									return true;
								},
								listeners: {
									select: function (combo, record) {
										const version = record.get('version');
										docType = record.get('docType');
										versionInput.setValue(version ?? null);
										docTypeInput.setValue(docType);

										if (docType === edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM) {
											let userData = edi.core.getUserData();
											let useEancom2 = edi.utils.getAttributeByName(
												userData.org.attributes,
												'ENABLE_PRICAT_EANCOM_V2'
											);
											let verNum = useEancom2 === 'true' ? '2' : '1';
											versionInput.setValue(verNum);
										}

										versionInput.setDisabled(!versionInput.getValue());

										if (docType === edi.constants.DOCUMENT_TYPES.COMMON_PRICAT) {
											docFlowTypeInput.setValue(edi.constants.DOCUMENT_TYPES.PRICAT_WITH_SIGN);
										}
										docFlowTypeInput.setDisabled(
											docType !== edi.constants.DOCUMENT_TYPES.COMMON_PRICAT
										);

										checkValid();
									}
								}
							})
						]
					}),
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('web.upload.documents.receiver'),
						items: [
							(partnerSelector = createCompanySelector({
								cls: '',
								useHiddenFields: false,
								is_valid: !!preSelectedPartner,
								selectedOrg: preSelectedPartner,
								selectedOrgValues: preSelectedValues,
								fieldValues: preSelectedValues,
								relations: hasOnePartner ? null : partners,
								callback: function (values, org) {
									partner = org;
									partnerId = org ? org.id : null;
									checkValid();
								},
								manualChangeDisabled: true,
								allowEditButton: false,
								relationsOnly: true
							}))
						]
					}),
					documentFileBlock
				]
			});

			modal = createModalPanel({
				title: edi.i18n.getMessage('web.upload.document.modal.title'),
				width: MODAL_SIZE.widthMedium,
				items: [form],
				buttonsBefore: [
					(saveBtn = createSaveButton(
						function () {
							var onAttachmentUploadFailure = function (data) {
								modal.setLoading(false);
								edi.core.logMessage(
									'Error uploading document attachment file' +
										(data && data.status ? ' status - ' + data.status : ''),
									'error'
								);
								edi.core.showError(
									edi.utils.formatComplexServerError(
										data,
										'document.file.upload.error.load.attachment'
									),
									function () {
										allowAttachmentUpload(true);
									}
								);
							};

							var formFields = edi.utils.getFormFields(form),
								attachment = formFields.attachment.getValue();
							formFields.fileName.setValue(
								edi.utils.base64.encode(attachment.realName || attachment.docName)
							);
							formFields.org_id_receiver.setValue(partnerId);

							edi.core.submitUploadForm(
								form,
								edi.rest.services.WEB_UPLOAD_DOCUMENT.UPLOAD,
								'document.file.upload.attachment.in.progress',
								function (responseData) {
									if (responseData && responseData.data && responseData.data.id) {
										modal.close();
										if (!edi.realtime.messages.isConnected()) {
											edi.realtime.messages.showConnectionError(
												'document.upload.websocket.error'
											);
											return;
										}

										let processResultLabel;
										let formResult;
										const docId = responseData.data.id;
										const modalResult = createModalPanel(
											{
												title: edi.i18n.getMessage('web.upload.document.processing.result'),
												width: MODAL_SIZE.widthMedium,
												items: [
													(formResult = createModalForm({
														padding: '0 0 24 0',
														items: [
															createFieldBlock({
																cls: FIELD_BLOCK_CLS.small,
																title: edi.i18n.getMessage('column.result'),
																items: [
																	(processResultLabel = createOldLabel({
																		text: edi.i18n.getMessage(
																			'file.processed.user.DRAFT'
																		)
																	}))
																]
															})
														]
													}))
												],
												listeners: {
													close: () => {
														edi.events.uploadDocument.un('change', modalResultHandler);
													}
												}
											},
											true
										);
										modalResult.show();
										const modalResultHandler = function (data) {
											const { type, number, fromOrg, toOrg, Error } = data?.data?.content;
											const fileStorageId = data?.data?.meta?.mainObjId;
											//Проверяем что открыто окно того документа который был загружен
											if (fileStorageId !== docId) {
												return;
											}
											processResultLabel?.setText(
												(function () {
													const status = data?.data?.meta?.messageType;
													if (status === 'EDI_WEB_UPLOAD_COMPLETED') {
														return edi.i18n.getMessage('file.processed.PROCESSED');
													} else if (status === 'EDI_WEB_UPLOAD_PROCESSED_WITH_ERROR') {
														return edi.i18n.getMessage(
															'file.processed.PROCESSED_WITH_ERROR'
														);
													}
												})()
											);

											formResult?.add([
												createFieldBlock({
													cls: FIELD_BLOCK_CLS.small,
													title: edi.i18n.getMessage('column.error.message'),
													hidden: !Error,
													items: [
														createOldLabel({
															isTextarea: true,
															maxHeight: 150,
															overflowY: 'auto',
															text: Error,
															valueLabel: true
														})
													]
												}),
												createFieldBlock({
													cls: FIELD_BLOCK_CLS.small,
													title: edi.i18n.getMessage('document.form.number'),
													hidden: !number,
													items: [
														createOldLabel({
															text: number
														})
													]
												}),
												createFieldBlock({
													cls: FIELD_BLOCK_CLS.small,
													title: edi.i18n.getMessage('web.upload.document.docType'),
													hidden: !type,
													items: [
														createOldLabel({
															text: edi.i18n.getMessage(`documents.doctype.${type}`)
														})
													]
												}),
												createFieldBlock({
													cls: FIELD_BLOCK_CLS.small,
													title: edi.i18n.getMessage('document.result.sender'),
													hidden: !fromOrg,
													items: [
														createOrgSelector({
															readOnly: true,
															notReturnedVoid: true,
															is_valid: true,
															fieldValues: edi.converters.convertOrgToPartie(fromOrg)
														})
													]
												}),
												createFieldBlock({
													cls: FIELD_BLOCK_CLS.small,
													title: edi.i18n.getMessage('document.result.receiver'),
													hidden: !toOrg,
													items: [
														createOrgSelector({
															readOnly: true,
															notReturnedVoid: true,
															is_valid: true,
															fieldValues: edi.converters.convertOrgToPartie(toOrg)
														})
													]
												})
											]);
										};
										if (!edi.events.uploadDocument.hasListener('change')) {
											edi.events.uploadDocument.on('change', modalResultHandler);
										}
									} else {
										onAttachmentUploadFailure(responseData);
									}
								},
								onAttachmentUploadFailure
							);
						},
						{ cls: BUTTON_CLS.primary }
					))
				]
			});

			modal.show();
			checkValid();
		};

		edi.methods.loadSettingsAndContinue(continueRendering, null, 'LIMIT_SIZE');
	},
	/**
	 * Opens group print modal
	 * @param	{ExtComponent}	grid
	 * @param	{ModuleData}	moduleData
	 * @param	{DocumentHeader[]}	[records]
	 **/
	documentsGroupPrint: function (grid, moduleData, records) {
		let modalGroupReports = function () {
			var columns = edi.columns.get('documents_group_reports');
			columns.push(
				createActionsColumnConfig({
					align: 'center',
					items: [
						{
							glyph: edi.constants.ICONS.DETAILS,
							testCls: 'test-action-column-details',
							tooltip: edi.i18n.getMessage('form.btn.details'),
							handler: function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex).get('requestList');
								var gridColumns = edi.columns.get('documents_in_print_report');
								var modalPrintDocuments = createModalPanel({
									title: edi.i18n.getMessage('document.group.print.documents.in.report'),
									width: MODAL_SIZE.widthLarge,
									items: [
										createModalForm({
											items: [
												createGrid({
													store: edi.stores.createMemoryStore(
														record,
														'DOCUMENTS_IN_PRINT_REPORT'
													),
													gridConfig: {
														columns: gridColumns,
														maxHeight: 400,
														disablePaging: true
													},
													viewConfig: {
														getRowClass: function (record) {
															return record.get('state') !==
																edi.constants.PRINT_STATUS.READY
																? ROW_COLOR_CLS.error
																: '';
														}
													}
												})
											]
										})
									],
									buttons: [
										createButton({
											cls: BUTTON_CLS.primary,
											text: edi.i18n.getMessage('btn.cancel'),
											glyph: edi.constants.ICONS.CANCEL,
											handler: function () {
												modalPrintDocuments.close();
											}
										})
									]
								});
								modalPrintDocuments.show();
							}
						},
						{
							glyph: edi.constants.ICONS.FILE_DOWNLOAD,
							testCls: 'test-action-column-download',
							tooltip: edi.i18n.getMessage('action.download'),
							handler: function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex),
									reportId = record.get('id');
								edi.rest.downloadFile(
									edi.utils.formatString(edi.rest.services.REPORT.GROUP.PDF.GET, {
										id: reportId
									})
								);
								modal.close();
							},
							isHidden: function (view, rowIndex, colIndex, item, record) {
								return record.get('requestList').every(function (item) {
									return item.state !== edi.constants.PRINT_STATUS.READY;
								});
							}
						}
					]
				})
			);
			const formItemsMap = {
				status: createCombo({
					fieldLabel: edi.i18n.getMessage('column.report.status'),
					name: 'status',
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.PRINT_STATUS.WAIT_DECISION,
							edi.constants.REPORT_STATE.GENERATING_ERROR,
							edi.constants.PRINT_STATUS.ORDERED,
							edi.constants.PRINT_STATUS.COMPLETED
						],
						function (id) {
							return edi.i18n.getMessage('report.status.' + id);
						},
						true
					)
				}),
				curentUser: createCheckbox({
					boxLabel: edi.i18n.getMessage('document.group.print.documents.filter.curentUser'),
					inputValue: true,
					checked: true,
					name: 'curentUser'
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					fieldsConfig: {
						common: {
							invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.create.to')
						},
						to: {
							chipTitle: ' — '
						}
					}
				})
			};
			var modal = createModalRemoteSelect(edi.rest.services.REPORT.GROUP.GET, undefined, {
				model: 'DOCUMENTS_GROUP_REPORTS',
				title: 'document.group.print.documents.modal',
				storeConfig: {
					sorters: undefined,
					autoLoad: false
				},
				proxyConfig: {
					pageParam: undefined,
					limitParam: undefined,
					startParam: undefined
				},
				checkboxes: true,
				viewConfig: {
					getRowClass: function (record) {
						return record.get('status') === edi.constants.REPORT_STATE.GENERATING_ERROR
							? ROW_COLOR_CLS.error
							: '';
					}
				},
				gridConfig: {
					columns: columns
				},
				pagingBarConfig: {
					addButtonsConfig: createButton({
						tooltip: edi.i18n.getMessage('form.btn.delete'),
						cls: [BUTTON_CLS.light, 'edi-label-button test-grid-paginator-group-delete'],
						margin: '0 0 0 35',
						glyph: edi.constants.ICONS.REMOVE,
						handler: function () {
							var store = modal.modalGrid.getStore();
							var gridSelModel = modal.modalGrid.getSelectionModel();
							if (gridSelModel) {
								var selected = gridSelModel.getSelection();
								var reportIds = [];
								for (var i = 0; i < selected.length; i++) {
									reportIds.push(selected[i].get('id'));
								}
								if (reportIds.length) {
									modal.setLoading();
									edi.rest.sendRequest(
										edi.utils.formatString(edi.rest.services.REPORT.GROUP.DELETE.PUT),
										'PUT',
										Ext.encode(reportIds),
										function () {
											store.reload();
										},
										null,
										function () {
											gridSelModel.deselectAll();
											modal.setLoading(false);
										}
									);
								}
							}
						}
					})
				},
				hideSaveButton: true,
				additionalControls: [
					createButton({
						cls: BUTTON_CLS.light,
						text: edi.i18n.getMessage('form.btn.reload'),
						glyph: edi.constants.ICONS.REFRESH,
						handler: function () {
							modal.down('grid').getStore().load();
						}
					})
				],
				createFilterFormItems: [
					createPanel({
						layout: {
							type: 'grid',
							area: [[6, 6], 6]
						},
						items: [
							formItemsMap.status,
							formItemsMap.curentUser,
							formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]
						]
					})
				],
				createFormItemsMap: formItemsMap,
				createArgs: function (values) {
					if (values.dateFrom) {
						values.modifyDateFrom = edi.utils.getSearchTime(
							values.dateFrom,
							edi.constants.DATE_FORMAT.SERVER
						);
						delete values.dateFrom;
					}
					if (values.dateTo) {
						values.modifyDateTo = edi.utils.getSearchTime(
							values.dateTo,
							edi.constants.DATE_FORMAT.SERVER,
							edi.constants.SEARCH_DAY_IN_MS
						);
						delete values.dateTo;
					}
					values.groupReportType = 'ON_DOCUMENTS';
					return values;
				}
			});
		};

		let gridSelModel = grid.getSelectionModel();
		let dataSelected = records ?? gridSelModel?.getSelection();
		let selectedIds = [];
		let selectedDocTypes = [];
		let reportId, timer, tickModal, tickModalMsgLabel;
		let userData = edi.core.getUserData();
		let partnersIds = [];
		let userOrgId = userData.org.id;
		let clearTimers = function () {
			timer ? clearInterval(timer) : null;
			if (tickModal) {
				tickModal.close();
			}
		};
		let seconds = 0;
		let tick = function () {
			tickModal = showInfo({
				items: createContainer({
					margin: '8 24',
					items: [
						(tickModalMsgLabel = createLabel({
							html: edi.i18n.getMessage('print.report.requested', [seconds++]),
							typography: 'body-short_01'
						}))
					]
				}),
				callback: function () {
					timer ? clearInterval(timer) : null;
					realtimeEvents.un('groupPrintFormComplete', onGroupPrintFormComplete);
				}
			});
		};
		const updateTickModalText = function () {
			tickModalMsgLabel?.setText(edi.i18n.getMessage('print.report.requested', [seconds++]), false);
		};

		const onGroupPrintFormComplete = function (msgData) {
			const { meta, content } = msgData?.data || {};
			if (reportId === meta?.mainObjId) {
				clearTimers();
				if (content?.Success) {
					edi.rest.downloadFile(
						edi.utils.formatString(edi.rest.services.REPORT.GROUP.PDF.GET, {
							id: reportId
						})
					);
				} else {
					const msg = edi.utils.formatComplexServerError(content?.Error, 'documents.report.request.error');
					if (msg) {
						edi.core.showError(msg);
					}
				}
			}
		};

		if (dataSelected.length) {
			for (var i = 0; i < dataSelected.length; i++) {
				var document = dataSelected[i].getData();
				selectedIds.push(document.id);
				if (!selectedDocTypes.some((it) => it === document.type)) {
					selectedDocTypes.push(document.type);
				}
				if (userOrgId === edi.utils.getObjectProperty(document, 'fromOrg.id')) {
					partnersIds.push(edi.utils.getObjectProperty(document, 'toOrg.id'));
				} else {
					partnersIds.push(edi.utils.getObjectProperty(document, 'fromOrg.id'));
				}
			}
			var failure = edi.document.actions.defaultFailureHandler(
				moduleData.tab,
				'documents.report.request.error',
				function () {
					moduleData.tab.setLoading(false);
				}
			);
			var createDocumentsReports = function (selectedTemplates) {
				var params = {
					ediReportRequestGroup: {
						format: edi.constants.REPORT_FORMATS.PDF
					},
					docIds: selectedIds
				};
				if (selectedTemplates && Array.isArray(selectedTemplates) && selectedTemplates.length) {
					params.documentReports = selectedTemplates;
				}

				realtimeEvents.on('groupPrintFormComplete', onGroupPrintFormComplete);

				edi.rest.sendRequest(
					edi.rest.services.REPORT.GROUP.POST,
					'POST',
					Ext.encode(params),
					function (responseData) {
						reportId = responseData.data.id;
						for (var i = 0; i < dataSelected.length; i++) {
							gridSelModel?.deselect(dataSelected[i]);
						}
						moduleData.tab.setLoading(false);
					},
					failure,
					function () {
						if (reportId) {
							tick();
							timer = setInterval(updateTickModalText, edi.constants.PRINT_STATUS_CHECK_DELAY);
						}
					}
				);
			};
			if (selectedDocTypes.length) {
				var args = {
					includeDocTypes: Ext.encode(selectedDocTypes),
					orgId: userData.org.id,
					gridName: 'global_with_local'
				};
				const uniqueItems = [...new Set(partnersIds)];
				if (uniqueItems.length === 1) {
					args['partnerId'] = uniqueItems[0];
				}
				edi.rest.sendRequest(
					edi.utils.compileURL(edi.rest.services.REPORT.TEMPLATE.GET, args),
					'GET',
					undefined,
					function (responseData) {
						if (responseData.success && responseData.items && responseData.items.length) {
							var repeatedTemplates = {};
							var reportTeplatesData = [];
							var docTypesNotTemplates = [];
							var notRepeatedTemplates = [];
							selectedDocTypes.forEach(function (docType) {
								var templates = responseData.items.filter(function (templates) {
									return templates.docType === docType;
								});
								if (templates.length === 0) {
									docTypesNotTemplates.push(docType);
								} else if (templates.length === 1) {
									notRepeatedTemplates.push(templates[0]);
								} else {
									repeatedTemplates[docType] = templates;
								}
							});

							var continueCreateReport = function () {
								for (var docType in repeatedTemplates) {
									if (repeatedTemplates.hasOwnProperty(docType)) {
										var templatesWithPartners = [];
										var templatesWithoutPartners = [];
										repeatedTemplates[docType]?.forEach(function (template) {
											var id =
												template.partner && template.partner.id ? template.partner.id : null;
											if (id) {
												templatesWithPartners.push(template);
											} else {
												templatesWithoutPartners.push(template);
											}
										});
										if (templatesWithPartners.length === 0) {
											templatesWithPartners = templatesWithoutPartners;
											templatesWithoutPartners = [];
										}
										var sortedTemplates = templatesWithPartners.sort(function (a, b) {
											return a.modifyDate - b.modifyDate;
										});
										templatesWithoutPartners = templatesWithoutPartners.sort(function (a, b) {
											return b.modifyDate - a.modifyDate;
										});
										var defaultTemplate = sortedTemplates.pop();
										defaultTemplate.templates = sortedTemplates.concat(templatesWithoutPartners);
										reportTeplatesData.push(defaultTemplate);
									}
								}
								var completionCreateReport = function () {
									if (Object.keys(repeatedTemplates).length > 0) {
										var gridColumns = edi.columns.get('report_templates'),
											templatesGrid;
										gridColumns.push(
											createActionsColumnConfig({
												items: [
													{
														glyph: edi.constants.ICONS.EDIT,
														testCls: 'test-action-column-edit',
														handler: function (grid, rowIndex) {
															var record = grid.getStore().getAt(rowIndex),
																templatesDocTypeGrid,
																buttonSelect;
															var recordData = record.getData();
															var recordTemplateData = Ext.clone(recordData);
															delete recordTemplateData.templates;
															var modalList = createModalPanel({
																width: MODAL_SIZE.widthMedium,
																title: edi.i18n.getMessage(
																	'report.export.modal.select.report.template.title'
																),
																items: [
																	createModalForm({
																		items: [
																			(templatesDocTypeGrid = createGrid({
																				proxyConfig: {
																					type: 'memory',
																					reader: {
																						rootProperty: 'items'
																					}
																				},
																				storeConfig: {
																					model: edi.models.getModel(
																						'REPORT_TEMPLATES'
																					),
																					data: {
																						items: recordData.templates
																					}
																				},
																				gridConfig: {
																					columns:
																						edi.columns.get(
																							'report_templates'
																						),
																					height: 200,
																					disablePaging: true,
																					listeners: {
																						itemclick: function () {
																							buttonSelect.setDisabled(
																								false
																							);
																						}
																					}
																				}
																			}))
																		]
																	})
																],
																buttonsBefore: [
																	(buttonSelect = createButton({
																		cls: BUTTON_CLS.primary,
																		text: edi.i18n.getMessage('form.btn.select'),
																		glyph: edi.constants.ICONS.DONE,
																		disabled: true,
																		handler: function () {
																			var gridSelModel =
																				templatesDocTypeGrid.getSelectionModel();
																			if (gridSelModel.getCount()) {
																				var selectedTemplateData = gridSelModel
																					.getSelection()[0]
																					.getData();
																				selectedTemplateData.templates =
																					recordData.templates.map(function (
																						item
																					) {
																						if (
																							item.id ==
																							selectedTemplateData.id
																						) {
																							return recordTemplateData;
																						}
																						return item;
																					});
																				record.set(selectedTemplateData);
																			}
																			modalList.close();
																		}
																	}))
																]
															});
															modalList.show();
														}
													}
												]
											})
										);
										var modal = createModalPanel({
											width: MODAL_SIZE.widthMedium,
											title: edi.i18n.getMessage('report.export.modal.report.templates.title'),
											items: [
												createModalForm({
													items: [
														createOldLabel({
															html: edi.i18n.getMessage(
																'report.export.question.select.report.template'
															)
														}),
														(templatesGrid = createGrid({
															proxyConfig: {
																type: 'memory',
																reader: {
																	rootProperty: 'items'
																}
															},
															storeConfig: {
																model: edi.models.getModel('REPORT_TEMPLATES'),
																data: {
																	items: reportTeplatesData
																}
															},
															gridConfig: {
																columns: gridColumns,
																height: 200,
																disablePaging: true,
																disableSelection: true
															}
														}))
													]
												})
											],
											buttonsBefore: [
												createButton({
													cls: BUTTON_CLS.primary,
													text: edi.i18n.getMessage('form.btn.apply'),
													glyph: edi.constants.ICONS.DONE,
													handler: function () {
														var storeRange = templatesGrid.getStore().getRange();
														var selectedTemplates = storeRange.map(function (template) {
															return {
																id: +template.get('id'),
																type: template.get('docType')
															};
														});
														modal.close();
														createDocumentsReports(selectedTemplates);
													}
												})
											]
										});
										modal.show();
									} else {
										createDocumentsReports(
											notRepeatedTemplates.map((t) => ({
												id: t.id,
												type: t.docType
											}))
										);
									}
								};
								if (uniqueItems.length > 1) {
									edi.core.showInfo(
										edi.i18n.getMessage('printed.form.diff.parnters.info'),
										function () {
											completionCreateReport();
										}
									);
								} else {
									completionCreateReport();
								}
							};

							if (docTypesNotTemplates.length) {
								selectedIds = dataSelected
									.filter(function (document) {
										return !docTypesNotTemplates.some((it) => it === document.get('type'));
									})
									.map(function (docData) {
										return docData.get('id');
									});
								edi.core.showWarn(
									edi.i18n.getMessage('report.export.doc.type.not.template.warning', [
										docTypesNotTemplates
											.map(function (docType) {
												return edi.i18n.getMessage('documents.doctype.' + docType);
											})
											.join(', ')
									]),
									continueCreateReport
								);
							} else {
								continueCreateReport();
							}
						} else {
							edi.core.showWarn(
								edi.i18n.getMessage('report.export.doc.type.not.template.warning', [
									selectedDocTypes
										.map(function (docType) {
											return edi.i18n.getMessage('documents.doctype.' + docType);
										})
										.join(', ')
								])
							);
						}
					},
					failure
				);
			}
		} else {
			modalGroupReports();
		}
	},
	calculateTaxRate: function (value) {
		if (value === edi.constants.taxRates.taxNoVat || value === edi.constants.taxRates.taxVatByTaxAgent) {
			return 0;
		}
		var userData = edi.core.getUserData();
		var rates = edi.methods.taxRates.getRatesByCountry(userData.org.country);
		var findedRate = rates.find(function (item) {
			return item.hasOwnProperty('mathValue') && 'function' == typeof item.mathValue && value == item.id;
		});
		if (findedRate) {
			return findedRate.mathValue();
		}
		return parseFloat(value || 0);
	},
	openPricatCreateModule: function () {
		const userOrgAttr = edi.core.getUserData().org.attributes;
		const isVersion2 = edi.utils.getAttributeByName(userOrgAttr, 'ENABLE_PRICAT_EANCOM_V2') === 'true';
		edi.core.openModule(`document.create.pricateancom${isVersion2 ? '_v2' : ''}`, null);
	},
	invitations: {
		create: function () {
			var createBtn, orgId, form;
			const { formItemsMap, items } = edi.filters.config.organizations.createFormItems();
			var modal = createModalPanel({
				width: MODAL_SIZE.widthMedium,
				title: edi.i18n.getMessage('invitation.to.partner'),
				items: [
					(form = createModalForm({
						items: [
							createFieldBlock({
								cls: FIELD_BLOCK_CLS.small,
								title: edi.i18n.getMessage('organization.select'),
								items: [
									createCompanySelector({
										cls: '',
										callback: function (values, org) {
											orgId = org ? org.id : null;
											createBtn.setDisabled(!orgId);
										},
										createModalRemoteSelect: createModalRemoteSelect,
										createModalRemoteSelectOptions: {
											createArgs: edi.filters.config.generic.createArgs,
											createFormItemsMap: formItemsMap,
											createFilterFormItems: items
										},
										relationsRemoteURL: edi.rest.services.ORGANIZATION.NO_PARTNER.GET,
										manualChangeDisabled: true,
										alwaysShowSelect: true,
										allowReset: false,
										allowBlank: false,
										readOnly: false
									})
								]
							})
						]
					}))
				],
				buttonsBefore: [
					(createBtn = createSaveButton(
						function () {
							modal.setLoading();

							edi.rest.sendRequest(
								edi.rest.services.INVITATION.POST,
								'POST',
								Ext.encode({
									acceptor: orgId
								}),
								function () {
									modal.setLoading(false);
									modal.close();
									edi.core.showInfo('invitation.sent');
									edi.events.invitation.fireEvent('change');
								},
								function () {
									edi.core.logMessage('Error sending agreement invitation', 'error');
									edi.core.showError('error.sending.invitation', function () {
										modal.setLoading(false);
									});
								}
							);
						},
						{
							disabled: true,
							text: edi.i18n.getMessage('invitation.send.to.partner')
						}
					))
				]
			});

			modal.show();
			form.on('validitychange', function () {
				createBtn.setDisabled(!orgId);
			});

			form.isValid();
		}
	},
	transformations: {
		// Creates doc based on other doc using transformation
		createDocViaTransformation: function (options) {
			var docType = options.docType,
				parentId = options.parentId,
				onCreateDocumentFailure =
					'function' == typeof options.onFailure
						? options.onFailure
						: function (responseData) {
								edi.core.showError(
									edi.utils.formatComplexServerError(
										responseData,
										'error.create.doc.via.transformation'
									)
								);
						  },
				beforeStart =
					'function' == typeof options.beforeStart
						? options.beforeStart
						: options.moduleTab
						? function () {
								options.moduleTab.setLoading();
						  }
						: null,
				callback =
					'function' == typeof options.callback
						? options.callback
						: options.moduleTab
						? function () {
								options.moduleTab.setLoading(false);
						  }
						: null;

			var onCreateDoc =
				'function' == typeof options.onSuccess
					? options.onSuccess
					: function (transformData) {
							if (transformData.success && 'object' == typeof transformData.data) {
								transformData.parentId = parentId;
								transformData.parties = options.parties;
								transformData.buyerOrg = options.buyerOrg;

								let modName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[docType];
								if (options.additionalData?.versionId) {
									modName =
										edi.constants.CREATE_MODULE_NAME_BY_TYPE[
											`${docType}_${options.additionalData.versionId}`
										];
								}
								let moduleData = {
									meta: {
										id: parentId
									}
								};
								if (options.additionalData) {
									Ext.merge(moduleData, options.additionalData);
								}
								edi.core.openModule(modName, transformData, undefined, false, undefined, moduleData);
							}
					  };

			beforeStart ? beforeStart() : null;
			let postData = options.postData || {
				docType: docType,
				docFlowType: docType
			};
			edi.rest.sendRequest(
				edi.utils.formatString(options.uri ? options.uri : edi.rest.services.DOCUMENTS.TRANSFORMATION.POST, {
					parentId: parentId
				}),
				'POST',
				Ext.encode(postData),
				onCreateDoc,
				onCreateDocumentFailure,
				callback
			);
		}
	},
	taxRates: {
		getNumberTypeRates: function (countryIso3, asString) {
			var tmp = [],
				country = edi.constants.TAX_RATES.BY_COUNTRY.hasOwnProperty(countryIso3) ? countryIso3 : 'RUS',
				rates = edi.constants.TAX_RATES.BY_COUNTRY[country];

			for (var i = 0; i < rates.length; i++) {
				if (!rates[i].type || rates[i].type !== 'string') {
					tmp.push(asString && true === asString ? rates[i].id : Number(rates[i].id));
				}
			}
			return tmp;
		},
		getRatesByCountry: function (countryIso3, addEmptyRecord) {
			if (!edi.constants.TAX_RATES) {
				return [];
			}
			var rates = addEmptyRecord ? [edi.stores.getEmptyRecord()] : [],
				country = edi.constants.TAX_RATES.BY_COUNTRY.hasOwnProperty(countryIso3) ? countryIso3 : 'RUS';

			rates = rates.concat(edi.constants.TAX_RATES.BY_COUNTRY[country]);
			return Ext.clone(rates);
		},
		isValidRate: function (rate, availableRates, allowEmpty) {
			var taxValue = typeof rate !== undefined && rate !== null ? String(rate).replace(/[%]*/g, '') : null,
				isEmptyTax = !rate || rate == edi.i18n.getMessage('form.combo.not.selected'),
				isValidTaxValue =
					taxValue &&
					!!availableRates.find(function (rateObj) {
						return rateObj.id == taxValue;
					});

			return (isEmptyTax && allowEmpty) || isValidTaxValue;
		},
		getProductsWithInvalidRates: function (allProducts, rates, properties) {
			properties = properties || {};

			var taxRateFieldName = properties.taxFieldName || 'TaxRate',
				EANFieldName = properties.eanFieldName || 'EAN',
				lineNumberFieldName = properties.lineNumberFieldName || 'LineNumber',
				res = [];

			allProducts.forEach(function (product) {
				if (!edi.methods.taxRates.isValidRate(product[taxRateFieldName], rates, properties.allowBlankRate)) {
					res.push(
						edi.models.createInstance('INVALID_RATE_PRODUCT', {
							LineNumber: product[lineNumberFieldName],
							EAN: product[EANFieldName],
							TaxRate: product[taxRateFieldName]
						})
					);
				}
			});

			return res;
		},
		showInvalidProductsWarn: function (productsWithInvalidTaxRates, availableRates, config) {
			config = config || {};
			var onOkClick = 'function' == typeof config.callback ? config.callback : null;
			if (!Array.isArray(productsWithInvalidTaxRates) || !productsWithInvalidTaxRates.length) {
				return;
			}

			var rateNames = availableRates.map(function (rateObj) {
				return rateObj.id ? edi.renderers.taxRateString(rateObj.id) : null;
			});

			edi.utils.clearEmptyArrayValues(rateNames);

			var columnsConfig = config.columns || {
				LineNumber: {
					title: 'column.line.number'
				},
				EAN: {
					title: 'column.ean'
				},
				TaxRate: {
					title: 'column.tax.rate'
				}
			};

			edi.core.showWarn(
				edi.i18n.getMessage('products.invalid.tax.rates.warn', [
					edi.document.actions.createItemsList(productsWithInvalidTaxRates, columnsConfig),
					rateNames.join(', ')
				]),
				onOkClick
			);
		},
		showInvalidProductsWarnIfNeeded: function (products, availableRates, config) {
			config = config || {};

			var productsWithInvalidRates = edi.methods.taxRates.getProductsWithInvalidRates(
				products,
				availableRates,
				config
			);
			if (productsWithInvalidRates.length) {
				edi.methods.taxRates.showInvalidProductsWarn(productsWithInvalidRates, availableRates, config);
			}
		}
	},
	documents: {
		showActiveAnnulModal: function (annulDoc, moduleData, options) {
			options = options || {};
			var annualReason = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'ANNUL_REASON');
			var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				//edi.modulesHandler.removeModule(moduleData);
			});

			var success = function () {
				moduleData.tab.setLoading(false);
				edi.events.documents.fireEvent('change', {
					id: moduleData.initData.data.id
				});
			};

			const abort = () => moduleData.tab?.setLoading(false);

			var modalAnnulDoc = createModalPanel({
				title: edi.i18n.getMessage('document.mark.annul.alert'),
				width: MODAL_SIZE.widthLarge,
				closable: !options.decisionMandatory,
				items: [
					createModalForm({
						cls: 'document-add-form',
						items: [
							createFieldBlock({
								cls: FIELD_BLOCK_CLS.small,
								title: edi.i18n.getMessage('document.mark.annul.reason'),
								items: [
									createOldLabel({
										text: edi.i18n.getMessage(annualReason)
									})
								]
							})
						]
					})
				],
				buttons: [
					createSendButton(
						function () {
							var sendAnnulDoc = function (certificate, poa, poaConfirmChecked) {
								const certificateHandler = createCertificateHandler({ certificate });
								const poaHandler = createPoaHandler({ poa, poaConfirmChecked });

								edi.utils.sign(
									annulDoc,
									moduleData.tab,
									function (failed, data, cert, poa, silent) {
										if (silent) {
											abort();
										} else if (failed) {
											failure(data);
										} else {
											success();
										}
									},
									undefined,
									undefined,
									true,
									certificateHandler,
									{
										signRefuse: abort
									},
									poaHandler
								);
							};
							if (annulDoc && annulDoc.beforeAnnul) {
								annulDoc.beforeAnnul(function (certificate, poa, poaConfirmChecked) {
									sendAnnulDoc(certificate, poa, poaConfirmChecked);
								});
							} else {
								sendAnnulDoc();
							}
							modalAnnulDoc.close();
						},
						{
							disabled: false,
							text: edi.i18n.getMessage('document.mark.annul.accept')
						}
					),
					createCancelButton(
						function () {
							edi.document.actions.handlerRejectAnnualDocument(
								annulDoc,
								success,
								failure,
								moduleData.tab
							);
							modalAnnulDoc.close();
						},
						{
							disabled: false,
							text: edi.i18n.getMessage('document.mark.annul.reject')
						}
					),
					options.decisionMandatory
						? null
						: createButton({
								cls: BUTTON_CLS.secondary,
								text: edi.i18n.getMessage('form.btn.remind.later'),
								glyph: edi.constants.ICONS.CLOSE,
								handler: () => modalAnnulDoc.close()
						  })
				]
			});
			modalAnnulDoc.show();
		},
		showReasonModal: function (title, callback, configFrom) {
			configFrom = configFrom || {};
			var inputMethod = getInputMethodByType(configFrom.type);
			var form = createModalForm({
				cls: 'document-add-form',
				items: [
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage(title + '.reason'),
						items: [
							inputMethod(
								Object.assign(configFrom, {
									name: 'reason',
									allowBlank: false,
									validator: function (value) {
										const errMsg = edi.i18n.getMessage(`${title}.reason.fill.fields.error`);
										return value && !value.trim() ? errMsg : true;
									}
								})
							)
						]
					})
				]
			});

			form.isValid();

			var modal = createModalPanel({
				title: edi.i18n.getMessage(title),
				width: MODAL_SIZE.widthMedium,
				items: [form],
				buttonsBefore: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('form.btn.send'),
						glyph: edi.constants.ICONS.REJECT,
						formBind: false,
						disabled: false,
						handler: function () {
							if (form.isValid()) {
								var values = edi.utils.collectFormValues(form);
								if (values && values.reason) {
									callback(values.reason);
								}
							}
						}
					})
				]
			});

			modal.show();
			return modal;
		},
		needToAddTaskId: function (recordData) {
			if (!edi.constants.NOTICE_DOC_TYPES || !recordData || !recordData.state || !recordData.type) {
				return false;
			}

			if (
				recordData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH &&
				recordData.state === edi.constants.STATE.DRAFT
			) {
				return false;
			}

			return edi.constants.NOTICE_DOC_TYPES.some((it) => it === recordData.type);
		},
		changeCountSignatureAttribute: function (increment, attr, doctype, documentId, direction, callback, newValue) {
			var failure = function (resp) {
				edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.saving.upd.project'));
				'function' == typeof callback ? callback() : null;
			};
			var side = edi.document.actions.getSignSide(doctype, direction);
			var attrName = 'countSignature_' + side;
			if (newValue) {
				attr.countSignature = newValue;
			} else {
				attr.countSignature = attr.countSignature + increment;
			}

			let payload = Ext.encode({
				[attrName]: attr.countSignature
			});
			let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.ATTRIBUTES.PUT, {
				docId: documentId
			});
			// @todo Плохое решение, сейчас если пользователь прерывает действие происходит еще один вызов,
			//  отменяющий это действие, Если пользователь, в процессе закроет окно, то отмены не случится
			edi.rest.sendRequest(url, 'PUT', payload, 'function' == typeof callback ? callback : null, failure);
		},
		updateAsyncSignStatus: function (socketMsg) {
			const { meta, content } = socketMsg?.data || {};
			if (meta?.mainObjId) {
				edi.events.documents.fireEvent('change', {
					id: meta.mainObjId,
					fromAsyncSign: true
				});
			}
			const docLabel =
				content?.docType && content.docNumber
					? createLabelForDetails({
							typography: 'body-short_01',
							color: '--color-white',
							text: edi.i18n.getMessage(
								content.Success ? 'document.async.sign.success' : 'document.async.sign.error',
								{
									type: edi.i18n.getMessage(`documents.doctype.${content.docType}`),
									number: content.docNumber
								}
							)
					  })
					: null;
			const errorLabel =
				!content.Success && edi.i18n.getMessage(content?.TypeError) !== content?.TypeError
					? createLabelForDetails({
							typography: 'body-short_01',
							color: '--color-white',
							html: edi.utils.formatComplexServerError({
								typeError: content.TypeError,
								additionalData: content.additionalData
							})
					  })
					: null;
			if (docLabel || errorLabel) {
				createToast({
					showButton: false,
					showCloseBtn: true,
					generateItems: () => [docLabel, errorLabel]
				});
			}
		}
	}
});

Ext.Object.merge(edi.methods, {
	certificate: {
		expiringCerts: function (callback) {
			let success = function (resp) {
				if (!Array.isArray(resp.items) || resp.items.length === 0) {
					return;
				}

				let expiringCerts = resp.items.map((cert) => {
					let leftDays = edi.utils.getCountDaysFromToday(cert.validToDate);
					let certObj = edi.utils.certificateObject(cert.subjectName);

					return {
						cert: cert.serialNumber,
						days: leftDays,
						user:
							(certObj.lastname ? certObj.lastname : '') +
							' ' +
							(certObj.firstname ? certObj.firstname : '')
					};
				});

				expiringCerts.forEach((cert) => {
					let windowConfig = {
						icoStyle: 'notice',
						title: edi.i18n.getMessage('certificate.expiring.days.title', { days: cert.days }),
						text:
							'<p>' +
							edi.i18n.getMessage('certificate.expiring.days.text') +
							' <b>' +
							cert.user +
							'</b></p>',
						buttons: [
							{
								xtype: 'button',
								text: edi.i18n.getMessage('certificate.expiring.days.btn'),
								handler: function () {
									if (edi.constants.IS_EDIWEB_CLIENT) {
										edi.core.openModule('control.center.module.panel', {
											activeTab: 'certificates'
										});
									} else {
										edi.core.openModule('user.profile');
									}
								}
							}
						]
					};
					setTimeout(edi.methods.notice.createWindowInfo, 3000, windowConfig);
				});
			};

			edi.rest.sendRequest(
				edi.rest.services.USER.CERTIFICATE.EXPIRING_LIST.GET,
				'GET',
				null,
				success,
				undefined,
				callback
			);
		}
	}
});

Ext.Object.merge(edi.methods, {
	validators: {
		ean: function (value) {
			var valid = true,
				result = edi.constants.VALIDATORS.EAN.test(value),
				strVal = String(value);
			if (!result && strVal.length) {
				result = edi.constants.VALIDATORS.INT.test(value);
				if (!result || strVal.length > 13) {
					valid = edi.i18n.getMessage('invalid.field.ean.value.default');
				}
			}
			return valid;
		}
	}
});

Ext.Object.merge(edi.methods, {
	poa: {
		getButtonUserProfile: function (getModal, recordData) {
			let buttons = [];
			if (!recordData.poaData) {
				buttons.push(
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('form.btn.poa.add'),
						glyph: edi.constants.ICONS.FOLDER,
						handler: function () {
							edi.methods.poa.selectPoA(recordData.data, false, function (poa) {
								getModal().setLoading();
								let url = edi.utils.formatString(edi.rest.services.USER.POA.LINK_BY_CERT.POST, {
									id: recordData.header.id,
									poaId: poa.docId
								});
								let failure = function (data) {
									edi.core.showError(
										edi.utils.formatComplexServerError(data, 'error.server'),
										function () {
											getModal().setLoading(false);
										}
									);
								};
								edi.rest.sendRequest(
									url,
									'POST',
									null,
									function (data) {
										getModal().close();
										edi.events.certificate.fireEvent('change');
									},
									failure
								);
							});
						}
					})
				);
			} else {
				buttons.push(
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('form.btn.poa.deactive'),
						glyph: edi.constants.ICONS.CLOSE,
						handler: function () {
							getModal().setLoading();
							let url = edi.utils.formatString(edi.rest.services.USER.POA.DELETE, {
								id: recordData.header.id,
								poaId: recordData.poaData.header
							});
							let failure = function (data) {
								edi.core.showError(
									edi.utils.formatComplexServerError(data, 'error.server'),
									function () {
										getModal().setLoading(false);
									}
								);
							};
							edi.rest.sendRequest(
								url,
								'DELETE',
								null,
								function (data) {
									getModal().close();
									edi.events.certificate.fireEvent('change');
								},
								failure
							);
						}
					})
				);
			}
			return buttons;
		},
		selectPoA: function (certificateData, isSignDoc, callback, refuse) {
			const failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
					'function' == typeof refuse ? refuse(successClose) : null;
				});
			};
			let url = edi.rest.services.POWER_OF_ATTORNEY.SEARCH_BY_CERT.POST;
			let successClose = false;
			edi.rest.sendRequest(
				url,
				'POST',
				Ext.encode(certificateData),
				function (data) {
					if (data?.data?.result) {
						// найдено ни одной МЧД
						if (!data.data.result.length) {
							if (isSignDoc) {
								//OVERRIDE Ediweb brgin
								const msgBox = showMessageBox({
									title: edi.i18n.getMessage('not.find.poa.for.sign.title'),
									msgText: edi.i18n.getMessage('not.find.poa.for.sign.suggestion'),
									okBtnConfig: {
										text: edi.i18n.getMessage('not.find.poa.for.sign.btn.goto'),
										handler: function () {
											msgBox.close();

											edi.core.openModule(
												'control.center.module.panel',
												undefined,
												undefined,
												undefined,
												undefined,
												undefined,
												function () {
													edi.events.control_center.fireEvent(
														'activatetab',
														'powersOfAttorney'
													);
												}
											);
										}
									},
									callback: function () {
										'function' == typeof refuse ? refuse(successClose) : null;
									}
								});
								//OVERRIDE Ediweb end
							} else {
								edi.core.showError('not.find.poa', function () {
									'function' == typeof refuse ? refuse(successClose) : null;
								});
							}
						}
						//выбор МЧД из списка
						else {
							let poas = data.data.result;
							let modal,
								gridData = [];

							let selectPoa = function (data) {
								'function' == typeof callback ? callback(data) : null;
								successClose = true;
							};

							let linkedPoa = poas.find(
								(item) =>
									edi.utils.getAttributeByName(item.attributes, 'POA_HAS_LINK') === 'true' &&
									!!item.poaHasLinked
							);
							if (linkedPoa) {
								selectPoa(linkedPoa);
							} else {
								for (let i = 0; i < poas.length; i++) {
									gridData.push(edi.models.createInstance('POA_SELECT_LIST', poas[i]));
								}

								let grid = createGrid({
									storeConfig: {
										proxy: createProxyConfig({
											type: 'pagingmemory'
										}),
										data: gridData || [],
										remoteSort: false,
										model: edi.models.getModel('POA_SELECT_LIST'),
										listeners: {
											datachanged: function () {
												modal ? modal.setLoading(false) : null;
											}
										}
									},
									gridConfig: {
										columns: edi.columns.get('poa_select_list'),
										listeners: {
											select: function (comp, record) {
												var data = record.getData();
												selectPoa(data);
												modal.close();
											}
										},
										disablePaging: true, //OVERRIDE Ediweb
										height: 400
									}
								});

								modal = createModalPanel({
									cls: 'edi-modal-certificate-selector',
									width: MODAL_SIZE.widthLarge,
									title: edi.i18n.getMessage('poa.select.title'),
									items: [
										createModalForm({
											items: [grid]
										})
									],
									listeners: {
										close: function () {
											'function' == typeof refuse ? refuse(successClose) : null;
										}
									}
								});
								modal.show();
							}
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		},
		openDetailsById: function ({ docId, poaId }, getModal, titlePostfix) {
			let modal = getModal();
			const url = edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.HEADER.BY_POA_ID.GET, {
				documentId: docId,
				poaId
			});
			modal.setLoading();
			let failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
					modal.setLoading(false);
				});
			};
			edi.rest.sendRequest(
				url,
				'GET',
				undefined,
				function (resp) {
					if (!resp || !resp.data) {
						failure(resp);
						return;
					}
					let docHeader = resp.data;
					const version = docHeader.versionId || docHeader.versForm;
					const moduleName =
						edi.constants.DETAILS_MODULE_NAME_BY_TYPE[
							edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + `_1.0.19.0-RC40`
						];
					edi.core.openModule(moduleName, docHeader, titlePostfix, undefined, undefined, {
						fromSignature: true,
						signedWithPoaDocId: docId,
						docSignersUri: edi.utils.formatString(powerOfAttorneyAPI.FROM_DOCUMENT.SIGNERS.GET, {
							docId,
							poaId
						}),
						docPrintTemplatesUri: edi.utils.formatString(powerOfAttorneyAPI.FROM_DOCUMENT.PDF.GET, {
							docId,
							poaId
						})
					});
					modal.close();
				},
				failure
			);
		}
	}
});

Object.assign(Ext.form.field.VTypes, edi.methods.validators.createNumberValidator('numberN12_3', 12, 3));
Object.assign(Ext.form.field.VTypes, edi.methods.validators.createNumberValidator('numberN10_2', 10, 2));
