const PACKAGE_ATTACH_COLUMN_CONFIG_NAME = 'package_attach_documents';
const PACKAGE_STATUTORY_COLUMN_CONFIG_NAME = 'package_statutory_documents';
const PACKAGE_ATTACH_PRIL02OSTRB_COLUMN_CONFIG_NAME = 'package_attach_pril02ostrb';
const PACKAGE_ATTACH_PRILUVIZMCEN_COLUMN_CONFIG_NAME = 'package_attach_priluvizmcen';
const PACKAGE_ATTACH_PRIL06KTOV_COLUMN_CONFIG_NAME = 'package_attach_pril06ktov';
const PACKAGE_ATTACH_PRILPROTSOGL_COLUMN_CONFIG_NAME = 'package_attach_prilprotsogl';
const PACKAGE_ATTACH_PRILCOMMUSL_COLUMN_CONFIG_NAME = 'package_attach_prilcommusl';
const PACKAGE_ATTACH_PRILCOMMUSL_ONLINE_PODUCTS_COLUMN_CONFIG_NAME = 'package_attach_prilcommusl_online_products';
const PACKAGE_ATTACH_PRILKTOVDR_COLUMN_CONFIG_NAME = 'package_attach_prilktovdr';

edi.columns.addColumns({
	[PACKAGE_ATTACH_COLUMN_CONFIG_NAME]: {
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 3,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('documents.doctype.' + value) : null;
			}
		},
		state: {
			text: 'documents.column.status',
			dataIndex: 'state',
			renderer: 'status',
			flex: 1,
			htmlEncode: true
		}
	},
	[PACKAGE_STATUTORY_COLUMN_CONFIG_NAME]: {
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 3,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('documents.doctype.' + value) : null;
			}
		},
		state: {
			text: 'documents.column.status',
			dataIndex: 'state',
			htmlEncode: true,
			renderer: function (value, meta, record) {
				let recordData = record.getData(),
					annualReason,
					stateInfo = '';
				if (recordData.type == edi.constants.DOCUMENT_TYPES.DP_PRAZN) {
					annualReason = edi.utils.getAttributeByName(recordData.attributes, 'ANNUL_REASON');
					if (annualReason) {
						stateInfo = edi.renderers.infoStateRenderer(' (' + annualReason + ')');
					}
				}
				return edi.renderers.status(value, meta, record) + stateInfo;
			},
			flex: 2
		}
	},
	[PACKAGE_ATTACH_PRIL02OSTRB_COLUMN_CONFIG_NAME]: {
		addressEntrance: {
			text: 'document.package.agreement.textInfos.title.column.addressEntrance',
			dataIndex: 'addressEntrance',
			flex: 2
		},
		addressReturn: {
			text: 'document.package.agreement.textInfos.title.column.addressReturn',
			dataIndex: 'addressReturn',
			flex: 2
		}
	},
	[PACKAGE_ATTACH_PRILUVIZMCEN_COLUMN_CONFIG_NAME]: {
		serialNumber: {
			text: 'document.package.agreement.textInfos.title.serialNumber',
			dataIndex: 'serialNumber',
			flex: 1
		},
		productOfCode: {
			text: 'document.package.agreement.textInfos.title.productOfCode',
			dataIndex: 'productOfCode',
			flex: 1
		},
		productOfName: {
			text: 'document.package.agreement.textInfos.title.productOfName',
			tdCls: 'x-grid-cell-inner-portable-row',
			dataIndex: 'productOfName',
			flex: 1.5
		},
		activePrice: {
			text: 'document.package.agreement.textInfos.title.activePrice',
			dataIndex: 'activePrice',
			flex: 1
		},
		newPrice: {
			text: 'document.package.agreement.textInfos.title.newPrice',
			dataIndex: 'newPrice',
			flex: 1
		},
		percentPrice: {
			text: 'document.package.agreement.textInfos.title.percentPrice',
			dataIndex: 'percentPrice',
			flex: 1
		}
	},
	[PACKAGE_ATTACH_PRIL06KTOV_COLUMN_CONFIG_NAME]: {
		positionCode: {
			text: 'document.package.agreement.textInfos.title.positionCode',
			dataIndex: 'positionCode',
			flex: 1
		},
		productName: {
			text: 'document.package.agreement.textInfos.title.productName',
			tdCls: 'x-grid-cell-inner-portable-row',
			dataIndex: 'productName',
			flex: 1.5
		},
		trademark: {
			text: 'document.package.agreement.textInfos.title.trademark',
			dataIndex: 'trademark',
			flex: 1
		},
		taxRate: {
			text: 'document.package.agreement.textInfos.title.tax',
			dataIndex: 'tax',
			flex: 1
		},
		unitOfMeasureMeter: {
			text: 'document.package.agreement.textInfos.title.unitOfMeasureMeter',
			dataIndex: 'unitOfMeasureMeter',
			flex: 1
		}
	},
	[PACKAGE_ATTACH_PRILPROTSOGL_COLUMN_CONFIG_NAME]: {
		serialNumber: {
			text: 'document.package.agreement.textInfos.title.serialNumber',
			dataIndex: 'serialNumber',
			flex: 1
		},
		productOfCode: {
			text: 'document.package.agreement.textInfos.title.productOfCode',
			dataIndex: 'productOfCode',
			flex: 1
		},
		productOfName: {
			text: 'document.package.agreement.textInfos.title.productOfName',
			tdCls: 'x-grid-cell-inner-portable-row',
			dataIndex: 'productOfName',
			flex: 1.5
		},
		price: {
			text: 'document.package.agreement.textInfos.title.price',
			dataIndex: 'price',
			flex: 1
		}
	},
	[PACKAGE_ATTACH_PRILCOMMUSL_COLUMN_CONFIG_NAME]: {
		productOfCode: {
			text: 'document.package.agreement.textInfos.title.productOfCode',
			dataIndex: 'productOfCode',
			flex: 1
		},
		productOfName: {
			text: 'document.package.agreement.textInfos.title.productOfName.trademark',
			tdCls: 'x-grid-cell-inner-portable-row',
			dataIndex: 'productOfName',
			flex: 1.5
		},
		premium: {
			text: 'document.package.agreement.textInfos.title.percentage.premium',
			dataIndex: 'premium',
			flex: 1
		}
	},
	[PACKAGE_ATTACH_PRILCOMMUSL_ONLINE_PODUCTS_COLUMN_CONFIG_NAME]: {
		onlineProductOfCode: {
			text: 'document.package.agreement.textInfos.title.productOfCode',
			dataIndex: 'onlineProductOfCode',
			flex: 1
		},
		onlineProductOfName: {
			text: 'document.package.agreement.textInfos.title.productOfName.trademark',
			tdCls: 'x-grid-cell-inner-portable-row',
			dataIndex: 'onlineProductOfName',
			flex: 1.5
		},
		onlineProductPremium: {
			text: 'document.package.agreement.textInfos.title.percentage.premium',
			dataIndex: 'onlineProductPremium',
			flex: 1
		}
	},
	[PACKAGE_ATTACH_PRILKTOVDR_COLUMN_CONFIG_NAME]: {
		positionCode: {
			text: 'document.package.agreement.textInfos.title.productionCode',
			dataIndex: 'productionCode',
			flex: 1
		},
		productName: {
			text: 'document.package.agreement.textInfos.title.productionName',
			tdCls: 'x-grid-cell-inner-portable-row',
			dataIndex: 'productionName',
			flex: 1.5
		},
		trademark: {
			text: 'document.package.agreement.textInfos.title.trademark',
			dataIndex: 'trademark',
			flex: 1
		},
		taxRate: {
			text: 'document.package.agreement.textInfos.title.tax',
			dataIndex: 'taxRate',
			flex: 1
		},
		unitOfMeasureMeter: {
			text: 'document.package.agreement.textInfos.title.unitOfMeasureMeter',
			dataIndex: 'unitOfMeasureMeter',
			flex: 1
		}
	}
});

let getPackageAttachColumns = () => edi.columns.get(PACKAGE_ATTACH_COLUMN_CONFIG_NAME);
let getPackageStatutoryColumns = () => edi.columns.get(PACKAGE_STATUTORY_COLUMN_CONFIG_NAME);

export {
	getPackageAttachColumns,
	getPackageStatutoryColumns,
	PACKAGE_ATTACH_COLUMN_CONFIG_NAME,
	PACKAGE_STATUTORY_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRIL02OSTRB_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILUVIZMCEN_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRIL06KTOV_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILPROTSOGL_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILCOMMUSL_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILCOMMUSL_ONLINE_PODUCTS_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILKTOVDR_COLUMN_CONFIG_NAME
};
