/**
 * Navigation configuration for fns
 * @author Vsevolod Liapin
 */

Ext.namespace('edi.navigation');

edi.navigation.addNavigationConfig({
	name: 'fns',
	items: {
		documents_create: {
			children: {
				fns_upd_5_01n: {
					title: 'document.fns_upd_5_01n',
					modName: 'document.create.fns_upd_5_01n',
					order: 505
				},
				fns_upd_5_02n: {
					title: 'document.fns_upd_5_02n',
					modName: 'document.create.fns_upd_5_02n',
					order: 505
				},
				service_act: {
					title: 'document.service_act',
					modName: 'document.create.service_act',
					order: 507
				}
			}
		}
	}
});
