import { createModalPanel, MODAL_CLS, MODAL_SIZE, showConfirmWithException } from '@UIkit/components/modal';
import {
	createHiddenField,
	createLabel,
	createLabelBlock,
	createTextField,
	createTriggerField
} from '@UIkit/components/fields';
import { openModulesForDocuments } from './methods';
import { createCarousel } from '@Ediweb/components/carousel';
import {
	createPanel,
	createContainer,
	createModalForm,
	createFieldBlock,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createDetailsModulePanel } from '@Components/panels';
import { createCheckbox } from '@UIkit/components/fields/index.js';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { tourEvents, tours } from '@Ediweb/components/tour';
import { showInfo } from '@UIkit/components/modal/MessageBox';
import { createToast } from '@UIkit/components/toast';
import { getDCRoleDependence } from '@Ediweb/methods';
import { createEmailField } from '@Components/fields';
import { createTariffGrid } from '@Ediweb/admin/modules/tariff/POA/methods';
import { createAcceptance, getAcceptance } from '@Ediweb/modules/ACCEPTANCE/acceptance';

const MAIN_MODULE_NAME = 'main';
const MAIN_TOUR_FLAG_NAME = 'user.mainHints';
const MAIN_TOUR_TARGET_1 = 'main-tour-target-1';
const MAIN_TOUR_TARGET_2 = 'main-tour-target-2';
const MAIN_TOUR_TARGET_3 = 'edi-tour-manager-show-tips-button'; //эта кнопка не в модуле, а в навигации

const BANNER_WIDTH = 292;
const BANNER_HEIGHT = 140;

Ext.namespace('edi.modules');
edi.modules[MAIN_MODULE_NAME] = function () {
	let moduleData, mainPanel, EWDocsButton, projectPanel, projectCardsPanel;
	let userData = edi.core.getUserData();
	let storeData = edi.stores.data.project_list,
		itemArr = [],
		cardArr = [];
	let sorterStoreData = [...storeData];
	sorterStoreData.sort(function (a, b) {
		return b.name === 'ediweb_docs' || b.connected - a.connected;
	});

	if (!edi.permissions.hasPermission('READ_DOCUMENTS_REST_SERVICES')) {
		sorterStoreData = sorterStoreData.filter((item) => item.name !== 'ediweb_docs');
	}

	let openDocumentModule = function () {
		edi.core.openModule('documents');
	};
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.main_product.on('change', onConnectNewProduct);
	};

	const updateProductTplData = function (cards, productsName, isConnect) {
		if (Array.isArray(cards)) {
			cards.forEach((item) => {
				if (productsName.includes(item.data.name)) {
					item.data.connected = isConnect;
					item.update(item.data);
					item.fireEvent('afterrender', item);
				}
			});
		}
	};

	const onConnectNewProduct = function (products, isConnect) {
		let productsNames = products.split(',');
		productsNames = productsNames.map((item) => item.toLowerCase());
		let itemBlocks = projectPanel.query('[cls~=edi-ediweb-project-item]');
		let cards = projectCardsPanel.query('[cls~=edi-ediweb-project-card]');
		storeData.forEach((item) => {
			if (productsNames.includes(item.name)) {
				item.connected = isConnect;
			}
		});
		updateProductTplData(itemBlocks, productsNames, isConnect);
		updateProductTplData(cards, productsNames, isConnect);
	};

	const createItemBlock = function (data) {
		let item = Ext.create('Ext.Component', {
			xtype: 'button',
			cls: 'edi-ediweb-project-item item',
			tpl: Ext.create(
				'Ext.XTemplate',
				'<tpl for=".">',
				'<a href="{[this.getConnectedLink(values)]}" target="{[this.getTarget(values)]}" class="x-btn">',
				'<div data-qtip="{[this.getTooltipString(values)]}" class="logo {name} {[this.getConnectedCls(values)]}"></div>',
				'</a>',
				'</tpl>',
				{
					getTooltipString: function (values) {
						return !values.connected && values.name !== 'ediweb_docs'
							? edi.i18n.getMessage('ediweb.product.not.connected')
							: null;
					},
					getConnectedCls: function (values) {
						return !values.connected ? 'notconnected' : '';
					},
					getConnectedLink: function (values) {
						return !values.connected ? '#' : values.product_url;
					},
					getTarget: function (values) {
						return !values.connected ? '' : '_blank';
					}
				}
			),
			data: data,
			listeners: {
				afterrender(comp) {
					setTimeout(function () {
						EWDocsButton = comp.el.dom.querySelector('a.x-btn');
						if (EWDocsButton && comp.data.name === 'ediweb_docs') {
							EWDocsButton.addEventListener('click', openDocumentModule);
						}
					}, 1);
				}
			}
		});

		return item;
	};

	const getProductUrl = function (product) {
		const productName = product.name.toUpperCase();
		const productKey = productName + '_URL';
		const productUrl = edi.stores.data.product_urls[productKey];
		return productUrl ? productUrl : '#';
	};

	const createTopProjectPanel = function () {
		let connectedProductCount = 0;
		for (let j in sorterStoreData) {
			sorterStoreData[j].product_url = getProductUrl(sorterStoreData[j]);
			connectedProductCount += sorterStoreData[j].connected ? 1 : 0;

			if (
				sorterStoreData[j].name !== 'premium_support' &&
				sorterStoreData[j].name !== 'affiliate_program' &&
				sorterStoreData[j].name !== 'edi'
			) {
				if (j <= 6) {
					itemArr.push(createItemBlock(sorterStoreData[j]));
				}
				if (connectedProductCount > 6) {
					itemArr.push(createItemBlock(sorterStoreData[j]));
				}
			}
		}

		return createPanel({
			cls: 'edi-ediweb-project-itemlist',
			layout: 'hbox',
			items: [
				createContainer({
					layout: 'hbox',
					cls: MAIN_TOUR_TARGET_2,
					items: itemArr
				})
			]
		});
	};

	const createWelcomePanel = function () {
		return createPanel({
			cls: 'edi-ediweb-main-panel',
			items: [
				createContainer({
					padding: 16,
					items: [
						createLabelBlock({
							gap: 8,
							contents: [
								{
									isBlock: true,
									typography: 'heading_02',
									text: edi.i18n.getMessage('ediweb.main.welcome.header')
								},
								{
									typography: 'body-long_01',
									color: '--color-grey-50',
									text: edi.i18n.getMessage('ediweb.main.welcome.header.subtitle')
								}
							]
						})
					]
				})
			]
		});
	};

	const getBanners = function (finishCallback) {
		const failure = function (data) {
			if (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
			} else {
				edi.core.showError('error');
			}
			typeof finishCallback === 'function' && finishCallback();
		};

		let bannerUrl = edi.utils.compileURL(edi.rest.services.ADVERTISING_TAPE.LIST.GET, {
			active: true
		});

		edi.rest.sendRequest(
			bannerUrl,
			'GET',
			undefined,
			function (responseData) {
				if (responseData && responseData.items.length) {
					const finishLoad = function (cards) {
						let sortedCards = cards.sort(function (a, b) {
							let aContainer = a.getRefItems()?.[0];
							let bContainer = b.getRefItems()?.[0];
							return aContainer?.priority - bContainer?.priority;
						});
						let banner = createCarousel({
							padding: '24 0 0 4',
							cards: sortedCards
						});

						mainPanel.insert(2, banner);
						typeof finishCallback === 'function' && finishCallback();
					};

					let cards = [];

					if (responseData.items.length) {
						responseData.items?.forEach(function (item, index) {
							let clickTime;
							let card = createContainer({
								width: BANNER_WIDTH,
								height: BANNER_HEIGHT + 8,
								listeners: {
									element: 'el',
									click: function () {
										if (new Date() - clickTime < 150) {
											if (item.urlString) {
												window.open(item.urlString, '_blank');
											}
										}
									},
									mousedown: function (e) {
										e.preventDefault();
										clickTime = new Date();
									}
								}
							});
							let id = item.id,
								contentType = item.contentType,
								priority = item.priority;
							edi.methods.advertising_tape.getContent(id, contentType, function (data) {
								let i = new Image();
								i.src = data;
								i.onload = function () {
									card.add(
										createContainer({
											width: BANNER_WIDTH,
											height: BANNER_HEIGHT,
											priority,
											cls: 'edi-ediweb-banner-img',
											html: i.outerHTML
										})
									);

									cards.push(card);

									let isAllContentLoaded = cards.length === responseData.items.length;

									if (isAllContentLoaded) {
										finishLoad(cards);
										card.setLoading(false);
									}
								};
							});
							card.setLoading();
						});
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					moduleData.tab.setLoading(false);
					typeof finishCallback === 'function' && finishCallback();
				}
			},
			failure
		);
	};

	const showConnectingModal = function (product) {
		switch (product.name) {
			case 'edi':
				checkAcceptance(product);
				break;
			case 'dropcat':
				showConnectingDropcatModal();
				break;
			case 'factorplat':
				showConnectingFactorplatModal();
				break;
			default:
				showConnectingDefaultModal(product);
				break;
		}
	};

	const createProjectCardPanel = function () {
		const createProductCard = function (data) {
			return Ext.create('Ext.Component', {
				xtype: 'container',
				cls: `edi-ediweb-project-card card ${data.name}-card`,
				tpl: Ext.create(
					'Ext.XTemplate',
					'<tpl for=".">',
					'<div class="left">' +
						'<tpl if="!!connected">' +
						'<div class="edi-state-container"> ' +
						'<div class="edi-icon edi-icon-DONE"></div>' +
						'<div class="text typography body-short_01">{[edi.i18n.getMessage(\'ediweb.connected.state\')]}</div>' +
						'</div>' +
						'</tpl>' +
						'<div class="title-container">' +
						`<div class="title typography heading_02">${edi.i18n.getMessage(
							`ediweb.product.card.title.${data.name}`
						)}</div>` +
						'</div>' +
						'<div class="content typography body-short_01">{description}</div>' +
						'<div id="{[this.getLinkId()]}" class="buttons">' +
						'</div>' +
						'</div>' +
						'<div class="logo {name}"></div>',
					'</tpl>',
					{
						getLinkId: function () {
							return data.name + '-connect-btn';
						}
					}
				),
				data: data,
				listeners: {
					afterrender: function (cmp) {
						let id = data.name + '-connect-btn';
						let connBtn;
						let isShowConnBtn = !data.connected;
						if (data.name === 'factorplat') {
							isShowConnBtn = !data.connected && edi.permissions.hasPermission('CLIENT_FP_ONBORDING');
						}
						if (isShowConnBtn) {
							connBtn = createButton({
								cls: [BUTTON_CLS.primary],
								text: edi.i18n.getMessage('ediweb.plug.btn'),
								handler: function () {
									showConnectingModal(data);
								},
								margin: '0 24 0 0'
							});
						}
						let link = createButton({
							cls: [BUTTON_CLS.secondary],
							text: edi.i18n.getMessage('ediweb.details.btn'),
							target: '_blank',
							href: data.url
						});

						createContainer({
							layout: {
								type: 'hbox',
								align: 'center',
								pack: 'start'
							},
							items: [connBtn, link],
							renderTo: id
						});
					}
				}
			});
		};

		let isFirst = true;
		for (let i in storeData) {
			if (storeData[i].name !== 'ediweb_docs' && storeData[i].name !== 'coreplat') {
				let card = createProductCard(storeData[i]);
				if (isFirst) {
					isFirst = false;
					card.addCls(MAIN_TOUR_TARGET_1);
				}
				cardArr.push(card);
			}
		}

		return createPanel({
			cls: 'edi-ediweb-project-cards typography heading_02',
			layout: 'column',
			title: edi.i18n.getMessage('ediweb.card.products'),
			items: cardArr
		});
	};

	const checkProductOrRole = function (product, putData, checkCallback) {
		const failure = function (data) {
			const productData = sorterStoreData.filter((item) => data.additionalData.includes(item.name));
			if (productData.length) {
				data.additionalData = productData.map((item) => item.label);
			}
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
		};

		const success = function (data) {
			if (data && data.data) {
				checkCallback(data.data);
			}
		};

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.PRODUCT.CHECK.PUT, {
				product: product
			}),
			'PUT',
			Ext.encode(putData.roles || []),
			success,
			failure
		);
	};
	/**
	 * Сначала дергается рест CHECK, который проверяет отправлялся ли аперак на подключение. Если нет, то дергаем
	 * рест ACTIVE, чтобы подключить продукт. Иначе - показываем сообщение:
	 * о том что запрос уже отправлен, если documents пустые, а requests не пустые
	 * о том, что продукт уже подключен, если documents не пустые, а requests пустые.
	 *
	 * Для ДК можно дополнительно доподключать роли, которые до этого не были подключены. Проверяем поли по полям
	 * connectedRoles - подключенные роли
	 * unConnectedRoles - неподключенные поли
	 * **/

	const connectHandler = function (product, putData = {}, beforeActiveProductCb) {
		const activeProduct = function (activeProductSuccess) {
			const failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
			};

			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.PRODUCT.ACTIVE.PUT, {
					product: product
				}),
				'PUT',
				Ext.encode(putData),
				activeProductSuccess,
				failure
			);
		};

		const beforeActiveProduct = function (checkData) {
			if (typeof beforeActiveProductCb === 'function') {
				beforeActiveProductCb(checkData, activeProduct);
			} else {
				activeProduct();
			}
		};

		checkProductOrRole(product, putData, beforeActiveProduct);
	};

	const defaultBeforeActiveProduct = function (checkData, showConfirm, activeProductCb, productCb, productName) {
		let documents = checkData.documents || [];
		let requests = checkData.requests || [];

		// если documents пустой, а requests не пустой, то значит аперак отправлен, но еще не подтвержден
		//если documents не пуст, значит аперак подтвержден и продукт подключен
		if (!documents.length && !requests.length) {
			showConfirm(checkData, activeProductCb);
		} else if (!documents.length && requests.length) {
			const isCustomMsgText = productName === 'dropcat' || productName === 'factorplat';
			const info = showInfo({
				isTitleMove: true,
				title: edi.i18n.getMessage('ediweb.default.connecting.product.title', [productName]),
				msgText: isCustomMsgText
					? edi.i18n.getMessage('ediweb.custom.connecting.product.info.msgText')
					: edi.i18n.getMessage('ediweb.default.connecting.product.info.msgText'),
				okBtnConfig: {
					text: edi.i18n.getMessage('btn.understand'),
					handler: function () {
						if (typeof productCb === 'function') {
							productCb();
						}
						info.close();
					}
				},
				noBtnConfig: {
					text: edi.i18n.getMessage('form.btn.cancel')
				}
			});
			return info;
		} else if (documents.length && !requests.length) {
			const info = showInfo({
				isTitleMove: true,
				title: edi.i18n.getMessage('ediweb.default.connecting.product.title', [productName]),
				msgText: edi.i18n.getMessage('ediweb.default.connecting.product.info.msgText.buy'),
				okBtnConfig: {
					text: edi.i18n.getMessage('ediweb.open.account.btn'),
					handler: function () {
						openModulesForDocuments(documents);
						if (typeof productCb === 'function') {
							productCb();
						}
						info.close();
					}
				}
			});
			return info;
		}
	};

	const showConnectingDefaultModal = function (product, config = {}) {
		let connectingModal, connectingForm;

		const fullname = userData.patronymicName
			? `${userData.lastName} ${userData.firstName} ${userData.patronymicName}`
			: `${userData.firstName} ${userData.lastName}`;

		const label = createLabel({
			typography: 'body-short_01',
			text: config.subtitle ?? edi.i18n.getMessage('ediweb.connecting.product.form.label')
		});

		let fio = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('full.name.abbr'),
			items: [
				createTextField({
					value: fullname,
					allowBlank: false,
					name: 'initiator'
				})
			]
		});

		let email = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('Email'),
			items: [
				createEmailField({
					value: userData.eMail,
					allowBlank: false,
					name: 'email'
				})
			]
		});
		let phone = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('user.profile.phone'),
			items: [
				createTextField({
					value: userData.phones,
					name: 'telephone'
				})
			]
		});
		let company = createHiddenField({
			value: userData?.org?.name,
			name: 'company'
		});

		let comment = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('ediweb.invitations.comment'),
			items: [
				createTextField({
					isTextarea: true,
					name: 'comment'
				})
			]
		});

		connectingForm = createModalForm({
			layout: {
				type: 'grid',
				gap: [24]
			},
			items: [label, fio, phone, email, comment, company]
		});
		let connectBtn = createButton({
			cls: BUTTON_CLS.primary,
			text: edi.i18n.getMessage('ediweb.lk.send.request'),
			handler: config.customConnectingHandler
				? function () {
						config.customConnectingHandler(connectingModal, connectingForm, config.additionalData);
				  }
				: function () {
						let formValues = connectingForm.getValues();
						if (connectingForm.isValid()) {
							connectHandler(product.name, formValues, (checkData, activeProductCb) => {
								return defaultBeforeActiveProduct(
									checkData,
									function (checkData, activeProductCb) {
										connectingModal.close();
										return activeProductCb(() => {
											createToast({
												type: 'info',
												titleContent: edi.i18n.getMessage(
													'ediweb.connecting.product.toast.title',
													[product.label]
												),
												content: edi.i18n.getMessage('ediweb.connecting.product.toast.content')
											});
										});
									},
									activeProductCb,
									function () {
										connectingModal.close();
									},
									product.label
								);
							});
						}
				  }
		});
		connectingModal = createModalPanel({
			width: MODAL_SIZE.widthSmall,
			isTitleMove: true,
			title: config.title ?? edi.i18n.getMessage('ediweb.connecting.product.title', [product.label]),
			items: [connectingForm],
			buttons: [connectBtn]
		});
		connectingModal.show();
	};

	const showTariffsModal = function (promocodeData, product) {
		let connectingModal, applyTariff;

		let tariff = promocodeData?.tariff || promocodeData;

		const infoLabel = createLabel({
			typography: 'body-short_01',
			text: edi.i18n.getMessage('ediweb.connecting.product.edi.info')
		});

		const tariffGrid = createTariffGrid(tariff);

		const promocodeInput = createTriggerField({
			fieldLabel: edi.i18n.getMessage('ediweb.tariff.promocodeInput'),
			name: 'code',
			triggers: {
				close: {
					glyph: edi.constants.ICONS.CLOSE,
					handler: function (comp) {
						comp.setValue();
						promocodeInfoLabel.setVisible(false);
						promocodeInfoErrorLabel.setVisible(false);
					}
				}
			},
			listeners: {
				change: function () {
					promocodeInfoLabel.setVisible(false);
					promocodeInfoErrorLabel.setVisible(false);
				}
			}
		});

		const promocodeInfoLabel = createLabel({
			typography: 'caption_01',
			hidden: true,
			color: '--color-grey-50',
			text: edi.i18n.getMessage('ediweb.connecting.product.promocode.info.activated')
		});

		const promocodeInfoErrorLabel = createLabel({
			typography: 'caption_01',
			color: '--color-error',
			hidden: true,
			text: ''
		});

		const infoContainer = createContainer({
			cls: 'edi-ediweb-promocode-info-container',
			layout: {
				type: 'grid',
				gap: [0]
			},
			items: [promocodeInfoLabel, promocodeInfoErrorLabel]
		});

		const applyPromocode = function (code) {
			const failure = function (data) {
				promocodeInfoLabel.setVisible(false);
				promocodeInfoErrorLabel.setVisible(true);
				if (data.typeError === 'controller.promo.code.cannot.create.promo.code.for.expired.tariff.error') {
					data.typeError = 'controller.promo.code.cannot.create.promo.code.for.expired.tariff.error.client';
				}
				promocodeInfoErrorLabel.setText(edi.i18n.getMessage(data.typeError));
			};

			const onSuccess = function (responseData) {
				if (responseData && responseData.data) {
					applyTariff = responseData.data;
					promocodeInfoLabel.setVisible(true);
					promocodeInfoErrorLabel.setVisible(false);
					const newTariffs = Array.isArray(responseData.data?.details) ? responseData.data?.details : [];
					const tariffGridStore = tariffGrid.getStore();
					if (newTariffs.length) {
						newTariffs.sort(function (a, b) {
							return +a.documentsCost - +b.documentsCost;
						});
						tariffGridStore.removeAll();
						tariffGridStore.loadData(newTariffs);
					}
				}
			};
			edi.rest.sendRequest(
				edi.rest.services.PROMOCODES.APPLY.POST,
				'POST',
				Ext.encode({ code: code }),
				onSuccess,
				failure
			);
		};

		const applyBtn = createButton({
			cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
			flex: 1,
			padding: '8 0 0 0',
			text: edi.i18n.getMessage('form.btn.apply'),
			handler: function () {
				applyPromocode(promocodeInput.getValue());
			}
		});

		const connectBtn = createButton({
			cls: [BUTTON_CLS.primary],
			text: edi.i18n.getMessage('ediweb.plug.btn'),
			handler: function () {
				const tariffId = applyTariff ? applyTariff.id : tariff.id;
				const url = edi.utils.formatString(
					edi.rest.services.ACCEPTANCE.CLIENT.CREATE.POST,
					{
						tariffId: tariffId
					},
					true
				);

				const putData = {};

				if (applyTariff?.promoCodeId) {
					putData.promoCodeId = applyTariff.promoCodeId;
				} else if (promocodeData?.id && promocodeData?.tariffType !== 'BASIC') {
					putData.promoCodeId = promocodeData.id;
				}

				const acceptanceOptions = {
					isShowFromMain: true,
					urlMethod: 'POST',
					urlParams: Ext.encode(putData),
					importByGuidUrl: edi.rest.services.ACCEPTANCE.CLIENT.IMPORT_BY_GUID.POST,
					signContentUrl: edi.rest.services.ACCEPTANCE.CLIENT.SIGN.GET,
					signUrl: edi.rest.services.ACCEPTANCE.CLIENT.SIGN.PUT,
					onSuccess: function (resp, acceptance) {
						if (resp.data === true) {
							return true;
						}
						if (
							!(
								resp.data?.state === edi.constants.STATE.DRAFT ||
								resp.data?.state === edi.constants.STATE.SENDER_REVIEW
							)
						) {
							showLastStepModal(resp.data);
						} else {
							acceptance.showModal();
						}
					},
					modalLabels: {
						title: edi.i18n.getMessage('ediweb.acceptance.window.title.cp'),
						content: 'ediweb.acceptance.window.content.cp'
					}
				};
				createAcceptance(showLastStepModal, url, acceptanceOptions);

				connectingModal.close();
			}
		});

		const cancelBtn = createButton({
			cls: [BUTTON_CLS.secondary],
			text: edi.i18n.getMessage('btn.cancel'),
			handler: function () {
				connectingModal.close();
			}
		});

		const callBtn = createButton({
			cls: [BUTTON_CLS.secondary],
			text: edi.i18n.getMessage('ediweb.connecting.product.title.edi.call'),
			handler: function () {
				connectingModal.close();
				showConnectingDefaultModal(product, {
					title: edi.i18n.getMessage('ediweb.connecting.product.title.edi.call'),
					subtitle: edi.i18n.getMessage('ediweb.connecting.product.subtitle.edi.call'),
					customConnectingHandler: customEdiConnectingHandler
				});
			}
		});

		const connectingForm = createModalForm({
			layout: {
				type: 'grid',
				gap: [16, 16],
				area: [12, 12, [5, 3], 12]
			},
			items: [infoLabel, tariffGrid, promocodeInput, applyBtn, infoContainer]
		});

		connectingModal = createModalPanel({
			width: MODAL_SIZE.widthMedium,
			isTitleMove: true,
			title: edi.i18n.getMessage('ediweb.connecting.product.title.edi'),
			items: [connectingForm],
			buttons: [connectBtn, cancelBtn, callBtn]
		});
		connectingModal.show();
	};

	const customEdiConnectingHandler = function (connectingModal, connectingForm, acceptanceData) {
		const failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
		};

		const onSuccess = function () {
			createToast({
				type: 'success',
				showCloseBtn: false,
				titleContent: edi.i18n.getMessage('ediweb.connecting.product.toast.title.no.product'),
				content: edi.i18n.getMessage('ediweb.connecting.product.toast.content')
			});
			connectingModal?.close();
		};

		let formValues = connectingForm.getValues();

		const putData = {
			acceptanceId: acceptanceData?.data?.id || acceptanceData?.id,
			comment: formValues.comment,
			phone: formValues.telephone,
			email: formValues.email,
			initiator: formValues.initiator
		};
		if (connectingForm.isValid()) {
			edi.rest.sendRequest(
				edi.rest.services.ACCEPTANCE.CLIENT.CREATE_APERAK.POST,
				'POST',
				Ext.encode(putData),
				onSuccess,
				failure
			);
		}
	};

	const showLastStepModal = function (acceptanceData) {
		showConnectingDefaultModal('edi', {
			title: edi.i18n.getMessage('ediweb.connecting.product.title.last.step'),
			subtitle: edi.i18n.getMessage('ediweb.connecting.product.title.edi.last.step'),
			additionalData: acceptanceData,
			customConnectingHandler: customEdiConnectingHandler
		});
	};

	const checkAcceptance = function (product) {
		const failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
		};

		const onCheckSuccess = function (responseData) {
			if (
				responseData &&
				(responseData.data === true || responseData.data?.state !== edi.constants.STATE.COMPLETED)
			) {
				showConnectingEdiModal(product);
			} else {
				showLastStepModal(responseData);
			}
		};
		edi.rest.sendRequest(edi.rest.services.ACCEPTANCE.CLIENT.GET, 'GET', null, onCheckSuccess, failure);
	};

	const showConnectingEdiModal = function (product) {
		const failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
		};

		const getBasicTariffs = function (callback, product) {
			const onSuccess = function (responseData) {
				if (responseData && responseData.items.length) {
					callback(responseData.items[0], product, true);
				}
			};
			edi.rest.sendRequest(edi.rest.services.PROMOCODES.TARIFF.BASIC.GET, 'GET', null, onSuccess, failure);
		};

		const onCheckSuccess = function (responseData) {
			if (responseData && responseData.items.length) {
				showTariffsModal(responseData.items?.[0], product);
			} else {
				getBasicTariffs(showTariffsModal, product);
			}
		};
		edi.rest.sendRequest(edi.rest.services.PROMOCODES.CHECK.GET, 'GET', null, onCheckSuccess, failure);
	};

	const showConnectingDropcatModal = function () {
		let rolesArray = [
			'client_by_te',
			'forwarder',
			'consignor',
			'consignee',
			'person_from',
			'carrier',
			'medical_org'
		];
		let rolesCheckboxArray = [],
			connectingModal,
			modal,
			connectBtn;
		rolesArray.forEach(function (role) {
			let checkbox = createCheckbox({
				name: role,
				boxLabel: Ext.String.format(
					'<b class="edi-ediweb-connecting-dropcat-modal-checkbox-title">{0}</b>' +
						'<div class="edi-ediweb-connecting-dropcat-modal-checkbox-subtitle">{1}</div>',
					edi.i18n.getMessage('ediweb.invitations.' + role),
					edi.i18n.getMessage('ediweb.invitations.subtitle.' + role)
				),
				inputValue: true,
				uncheckedValue: false,
				listeners: {
					change: function (comp, checked) {
						const isSomeChecked = rolesCheckboxArray.some((item) => item.checked);
						const checkedArray = rolesCheckboxArray.filter((item) => item.checked);
						let ids = checkedArray.map((item) => item.name);

						connectBtn.setDisabled(!isSomeChecked);
						let fields = connectingForm?.getForm()?.getFields()?.items;

						if (Array.isArray(ids)) {
							let dependenceIds = getDCRoleDependence(ids);

							fields.forEach((field) => {
								const isDisabled = dependenceIds.some((it) => it === field.getName());
								field.setDisabled(isDisabled);
							});
						}
					}
				}
			});
			rolesCheckboxArray.push(checkbox);
		});
		let modalItems = [
			createLabel({
				typography: 'body-short_03',
				html: edi.i18n.getMessage('ediweb.connecting.dropcat.label')
			})
		];
		modalItems = modalItems.concat(rolesCheckboxArray);

		const successModal = function (isOpenDSF, documents) {
			let modal, contents;
			let contractBtn = createButton({
				cls: [BUTTON_CLS.primary],
				text: edi.i18n.getMessage('ediweb.connecting.dropcat.contract.btn'),
				handler: function () {
					openModulesForDocuments(documents);
					modal.close();
				}
			});

			contents = createModalForm({
				layout: {
					type: 'grid',
					gap: [24]
				},
				items: []
			});

			if (isOpenDSF) {
				documents.forEach(function (doc) {
					let item = createLabel({
						typography: 'body-short_01',
						html: edi.i18n.getMessage('ediweb.connecting.dropcat.contract.item', [
							edi.i18n.getMessage('documents.doctype.' + doc.type)
						])
					});
					contents.add(item);
				});
				let contract = createLabel({
					typography: 'body-short_01',
					text: edi.i18n.getMessage('ediweb.connecting.dropcat.contract')
				});
				contents.add(contract);
			} else {
				let item = createLabel({
					typography: 'body-short_01',
					text: edi.i18n.getMessage('ediweb.connecting.dropcat.soon')
				});
				contents.add(item);
			}

			modal = createModalPanel({
				title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
				items: contents,
				isTitleMove: true,
				closeButtonOptions: {
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('button.ok'),
					hidden: isOpenDSF,
					glyph: edi.constants.ICONS.DONE,
					handler: function () {
						modal.close();
						if (documents && documents.length) {
							successModal(true, documents);
						}
					}
				},
				buttonsBefore: [isOpenDSF ? contractBtn : null]
			});
			modal.show();
		};

		connectBtn = createButton({
			cls: BUTTON_CLS.primary,
			disabled: true,
			text: edi.i18n.getMessage('ediweb.plug.btn'),
			handler: function () {
				/* этот код потребуется в следующих версиях
				const showConfirm = function (connectedRoles, unConnectedRoles) {
					let exceptionText = null,
						msgText = null;

					if (!connectedRoles.length && unConnectedRoles.length) {
						connectingModal.close();
						return createToast({
							type: 'info',
							titleContent: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.title'),
							content: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.content')
						});
					}

					if (connectedRoles.length > 0) {
						const connectedRolesTextWord = edi.i18n.decline(connectedRoles.length, 'roleRodPadezh');
						let connectedRolesTranslateArray = connectedRoles.map((role) =>
							edi.i18n.getMessage(`ediweb.invitations.${role}`)
						);
						exceptionText = edi.i18n.customTranslation(
							'custom.ediweb.connecting.dropcat.exceptionText',
							{
								rolesWord: connectedRolesTextWord,
								roles: connectedRolesTranslateArray.join(', ')
							},
							edi.i18n.getMessage('ediweb.connecting.dropcat.exceptionText')
						);
					}

					if (unConnectedRoles.length > 0) {
						const unConnectedRolesTextWord = edi.i18n.decline(unConnectedRoles.length, 'roleRodPadezh');
						let unConnectedRolesTranslateArray = unConnectedRoles.map((role) =>
							edi.i18n.getMessage(`ediweb.invitations.${role}`)
						);

						msgText = edi.i18n.customTranslation(
							'custom.ediweb.connecting.dropcat.msgText',
							{
								rolesWord: unConnectedRolesTextWord,
								roles: unConnectedRolesTranslateArray.join(', ')
							},
							edi.i18n.getMessage('ediweb.connecting.dropcat.msgText')
						);
					}

					showConfirmWithException({
						title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
						exceptionType: 'warning',
						exceptionText: exceptionText,
						msgText: msgText,
						yesBtnConfig: {
							text: edi.i18n.getMessage('btn.understand')
						},
						noBtnConfig: {
							hidden: true
						},
						success: () => {
							connectingModal.close();
						}
					});
				};
				 */

				let formValues = connectingForm.getValues();
				let data = [];
				Object.entries(formValues).forEach(function (key) {
					let value = key[1];
					if (value) {
						data.push(key[0]);
					}
				});
				const putData = {
					roles: data,
					initiator: `${userData.lastName} ${userData.firstName}`,
					email: userData.eMail,
					telephone: userData.phones,
					company: userData?.org?.name
				};

				/* этот код потребуется в следующих версиях
				const beforeActiveProduct = function (checkData, activeProductCb) {
					const roles = putData.roles;
					let connectedRoles = checkData.connectedRoles || [];
					let unConnectedRoles = checkData.unConnectedRoles || [];
					connectedRoles = connectedRoles.filter((role) => {
						return roles.includes(role);
					});
					unConnectedRoles = unConnectedRoles.filter((role) => {
						return roles.includes(role);
					});

					if (connectedRoles.length && !unConnectedRoles.length) {
						showConfirm(connectedRoles, unConnectedRoles);
					} else {
						return activeProductCb(() => {
							showConfirm(connectedRoles, unConnectedRoles);
						});
					}
				};
				*/

				const beforeActiveProduct = function (checkData, activeProductCb) {
					let documents = checkData.documents || [];
					let requests = checkData.requests || [];

					const showSuccessToast = function () {
						connectingModal.close();
						return createToast({
							type: 'info',
							titleContent: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.title'),
							content: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.content')
						});
					};

					// если documents пустой, а requests не пустой, то значит аперак отправлен, но еще не подтвержден
					//если documents не пуст, значит аперак подтвержден и продукт подключен
					if (!documents.length && !requests.length) {
						return activeProductCb(showSuccessToast);
					} else if (requests.length) {
						let confirm = showConfirmWithException({
							title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
							exceptionType: 'warning',
							exceptionText: edi.i18n.getMessage('ediweb.connecting.dropcat.exceptionText.msg'),
							yesBtnConfig: {
								text: edi.i18n.getMessage('btn.wait')
							},
							noBtnConfig: {
								text: edi.i18n.getMessage('btn.reconnect')
							},
							success: () => {
								connectingModal.close();
							},
							failure: () => {
								const resendProductSuccess = function () {
									showSuccessToast();
									confirm.close();
								};
								const failure = function (data) {
									edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
								};
								edi.rest.sendRequest(
									edi.utils.formatString(edi.rest.services.PRODUCT.RESEND.PUT, {
										product: 'dropcat'
									}),
									'PUT',
									Ext.encode(putData),
									resendProductSuccess,
									failure
								);
								connectingModal.close();
							}
						});
					}
				};

				connectHandler('dropcat', putData, beforeActiveProduct);
			}
		});

		let connectingForm = createModalForm({
			layout: {
				type: 'grid',
				gap: [24]
			},
			items: modalItems
		});
		connectingModal = createModalPanel({
			cls: [MODAL_CLS.withShadows, 'edi-ediweb-connecting-dropcat-modal'],
			width: MODAL_SIZE.widthMedium,
			isTitleMove: true,
			title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
			items: [connectingForm],
			buttons: [
				connectBtn,
				createButton({
					cls: BUTTON_CLS.secondary,
					text: edi.i18n.getMessage('ediweb.cancel.btn'),
					handler: function () {
						connectingModal.close();
					}
				})
			]
		});
		connectingModal.show();
	};

	const showConnectingFactorplatModal = function () {
		const showConfirm = function (checkData, activeProductCb) {
			let confirm;
			return (confirm = showConfirmWithException({
				title: edi.i18n.getMessage('ediweb.connecting.factorplat.title'),
				exceptionType: 'warning',
				exceptionText: edi.i18n.getMessage('ediweb.connecting.factorplat.exceptionText'),
				msgText: edi.i18n.getMessage('ediweb.connecting.factorplat.msgText'),
				yesBtnConfig: {
					text: edi.i18n.getMessage('ediweb.plug.btn')
				},
				noBtnConfig: {
					text: edi.i18n.getMessage('btn.cancel')
				},
				success: () => {
					return activeProductCb(() => {
						const info = showInfo({
							isTitleMove: true,
							title: edi.i18n.getMessage('ediweb.default.connecting.product.title', ['FactorPlat']),
							msgText: edi.i18n.getMessage('ediweb.default.connecting.product.info.msgText'),
							okBtnConfig: {
								text: edi.i18n.getMessage('btn.understand'),
								handler: function () {
									info.close();
								}
							}
						});
						confirm.close();
					});
				}
			}));
		};

		const putData = {
			roles: [],
			initiator: `${userData.lastName} ${userData.firstName}`,
			email: userData.eMail,
			telephone: userData.phones,
			company: userData?.org?.name
		};

		connectHandler('factorplat', putData, (checkData, activeProductFn) => {
			return defaultBeforeActiveProduct(checkData, showConfirm, activeProductFn, null, 'FactorPlat');
		});
	};

	const createLayout = function (finishCallback) {
		getBanners(finishCallback);

		mainPanel = createPanel({
			cls: 'edi-ediweb-gray-panel',
			bodyPadding: 20,
			autoScroll: true,
			items: [
				createWelcomePanel(),
				(projectPanel = createTopProjectPanel()),
				(projectCardsPanel = createProjectCardPanel())
			]
		});

		return mainPanel;
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const finishCreation = function () {
			if ('function' == typeof initCallBack) {
				initCallBack();
				if (edi.core.getExtraData(MAIN_TOUR_FLAG_NAME) !== 'true') {
					setTimeout(() => {
						if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
							tourEvents.fireEvent('start_tour', MAIN_MODULE_NAME, moduleData);
						}
					}, 500);
				}
			}
		};

		const modulePanel = createDetailsModulePanel();
		modulePanel.add(createLayout(finishCreation));
		moduleData.tab.add(modulePanel);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		edi.events.main_product.un('change', onConnectNewProduct);

		EWDocsButton.remove('click', openDocumentModule);
		return true;
	};
};

tours[MAIN_MODULE_NAME] = {
	getName: () => MAIN_MODULE_NAME,
	createSteps: () => [
		{
			getTitle: () => edi.i18n.getMessage('main.tour.step.1.title'),
			getContent: () => edi.i18n.getMessage('main.tour.step.1.content'),
			getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${MAIN_TOUR_TARGET_1}`),
			position: 'right'
		},
		{
			getTitle: () => edi.i18n.getMessage('main.tour.step.2.title'),
			getContent: () => edi.i18n.getMessage('main.tour.step.2.content'),
			getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${MAIN_TOUR_TARGET_2}`),
			position: 'right'
		},
		{
			getTitle: () => edi.i18n.getMessage('main.tour.step.3.title'),
			getContent: () => edi.i18n.getMessage('main.tour.step.3.content'),
			getTargetEl: (tour) => document.querySelector(`.${MAIN_TOUR_TARGET_3}`),
			position: 'right-top'
		}
	],
	onTourClosed: function () {
		const tour = this;
		//поставим флаг, что юзер прошел все страницы тура
		if (tour.currentStepNum === tour.steps.length - 1) {
			edi.core.setExtraData(MAIN_TOUR_FLAG_NAME, 'true');
		}
	}
};
