import './fieldError.scss';

Ext.override(UI.plugin.fieldError, {
	reservedSpaceCls: 'edi-plugin-bottom-reserve',
	reserveSpace: false,
	additionalTextCls: 'ui-field-status-additional',
	additionalText: '',

	init: function (field) {
		const plugin = this;
		plugin.callParent(arguments);

		if (plugin.isReserveSpaceEnabled(field)) {
			field.on('validitychange', function () {
				plugin.onMandatory(field);
			});
		}
	},

	isReserveSpaceEnabled(cmp) {
		let plugin = this;
		return cmp.hasOwnProperty('reserveSpace') ? cmp.reserveSpace === true : plugin.reserveSpace === true;
	},

	fireUpdateLayout: function (field) {
		if (typeof field.updateLayout === 'function') {
			//после отображения текста "обязательно для заполнения" на валидном поле (пишем напрямую в DOM)
			//поэтому обновим layout поля, т.к. текст может быть как на 1 так и на несколько строк, а Экст об
			//этом ничего не знает и надо его пнуть
			field.updateLayout();
		}
	},

	onMandatory: function (field) {
		const plugin = this;

		if (!!field.preventMark || !field.ariaStatusEl) {
			return;
		}

		let isEmpty = Ext.isEmpty(field.getValue()),
			errors = field.getErrors(),
			isError = !!errors?.length,
			isMandatory = field.hasOwnProperty('allowBlank') && !field.allowBlank,
			showAdditionalMsg = field.hasOwnProperty('additionalText') && !!field.additionalText,
			showRequiredMsg = field.hasOwnProperty('showRequiredMsg') ? field.showRequiredMsg : plugin.showRequiredMsg;

		if (field.readOnly || field.disabled) isMandatory = false;

		field[!isEmpty && isError ? 'addCls' : 'removeCls'](plugin._invalidFieldCls);
		field.ariaStatusEl[!isMandatory && showRequiredMsg && !isError ? 'addCls' : 'removeCls'](
			plugin._hiddenOffsetsCls
		);
		field.ariaStatusEl[showAdditionalMsg && !isError ? 'addCls' : 'removeCls'](plugin.additionalTextCls);

		if (isMandatory) {
			if (
				showRequiredMsg &&
				!isError &&
				field.ariaStatusEl.dom.innerHTML !== Ext.String.htmlEncode(field.blankText)
			) {
				field.ariaStatusEl.dom.innerHTML = Ext.String.htmlEncode(field.blankText);
				plugin.fireUpdateLayout(field);
			}

			field.ariaStatusEl[showRequiredMsg ? 'addCls' : 'removeCls'](plugin._requiredStatusCls);
			field.ariaStatusEl[isEmpty ? 'addCls' : 'removeCls'](plugin._errorStatusCls);
			field[isEmpty ? 'addCls' : 'removeCls'](plugin._requiredFieldCls);
		} else {
			field.removeCls(plugin._requiredFieldCls);
		}

		if (isError) {
			field.ariaStatusEl[isError ? 'addCls' : 'removeCls'](plugin._errorStatusCls);
		}

		if (
			showAdditionalMsg &&
			!isError &&
			field.ariaStatusEl.dom.innerHTML !== Ext.String.htmlEncode(field.additionalText)
		) {
			field.ariaStatusEl.dom.innerHTML = Ext.String.htmlEncode(field.additionalText);
			plugin.fireUpdateLayout(field);
			field.ariaStatusEl[showRequiredMsg ? 'addCls' : 'removeCls'](plugin._requiredStatusCls);
			field.ariaStatusEl[isEmpty ? 'addCls' : 'removeCls'](plugin._errorStatusCls);
			field[isEmpty ? 'addCls' : 'removeCls'](plugin._requiredFieldCls);
		}

		let reserve = plugin.isReserveSpaceEnabled(field) && !field.getActiveError() && !showAdditionalMsg;
		if (plugin.isReserveSpaceEnabled(field)) {
			plugin.fireUpdateLayout(field);
		}
		field[reserve ? 'addCls' : 'removeCls'](plugin.reservedSpaceCls);
	}
});
