// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
	// @ts-ignore
} from '@UIkit/components/grid';
// @ts-ignore
import { showConfirm } from '@UIkit/components/modal/MessageBox';
import { filterMethods } from './filter';
import { DOCUMENT_PACKAGES_COLUMNS_NAME } from './columns';
import { DOCUMENT_PACKAGES_MODEL_CONFIG_NAME } from './models';
import { createModulePanel } from '@Components/panels';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { documentPackageApi, DocumentPackageHeader } from '@Edi/modules/documentPackages/entities';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';

export class PackagesGridModule {
	filterObject: AnyObject;
	moduleData: ModuleData<unknown>;
	filterForm: ExtComponent;
	grid: ExtComponent;
	actionsToolbar: ExtComponent | null;
	_gridDataChangedHandler: PackagesGridModule['gridDataChangedHandler'];
	_fireSearch: PackagesGridModule['fireSearch'];

	constructor() {
		const me = this;
		me._gridDataChangedHandler = me.gridDataChangedHandler.bind(me);
		me._gridDataChangedHandler = me.gridDataChangedHandler.bind(me);
		me._fireSearch = me.fireSearch.bind(me);
	}

	/**
	 * Module initialization
	 */
	init(data: ModuleData<DocumentHeader>, initCallBack: Function): () => boolean {
		const me = this;
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		documentPackageMethods.eventsObserver.on('uploadNewDocument', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.on('update', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.on('changeDocuments', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.on('delete', me._gridDataChangedHandler);
	}

	setModuleLoading(loading: boolean | string | AnyObject) {
		const me = this;
		!me.moduleData.tab.destroyed && me.moduleData.tab.setLoading(loading);
	}

	fireSearch() {
		const me = this;
		if (!me.filterObject) {
			me.filterObject = edi.filters.createGridFilter(
				edi.utils.compileURL(documentPackageApi.endpoints.GET_PACKAGES, {
					gridName: 'all'
				}),
				me.filterForm,
				me.grid,
				filterMethods.createArgsPackages,
				null,
				{
					persistence: {
						enabled: true,
						name: `${me.moduleData.modName}_packages_grids`
					}
				}
			);
		}
		me.filterObject.filter();
	}

	/**
	 * Creates filter form
	 */
	createFilterForm(): ExtComponent {
		const me = this;
		const { formItemsMap, items } = filterMethods.createPackagesFormItems();
		return createModuleFilterForm(
			{
				usePlaceholderWrapper: true,
				formItemsMap,
				items
			},
			me._fireSearch
		) as ExtComponent;
	}

	deleteActionHandler(rowSelected: ExtRecord<DocumentHeader>[]) {
		const me = this;
		showConfirm({
			msgText: 'documents.package.delete.confirm',
			success: async function () {
				me.setModuleLoading(true);
				const deletedIds: number[] = [];
				for (let row of rowSelected) {
					const packageId: number = row.get('id');
					const { success, response } = await documentPackageApi.deletePackage(packageId);
					if (success) {
						deletedIds.push(packageId);
					} else {
						edi.rest.getErrorHandler()(response);
					}
				}
				deletedIds.forEach((id) =>
					documentPackageMethods.eventsObserver.fireEvent('delete', { packageId: id })
				);
				me.setModuleLoading(false);
			}
		});
	}

	createActionHandler() {
		edi.document.actions.openCreateModule(edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE);
	}

	async mergeActionHandler(rowSelected: ExtRecord<DocumentHeader>[]) {
		const packageIds = rowSelected.map((rec) => rec.get('id'));
		const { success, response } = await documentPackageApi.mergePackages(packageIds);
		if (success) {
			packageIds.forEach((packageId) => documentPackageMethods.eventsObserver.fireEvent('delete', { packageId }));
		} else {
			edi.rest.getErrorHandler()(response);
		}
	}

	groupActionsItemClick(id: string) {
		const me = this;
		let rowSelected: ExtRecord<DocumentHeader>[] = me.grid.getSelectionModel().selected?.items ?? [];
		if (edi.constants.DOCUMENT_ACTIONS.DELETE === id) {
			me.deleteActionHandler(rowSelected);
		} else if (edi.constants.DOCUMENT_ACTIONS.CREATE === id) {
			me.createActionHandler();
		} else if ('MERGE' === id) {
			me.mergeActionHandler(rowSelected);
		}
	}

	createGroupActionItems(): AnyObject[] {
		return [
			{
				id: edi.constants.DOCUMENT_ACTIONS.CREATE,
				name: edi.i18n.getMessage('form.btn.create'),
				notDisabled: edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT'),
				isActionDisabled: () => !edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
			},
			{
				id: 'MERGE',
				name: edi.i18n.getMessage('documentPackage.action.merge'),
				additionalData: {
					glyph: edi.constants.ICONS.LIBRARY_ADD
				},
				isActionDisabled: (btn: ExtComponent, selected: ExtRecord<DocumentPackageHeader>[]) => {
					if (!edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT') || selected.length < 2) {
						return true;
					}
					const type = selected[0].get('type');
					const selectedAreSameType = selected.every((rec) => rec.get('type') === type);
					const selectedAreDraft = selected.every((rec) => rec.get('state') === edi.constants.STATE.DRAFT);
					return !(selectedAreSameType && selectedAreDraft);
				}
			},
			{
				id: edi.constants.DOCUMENT_ACTIONS.DELETE,
				name: edi.i18n.getMessage('form.btn.delete'),
				isActionDisabled: () => !edi.permissions.hasPermission('DELETE_PACKAGE_OBJECT')
			}
		];
	}

	createGridActionsToolbar(): PackagesGridModule['actionsToolbar'] {
		const me = this;
		me.actionsToolbar = createGridActionBar({
			actionCfgs: me.createGroupActionItems(),
			defaultHandler: me.groupActionsItemClick.bind(me)
		}) as ExtComponent | null;
		return me.actionsToolbar;
	}

	/**
	 * Initializes grid
	 */
	createGridFn(): ExtComponent {
		const me = this;
		const columns = edi.columns.get(DOCUMENT_PACKAGES_COLUMNS_NAME);
		const actions = me.createActionColumn();
		if (actions !== null) {
			columns.push(actions);
		}

		const totalsLabel = createLabel({
			text: edi.i18n.getMessage('records.selected.totals', {
				selectedRows: 0
			}),
			cls: 'edi-tbar-total-label-right'
		});

		const topBar = me.createGridActionsToolbar();

		return createGrid({
			saveSorters: true,
			savedSortersName: 'document_packages',
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel(DOCUMENT_PACKAGES_MODEL_CONFIG_NAME),
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				layout: 'fit',
				border: 1,
				listeners: {
					celldblclick: function (
						_view: ExtComponent,
						_td: unknown,
						_cellIndex: number,
						record: ExtRecord<DocumentHeader>
					) {
						edi.document.actions.openDetailsModule(
							edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE,
							record.getData()
						);
					}
				},
				selModel: topBar?.menuActionItemsCnt
					? createGridCheckboxSelectionModel({
							topBar: topBar,
							selectionProcessor: function (
								selModel: ExtComponent,
								selected: ExtRecord<DocumentHeader>[],
								actionItems: ExtComponent[]
							) {
								edi.methods.gridSelModules(selModel, selected, actionItems, topBar, totalsLabel);
							}
					  })
					: undefined,
				tbar: topBar
			},
			pagingBarConfig: {
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
			}
		}) as ExtComponent;
	}

	/**
	 * Creates action column of the grid
	 */
	createActionColumn() {
		const items = [
			{
				glyph: edi.constants.ICONS.DETAILS,
				testCls: 'test-action-column-details',
				handler: function (
					_view: ExtComponent,
					_rowIndex: number,
					_colindex: number,
					_actionItem: unknown,
					_event: unknown,
					record: ExtRecord<DocumentHeader>
				) {
					edi.document.actions.openDetailsModule(
						edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE,
						record.getData()
					);
				}
			},
			{
				glyph: edi.constants.ICONS.EDIT,
				testCls: 'test-action-column-edit',
				handler: function (
					_view: ExtComponent,
					_rowIndex: number,
					_colindex: number,
					_actionItem: unknown,
					_event: unknown,
					record: ExtRecord<DocumentHeader>
				) {
					edi.document.actions.openCreateModule(
						edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE,
						record.getData(),
						true
					);
				},
				isActionDisabled: function (
					_view: ExtComponent,
					_rowIndex: number,
					_colindex: number,
					_actionItem: unknown,
					record: ExtRecord<DocumentHeader>
				) {
					const checkOptions = edi.action.getDocumentData(
						edi.models.createInstance('DOCUMENT', record.getData())
					);
					checkOptions.docType = edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE;
					checkOptions.packageHeader = record.getData();
					return !edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.EDIT, checkOptions);
				}
			}
		];
		return createActionsColumnConfig({
			items: items,
			width: edi.utils.getActionColumnWidth(items.length)
		});
	}

	/**
	 * Renders page layout
	 */
	renderData(initCallBack?: Function) {
		const me = this;
		const modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		}) as ExtComponent;

		me.grid = me.createGridFn();
		me.filterForm = me.createFilterForm() as ExtComponent;
		modulePanel.add(me.filterForm);

		modulePanel.add(me.grid);
		me.moduleData.tab.add(modulePanel);

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
		me._fireSearch();
	}

	/**
	 * Reloads grid and resets filter form
	 */
	gridDataChangedHandler() {
		const me = this;
		me.fireSearch();
	}

	/**
	 * Routine that must be done before module destroy
	 */
	onDestroy() {
		const me = this;
		if (me.filterObject && me.filterObject.searchTimeout) {
			clearTimeout(me.filterObject.searchTimeout);
		}
		documentPackageMethods.eventsObserver.un('uploadNewDocument', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.un('update', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.un('changeDocuments', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.un('delete', me._gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

edi.modules['document.packages'] = PackagesGridModule;
edi.modulesCfg['document.packages'] = {
	title: 'navigation.document.packages',
	modName: 'document.packages',
	folder: 'edi/modules/documentPackages',
	permissions: ['READ_PACKAGE_OBJECT']
};
