export const documentPackageSelectors = {
	getFieldsMap: function (fieldsMapPrefix: string): AnyObject {
		return {
			id: `${fieldsMapPrefix}.id`,
			company_name: `${fieldsMapPrefix}.name`,
			company_inn: `${fieldsMapPrefix}.inn`,
			company_kpp: `${fieldsMapPrefix}.kpp`,
			company_iln: `${fieldsMapPrefix}.iln`,
			company_reg_nr: `${fieldsMapPrefix}.regNr`
		};
	},
	getModalConf: function (): AnyObject {
		const defaults = edi.selectors.defaults;
		return {
			title: edi.i18n.getMessage('documents.order.buyer'),
			tabs: {
				main: {
					company_name: defaults.company_name,
					company_gln: defaults.company_gln,
					company_reg_number: defaults.company_reg_nr,
					company_inn: defaults.company_inn
				}
			}
		};
	}
};
