import {
	createModalForm,
	createContainer,
	createPanel,
	createFieldBlockForDetails,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createLabel, createLabelForDetails } from '@UIkit/components/fields';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createGrid } from '@UIkit/components/grid';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createProxyConfig } from '@Components/storeComponents';
import { createTextField } from '@UIkit/components/fields';
import './DocumentHeaderPanel.scss';
import { errorsAndWarningsMethods } from '@Edi/modules/documentGrids/warningAndErrorGrid/methods';
import { createPackageInfoModal } from '@Edi/modules/documentPackages/packageInfoModal';

const baseCls = 'edi-document-header-panel';
const DETAILS_PANEL_CLS = {
	countWithIcon: `${baseCls}-countWithIcon`
};

const createCreationDateField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.date.create'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		order: 10,
		items: [
			createLabelForDetails({
				text: edi.utils.formatDate(
					me.documentData.creationDate,
					edi.constants.DATE_FORMAT.DATE_TIME_FNS,
					edi.constants.DATE_FORMAT.SERVER
				)
			})
		]
	});
};

const createModifyDateField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.date.modify'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		order: 20,
		items: [
			createLabelForDetails({
				text:
					me.documentData.creationDate != me.documentData.modifyDate
						? edi.utils.formatDate(
								me.documentData.modifyDate,
								edi.constants.DATE_FORMAT.DATE_TIME_FNS,
								edi.constants.DATE_FORMAT.SERVER
						  )
						: ''
			})
		]
	});
};

const createCrptStatusField = (me, actualCrptState) => {
	const data = me.documentData;
	const attrs = data.attributes;
	let labelItems = [
		createContainer({
			width: '80%',
			html: actualCrptState
		})
	];

	if (me.showCRPTPrintBtn) {
		let printAction = function (printId) {
			edi.rest.downloadFile(
				edi.utils.formatString(edi.rest.services.REPORT.PRINT_FORM.GET, { reportId: printId })
			);
		};

		let direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		let printDocId =
			(direction === edi.constants.DIRECTIONS.INCOMING
				? edi.utils.getAttributeByName(attrs, 'APERAK_CRPT_ID_SELLER')
				: edi.utils.getAttributeByName(attrs, 'APERAK_CRPT_ID_BUYER')) ||
			edi.utils.getAttributeByName(attrs, 'APERAK_CRPT_ID');
		let printPoaDocId =
			(direction === edi.constants.DIRECTIONS.INCOMING
				? edi.utils.getAttributeByName(attrs, 'APERAK_POA_CRPT_SELLER')
				: edi.utils.getAttributeByName(attrs, 'APERAK_POA_CRPT_BUYER')) ||
			edi.utils.getAttributeByName(attrs, 'APERAK_POA_CRPT');

		labelItems.push(
			createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.icon, DETAILS_PANEL_CLS.countWithIcon],
				tooltip: edi.i18n.getMessage('action.crpt.state.print'),
				glyph: edi.constants.ICONS.PRINT,
				menu: {
					plain: true,
					hideMode: 'display',
					items: [
						{
							text: edi.i18n.getMessage('report.processing.UPD'),
							disabled: !printDocId,
							handler() {
								printAction(printDocId);
							}
						},
						{
							text: edi.i18n.getMessage('report.processing.annul'),
							disabled: !printPoaDocId,
							handler() {
								printAction(printPoaDocId);
							}
						}
					]
				}
			})
		);
	}
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.crpt_state'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		order: 40,
		items: labelItems
	});
};

const createStateField = (me) => {
	let stateHTML;
	const defaultStateHandler = function (data) {
		let stateHTML = edi.renderers.status(data);
		if (
			data.state === edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX ||
			data.state === edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX
		) {
			stateHTML += ': ' + data.routePositionName;
		}
		return stateHTML;
	};

	if (me.noStateRenderer) {
		stateHTML = me.documentData.state;
	} else if (me.stateRenderer) {
		stateHTML = me.stateRenderer(me.documentData.state, null, me.documentData);
	} else if (
		me.documentData.type &&
		edi.methods.documents &&
		'function' === typeof edi.methods.documents.getDocumentOwnStatusName
	) {
		const res = edi.methods.documents.getDocumentOwnStatusName(me.initData, me.documentData.type);
		stateHTML = res ? res : defaultStateHandler(me.documentData);
	} else {
		stateHTML = defaultStateHandler(me.documentData);
	}
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.state'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		order: 30,
		items: [
			createContainer({
				html: stateHTML
			})
		]
	});
};

const createTotalSummField = (me, totalSumm) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		order: 50,
		title: edi.i18n.getMessage(edi.utils.getObjectProperty(me, 'summTitle') || 'document.summ'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		items: [
			createLabelForDetails({
				html: edi.renderers.currencyWithSeparators(
					'function' == typeof totalSumm ? totalSumm() : edi.utils.displayCurrencyDecimals(totalSumm)
				)
			})
		]
	});
};

const createSignaturesField = (me, config) => {
	const { fieldBlockCfg, labelCfg, buttonCfg } = config || {};
	return createFieldBlockForDetails(
		Object.assign(
			{
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.signatures.count'),
				grid: me.DEFAULT_GRID_FIELD_CONFIG,
				layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
				order: 60,
				items: [
					createLabel(
						Object.assign(
							{
								color: me?.documentData?.countSignatures || '--color-grey-30',
								cls: DETAILS_PANEL_CLS.labelPosition,
								text: me?.documentData?.countSignatures || 0,
								showZero: true
							},
							labelCfg
						)
					),
					createButton(
						Object.assign(
							{
								cls: [BUTTON_CLS.light, BUTTON_CLS.icon, DETAILS_PANEL_CLS.countWithIcon],
								tooltip: edi.i18n.getMessage('document.show.signatures.info'),
								glyph: edi.constants.ICONS.DETAILS,
								disabled: !me?.documentData?.countSignatures,
								handler: function () {
									edi.document.actions.signatureInfoDialog(me.initData, me.documentData.id);
								}
							},
							buttonCfg
						)
					)
				]
			},
			fieldBlockCfg
		)
	);
};

const createLinkedDocsField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.linked.docs'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		order: 70,
		items: [
			createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
				tooltip: edi.i18n.getMessage('document.show.linked.docs'),
				glyph: edi.constants.ICONS.DETAILS,
				disabled: !me.documentData.hasLinkedDocuments,
				handler: function () {
					if (
						me.documentData.type &&
						(me.documentData.type.match(/^LEGACY_/) || me.documentData.type.match(/^EDI_FNS/))
					) {
						const depth = 5;
						edi.document.actions.linkedDocumentsDialog(
							me.documentData.id,
							// EW start
							{
								docData: me.documentData
							},
							// EW end
							{
								tree: edi.rest.services.DOCUMENTS.LINKED.LINK.GET,
								path: edi.rest.services.DOCUMENTS.LINKED.PATH.GET
							},
							depth
						);
					} else if (
						me.documentData.type &&
						me.documentData.type.match(/^ON_SERVICE_/) &&
						me.linkedUpdUkdData
					) {
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.ON_SERVICE.GET, {
								documentId: me.linkedUpdUkdData.id
							}),
							'GET',
							null,
							function (data) {
								const model = edi.models.getModel('DOCUMENT');
								const columns = edi.columns.get('documents_all');
								const grid = createGrid({
									storeConfig: {
										pageSize: edi.constants.MAX_PAGE_SIZE,
										model: model,
										proxy: createProxyConfig({
											type: 'memory',
											data: {
												items: data.items
											}
										})
									},
									gridConfig: {
										columns: columns,
										maxHeight: 240
									}
								});

								var defaults = {
									width: MODAL_SIZE.widthLarge,
									title: edi.i18n.getMessage('document.linked.docs'),
									items: [
										createModalForm({
											items: [grid]
										})
									]
								};
								const modal = createModalPanel(defaults, true);
								modal.show();
							}
						);
					} else {
						edi.document.actions.linkedDocumentsDialog(me.documentData.id, {
							docData: me.documentData
						});
					}
				}
			})
		]
	});
};

const createReceiptsField = (me) => {
	let receiptsData = [],
		receiptsButton;
	if (me.documentData.hasLinkedDocuments && me.isReceipts) {
		edi.rest.sendRequest(
			edi.document.actions.formatLinkedUri(me.initData),
			'GET',
			null,
			function (data) {
				if (data && data.data && data.data.children) {
					receiptsData = data.data.children.filter(function (item) {
						return edi.constants.RECEIPTS.some((it) => it === item.type);
					});
				}
			},
			undefined,
			function () {
				if (receiptsData.length) {
					receiptsButton.removeAll();
					receiptsButton.add(
						createButton({
							cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
							tooltip: edi.i18n.getMessage('document.show.linked.docs'),
							glyph: edi.constants.ICONS.DETAILS,
							handler: function () {
								edi.document.actions.receiptsDocumentsDialog(me.documentData.id, receiptsData);
							}
						})
					);
				}
			}
		);
	}
	receiptsButton = createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('receipt.title'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		order: 80,
		items: [
			createLabelForDetails({
				text: edi.i18n.getMessage('receipt.without')
			})
		]
	});
	return receiptsButton;
};

const createLogField = (me) => {
	const ids = me.documentData.ids?.length ? me.documentData.ids : [];
	ids.push(me.documentData.id);
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.logs'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		order: 90,
		items: [
			createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
				tooltip: edi.i18n.getMessage('document.show.logs'),
				glyph: edi.constants.ICONS.VISIBILITY,
				handler: function () {
					edi.document.actions.logsInfoDialog(ids);
				}
			})
		]
	});
};

const createIdField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('column.identifier'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		order: 100,
		items: [
			createLabelForDetails({
				text: me.documentData.id
			})
		]
	});
};

const createUsageField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.lock'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		order: 110,
		items: [
			createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
				tooltip: edi.i18n.getMessage('document.show.logs'),
				glyph: edi.constants.ICONS.VISIBILITY,
				handler: function () {
					edi.methods.lockDocument.details(me.documentData.id, {
						modalConfig: {
							padding: '0 24'
						}
					});
				}
			})
		]
	});
};

const createPackageField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.packages'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		order: 120,
		items: [
			createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
				tooltip: edi.i18n.getMessage('document.show.logs'),
				glyph: edi.constants.ICONS.VISIBILITY,
				handler: function () {
					const modal = createPackageInfoModal({ packageId: me.documentData.packageId });
					modal.show();
				}
			})
		]
	});
};

const createFunctionCodeField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.function.code'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		order: 130,
		items: [
			createLabelForDetails({
				text: edi.renderers.documentFunctionCode(
					edi.utils.getAttributeByName(me.documentData.attributes, 'documentFunctionCode')
				)
			})
		]
	});
};

const createDocVersionField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.version'),
		order: 140,
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		items: [
			createLabelForDetails({
				text: edi.utils.getAttributeByName(me.documentData.attributes, 'documentVersion')
			})
		]
	});
};

const createNotifyField = (me) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.action.annul.all.modal.title'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		order: 150,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		items: [
			createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
				tooltip: edi.i18n.getMessage('document.action.annul.all.modal.title'),
				glyph: edi.constants.ICONS.DETAILS,
				handler: function () {
					edi.document.actions.documentNotifyDialog(me.documentData.id);
				}
			})
		]
	});
};

const createEdemStatusField = (me, edemStatus) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		order: 160,
		title: edi.i18n.getMessage('document.edem.status'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		items: [
			createContainer({
				html: edemStatus
			})
		]
	});
};

const getPoaErrorsField = function (me, poaState, docId) {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.poa.errors.status'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		order: 170,
		items:
			poaState !== 'errors'
				? [
						createContainer({
							html: edi.renderers.status('POWER_OF_ATTORNEY_STATUS_' + poaState)
						})
				  ]
				: [
						createContainer({
							html: edi.renderers.status('POWER_OF_ATTORNEY_STATUS_' + poaState)
						}),
						createButton({
							cls: [BUTTON_CLS.light, BUTTON_CLS.icon, DETAILS_PANEL_CLS.countWithIcon],
							tooltip: edi.i18n.getMessage('document.poa.errors.status.details'),
							glyph: edi.constants.ICONS.DETAILS,
							handler: function () {
								edi.document.actions.showPoaErrorDialog(docId);
							}
						})
				  ]
	});
};

const createReasonFields = (me) => {
	const reasonRowFields = [];
	const createReasonRowPanel = function (reasonText, fieldTitle) {
		const fourthRow = createPanel({
			cls: 'edi-document-header-panel-row',
			margin: '0 0 16 0',
			items: [
				createFieldBlockForDetails({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage(fieldTitle),
					grid: me.DEFAULT_GRID_FIELD_CONFIG,
					items: [
						createLabelForDetails({
							text:
								//Нужно только в ФП FACTORPLAT-885 коммит aea340c7
								'function' == typeof me.rejectReasonRenderer
									? me.rejectReasonRenderer(reasonText)
									: edi.i18n.getMessage(reasonText)
						})
					]
				})
			]
		});
		reasonRowFields.push(fourthRow);
	};

	const data = me.documentData;
	const attrs = data.attributes;
	const annulStatus = edi.utils.getAttributeByName(attrs, 'ANNUL_STATUS');
	const annulReason = edi.utils.getAttributeByName(attrs, 'ANNUL_REASON');
	const opposingAnnulStatus = edi.utils.getAttributeByName(attrs, 'OPPOSING_ANNUL_STATUS');
	const utochReason = me.hasOwnProperty('utochReasonText')
		? me.utochReasonText
		: edi.utils.getAttributeByName(attrs, me.reasonAttrName || 'UTOCH_REASON');
	let rejectReason = '';
	if (!me.rejectReasonText) {
		rejectReason = utochReason || edi.utils.getAttributeByName(attrs, 'REJECT_REASON');
	}
	const cancelReason = edi.utils.getAttributeByName(attrs, 'CANCEL_REASON');
	const annulCreator = edi.utils.getAttributeByName(attrs, 'ANNUL_CREATOR');
	const annulCreatedByCurrentUser = !annulCreator || annulCreator == edi.core.getUserOrgID();
	const opposingAnnulCreator = edi.utils.getAttributeByName(attrs, 'OPPOSING_ANNUL_CREATOR');
	const opposingAnnulCreatedByCurrentUser = !opposingAnnulCreator;

	if (annulReason && annulStatus && !(annulStatus === edi.constants.STATE.DRAFT && !annulCreatedByCurrentUser)) {
		if (!!me.showAnnulLog) {
			createReasonRowPanel(annulReason, 'document.mark.annul.reason');
		}
		if (annulStatus === edi.constants.STATE.DELETED) {
			const title = edi.i18n.getMessage(
				me.showAnnulLog ? 'document.reject.reason.field.title' : 'document.mark.annul.reject.reason.value'
			);
			reasonRowFields.push(
				createPanel({
					layout: 'column',
					cls: 'edi-document-header-panel-row',
					margin: '0 0 16 0',
					items: [
						createFieldBlockForDetails({
							cls: FIELD_BLOCK_CLS.small,
							title: me.annulLogTitle ?? title,
							grid: me.DEFAULT_GRID_FIELD_CONFIG,
							items: [
								createLabelForDetails({
									text: me.latestAnnulRejectReasonText
								})
							]
						})
					]
				})
			);
		}
	}

	if (
		opposingAnnulStatus &&
		opposingAnnulStatus !== edi.constants.STATE.DELETED &&
		!(opposingAnnulStatus === edi.constants.STATE.ACTIVE && !!opposingAnnulCreatedByCurrentUser)
	) {
		if (
			data.toOrg &&
			data.fromOrg &&
			edi.constants.DIRECTIONS.INCOMING === edi.utils.getDocumentDirection(data.toOrg, data.fromOrg)
		) {
			createReasonRowPanel(
				edi.utils.getAttributeByName(attrs, 'OPPOSING_ANNUL_REASON'),
				'document.mark.annul.reason'
			);
		}
	}

	if (
		rejectReason &&
		!(annulStatus && annulStatus === edi.constants.STATE.ACTIVE) &&
		!(opposingAnnulStatus && opposingAnnulStatus === edi.constants.STATE.ACTIVE)
	) {
		var isClarify = me.hasOwnProperty('isClarify') ? me.isClarify : !!utochReason;
		createReasonRowPanel(rejectReason, isClarify ? 'document.mark.clarify.reason' : 'document.reject.reason');
	}

	if (me.rejectReasonText) {
		let title;
		if (me.rejectReasonTitle) {
			title = me.rejectReasonTitle;
		} else {
			title = 'document.reject.reason';
			if (utochReason) {
				title = 'document.mark.clarify.reason';
			} else if (me.hasOwnProperty('revokeReason')) {
				title = 'document.revoke.reason.title';
			}
		}
		reasonRowFields.push(
			createPanel({
				layout: 'column',
				cls: 'edi-document-header-panel-row',
				margin: '0 0 16 0',
				items: [
					createFieldBlockForDetails({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage(title),
						grid: me.DEFAULT_GRID_FIELD_CONFIG,
						layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
						items: [
							createButton({
								cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
								tooltip: edi.i18n.getMessage('document.show.linked.docs'),
								glyph: edi.constants.ICONS.DETAILS,
								handler: function () {
									var modal = createModalPanel({
										title: edi.i18n.getMessage(title),
										width: MODAL_SIZE.widthLarge,
										items: [
											createModalForm({
												items: [
													createTextField({
														isTextarea: true,
														width: '100%',
														value: me.rejectReasonText,
														readOnly: true,
														grow: true,
														growMax: 2000,
														autoScroll: true,
														autoHeight: true,
														listeners: {
															resize: function (comp) {
																comp.autoSize();
															}
														}
													})
												]
											})
										]
									});
									modal.show();
								}
							})
						]
					})
				]
			})
		);
	}
	if (cancelReason) {
		createReasonRowPanel(cancelReason, 'document.cancel.reason');
	}
	return reasonRowFields;
};

const createAperakStatusField = (me, aperakCode, aperakText) => {
	return createPanel({
		layout: 'column',
		cls: 'edi-document-header-panel-row',
		margin: '0 0 5',
		items: [
			createFieldBlockForDetails({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('document.aperak.text'),
				grid: me.DEFAULT_GRID_FIELD_CONFIG,
				items: [
					createLabelForDetails({
						text: aperakText
					})
				]
			})
		]
	});
};

const createWarningAndErrorDocsField = (me, text, isError) => {
	return createFieldBlockForDetails({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage(isError ? 'document.column.errors' : 'document.column.warnings'),
		grid: me.DEFAULT_GRID_FIELD_CONFIG,
		layout: me.DEFAULT_LAYOUT_FIELD_CONFIG,
		order: 170,
		items: [
			createButton({
				cls: [
					BUTTON_CLS.icon,
					`edi-document-header-panel-warningAndErrorDocs ${isError ? 'icon-error' : 'icon-warning'}`
				],
				glyph: isError ? edi.constants.ICONS.ERROR : edi.constants.ICONS.WARNING,
				handler: function () {
					const activeModule = edi.modulesHandler.getActiveModule();
					if (activeModule?.tab && !activeModule.tab.isDestroyed) {
						activeModule.tab.setLoading(true);
					}
					const callback = () => {
						if (activeModule?.tab && !activeModule.tab.isDestroyed) {
							activeModule.tab.setLoading(false);
						}
					};
					isError
						? errorsAndWarningsMethods.showErrorConfirmWithException({
								docHeaderData: me.documentData,
								callback
						  })
						: errorsAndWarningsMethods.showWarningConfirmWithException({
								docHeaderData: me.documentData,
								callback
						  });
				}
			})
		]
	});
};

export {
	createCreationDateField,
	createModifyDateField,
	createCrptStatusField,
	createStateField,
	createTotalSummField,
	createSignaturesField,
	createLinkedDocsField,
	createReceiptsField,
	createLogField,
	createIdField,
	createUsageField,
	createPackageField,
	createFunctionCodeField,
	createDocVersionField,
	createNotifyField,
	createEdemStatusField,
	getPoaErrorsField,
	createReasonFields,
	createAperakStatusField,
	createWarningAndErrorDocsField
};
