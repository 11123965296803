import { CreateDocForPackageWindow, DocTypeConfig } from '@Edi/modules/documentPackages/createDocForPackageWindow';

export class FnsCreateDocForPackageWindow extends CreateDocForPackageWindow {
	getTypes(): DocTypeConfig[] {
		return super.getTypes().concat([
			{
				id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.01-N',
				name: edi.i18n.getMessage('document.fns_upd_5_01n'),
				moduleName:
					edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.01-N'],
				isAvailable: () => edi.permissions.hasPermission('CREATE_EDI_FNS_UPD') //edi.permissions.hasPermission('CLIENT_EDI_FNS_UPD_5_01_N_CREATE')
			},
			{
				id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.02-N',
				name: edi.i18n.getMessage('document.fns_upd_5_02n'),
				moduleName:
					edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.02-N'],
				isAvailable: () => edi.permissions.hasPermission('CREATE_EDI_FNS_UPD')
			}
		]);
	}
}
