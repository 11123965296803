import { createTextField } from '@Components/fields';
import { createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsColumnConfig, createCheckboxSelectionModel, createGrid, createToolBar } from '@Components/grid';
import { createAddButton, createDeleteButton } from '@Components/buttons';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createLoccatFormItems, createProdcatFormItems } from './methods';
import { getProductCatalogColumns } from './columns';
import { createContainer, createFieldBlock } from '@UIkit/components/panels';
import { createButtonContainer } from '@UIkit/components/panels/buttonContainer/ButtonContainer';
import { catalogsAPI } from './rest';
import { showConfirm } from '@UIkit/components/modal/MessageBox';

Ext.namespace('edi.modules');
edi.modules['catalog.create'] = function () {
	let moduleData,
		objectData,
		grid,
		filterForm,
		filterObject,
		selectedRecords = [],
		modulePanelButtons,
		reloadCatalogBtn,
		deleteBtn,
		catalogType,
		isPRODCAT,
		isLOCCAT;
	let isChanged = false;
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		objectData = moduleData.initData.data;
		catalogType = objectData.type;
		isPRODCAT = catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT;
		isLOCCAT = catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT;

		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.catalog.on('change', changeHandler);
	};
	const fireSearch = function () {
		if (!filterObject) {
			const url = edi.utils.formatString(edi.rest.services.CATALOGS.V2.RECORDS.FILTER.GET, {
				type: edi.constants.CATALOG_TYPES[catalogType],
				id: objectData.id
			});
			filterObject = edi.filters.createGridFilter(url, filterForm, grid);
		}
		filterObject.filter();
	};

	const createMainDataBlock = () => {
		const creationDate = createTextField({
			fieldLabel: edi.i18n.getMessage('column.creation.date'),
			disabled: true,
			value: edi.utils.formatDate(objectData.creationDate, edi.constants.DATE_FORMAT.FNS)
		});
		const fromOrg = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.column.fromOrg'),
			disabled: true,
			value: edi.renderers.organization(objectData.fromOrg || objectData.fromNetwork)
		});
		const toOrg = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.column.toOrg'),
			disabled: true,
			value: edi.renderers.organization(objectData.toOrg || objectData.toNetwork)
		});

		return createFieldBlock({
			cls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[2, 4, 4]]
			},
			items: [creationDate, fromOrg, toOrg]
		});
	};

	const createFilterForm = () => {
		const { formItemsMap, items } = isPRODCAT ? createProdcatFormItems() : createLoccatFormItems();
		filterForm = createModuleFilterForm(
			{
				usePlaceholderWrapper: true,
				formItemsMap,
				items
			},
			fireSearch
		);

		return filterForm;
	};

	/**
	 * Creates details panel
	 * @returns {Object}
	 */
	const createLayout = function () {
		return createFormForModule({
			cls: 'edi-details-panel',
			region: 'north',
			items: [createMainDataBlock()]
		});
	};

	const createCatalogGrid = function () {
		let columns, model;

		if (isPRODCAT) {
			columns = getProductCatalogColumns();
			model = edi.models.getModel('PRODCAT_LINE');
		} else {
			columns = edi.columns.get(isLOCCAT ? 'addresscatalog_lines' : 'delcat_lines');
			model = edi.models.getModel(isLOCCAT ? 'LOCCAT_LINE' : 'DELCAT_LINE');
		}

		columns.push(
			createActionsColumnConfig({
				width: edi.utils.getActionColumnWidth(1),
				items: [
					{
						glyph: edi.constants.ICONS.EDIT,
						testCls: 'test-action-column-edit',
						handler: function (grid, rowIndex) {
							const recordData = grid.getStore().getAt(rowIndex).getData();
							edi.methods.catalog.item.update(objectData.id, recordData, catalogType);
						}
					}
				]
			})
		);

		const tools = [
			createAddButton(
				function () {
					edi.methods.catalog.item.create(objectData.id, catalogType);
				},
				{
					tooltip: edi.i18n.getMessage('catalog.add.row.btn'),
					text: edi.i18n.getMessage('catalog.add.row.btn')
				}
			),
			(deleteBtn = edi.permissions.hasPermission('CLIENT_DELETE_CATALOG_V2_LINES')
				? createDeleteButton(
						function () {
							edi.methods.catalog.item.remove(objectData.id, selectedRecords, catalogType);
						},
						{
							cls: [BUTTON_CLS.light, BUTTON_CLS.small],
							tooltip: edi.i18n.getMessage('catalog.delete.row.btn'),
							text: edi.i18n.getMessage('catalog.delete.row.btn')
						}
				  )
				: null),
			(reloadCatalogBtn = createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.small],
				tooltip: edi.i18n.getMessage('action.reload.catalog'),
				text: edi.i18n.getMessage('action.reload.catalog'),
				glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
				disabled: isModified(),
				handler: function () {
					edi.methods.catalog.showUploadCatalogDialog(moduleData, true);
					edi.events.catalog.on('change', changeHandler);
				}
			}))
		];

		grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: model,
				autoLoad: false,
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'creationDate',
					direction: 'ASC'
				}
			},
			gridConfig: {
				selModel: createCheckboxSelectionModel({
					listeners: {
						selectionchange: function (model, selected) {
							selectedRecords = selected.map(function (record) {
								return record.getData();
							});

							deleteBtn?.setDisabled(!selected.length);
						}
					}
				}),
				cls: 'tab-grid with-filter',
				columns: columns,
				layout: 'fit',
				scrollable: {
					x: 'auto',
					y: 'auto'
				},
				region: 'center',
				border: 0,
				dockedItems: createToolBar({
					items: tools
				}),
				autoScroll: true,
				disableSelection: false,
				listeners: {
					render: fireSearch,
					celldblclick: function (view, td, cellIndex, record, tr, rowIndex) {
						const recordData = grid.getStore().getAt(rowIndex).getData();
						edi.methods.catalog.item.update(objectData.id, recordData, catalogType);
					}
				}
			}
		});
		return grid;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (eventData) {
		if (eventData && eventData.catalogType == catalogType && eventData.id == objectData.id) {
			if (eventData.deleted || eventData.reloadCatalog) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				grid.getStore().reload();
				loadCatalogHeader().then(() => {
					isChanged = isModified();
					updateModulePanelButtons();
				});
			}
		}
	};

	const loadCatalogHeader = async function () {
		//Нет реста который возвращает header для каталога, вместо этого используем сервис фильтра
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.utils.compileURL(edi.rest.services.CATALOGS.V2.FILTER.GET, {
				id: objectData.id
			})
		});

		if (success && data?.items?.[0]) {
			const catalogData = data.items[0];
			objectData = catalogData;
		} else {
			edi.modulesHandler.removeModule(moduleData);
			edi.core.showError(edi.utils.formatComplexServerError(data, 'catalog.error.getting.data'));
		}
	};

	const isModified = () => {
		const isActive = objectData.state === edi.constants.STATE.ACTIVE;
		const isModify = edi.utils.getAttributeByName(objectData.attributes, 'MODIFIED', undefined, true);
		return isActive && isModify;
	};

	const updateModulePanelButtons = () => {
		const isModify = isModified();
		modulePanelButtons?.setVisible(isModify);
		reloadCatalogBtn?.setDisabled(isModify);
	};

	const resetChanges = function () {
		const success = function () {
			moduleData.tab.setLoading(false);
			isChanged = false;
			edi.modulesHandler.removeModule(moduleData);
			edi.events.catalog.fireEvent('change', {
				id: objectData.id,
				catalogType: objectData.type
			});
		};

		const failure = function (data) {
			edi.core.logMessage('Error saving partin', 'warn');
			edi.core.showError(edi.utils.formatComplexServerError(data, 'catalog.reset.changes.error'), function () {
				moduleData.tab.setLoading(false);
			});
		};

		moduleData.tab.setLoading(true);

		edi.rest.sendRequest(
			edi.utils.formatString(catalogsAPI.RESET.PUT, {
				id: objectData.id
			}),
			'PUT',
			undefined,
			success,
			failure
		);
	};

	const saveChanges = function () {
		const success = function () {
			moduleData.tab.setLoading(false);
			isChanged = false;
			edi.modulesHandler.removeModule(moduleData);
			edi.events.catalog.fireEvent('change', {
				id: objectData.id,
				catalogType: objectData.type
			});
		};

		const failure = function (data) {
			edi.core.logMessage('Error saving partin', 'warn');
			edi.core.showError(edi.utils.formatComplexServerError(data, 'catalog.save.error'), function () {
				moduleData.tab.setLoading(false);
			});
		};

		moduleData.tab.setLoading(true);

		edi.rest.sendRequest(
			edi.utils.formatString(catalogsAPI.APPLY.PUT, {
				id: objectData.id
			}),
			'PUT',
			undefined,
			success,
			failure
		);
	};

	const createModulePanelButtons = function () {
		const isHidden = !isModified();

		const applyButton = createButton({
			text: edi.i18n.getMessage('form.btn.save'),
			handler: function () {
				saveChanges();
			}
		});
		const resetButton = createButton({
			text: edi.i18n.getMessage('form.btn.reset.changes'),
			handler: function () {
				resetChanges();
			}
		});

		return (modulePanelButtons = createButtonContainer({
			hidden: isHidden,
			items: [applyButton, resetButton]
		}));
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const modulePanel = createDetailsModulePanel({
			layout: 'border',
			items: [
				createLayout(),
				createContainer({
					layout: 'border',
					region: 'center',
					items: [createFilterForm(), createCatalogGrid()]
				})
			],
			buttons: createModulePanelButtons()
		});
		moduleData.tab.add(modulePanel);

		isChanged = isModified();

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	const checkChangesBeforeClose = () => {
		showConfirm({
			msgText: edi.i18n.getMessage('catalog.save.confirmation'),
			yesBtnConfig: {
				text: edi.i18n.getMessage('form.btn.save')
			},
			noBtnConfig: {
				text: edi.i18n.getMessage('btn.come.back.later')
			},
			failure: () => {
				isChanged = false;
				edi.modulesHandler.removeModule(moduleData);
			},
			success: () => saveChanges()
		});
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		if (isChanged) {
			checkChangesBeforeClose();
			return false;
		}

		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.events.catalog.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
