// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { showMessageBox } from '@UIkit/components/modal/MessageBox';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';

export interface AsyncDownloadTaskProps {
	taskId: number;
	afterClose?: () => void;
}

export class AsyncDownloadTask {
	props: AsyncDownloadTaskProps;
	progress: ExtComponent | null = null;
	closeMsgTitle = 'export.group.request.progress.title';
	closeMsgText = 'export.group.request.progress.close';
	downloadLaterBtnText = 'form.btn.download.later';
	cancelBtnText = 'form.btn.stop';
	defaultErrorText = 'error.accept.registry.find';
	progressTitle = 'export.group.request.progress.title';
	progressMsg = 'export.group.request.progress.msg';
	closeBtnText = 'form.btn.close';
	_userStopMe = false;
	_percent = 0;

	constructor(props: AsyncDownloadTaskProps) {
		this.props = props;
	}

	afterCloseHandler() {
		const me = this;
		typeof me.props.afterClose === 'function' && me.props.afterClose();
	}

	cancelHandler() {
		const me = this;
		let cancelUrl = edi.utils.formatString(
			edi.rest.services.EXPORT.GROUP_REQUEST.PUT,
			{ id: me.props.taskId },
			true
		);
		edi.rest.sendRequest(cancelUrl, 'PUT', Ext.encode({ REJECT: true }));
		me.afterCloseHandler();
	}

	showCloseDialog() {
		const me = this;
		const closeMsg = edi.core.confirm(
			edi.i18n.getMessage(me.closeMsgTitle),
			edi.i18n.getMessage(me.closeMsgText),
			undefined,
			undefined,
			undefined,
			undefined,
			{
				closable: false,
				buttons: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage(me.downloadLaterBtnText),
						handler: function () {
							closeMsg.close();
							me.afterCloseHandler();
						}
					}),
					createButton({
						cls: BUTTON_CLS.secondary,
						text: edi.i18n.getMessage(me.cancelBtnText),
						handler: function () {
							closeMsg.close();
							me.cancelHandler();
						}
					})
				]
			}
		) as ExtComponent;
	}

	downloadAndClose(fileId: number) {
		const me = this;
		edi.rest.downloadFile(
			edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.EXPORT.GET, { id: fileId })
		);
		me.progress?.close();
		me.progress = null;
		me.afterCloseHandler();
	}

	successHandler(resp: AnyObject) {
		const me = this;
		if (me._userStopMe) {
			if (me.progress) {
				me.progress.close();
				me.progress = null;
			}
			me.showCloseDialog();
		} else if (me.progress) {
			if (resp.data?.hasOwnProperty('percentageOfReadiness')) {
				me._percent = resp.data.percentageOfReadiness;
			}
			me.progress.updateProgress(me._percent / 100, me._percent + ' %');
			if (
				me._percent === 100 &&
				(edi.constants.EXPORT_GROUP_REQUEST.STATE.READY === resp.data?.state ||
					edi.constants.EXPORT_GROUP_REQUEST.STATE.PROCESSED_WITH_ERROR === resp.data?.state)
			) {
				me.downloadAndClose(resp.data.id);
			} else {
				setTimeout(me.getExportedDocStatus.bind(me), 1000);
			}
		}
	}

	failureHandler(data: AnyObject) {
		const me = this;
		if (me.progress && me._userStopMe) {
			me.progress.close();
			me.progress = null;
		}
		edi.core.showError(edi.utils.formatComplexServerError(data, me.defaultErrorText));
	}

	getExportedDocStatus() {
		const me = this;
		let url = edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.GET, { id: me.props.taskId });
		edi.rest.sendRequest(url, 'GET', null, me.successHandler.bind(me), me.failureHandler.bind(me));
	}

	startCheckTask() {
		const me = this;

		me.progress = showMessageBox({
			closable: false,
			title: edi.i18n.getMessage(me.progressTitle),
			width: MODAL_SIZE.widthMedium,
			progress: true,
			progressBarConfig: {
				title: edi.i18n.getMessage(me.progressMsg),
				description: me._percent + ' %'
			},
			okBtnConfig: {
				text: edi.i18n.getMessage(me.closeBtnText),
				handler: function () {
					me.progress?.close();
					me._userStopMe = true;
				}
			}
		}) as ExtComponent;
		me.getExportedDocStatus();
	}
}

export const asyncDownloadTask = {
	check: function (props: AsyncDownloadTaskProps) {
		new AsyncDownloadTask(props).startCheckTask();
	}
};
