/**
 * UI permissions for Edi package
 * @author Anatoli Deryshev
 */
Ext.namespace('edi.permissions');
edi.permissions.setUiPermissions({
	CREATE_DOCUMENTS: {
		OR: [
			'CREATE_LEGACY_ORDER',
			'CREATE_LEGACY_DELJIT',
			'CREATE_LEGACY_REC_ADV',
			'CREATE_LEGACY_DES_ADV',
			'CREATE_LEGACY_ALCDES',
			'CREATE_LEGACY_INVOICE',
			'CREATE_DSF_CURRENCY_CONTROL',
			'CREATE_DSF_AGREEMENT',
			'CREATE_DSF_UNKNOWN',
			'CREATE_DSF_UNFORMALIZED',
			'CREATE_PRICAT_EANCOM'
		]
	},
	READ_ON_AVZ: {
		OR: ['ON_AVZ_COMPLETE']
	},
	READ_DOCUMENTS: {
		OR: [
			'READ_LEGACY_ORDER',
			'READ_LEGACY_DELJIT',
			'READ_LEGACY_REC_ADV',
			'READ_LEGACY_DES_ADV',
			'READ_LEGACY_ALCDES',
			'READ_LEGACY_INVOICE',
			'READ_DSF_CURRENCY_CONTROL',
			'READ_DSF_AGREEMENT',
			'READ_DSF_UNKNOWN',
			'READ_DSF_UNFORMALIZED',
			'READ_EDI_IFTMBF',
			'READ_EDI_IFTMBC',
			'READ_EDI_IFTMBF_APPROVAL',
			'READ_EDI_IFTMBC_APPROVAL',
			'READ_PRICAT_EANCOM',
			'READ_COMMON_PRICAT',
			'READ_DP_CONTAINER',
			'READ_DP_CONTAINER_NE',
			'READ_DP_CONTAINER_DR',
			'READ_X5_AGREEMENT_CONTAINER',
			'READ_DSF_INVOICE',
			'READ_DSF_SUPPLEMENTARY_AGREEMENT',
			'READ_DSF_FROV_DFCT_RPT',
			'READ_DSF_FROV_FINE_ACT',
			'READ_DSF_FROV_SGL_SO_ACT',
			'READ_DSF_FROV_SETOFF_ACT',
			'READ_DSF_FROV_SCHET',
			'READ_DSF_ACT_DIFFRNT',
			'READ_DSF_RECADV_DIFF_ACT',
			'READ_DOCUMENTS_REST_SERVICES'
		]
	},
	CREATE_DSF: {
		OR: ['CREATE_DSF_CURRENCY_CONTROL', 'CREATE_DSF_AGREEMENT', 'CREATE_DSF_UNKNOWN', 'CREATE_DSF_UNFORMALIZED']
	},
	EDIT_DSF: {
		OR: ['EDIT_DSF_CURRENCY_CONTROL', 'EDIT_DSF_AGREEMENT', 'EDIT_DSF_UNKNOWN', 'EDIT_DSF_UNFORMALIZED']
	},
	READ_DSF: {
		OR: [
			'READ_DSF_CURRENCY_CONTROL',
			'READ_DSF_AGREEMENT',
			'READ_DSF_UNKNOWN',
			'READ_DSF_UNFORMALIZED',
			'READ_DSF_INVOICE',
			'READ_DSF_SUPPLEMENTARY_AGREEMENT',
			'READ_DSF_FROV_DFCT_RPT',
			'READ_DSF_FROV_FINE_ACT',
			'READ_DSF_FROV_SGL_SO_ACT',
			'READ_DSF_FROV_SETOFF_ACT',
			'READ_DSF_FROV_SCHET',
			'READ_DSF_ACT_DIFFRNT',
			'READ_DSF_RECADV_DIFF_ACT'
		]
	},
	CLARIFY_DSF: {
		OR: [
			'READ_DSF_CURRENCY_CONTROL',
			'READ_DSF_AGREEMENT',
			'READ_DSF_UNKNOWN',
			'READ_DSF_UNFORMALIZED',
			'READ_DSF_INVOICE',
			'READ_DSF_SUPPLEMENTARY_AGREEMENT',
			'READ_DSF_FROV_DFCT_RPT',
			'READ_DSF_FROV_FINE_ACT',
			'READ_DSF_FROV_SGL_SO_ACT',
			'READ_DSF_FROV_SETOFF_ACT',
			'READ_DSF_FROV_SCHET',
			'READ_DSF_ACT_DIFFRNT',
			'READ_DSF_RECADV_DIFF_ACT'
		]
	},

	READ_DSF_REESTR_ROUTE: 'CLIENT_DSF_REESTR_ROUTE_READ',
	SIGN_DSF_REESTR_ROUTE: {
		OR: ['CLIENT_DSF_REESTR_ROUTE_RECEIVER_SIGN', 'CLIENT_DSF_REESTR_ROUTE_SENDER_SIGN']
	},
	ANNUL_DSF: 'CREATE_EDI_DSF_DP_PRANNUL',
	ANNUL_DSF_CLIENT: 'CREATE_EDI_DSF_DP_PRANNUL',
	ANNUL_ON_AVZ: 'ON_AVZ_PRANNUL_RESPONSE',
	READ_DP_CONTAINER: {
		OR: ['CREATE_DP_CONTAINER', 'UPDATE_DP_CONTAINER']
	},
	READ_DP_CONTAINER_NE: {
		OR: ['CREATE_DP_CONTAINER_NE', 'UPDATE_DP_CONTAINER_NE']
	},
	READ_DP_CONTAINER_DR: {
		OR: ['CREATE_DP_CONTAINER_DR', 'UPDATE_DP_CONTAINER_DR']
	},
	EDIT_DP_CONTAINER: 'UPDATE_DP_CONTAINER',
	EDIT_DP_CONTAINER_NE: 'UPDATE_DP_CONTAINER_NE',
	EDIT_ON_SERVICE_CONTAINER: 'CREATE_ON_SERVICE_CONTAINER',
	EDIT_DP_CONTAINER_DR: 'UPDATE_DP_CONTAINER_DR',
	ANNUL_DP_PRAZN: 'CREATE_DP_CONTAINER_ANNUL',
	READ_EDI_SLSFCT: 'READ_SLSFCT',
	READ_EDI_SLSRPT: 'READ_SLSRPT',
	READ_EDI_INVRPT: {
		OR: ['READ_INVRPT']
	},
	EXPORT_EDI_INVRPT: {
		OR: ['EXPORT_INVRPT']
	},
	SIGN_COMMON_PRICAT: {
		OR: ['SIGN_COMMON_PRICAT_WITH_SIGN_RECEIVER', 'SIGN_COMMON_PRICAT_WITH_SIGN_SENDER']
	},
	SIGN_X5_AGREEMENT_CONTAINER: {
		OR: ['READ_X5_AGREEMENT_CONTAINER']
	},
	SIGN_CONTAINER_COMMON_PRICAT: {
		OR: ['SIGN_COMMON_PRICAT_WITH_SIGN_RECEIVER', 'SIGN_COMMON_PRICAT_WITH_SIGN_SENDER']
	},
	REJECT_CONTAINER_COMMON_PRICAT: {
		OR: ['REJECT_COMMON_PRICAT_WITH_SIGN']
	},
	DELETE_CONTAINER_COMMON_PRICAT: {
		OR: ['DELETE_COMMON_PRICAT_WITH_SIGN']
	},
	CREATE_COMMON_PRICAT: {
		OR: ['CREATE_COMMON_PRICAT_WITH_SIGN']
	},
	REJECT_COMMON_PRICAT: {
		OR: ['REJECT_COMMON_PRICAT_WITH_SIGN']
	},
	DELETE_COMMON_PRICAT: {
		OR: ['DELETE_COMMON_PRICAT_WITH_SIGN']
	},
	ACCEPT_FORMALIZED_DOCUMENT: {
		OR: ['READ_FORMALIZED_DOCUMENT']
	},
	ACCEPT_PRICAT_EANCOM: {
		OR: ['READ_PRICAT_EANCOM']
	}
});
