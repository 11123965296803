/**
 * Object for document action methods
 * @authors Vsevolod L.
 */
Ext.namespace('edi.document.actions');

edi.document.actions.availableFromGrid.SIGN.push({
	documents: edi.constants.UPD_UKD_DOCUMENT_TYPES,
	allow: function (document) {
		const direction = edi.utils.getDocumentDirection(document.toOrg, document.fromOrg);
		const isAllowVersion =
			document.versionId && edi.constants.ACTIVE_EDI_FNS_UPD_VERSIONS?.includes(document.versionId);

		return isAllowVersion || direction !== 'INCOMING';
	}
});

const originalGetLinkedAnnulDoc = edi.document.actions.getLinkedAnnulDoc;
Object.assign(edi.document.actions, {
	/**
	 * Fetches linked documents and retrieve annulDoc for DSF
	 * @param	{Object}	docHeader
	 * @return	{Promise<{success: boolean, linkedDocs: Object[]|null, error: Object|null, annulDoc: Object|null}>}
	 */
	getLinkedAnnulDocForUpd: async function (docHeader) {
		const { success, data: response } = await edi.rest.asyncSendRequest({
			url: edi.document.actions.formatLinkedTreeUri(
				{ id: docHeader.id },
				{ depth: edi.constants.DEFAULT.TREE_DEPTH }
			),
			method: 'GET'
		});

		let linkedDocs = null;
		let annulDoc = null;

		if (success) {
			if (response && Array.isArray(response.items)) {
				linkedDocs = response.items;

				const businessState = docHeader.businessState;
				const utochReasonText = edi.utils.getAttributeByName(docHeader.attributes, 'UTOCH_REASON');
				const isAnnulUtoch =
					utochReasonText ||
					businessState === edi.constants.STATE.ON_ANNUL ||
					businessState === edi.constants.STATE.ANNUL_SIGN;

				linkedDocs.forEach((doc) => {
					if (
						isAnnulUtoch &&
						doc.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
						edi.constants.STATE.WAIT_PARTNER_DECISION === doc.state
					) {
						annulDoc = doc;
					}
				});
			}
			return {
				success: true,
				error: null,
				linkedDocs,
				annulDoc
			};
		} else {
			return {
				success: false,
				error: response,
				linkedDocs,
				annulDoc
			};
		}
	},
	/**
	 * Fetches linked documents and retrieve annulDoc for DSF
	 * @param	{Object}	docHeader
	 * @return	{Promise<{success: boolean, linkedDocs: Object[]|null, error: Object|null, annulDoc: Object|null}>}
	 */
	getLinkedAnnulDocForUkd: async function (docHeader) {
		const { success, data: response } = await edi.rest.asyncSendRequest({
			url: edi.document.actions.formatLinkedTreeUri(
				{ id: docHeader.id },
				{ depth: edi.constants.DEFAULT.TREE_DEPTH }
			),
			method: 'GET'
		});

		let linkedDocs = null;
		let annulDoc = null;

		if (success) {
			if (response && Array.isArray(response.items)) {
				linkedDocs = response.items;

				const utochReasonText = edi.utils.getAttributeByName(docHeader.attributes, 'UTOCH_REASON');
				const businessState = docHeader.businessState;
				const isAnnulUtoch =
					utochReasonText ||
					businessState === edi.constants.STATE.ON_ANNUL ||
					businessState === edi.constants.STATE.ANNUL_SIGN;

				linkedDocs.forEach((doc) => {
					if (
						isAnnulUtoch &&
						doc.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
						edi.constants.STATE.WAIT_PARTNER_DECISION === doc.state
					) {
						annulDoc = doc;
					}
				});
			}
			return {
				success: true,
				error: null,
				linkedDocs,
				annulDoc
			};
		} else {
			return {
				success: false,
				error: response,
				linkedDocs,
				annulDoc
			};
		}
	},
	/**
	 * Fetches linked documents and retrieve annulDoc
	 * @param	{Object}	docHeader
	 * @return	{Promise<{success: boolean, linkedDocs: Object[]|null, error: Object|null, annulDoc: Object|null}>}
	 */
	getLinkedAnnulDocAndIzvpol: async function (docHeader) {
		if (docHeader.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD) {
			return await edi.document.actions.getLinkedAnnulDocForUpd(docHeader);
		} else if (docHeader.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD) {
			return await edi.document.actions.getLinkedAnnulDocForUkd(docHeader);
		} else {
			return await originalGetLinkedAnnulDoc(docHeader);
		}
	}
});
