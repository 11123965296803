import { getDirectionInvitation } from './modules/INVITATIONS/MASS_INVITATIONS/utils';
import { getApprovalDocumentTitle } from '@Ediweb/modules/APPROVAL_DOCUMENTS/methods';

edi.columns.addColumns({
	ew_documents_all_archived: {
		parents: 'documents_all_archived',
		config: {
			id: null,
			modifyDate: null,
			creationDate: {
				hidden: false
			},
			removeDate: null,
			transporter: null,
			orderNumber: null,
			contractNumber: null,
			desadvNumber: null,
			pointName: null,
			deliveryGLN: null,
			totalSumm: null,
			totalNetAmount: null,
			totalTaxAmount: null,
			remark: null,
			documentWarnings: null
		}
	},
	document: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1,
			htmlEncode: true,
			renderer: 'doctype'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		number: {
			text: 'documents.column.number',
			sortable: true,
			flex: 1,
			dataIndex: 'number'
		},
		totalSumm: {
			text: 'documents.column.summ',
			renderer: 'displayCurrencyDecimals',
			sortable: false,
			align: 'right',
			flex: 1,
			dataIndex: 'totalSumm'
		},
		documentSender: {
			text: 'documents.column.document.sender',
			sortable: false,
			flex: 1,
			dataIndex: 'fromOrg',
			renderer: 'organization'
		},
		documentRecipient: {
			text: 'documents.column.document.recipient',
			sortable: false,
			flex: 1,
			dataIndex: 'toOrg',
			renderer: 'organization'
		},
		businessState: {
			text: 'documents.column.status',
			dataIndex: 'businessState',
			htmlEncode: true,
			renderer: 'columnStatus',
			flex: 1
		}
	},
	notifications: {
		title: {
			text: 'documents.column.docName',
			sortable: true,
			flex: 1,
			dataIndex: 'title'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1,
			htmlEncode: true,
			renderer: 'doctype'
		},
		invitation: {
			text: 'documents.column.document.receiver',
			sortable: false,
			flex: 1,
			dataIndex: 'fromOrg',
			renderer: 'organization'
		},
		state: {
			text: 'documents.column.status',
			dataIndex: 'state',
			htmlEncode: true,
			renderer: 'status',
			flex: 1
		}
	},
	ew_invitations: {
		productRole: {
			text: 'invitations.column.product',
			sortable: true,
			flex: 1,
			dataIndex: 'product',
			htmlEncode: true,
			renderer: 'getProductLogo'
		},
		receiverName: {
			text: 'document.receiver',
			sortable: true,
			flex: 2,
			dataIndex: 'receiverName'
		},
		receiverInn: {
			text: 'column.org.inn',
			dataIndex: 'receiverInn',
			flex: 1
		},
		receiverKpp: {
			text: 'column.org.kpp',
			dataIndex: 'receiverKpp',
			flex: 1
		},
		state: {
			text: 'document.state',
			tdCls: 'edi-ediweb-invitation-state',
			dataIndex: 'state',
			htmlEncode: true,
			renderer(value, meta, record) {
				let { state, statusKey } = edi.renderers._invitationStatus(record.getData());
				return edi.renderers.baseStateRenderer(state, edi.i18n.getMessage(statusKey), meta);
			},
			flex: 1
		},
		creationDate: {
			text: 'invitations.column.creationDate',
			sortable: true,
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		modifyDate: {
			text: 'invitations.column.statusDate',
			sortable: true,
			dataIndex: 'modifyDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		}
	},
	ew_invitations_incoming: {
		productRole: {
			text: 'invitations.column.product',
			sortable: true,
			flex: 1,
			dataIndex: 'product',
			htmlEncode: true,
			renderer: 'getProductLogo'
		},
		senderName: {
			text: 'document.sender',
			sortable: true,
			flex: 2,
			dataIndex: 'senderName'
		},
		senderInn: {
			text: 'column.org.inn',
			dataIndex: 'senderInn',
			flex: 1
		},
		senderKpp: {
			text: 'column.org.kpp',
			dataIndex: 'senderKpp',
			flex: 1
		},
		state: {
			text: 'document.state',
			tdCls: 'edi-ediweb-invitation-state',
			dataIndex: 'state',
			htmlEncode: true,
			renderer(value, meta, record) {
				let statusKey = 'documents.status.';
				if (
					value === edi.constants.INVITATIONS.STATE.COMPLETED ||
					value === edi.constants.INVITATIONS.STATE.SENT ||
					value === edi.constants.INVITATIONS.STATE.REJECTED ||
					value === edi.constants.INVITATIONS.STATE.REVOKE
				) {
					statusKey += 'invitation.';
				}
				statusKey += value;

				let dir = edi.utils.getDocumentDirection(
					record.get('ediDocument').toOrg,
					record.get('ediDocument').fromOrg
				);

				if (value === edi.constants.STATE.SENT && dir === edi.constants.DIRECTIONS.INCOMING) {
					statusKey += `.${dir}`;
				}

				return edi.renderers.baseStateRenderer(value, edi.i18n.getMessage(statusKey), meta);
			},
			flex: 1
		},
		creationDate: {
			text: 'invitations.column.creationDate',
			sortable: true,
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		modifyDate: {
			text: 'invitations.column.statusDate',
			sortable: true,
			dataIndex: 'modifyDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		}
	},
	invitations_bulk: {
		org: {
			text: 'ediweb.invitations.orgName',
			sortable: false,
			flex: 1,
			dataIndex: 'org',
			renderer: 'organization'
		},
		inn: {
			text: 'column.org.inn',
			dataIndex: 'inn',
			flex: 1
		},
		kpp: {
			text: 'column.org.kpp',
			dataIndex: 'kpp',
			flex: 1
		},
		email: {
			text: 'email',
			dataIndex: 'email',
			flex: 0.75
		},
		workIn: {
			text: 'ediweb.invitations.work.in.ew',
			dataIndex: 'workIn',
			flex: 0.75,
			renderer: 'yesNo'
		},
		comments: {
			text: 'ediweb.invitations.comments',
			dataIndex: 'comments',
			flex: 1
		}
	},
	upd_product_info_tracking: {
		trackingProdNum: {
			text: 'line.item.infoTracking.trackingProdNum',
			dataIndex: 'trackingProdNum',
			flex: 1
		},
		trackingUnitMes: {
			text: 'line.item.infoTracking.trackingUnitMes',
			dataIndex: 'trackingUnitMes',
			flex: 1,
			renderer(val, meta, rec) {
				return rec.get('trackingUnitMesName') || val;
			}
		},
		trackingProdQuan: {
			text: 'line.item.infoTracking.trackingProdQuan',
			align: 'right',
			dataIndex: 'trackingProdQuan',
			flex: 1
		},
		trackingAdd: {
			text: 'line.item.infoTracking.trackingAdd',
			dataIndex: 'trackingAdd',
			flex: 1
		}
	},
	fns_document_summary_base: {
		totalWithoutNDS: {
			text: 'column.total.net.amount',
			dataIndex: 'TotalWithoutNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			align: 'right',
			renderer: 'displayCurrencyDecimals'
		},
		mustPaid: {
			text: 'column.total.tax.amount',
			dataIndex: 'MustPaid',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'cost'
		},
		totalWithNDS: {
			text: 'column.total.gross.amount',
			dataIndex: 'TotalWithNDS',
			align: 'right',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		}
	},
	fns_upd_lines: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'lineNumber',
			flex: 0.5
		},
		productName: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'productName',
			flex: 2
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'count',
			flex: 0.75,
			align: 'right',
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 3,
				allowDecimals: true,
				allowBlank: false,
				minValue: 1
			},
			renderer: 'decimal'
		},
		okei: {
			text: 'column.unit.of.measure',
			dataIndex: 'productOkei',
			flex: 0.75,
			hidden: true,
			renderer: 'okei'
		},
		presenceGDT: {
			text: 'column.fns.presence.gdt',
			dataIndex: 'customsDeclarationInfo',
			flex: 0.75,
			hidden: true,
			renderer: function (value, column) {
				if (value.length > 0) {
					var numbersGTD = value.map(function (item) {
						return item.number;
					});
					column.tdAttr = 'data-qtip="' + numbersGTD.join('<br/>') + '"';
					return edi.i18n.getMessage('answer.exist');
				} else {
					return edi.i18n.getMessage('answer.no');
				}
			}
		},
		price: {
			text: 'column.fns.invoice.unit.net.price',
			dataIndex: 'cost',
			align: 'right',
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		taxSum: {
			text: 'column.tax.amount',
			dataIndex: 'taxSum',
			align: 'right',
			order: 7,
			flex: 0.75,
			renderer: function (value) {
				return edi.renderers.currencyWithSeparators(edi.utils.displayCurrencyDecimals(value.taxSum || 0, 2));
			}
		},
		tax: {
			text: 'column.fns.tax.rate',
			dataIndex: 'tax',
			align: 'right',
			order: 7,
			flex: 0.75
		},
		excise: {
			text: 'line.upd.excise',
			dataIndex: 'excise',
			align: 'right',
			flex: 0.75,
			renderer: function (value) {
				return value.sumExcise !== null
					? edi.renderers.currencyWithSeparators(edi.utils.displayCurrencyDecimals(value.sumExcise))
					: value.withoutExcise
					? value.withoutExcise
					: 'без акциза';
			}
		},
		costWithoutNDS: {
			text: 'column.net.amount',
			align: 'right',
			dataIndex: 'costWithoutNDS',
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		costWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'info',
			align: 'right',
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		}
	},
	fns_upd_summary: {
		totalWithoutNDS: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'totalWithoutNDS',
			align: 'right',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		mustPaid: {
			text: 'line.item.tax.amount',
			dataIndex: 'totalNDS',
			align: 'right',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		totalWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'totalWithNDS',
			align: 'right',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		}
	},
	fns_ukd_lines_5_01n: {
		lineNumInvoice: {
			xtype: 'treecolumn',
			text: 'column.line.number',
			htmlEncode: true,
			cls: 'ui-tree-panel-header-treecolumn',
			dataIndex: 'lineNumInvoice',
			sortable: false,
			order: 0,
			flex: 0.5
		},
		productName: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			// minWidth: 350,
			// maxWidth: 500,
			dataIndex: 'ProductName',
			sortable: false,
			flex: 1
		},
		state: {
			text: 'column.product.correct.state',
			dataIndex: 'State',
			sortable: false,
			htmlEncode: true,
			renderer: function (value, meta, record) {
				if (value === 'NewData') {
					value = record.isExpanded() || record.childNodes.length > 1 ? 'ediweb.' + value : null;
				}
				if (value === 'OriginalData') {
					value = 'ediweb.' + value;
				}
				return Ext.String.format(
					'<b>{0}</b>',
					value ? edi.i18n.getMessage('corrected.product.state.' + value) : ''
				);
			},
			flex: 2
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'Count',
			sortable: false,
			tdCls: 'editable',
			align: 'right',
			flex: 0.75,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				showLabelAllowBlank: false,
				minValue: 0
			},
			renderer: 'decimal'
		},
		productOkei: {
			text: 'column.unit.of.measure',
			dataIndex: 'ProductOkei',
			tdCls: 'editable',
			sortable: false,
			field: {
				xtype: 'combo',
				valueField: 'id',
				displayField: 'name_code',
				forceSelection: true,
				anyMatch: true,
				allowBlank: false,
				showLabelAllowBlank: false,
				queryMode: 'local',
				store: {
					model: 'edi.models.OKEI_CODES',
					data: 'data.okei_codes'
				}
			},
			flex: 0.75,
			renderer: 'okei'
		},
		price: {
			text: 'column.fns.invoice.unit.net.price',
			dataIndex: 'Cost',
			sortable: false,
			tdCls: 'editable',
			align: 'right',
			flex: 0.75,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				showLabelAllowBlank: false,
				minValue: 0,
				currWithZero: true
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			tdCls: 'editable',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'combo',
				allowBlank: false,
				showLabelAllowBlank: false,
				displayField: 'name',
				forceSelection: true,
				queryMode: 'local',
				store: {
					model: 'edi.models.SIMPLE',
					data: 'data.fnsTaxRates'
				},
				valueField: 'id'
			},
			flex: 0.75
		},
		nDSCost: {
			text: 'column.tax.amount',
			dataIndex: 'NDSCost',
			tdCls: 'editable',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				showLabelAllowBlank: false,
				minValue: 0,
				currWithZero: true
			},
			flex: 0.75,
			renderer: function (value) {
				return edi.renderers.currencyWithSeparators(
					edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
				);
			}
		},
		exciseCost: {
			text: 'column.excise.amount',
			dataIndex: 'ExciseCost',
			tdCls: 'editable',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				showLabelAllowBlank: false,
				minValue: 0
			},
			renderer: function (value, meta, record) {
				var result = value,
					state = record.get('State');
				if (state != 'Increase' && state != 'Decrease') {
					result = value == 0 || value == 'без акциза' ? 'без акциза' : value;
				}

				return result;
			},
			flex: 0.75
		},
		costWithoutNDS: {
			text: 'column.net.amount',
			dataIndex: 'CostWithoutNDS',
			tdCls: 'editable',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'numberfield',
				// validator: "validators.amount15", // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: true,
				currWithZero: true
			},
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		costWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'CostWithNDS',
			tdCls: 'editable',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'numberfield',
				// validator: "validators.amount15", // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: true,
				currWithZero: true
			},
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		}
	},
	corrected_fns_invoice_lines: {
		lineNumber: {
			xtype: 'treecolumn',
			text: 'column.line.number',
			htmlEncode: true,
			dataIndex: 'lineNum',
			sortable: false,
			order: 0,
			flex: 0.5
		},
		productName: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ProductName',
			sortable: false,
			flex: 2
		},
		state: {
			text: 'column.product.correct.state',
			dataIndex: 'State',
			sortable: false,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('corrected.product.state.' + value) : '';
			},
			flex: 2
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'Count',
			align: 'right',
			sortable: false,
			flex: 0.75,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: 'decimal'
		},
		productOkei: {
			text: 'column.unit.of.measure',
			dataIndex: 'ProductOkei',
			sortable: false,
			field: {
				xtype: 'combo',
				valueField: 'id',
				displayField: 'name_code',
				forceSelection: true,
				anyMatch: true,
				allowBlank: false,
				queryMode: 'local',
				store: {
					model: 'edi.models.OKEI_CODES',
					data: 'data.okei_codes'
				}
			},
			flex: 0.75,
			renderer: 'okei'
		},
		price: {
			text: 'column.fns.invoice.unit.net.price',
			dataIndex: 'Cost',
			align: 'right',
			sortable: false,
			flex: 0.75,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true,
				emptyValue: 0
			},
			renderer: 'displayCurrencyDecimals'
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'combo',
				allowBlank: false,
				displayField: 'name',
				forceSelection: true,
				queryMode: 'local',
				store: {
					model: 'edi.models.SIMPLE',
					data: 'data.fnsTaxRates'
				},
				valueField: 'id'
			},
			flex: 0.75
		},
		nDSCost: {
			text: 'column.tax.amount',
			dataIndex: 'NDSCost',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true
			},
			flex: 0.75,
			renderer: function (value) {
				return edi.renderers.currencyWithSeparators(
					edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
				);
			}
		},
		exciseCost: {
			text: 'column.excise.amount',
			dataIndex: 'ExciseCost',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: function (value, meta, record) {
				var result = value,
					state = record.get('State');
				if (state != 'Increase' && state != 'Decrease') {
					result = value == 0 || value == 'без акциза' ? 'без акциза' : value;
				}

				return result;
			},
			flex: 0.75
		},
		costWithoutNDS: {
			text: 'column.net.amount',
			dataIndex: 'CostWithoutNDS',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true,
				emptyValue: 0
			},
			flex: 0.75,
			renderer: 'displayCurrencyDecimals'
		},
		costWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'CostWithNDS',
			align: 'right',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true,
				emptyValue: 0
			},
			flex: 0.75,
			renderer: 'displayCurrencyDecimals'
		}
	},
	corrected_fns_invoice_summary: {
		state: {
			text: 'column.product.correct.state',
			dataIndex: 'State',
			sortable: false,
			hideable: false,
			flex: 3,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('corrected.product.state.' + value) : '';
			}
		},
		totalWithoutNDS: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalWithoutNDS',
			flex: 1,
			align: 'right',
			sortable: false,
			hideable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15',
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: true,
				minValue: 0
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		mustPaid: {
			text: 'line.item.tax.amount',
			dataIndex: 'MustPaid',
			align: 'right',
			flex: 1,
			sortable: false,
			hideable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15',
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: true,
				minValue: 0
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		totalWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'TotalWithNDS',
			align: 'right',
			flex: 1,
			sortable: false,
			hideable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15',
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: true,
				minValue: 0
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		}
	},
	documents_sign_info: {
		subjectDN: {
			hidden: true,
			flex: 1
		},
		fullName: {
			flex: 1
		},
		issuer: {
			hidden: true,
			flex: 1
		},
		signDate: {
			order: 10
		}
	}
});

edi.columns.addColumns({
	corrected_fns_invoice_lines_5_02: {
		parents: 'corrected_fns_invoice_lines',
		config: {
			taxRate: {
				field: {
					xtype: 'combo',
					allowBlank: false,
					align: 'right',
					displayField: 'name',
					forceSelection: true,
					queryMode: 'local',
					store: {
						model: 'edi.models.SIMPLE',
						data: 'data.fnsNTaxRates'
					},
					valueField: 'id'
				}
			}
		}
	},
	fns_ukd_lines_5_01n_02: {
		parents: 'fns_ukd_lines_5_01n',
		config: {
			taxRate: {
				field: {
					xtype: 'combo',
					allowBlank: false,
					align: 'right',
					displayField: 'name',
					forceSelection: true,
					queryMode: 'local',
					store: {
						model: 'edi.models.SIMPLE',
						data: 'data.fnsNTaxRates'
					},
					valueField: 'id'
				}
			}
		}
	},
	fns_upd_summary_editable: {
		parents: 'fns_upd_summary',
		config: {
			totalWithoutNDS: {
				tdCls: 'editable',
				align: 'right',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			mustPaid: {
				tdCls: 'editable',
				align: 'right',
				editor: {
					xtype: 'numberfield',
					validator: function (value) {
						return parseFloat(value) >= 0 && String(value).length <= 14
							? true
							: edi.i18n.getMessage('invalid.field.value');
					},
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalWithNDS: {
				tdCls: 'editable',
				align: 'right',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			}
		}
	},
	fns_upd_lines_priceWithNDS: {
		parents: 'fns_upd_lines',
		config: {
			priceWithNDS: {
				text: 'column.unit.gross.price',
				align: 'right',
				dataIndex: 'priceWithNDS',
				flex: 0.75,
				renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
			}
		}
	},
	user_cert: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true,
			sortable: false
		},
		user: {
			text: 'column.user',
			sortable: false,
			flex: 2,
			renderer: 'user',
			dataIndex: 'user'
		},
		certificate: {
			text: 'column.certificate',
			dataIndex: 'subjectName',
			flex: 2,
			sortable: false,
			renderer: function (objData) {
				var name = objData.lastname + ' ' + objData.firstname,
					position = objData.position ? ', ' + objData.position : '',
					cn = objData.commonName ? ', ' + objData.commonName : '';

				return name + position + cn;
			}
		}
	}
});

edi.columns.addColumns({
	ew_invitations_draft: {
		parents: 'ew_invitations',
		config: {
			creationDate: {
				text: 'invitations.column.createdDate'
			},
			modifyDate: {
				text: 'invitations.column.modifiedDate'
			}
		}
	},
	ew_invitations_mass: {
		parents: 'ew_invitations',
		config: {
			invitationName: {
				text: 'invitation.mass.column.name',
				sortable: false,
				flex: 2,
				order: 1,
				dataIndex: 'invitationName'
			},
			creationDate: {
				text: 'invitations.column.createdDate',
				order: 2
			},
			modifyDate: {
				text: 'invitations.column.modifiedDate',
				order: 3
			},
			productRole: {
				order: 4
			},
			invitationCount: {
				text: 'invitation.mass.column.count',
				sortable: false,
				flex: 1,
				order: 5,
				dataIndex: 'invitationCount',
				renderer: function (value, tdAttr, record) {
					const massInvitations = value?.invitations ?? [];
					const state = record?.get('state') ?? null;
					const countInvitations = massInvitations?.length ?? 0;

					if (state !== edi.constants.STATE.SENT) {
						return countInvitations;
					}
					let countSentInvitation = 0;
					massInvitations.forEach((invitation) => {
						if (invitation.state !== edi.constants.STATE.DRAFT) countSentInvitation += 1;
					});
					return countSentInvitation;
				}
			},
			state: {
				order: 6,
				htmlEncode: true,
				renderer: edi.renderers.massInvitationStatus
			},
			receiverName: null,
			receiverInn: null,
			receiverKpp: null
		}
	},
	mass_invitations_list: {
		organizationName: {
			order: 1,
			text: 'ediweb.invitations.organization.name',
			sortable: false,
			align: 'center',
			flex: 1,
			dataIndex: 'organizationName'
		},
		invitationInn: {
			order: 2,
			text: 'column.org.inn',
			sortable: true,
			flex: 1,
			dataIndex: 'invitationInn'
		},
		invitationKpp: {
			order: 3,
			text: 'column.org.kpp',
			sortable: true,
			flex: 1,
			dataIndex: 'invitationKpp'
		},
		invitationEmail: {
			order: 4,
			text: 'email',
			sortable: true,
			flex: 1,
			dataIndex: 'invitationEmail'
		},
		workOnEdiweb: {
			order: 5,
			text: 'invitation.mass.column.work.in.ediweb',
			sortable: true,
			flex: 1,
			dataIndex: 'workOnEdiweb',
			htmlEncode: true,
			renderer: function (val) {
				return val
					? '<span role="img" class="invitations-mass-workinediweb" style="font-family:Material Icons;">&#xe876</span>'
					: null;
			}
		},
		invitationComment: {
			order: 7,
			text: 'invitation.mass.column.comment',
			sortable: true,
			flex: 1,
			dataIndex: 'invitationComment'
		}
	}
});

edi.columns.addColumns({
	bank_account: {
		bik: {
			text: 'column.bik',
			flex: 0.7,
			dataIndex: 'bik',
			sortable: false
		},
		corrAccNumber: {
			text: 'column.corr.acc.number',
			flex: 1.2,
			dataIndex: 'corrAccNumber',
			sortable: false
		},
		number: {
			text: 'company.bank.account',
			flex: 1.2,
			dataIndex: 'number',
			sortable: false
		},
		preferredOwnerName: {
			text: 'column.preferred.owner.name',
			flex: 1.5,
			dataIndex: 'preferredOwnerName',
			sortable: false
		}
	},
	ew_certificates: {
		id: {
			text: 'documents.column.id',
			sortable: false,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		serialNumber: {
			text: 'documents.column.cert.number',
			sortable: false,
			flex: 2,
			hidden: true,
			renderer: 'valueAsToolTip',
			dataIndex: 'SerialNumber'
		},
		subjectName: {
			text: 'column.certificate.subject',
			sortable: false,
			flex: 2,
			renderer: 'certificateSubjectString',
			dataIndex: 'SubjectName'
		},
		issuerName: {
			text: 'column.certificate.issuer',
			sortable: false,
			flex: 2,
			hidden: true,
			renderer: 'certificateIssuerString',
			dataIndex: 'IssuerName'
		},
		validFromDate: {
			text: 'column.valid.from.date',
			dataIndex: 'validFromDate',
			flex: 1,
			sortable: true,
			renderer: 'docTime'
		},
		validToDate: {
			text: 'column.valid.to.date',
			dataIndex: 'validToDate',
			flex: 1,
			sortable: true,
			renderer: 'docTime'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		algorithm: {
			text: 'column.certificate.algorithm',
			hidden: true,
			sortable: false,
			dataIndex: 'algorithm',
			renderer: function (val) {
				return val ? val.oid : '';
			}
		},
		state: {
			text: 'documents.column.cert.status',
			dataIndex: 'state',
			htmlEncode: true,
			renderer(value, meta) {
				let text = edi.i18n.getMessage('certificate.status.' + value);
				return edi.renderers.baseStateRenderer(value, text, meta);
			},
			flex: 1
		}
	},
	mass_invitations_list_edit: {
		parents: 'mass_invitations_list',
		config: {
			state: {
				order: 6,
				text: 'document.state',
				htmlEncode: true,
				renderer: function (value, meta, record) {
					const direction = getDirectionInvitation(record.getData());
					let { state, statusKey } = edi.renderers._invitationStatus(record.getData(), direction || 'empty');
					return edi.renderers.baseStateRenderer(state, edi.i18n.getMessage(statusKey), meta);
				},
				dataIndex: 'state',
				flex: 1
			}
		}
	},
	ew_subdivisions: {
		name: {
			xtype: 'treecolumn',
			text: 'org.profile.subdivision',
			htmlEncode: true,
			sortable: false,
			flex: 1,
			dataIndex: 'name',
			renderer: function (val) {
				return val || ' '; //пробел нужен, т.к. пустое значение заменяется на Root где то в самом компоненте триколумн
			}
		},
		count: {
			text: 'org.profile.subdivision.employeesCount',
			sortable: false,
			flex: 1,
			dataIndex: 'count'
		}
	},
	ew_employees: {
		user: {
			text: 'full.name.abbr',
			sortable: false,
			flex: 2,
			dataIndex: 'user',
			renderer: function (value, meta, record) {
				let user = edi.renderers.getUserInfo(record);
				return `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`;
			}
		},
		email: {
			text: 'user.profile.email',
			sortable: false,
			flex: 1.5,
			dataIndex: 'email',
			renderer: function (value, meta, record) {
				let user = edi.renderers.getUserInfo(record);
				return user.email || '';
			}
		},
		position: {
			text: 'column.position',
			sortable: false,
			flex: 1,
			dataIndex: 'position'
		},
		subdivision: {
			text: 'org.profile.subdivision',
			sortable: false,
			flex: 1,
			dataIndex: 'subdivision',
			renderer: function (v) {
				return v ? v.name : '';
			}
		},
		status: {
			text: 'document.status',
			sortable: false,
			flex: 1,
			dataIndex: 'status',
			htmlEncode: true,
			renderer(value, meta, record) {
				let { state, statusKey } = edi.renderers.employeesStatus(record.getData());
				return edi.renderers.baseStateRenderer(state, edi.i18n.getMessage(statusKey), meta);
			}
		},
		userAdmin: {
			text: 'org.profile.employees.userAdmin',
			sortable: false,
			flex: 1,
			dataIndex: 'userAdmin',
			renderer: function (v) {
				return v ? edi.i18n.getMessage('answer.yes') : '—';
			}
		},
		productInfos: {
			text: 'ediweb.employee.access.to.products.externalTitle',
			tdCls: 'employee-products-access',
			sortable: false,
			dataIndex: 'productInfos',
			flex: 2,
			htmlEncode: true,
			renderer: function (value) {
				let res = '',
					uniqueArr = [];

				value.forEach(function (item) {
					uniqueArr.push(item.productType);
				});
				uniqueArr = [...new Set(uniqueArr)];

				uniqueArr.forEach(function (product) {
					res += edi.renderers.getProductLogo(product.toLowerCase());
				});

				return res;
			}
		}
	},
	ew_employees_modal: {
		user: {
			text: 'full.name.abbr',
			sortable: false,
			flex: 2,
			dataIndex: 'user',
			renderer: function (value, meta, record) {
				let user = edi.renderers.getUserInfo(record);
				return `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`;
			}
		},
		email: {
			text: 'user.profile.email',
			sortable: false,
			flex: 1.5,
			dataIndex: 'email',
			renderer: function (value, meta, record) {
				let user = edi.renderers.getUserInfo(record);
				return user.email || '';
			}
		},
		position: {
			text: 'column.position',
			sortable: false,
			flex: 1,
			dataIndex: 'position'
		},
		subdivision: {
			text: 'org.profile.subdivision',
			sortable: false,
			flex: 1,
			dataIndex: 'subdivision',
			renderer: function (v) {
				return v ? v.name : '';
			}
		},
		status: {
			text: 'document.status',
			sortable: false,
			flex: 1,
			dataIndex: 'status',
			htmlEncode: true,
			renderer(value, meta, record) {
				let { state, statusKey } = edi.renderers.employeesStatus(record.getData());
				return edi.renderers.baseStateRenderer(state, edi.i18n.getMessage(statusKey), meta);
			}
		},
		personalId: {
			text: 'person.snils',
			sortable: false,
			flex: 1,
			dataIndex: 'personalId',
			htmlEncode: true,
			renderer(value, meta, record) {
				let snils = record.get('user')?.personalId;
				return snils ? edi.converters.SNILS.convertForShow(snils) : '';
			}
		}
	},
	upd_declaration_info_ediweb: {
		parents: 'upd_declaration_info',
		config: {
			countryCode: {
				hideable: true
			},
			number: {
				hideable: true
			}
		}
	},
	ew_routes: {
		name: {
			text: 'org.profile.routes.name',
			sortable: false,
			flex: 1.5,
			dataIndex: 'name'
		},
		stagesCount: {
			text: 'org.profile.routes.stagesCount',
			sortable: false,
			flex: 1,
			dataIndex: 'stagesCount'
		},
		orderOfAgreement: {
			text: 'org.profile.routes.orderOfAgreement',
			sortable: false,
			flex: 1,
			dataIndex: 'orderOfAgreement',
			renderer: function (value, meta, record) {
				return value ? edi.i18n.getMessage('org.profile.routes.orderOfAgreement.' + value) : '';
			}
		},
		routeType: {
			text: 'org.profile.routes.routeType',
			sortable: false,
			flex: 1,
			dataIndex: 'routeType',
			renderer: function (value, meta, record) {
				return value ? edi.i18n.getMessage('org.profile.routes.routeType.' + value) : '';
			}
		},

		information: {
			text: 'org.profile.routes.information',
			sortable: false,
			flex: 1,
			dataIndex: 'information',
			htmlEncode: true,
			renderer: function (value, meta, record) {
				var result = [];
				if (Array.isArray(value) && value.length) {
					value.forEach(function (v) {
						result.push(edi.i18n.getMessage(v));
					});
					meta.tdAttr = 'data-qtip="' + result.join(', ') + '"';
				}
				return value.length ? '<span style="color: #E82000">' + result.join(', ') + '</span>' : null;
			}
		}
	},
	ew_approvals: {
		sender: {
			text: 'document.sender',
			sortable: false,
			flex: 1,
			dataIndex: 'sender',
			renderer: function (value) {
				var res = '';
				if (value) {
					let employeeValues = edi.renderers.getUserInfo(value);
					res = `${employeeValues.lastName || ''} ${employeeValues.firstName || ''} ${
						employeeValues.middleName || ''
					}`;
				}
				return res;
			}
		},
		title: {
			text: 'ew.documents.approvals.title',
			sortable: false,
			flex: 2,
			dataIndex: 'title',
			renderer: function (value, meta, record) {
				return getApprovalDocumentTitle(record.getData(), value);
			}
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		id: {
			text: 'ID',
			dataIndex: 'id',
			flex: 1
		},
		docNumber: {
			text: 'documents.column.number',
			dataIndex: 'docNumber',
			flex: 1
		},
		routeName: {
			text: 'ew.documents.approvals.routeName',
			dataIndex: 'routeName',
			flex: 1,
			sortable: false,
			renderer: function (value, meta, rec) {
				return edi.i18n.getMessage(value);
			}
		},
		state: {
			text: 'ew.documents.approvals.state',
			dataIndex: 'state',
			flex: 1,
			htmlEncode: true,
			renderer: function (value, meta, rec) {
				return edi.renderers.baseStateRenderer(
					value,
					edi.i18n.getMessage('ew.documents.approvals.state.' + value),
					meta
				);
			}
		},
		stage: {
			text: 'ew.documents.approvals.stage',
			dataIndex: 'stagesCount',
			flex: 1,
			sortable: false,
			renderer: function (value, meta, rec) {
				return rec.get('currentStageNumber') + '/' + value;
			}
		},
		executor: {
			text: 'ew.documents.approvals.executor',
			dataIndex: 'executors',
			flex: 1,
			sortable: false,
			renderer: function (value, meta, rec) {
				var name = '';
				if (Array.isArray(value) && value.length) {
					name = edi.renderers.renderExecutorForApproval(value);
				}
				meta.tdAttr = 'data-qtip="' + name + '"';
				return name;
			}
		}
	}
});

edi.columns.addColumns({
	ew_approvals_archive: {
		parents: 'ew_approvals',
		config: {
			stage: null,
			executor: null,
			id: null,
			headerId: {
				text: 'ID',
				order: 3,
				hidden: true,
				sortable: false,
				dataIndex: 'headerId',
				flex: 1
			},
			sender: {
				text: 'document.sender',
				sortable: false,
				flex: 1,
				dataIndex: 'sender',
				renderer: function (value, meta, rec) {
					return value;
				}
			},
			title: {
				flex: 1
			}
		}
	},
	ew_approvals_modal: {
		parents: 'ew_approvals',
		config: {
			docNumber: null,
			sender: null,
			routeName: null
		}
	},
	tariff_edi_grid: {
		documentsNumber: {
			text: 'ediweb.tariff.documentsNumber',
			dataIndex: 'documentsNumber',
			sortable: true,
			flex: 1
		},
		documentCost: {
			text: 'ediweb.tariff.edi.grid.documentCost',
			dataIndex: 'documentsCost',
			align: 'right',
			sortable: true,
			flex: 1
		}
	}
});

const EW_GRID_COMMON_COLUMN_CONFIG_NAME = 'document_extended';
edi.columns.addColumns({
	[EW_GRID_COMMON_COLUMN_CONFIG_NAME]: {
		parents: 'document',
		config: {
			crpt_state: {
				text: 'documents.column.crpt_status',
				dataIndex: 'crpt_status',
				renderer: 'crpt_status',
				htmlEncode: true,
				sortable: false,
				hidden: true,
				order: 324,
				flex: 1.5
			},
			edem_status: {
				text: 'documents.column.edem_status',
				dataIndex: 'edem_status',
				renderer: function (value, meta, record) {
					let statusElems = edi.renderers.edem_status(value, meta, record);
					return statusElems.text;
				},
				htmlEncode: true,
				sortable: false,
				hidden: true,
				order: 327,
				flex: 1
			}
		}
	}
});

export { EW_GRID_COMMON_COLUMN_CONFIG_NAME };
