import { createNamedDocumentBlock } from '../documentBlock/createDocumentBlock';
// @ts-ignore
import { createContainer, createFieldBlock, createFieldSet, createMaxWidthContainer } from '@UIkit/components/panels';
// @ts-ignore
import { createCombo, createDateField, createTextField } from '@UIkit/components/fields';
import { PersonHandingGoodsBlock } from '../personHandingGoodsBlock/createPersonHandingGoodsBlock';
import { calculateUpdBusinessProcess } from '../../methods';

export interface TransportAndCargoProps {
	documentData: AnyObject;
	initialData: AnyObject;

	getCurrentUPDState: () => {
		updFunction: string;
		isEdit: boolean;
		isCopy: boolean;
	};
	[key: string]: any;
}

export class TransportAndCargo {
	fields: {
		internationalRulesValue?: ExtComponent;
		internationalRulesVersion?: ExtComponent;
		transportInfo?: ExtComponent;
		contractItemTransferDate?: ExtComponent;
		transferInfo?: ExtComponent;
		contentAction?: ExtComponent;
		typeAction?: ExtComponent;
		shippingDate?: ExtComponent;
		startingDate?: ExtComponent;
		endingDate?: ExtComponent;

		//Поля из PersonHandingGoodsBlock
		firstName?: ExtComponent;
		lastName?: ExtComponent;
		patronymicName?: ExtComponent;
		position?: ExtComponent;
		inn?: ExtComponent;
		orgName?: ExtComponent;
		orgInn?: ExtComponent;
		otherInformation?: ExtComponent;
		handingGoodsTypeField?: ExtComponent;

		[fieldName: string]: ExtComponent | undefined;
	};
	blocks: {
		shipmentGoodsReason?: ExtComponent;
		transferConfirmationDocument?: ExtComponent;

		//Блоки из PersonHandingGoodsBlock
		authority?: ExtComponent;
		orgAuthority?: ExtComponent;
		orgWorkerAuthority?: ExtComponent;

		[blockName: string]: ExtComponent | undefined;
	};

	personHandingGoods: PersonHandingGoodsBlock;

	props: TransportAndCargoProps;

	path: string = 'dokument.svProdPer.svPer';

	constructor(props: TransportAndCargoProps, overrides?: Partial<TransportAndCargo>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: TransportAndCargoProps, overrides?: Partial<TransportAndCargo>) {
		const me = this;

		me.fields = {};
		me.blocks = {};

		Ext.merge(me, overrides);
		me.props = props;
	}

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		return [me.path, ...name].filter(Boolean).join('.');
	}

	validateFields() {
		const me = this;
		me.validateShippingDateField();
		me.validateStartingDateField();
		me.validateEndingDateField();
	}

	getFields() {
		const me = this;
		return {
			...me.fields,
			...me.personHandingGoods.getFields()
		};
	}

	getBlocks() {
		const me = this;
		return {
			...me.blocks,
			...me.personHandingGoods.getBlocks()
		};
	}

	createShipmentGoodsReasonDocumentBlock(): ExtComponent {
		const me = this;
		const name = me.createFieldName('osnPer');
		return (me.blocks.shipmentGoodsReason = createNamedDocumentBlock({
			data: edi.utils.getObjectProperty(me.props.documentData, name, true),
			name,
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods')
		}));
	}

	createInternationalRulesValueField(): ExtComponent {
		const me = this;
		return (me.fields.internationalRulesValue = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.transferInfo.transportation.inkoterms'),
			name: me.createFieldName('tran.inkoterms'),
			valueSrc: me.props.documentData,
			maxLength: 3,
			minLength: 3
		}));
	}

	createInternationalRulesVersionField(): ExtComponent {
		const me = this;
		return (me.fields.internationalRulesVersion = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.transferInfo.transportation.verInkoterms'),
			name: me.createFieldName('tran.verInkoterms'),
			valueSrc: me.props.documentData,
			maxLength: 4,
			minLength: 4
		}));
	}

	createTransportInfoField(): ExtComponent {
		const me = this;
		return (me.fields.transportInfo = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.transferInfo.transportation.svTran'),
			name: me.createFieldName('tran.svTran'),
			isTextarea: true,
			rowsHtmlAttributeValue: 4,
			valueSrc: me.props.documentData,
			maxLength: 1000
		}));
	}

	createTransportationView(): ExtComponent {
		const me = this;

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.transferInfo.transportation'),
			layout: {
				type: 'grid',
				area: [[4, 6]]
			},
			items: [
				createContainer({
					layout: {
						type: 'grid',
						gap: 13
					},
					items: [me.createInternationalRulesValueField(), me.createInternationalRulesVersionField()]
				}),
				me.createTransportInfoField()
			]
		});
	}

	createContractItemTransferDateField(): ExtComponent {
		const me = this;

		return (me.fields.contractItemTransferDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			format: edi.constants.DATE_FORMAT.FNS,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			valueSrc: me.props.documentData,
			name: me.createFieldName('svPerVeschi.dataPerVesch')
		}));
	}

	createTransferInfoField(): ExtComponent {
		const me = this;
		return (me.fields.transferInfo = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.info'),
			name: me.createFieldName('svPerVeschi.svPerVesch'),
			valueSrc: me.props.documentData,
			maxLength: 1000
		}));
	}

	createTransferConfirmationDocument(): ExtComponent {
		const me = this;
		const name = me.createFieldName('svPerVeschi.dokPerVesch');
		return (me.blocks.transferConfirmationDocument = createNamedDocumentBlock(
			{
				data: edi.utils.getObjectProperty(me.props.documentData, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.transfer.confirmation.document')
			},
			{
				isSingleDocument: true
			}
		));
	}

	createContractItemTransferInfoView(): ExtComponent {
		const me = this;
		return createContainer({
			layout: {
				type: 'grid'
			},
			items: [
				createMaxWidthContainer({
					items: createFieldBlock({
						title: edi.i18n.getMessage('documents.fns_upd_5_02n.contract.item.transfer'),
						layout: {
							type: 'grid',
							area: [[2, 8]]
						},
						items: [me.createContractItemTransferDateField(), me.createTransferInfoField()]
					})
				}),
				me.createTransferConfirmationDocument()
			]
		}) as ExtComponent;
	}

	createContentActionField(): ExtComponent {
		const me = this;
		const contentActionItems: {
			[key: string]: string;
		} = {
			itemsTransferred: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.itemsTransferred'),
			workPassed: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed'),
			servicesRendered: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered')
		};
		let contentActionValue = '';
		const name = me.createFieldName('sodOper');

		for (let key in contentActionItems) {
			if (contentActionItems[key] === edi.utils.getObjectProperty(me.props.documentData, name)) {
				contentActionValue = edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.' + key);
			} else {
				contentActionValue = edi.utils.getObjectProperty(me.props.documentData, name);
			}
		}

		const { isEdit, isCopy } = me.props.getCurrentUPDState();

		const contentAction = (me.fields.contentAction = createCombo({
			maxLength: 255,
			forceSelection: false,
			allowBlank: false,
			store: edi.stores.createMemoryStore(
				[
					{
						id: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.itemsTransferred'),
						name: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.itemsTransferred')
					},
					{
						id: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed'),
						name: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed')
					},
					{
						id: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered'),
						name: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered')
					}
				],
				'SIMPLE',
				true
			),
			value:
				isEdit || isCopy
					? contentActionValue
					: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered'),
			name,
			validator: function () {
				return this.allowBlank || !!this.getValue();
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: contentAction
		});
	}

	createTypeActionField(): ExtComponent {
		const me = this;
		const typeAction = (me.fields.typeAction = createTextField({
			allowBlank: true,
			maxLength: 255,
			qtipText: edi.i18n.getMessage('documents.fns_upd.transferInfo.typeAction.toolTip'),
			valueSrc: me.props.documentData,
			name: me.createFieldName('vidOper')
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.typeAction'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: typeAction
		});
	}

	validateShippingDateField() {
		const me = this;

		if (!me.fields.shippingDate) return;

		const { updFunction } = me.props.getCurrentUPDState();
		const { isSCHFDOP, isDOP } = calculateUpdBusinessProcess(updFunction);

		const startingDateValue = me.fields.startingDate?.getValue();
		const endingDateValue = me.fields.endingDate?.getValue();
		const isUpdSCHFDOPorDOP = isSCHFDOP || isDOP;

		//Элемент обязателен при <Функция> = СЧФДОП | ДОП и при отсутствии <ДатаНачПер> и <ДатаОконПер>
		me.fields.shippingDate.allowBlank = !(isUpdSCHFDOPorDOP && !endingDateValue && !startingDateValue);

		me.fields.shippingDate.isValid();
	}
	createShippingDateField(): ExtComponent {
		const me = this;

		const shippingDate = (me.fields.shippingDate = createDateField({
			allowBlank: true,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			valueSrc: me.props.documentData,
			name: me.createFieldName('dataPer'),
			listeners: {
				change: () => {
					me.validateStartingDateField();
					me.validateEndingDateField();
				}
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.shippingDate'),
			layout: {
				type: 'grid',
				area: [2]
			},
			items: shippingDate
		});
	}

	validateStartingDateField() {
		const me = this;

		if (!me.fields.shippingDate || !me.fields.startingDate) return;

		const { updFunction } = me.props.getCurrentUPDState();
		const { isSCHFDOP, isDOP } = calculateUpdBusinessProcess(updFunction);

		const shippingDateValue = me.fields.shippingDate?.getValue();
		const isUpdSCHFDOPorDOP = isSCHFDOP || isDOP;

		//Элемент обязателен при <Функция> = СЧФДОП | ДОП и при отсутствии <ДатаПер>.
		me.fields.startingDate.allowBlank = !(isUpdSCHFDOPorDOP && !shippingDateValue);
		me.fields.startingDate.isValid();
	}
	createStartingDateField(): ExtComponent {
		const me = this;
		return (me.fields.startingDate = createDateField({
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			valueSrc: me.props.documentData,
			name: me.createFieldName('dataNachPer'),
			listeners: {
				change: function (comp: ExtComponent) {
					if (comp.getValue()) {
						me.fields.endingDate?.setMinValue(comp.getValue());
					}
					comp.isValid();

					me.validateShippingDateField();
				}
			}
		}));
	}

	validateEndingDateField() {
		const me = this;

		if (!me.fields.shippingDate || !me.fields.endingDate) return;

		const { updFunction } = me.props.getCurrentUPDState();
		const { isSCHFDOP, isDOP } = calculateUpdBusinessProcess(updFunction);

		const shippingDateValue = me.fields.shippingDate?.getValue();
		const isUpdSCHFDOPorDOP = isSCHFDOP || isDOP;

		//Элемент обязателен при <Функция> = СЧФДОП | ДОП и при отсутствии <ДатаПер>.
		me.fields.endingDate.allowBlank = !(isUpdSCHFDOPorDOP && !shippingDateValue);
		me.fields.endingDate.isValid();
	}
	createEndingDateField(): ExtComponent {
		const me = this;

		return (me.fields.endingDate = createDateField({
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			valueSrc: me.props.documentData,
			name: me.createFieldName('dataOkonPer'),
			listeners: {
				change: function (comp: ExtComponent) {
					if (!me.fields.startingDate?.getValue()) {
						comp.setValue(null);
					}
					comp.isValid();

					me.validateShippingDateField();
				}
			}
		}));
	}

	createPersonHandingGoodsBlock(): ExtComponent {
		const me = this;
		me.personHandingGoods = new PersonHandingGoodsBlock({ data: me.props.documentData });
		const personHandingGoodsBlock = me.personHandingGoods.createPersonHandingGoodsBlock() as ExtComponent;
		Object.assign(me.fields, me.personHandingGoods.getFields());
		return personHandingGoodsBlock;
	}

	createTransportAndCargoFieldSet(): ExtComponent {
		const me = this;
		return createFieldSet({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'),
			layout: {
				type: 'grid',
				gap: 24
			},
			validateFields: me.validateFields.bind(me),
			collapsible: true,
			items: [
				me.createShipmentGoodsReasonDocumentBlock(),
				me.createPersonHandingGoodsBlock(),
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [me.createTransportationView()]
				}),
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [me.createContentActionField(), me.createTypeActionField(), me.createShippingDateField()]
				}),
				me.createContractItemTransferInfoView(),

				createFieldBlock({
					title: edi.i18n.getMessage('documents.fns_upd.transferInfo.starting'),
					layout: {
						type: 'grid',
						area: [[2, 2]]
					},
					items: [me.createStartingDateField(), me.createEndingDateField()]
				})
			],
			listeners: {
				afterrender: () => {
					me.validateFields();
				}
			}
		});
	}
}

export const createTransportAndCargo = (props: TransportAndCargoProps, overrides?: Partial<TransportAndCargo>) => {
	const transportAndCargo = new TransportAndCargo(props, overrides);
	const transportAndCargoFieldSet = transportAndCargo.createTransportAndCargoFieldSet() as ExtComponent;
	transportAndCargoFieldSet.getFields = () => transportAndCargo.getFields();
	return transportAndCargoFieldSet;
};
