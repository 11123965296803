/*
    Замена прав УПД по задаче PC-4357

	SIGN_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR_SUPPLIER   // -> SIGN_EDI_FNS_UPD_SUPPLIER, в Core не использовалось
	SIGN_EDI_FNS_UPD_SCHF_WITHOUT_OPERATOR_SUPPLIER      // -> SIGN_EDI_FNS_UPD_SUPPLIER, в Core не использовалось
	SIGN_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE              // -> SIGN_EDI_FNS_UPD_SUPPLIER
	SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER                    // -> SIGN_EDI_FNS_UPD_SUPPLIER
	SIGN_EDI_FNS_UPD_SCHF_SUPPLIER                       // -> SIGN_EDI_FNS_UPD_SUPPLIER
	SIGN_EDI_FNS_UPD_DOP_SUPPLIER                        // -> SIGN_EDI_FNS_UPD_SUPPLIER

	SIGN_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR_CONSUMER   // -> SIGN_EDI_FNS_UPD_CONSUMER, в Core не использовалось
	SIGN_EDI_FNS_UPD_SCHF_WITHOUT_OPERATOR_CONSUMER      // -> SIGN_EDI_FNS_UPD_CONSUMER, в Core не использовалось
	SIGN_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE_CONSUMER     // -> SIGN_EDI_FNS_UPD_CONSUMER
	SIGN_EDI_FNS_UPD_SCHFDOP_ROAMING_IN_CONSUMER         // -> SIGN_EDI_FNS_UPD_CONSUMER
	SIGN_EDI_FNS_UPD_SCHF_ROAMING_IN_CONSUMER            // -> SIGN_EDI_FNS_UPD_CONSUMER
	SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER                    // -> SIGN_EDI_FNS_UPD_CONSUMER
	SIGN_EDI_FNS_UPD_SCHF_CONSUMER                       // -> SIGN_EDI_FNS_UPD_CONSUMER
	SIGN_EDI_FNS_UPD_DOP_CONSUMER                        // -> SIGN_EDI_FNS_UPD_CONSUMER

	DELETE_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR          // -> DELETE_EDI_FNS_UPD, в Core не использовалось
	DELETE_EDI_FNS_UPD_SCHF_WITHOUT_OPERATOR             // -> DELETE_EDI_FNS_UPD, в Core не использовалось
	DELETE_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE            // -> DELETE_EDI_FNS_UPD, в Core не использовалось
	DELETE_EDI_FNS_UPD_SCHFDOP                           // -> DELETE_EDI_FNS_UPD
	DELETE_EDI_FNS_UPD_SCHF                              // -> DELETE_EDI_FNS_UPD
	DELETE_EDI_FNS_UPD_DOP								 // -> DELETE_EDI_FNS_UPD

	COMPLETE_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR        // -> COMPLETE_EDI_FNS_UPD, в Core не использовалось
	COMPLETE_EDI_FNS_UPD_SCHF_WITHOUT_OPERATOR           // -> COMPLETE_EDI_FNS_UPD, в Core не использовалось
	COMPLETE_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE          // -> COMPLETE_EDI_FNS_UPD, в Core не использовалось
	COMPLETE_EDI_FNS_UPD_SCHFDOP                         // -> COMPLETE_EDI_FNS_UPD
	COMPLETE_EDI_FNS_UPD_DOP                             // -> COMPLETE_EDI_FNS_UPD
	COMPLETE_EDI_FNS_UPD_SCHF                            // -> COMPLETE_EDI_FNS_UPD

	CREATE_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR_P2       // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR          // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_EDI_FNS_UPD_SCHF_WITHOUT_OPERATOR             // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_P2_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE         // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE            // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_EDI_FNS_UPD_SCHFDOP_P2                        // -> CREATE_EDI_FNS_UPD
	CREATE_EDI_FNS_UPD_DOP_P2                            // -> CREATE_EDI_FNS_UPD
	CREATE_EDI_FNS_UPD_P2                                // -> CREATE_EDI_FNS_UPD
	CREATE_EDI_FNS_UPD_SCHFDOP                           // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_EDI_FNS_UPD_SCHF                              // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_EDI_FNS_UPD_DOP                               // -> CREATE_EDI_FNS_UPD, в Core не использовалось
	CREATE_EDI_FNS_DP_UVUTOCH                            // -> CREATE_EDI_FNS_UPD, в Core не использовалось

	EDIT_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR_P2         // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR            // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_EDI_FNS_UPD_SCHF_WITHOUT_OPERATOR               // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_P2_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE           // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE              // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_EDI_FNS_UPD_SCHFDOP                             // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_EDI_FNS_UPD_SCHF                                // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_EDI_FNS_UPD_DOP                                 // -> EDIT_EDI_FNS_UPD, в Core не использовалось
	EDIT_EDI_FNS_UPD_P2                                  // -> EDIT_EDI_FNS_UPD, в Core не использовалось
*/

import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';

const actionRulesForUpdModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.UTOCH
			]
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: documentPackageMethods.isDocActionBlocked,
				allow: false
			},
			{
				directions: [edi.constants.DIRECTIONS.OUTGOING],
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow: function (documentData) {
					const docWarnings = edi.utils.getAttributeByName(
						documentData.attributes,
						'errorNomSredIdentTovAndSvedProslezh'
					);
					const isBlocked = edi.utils.getAttributeByName(
						documentData.attributes,
						'isBlocked',
						undefined,
						true
					);
					return !(docWarnings || isBlocked);
				}
			},
			{
				directions: [edi.constants.DIRECTIONS.INCOMING],
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow: function (documentData) {
					const bpName = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
					const bpNamesWithoutSignAction = [
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT
					];
					const isBpWithoutSign = bpNamesWithoutSignAction.some((it) => it === bpName);
					const isBlocked = edi.utils.getAttributeByName(
						documentData.attributes,
						'isBlocked',
						undefined,
						true
					);
					return !isBpWithoutSign && !isBlocked;
				}
			}
		]
	},
	REJECT: {
		permissionChanging: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				change() {
					return null;
				}
			}
		],
		conditions: [
			{
				customMethod: documentPackageMethods.isDocActionBlocked,
				allow: false
			}
		]
	},
	CLARIFY: {
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					const bpName = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
					const allowBpNames = [
						edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF,
						edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP
					];
					const isArchived = documentData.record.get('archived');
					const isAllowedBpName = !!allowBpNames.find(
						(allowBpName) => edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[bpName] === allowBpName
					);
					return !isArchived;
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		LOOP: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [],
			BUSINESS_STATE: []
		},
		permissionChanging: [],
		conditions: []
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow(documentData) {
					let version = documentData?.record?.get('versionId');
					return edi.constants.ACTIVE_EDI_FNS_UPD_VERSIONS?.includes(version);
				}
			}
		]
	},
	COMPLETE: {
		INCOMING: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [
				edi.constants.STATE.READ,
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		OUTGOING: {
			SINGLE_APPLY: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	READ: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.SENT, edi.constants.STATE.UTOCH]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow: function (checkOptions) {
					let attrs = checkOptions.attributes;
					let bpName = edi.utils.getAttributeByName(attrs, 'bpName');
					let bpNamesWithoutReadAction = [
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT
					];
					const hasReadAction = !bpNamesWithoutReadAction.find(
						(bpNameWithoutRead) => bpNameWithoutRead === bpName
					);
					const isBlocked = edi.utils.getAttributeByName(
						checkOptions.attributes,
						'isBlocked',
						undefined,
						true
					);

					return !isBlocked && hasReadAction;
				}
			}
		]
	},
	SEND: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [],
		conditions: []
	},
	CREATE_FROM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	},
	COPY: {
		OUTGOING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow(documentData) {
					let version = documentData?.record?.get('versionId');
					return edi.constants.ACTIVE_EDI_FNS_UPD_VERSIONS?.includes(version);
				}
			}
		]
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						documentPackageMethods.isPackagingAllowed(checkOptions.docType) &&
						checkOptions.state === edi.constants.STATE.DRAFT
					);
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH_COMPLETED,
				edi.constants.STATE.RENOUNCEMENT,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH_COMPLETED,
				edi.constants.STATE.RENOUNCEMENT,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: documentPackageMethods.isDocActionBlocked,
				allow: false
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow: function (checkOptions) {
					const isBlocked = edi.utils.getAttributeByName(
						checkOptions.attributes,
						'isBlocked',
						undefined,
						true
					);

					return !isBlocked;
				}
			}
		]
	},
	CREATE_UKD: {
		OUTGOING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH_COMPLETED,
				edi.constants.STATE.ON_ANNUL
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow(documentData) {
					let renounced = edi.utils.getAttributeByName(documentData.attributes, 'RENOUNCEMENT') === 'true';
					let hasUtochReason = !!documentData.attributes?.UTOCH_REASON;
					let isSignRejected = renounced && hasUtochReason;
					return !isSignRejected;
				}
			}
		]
	},
	RENOUNCEMENT: {
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			BUSINESS_STATE: [edi.constants.STATE.SENT, edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW]
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: documentPackageMethods.isDocActionBlocked,
				allow: false
			},
			{
				allow: function (documentData) {
					const bpName = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
					const allowBpNames = [
						edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF,
						edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP
					];
					const isAllowedBpName = allowBpNames.find(
						(allowBpName) => edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[bpName] === allowBpName
					);
					return isAllowedBpName;
				}
			}
		]
	},
	CREATE_CUD: {
		OUTGOING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH_COMPLETED,
				edi.constants.STATE.ON_ANNUL
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
				allow(documentData) {
					let renounced = edi.utils.getAttributeByName(documentData.attributes, 'RENOUNCEMENT') === 'true';
					let hasUtochReason = !!documentData.attributes?.UTOCH_REASON;
					let isSignRejected = renounced && hasUtochReason;
					return !isSignRejected;
				}
			}
		]
	},
	CREATE_TORG2: {
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (data) {
					var annulStatus = edi.utils.getAttributeByName(data.attributes, 'ANNUL_REASON'),
						utochReason = edi.utils.getAttributeByName(data.attributes, 'UTOCH_REASON'),
						rawBpName = edi.utils.getAttributeByName(data.attributes, 'bpName');
					return (
						!annulStatus &&
						!utochReason &&
						['DOP', 'SCHFDOP'].some((it) => it === edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[rawBpName])
					);
				}
			}
		]
	}
};

const actionRulesForUpdStatuses = {
	SIGN: {
		OUTGOING: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_10_DP_IZVPOL,
					STATE: [edi.constants.STATE.WAIT_SIGNATURE_STATUS10]
				}
			]
		},
		permissionChanging: [
			{
				documents: [
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_DP_IZVPOL,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_P1_DP_IZVPOL
				],
				change: function (actionPermission, checkOptions) {
					return 'SIGN_EDI_FNS_UPD_CONSUMER';
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_11_P2_DP_IZVPOL],
				change: function (actionPermission, checkOptions) {
					return 'SIGN_EDI_FNS_UPD_SUPPLIER';
				}
			}
		]
	}
};

//TODO по хорошему надо разделять правила для каждого типа отдельно, но пока что слишком трудозатратно
Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD]: actionRulesForUpdModule,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PDPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PDOTPR]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_IZVPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_IZVPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_DP_PDOTPR]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_DP_IZVPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_10_DP_IZVPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_UVUTOCH_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_UOU_DP_PDPOL_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_UOU_DP_PDOTPR_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_UOU_DP_PDOTPR_DECREE_14]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_11_P2_DP_IZVPOL]: actionRulesForUpdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_P1_DP_IZVPOL]: actionRulesForUpdStatuses
});

export { actionRulesForUpdModule, actionRulesForUpdStatuses };
