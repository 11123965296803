// Документ: EDI_FNS_UPD_SERVICE_AKT

// Список всех возможных статусов от back'а
//  DRAFT, DELETED, SENT, READ, SIGNED_BY_CONSUMER, COMPLETED, SENDER_REVIEW, RECEIVER_REVIEW   ?ARCHIVED

// http://confluence.jira.lan:8090/pages/viewpage.action?pageId=56005216

const actionRulesForEdiFnsUpdServiceAktModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {}
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		conditions: [
			{
				//чтобы мы не шли в defaultActionRule и могли скачивать в статусе аннулирован
				allow: true
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {}
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.SIGNED_BY_CONSUMER
			]
		},
		conditions: [
			{
				states: [edi.constants.STATE.SIGNED_BY_CONSUMER],
				directions: [edi.constants.DIRECTIONS.INCOMING],
				allow: false
			},
			{
				states: [edi.constants.STATE.READ],
				allow: function (documentData) {
					return documentData.needSignatures;
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.READ, edi.constants.STATE.SENT, edi.constants.STATE.SIGNED_BY_CONSUMER]
		}
	},
	ROUTE_REJECT: {
		INCOMING: {
			BUSINESS_STATE: []
		},
		OUTGOING: {
			BUSINESS_STATE: []
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {},
	CLARIFY: {
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT],
			BUSINESS_STATE: [edi.constants.STATE.SENT, edi.constants.STATE.READ]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived');
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		}
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		}
	},
	COMPLETE: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.READ]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.hasPart2 && checkOptions.annulNotActive;
				},
				allow: false
			}
		]
	},
	READ: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.SENT]
		}
	},
	READ_AND_COMPLETE: {},
	SEND: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {}
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		}
	},
	CONVERT_TO_DRAFT: {},
	COPY: {},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived');
				}
			}
		]
	},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

//Сюда пишем отдельные правила для всех квитанций "Акт расчета премий"
const actionRulesForEdiFnsUpdServiceAktStatuses = {
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK_DECREE_14]
		}
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT]: actionRulesForEdiFnsUpdServiceAktModule,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL_DECREE_14]:
		actionRulesForEdiFnsUpdServiceAktStatuses
});

export { actionRulesForEdiFnsUpdServiceAktModule, actionRulesForEdiFnsUpdServiceAktStatuses };
