// @ts-ignore
import { createNumberField, createRadio, createTextField } from '@UIkit/components/fields';
// @ts-ignore
import { createContainer, createFieldBlock, createFieldSet } from '@UIkit/components/panels';
import { POA_CONST } from '@Edi/modules/power_of_attorney/configuration';
import { createCheckLabel } from '@Components/checkLabel/CheckLabel';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { utils } from '@App/js/utilities';
import { powerOfAttorneyServices } from '@Edi/modules/power_of_attorney/services';
// @ts-ignore
import { showError } from '@UIkit/components/modal/MessageBox';
import { createInfoElementPlugin } from '@Core/methods';

export interface DefaultComponentProps {
	config?: AnyObject;
}
export enum ReTrustViewModelPoaStates {
	PENDING = 'pending',
	MATCHED = 'matched',
	NOT_FOUND = 'notFound'
}
export enum ViewModelPoaTypes {
	ORIGINAL,
	RETRUST
}

export interface ReTrustBlockProps {
	onParentalPoAStateChanged?: (state: ReTrustViewModelPoaStates) => void;
	onPoATypeChanged?: (isReTrust: boolean) => void;
	isReTrust?: boolean;
	isEdit?: boolean;
}
export class ReTrustBlock {
	props: ReTrustBlockProps;
	fields: Partial<{
		originalPoAType: ExtComponent;
		reTrustPoAType: ExtComponent;
		parentalNumber: ExtComponent;
		principalInn: ExtComponent;
		confidantInn: ExtComponent;
		[fieldName: string]: ExtComponent;
	}>;
	labels: Partial<{
		loadPoaResult: ExtComponent;
		[labelName: string]: ExtComponent;
	}>;
	blocks: Partial<{
		poaTypeToggle: ExtComponent;
		reTrustContainer: ExtComponent;
		[blockName: string]: ExtComponent;
	}>;
	buttons: Partial<{
		loadParentalBtn: ExtComponent;
		resetBtn: ExtComponent;
		[buttonName: string]: ExtComponent;
	}>;
	isReTrust = false;
	parentalPoA?: AnyObject;

	constructor(props: ReTrustBlockProps = {}) {
		const me = this;
		me.modifyConfig(props);
	}

	modifyConfig(props: ReTrustBlockProps = {}) {
		const me = this;

		me.fields = {};
		me.blocks = {};
		me.buttons = {};
		me.labels = {};
		me.parentalPoA = undefined;

		me.props = props;

		if (me.props.isReTrust) me.isReTrust = true;
	}

	getParentalPoAContent(): AnyObject | undefined {
		const me = this;
		return Ext.clone(me.parentalPoA);
	}
	setParentalPoAContent(content?: AnyObject) {
		const me = this;
		me.parentalPoA = content ?? undefined;
	}

	onParentalPoAStateChanged(state: ReTrustViewModelPoaStates) {
		const me = this;
		if (typeof me.props.onParentalPoAStateChanged === 'function') {
			me.props.onParentalPoAStateChanged(state);
		}
	}

	createOriginalPoATypeField({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.fields.originalPoAType = createRadio(
			Ext.merge(
				{
					boxLabel: edi.i18n.getMessage('power.of.attorney.data'),
					name: 'powerOfAttorneyType',
					value: !me.isReTrust,
					disabled: me.props.isEdit,
					padding: '0 16 0 0',
					submitValue: false,
					listeners: {
						change: (cmp: ExtComponent, value: boolean) =>
							value && me.setPoATypeState(ViewModelPoaTypes.ORIGINAL)
					}
				},
				config
			)
		));
	}

	createReTrustPoATypeField({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.fields.reTrustPoAType = createRadio(
			Ext.merge(
				{
					boxLabel: edi.i18n.getMessage('power.of.attorney.reassignment'),
					name: 'powerOfAttorneyType',
					value: me.isReTrust,
					disabled: me.props.isEdit,
					submitValue: false,
					listeners: {
						change: (cmp: ExtComponent, value: boolean) =>
							value && me.setPoATypeState(ViewModelPoaTypes.RETRUST)
					}
				},
				config
			)
		));
	}

	createPoATypeToggleBlock({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.blocks.poaTypeToggle = createContainer(
			Ext.merge(
				{
					layout: 'hbox',
					items: [me.createOriginalPoATypeField(), me.createReTrustPoATypeField()]
				},
				config
			)
		) as ExtComponent);
	}

	getParentalPoaFields(): ExtComponent[] {
		const me = this;
		return [me.fields.parentalNumber, me.fields.principalInn, me.fields.confidantInn] as ExtComponent[];
	}

	reTrustFieldsOnChangeHandler = () => {
		const me = this;
		const viewModel = me.blocks.reTrustMain?.getViewModel();
		if (!viewModel?.get('isPending')) me.setReTrustPoaState(ReTrustViewModelPoaStates.PENDING);
		const isAllFieldsEmpty = !me.getParentalPoaFields().some((f) => !!f?.getValue());
		viewModel?.set('isAllFieldsEmpty', isAllFieldsEmpty);
	};

	createParentalNumberField({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.fields.parentalNumber = createTextField(
			Ext.merge(
				{
					fieldLabel: edi.i18n.getMessage('parental.power.of.attorney.number'),
					allowBlank: false,
					regex: POA_CONST.NUMBER_VALIDATOR,
					listeners: {
						change: () => me.reTrustFieldsOnChangeHandler()
					},
					bind: {
						validation: '{reTrustValidation}',
						disabled: '{isParentalPoaFieldsDisabled}'
					}
				},
				config
			)
		));
	}

	createPrincipalInn({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.fields.principalInn = createNumberField(
			Ext.merge(
				{
					fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.inn'),
					allowBlank: false,
					regex: edi.constants.VALIDATORS.INN,
					listeners: {
						change: () => me.reTrustFieldsOnChangeHandler()
					},
					bind: {
						disabled: '{isParentalPoaFieldsDisabled}'
					}
				},
				config
			)
		));
	}

	createConfidantInn({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.fields.confidantInn = createNumberField(
			Ext.merge(
				{
					fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.inn.short'),
					allowBlank: false,
					regex: edi.constants.VALIDATORS.INN,
					listeners: {
						change: () => me.reTrustFieldsOnChangeHandler()
					},
					bind: {
						disabled: '{isParentalPoaFieldsDisabled}'
					}
				},
				config
			)
		));
	}

	createLoadPoaResultLabel({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.labels.loadPoaResult = createCheckLabel(
			Ext.merge(
				{
					text: 'ok',
					glyph: edi.constants.ICONS.RELOAD,
					margin: '12 16 0 0',
					typography: 'body-short_01',
					bind: {
						hidden: '{isPending}',
						text: `{isPoAMatched ? "${edi.i18n.getMessage(
							'power.of.attorney.loaded.status.matched'
						)}" : "${edi.i18n.getMessage('power.of.attorney.loaded.status.notFound')}"}`,
						glyph: `{isPoAMatched ? "${edi.constants.ICONS.CHECK_ROUNDED}" : "${edi.constants.ICONS.REJECT}"}`,
						glyphColor: '{isPoAMatched ? "var(--color-success)" : "var(--color-yellow)"}'
					}
				},
				config
			)
		));
	}

	findAndFocusInvalidField() {
		const me = this;
		return utils.findAndFocusInvalidField({
			fields: me.getParentalPoaFields() as ExtComponent[]
		});
	}

	async loadParentalPoa() {
		const me = this;
		return powerOfAttorneyServices.loadParentalPoa({
			doverRegNumber: me.fields.parentalNumber?.getValue(),
			principalInn: me.fields.principalInn?.getValue(),
			confidantInn: me.fields.confidantInn?.getValue()
		});
	}

	showParentalPoANotFoundError() {
		showError({
			msgText: edi.i18n.getMessage('power.of.attorney.parental.poa.not.found.error'),
			listeners: {
				afterrender: (cmp: ExtComponent) => {
					const loadLink = cmp.msgTextCmp.el.dom.querySelector('#poa-load-link');

					loadLink?.addEventListener('click', function (e: MouseEvent) {
						e.preventDefault();
						edi.core.openModule('powers.of.attorney.list');
						cmp.close();
					});
				}
			}
		});
	}

	createLoadParentalBtn({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.buttons.loadParentalBtn = createButton(
			Ext.merge(
				{
					cls: [BUTTON_CLS.small, BUTTON_CLS.outline],
					margin: '8 16 0 0',
					text: edi.i18n.getMessage('power.of.attorney.load.parental'),
					handler: async function () {
						if (me.findAndFocusInvalidField()) return;

						const parentalPoaResult = await me.loadParentalPoa();

						if (parentalPoaResult.success && 'poa' in parentalPoaResult) {
							me.setParentalPoAContent(parentalPoaResult.poa);
						} else {
							me.setParentalPoAContent();
							me.showParentalPoANotFoundError();
						}

						me.setReTrustPoaState(
							parentalPoaResult.success
								? ReTrustViewModelPoaStates.MATCHED
								: ReTrustViewModelPoaStates.NOT_FOUND
						);
					},
					bind: {
						hidden: '{!isPending}'
					}
				},
				config
			)
		) as ExtComponent);
	}

	createResetBtn({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.buttons.resetBtn = createButton(
			Ext.merge(
				{
					cls: [BUTTON_CLS.small, BUTTON_CLS.light],
					glyph: edi.constants.ICONS.RELOAD,
					margin: '8 0 0 0',
					text: edi.i18n.getMessage('btn.tooltip.reset'),
					handler: () => {
						me.getParentalPoaFields().forEach((field) => {
							if (field && !field.isDestroyed) field.setValue(null);
						});
						me.setParentalPoAContent();
						me.setReTrustPoaState(ReTrustViewModelPoaStates.PENDING);
					},
					bind: {
						disabled: '{isAllFieldsEmpty}'
					}
				},
				config
			)
		) as ExtComponent);
	}

	setReTrustPoaState(state: ReTrustViewModelPoaStates) {
		const me = this;
		if (!Object.values(ReTrustViewModelPoaStates).includes(state)) return;
		const viewModel = me.blocks.reTrustMain?.getViewModel();
		viewModel?.set('reTrustPoaState', state);

		me.onParentalPoAStateChanged(state);
	}

	createReTrustContainerFields(): ExtComponent[] {
		const me = this;
		return [me.createParentalNumberField(), me.createPrincipalInn(), me.createConfidantInn()];
	}

	createReTrustContainerActions(): ExtComponent[] {
		const me = this;
		return [me.createLoadParentalBtn(), me.createLoadPoaResultLabel(), me.createResetBtn()];
	}

	createReTrustContainer({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.blocks.reTrustContainer = createContainer(
			Ext.merge(
				{
					layout: {
						type: 'grid',
						area: [[4, 2, 2, 4]]
					},
					items: [
						...me.createReTrustContainerFields(),
						createContainer({
							layout: 'hbox',
							items: me.createReTrustContainerActions(),
							bind: {
								hidden: '{isEdit}'
							}
						})
					],
					listeners: {
						afterrender: (cmp: ExtComponent) => {
							const viewModel = me.blocks.reTrustMain?.getViewModel();
							viewModel?.set('isAllFieldsEmpty', !me.getParentalPoaFields().some((f) => !!f?.getValue()));
						}
					},
					bind: {
						disabled: '{!isReTrustPoA}',
						hidden: '{!isReTrustPoA}'
					}
				},
				config
			)
		) as ExtComponent);
	}

	createReTrustFieldSetViewModel() {
		const me = this;
		return {
			data: {
				poaType: me.isReTrust ? ViewModelPoaTypes.RETRUST : ViewModelPoaTypes.ORIGINAL,
				isEdit: me.props.isEdit,
				reTrustPoaState: ReTrustViewModelPoaStates.PENDING,
				isAllFieldsEmpty: true
			},
			formulas: {
				isReTrustPoA: (get: Function) => get('poaType') === ViewModelPoaTypes.RETRUST,
				isPending: (get: Function) => get('reTrustPoaState') === ReTrustViewModelPoaStates.PENDING,
				isPoAMatched: (get: Function) => get('reTrustPoaState') === ReTrustViewModelPoaStates.MATCHED,
				isPoANotFound: (get: Function) => get('reTrustPoaState') === ReTrustViewModelPoaStates.NOT_FOUND,
				reTrustValidation: (get: Function) => {
					const isPoAMatched = get('isPoAMatched');
					if (!isPoAMatched) return true;

					const parentalPoA = me.getParentalPoAContent();

					const isReTrustAllowed =
						edi.utils.getObjectProperty(parentalPoA, 'document.dover.svDov.prPeredov') !== '2';
					if (!isReTrustAllowed) return edi.i18n.getMessage('power.of.attorney.retrust.prperedov.error');
				},
				isParentalPoaFieldsDisabled: (get: Function) =>
					!get('isReTrustPoA') || get('isEdit') || (get('isPoAMatched') && !get('reTrustValidation'))
			}
		};
	}
	onPoATypeChanged(type: ViewModelPoaTypes) {
		const me = this;
		if (typeof me.props.onPoATypeChanged === 'function') {
			me.props.onPoATypeChanged(type === ViewModelPoaTypes.RETRUST);
		}
	}
	setPoATypeState(state: ViewModelPoaTypes) {
		const me = this;
		const viewModel = me.blocks.reTrustMain?.getViewModel();
		viewModel?.set('poaType', state);
		me.onPoATypeChanged(state);
	}
	createReTrustFieldSet({ config = {} }: DefaultComponentProps = {}): ExtComponent {
		const me = this;
		return (me.blocks.reTrustMain = createFieldSet(
			Ext.merge(
				{
					collapsible: false,
					viewModel: me.createReTrustFieldSetViewModel(),
					items: createFieldBlock({
						title: edi.i18n.getMessage('power.of.attorney.type'),
						layout: {
							type: 'grid'
						},
						items: [me.createPoATypeToggleBlock(), me.createReTrustContainer()],
						plugins: [
							createInfoElementPlugin({
								hintConfig: {
									html: edi.i18n.getMessage('power.of.attorney.type.tooltip')
								},
								refEl: 'titleEl',
								alignItems: 'start'
							})
						]
					})
				},
				config
			)
		));
	}
}
