import { DocumentPackageHeader, PackageType } from './entities';
import { documentPackageMethods } from './methods';

export const DOCUMENT_PACKAGES_COLUMNS_NAME = 'document_packages';
export const DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME = 'document_packages_docs_grid';
export const DOCUMENT_PACKAGES_DETAILS_WINDOW_COLUMNS_NAME = 'document_packages_details_window';
export const DOCUMENT_PACKAGES_ADD_WINDOW_COLUMNS_NAME = 'document_packages_add_window';
export const DOCUMENT_PACKAGES_LINK_WINDOW_COLUMNS_NAME = 'document_packages_link_window';

edi.columns.addColumns({
	[DOCUMENT_PACKAGES_COLUMNS_NAME]: {
		id: {
			order: 10,
			text: 'column.id',
			sortable: true,
			flex: 1,
			dataIndex: 'id'
		},
		number: {
			order: 20,
			text: 'documentPackage.number',
			sortable: true,
			flex: 1,
			dataIndex: 'number'
		},
		type: {
			order: 30,
			text: 'column.type',
			sortable: false,
			flex: 1,
			dataIndex: 'type',
			renderer: function (value: string) {
				const isClosed = value === PackageType.ENCLOSED;
				return edi.i18n.getMessage(isClosed ? 'documentPackage.type.closed' : 'documentPackage.type.opened');
			}
		},
		state: {
			order: 40,
			text: 'column.status',
			sortable: false,
			flex: 1.5,
			htmlEncode: true,
			dataIndex: 'state',
			renderer: function (value: string, meta: AnyObject, record: ExtRecord<DocumentPackageHeader>) {
				return documentPackageMethods.packageStateRenderer(record.getData());
			}
		},
		direction: {
			order: 50,
			text: 'documents.column.direction',
			sortable: false,
			align: 'center',
			flex: 1,
			renderer: 'direction',
			htmlEncode: true
		},
		modifyDate: {
			order: 60,
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			order: 70,
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		fromOrg: {
			order: 80,
			text: 'documents.column.fromOrg',
			dataIndex: 'fromOrg',
			renderer: 'organization',
			sortable: true,
			flex: 2
		},
		toOrg: {
			order: 90,
			text: 'documents.column.toOrg',
			dataIndex: 'toOrg',
			renderer: 'organization',
			sortable: true,
			flex: 2
		},
		documents_count: {
			order: 100,
			text: 'column.document.package.count',
			dataIndex: 'documents',
			renderer: 'arrayCount',
			sortable: false,
			flex: 1
		}
	},
	[DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME]: {
		id: {
			order: 10,
			text: 'documents.column.id',
			sortable: true,
			flex: 0.5,
			hidden: true,
			dataIndex: 'id'
		},
		number: {
			order: 20,
			text: 'field.name.number',
			sortable: true,
			flex: 1,
			dataIndex: 'number'
		},
		doctime: {
			text: 'documents.column.date',
			flex: 1,
			order: 30,
			dataIndex: 'doctime',
			renderer: 'docTime'
		},
		modifyDate: {
			order: 40,
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			sortable: true,
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			order: 50,
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			sortable: true,
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		businessState: {
			order: 60,
			text: 'documents.column.status',
			dataIndex: 'businessState',
			renderer: 'columnStatus',
			flex: 1,
			htmlEncode: true
		},
		type: {
			order: 70,
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1,
			renderer: 'doctype',
			htmlEncode: true
		}
	}
});
edi.columns.addColumns({
	[DOCUMENT_PACKAGES_DETAILS_WINDOW_COLUMNS_NAME]: {
		parents: DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME,
		config: {}
	},
	[DOCUMENT_PACKAGES_ADD_WINDOW_COLUMNS_NAME]: {
		parents: DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME,
		config: {}
	},
	[DOCUMENT_PACKAGES_LINK_WINDOW_COLUMNS_NAME]: {
		parents: DOCUMENT_PACKAGES_COLUMNS_NAME,
		config: {}
	}
});
