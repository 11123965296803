/**
 * Core converters methods
 * @author Anatoly Deryshev
 */
edi.converters.registerConverters({
	/**
	 * convert organization to partie
	 * @param    {Object}    org           organization's data
	 * @param    {Object}    [properties]    additional properties
	 * @returns    {Object}                partie's data
	 */
	convertOrgToPartie: function (org, properties) {
		org = org ? org : {};
		properties = properties || {};
		var address = edi.utils.getAddressFromOrganization(org, edi.constants.ADDRESS_TYPES.LEGAL) || {},
			addressReal = edi.utils.getAddressFromOrganization(org, edi.constants.ADDRESS_TYPES.REAL) || {},
			addressPostal = edi.utils.getAddressFromOrganization(org, edi.constants.ADDRESS_TYPES.POSTAL) || {},
			separateRegData = properties.hasOwnProperty('separateMainData') ? properties.separateMainData : true,
			separateAddress = properties.hasOwnProperty('separateAddress') ? properties.separateAddress : true;
		var orgType;
		switch (org.orgType) {
			case edi.constants.ORGANIZATION_TYPES.INDIVIDUAL:
				orgType = 'individual';
				break;
			case edi.constants.ORGANIZATION_TYPES.FOREIGN_LEGAL_ENTITY:
				orgType = 'legalForeigner';
				break;
			default:
				orgType = 'company';
		}
		var isForeigner = orgType === 'legalForeigner',
			isIndividual = orgType === 'individual',
			individualCompanyName,
			individualData = [];
		var isCountryDefined = !!address.countryCode,
			countryCode = address.countryCode || addressReal.countryCode || addressPostal.countryCode,
			country = isCountryDefined ? edi.utils.getCountryFULLByISO(countryCode) : {},
			countryIso2 = country.iso_2 || 'RU',
			isRussian = countryIso2 === 'RU',
			addrType = !isRussian ? 1 : isCountryDefined ? 0 : undefined,
			fillCompany = !(separateRegData && (isIndividual || isForeigner)),
			fillRusAddress = separateAddress && isRussian;
		var isCountryPostalDefined = !!addressPostal.countryCode,
			countryCodePostal = addressPostal.countryCode,
			countryPostal = isCountryPostalDefined ? edi.utils.getCountryFULLByISO(countryCodePostal) : {},
			countryIso2Postal = countryPostal.iso_2 || 'RU',
			isRussianPostal = countryIso2Postal === 'RU',
			addrTypePostal = !isRussianPostal ? 1 : isCountryPostalDefined ? 0 : undefined,
			fillRusAddressPostal = separateAddress && isRussianPostal;

		if (
			isIndividual &&
			properties.hasOwnProperty('hideIndividualOrgAdditionalData') &&
			true === properties.hideIndividualOrgAdditionalData
		) {
			individualCompanyName = org.name;
		} else if (
			isIndividual &&
			org.individualOrgAdditionalData &&
			'object' == typeof org.individualOrgAdditionalData
		) {
			individualData =
				org.individualOrgAdditionalData.firstName && org.individualOrgAdditionalData.lastName
					? [
							org.individualOrgAdditionalData.lastName,
							org.individualOrgAdditionalData.firstName,
							org.individualOrgAdditionalData.middleName
					  ]
					: org.name
					? org.name.split(' ')
					: [];
		}

		var convertAddrToText = function (address) {
			var txt = '';
			var addrParts = [
				'zip',
				'area',
				'city',
				'localityType',
				'locality',
				'streetType',
				'street',
				'building',
				'block',
				'addInf',
				'flat'
			];
			var isFirst = true;
			for (var i = 0; i < addrParts.length; i++) {
				var name = addrParts[i];
				var value = address[name];
				if (value) {
					if (!isFirst && 'locality' !== name) {
						txt += ', ';
					} else if (!isFirst && 'locality' === name && address['localityType']) {
						txt += ' ';
					} else if (!isFirst) {
						txt += ', ';
					}
					txt += value;
					isFirst = false;
				}
			}
			return txt;
		};
		if (Object.keys(address).length > 0) {
			address = address;
		} else if (Object.keys(addressReal).length > 0) {
			address = addressReal;
			country = !!addressReal.countryCode ? edi.utils.getCountryFULLByISO(addressReal.countryCode) : {};
		} else if (Object.keys(addressPostal).length > 0) {
			address = addressPostal;
			country = countryPostal;
		}

		var forAddr = '';
		if (addrType && address) {
			forAddr = convertAddrToText(address);
		}

		var forAddrPostal = '';
		if (addrTypePostal && addressPostal) {
			forAddrPostal = convertAddrToText(addressPostal);
		}

		var bacc = org.acc,
			bname = org.bank,
			bik = org.bankBIK,
			bCorrAcc,
			fullStreet = (address.streetType ? address.streetType + ' ' : '') + (address?.street || ''),
			fullStreetPostal =
				(addressPostal.streetType ? addressPostal.streetType + ' ' : '') + (addressPostal.street || ''),
			fullLocality = (address.localityType ? address.localityType + ' ' : '') + (address.locality || ''),
			fullLocalityPostal =
				(addressPostal.localityType ? addressPostal.localityType + ' ' : '') + (addressPostal.locality || '');

		if (org.accounts && org.accounts.length && !bacc && !bname && !bik) {
			let account = edi.methods.header.getLatestOrgAccount(org.accounts);
			bacc = account.number || account.accountNumber;
			bCorrAcc = account.corrAccNumber || account.bankAcc;
			bname = account.preferredOwnerName || account.name || '';
			bik = account.bik || account.bankBIC;
		}

		var res = {
			id: org.id,
			type: orgType,
			address_type: addrType,
			addr_category: address && address.type ? edi.renderers.addressTypeConvert(address.type) : undefined,
			company_name: fillCompany ? org.name : undefined,
			company_iln: org.iln,
			company_inn: fillCompany ? org.inn : undefined,
			company_kpp: fillCompany ? org.kpp : undefined,
			company_util_reg_nr: org.regNr,

			okpo: '',
			company_okpo: org.okpo,
			company_okato: org.okato,
			company_oktmo: org.oktmo,

			individual_company_name: isIndividual && individualCompanyName ? individualCompanyName : undefined,
			individual_lastname: isIndividual && individualData[0] ? individualData[0] : undefined,
			individual_firstname: isIndividual && individualData[1] ? individualData[1] : undefined,
			individual_patronymicname: isIndividual && individualData[2] ? individualData[2] : undefined,
			// for legacy docs individual reg data sores in company fields (company_iln, company_inn, company_kpp)
			individual_inn: isIndividual && !fillCompany ? org.inn : undefined,

			foreign_name: isForeigner ? org.name : undefined,
			foreign_identifier: isForeigner ? org.inn : undefined,
			foreign_country: isForeigner ? org.country : undefined,
			foreign_iln: isForeigner ? org.iln : undefined,

			bank_acc: bacc,
			bank_name: bname,
			bank_id: bik,
			bank_corr_acc: bCorrAcc,
			contact_phone: org.phones,
			contact_fax: org.faxes,
			contact_email: org.email,
			fns_id: org.fnsId,
			accounts: org.accounts
		};

		var addressObj = !separateAddress
			? {
					//International address (for legacy docs)
					addr_country: country.iso_2,
					addr_country_name: country.name,
					addr_zip: address.zip, //addr_rus_zip
					addr_region_code: address.regionCode, // Регион - addr_rus_region
					addr_area: address.area, //Область - addr_rus_county
					addr_city: address.city, // Город - addr_rus_city
					addr_street: fullStreet, //Улица - addr_rus_street
					addr_locality: isRussian ? fullLocality : undefined, // населённый пункт - addr_rus_community
					addr_home: address.building, // Дом - addr_rus_home
					addr_block: address.block, // Блок - addr_rus_block
					addr_flat: address.flat, // Квартира - addr_rus_flat
					addr_addInf: address.addInf // Доп информация - addr_rus_addInf
			  }
			: {
					// Separated rus & foreing address (for fns documents)
					addr_rus_zip: fillRusAddress ? address.zip : undefined,
					addr_rus_zip_post: fillRusAddressPostal ? addressPostal.zip : undefined,
					addr_rus_region: fillRusAddress ? address.regionCode : undefined,
					addr_rus_region_post: fillRusAddressPostal ? addressPostal.regionCode : undefined,
					addr_rus_county: fillRusAddress ? address.area : undefined,
					addr_rus_county_post: fillRusAddressPostal ? addressPostal.area : undefined,
					addr_rus_community: fillRusAddress ? fullLocality : undefined,
					addr_rus_community_post: fillRusAddressPostal ? fullLocalityPostal : undefined,
					addr_rus_city: fillRusAddress ? address.city : undefined,
					addr_rus_city_post: fillRusAddressPostal ? addressPostal.city : undefined,
					addr_rus_street: fillRusAddress ? fullStreet : undefined,
					addr_rus_street_post: fillRusAddressPostal ? fullStreetPostal : undefined,
					addr_rus_home: fillRusAddress ? address.building : undefined,
					addr_rus_home_post: fillRusAddressPostal ? addressPostal.building : undefined,
					addr_rus_block: fillRusAddress ? address.block : undefined,
					addr_rus_block_post: fillRusAddressPostal ? addressPostal.block : undefined,
					addr_rus_flat: fillRusAddress ? address.flat : undefined,
					addr_rus_addInf: fillRusAddress ? address.addInf : undefined,
					addr_rus_flat_post: fillRusAddressPostal ? addressPostal.flat : undefined,
					addr_for_country: !isRussian ? country.iso_number_3 : undefined,
					addr_for_country_post: !isRussianPostal ? countryPostal.iso_number_3 : undefined,
					addr_for_country_name: !isRussian ? country.name : undefined,
					addr_for_country_name_post: !isRussianPostal ? countryPostal.name : undefined,
					addr_for_text: !isRussian ? forAddr : undefined,
					addr_for_text_post: !isRussianPostal ? forAddrPostal : undefined,
					addr_addInf: !isRussian ? address.addInf : undefined // Доп информация - addr_addInf
			  };

		Object.assign(res, addressObj);

		edi.utils.clearEmptyValues(res);
		return res;
	}
});

/**
 * SNILS (PersonalId)
 */
edi.converters.SNILS = {
	/**
	 * Convert SNILS when recieved data  XXXXXXXXXXX -> XXX-XXX-XXX XX
	 * @param {String} snils
	 * @returns {String}
	 */
	convertForShow: function (snils) {
		return snils.match(/^(\d){11}$/)
			? snils.substring(0, 3) +
					'-' +
					snils.substring(3, 6) +
					'-' +
					snils.substring(6, 9) +
					' ' +
					snils.substring(9)
			: snils;
	},

	/**
	 * Convert SNILS before save from  XXX-XXX-XXX XX -> XXXXXXXXXXX
	 * @param snils
	 */
	convertForSave: function (snils) {
		return snils ? snils.replace(/[^+\d]/g, '') : snils;
	}
};
