import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createContainer, createForm } from '@UIkit/components/panels';
import { createModalPanel } from '@UIkit/components/modal';
import { showInfoToast } from '@Ediweb/core';

const tourEvents = new Ext.util.Observable();
const tours = {};

Ext.define('edi.components.TourManager', {
	singleton: true,
	extend: 'Ext.Component',
	alias: 'widget.edi-tour-manager',
	//Observable для сбора событий
	tourEvents,
	//объект для хранения туров по именам модулей
	tours,
	_activeTour: null,

	initComponent: function () {
		const me = this;
		me.callParent(arguments);
		me.afterInit();
	},

	afterInit: function () {
		const me = this;
		tourEvents.on('start_tour', (tourName, module, opts) => me.startTour(tourName, module, opts));
		tourEvents.on('stop_tour', () => me.stopTour());
		edi.events.module.on('moduleActivated', (module) => me.enableDisableShowTipsButton(module));
		edi.events.modules.on('rendered', function () {
			if (!me._showTipsButton) {
				const navPanel = edi.core.getNavigationPanel()?.navPanel;
				navPanel?.add(me.createShowTipsButton());
				me.enableDisableShowTipsButton(edi.modulesHandler?.getActiveModule());
			}
		});
	},

	enableDisableShowTipsButton: function (module) {
		const me = this;
		if (me._showTipsButton) {
			const activeModule = module || edi.modulesHandler?.getActiveModule();
			const tourCfg = me.tours[activeModule?.modName];
			me._showTipsButton.setDisabled(!tourCfg);
			me._showTipsButton.setTooltip(
				!!tourCfg ? edi.i18n.getMessage('tour.show.tips') : edi.i18n.getMessage('tour.show.tips.disabled')
			);
		}
	},

	/**
	 * Метод откладывает запуск тура пока юай заблокирован модалками
	 * @param	{Function}	cb
	 */
	delayUntilUiUnlocked: function (cb) {
		const me = this;
		clearInterval(me.uiLockedTimer);
		me.uiLockedTimer = setInterval(function () {
			if (edi.constants.INTRO_MODAL_IS_VISIBLE !== true) {
				clearInterval(me.uiLockedTimer);
				if (typeof cb === 'function') {
					cb();
				}
			}
		}, 500);
	},

	startTour: function (tourName, module, opts) {
		const me = this;
		const activeModule = module || edi.modulesHandler?.getActiveModule();
		me.enableDisableShowTipsButton(activeModule);
		const tourCfg = Ext.clone(me.tours[tourName] || me.tours[activeModule?.modName]);
		if (tourCfg) {
			const start = function () {
				//сначала пристрелим уже запущенный тур
				me._activeTour?.modal?.close();
				//т.к. модули инициализируются лениво, то юзер мог переключить модуль
				//поэтому запустим тур если мы сейчас на том же модуле, что и инициализировал запуск тура
				if (!module?.modName || module.modName === edi.modulesHandler?.getActiveModule()?.modName) {
					tourCfg.queryRoot = activeModule?.tab?.el?.dom;
					tourCfg.externalOpts = opts || (activeModule?.getTourOpts ? activeModule.getTourOpts() : null);
					me._activeTour = Ext.create('edi.components.tour', tourCfg);
					me._activeTour.showTourModal(opts);
				}
			};
			//если вызываем тур по событию (может быть по нажатию кнопки или программно) и т.к. модуль
			//инициализируется не синхронно, то подождем пока он отрендерится тогда уже запустим тур
			if (activeModule?.moduleRenderedPromise?.then) {
				activeModule.moduleRenderedPromise.then(function () {
					me.delayUntilUiUnlocked(start);
				});
			} else {
				me.delayUntilUiUnlocked(start);
			}
		}
	},

	stopTour: function () {
		const me = this;
		if (me._activeTour) {
			me._activeTour.modal?.close();
			me._activeTour = null;
		}
	},

	createShowTipsButton: function (btnCfg) {
		const me = this;
		me._showTipsButton = createButton(
			Ext.merge(
				{
					cls: [BUTTON_CLS.icon, 'edi-tour-manager-show-tips-button'],
					glyph: edi.constants.ICONS.LIGHTBULB,
					handler: function () {
						const activeModule = edi.modulesHandler?.getActiveModule();
						const tourCfg = me.tours[activeModule?.modName];
						if (tourCfg) {
							let optsFromModule = activeModule.getTourOpts ? activeModule.getTourOpts() : null;
							optsFromModule = Ext.merge({ eventFromUser: true }, optsFromModule);
							const fakeTour = { externalOpts: optsFromModule };
							const isAvailable =
								!tourCfg.getStartErrorText ||
								(typeof tourCfg.getStartErrorText === 'function' &&
									!tourCfg.getStartErrorText.apply(fakeTour));
							if (isAvailable) {
								me.startTour(null, activeModule, optsFromModule);
							} else {
								showInfoToast(null, edi.i18n.getMessage('error.tour.not.available'));
							}
						} else {
							showInfoToast(null, edi.i18n.getMessage('error.tour.not.available'));
						}
					}
				},
				btnCfg
			)
		);
		return me._showTipsButton;
	}
});

Ext.define('edi.components.tour', {
	extend: 'Ext.Component',
	alias: 'widget.edi-tour',
	//получение имени тура, может пригодиться в ивентах старта\окончания тура (т.к. туров на 1 модуль может быть несколько)
	getName: () => 'dummy',
	//получение шагов тура при инициализации (т.к. туров на 1 модуль может быть несколько)
	createSteps: () => [],
	//метод возвращает текст ошибки, если при старте тура не было шагов, нужно для кейсов, когда вручную нажали показать подсказки, но модуль или грид еще не готовы
	getStartErrorText: () => {},
	//номер шага (индекс в массиве), который отобразим при открытии подсказки
	currentStepNum: 0,
	//DOM элемент относительно которого будем искать таргеты для подсказок
	queryRoot: null,
	//сюда можно закинуть любую дичь извне при создании тура, использовать можно, например в createSteps
	externalOpts: null,

	onTourStarted: function (tour) {},
	onTourClosed: function (tour) {},
	onStepStarted: function (tour, step, stepNum) {},
	//true - показывать кнопку "Назад"
	allowStepBack: false,
	//true - показывать кнопку "Завершить" на любом шаге
	allowSkip: false,
	//true - показывать стрелочку от окна к рамке вокруг таргета
	showArrow: true,
	//базовый класс, от которого строятся классы для окна, маски и стрелочек
	modalCls: 'edi-ediweb-tour-modal',
	modalWidth: 440,
	modalPadding: '24 24 0 24',
	//отступ окна от таргета
	modalTargetGap: 24,
	//отступ рамки вокруг таргета
	targetMargin: 8,
	//отступ центра стрелки от границы окна при отображении её у края окна
	arrowShiftFromBorder: 16,

	steps: null,
	modal: null,
	mask: null,
	targetForMask: null,

	initComponent: function () {
		this.setOwnConfig();
		this.callParent(arguments);
		this.afterInit();
	},

	setOwnConfig: function () {
		const me = this;
		if (typeof me.createSteps === 'function') {
			me.steps = me.createSteps();
		}
	},

	afterInit: function () {},

	isElementViewport: function (element) {
		const rect = element.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	},

	showTourModal: function () {
		const me = this;

		if (!Array.isArray(me.steps) || me.steps.length === 0) {
			const errorText = me.getStartErrorText();
			if (errorText) {
				showInfoToast(null, edi.i18n.getMessage(errorText));
			}
			return;
		}

		if (me.currentStepNum < 0 || me.currentStepNum > me.steps.length - 1) {
			me.modal?.close();
			return;
		}

		me.targetForMask = me.steps[me.currentStepNum].getTargetEl(me);
		if (me.targetForMask?.scrollIntoView && !me.isElementViewport(me.targetForMask)) {
			me.targetForMask.scrollIntoView({ block: 'center', inline: 'center' });
		}
		if (me.modal) {
			me.modal.removeAll();
			me.modal.add(me.createModalItems());
			me.modal.getDockedItems()[0].removeAll();
			me.modal.getDockedItems()[0].add(me.createModalButtons());
			me.modal.updateLayout();
		} else {
			me.createStepModal();
		}

		me.setMaskOnTarget();
		me.setModalPosition();
		me.onStepStarted(me, me.steps[me.currentStepNum], me.currentStepNum);
	},

	nextStep: function () {
		const me = this;
		me.currentStepNum += 1;
		me.showTourModal();
	},

	previousStep: function () {
		const me = this;
		me.currentStepNum -= 1;
		me.showTourModal();
	},

	getTargetBoundingRect: function () {
		const me = this;
		if (typeof me.targetForMask?.getBoundingClientRect === 'function') {
			return me.targetForMask.getBoundingClientRect();
		} else {
			const height = Ext.getViewportHeight();
			const width = Ext.getViewportWidth();
			return {
				width: 0,
				height: 0,
				top: height / 2,
				bottom: height / 2,
				left: width / 2,
				right: width / 2
			};
		}
	},

	setModalPosition: function () {
		const me = this;
		const step = me.steps[me.currentStepNum];

		const arrowCls = `${me.modalCls}-arrow`;
		me.modal.removeCls(`${arrowCls}-left`);
		me.modal.removeCls(`${arrowCls}-right`);
		me.modal.removeCls(`${arrowCls}-top`);
		me.modal.removeCls(`${arrowCls}-bottom`);
		me.modal.removeCls(`${arrowCls}-align-start`);
		me.modal.removeCls(`${arrowCls}-align-middle`);
		me.modal.removeCls(`${arrowCls}-align-end`);

		//это обновляет целевые размеры окну (пишет в el.dom.style), и запускается анимация изменения высоты и ширины
		me.modal.setWidth(step.width || me.modalWidth);
		me.modal.updateLayout();

		const targetBox = me.getTargetBoundingRect();
		//т.к. на окно применяется анимация, то получение размеров el.dom дает мгновенные значения, а нам нужны конечные
		//после анимации, в цсс свойство как раз таки пишутся эти нужные нам значения
		const newModalWidth = parseInt(me.modal.el.dom.style.width);
		const newModalHeight = parseInt(me.modal.el.dom.style.height);
		const pos = { x: 0, y: 0 };

		switch (step.position) {
			case 'left-top': {
				pos.y = targetBox.top + targetBox.height / 2 - newModalHeight + me.arrowShiftFromBorder;
				pos.x = targetBox.left - newModalWidth - me.modalTargetGap;
				me.showArrow && me.targetForMask && me.modal.addCls([`${arrowCls}-right`, `${arrowCls}-align-end`]);
				break;
			}
			case 'left': {
				pos.y = targetBox.top + targetBox.height / 2 - newModalHeight / 2;
				pos.x = targetBox.left - newModalWidth - me.modalTargetGap;
				me.showArrow && me.targetForMask && me.modal.addCls(`${arrowCls}-right`);
				break;
			}
			case 'left-bottom': {
				pos.y = targetBox.top + targetBox.height / 2 - me.arrowShiftFromBorder;
				pos.x = targetBox.left - newModalWidth - me.modalTargetGap;
				me.showArrow && me.targetForMask && me.modal.addCls([`${arrowCls}-right`, `${arrowCls}-align-start`]);
				break;
			}
			case 'right-top': {
				pos.y = targetBox.top + targetBox.height / 2 - newModalHeight + me.arrowShiftFromBorder;
				pos.x = targetBox.right + me.modalTargetGap;
				me.showArrow && me.targetForMask && me.modal.addCls([`${arrowCls}-left`, `${arrowCls}-align-end`]);
				break;
			}
			case 'right': {
				pos.y = targetBox.top + targetBox.height / 2 - newModalHeight / 2;
				pos.x = targetBox.right + me.modalTargetGap;
				me.showArrow && me.targetForMask && me.modal.addCls(`${arrowCls}-left`);
				break;
			}
			case 'right-bottom': {
				pos.y = targetBox.top + targetBox.height / 2 - me.arrowShiftFromBorder;
				pos.x = targetBox.right + me.modalTargetGap;
				me.showArrow && me.targetForMask && me.modal.addCls([`${arrowCls}-left`, `${arrowCls}-align-start`]);
				break;
			}
			case 'top': {
				pos.y = targetBox.top - newModalHeight - me.modalTargetGap;
				pos.x = targetBox.left + targetBox.width / 2 - newModalWidth / 2;
				me.showArrow && me.targetForMask && me.modal.addCls(`${arrowCls}-bottom`);
				break;
			}
			default: {
				//'bottom'
				pos.y = targetBox.bottom + me.modalTargetGap;
				pos.x = targetBox.left + targetBox.width / 2 - newModalWidth / 2;
				me.showArrow && me.targetForMask && me.modal.addCls(`${arrowCls}-top`);
			}
		}

		me.modal.setPosition(pos.x, pos.y);
		//!!! ACHTUNG !!! время таймаута - это ровно столько же, сколько и анимация transition в tour.scss
		//меньше ставить нельзя, а больше уже некрасиво =\
		//это нужно если новый размер окна больше предыдущего и оно должно упереться в край экрана
		//Ext для расчетов выхода за границу экрана использует значение на момент вызова функции,
		//а так как css еще не дорастянул фактические размеры (из-за анимации) то и окно улетает за границу
		//повторный вызов установки положения приводит его на место
		setTimeout(() => me.modal?.setPosition(pos.x, pos.y), 300);
	},

	createModalItems: function () {
		const me = this;
		const step = me.steps[me.currentStepNum];

		const stepper =
			me.steps.length === 1
				? null
				: Ext.create('Ext.view.View', {
						cls: `${me.modalCls}-stepper`,
						region: 'north',
						itemSelector: 'div',
						tpl: Ext.create(
							'Ext.XTemplate',
							'<tpl for=".">',
							'<div class="{[this.getClass(values)]}"></div>',
							'</tpl>',
							{
								getClass: function (val) {
									return val.active ? `active` : '';
								}
							}
						),
						data: me.steps.map((s, index) => ({ active: me.currentStepNum === index }))
				  });

		const text = createContainer({
			cls: `${me.modalCls}-content`,
			region: 'center',
			autoScroll: true,
			tpl: Ext.create(
				'Ext.XTemplate',
				`<div class='${me.modalCls}-content-title'>`,
				step.getTitle(me),
				'</div>',
				`<div class='${me.modalCls}-content-text'>`,
				step.getContent(me),
				'</div>'
			),
			data: {}
		});

		return [
			//форма в окне нужна что бы модалка растягивалась под содержимое по высоте
			createForm({
				layout: 'grid',
				items: [stepper, text]
			})
		];
	},

	createBackButton: function () {
		const me = this;
		return me.allowStepBack === true && me.currentStepNum !== 0
			? createButton({
					text: edi.i18n.getMessage('btn.back'),
					cls: BUTTON_CLS.secondary,
					glyph: edi.constants.ICONS.ARROW_BACK,
					handler: function () {
						me.previousStep();
					}
			  })
			: null;
	},

	createNextButton: function () {
		const me = this;
		return me.currentStepNum !== me.steps.length - 1
			? createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('btn.understand'),
					handler: function () {
						me.nextStep();
					}
			  })
			: null;
	},

	createCompleteButton: function () {
		const me = this;
		return me.allowSkip === true || me.currentStepNum === me.steps.length - 1
			? createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('btn.understand'),
					handler: function () {
						me.modal.close();
					}
			  })
			: null;
	},

	createModalButtons: function () {
		const me = this;
		return [me.createBackButton(), me.createNextButton(), me.createCompleteButton()];
	},

	getMaskWrapperCls: function () {
		const me = this;
		return `${me.modalCls}-mask-wrapper`;
	},

	getMaskFragmentCls: function (name) {
		const me = this;
		return name ? `${me.modalCls}-mask-fragment-UI Platform Maven Webapp` : `${me.modalCls}-mask-fragment`;
	},

	setMaskOnTarget: function () {
		const me = this;

		let wrapperEl = document.querySelector(`.${me.getMaskWrapperCls()}`);
		if (!wrapperEl) {
			if (me.modal?.el?.dom) {
				wrapperEl = document.createElement('div');
				wrapperEl.classList.add(me.getMaskWrapperCls());
				wrapperEl.style.zIndex = String(me.modal.el.dom.style.zIndex - 1);
				me.mask = wrapperEl;
				me.modal.el.dom.parentElement.appendChild(me.mask);
			}
		}

		if (!!wrapperEl) {
			const targetBox = me.getTargetBoundingRect();
			const targetMargin = targetBox.width === 0 && targetBox.height === 0 ? 0 : me.targetMargin;
			const cfg = {
				top: { x: '0px', y: '0px', width: '100%', height: `${targetBox.top - targetMargin}px` },
				right: {
					x: `${targetBox.right + targetMargin}px`,
					y: `${targetBox.top - targetMargin}px`,
					width: `calc(100% - ${targetBox.right - targetMargin}px`,
					height: `${targetBox.height + 2 * targetMargin}px`
				},
				bottom: {
					x: '0px',
					y: `${targetBox.top + targetBox.height + targetMargin}px`,
					width: '100%',
					height: `calc(100% - ${targetBox.top + targetBox.height + targetMargin}px)`
				},
				left: {
					x: 0,
					y: `${targetBox.top - targetMargin}px`,
					width: `${targetBox.left - targetMargin}px`,
					height: `${targetBox.height + 2 * targetMargin}px`
				}
			};

			Object.entries(cfg).forEach(([name, c]) => {
				let fragment = document.querySelector(`.${me.getMaskFragmentCls(name)}`);
				if (!fragment) {
					fragment = document.createElement('div');
					fragment.classList.add(me.getMaskFragmentCls());
					fragment.classList.add(me.getMaskFragmentCls(name));
					wrapperEl.appendChild(fragment);
				}

				fragment.style.top = c.y;
				fragment.style.left = c.x;
				fragment.style.width = c.width;
				fragment.style.height = c.height;
			});
		}
	},

	removeMask: function () {
		const me = this;
		if (me.mask && me.modal?.el?.dom) {
			me.modal.el.dom.parentElement.removeChild(me.mask);
		}
		me.mask = null;
	},

	createStepModal: function () {
		const me = this;
		if (me.isDestroyed) {
			return;
		}

		const step = me.steps[me.currentStepNum];

		me.modal = createModalPanel({
			cls: me.modalCls,
			constrain: true, //не позволять окну уходить за границу вьюпорта
			header: false,
			layout: 'fit',
			autoScroll: false,
			resizable: false,
			modal: false,
			width: step.width || me.modalWidth,
			minWidth: 24,
			height: undefined, //высота подстраивается под контент
			minHeight: 24,
			bodyPadding: me.modalPadding,
			items: me.createModalItems(),
			buttons: me.createModalButtons(),
			listeners: {
				afterrender: function () {
					me.setModalPosition();
					typeof me.onTourStarted === 'function' && me.onTourStarted(me);
					setTimeout(function () {
						//первое появление окна без анимации, что бы не летало по всему экрану,
						//а к следующим шагам уже будет плавный переход
						me.modal.addCls('animated-motion');
					}, 10);
				},
				close: function () {
					me.removeMask();
					me.targetForMask = null;
					me.modal = null;
					me.destroy();
					typeof me.onTourClosed === 'function' && me.onTourClosed(me);
				}
			}
		});
		me.modal.show();
	}
});

export { tourEvents, tours };
