import { guidImportHandlerMethods } from './importHandler';
import { chainImportHandler } from './chainImportHandler';

interface poaProcessesProps {
	guidImportHandler: () => void;
	chainImportHandler: () => void;
}
export const poaProcesses: poaProcessesProps = {
	guidImportHandler: guidImportHandlerMethods.showModal,
	chainImportHandler
};
