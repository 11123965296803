import { SERVER_DATE_FORMAT } from '@Edi/modules/catalog/LEGACY_PARTIN/methods';

export class UpdOrgSelectors {
	switchCompanyTypeBlocks(component: ExtComponent) {
		edi.selectors.fieldControls.switchCompanyTypeBlocks(
			component,
			function (form: ExtComponent, orgType: string, modal: ExtComponent) {
				const isModalReadOnly = edi.utils.getObjectProperty(modal, 'modalConf.readOnly') || false,
					forceChangeFieldsMap = edi.utils.getObjectProperty(modal, 'forceChangeFields') || {},
					forceChangeAnyField = !!modal.forceChangeAnyField,
					fieldValues = modal ? modal.selectedOrgValues : null,
					fieldsForm = form.getForm();

				const enableFieldBlockByType = function (fieldName: string, type: string) {
					const field = fieldsForm.findField(fieldName);
					if (field && field.ownerCt) {
						edi.selectors.utils.enableFieldBlock(
							field.ownerCt,
							orgType === type,
							fieldValues,
							isModalReadOnly,
							forceChangeFieldsMap,
							forceChangeAnyField
						);
					}
				};
				enableFieldBlockByType('foreign_info', 'legalForeigner');
				enableFieldBlockByType('foreign_name', 'legalForeigner');
				enableFieldBlockByType('foreign_identifier', 'legalForeigner');
				enableFieldBlockByType('physical_inn', 'individualParticipant');
				enableFieldBlockByType('physical_requisites', 'individualParticipant');
				enableFieldBlockByType('physical_additInfo', 'individualParticipant');
				enableFieldBlockByType('physical_lastname', 'individualParticipant');
				enableFieldBlockByType('physical_firstname', 'individualParticipant');
				enableFieldBlockByType('physical_patronymicname', 'individualParticipant');
			},
			function (fieldValues: AnyObject) {
				let orgType;
				if (fieldValues.foreign_name) {
					orgType = 'legalForeigner';
				} else if (fieldValues.individual_firstname) {
					orgType = 'individual';
				} else if (fieldValues.company_name) {
					orgType = 'company';
				} else if (fieldValues.physical_firstname) {
					orgType = 'individualParticipant';
				}

				return orgType;
			}
		);
	}

	getCompanyTypeConfig() {
		const me = this;
		return edi.selectors.mergeConfigs('company_type_combo', {
			fieldConfig: {
				store: 'initCompany4TypeStore',
				disabled: function (org: AnyObject) {
					return (
						!!org &&
						(org.type === 'company' ||
							org.type === 'individual' ||
							org.type === 'legalForeigner' ||
							org.type === 'individualParticipant')
					);
				},
				listeners: {
					change: me.switchCompanyTypeBlocks,
					afterRender: me.switchCompanyTypeBlocks
				}
			}
		});
	}

	switchAddressBlocks(addressTypeCombo: ExtComponent) {
		const modal = addressTypeCombo.modalInstance;
		const form = modal ? modal.formPanel : null;
		const isModalReadOnly = edi.utils.getObjectProperty(modal, 'modalConf.readOnly') || false;
		const forceChangeFieldsMap = edi.utils.getObjectProperty(modal, 'forceChangeFields') || {};
		const forceChangeAnyField = !!modal.forceChangeAnyField;
		const value = addressTypeCombo.getValue();
		const org = modal ? modal.selectedOrgValues : null;
		const types: AnyObject = {
			0: 'rus',
			1: 'foreign',
			2: 'codeStateAddressRegister'
		};

		if (!form) {
			return;
		}

		const enableFieldBlockByType = function (fieldName: string, type: string) {
			const fields = form.down('textfield[name=' + fieldName + ']');
			if (fields) {
				const block = fields.up('[blockId="address_data_block"]');
				if (block) {
					edi.selectors.utils.enableFieldBlock(
						block,
						types[value] === type,
						org,
						isModalReadOnly,
						forceChangeFieldsMap,
						forceChangeAnyField
					);
				}
			}
		};

		enableFieldBlockByType('addr_rus_region', 'rus');
		enableFieldBlockByType('addr_for_text', 'foreign');
		enableFieldBlockByType('addr_code_gar', 'codeStateAddressRegister');

		if (form) {
			form.isValid();
		}
	}

	getAddressType() {
		const me = this;

		return edi.selectors.mergeConfigs('address_type_combo', {
			fieldConfig: {
				store: 'initNewUPDAddressTypeStore',
				value: function (org: AnyObject) {
					return org.address_type == 0 || org.address_type == 1 || org.address_type == 2
						? org.address_type
						: 0;
				},
				displayField: 'type',
				listeners: {
					change: me.switchAddressBlocks,
					afterRender: me.switchAddressBlocks
				},
				disabled: false
			}
		});
	}

	getOtherFieldsMap(fieldsMapPrefix: string) {
		return {
			structural_subdivision: fieldsMapPrefix + 'struktPodr',
			info_for_members: fieldsMapPrefix + 'infoForMembers',
			address_type: fieldsMapPrefix + 'address_type',
			glnMesta: fieldsMapPrefix + 'adres.glnMesta'
		};
	}

	getCompanyFieldsMap(fieldsMapPrefix: string) {
		const company = fieldsMapPrefix + 'idSv.svYULUch.';
		return {
			company_name: company + 'naimOrg',
			company_inn: company + 'innyul',
			company_kpp: company + 'kpp',
			company_okpo: fieldsMapPrefix + 'okpo',

			kodOPF: fieldsMapPrefix + 'kodOPF',
			polnNaimOPF: fieldsMapPrefix + 'polnNaimOPF',
			sokrNaim: fieldsMapPrefix + 'sokrNaim',
			infDlyaUchast: fieldsMapPrefix + 'infDlyaUchast'
		};
	}

	getIndividualFieldsMap(fieldsMapPrefix: string) {
		const individual = fieldsMapPrefix + 'idSv.svIP.';

		return {
			individual_inn: individual + 'innfl',
			individual_lastname: individual + 'fio.familiya',
			individual_firstname: individual + 'fio.imya',
			individual_patronymicname: individual + 'fio.otchestvo',
			individual_certificateRegistrationIP: fieldsMapPrefix + 'idSv.svIP.svGosRegIP',

			company_util_reg_nr: individual + 'ogrnip',
			dataOGRNIP: individual + 'dataOGRNIP',
			inieSved: individual + 'inieSved',
			sokrNaim: fieldsMapPrefix + 'sokrNaim',
			infDlyaUchast: fieldsMapPrefix + 'infDlyaUchast'
		};
	}

	getPhysicalFieldsMap(fieldsMapPrefix: string) {
		const physical = fieldsMapPrefix + 'idSv.svFLUch.';

		return {
			physical_lastname: physical + 'fio.familiya',
			physical_firstname: physical + 'fio.imya',
			physical_patronymicname: physical + 'fio.otchestvo',
			physical_inn: physical + 'innfl',
			physical_requisites: physical + 'requisites',
			physical_additInfo: physical + 'inieSved',

			idStatL: physical + 'idStatL',
			infDlyaUchast: fieldsMapPrefix + 'infDlyaUchast'
		};
	}

	getForeignFieldsMap(fieldsMapPrefix: string) {
		const foreigner = fieldsMapPrefix + 'idSv.svInNeUch.';

		return {
			foreign_identifier: foreigner + 'identif',
			foreign_name: foreigner + 'naim',
			foreign_info: foreigner + 'inieSved',

			idStat: foreigner + 'idStat',
			kodStr: foreigner + 'kodStr',
			naimStran: foreigner + 'naimStran',
			infDlyaUchast: fieldsMapPrefix + 'infDlyaUchast'
		};
	}

	getRusAddressFieldsMap(fieldsMapPrefix: string) {
		const rusAddress = fieldsMapPrefix + 'adres.adrRF.';

		return {
			addr_rus_zip: rusAddress + 'indeks',
			addr_rus_region: rusAddress + 'kodRegion',
			addr_region_name: rusAddress + 'naimRegion',
			addr_rus_county: rusAddress + 'rayon',
			addr_rus_city: rusAddress + 'gorod',
			addr_rus_street: rusAddress + 'ulitsa',
			addr_rus_community: rusAddress + 'naselPunkt',
			addr_rus_home: rusAddress + 'dom',
			addr_rus_block: rusAddress + 'korpus',
			addr_rus_flat: rusAddress + 'kvart',
			rusAddressInieSved: rusAddress + 'inieSved'
		};
	}

	getForeignAddrFieldsMap(fieldsMapPrefix: string) {
		const foreignAddr = fieldsMapPrefix + 'adres.adrInf.';

		return {
			addr_for_country: foreignAddr + 'kodStr',
			addr_for_country_name: foreignAddr + 'naimStran',
			addr_for_text: foreignAddr + 'adrTekst'
		};
	}

	getGarAddrFieldsMap(fieldsMapPrefix: string) {
		const foreignAddr = fieldsMapPrefix + 'adres.adrGAR.';

		return {
			addr_code_gar: foreignAddr + 'idNom',
			naimRegion: foreignAddr + 'naimRegion',
			indeks: foreignAddr + 'indeks',
			region: foreignAddr + 'region',
			tmunitsipRayonKod: foreignAddr + 'munitsipRayon.vidKod',
			tmunitsipRayonNaim: foreignAddr + 'munitsipRayon.naim',
			gorodSelPoselenKod: foreignAddr + 'gorodSelPoselen.vidKod',
			gorodSelPoselenNaim: foreignAddr + 'gorodSelPoselen.naim',
			naselenPunktVid: foreignAddr + 'naselenPunkt.vid',
			naselenPunktNaim: foreignAddr + 'naselenPunkt.naim',
			elPlanStrukturTip: foreignAddr + 'elPlanStruktur.tip',
			elPlanStrukturNaim: foreignAddr + 'elPlanStruktur.naim',
			elUlDorSetiTip: foreignAddr + 'elUlDorSeti.tip',
			elUlDorSetiNaim: foreignAddr + 'elUlDorSeti.naim',
			zdanieTip: foreignAddr + 'zdanie.0.tip',
			zdanieNomer: foreignAddr + 'zdanie.0.nomer',
			zemelUchastok: foreignAddr + 'zemelUchastok',
			pomeschZdaniyaTip: foreignAddr + 'pomeschZdaniya.tip',
			pomeschZdaniyaNomer: foreignAddr + 'pomeschZdaniya.nomer',
			pomeschKvartiriTip: foreignAddr + 'pomeschKvartiri.tip',
			pomeschKvartiriNomer: foreignAddr + 'pomeschKvartiri.nomer'
		};
	}

	getContactFieldsMap(fieldsMapPrefix: string) {
		const contact = fieldsMapPrefix + 'kontakt.';

		return {
			contact_phone: contact + 'tlf.0',
			contact_email: contact + 'elPochta.0',
			inKont: contact + 'inKont'
		};
	}

	getBankFieldsMap(fieldsMapPrefix: string) {
		const bank = fieldsMapPrefix + 'bankRekv.';

		return {
			bank_corr_acc: bank + 'svBank.korSchet',
			bank_acc: bank + 'nomerScheta',
			bank_name: bank + 'svBank.naimBank',
			bank_id: bank + 'svBank.bik'
		};
	}

	getCompanyDataModalFieldsBlock() {
		const me = this;

		return {
			company_type_fields_block: {
				type: 'fieldsBlock',
				containerConf: {
					layout: {
						type: 'grid',
						gap: 24,
						area: [8, 4, [4, 4], [2, 8], 8, 8, 8]
					}
				},
				fields: {
					company_name: Object.assign({}, edi.selectors.defaults.company_name, {
						title: 'company.name.full',
						allowBlank: false,
						maxLength: 1000
					}),
					company_okpo: Object.assign({}, edi.selectors.defaultsFNS.main.company_okpo, {
						title: 'company.okpo.short',
						allowBlank: true
					}),
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						title: 'company.inn.short',
						emptyText: '',
						allowBlank: false
					}),
					company_kpp: edi.selectors.defaults.company_kpp,
					kodOPF: {
						title: 'company.kodOPF',
						name: 'kodOPF',
						minLength: 2,
						maxLength: 5
					},
					polnNaimOPF: {
						title: 'company.polnNaimOPF',
						name: 'polnNaimOPF',
						minLength: 4,
						maxLength: 255
					},
					structural_subdivision: Object.assign({}, edi.selectors.defaults.structural_subdivision, {
						maxLength: 500,
						order: 0
					}),
					sokrNaim: {
						title: 'company.sokrNaim',
						name: 'sokrNaim',
						maxLength: 510
					},
					infDlyaUchast: {
						title: 'company.infDlyaUchast',
						name: 'infDlyaUchast',
						isTextarea: true,
						rowsHtmlAttributeValue: 5,
						maxLength: 255
					}
				}
			}
		};
	}

	getIndividualDataModalFieldsBlock() {
		return {
			company_type_fields_block: {
				type: 'fieldsBlock',
				containerConf: {
					layout: {
						type: 'grid',
						gap: 24,
						area: [[4, 4], 12, 8, 8, 8]
					}
				},
				fields: {
					individual_inn: Object.assign({}, edi.selectors.defaults.individual_inn, {
						allowBlank: false,
						title: 'company.inn.short'
					}),
					company_okpo: Object.assign({}, edi.selectors.defaultsFNS.main.individual_okpo, {
						title: 'company.okpo.short',
						allowBlank: true
					}),
					company_individual_data: {
						type: 'fieldset',
						title: 'company.individual.data',
						panelConf: {
							layout: {
								type: 'grid',
								gap: 24,
								area: [[4, 4, 4], 8, [4, 4], 8]
							}
						},
						fields: {
							individual_lastName: Object.assign({}, edi.selectors.defaultsFNS.main.individual_lastName, {
								maxLength: 60
							}),
							individual_firstName: Object.assign(
								{},
								edi.selectors.defaultsFNS.main.individual_firstName,
								{
									maxLength: 60
								}
							),
							individual_patronymicName: Object.assign(
								{},
								edi.selectors.defaultsFNS.main.individual_patronymicName,
								{
									maxLength: 60
								}
							),
							certificateRegistrationIP: Object.assign(
								{},
								edi.selectors.defaultsFNS.main.certificateRegistrationIP,
								{
									maxLength: 100,
									title: 'individual.certificateRegistration.full'
								}
							),
							ogrnip: {
								title: 'company.ogrnip.short',
								name: 'company_util_reg_nr',
								regex: edi.constants.VALIDATORS.INDI_OGRN
							},
							dataOGRNIP: {
								title: 'company.dataOGRNIP',
								type: 'date',
								name: 'dataOGRNIP',
								format: edi.constants.DATE_FORMAT.FNS,
								submitFormat: edi.constants.DATE_FORMAT.FNS
							},
							inieSved: {
								title: 'company.inieSved',
								name: 'inieSved',
								isTextarea: true,
								rowsHtmlAttributeValue: 5,
								maxLength: 255
							}
						}
					},
					structural_subdivision: Object.assign({}, edi.selectors.defaults.structural_subdivision, {
						maxLength: 500,
						order: 0
					}),
					sokrNaim: {
						title: 'company.sokrNaim',
						name: 'sokrNaim',
						maxLength: 510
					},
					infDlyaUchast: {
						title: 'company.infDlyaUchast',
						name: 'infDlyaUchast',
						isTextarea: true,
						rowsHtmlAttributeValue: 5,
						maxLength: 255
					}
				}
			}
		};
	}

	getForeignDataModalFieldsBlock() {
		const me = this;

		return {
			company_type_fields_block: {
				type: 'fieldsBlock',
				containerConf: {
					layout: {
						type: 'grid',
						gap: 24,
						area: [[8, 4], 12, 12, 8]
					}
				},
				fields: {
					foreign_name: {
						title: 'company.foreign_name',
						name: 'foreign_name',
						emptyText: 'company.name.comment',
						allowBlank: false,
						maxLength: 1000
					},
					foreign_identifier: {
						title: 'column.log.object.id',
						name: 'foreign_identifier',
						itemId: 'foreign_identifier',
						emptyText: 'column.log.object.id',
						maxLength: 255,
						listeners: {
							change: me.onForeignIdentifierChange,
							afterrender: me.onForeignIdentifierChange
						}
					},
					company_foreign_fields: {
						type: 'fieldset',
						panelConf: {
							layout: {
								type: 'grid',
								gap: 24,
								area: [6, 8]
							}
						},
						fields: {
							idStat: {
								title: 'company.idStat',
								type: 'combo',
								fieldConfig: {
									allowBlank: false,
									store: edi.stores.createMemoryStore(
										[
											{
												id: 'ИО',
												name: edi.i18n.getMessage('company.idStat.io')
											},
											{
												id: 'ИГ',
												name: edi.i18n.getMessage('company.idStat.ig')
											}
										],
										'SIMPLE'
									),
									name: 'idStat'
								}
							},
							kodStr: {
								title: edi.i18n.getMessage('company.country.code'),
								type: 'combo',
								fieldConfig: {
									name: 'kodStr',
									store: 'initValidCountryFullStore',
									allowBlank: false,
									valueField: 'iso_number_3',
									displayField: 'display_name'
								}
							}
						}
					},
					foreign_info: {
						title: 'company.type.legalForeigner.foreign_info',
						maxLength: 255,
						itemId: 'foreign_info',
						name: 'foreign_info',
						listeners: {
							change: me.onForeignInfoChange,
							afterrender: me.onForeignInfoChange
						}
					},
					infDlyaUchast: {
						title: 'company.infDlyaUchast',
						name: 'infDlyaUchast',
						isTextarea: true,
						rowsHtmlAttributeValue: 5,
						maxLength: 255
					}
				}
			}
		};
	}

	getPhysicalDataModalFieldsBlock() {
		const me = this;

		return {
			company_type_fields_block: {
				type: 'fieldsBlock',
				containerConf: {
					layout: {
						type: 'grid',
						gap: 24,
						area: [[4, 4, 4], [4, 6], 8, 12]
					},
					listeners: {
						afterrender: me.onFLFieldChange.bind(me)
					}
				},
				fields: {
					physical_lastname: {
						name: 'physical_lastname',
						title: 'last.name',
						itemId: 'physical_lastname',
						allowBlank: false,
						maxLength: 60,
						requiredFields: ['physical_firstname'],
						listeners: {
							change: me.onFLFieldChange.bind(me)
						}
					},
					physical_name: {
						name: 'physical_firstname',
						title: 'first.name',
						itemId: 'physical_firstname',
						allowBlank: false,
						maxLength: 60,
						requiredFields: ['physical_lastname'],
						listeners: {
							change: me.onFLFieldChange.bind(me)
						}
					},
					physical_patronymicname: {
						name: 'physical_patronymicname',
						maxLength: 60,
						title: 'patronymic.name'
					},
					physical_inn: {
						name: 'physical_inn',
						itemId: 'physical_inn',
						title: 'company.inn.short',
						maskRe: /\d/i,
						allowBlank: false,
						regex: edi.constants.VALIDATORS.INDI_INN,
						listeners: {
							change: me.onFLFieldChange.bind(me)
						}
					},
					idStatL: {
						title: 'company.idStatL',
						type: 'combo',
						fieldConfig: {
							itemId: 'idStatL',
							store: edi.stores.createMemoryStore(
								[
									{
										id: '0',
										name: edi.i18n.getMessage('company.idStatL.0')
									},
									{
										id: '1',
										name: edi.i18n.getMessage('company.idStatL.1')
									}
								],
								'SIMPLE'
							),
							name: 'idStatL',
							listeners: {
								change: (cmp: ExtComponent) => {
									me.onFLFieldChange(cmp);
									cmp.up('form')?.isValid();
								}
							}
						}
					},
					infDlyaUchast: {
						title: 'company.infDlyaUchast',
						name: 'infDlyaUchast',
						isTextarea: true,
						rowsHtmlAttributeValue: 5,
						maxLength: 255
					},
					physical_additInfo: {
						itemId: 'physical_additInfo',
						name: 'physical_additInfo',
						title: 'company.physical.additInfo',
						maxLength: 255,
						allowBlank: false,
						listeners: {
							change: me.onFLFieldChange.bind(me)
						}
					}
				}
			}
		};
	}

	getFLValidityFields(comp: ExtComponent) {
		const blockId: ExtComponent | undefined = comp.up('[blockId="company_data_block"]');
		const lastnameField = blockId?.down('[itemId="physical_lastname"]');
		const firstnameField = blockId?.down('[itemId="physical_firstname"]');
		const innField = blockId?.down('[itemId="physical_inn"]');
		const idStatLField = blockId?.down('[itemId="idStatL"]');
		const additInfoField = blockId?.down('[itemId="physical_additInfo"]');

		return { lastnameField, firstnameField, innField, idStatLField, additInfoField };
	}

	getFLValidityFieldsValues(comp: ExtComponent) {
		const me = this;

		const { lastnameField, firstnameField, innField, idStatLField, additInfoField } = me.getFLValidityFields(comp);

		const lastnameFieldValue = lastnameField.getValue();
		const firstnameFieldValue = firstnameField.getValue();
		const idStatLFieldValue = idStatLField.getValue();
		const innFieldValue = innField.getValue();
		const additInfoFieldValue = additInfoField.getValue();

		return { lastnameFieldValue, firstnameFieldValue, idStatLFieldValue, innFieldValue, additInfoFieldValue };
	}

	onFLFieldChange(comp: ExtComponent) {
		const me = this;

		const { lastnameField, firstnameField, innField, idStatLField, additInfoField } = me.getFLValidityFields(comp);
		const { lastnameFieldValue, firstnameFieldValue, idStatLFieldValue, innFieldValue, additInfoFieldValue } =
			me.getFLValidityFieldsValues(comp);

		const innMandatory =
			idStatLFieldValue !== '0' && !additInfoFieldValue && !lastnameFieldValue && !firstnameFieldValue;
		const additInfoFieldMandatory = idStatLFieldValue !== '0' && !lastnameFieldValue && !firstnameFieldValue;
		const fioMandatory = idStatLFieldValue !== '0' && !innFieldValue && !additInfoFieldValue;

		lastnameField.allowBlank = !fioMandatory;
		firstnameField.allowBlank = !fioMandatory;
		innField.allowBlank = !innMandatory;
		additInfoField.allowBlank = !additInfoFieldMandatory;

		lastnameField.isValid();
		firstnameField.isValid();
		innField.isValid();
		additInfoField.isValid();
	}

	onForeignIdentifierChange(comp: ExtComponent) {
		const value = comp.getValue();
		const blockId: ExtComponent | undefined = comp.up('[blockId="company_data_block"]');
		const foreignInfo = blockId?.down('[itemId="foreign_info"]');
		foreignInfo.allowBlank = !!value;
		foreignInfo.isValid();
	}

	onForeignInfoChange(comp: ExtComponent) {
		const value = comp.getValue();
		const blockId: ExtComponent | undefined = comp.up('[blockId="company_data_block"]');
		const foreignIdentifier = blockId?.down('[itemId="foreign_identifier"]');

		foreignIdentifier.allowBlank = !!value;
		foreignIdentifier.isValid();
	}

	getMainTabFields() {
		const me = this;

		return {
			main: {
				company_type: me.getCompanyTypeConfig(),
				company_data: {
					type: 'container',
					blockId: 'company_data_block',
					fields: { ...me.getCompanyDataModalFieldsBlock() }
				},
				individual_data: {
					type: 'container',
					blockId: 'company_data_block',
					fields: { ...me.getIndividualDataModalFieldsBlock() }
				},
				foreign_fields: {
					type: 'container',
					blockId: 'company_data_block',
					fields: { ...me.getForeignDataModalFieldsBlock() }
				},
				physical_fields: {
					type: 'container',
					blockId: 'company_data_block',
					fields: { ...me.getPhysicalDataModalFieldsBlock() }
				}
			}
		};
	}

	getRussAddressDataModalFieldsBlock() {
		const me = this;

		return {
			rusAddress_fields_block: {
				type: 'fieldsBlock',
				containerConf: {
					layout: {
						type: 'grid',
						gap: 24,
						area: [12, 8]
					}
				},
				fields: {
					russAddressFieldSet: {
						type: 'fieldset',
						title: 'company.address.header',
						panelConf: {
							layout: {
								type: 'grid',
								area: [[4, 8], 8, [6, 6], [6, 2, 2, 2], 4]
							}
						},
						fields: {
							zip: edi.selectors.defaultsFNS.address.zip,
							region: edi.selectors.defaultsFNS.address.region,
							area: Ext.merge({}, edi.selectors.defaultsFNS.address.area, {
								maxLength: 255
							}),
							city: Ext.merge({}, edi.selectors.defaultsFNS.address.city, {
								maxLength: 255
							}),
							locality: Ext.merge({}, edi.selectors.defaultsFNS.address.locality, {
								maxLength: 255
							}),
							street: Ext.merge({}, edi.selectors.defaultsFNS.address.street, {
								maxLength: 255
							}),
							home: Ext.merge({}, edi.selectors.defaultsFNS.address.home, {
								maxLength: 50
							}),
							block: Ext.merge({}, edi.selectors.defaultsFNS.address.block, {
								maxLength: 50
							}),
							flat: Ext.merge({}, edi.selectors.defaultsFNS.address.flat, {
								maxLength: 50
							}),
							...me.gertGlnMestaField()
						}
					},
					rusAddressInieSved: {
						title: 'company.rusAddress.inieSved',
						name: 'rusAddressInieSved',
						isTextarea: true,
						rowsHtmlAttributeValue: 5,
						maxLength: 1000
					}
				}
			}
		};
	}

	getForeignAddressDataModalFieldsBlock() {
		const me = this;

		return {
			foreignAddress_fields_block: {
				type: 'fieldsBlock',
				containerConf: {
					layout: {
						type: 'grid',
						gap: 24,
						area: [12, 8]
					}
				},
				fields: {
					foreignAddressFieldSet: {
						type: 'fieldset',
						panelConf: {
							layout: {
								type: 'grid',
								area: [8, 8, 4]
							}
						},
						title: 'address.company.foreign.header',
						fields: {
							addr_for_country: Ext.merge({}, edi.selectors.defaultsFNS.address.country, {
								fieldConfig: {
									store: 'initValidCountryFullStore',
									allowBlank: false
								}
							}),
							foreignAddr: edi.selectors.defaultsFNS.address.foreignAddr,
							...me.gertGlnMestaField()
						}
					}
				}
			}
		};
	}

	getGarAddressDataModalFieldsBlock() {
		const me = this;

		return {
			garAddress_fields_block: {
				type: 'fieldsBlock',
				containerConf: {
					layout: {
						type: 'grid',
						gap: 24,
						area: [12, 8, 12, 8]
					}
				},
				fields: {
					garAddressFieldSet: {
						type: 'fieldset',
						panelConf: {
							layout: {
								type: 'grid',
								gap: 24,
								area: [[8, 4], 12, [4, 8], 12, 12, 12, 12, 12, 12, 5]
							}
						},
						fields: {
							addr_code_gar: {
								name: 'addr_code_gar',
								title: 'company.address.type.codeStateAddressRegister',
								emptyText: 'company.address.type.codeStateAddressRegister',
								maxLength: 36,
								minLength: 36,
								allowBlank: false
							},
							...me.gertGlnMestaField(),
							gar_title: {
								type: 'label',
								cls: 'typography heading_01',
								title: 'company.address.type.codeStateAddressRegister.title'
							},
							indeks: {
								name: 'indeks',
								title: 'company.zip',
								regex: edi.constants.VALIDATORS.ZIP_RUSSIA,
								maxLength: 6
							},
							region: {
								type: 'combo',
								title: 'company.region',
								fieldConfig: {
									name: 'region',
									displayField: 'display_name',
									store: 'initRegionsStore',
									allowBlank: false,
									listeners: {
										change: me.onGarRegionChange
									}
								}
							},
							tmunitsipRayonFieldsBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.tmunitsipRayon'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									tmunitsipRayonKod: {
										type: 'combo',
										fieldConfig: {
											name: 'tmunitsipRayonKod',
											itemId: 'tmunitsipRayonKod',
											requiredFields: ['tmunitsipRayonNaim'],
											store: edi.stores.createMemoryStore(
												[
													{
														id: '1',
														name: edi.i18n.getMessage(
															'company.address.type.gar.tmunitsipRayon.1'
														)
													},
													{
														id: '2',
														name: edi.i18n.getMessage(
															'company.address.type.gar.tmunitsipRayon.2'
														)
													},
													{
														id: '3',
														name: edi.i18n.getMessage(
															'company.address.type.gar.tmunitsipRayon.3'
														)
													},
													{
														id: '4',
														name: edi.i18n.getMessage(
															'company.address.type.gar.tmunitsipRayon.4'
														)
													},
													{
														id: '5',
														name: edi.i18n.getMessage(
															'company.address.type.gar.tmunitsipRayon.5'
														)
													}
												],
												'SIMPLE'
											)
										}
									},
									tmunitsipRayonNaim: {
										requiredFields: ['tmunitsipRayonKod'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'tmunitsipRayonNaim',
										maxLength: 255
									}
								}
							},
							gorodSelPoselenBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.gorodSelPoselen'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									gorodSelPoselenKod: {
										type: 'combo',
										fieldConfig: {
											name: 'gorodSelPoselenKod',
											itemId: 'gorodSelPoselenKod',
											requiredFields: ['gorodSelPoselenNaim'],
											store: edi.stores.createMemoryStore(
												[
													{
														id: '1',
														name: edi.i18n.getMessage(
															'company.address.type.gar.gorodSelPoselen.1'
														)
													},
													{
														id: '2',
														name: edi.i18n.getMessage(
															'company.address.type.gar.gorodSelPoselen.2'
														)
													},
													{
														id: '3',
														name: edi.i18n.getMessage(
															'company.address.type.gar.gorodSelPoselen.3'
														)
													},
													{
														id: '4',
														name: edi.i18n.getMessage(
															'company.address.type.gar.gorodSelPoselen.4'
														)
													}
												],
												'SIMPLE'
											)
										}
									},
									gorodSelPoselenNaim: {
										requiredFields: ['gorodSelPoselenKod'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'gorodSelPoselenNaim',
										maxLength: 255
									}
								}
							},
							naselenPunktFieldsBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.naselenPunkt'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									naselenPunktVid: {
										requiredFields: ['naselenPunktNaim'],
										name: 'naselenPunktVid',
										maxLength: 50
									},
									naselenPunktNaim: {
										requiredFields: ['naselenPunktVid'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'naselenPunktNaim',
										maxLength: 255
									}
								}
							},
							elPlanStruktuFieldsBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.elPlanStruktur'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									elPlanStrukturTip: {
										requiredFields: ['elPlanStrukturNaim'],
										name: 'elPlanStrukturTip',
										maxLength: 50
									},
									elPlanStrukturNaim: {
										requiredFields: ['elPlanStrukturTip'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'elPlanStrukturNaim',
										maxLength: 255
									}
								}
							},
							elUlDorSetiFieldsBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.elUlDorSeti'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									elUlDorSetiTip: {
										requiredFields: ['elUlDorSetiNaim'],
										name: 'elUlDorSetiTip',
										maxLength: 50
									},
									elUlDorSetiNaim: {
										requiredFields: ['elUlDorSetiTip'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'elUlDorSetiNaim',
										maxLength: 255
									}
								}
							},
							zdanieFieldsBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.zdanie'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									zdanieTip: {
										requiredFields: ['zdanieNomer'],
										name: 'zdanieTip',
										maxLength: 50
									},
									zdanieNomer: {
										requiredFields: ['zdanieTip'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'zdanieNomer',
										maxLength: 255
									}
								}
							},
							zemelUchastok: {
								title: 'company.address.type.gar.zemelUchastok',
								name: 'zemelUchastok',
								maxLength: 50
							},
							pomeschZdaniyaFieldsBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.pomeschZdaniya'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									pomeschZdaniyaTip: {
										requiredFields: ['pomeschZdaniyaNomer'],
										name: 'pomeschZdaniyaTip',
										maxLength: 50
									},
									pomeschZdaniyaNomer: {
										requiredFields: ['pomeschZdaniyaTip'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'pomeschZdaniyaNomer',
										maxLength: 255
									}
								}
							},
							pomeschKvartiriFieldsBlock: {
								type: 'fieldsBlock',
								containerConf: {
									title: edi.i18n.getMessage('company.address.type.gar.pomeschKvartiri'),
									layout: {
										type: 'grid',
										area: [[5, 7]]
									}
								},
								fields: {
									pomeschKvartiriTip: {
										requiredFields: ['pomeschKvartiriNomer'],
										name: 'pomeschKvartiriTip',
										maxLength: 50
									},
									pomeschKvartiriNomer: {
										requiredFields: ['pomeschKvartiriTip'],
										fieldLabel: edi.i18n.getMessage('line.name'),
										name: 'pomeschKvartiriNomer',
										maxLength: 255
									}
								}
							}
						}
					}
				}
			}
		};
	}

	gertGlnMestaField() {
		return {
			glnMesta: {
				title: 'company.address.type.gln',
				name: 'glnMesta',
				regex: edi.constants.VALIDATORS.GLN
			}
		};
	}

	onGarRegionChange(comp: ExtComponent) {
		const value = comp.getValue();
		const blockId: ExtComponent | undefined = comp.up('[blockId="address_data_block"]');
		const tmunitsipRayonKod = blockId?.down('[itemId="tmunitsipRayonKod"]');
		tmunitsipRayonKod.allowBlank = value === '99';
		tmunitsipRayonKod.isValid();
	}
	getAddressTabFields() {
		const me = this;

		return {
			address: {
				addressType: me.getAddressType(),
				russAddress: {
					type: 'container',
					blockId: 'address_data_block',
					fields: { ...me.getRussAddressDataModalFieldsBlock() }
				},
				foreignAddress: {
					type: 'container',
					blockId: 'address_data_block',
					fields: { ...me.getForeignAddressDataModalFieldsBlock() }
				},
				garAddress: {
					type: 'container',
					blockId: 'address_data_block',
					fields: { ...me.getGarAddressDataModalFieldsBlock() }
				}
			}
		};
	}

	getBankTabFields() {
		return {
			bank: {
				bank_data: {
					type: 'fieldsBlock',
					containerConf: {
						layout: {
							type: 'grid',
							gap: 24,
							area: [[5, 4, 3], 8]
						}
					},
					fields: {
						bank_acc: edi.selectors.defaultsFNS.bank.bank_acc_select,
						bank_corr_acc: Ext.merge({}, edi.selectors.defaultsFNS.bank.bank_corr_acc, {
							maxLength: 20
						}),
						bank_id: Ext.merge({}, edi.selectors.defaultsFNS.bank.bank_id, {
							regex: edi.constants.VALIDATORS.BIK,
							regexText: 'invalid.value.bank.id'
						}),
						bank_name: Ext.merge({}, edi.selectors.defaultsFNS.bank.bank_name, {
							maxLength: 1000
						})
					}
				}
			}
		};
	}

	getContactTabFields() {
		return {
			contact: {
				contact_data: {
					type: 'fieldsBlock',
					containerConf: {
						layout: {
							type: 'grid',
							gap: 24,
							area: [4, 7, 8]
						}
					},
					fields: {
						contact_phone: Object.assign({}, edi.selectors.defaultsFNS.contact.phone, {
							title: 'company.phone.fax',
							maxLength: 255
						}),
						contact_email: Object.assign({}, edi.selectors.defaultsFNS.contact.email, {
							maxLength: 255
						}),
						inKont: {
							title: 'company.contact.type.inKont',
							name: 'inKont',
							isTextarea: true,
							rowsHtmlAttributeValue: 5,
							maxLength: 255
						}
					}
				}
			}
		};
	}

	// FIELD MAPS
	getSellerFieldsMap(fieldsMapPrefix: string) {
		const me = this;
		fieldsMapPrefix = fieldsMapPrefix + '.';

		return {
			...me.getCompanyFieldsMap(fieldsMapPrefix),
			...me.getIndividualFieldsMap(fieldsMapPrefix),
			...me.getPhysicalFieldsMap(fieldsMapPrefix),
			...me.getForeignFieldsMap(fieldsMapPrefix),

			...me.getRusAddressFieldsMap(fieldsMapPrefix),
			...me.getForeignAddrFieldsMap(fieldsMapPrefix),
			...me.getGarAddrFieldsMap(fieldsMapPrefix),

			...me.getContactFieldsMap(fieldsMapPrefix),

			...me.getBankFieldsMap(fieldsMapPrefix),

			...me.getOtherFieldsMap(fieldsMapPrefix),

			type: true
		};
	}

	getBuyerFieldsMap(fieldsMapPrefix: string) {
		const me = this;
		fieldsMapPrefix = fieldsMapPrefix + '.';

		return {
			...me.getCompanyFieldsMap(fieldsMapPrefix),
			...me.getIndividualFieldsMap(fieldsMapPrefix),
			...me.getPhysicalFieldsMap(fieldsMapPrefix),
			...me.getForeignFieldsMap(fieldsMapPrefix),

			...me.getRusAddressFieldsMap(fieldsMapPrefix),
			...me.getForeignAddrFieldsMap(fieldsMapPrefix),
			...me.getGarAddrFieldsMap(fieldsMapPrefix),

			...me.getContactFieldsMap(fieldsMapPrefix),

			...me.getBankFieldsMap(fieldsMapPrefix),

			...me.getOtherFieldsMap(fieldsMapPrefix),

			type: true
		};
	}

	getShipFromFieldsMap(fieldsMapPrefix: string) {
		const me = this;
		fieldsMapPrefix = fieldsMapPrefix + '.';

		return {
			...me.getCompanyFieldsMap(fieldsMapPrefix),
			...me.getIndividualFieldsMap(fieldsMapPrefix),
			...me.getPhysicalFieldsMap(fieldsMapPrefix),
			...me.getForeignFieldsMap(fieldsMapPrefix),

			...me.getRusAddressFieldsMap(fieldsMapPrefix),
			...me.getForeignAddrFieldsMap(fieldsMapPrefix),
			...me.getGarAddrFieldsMap(fieldsMapPrefix),

			...me.getContactFieldsMap(fieldsMapPrefix),

			...me.getBankFieldsMap(fieldsMapPrefix),

			...me.getOtherFieldsMap(fieldsMapPrefix),

			type: 'type'
		};
	}

	getCargoReceiverFieldsMap(fieldsMapPrefix: string) {
		const me = this;
		fieldsMapPrefix = fieldsMapPrefix + '.';

		return {
			...me.getCompanyFieldsMap(fieldsMapPrefix),
			...me.getIndividualFieldsMap(fieldsMapPrefix),
			...me.getPhysicalFieldsMap(fieldsMapPrefix),
			...me.getForeignFieldsMap(fieldsMapPrefix),

			...me.getRusAddressFieldsMap(fieldsMapPrefix),
			...me.getForeignAddrFieldsMap(fieldsMapPrefix),
			...me.getGarAddrFieldsMap(fieldsMapPrefix),

			...me.getContactFieldsMap(fieldsMapPrefix),

			...me.getBankFieldsMap(fieldsMapPrefix),

			...me.getOtherFieldsMap(fieldsMapPrefix),

			type: 'type'
		};
	}

	getFactorFieldsMap(fieldsMapPrefix: string) {
		const me = this;
		fieldsMapPrefix = fieldsMapPrefix + '.';

		return {
			...me.getCompanyFieldsMap(fieldsMapPrefix),
			...me.getIndividualFieldsMap(fieldsMapPrefix),
			...me.getPhysicalFieldsMap(fieldsMapPrefix),
			...me.getForeignFieldsMap(fieldsMapPrefix),

			...me.getRusAddressFieldsMap(fieldsMapPrefix),
			...me.getForeignAddrFieldsMap(fieldsMapPrefix),
			...me.getGarAddrFieldsMap(fieldsMapPrefix),

			...me.getContactFieldsMap(fieldsMapPrefix),

			...me.getBankFieldsMap(fieldsMapPrefix),

			...me.getOtherFieldsMap(fieldsMapPrefix),

			type: 'type'
		};
	}

	// MODAL CONFIGS

	getSellerModalConfig() {
		const me = this;

		return {
			title: edi.i18n.getMessage('document.seller'),
			tabs: {
				...me.getMainTabFields(),
				...me.getAddressTabFields(),
				...me.getBankTabFields(),
				...me.getContactTabFields()
			}
		};
	}

	getBuyerModalConfig() {
		const me = this;

		return {
			title: edi.i18n.getMessage('document.buyer'),
			tabs: {
				...me.getMainTabFields(),
				...me.getAddressTabFields(),
				...me.getBankTabFields(),
				...me.getContactTabFields()
			}
		};
	}

	getShipFromModalConfig() {
		const me = this;

		return {
			title: edi.i18n.getMessage('document.ship.from'),
			tabs: {
				...me.getMainTabFields(),
				...me.getAddressTabFields(),
				...me.getBankTabFields(),
				...me.getContactTabFields()
			}
		};
	}

	getCargoReceiverModalConfig() {
		const me = this;

		return {
			title: edi.i18n.getMessage('document.delivery'),
			tabs: {
				...me.getMainTabFields(),
				...me.getAddressTabFields(),
				...me.getBankTabFields(),
				...me.getContactTabFields()
			}
		};
	}

	getFactorModalConfig() {
		const me = this;

		return {
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.factorInfo'),
			tabs: {
				...me.getMainTabFields(),
				...me.getAddressTabFields(),
				...me.getBankTabFields(),
				...me.getContactTabFields()
			}
		};
	}
}
