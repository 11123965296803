import { getPartinLocalizationsColumns, getPartinHeadquartersColumns, getPartinBranchesColumns } from './columns.js';
import { getPartinLocalizationsModel, getPartinHeadquartersModel, getPartinBranchesModel } from './models.js';
import {
	createCatalogGrid,
	convertOrgBeforeRender,
	showModalLocalizationsForm,
	showModalHeadquartersForm,
	showModalBranchesForm
} from './methods';

import {
	createActionsPanel,
	createDetailsModulePanel,
	createFormForModule,
	createTab,
	createTabPanel,
	TAB_PANEL_CLS
} from '@Components/panels';
import { createActionsButton } from '@Components/buttons';
import { createLabelForDetails } from '@UIkit/components/fields';
import { createFieldBlockForDetails, createFieldSet, FIELD_BLOCK_CLS } from '@UIkit/components/panels';

class PartinDetails {
	moduleData;
	docHeader;
	docId;
	docContent;
	activateCatalogActionBtn;
	localizationsGrid;
	headquartersGrid;
	branchesGrid;
	moduleForm;
	activeTab;
	tabCounter = 0;
	_changeHandler;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data, initCallBack) {
		const me = this;
		me._changeHandler = me.changeHandler.bind(me);
		me.moduleData = data;
		me.activeTab = 0;
		me.docHeader = edi.utils.getObjectProperty(me.moduleData, 'initData.data') || {};
		me.docId = me.docHeader.id;

		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	changeHandler(eventData) {
		const me = this;
		if (
			eventData &&
			eventData.catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN &&
			eventData.id == me.docId
		) {
			if (eventData.deleted || eventData.reloadCatalog) {
				edi.modulesHandler.removeModule(me.moduleData);
			} else {
				switch (eventData.subCatalogType) {
					case 'localization':
						me.localizationsGrid.getStore().reload();
						break;
					case 'branch':
						me.branchesGrid.getStore().reload();
						break;
					case 'headquarter':
						me.headquartersGrid.getStore().reload();
						break;
					default:
						me.moduleData.tab.removeAll();
						me.renderData();
						break;
				}
			}
		}
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.catalog.on('change', me._changeHandler);
	}

	getCatalogGridProxyConfig({ id, type }) {
		return {
			type: 'ajax',
			url: edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.GET, {
				id,
				type
			})
		};
	}

	getCatalogGridStoreConfig() {
		const me = this;
		return {
			listeners: {
				dataChanged: (store) => {
					if (store.getCount() && me.activateCatalogActionBtn?.disabled) {
						me.activateCatalogActionBtn.setDisabled(false);
					}
				}
			}
		};
	}

	/**
	 * Creates Localizations grid
	 * @return    {Object}   grid
	 */
	createLocalizationsGrid() {
		const me = this;
		me.localizationsGrid = createCatalogGrid(
			{
				columns: getPartinLocalizationsColumns(),
				title: edi.i18n.getMessage('catalog.partin.localizations'),
				model: getPartinLocalizationsModel(),
				store: me.getCatalogGridStoreConfig(),
				proxy: me.getCatalogGridProxyConfig({ id: me.docId, type: 'localization' }),
				readOnly: true
			},
			null,
			showModalLocalizationsForm
		);

		let tabIndex = me.tabCounter++;

		return createTab({
			title: edi.i18n.getMessage('catalog.partin.localizations'),
			closable: false,
			listeners: {
				activate: function () {
					me.activeTab = tabIndex;
					me.docHeader.activeTab = me.activeTab;
				}
			},
			items: [me.localizationsGrid]
		});
	}

	/**
	 * Creates Headquarters grid
	 * @return    {Object}   grid
	 */
	createHeadquartersGrid() {
		const me = this;
		me.headquartersGrid = createCatalogGrid(
			{
				columns: getPartinHeadquartersColumns(),
				title: edi.i18n.getMessage('catalog.partin.headquarters'),
				model: getPartinHeadquartersModel(),
				store: me.getCatalogGridStoreConfig(),
				proxy: me.getCatalogGridProxyConfig({ id: me.docId, type: 'headquarter' }),
				readOnly: true
			},
			null,
			showModalHeadquartersForm
		);

		let tabIndex = me.tabCounter++;

		return createTab({
			title: edi.i18n.getMessage('catalog.partin.headquarters'),
			closable: false,
			listeners: {
				activate: function () {
					me.activeTab = tabIndex;
					me.docHeader.activeTab = me.activeTab;
				}
			},
			items: [me.headquartersGrid]
		});
	}

	/**
	 * Creates Branches grid
	 * @return    {Object}   grid
	 */
	createBranchesGrid() {
		const me = this;
		me.branchesGrid = createCatalogGrid(
			{
				columns: getPartinBranchesColumns(),
				title: edi.i18n.getMessage('catalog.partin.branches'),
				model: getPartinBranchesModel(),
				store: me.getCatalogGridStoreConfig(),
				proxy: me.getCatalogGridProxyConfig({ id: me.docId, type: 'branch' }),
				readOnly: true
			},
			null,
			showModalBranchesForm
		);

		let tabIndex = me.tabCounter++;

		return createTab({
			title: edi.i18n.getMessage('catalog.partin.branches'),
			closable: false,
			listeners: {
				activate: function () {
					me.activeTab = tabIndex;
					me.docHeader.activeTab = me.activeTab;
				}
			},
			items: [me.branchesGrid]
		});
	}

	/**
	 * Delete catalog grid
	 * @return    {Object}   grid
	 */
	removePartin(id, catalogType) {
		edi.core.confirm('confirmation.title', 'document.delete.question', function () {
			let moduleData = edi.modulesHandler.getActiveModule();
			moduleData.tab.setLoading();
			let failure = function () {
				edi.core.showError('error.server', function () {
					moduleData.tab.setLoading(false);
				});
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.CATALOGS.V2.DELETE, {
					type: edi.constants.CATALOG_TYPES[catalogType],
					id: id
				}),
				'DELETE',
				null,
				function () {
					moduleData.tab.setLoading(false);
					edi.events.catalog.fireEvent('change', {
						id: id,
						catalogType: catalogType,
						deleted: true
					});
				},
				failure
			);
		});
	}

	/**
	 * Activate catalog
	 */
	activatePartin(id) {
		const me = this;
		let moduleData = edi.modulesHandler.getActiveModule();
		moduleData.tab.setLoading();
		let failure = function () {
			edi.core.showError('error.server', function () {
				moduleData.tab.setLoading(false);
			});
		};
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.CATALOGS.V2.ACTIVATE, {
				type: edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN],
				id: id
			}),
			'PUT',
			null,
			function () {
				moduleData.tab.setLoading(false);
				edi.events.catalog.fireEvent('change', {
					id: id,
					catalogType: edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN
				});
				me.docHeader.state = edi.constants.CATALOG_STATES.ACTIVE;
			},
			failure
		);
	}

	/**
	 * Complete catalog grid
	 * @return    {Object}   grid
	 */
	completePartin(id, catalogType) {
		const me = this;
		me.moduleData.tab.setLoading();
		let success = function () {
			me.moduleData.tab.setLoading(false);
			edi.events.catalog.fireEvent('change', {
				id: id,
				catalogType: catalogType,
				deleted: true
			});
		};
		let failure = function () {
			edi.core.showError('error.server', function () {
				me.moduleData.tab.setLoading(false);
			});
		};
		const postData = {};
		const stringified = Ext.encode(postData);
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.CATALOGS.V2.COMPLETED,
				{
					type: edi.constants.CATALOG_TYPES[catalogType],
					id: id
				},
				true
			),
			'PUT',
			stringified,
			success,
			failure
		);
	}

	/**
	 * Creates action pane
	 */
	createModuleActionsPanel = function () {
		const me = this;
		let actionsPanel = createActionsPanel();
		const direction = edi.utils.getDocumentDirection(me.docHeader.toOrg, me.docHeader.fromOrg);
		let directionForEdit =
			direction === edi.constants.DIRECTIONS.OUTGOING || direction === edi.constants.DIRECTIONS.LOOP;
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: me.docHeader,
			moduleData: me.moduleData,
			infoNumber: me.docContent?.partyInformationCatalogNumber,
			actionProps: {
				SEND: {
					component: (me.activateCatalogActionBtn = createActionsButton({
						text: edi.i18n.getMessage('catalog.activate'),
						glyph: edi.constants.ICONS.CHECK_LIST,
						//для активации в каком-то гриде должна быть хотя бы одна запись
						disabled: true,
						handler: function () {
							me.activatePartin(me.docId);
						}
					})),
					isAvailable: () => {
						return (
							edi.permissions.hasPermission('CLIENT_LEGACY_PARTIN_ACTIVATE') &&
							me.docHeader.state === edi.constants.CATALOG_STATES.DRAFT &&
							directionForEdit
						);
					}
				},
				COMPLETE: {
					component: createActionsButton({
						text: edi.i18n.getMessage('request.action.read.and.complete'),
						glyph: edi.constants.ICONS.DONE,
						handler: function () {
							me.completePartin(me.docId, edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN);
						}
					}),
					isAvailable: () => {
						return (
							edi.permissions.hasPermission('CLIENT_LEGACY_PARTIN_COMPLETE') &&
							me.docHeader.state === edi.constants.STATE.ACTIVE &&
							direction !== edi.constants.DIRECTIONS.INCOMING
						);
					}
				},
				EDIT: {
					component: edi.document.actions.createEditActionButton(
						'partin.create',
						me.docHeader,
						edi.i18n.getMessage(
							'catalog.type.' + edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN]
						) +
							' №' +
							(edi.utils.getObjectProperty(me.docContent, 'partyInformationCatalogNumber') || ''),
						null,
						null
					),
					isAvailable: () => {
						return (
							edi.permissions.hasPermission('CLIENT_LEGACY_PARTIN_EDIT') &&
							me.docHeader.state === edi.constants.CATALOG_STATES.DRAFT &&
							directionForEdit
						);
					}
				},
				DELETE: {
					component: createActionsButton({
						text: edi.i18n.getMessage('document.delete.document'),
						glyph: edi.constants.ICONS.DELETE,
						handler: function () {
							me.removePartin(me.docId, edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN);
						}
					}),
					isAvailable: () => {
						return (
							edi.permissions.hasPermission('CLIENT_LEGACY_PARTIN_DELETE') &&
							me.docHeader.state === edi.constants.CATALOG_STATES.DRAFT &&
							directionForEdit
						);
					}
				},
				EXPORT: {
					component: createActionsButton({
						text: edi.i18n.getMessage('action.export'),
						glyph: edi.constants.ICONS.FILE_DOWNLOAD,
						handler: function () {
							me.moduleData.initData.docExportUri = edi.rest.services.CATALOGS.LEGACY_PARTIN.EXPORT;
							edi.rest.downloadFile(
								edi.utils.formatString(me.moduleData.initData.docExportUri, {
									id: me.docId
								})
							);
						}
					}),
					isAvailable: () => true
				},
				REFRESH: null
			}
		});

		return actionsPanel;
	};

	getHeaderUrl() {
		const me = this;
		return edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.HEADER.GET, {
			id: me.docId
		});
	}

	getHeaderData({ onSuccess }) {
		const me = this;
		const url = me.getHeaderUrl();
		let failure = edi.document.actions.defaultFailureHandler(me.moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(me.moduleData);
		});
		const success = function (respData) {
			if (respData && respData.data) {
				me.docContent = (respData.data && respData.data) || {};
				if (typeof onSuccess === 'function') {
					onSuccess(respData);
				}
			} else {
				failure(respData);
			}
		};

		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	}

	/**
	 * Creates header fieldset
	 * @return    {Object}    header fieldset
	 */
	createHeaderFieldset = function () {
		const me = this;
		const get = function (name) {
			return edi.utils.getObjectProperty(me.docContent, name);
		};

		let documentFunctionCode = get('documentFunctionCode');
		const defaultFieldBlockConfig = {
			cls: [FIELD_BLOCK_CLS.small, FIELD_BLOCK_CLS.titleMarginMedium]
		};
		return createFieldSet({
			title: edi.i18n.getMessage('catalog.details'),
			collapsible: true,
			collapsed: true,
			layout: {
				type: 'grid',
				gap: [26, 16],
				area: [[2, 2, 2, 2, 2], 12]
			},
			items: [
				createFieldBlockForDetails({
					...defaultFieldBlockConfig,
					title: edi.i18n.getMessage('catalog.partin.catalogNumber'),
					items: [
						createLabelForDetails({
							text: get('partyInformationCatalogNumber')
						})
					]
				}),
				createFieldBlockForDetails({
					...defaultFieldBlockConfig,
					title: edi.i18n.getMessage('catalog.partin.catalogDate'),
					items: [
						createLabelForDetails({
							text: edi.utils.formatDate(
								get('partyInformationCatalogDate'),
								edi.constants.DATE_FORMAT.FNS,
								edi.constants.DATE_FORMAT.CLIENT
							)
						})
					]
				}),
				createFieldBlockForDetails({
					...defaultFieldBlockConfig,
					title: edi.i18n.getMessage('catalog.partin.state'),
					items: [
						createLabelForDetails({
							html: edi.renderers.baseStateRenderer(
								me.docHeader.state,
								edi.i18n.getMessage('catalog.status.' + me.docHeader.state)
							)
						})
					]
				}),
				createFieldBlockForDetails({
					...defaultFieldBlockConfig,
					title: edi.i18n.getMessage('catalog.partin.functionCode'),
					items: [
						createLabelForDetails({
							text: documentFunctionCode
								? edi.i18n.getMessage('functional.document.code.' + documentFunctionCode)
								: undefined
						})
					]
				}),
				createFieldBlockForDetails({
					...defaultFieldBlockConfig,
					title: edi.i18n.getMessage('catalog.partin.receiver'),
					items: [
						createLabelForDetails({
							text: get('toOrg.name')
						})
					]
				}),
				createFieldBlockForDetails({
					...defaultFieldBlockConfig,
					title: edi.i18n.getMessage('remarks'),
					items: [
						createLabelForDetails({
							text: get('remarks')
						})
					]
				})
			]
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	renderData(initCallBack) {
		const me = this;
		me.moduleData.tab.setLoading();
		const onSuccess = function () {
			['fromOrg', 'toOrg'].forEach((path) => {
				let newValues = convertOrgBeforeRender(edi.utils.getObjectProperty(me.docHeader, path));
				edi.utils.setObjectProperty(me.docContent, path, newValues);
			});
			me.tabCounter = 0;

			let catalogTab = createTabPanel(
				{
					cls: [TAB_PANEL_CLS.simpleWithoutPadding, 'module-tabs', 'edi-additional-tbar'],
					activeTab: me.activeTab,
					items: [me.createLocalizationsGrid(), me.createHeadquartersGrid(), me.createBranchesGrid()]
				},
				true
			);

			me.moduleForm = createFormForModule({
				cls: 'edi-form document-details-form',
				items: [me.createHeaderFieldset(), catalogTab]
			});

			let modulePanel = createDetailsModulePanel({
				items: [me.moduleForm]
			});

			me.moduleData.tab.removeAll();
			me.moduleData.tab.add(me.createModuleActionsPanel());
			me.moduleData.tab.add(modulePanel);
			me.moduleData.tab.setLoading(false);
			if ('function' == typeof initCallBack) {
				initCallBack();
			}
		};

		me.getHeaderData({
			onSuccess
		});
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;
		edi.events.catalog.un('change', me._changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

class ClientPartinDetails extends PartinDetails {}

Ext.namespace('edi.modules');
edi.modules['partin.details'] = ClientPartinDetails;

export { PartinDetails };
