import { SvedTov } from './definitions/producLineDef';
import { UPD502SummaryRecord } from './definitions/records';

Ext.namespace('edi.total');
Object.assign(edi.total, {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.02-N']: function (
		lines: number,
		store: ExtBase,
		totalRecord: ExtRecord<UPD502SummaryRecord>,
		documentData: AnyObject
	) {
		if (documentData && !documentData.skipGetObjectTotalProperty) {
			totalRecord.set('nomStrVsego', lines || 0);
			totalRecord.set(
				'stTovBezNDSVsego',
				edi.utils.getObjectProperty(documentData, 'tablSchFakt.vsegoOpl.stTovBezNDSVsego', false) || 0
			);
			totalRecord.set(
				'sumNalVsego',
				edi.utils.getObjectProperty(documentData, 'tablSchFakt.vsegoOpl.sumNalVsego.sumNal', false) || 0
			);
			totalRecord.set(
				'stTovUchNalVsego',
				edi.utils.getObjectProperty(documentData, 'tablSchFakt.vsegoOpl.stTovUchNalVsego', false) || 0
			);
			totalRecord.set(
				'kolNettoVs',
				edi.utils.getObjectProperty(documentData, 'tablSchFakt.vsegoOpl.kolNettoVs', false) || 0
			);
		} else {
			let totalWithNDS = 0;
			let totalNDS = 0;
			let totalWithoutNDS = 0;
			let totalCount = 0;

			store.each(function (record: ExtRecord<SvedTov>) {
				const data = record.getData();
				if (data.stTovBezNDS) {
					totalWithoutNDS += data.stTovBezNDS ?? 0;
				}
				if ('sumNal' in data.sumNal) {
					totalNDS += +data.sumNal.sumNal ?? 0;
				}
				if (data.stTovUchNal) {
					totalWithNDS += data.stTovUchNal ?? 0;
				}
				if (data.kolTov) {
					totalCount += data.kolTov ?? 0;
				}
			});

			totalRecord.set('nomStrVsego', lines || 0);
			totalRecord.set('stTovBezNDSVsego', edi.utils.roundTo(totalWithoutNDS, 2) || 0);
			totalRecord.set('sumNalVsego', edi.utils.roundTo(totalNDS, 2) || 0);
			totalRecord.set('stTovUchNalVsego', edi.utils.roundTo(totalWithNDS, 2) || 0);
			totalRecord.set('kolNettoVs', totalCount || 0);
		}
	}
});
