/**
 * Modules configuration for edi
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.modulesCfg');
new (function () {
	var path = 'edi/modules';
	var fnsPatch = 'fns/modules';
	Object.assign(edi.modulesCfg, {
		documents: {
			title: 'navigation.documents',
			modName: 'documents',
			folder: path + '/documentGrids',
			isMain: true,
			permissions: ['READ_DOCUMENTS']
		},
		'document.archive': {
			title: 'navigation.archive',
			modName: 'document.archive',
			folder: path + '/documentGrids',
			permissions: ['READ_DOCUMENTS']
		},
		'document.statuses': {
			title: 'module.tab.statuses',
			modName: 'document.statuses',
			folder: path + '/documentGrids',
			permissions: ['READ_DOCUMENTS']
		},
		'patterns.generation.doc.num': {
			title: 'document.generation.numbers',
			folder: path,
			modName: 'patterns.generation.doc.num',
			permissions: ['CLIENT_NUMBERATION_SETTINGS']
		},
		'document.create.dsf': {
			title: 'document.add.dsf',
			modName: 'document.create.dsf',
			folder: path + '/documents/DSF',
			permissions: ['CREATE_DSF'],
			permissionsEdit: ['EDIT_DSF']
		},
		'document.create.invoice': {
			title: 'document.add.invoice',
			modName: 'document.create.invoice',
			folder: path + '/documents/LEGACY_INVOICE',
			permissions: ['CREATE_LEGACY_INVOICE'],
			permissionsEdit: ['EDIT_LEGACY_INVOICE']
		},
		'document.create.recadv': {
			title: 'document.add.recadv',
			modName: 'document.create.recadv',
			folder: path + '/documents/LEGACY_REC_ADV',
			permissions: ['CREATE_LEGACY_REC_ADV'],
			permissionsEdit: ['EDIT_LEGACY_REC_ADV']
		},
		'document.create.retann': {
			title: 'document.add.retann',
			modName: 'document.create.retann',
			folder: path + '/documents/LEGACY_RETANN',
			permissions: ['CREATE_LEGACY_RETANN'],
			permissionsEdit: ['EDIT_LEGACY_RETANN']
		},
		'document.create.order': {
			title: 'document.add.order',
			modName: 'document.create.order',
			folder: path + '/documents/LEGACY_ORDER',
			permissions: ['CREATE_LEGACY_ORDER'],
			permissionsEdit: ['EDIT_LEGACY_ORDER']
		},
		'document.create.deljit': {
			title: 'document.add.deljit',
			modName: 'document.create.deljit',
			folder: path + '/documents/LEGACY_DELJIT',
			permissions: ['CREATE_LEGACY_DELJIT'],
			permissionsEdit: ['EDIT_LEGACY_DELJIT']
		},
		'document.create.torg_2': {
			title: 'document.add.torg2',
			modName: 'document.create.torg_2',
			folder: fnsPatch + '/documents/TORG_2',
			permissions: ['CREATE_EDI_FNS_TORG2'],
			permissionsEdit: ['EDIT_EDI_FNS_TORG2']
		},
		'document.create.desadv': {
			title: 'document.add.desadv',
			modName: 'document.create.desadv',
			folder: path + '/documents/LEGACY_DES_ADV',
			permissions: ['CREATE_LEGACY_DES_ADV'],
			permissionsEdit: ['EDIT_LEGACY_DES_ADV']
		},
		'document.create.alcdes': {
			title: 'document.add.alcdes',
			modName: 'document.create.alcdes',
			folder: path + '/documents/LEGACY_ALCDES',
			permissions: ['CREATE_LEGACY_ALCDES'],
			permissionsEdit: ['EDIT_LEGACY_ALCDES']
		},
		'document.create.ordrsp': {
			title: 'document.add.ordrsp',
			modName: 'document.create.ordrsp',
			folder: path + '/documents/LEGACY_ORDER_RESP',
			permissions: ['CREATE_LEGACY_ORDER_RESP'],
			permissionsEdit: ['EDIT_LEGACY_ORDER_RESP']
		},
		'document.create.iftmbf': {
			title: 'document.create.iftmbf',
			modName: 'document.create.iftmbf',
			folder: path + '/documents/EDI_IFTMBF',
			permissions: ['CREATE_EDI_IFTMBF'],
			permissionsEdit: ['EDIT_EDI_IFTMBF']
		},
		'document.create.iftmbf_approval': {
			title: 'document.create.iftmbf_approval',
			modName: 'document.create.iftmbf_approval',
			folder: path + '/documents/EDI_IFTMBF_APPROVAL',
			permissions: ['CREATE_EDI_IFTMBF_APPROVAL'],
			permissionsEdit: ['EDIT_EDI_IFTMBF_APPROVAL']
		},
		'document.create.iftmbc': {
			title: 'document.create.iftmbc',
			modName: 'document.create.iftmbc',
			folder: path + '/documents/EDI_IFTMBC',
			permissions: ['CREATE_EDI_IFTMBC'],
			permissionsEdit: ['EDIT_EDI_IFTMBC']
		},
		'document.create.delfor': {
			title: 'document.create.delfor',
			modName: 'document.create.delfor',
			folder: path + '/documents/DELFOR',
			permissions: ['CREATE_DELFOR'],
			permissionsEdit: ['EDIT_DELFOR_RECEIVER']
		},
		'document.create.pricateancom': {
			title: 'document.create.pricateancom',
			modName: 'document.create.pricateancom',
			folder: path + '/documents/PRICAT_EANCOM',
			permissions: ['CREATE_PRICAT_EANCOM'],
			permissionsEdit: ['EDIT_PRICAT_EANCOM']
		},
		'document.create.pricateancom_v2': {
			title: 'document.create.pricateancom_v2',
			modName: 'document.create.pricateancom_v2',
			folder: path + '/documents/PRICAT_EANCOM',
			permissions: ['CREATE_PRICAT_EANCOM'],
			permissionsEdit: ['EDIT_PRICAT_EANCOM']
		},
		'document.create.commonpricat': {
			title: 'document.create.commonpricat',
			modName: 'document.create.commonpricat',
			folder: path + '/documents/COMMON_PRICAT',
			permissions: ['CREATE_COMMON_PRICAT_WITH_SIGN'],
			permissionsEdit: ['EDIT_COMMON_PRICAT']
		},
		'document.details.container_common_pricat': {
			title: 'document.details.container_common_pricat',
			modName: 'document.details.container_common_pricat',
			highlightMenuId: 'documents',
			folder: path + '/documents/CONTAINER_COMMON_PRICAT',
			permissions: ['READ_CONTAINER_COMMON_PRICAT']
		},
		'document.create.iftmbc_approval': {
			title: 'document.create.iftmbc_approval',
			modName: 'document.create.iftmbc_approval',
			folder: path + '/documents/EDI_IFTMBC_APPROVAL',
			permissions: ['CREATE_EDI_IFTMBC_APPROVAL'],
			permissionsEdit: ['EDIT_EDI_IFTMBC_APPROVAL']
		},
		'document.create.formalized': {
			title: 'document.create.formalized',
			modName: 'document.create.formalized',
			folder: path + '/documents/FORMALIZED_DOCUMENT',
			permissions: ['CREATE_FORMALIZED_DOCUMENT'],
			permissionsEdit: ['EDIT_FORMALIZED_DOCUMENT']
		},
		'document.details.invoice': {
			title: 'document.details.invoice',
			modName: 'document.details.invoice',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_INVOICE',
			permissions: ['READ_LEGACY_INVOICE']
		},
		'document.details.order': {
			title: 'document.details.order',
			modName: 'document.details.order',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_ORDER',
			permissions: ['READ_LEGACY_ORDER']
		},
		'document.details.x5.agreement.container': {
			title: 'x5.agreement.container',
			modName: 'document.details.x5.agreement.container',
			highlightMenuId: 'documents',
			folder: path + '/documents/X5_AGREEMENT_CONTAINER',
			permissions: ['READ_X5_AGREEMENT_CONTAINER']
		},
		'document.details.pricateancom': {
			title: 'document.details.pricateancom',
			modName: 'document.details.pricateancom',
			highlightMenuId: 'documents',
			folder: path + '/documents/PRICAT_EANCOM',
			permissions: ['READ_PRICAT_EANCOM']
		},
		'document.details.pricateancom_v2': {
			title: 'document.details.pricateancom_v2',
			modName: 'document.details.pricateancom_v2',
			highlightMenuId: 'documents',
			folder: path + '/documents/PRICAT_EANCOM',
			permissions: ['READ_PRICAT_EANCOM']
		},
		'document.details.commonpricat': {
			title: 'document.details.commonpricat',
			modName: 'document.details.commonpricat',
			highlightMenuId: 'documents',
			folder: path + '/documents/COMMON_PRICAT',
			permissions: ['READ_COMMON_PRICAT']
		},
		'document.details.slsfct': {
			title: 'document.details.slsfct',
			modName: 'document.details.slsfct',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_SLSFCT',
			permissions: ['READ_SLSFCT']
		},
		'document.details.logset': {
			title: 'document.details.logset',
			modName: 'document.details.logset',
			highlightMenuId: 'documents',
			folder: path + '/documents/LOGSET',
			permissions: ['READ_LOGSET']
		},
		'document.create.logset': {
			title: 'document.create.logset',
			modName: 'document.create.logset',
			folder: path + '/documents/LOGSET',
			permissionsEdit: ['EDIT_LOGSET_RECEIVER']
		},
		'document.details.deljit': {
			title: 'document.details.deljit',
			modName: 'document.details.deljit',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_DELJIT',
			permissions: ['CREATE_LEGACY_DELJIT']
		},
		'document.details.recadv': {
			title: 'document.details.recadv',
			modName: 'document.details.recadv',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_REC_ADV',
			permissions: ['READ_LEGACY_REC_ADV']
		},
		'document.details.torg_2': {
			title: 'document.details.torg2',
			modName: 'document.details.torg_2',
			highlightMenuId: 'documents',
			folder: fnsPatch + '/documents/TORG_2',
			permissions: ['READ_EDI_FNS_TORG2']
		},
		'document.details.retann': {
			title: 'document.details.retann',
			modName: 'document.details.retann',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_RETANN',
			permissions: ['READ_LEGACY_RETANN']
		},
		'document.details.desadv': {
			title: 'document.details.desadv',
			modName: 'document.details.desadv',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_DES_ADV',
			permissions: ['READ_LEGACY_DES_ADV']
		},
		'document.details.alcdes': {
			title: 'document.details.alcdes',
			modName: 'document.details.alcdes',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_ALCDES',
			permissions: ['READ_LEGACY_ALCDES']
		},
		'document.details.on_avz': {
			title: 'document.details.on_avz',
			modName: 'document.details.on_avz',
			highlightMenuId: 'documents',
			folder: path + '/documents/ON_AVZ',
			permissions: ['READ_ON_AVZ']
		},
		'document.details.ordrsp': {
			title: 'document.details.ordrsp',
			modName: 'document.details.ordrsp',
			highlightMenuId: 'documents',
			folder: path + '/documents/LEGACY_ORDER_RESP',
			permissions: ['READ_LEGACY_ORDER_RESP']
		},
		'document.details.dsf': {
			title: 'document.details.dsf',
			modName: 'document.details.dsf',
			highlightMenuId: 'documents',
			folder: path + '/documents/DSF',
			permissions: ['READ_DSF']
		},
		'document.details.dsf_reestr_route': {
			title: 'document.details.dsf_reestr_route',
			modName: 'document.details.dsf_reestr_route',
			highlightMenuId: 'documents',
			folder: path + '/documents/DSF_REESTR_ROUTE',
			permissions: ['CLIENT_DSF_REESTR_ROUTE_READ']
		},
		'document.details.delfor': {
			title: 'document.details.delfor',
			modName: 'document.details.delfor',
			highlightMenuId: 'documents',
			folder: path + '/documents/DELFOR',
			permissions: ['READ_DELFOR']
		},
		'document.details.iftmbf': {
			title: 'document.details.iftmbf',
			modName: 'document.details.iftmbf',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_IFTMBF',
			permissions: ['READ_EDI_IFTMBF']
		},
		'document.details.slsrpt': {
			title: 'document.details.slsrpt',
			modName: 'document.details.slsrpt',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_SLSRPT',
			permissions: ['READ_SLSRPT']
		},
		'document.details.iftmbf_approval': {
			title: 'document.details.iftmbf_approval',
			modName: 'document.details.iftmbf_approval',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_IFTMBF_APPROVAL',
			permissions: ['READ_EDI_IFTMBF_APPROVAL']
		},
		'document.details.iftmbc': {
			title: 'document.details.iftmbc',
			modName: 'document.details.iftmbc',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_IFTMBC',
			permissions: ['READ_EDI_IFTMBC']
		},
		'document.details.iftmbc_approval': {
			title: 'document.details.iftmbc_approval',
			modName: 'document.details.iftmbc_approval',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_IFTMBC_APPROVAL',
			permissions: ['READ_EDI_IFTMBC_APPROVAL']
		},
		'document.details.formalized': {
			title: 'document.details.formalized',
			modName: 'document.details.formalized',
			highlightMenuId: 'documents',
			folder: path + '/documents/FORMALIZED_DOCUMENT',
			permissions: ['READ_FORMALIZED_DOCUMENT']
		},
		'document.details.frov_sig_so_act2_from_x5': {
			title: 'document.details.frov_sig_so_act2_from_x5',
			modName: 'document.details.frov_sig_so_act2_from_x5',
			highlightMenuId: 'documents',
			folder: path + '/documents/FROV_SIG_SO_ACT2_FROM_X5',
			permissions: ['READ_FROV_SIG_SO_ACT2_FROM_X5']
		},
		invitations: {
			title: 'invitations',
			modName: 'invitations',
			folder: path + '/invitations',
			permissions: ['READ_INVITATION_TO_COOPERATION']
		},
		'invitation.details': {
			title: 'invitation.details',
			modName: 'invitation.details',
			highlightMenuId: 'invitations',
			folder: path + '/invitations',
			permissions: ['READ_INVITATION_TO_COOPERATION']
		},
		'catalogs.v2': {
			title: 'catalogs',
			modName: 'catalogs.v2',
			folder: path + '/catalog',
			permissions: ['READ_CATALOG_V2']
		},
		'catalog.create': {
			title: 'catalog.create',
			modName: 'catalog.create',
			folder: path + '/catalog',
			permissions: ['EDIT_CATALOG_V2'],
			permissionsEdit: ['EDIT_CATALOG_V2']
		},
		'catalog.details': {
			title: 'catalog.details',
			modName: 'catalog.details',
			highlightMenuId: 'catalogs.v2',
			folder: path + '/catalog',
			permissions: {
				OR: ['READ_CATALOG', 'READ_CATALOG_V2']
			}
		},
		//Данный модуль используется только в ФП, но кто знает, где ещё он может понадобиться
		//со стороны бэка поддержка осталась в edi, поэтому просто отключим его из бандла
		'notifications.settings': {
			title: 'notifications.settings',
			modName: 'notifications.settings',
			folder: path,
			permissions: {
				OR: ['GET_USER_MAIL_NOTIFICATIONS_SETTINGS', 'GET_USER_NOTIFICATIONS_SETTINGS']
			}
		},
		partners: {
			title: 'org.partners',
			modName: 'partners',
			folder: path + '/partners',
			permissions: ['READ_USER_PROFILE']
		},
		'partner.details': {
			title: 'org.partner.details',
			modName: 'partner.details',
			folder: path + '/partners',
			permissions: ['READ_USER_PROFILE']
		},
		'rounding.settings': {
			title: 'rounding.settings',
			modName: 'rounding.settings',
			folder: path + '/organization_settings',
			permissions: ['EDIT_ORGANIZATION_SETTINGS']
		},
		'email.notification': {
			title: 'email.notification',
			modName: 'email.notification',
			folder: path + '/organization_settings',
			permissions: ['READ_EMAIL_NOTIFICATION_SETTINGS']
		},
		'deferred.reports': {
			title: 'deferred.reports',
			folder: path,
			modName: 'deferred.reports',
			permissions: ['READ_EDI_REPORT_REQUEST_PROCESSING']
		},
		'document.details.invrpt': {
			title: 'fhp.reports.details.edi.invrpt',
			folder: path + '/documents/INVRPT',
			modName: 'document.details.invrpt',
			highlightMenuId: 'documents',
			permissions: ['READ_INVRPT']
		},
		'document.create.package': {
			title: 'document.create.package',
			modName: 'document.create.package',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE',
			permissions: ['CREATE_DP_CONTAINER'],
			permissionsEdit: ['EDIT_DP_CONTAINER']
		},
		'document.details.package': {
			title: 'document.details.package',
			modName: 'document.details.package',
			highlightMenuId: 'documents',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE',
			permissions: ['CREATE_DP_CONTAINER'],
			permissionsEdit: ['EDIT_DP_CONTAINER']
		},
		'package.document.form': {
			title: 'package.document.form',
			modName: 'package.document.form',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE',
			permissions: ['CREATE_DP_CONTAINER', 'EDIT_DP_CONTAINER'],
			permissionsEdit: ['EDIT_DP_CONTAINER']
		},
		'document.create.package.ne': {
			title: 'document.create.package.ne',
			modName: 'document.create.package.ne',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE_NE',
			permissions: ['CREATE_DP_CONTAINER'],
			permissionsEdit: ['EDIT_DP_CONTAINER']
		},
		'document.details.package.ne': {
			title: 'document.details.package.ne',
			modName: 'document.details.package.ne',
			highlightMenuId: 'documents',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE_NE',
			permissions: ['CREATE_DP_CONTAINER'],
			permissionsEdit: ['EDIT_DP_CONTAINER']
		},
		'package.ne.document.form': {
			title: 'package.document.form',
			modName: 'package.ne.document.form',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE_NE',
			permissions: ['CREATE_DP_CONTAINER', 'EDIT_DP_CONTAINER'],
			permissionsEdit: ['EDIT_DP_CONTAINER']
		},
		'document.create.package.dr': {
			title: 'document.create.package.dr',
			modName: 'document.create.package.dr',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE_DR',
			permissions: ['CREATE_DP_CONTAINER_DR'],
			permissionsEdit: ['EDIT_DP_CONTAINER_DR']
		},
		'document.details.package.dr': {
			title: 'document.details.package.dr',
			modName: 'document.details.package.dr',
			highlightMenuId: 'documents',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE_DR',
			permissions: ['CREATE_DP_CONTAINER_DR'],
			permissionsEdit: ['EDIT_DP_CONTAINER_DR']
		},
		'package.dr.document.form': {
			title: 'package.document.form',
			modName: 'package.dr.document.form',
			folder: path + '/documents/AGREEMENT_PACKAGES/EDI_AGREEMENT_PACKAGE_DR',
			permissions: ['CREATE_DP_CONTAINER_DR', 'UPDATE_DP_CONTAINER_DR'],
			permissionsEdit: ['UPDATE_DP_CONTAINER_DR']
		},
		'document.create.on.service.container': {
			title: 'document.create.on.service.container',
			modName: 'document.create.on.service.container',
			folder: path + '/documents/ON_SERVICE_CONTAINER',
			permissions: ['CREATE_ON_SERVICE_CONTAINER'],
			permissionsEdit: ['EDIT_ON_SERVICE_CONTAINER']
		},
		'document.details.on.service.container': {
			title: 'document.details.on.service.container',
			modName: 'document.details.on.service.container',
			highlightMenuId: 'documents',
			folder: path + '/documents/ON_SERVICE_CONTAINER',
			permissions: ['CREATE_ON_SERVICE_CONTAINER'],
			permissionsEdit: ['EDIT_ON_SERVICE_CONTAINER']
		},
		'export.group.request': {
			title: 'navigation.export.group.request',
			modName: 'export.group.request',
			folder: path + '/export',
			permissions: ['READ_EDI_GROUP_EXPORT_REQUEST']
		},
		external_link: {
			title: 'external_link',
			modName: 'external_link',
			folder: path + '/external_link'
		},
		'partin.create': {
			title: 'partin.create',
			modName: 'partin.create',
			folder: path + '/catalog/LEGACY_PARTIN',
			permissions: ['CLIENT_LEGACY_PARTIN_CREATE'],
			permissionsEdit: ['CLIENT_LEGACY_PARTIN_EDIT']
		},
		'partin.details': {
			title: 'partin.details',
			modName: 'partin.details',
			folder: path + '/catalog/LEGACY_PARTIN',
			permissions: ['CLIENT_LEGACY_PARTIN_READ']
		},
		'document.create.dsf_client': {
			title: 'document.create.dsf_client',
			modName: 'document.create.dsf_client',
			folder: path + '/documents/DSF_CLIENT',
			permissions: ['CREATE_DSF_CLIENT'],
			permissionsEdit: ['EDIT_DSF_CLIENT']
		},
		'document.details.dsf_client': {
			title: 'document.details.dsf_client',
			modName: 'document.details.dsf_client',
			highlightMenuId: 'documents',
			folder: path + '/documents/DSF_CLIENT',
			permissions: ['READ_DSF_CLIENT']
		},
		'document.details.ovz_from_x5': {
			title: 'document.details.ovz_from_x5',
			modName: 'document.details.ovz_from_x5',
			highlightMenuId: 'documents',
			folder: path + '/documents/OVZ_FROM_X5',
			permissions: ['READ_OVZ_FROM_X5']
		},
		'powers.of.attorney.list': {
			title: 'powers.of.attorney.list',
			modName: 'powers.of.attorney.list',
			folder: path,
			permissions: ['READ_POWER_OF_ATTORNEY']
		},
		'power.of.attorney.create': {
			title: 'power.of.attorney.create',
			modName: 'power.of.attorney.create',
			folder: path + '/power_of_attorney',
			permissions: ['CREATE_POWER_OF_ATTORNEY'],
			permissionsEdit: ['CREATE_POWER_OF_ATTORNEY']
		},
		'power.of.attorney.details': {
			title: 'power.of.attorney.details',
			modName: 'power.of.attorney.details',
			folder: path + '/power_of_attorney',
			permissions: ['READ_POWER_OF_ATTORNEY']
		},
		'power.of.attorney.create_003': {
			title: 'power.of.attorney.create_003',
			modName: 'power.of.attorney.create_003',
			folder: path + '/power_of_attorney',
			permissions: ['CREATE_POWER_OF_ATTORNEY'],
			permissionsEdit: ['CREATE_POWER_OF_ATTORNEY']
		},
		'power.of.attorney.details_003': {
			title: 'power.of.attorney.details_003',
			modName: 'power.of.attorney.details_003',
			folder: path + '/power_of_attorney',
			permissions: ['READ_POWER_OF_ATTORNEY']
		}
	});
})();
