import { DocumentPackageCreateModule } from '@Edi/modules/documentPackages/create.documentPackage';
import { FnsUploadDocToPackageWindow } from './uploadDocToPackageWindow';
import { FnsCreateDocForPackageWindow } from './createDocForPackageWindow';

export class FnsDocumentPackageCreateModule extends DocumentPackageCreateModule {
	uploadAndAddDoc() {
		const me = this;
		if (me.packageHeader?.id) {
			new FnsUploadDocToPackageWindow({
				packageId: me.packageHeader.id,
				receiverOrgId: me.receiverField.selectedOrg.id
			}).show();
		}
	}

	createAndAddDoc() {
		const me = this;
		if (me.packageHeader?.id) {
			new FnsCreateDocForPackageWindow({
				packageId: me.packageHeader.id,
				receiverOrgId: me.receiverField.selectedOrg.id
			}).showModal();
		}
	}
}
edi.modules['create.documentPackage'] = FnsDocumentPackageCreateModule;
