import { filterMethods } from '@Edi/modules/documentPackages/filter';

const originalGetExistingDocsTypes = filterMethods.getExistingDocsTypes;
filterMethods.getExistingDocsTypes = function () {
	const itemsTree = originalGetExistingDocsTypes();
	itemsTree.FNS = {
		order: 5,
		items: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD, edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]
	};
	itemsTree.DSF.items.push(edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE);
	return itemsTree;
};
