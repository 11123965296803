import { guidImportHandlerMethods } from '@Edi/modules/power_of_attorney/processes/importHandler';
// @ts-ignore
import { createLabel, createTextField } from '@UIkit/components/fields';
// @ts-ignore
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';

guidImportHandlerMethods.createRepresentativeInnField = function () {
	const representativeInnInput = createTextField({
		fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.inn.short'),
		regex: edi.constants.VALIDATORS.INN,
		regexText: edi.i18n.getMessage('company.inn.error.full'),
		maskRe: edi.constants.VALIDATORS.MASK_RE_INN,
		name: 'representativeInn',
		allowBlank: false,
		triggers: {
			paste: {
				extraCls: 'edi-icon edi-icon-PASTE',
				tooltip: edi.i18n.getMessage('set.self.inn'),
				handler() {
					const userData = edi.core.getUserData();
					const userInn = userData?.userData?.user?.inn;

					if (userInn) {
						representativeInnInput.setValue(userInn);
					} else {
						showInnModal();
					}
				}
			}
		}
	}) as ExtComponent;

	const representativeInnField = createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		items: [representativeInnInput]
	}) as ExtComponent;

	return { representativeInnField, representativeInnInput };
};

const showInnModal = () => {
	const createBtn = createButton({
		text: edi.i18n.getMessage('power.of.attorney.import.inn.modal.btn'),
		cls: BUTTON_CLS.primary,
		handler: () => {
			edi.core.openModule('user.profile');
			guidImportHandlerMethods.importModal?.close();
			modal.close();
		}
	});

	const infoLabel = createLabel({
		text: edi.i18n.getMessage('power.of.attorney.import.inn.modal.info.label')
	});

	const modal = createModalPanel({
		title: edi.i18n.getMessage('power.of.attorney.import.inn.modal.title'),
		width: MODAL_SIZE.widthSmall,
		bodyPadding: '0 24',
		items: [infoLabel],
		buttonsBefore: [createBtn],
		closeButtonOptions: {
			hidden: true
		}
	});

	modal.show();
	return modal;
};
