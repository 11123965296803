/**
 * Fns converters methods
 * @author Anatoly Deryshev
 */
edi.converters.registerConverters({
	/**
	 * Convert UPD products for editable tree grid
	 * @param       lines        FNS products array
	 * @returns     {Array}      Converted products array
	 */
	convertUPDProductsToUKDGridValues: function (lines, versionId, bpName) {
		var products = [];
		if ('string' != typeof lines) {
			for (var i = 0; i < lines.length; i++) {
				var line = lines[i],
					convLine,
					result,
					InfPolStr = [],
					infoTracking = [],
					j,
					k,
					textInfos = edi.utils.getObjectProperty(line, 'textInfos', true),
					infoTrackingLine = edi.utils.getObjectProperty(line, 'additionalProductInfo.infoTracking', true),
					identifyProductToolNumbersLine = edi.utils.getObjectProperty(
						line,
						'additionalProductInfo.identifyProductToolNumbers',
						true
					),
					ndsCost =
						line.taxSum && line.taxSum.withoutTax
							? edi.constants.taxRates.taxNoVat
							: (line.taxSum && line.taxSum.taxSum) || 0;

				for (j = 0; j < textInfos.length; j++) {
					InfPolStr.push({
						Identif: textInfos[j].identifier,
						Znachen: textInfos[j].value
					});
				}

				infoTrackingLine = infoTrackingLine.map(function (item) {
					return {
						trackingProdQuanDiff: {
							trackingProdQuanBefore: parseFloat(Number(item.trackingProdQuan).toFixed(11)),
							trackingProdQuanAfter: parseFloat(Number(item.trackingProdQuan).toFixed(11))
						},
						trackingProdNum: item.trackingProdNum,
						trackingUnitMes: item.trackingUnitMes,
						trackingAdd: item.trackingAdd
					};
				});

				convLine = {
					Count: line.count || 0,
					Cost: line.cost ? edi.utils.roundTo(line.cost, 2) : 0,
					TaxRate: line.tax || 0,
					ExciseCost:
						line.excise && line.excise.withoutExcise
							? 'без акциза'
							: (line.excise && line.excise.sumExcise) || 0,
					NDSCost:
						ndsCost && ndsCost != edi.constants.taxRates.taxNoVat ? edi.utils.roundTo(ndsCost, 2) : ndsCost,
					ProductOkei: line.productOkei,
					CostWithoutNDS: line.costWithoutNDS ? edi.utils.roundTo(line.costWithoutNDS, 2) : 0,
					CostWithNDS:
						ndsCost && ndsCost != edi.constants.taxRates.taxNoVat
							? edi.utils.roundTo(line.costWithoutNDS + ndsCost, 2)
							: edi.utils.roundTo(line.costWithoutNDS, 2)
				};

				result = Ext.clone(convLine);
				result.lineNum = i + 1;
				result['ProductName'] = line.productName;
				result['identifyProductToolNumbers'] =
					edi.utils.getObjectProperty(line, 'additionalProductInfo.identifyProductToolNumbers') || [];
				result['InfPolStr'] = InfPolStr;

				result.OriginalData = Ext.clone(convLine);
				result.OriginalData.State = 'OriginalData';
				result.OriginalData.InfPolStr = InfPolStr;

				result.NewData = Ext.clone(convLine);
				result.NewData.State = 'NewData';
				result.NewData.InfPolStr = InfPolStr;

				if (versionId === '5.01-N') {
					result['lineNumInvoice'] = line.hasOwnProperty('lineNumInvoice')
						? line.lineNumInvoice
						: line.lineNumber;
					result['lineNumber'] = result['lineNumInvoice'];
					result['productDesc'] = edi.utils.getObjectProperty(line, 'additionalProductInfo.productDesc');
					result['productSort'] = edi.utils.getObjectProperty(line, 'additionalProductInfo.productSort');
					result['productReference'] = edi.utils.getObjectProperty(
						line,
						'additionalProductInfo.productReference'
					);
					result['productCode'] = edi.utils.getObjectProperty(line, 'additionalProductInfo.productCode');
					result['productTypeCode'] = edi.utils.getObjectProperty(
						line,
						'additionalProductInfo.productTypeCode'
					);
					result['infoTracking'] = infoTrackingLine;
					result.OriginalData.infoTracking = infoTrackingLine;
					result.NewData.infoTracking = infoTrackingLine;
					result['identifyProductToolNumbersBefore'] = identifyProductToolNumbersLine;
					result['identifyProductToolNumbersAfter'] = identifyProductToolNumbersLine;
				} else {
					result['LineNumber'] = line.lineNumber;
					result['corAccDebet'] = edi.utils.getObjectProperty(line, 'additionalProductInfo.corAccDebet');
					result['corAccCredit'] = edi.utils.getObjectProperty(line, 'additionalProductInfo.corAccCredit');
				}

				var increaseValues = {
					CostWithNDS: 0,
					CostWithoutNDS: 0,
					NDSCost: 0,
					State: 'Increase'
				};

				result.Increase = edi.models.createInstance('FNS_UKD_LINE', Ext.clone(increaseValues)).getData();
				increaseValues.State = 'Decrease';
				result.Decrease = edi.models.createInstance('FNS_UKD_LINE', Ext.clone(increaseValues)).getData();

				products.push(result);
			}
		}

		return {
			products: products,
			totalIncrease: edi.models
				.createInstance('FNS_UKD_SUMMARY', {
					State: 'Increase'
				})
				.getData(),
			totalDecrease: edi.models
				.createInstance('FNS_UKD_SUMMARY', {
					State: 'Decrease'
				})
				.getData()
		};
	},
	/**
	 * Convert UKD products from grid to corrected_upd format
	 * @param       values        FNS products array
	 * @returns     {Array}      Converted products array
	 */
	convertUKDGridValuesToUKDProduct: function (values, versionId, bpName) {
		var products = [],
			product,
			i,
			totalIncrease = values.TotalIncrease,
			totalDecrease = values.TotalDecrease,
			types;

		var getMeasureUnitName = function (unitId) {
			const okeiStore = edi.stores.initLegacyOkeiStore();
			const index = okeiStore.findExact('id', unitId);
			return index !== -1 ? okeiStore.getAt(index).get('name') : unitId;
		};

		var hasNoVat = true;

		for (i = 0; i < values.products.length; i++) {
			product = values.products[i];
			var vatBefore,
				vatAfter,
				result,
				before = edi.utils.getObjectProperty(product, 'OriginalData'),
				after = edi.utils.getObjectProperty(product, 'NewData'),
				increase = edi.utils.getObjectProperty(product, 'Increase'),
				decrease = edi.utils.getObjectProperty(product, 'Decrease'),
				excBefore = edi.utils.getObjectProperty(before, 'ExciseCost'),
				excAfter = edi.utils.getObjectProperty(after, 'ExciseCost'),
				exciseInc = edi.utils.getObjectProperty(increase, 'ExciseCost'),
				exciseDec = edi.utils.getObjectProperty(decrease, 'ExciseCost'),
				taxRateBefore = edi.utils.getObjectProperty(before, 'TaxRate'),
				taxRateAfter = edi.utils.getObjectProperty(after, 'TaxRate'),
				taxInc = edi.utils.getObjectProperty(increase, 'NDSCost'),
				taxDec = edi.utils.getObjectProperty(decrease, 'NDSCost');

			types = edi.methods.taxRates.getNumberTypeRates('RUS', true);

			vatBefore = edi.utils.getObjectProperty(before, 'TaxRate');
			if (types.includes(vatBefore)) {
				vatBefore += '%';
			}

			vatAfter = edi.utils.getObjectProperty(after, 'TaxRate');
			if (types.includes(vatAfter)) {
				vatAfter += '%';
			}

			result = {
				textInfos: edi.converters.convertTextInfo(
					edi.utils.getObjectProperty(product, 'InfPolStr', true),
					'toUKD'
				),
				costWithoutVat: {
					costBeforeEdit: edi.utils.getObjectProperty(before, 'CostWithoutNDS'),
					costAfterEdit: edi.utils.getObjectProperty(after, 'CostWithoutNDS'),
					costIncreased: edi.utils.getObjectProperty(increase, 'CostWithoutNDS'),
					costReduced: edi.utils.getObjectProperty(decrease, 'CostWithoutNDS')
				},
				costWithVat: {
					costBeforeEdit: edi.utils.getObjectProperty(before, 'CostWithNDS'),
					costAfterEdit: edi.utils.getObjectProperty(after, 'CostWithNDS'),
					costIncreased: edi.utils.getObjectProperty(increase, 'CostWithNDS'),
					costReduced: edi.utils.getObjectProperty(decrease, 'CostWithNDS')
				},
				productName: edi.utils.getObjectProperty(product, 'ProductName'),
				productOkeiBefore: edi.utils.getObjectProperty(before, 'ProductOkei'),
				productOkeiAfter: edi.utils.getObjectProperty(after, 'ProductOkei'),
				countBefore: edi.utils.getObjectProperty(before, 'Count'),
				countAfter: edi.utils.getObjectProperty(after, 'Count'),
				costBefore: edi.utils.getObjectProperty(before, 'Cost'),
				costAfter: edi.utils.getObjectProperty(after, 'Cost'),
				taxBefore: vatBefore,
				taxAfter: vatAfter
			};

			var resultFieldsForCheck = [
				{
					checkField: 'productOkeiBefore',
					defField: 'additInfo.unitOfMeasureBefore',
					condition:
						bpName !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS &&
						bpName !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_BPV4 &&
						bpName !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_ROAMING_OUT_BPV4 &&
						bpName !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_ROAMING_IN_BPV4 &&
						bpName !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_DECREE_14 &&
						edi.utils.getObjectProperty(product, 'unitOfMeasure', true).length === 0
				}
			];

			if (versionId === '5.01-N') {
				result.lineNumInvoice = edi.utils.getObjectProperty(product, 'lineNumber');
				result['lineNumber'] = i + 1;
				result.additInfo = {
					productDesc: edi.utils.getObjectProperty(product, 'productDesc'),
					productSort: edi.utils.getObjectProperty(product, 'productSort'),
					productReference: edi.utils.getObjectProperty(product, 'productReference'),
					productCode: edi.utils.getObjectProperty(product, 'productCode'),
					productTypeCode: edi.utils.getObjectProperty(product, 'productTypeCode')
				};

				resultFieldsForCheck.forEach(function (field) {
					if (edi.utils.getObjectProperty(result, field.checkField, true).length === 0 && field.condition) {
						edi.utils.setObjectProperty(result, field.defField, '-');
					}
				});

				if (edi.utils.getObjectProperty(result, 'productOkeiBefore', true).length > 0) {
					var measureUnit = getMeasureUnitName(edi.utils.getObjectProperty(result, 'productOkeiBefore'));
					result.additInfo['unitOfMeasureBefore'] = measureUnit || '-';
				} else {
					result.additInfo['unitOfMeasureBefore'] = '-';
				}
				if (edi.utils.getObjectProperty(result, 'productOkeiAfter', true).length > 0) {
					result.additInfo['unitOfMeasureAfter'] =
						getMeasureUnitName(edi.utils.getObjectProperty(result, 'productOkeiAfter')) || '-';
				} else {
					result.additInfo['unitOfMeasureAfter'] = '-';
				}

				edi.utils.getObjectProperty(product, 'infoTracking', true).forEach(function (item) {
					var trackingProdQuanBeforeValue = Number(
							edi.utils.getObjectProperty(item, 'trackingProdQuanDiff.trackingProdQuanBefore')
						),
						trackingProdQuanAfterValue = Number(
							edi.utils.getObjectProperty(item, 'trackingProdQuanDiff.trackingProdQuanAfter')
						);

					var trackingProdQuanBefore = parseFloat(trackingProdQuanBeforeValue.toFixed(11));
					var trackingProdQuanAfter = parseFloat(trackingProdQuanAfterValue.toFixed(11));

					if (trackingProdQuanBefore > trackingProdQuanAfter) {
						var trackingProdQuanReduce = trackingProdQuanBefore - trackingProdQuanAfter;
						edi.utils.setObjectProperty(
							item,
							'trackingProdQuanDiff.trackingProdQuanReduce',
							parseFloat(trackingProdQuanReduce.toFixed(11))
						);
						edi.utils.setObjectProperty(item, 'trackingProdQuanDiff.trackingProdQuanIncrease', null);
					} else {
						var trackingProdQuanIncrease = trackingProdQuanAfter - trackingProdQuanBefore;
						edi.utils.setObjectProperty(
							item,
							'trackingProdQuanDiff.trackingProdQuanIncrease',
							parseFloat(trackingProdQuanIncrease.toFixed(11))
						);
						edi.utils.setObjectProperty(item, 'trackingProdQuanDiff.trackingProdQuanReduce', null);
					}
				});
				result.infoTracking = edi.utils.getObjectProperty(product, 'infoTracking', true);
				result.identifyProductToolNumbersBefore = edi.utils.getObjectProperty(
					product,
					'identifyProductToolNumbersBefore',
					true
				);
				result.identifyProductToolNumbersAfter = edi.utils.getObjectProperty(
					product,
					'identifyProductToolNumbersAfter',
					true
				);
			} else {
				result.lineNum = edi.utils.getObjectProperty(product, 'lineNum');
				result.additInfo = {
					korAccDeb: edi.utils.getObjectProperty(product, 'corAccDebet'),
					korAccKred: edi.utils.getObjectProperty(product, 'corAccCredit')
				};
			}

			var noExcBefore = 'без акциза' == excBefore || 0 == excBefore,
				noExcAfter = 'без акциза' == excAfter || 0 == excAfter,
				noTaxBefore = edi.constants.taxRates.taxNoVat == taxRateBefore,
				noTaxAfter = edi.constants.taxRates.taxNoVat == taxRateAfter,
				noExciseDiff = exciseInc == 0 && exciseDec == 0,
				noTaxDiff = taxInc == 0 && taxDec == 0;

			hasNoVat = hasNoVat && noTaxAfter;

			result['exciseBefore'] = {
				sumExcise: noExcBefore ? null : excBefore,
				withoutExcise: noExcBefore ? 'без акциза' : null
			};
			result['exciseAfter'] = {
				sumExcise: noExcAfter ? null : excAfter,
				withoutExcise: noExcAfter ? 'без акциза' : null
			};
			result['amountVatBefore'] = {
				amountVat: noTaxBefore ? null : edi.utils.getObjectProperty(before, 'NDSCost'),
				withoutVat: noTaxBefore ? edi.constants.taxRates.taxNoVat : null
			};
			result['amountVatAfter'] = {
				amountVat: noTaxAfter ? null : edi.utils.getObjectProperty(after, 'NDSCost'),
				withoutVat: noTaxAfter ? edi.constants.taxRates.taxNoVat : null
			};

			// This object must contain only 1 value at the same time. If excise difference = 0, set "DecreaseSum : 0"
			result['exciseDiff'] = {
				amountIncrease: exciseInc == 0 ? null : exciseInc,
				amountReduce: exciseDec != 0 ? exciseDec : noExciseDiff ? 0 : null
			};

			// The same as with ExciseDifference
			result['amountVatDiff'] = {
				amountIncrease: taxInc || null,
				amountReduce: taxDec ? taxDec : noTaxDiff ? 0 : null
			};

			if (product[edi.constants.INTERNAL_LINE_ID]) {
				result[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			products.push(result);
		}

		var totalResult;

		if (versionId === '5.01-N') {
			totalResult = {
				productInfo: products,
				totalIncrease:
					Object.keys(totalIncrease).length > 0
						? {
								amountVat: {
									amountVat: hasNoVat
										? null
										: edi.utils.getObjectProperty(totalIncrease, 'MustPaid')
										? edi.utils.getObjectProperty(totalIncrease, 'MustPaid')
										: '0.00',
									withoutVat: hasNoVat ? edi.constants.taxRates.taxNoVat : null
								},
								totalAmountWithoutVat: edi.utils.getObjectProperty(totalIncrease, 'TotalWithoutNDS'),
								totalAmountWithVat: edi.utils.getObjectProperty(totalIncrease, 'TotalWithNDS')
						  }
						: null,
				totalReduce:
					Object.keys(totalDecrease).length > 0
						? {
								amountVatTotalReduce: {
									amountVat: hasNoVat
										? null
										: edi.utils.getObjectProperty(totalDecrease, 'MustPaid') || '0.00',
									withoutVat: hasNoVat ? edi.constants.taxRates.taxNoVat : null
								},
								totalAmountWithoutVatTotalReduce: edi.utils.getObjectProperty(
									totalDecrease,
									'TotalWithoutNDS'
								),
								totalAmountWithVatTotalReduce: edi.utils.getObjectProperty(
									totalDecrease,
									'TotalWithNDS'
								)
						  }
						: null
			};
		} else {
			totalResult = {
				productInfo: products,
				totalIncrease:
					Object.keys(totalIncrease).length > 0
						? {
								amountVat: {
									amountVat: edi.utils.getObjectProperty(totalIncrease, 'MustPaid')
										? edi.utils.getObjectProperty(totalIncrease, 'MustPaid')
										: null,
									withoutVat: edi.utils.getObjectProperty(totalIncrease, 'MustPaid')
										? null
										: edi.constants.taxRates.taxNoVat
								},
								totalAmountWithoutVat: edi.utils.getObjectProperty(totalIncrease, 'TotalWithoutNDS'),
								totalAmountWithVat: edi.utils.getObjectProperty(totalIncrease, 'TotalWithNDS')
						  }
						: null,
				totalReduce:
					Object.keys(totalDecrease).length > 0
						? {
								amountVatTotalReduce: {
									amountVat: edi.utils.getObjectProperty(totalDecrease, 'MustPaid') || null,
									withoutVat: edi.utils.getObjectProperty(totalDecrease, 'MustPaid')
										? null
										: edi.constants.taxRates.taxNoVat
								},
								totalAmountWithoutVatTotalReduce: edi.utils.getObjectProperty(
									totalDecrease,
									'TotalWithoutNDS'
								),
								totalAmountWithVatTotalReduce: edi.utils.getObjectProperty(
									totalDecrease,
									'TotalWithNDS'
								)
						  }
						: null
			};
		}

		return totalResult;
	},
	/**
	 * Convert UKD lines for grid
	 * @param       src            Products and total values
	 * @param       products       already extracted products
	 * @returns     {Object}
	 */
	convertUKDProductToUKDGridValues: function (src, products, versionId) {
		products = products || edi.utils.getObjectProperty(src, 'productInfo');
		var lines = [],
			i,
			totalIncrease,
			totalDecrease;

		for (i = 0; i < products.length; i++) {
			var product = products[i],
				line,
				beforeValues,
				afterValues,
				taxRateBefore = edi.utils.getObjectProperty(product, 'taxBefore').replace('%', ''),
				taxRateAfter = edi.utils.getObjectProperty(product, 'taxAfter').replace('%', ''),
				costBefore = parseFloat(edi.utils.getObjectProperty(product, 'costBefore') || 0),
				costAfter = parseFloat(edi.utils.getObjectProperty(product, 'costAfter') || 0),
				countBefore = parseFloat(edi.utils.getObjectProperty(product, 'countBefore') || 0),
				countAfter = parseFloat(edi.utils.getObjectProperty(product, 'countAfter') || 0),
				costDiff = costAfter - costBefore,
				countDiff = countAfter - countBefore,
				textInfo = edi.converters.convertTextInfo(
					edi.utils.getObjectProperty(product, 'textInfos', true),
					'toInvoice'
				),
				infoTrackingData = edi.utils.getObjectProperty(product, 'infoTracking', true),
				identifyProductToolNumbersBeforeData = edi.utils.getObjectProperty(
					product,
					'identifyProductToolNumbers'
				)
					? edi.utils.getObjectProperty(product, 'identifyProductToolNumbers', true)
					: edi.utils.getObjectProperty(product, 'identifyProductToolNumbersBefore', true),
				identifyProductToolNumbersAfterData = edi.utils.getObjectProperty(
					product,
					'identifyProductToolNumbersAfter',
					true
				);

			beforeValues = {
				Cost: costBefore,
				NDSCost:
					edi.utils.getObjectProperty(product, 'amountVatBefore.withoutVat') ==
					edi.constants.taxRates.taxNoVat
						? 0
						: edi.utils.getObjectProperty(product, 'amountVatBefore.amountVat'),
				CostWithNDS: edi.utils.getObjectProperty(product, 'costWithVat.costBeforeEdit'),
				CostWithoutNDS: edi.utils.getObjectProperty(product, 'costWithoutVat.costBeforeEdit'),
				Count: countBefore,
				ExciseCost: edi.utils.getObjectProperty(product, 'exciseBefore.sumExcise') || 'без акциза',
				ProductOkei: edi.utils.getObjectProperty(product, 'productOkeiBefore'),
				State: 'OriginalData',
				TaxRate: taxRateBefore,
				InfPolStr: textInfo,
				infoTracking: infoTrackingData,
				identifyProductToolNumbersBefore: identifyProductToolNumbersBeforeData,
				identifyProductToolNumbersAfter: identifyProductToolNumbersAfterData
			};

			afterValues = {
				Cost: costAfter,
				NDSCost:
					edi.utils.getObjectProperty(product, 'amountVatAfter.withoutVat') == edi.constants.taxRates.taxNoVat
						? 0
						: edi.utils.getObjectProperty(product, 'amountVatAfter.amountVat'),
				CostWithNDS: edi.utils.getObjectProperty(product, 'costWithVat.costAfterEdit'),
				CostWithoutNDS: edi.utils.getObjectProperty(product, 'costWithoutVat.costAfterEdit'),
				Count: countAfter,
				ExciseCost: edi.utils.getObjectProperty(product, 'exciseAfter.sumExcise') || 'без акциза',
				ProductOkei: edi.utils.getObjectProperty(product, 'productOkeiAfter'),
				State: 'NewData',
				TaxRate: taxRateAfter
			};

			if (versionId === '5.01-N') {
				beforeValues.productDesc = edi.utils.getObjectProperty(product, 'additInfo.productDesc');
				beforeValues.productSort = edi.utils.getObjectProperty(product, 'additInfo.productSort');
				beforeValues.unitOfMeasureBefore = edi.utils.getObjectProperty(product, 'additInfo.productOkeiBefore');
				beforeValues.lineNumInvoice = edi.utils.getObjectProperty(product, 'lineNumInvoice');
				beforeValues.lineNumber = edi.utils.getObjectProperty(product, 'lineNumber');
				afterValues.unitOfMeasureAfter = edi.utils.getObjectProperty(product, 'additInfo.productOkeiAfter');
				afterValues.lineNumInvoice = edi.utils.getObjectProperty(product, 'lineNumInvoice');
				afterValues.lineNumber = edi.utils.getObjectProperty(product, 'lineNumber');
			} else {
				beforeValues.corAccDebet = edi.utils.getObjectProperty(product, 'additInfo.korAccDeb');
				beforeValues.corAccCredit = edi.utils.getObjectProperty(product, 'additInfo.korAccKred');
			}

			line = Ext.clone(afterValues);
			line.ProductName = edi.utils.getObjectProperty(product, 'productName');
			line.InfPolStr = textInfo;
			delete line.State;

			if (versionId === '5.01-N') {
				line.productDesc = edi.utils.getObjectProperty(product, 'additInfo.productDesc');
				line.productSort = edi.utils.getObjectProperty(product, 'additInfo.productSort');
				line.productReference = edi.utils.getObjectProperty(product, 'additInfo.productReference');
				line.productCode = edi.utils.getObjectProperty(product, 'additInfo.productCode');
				line.productTypeCode = edi.utils.getObjectProperty(product, 'additInfo.productTypeCode');
				line.infoTracking = infoTrackingData;
				line.identifyProductToolNumbersBefore = identifyProductToolNumbersBeforeData;
				line.identifyProductToolNumbersAfter = identifyProductToolNumbersAfterData;
			} else {
				line.corAccDebet = edi.utils.getObjectProperty(product, 'additInfo.korAccDeb');
				line.corAccCredit = edi.utils.getObjectProperty(product, 'additInfo.korAccKred');
			}

			line.lineNum = edi.utils.getObjectProperty(product, 'lineNum');
			if (!product.lineNumInvoice) {
				line['lineNumInvoice'] = !!product.lineNumInvoice ? product.lineNumInvoice : product.lineNumber;
			}
			line.OriginalData = Ext.clone(beforeValues);
			line.NewData = Ext.clone(afterValues);
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}

			line.Increase = {
				Cost: costDiff > 0 ? costDiff : 0,
				NDSCost: edi.utils.getObjectProperty(product, 'amountVatDiff.amountIncrease') || 0,
				CostWithNDS: edi.utils.getObjectProperty(product, 'costWithVat.costIncreased') || 0,
				CostWithoutNDS: edi.utils.getObjectProperty(product, 'costWithoutVat.costIncreased') || 0,
				Count: countDiff > 0 ? countDiff : 0,
				State: 'Increase'
			};

			line.Decrease = {
				Cost: costDiff < 0 ? Math.abs(costDiff) : 0,
				NDSCost: edi.utils.getObjectProperty(product, 'amountVatDiff.amountReduce') || 0,
				CostWithNDS: edi.utils.getObjectProperty(product, 'costWithVat.costReduced') || 0,
				CostWithoutNDS: edi.utils.getObjectProperty(product, 'costWithoutVat.costReduced') || 0,
				Count: countDiff < 0 ? Math.abs(countDiff) : 0,
				State: 'Decrease'
			};

			lines.push(line);
		}

		totalIncrease = edi.utils.getObjectProperty(src, 'totalIncrease');
		totalDecrease = edi.utils.getObjectProperty(src, 'totalReduce');

		return {
			products: lines,
			totalIncrease: {
				MustPaid: edi.utils.getObjectProperty(totalIncrease, 'amountVat.amountVat') || 0,
				TotalWithNDS: edi.utils.getObjectProperty(totalIncrease, 'totalAmountWithVat') || 0,
				TotalWithoutNDS: edi.utils.getObjectProperty(totalIncrease, 'totalAmountWithoutVat') || 0,
				State: 'Increase'
			},
			totalDecrease: {
				MustPaid: edi.utils.getObjectProperty(totalDecrease, 'amountVatTotalReduce.amountVat') || 0,
				TotalWithNDS: edi.utils.getObjectProperty(totalDecrease, 'totalAmountWithVatTotalReduce') || 0,
				TotalWithoutNDS: edi.utils.getObjectProperty(totalDecrease, 'totalAmountWithoutVatTotalReduce') || 0,
				State: 'Decrease'
			}
		};
	},
	processTorg12ProductData: function (record) {
		var name = edi.utils.safeString(record.get('name'));
		var characterProduct = edi.utils.safeString(record.get('characterProduct'));
		var articleCode = edi.utils.safeString(record.get('articleCode'));
		var code = edi.utils.safeString(record.get('code'));
		var result = [];
		if (name) {
			result.push(name);
		}
		if (characterProduct) {
			result.push(characterProduct);
		}
		if (articleCode) {
			result.push(articleCode);
		}
		if (code) {
			result.push(code);
		}
		return result.join(' / ');
	},
	/**
	 * Convert product textInfo from invocie format to UKD and vice versa
	 * @param        src          Source textInfo array
	 * @param        direction    Convertation direction
	 * @returns      {Array}      Converted array
	 */
	convertTextInfo: function (src, direction) {
		var result = [],
			i;
		for (i = 0; i < src.length; i++) {
			result.push(
				direction == 'toUKD'
					? {
							identifier: src[i].Identif,
							value: src[i].Znachen
					  }
					: {
							Identif: src[i].identifier,
							Znachen: src[i].value
					  }
			);
		}
		return result;
	}
});
