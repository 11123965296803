// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
// @ts-ignore
import { createGrid } from '@UIkit/components/grid';
import { filterMethods } from './filter';
import { DOCUMENT_PACKAGES_ADD_WINDOW_COLUMNS_NAME } from './columns';
import { createModuleFilterForm } from '@Components/moduleFilterForm';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import { createGridCheckboxSelectionModel } from '@Components/grid';
import { errorsAndWarningsMethods } from '@Edi/modules/documentGrids/warningAndErrorGrid/methods';
import { createPanel } from '@Components/panels';

export interface PackageAddExistingDocWindowProps<T> extends AnyObject {
	callback: (records: ExtRecord<T>[]) => void;
	toOrgId: number;
}

export class PackageAddExistingDocWindow<T = DocumentHeader> {
	props: PackageAddExistingDocWindowProps<T>;
	modal: ExtComponent;
	selectButton: ExtComponent;
	modalTabPanel: ExtComponent;
	modalGrids: { [gridName: string]: ExtComponent };
	modalFilters: { [gridName: string]: ExtComponent };
	modalFilterObjects: { [gridName: string]: AnyObject };
	_fireSearch: PackageAddExistingDocWindow['fireSearch'];
	modalTitle = 'documents.package.add.documents';
	selectButtonText = 'form.btn.select';

	constructor(props: PackageAddExistingDocWindowProps<T>) {
		const me = this;
		me.props = props;
		me.modalGrids = {};
		me.modalFilters = {};
		me.modalFilterObjects = {};
		me._fireSearch = me.fireSearch.bind(me);
		me.createModal();
	}

	collectSelectedRecords(): ExtRecord<T>[] {
		const me = this;
		const selectedRecords: ExtRecord<T>[] = [];
		Object.values(me.modalGrids).forEach((grid) =>
			selectedRecords.push(...(grid.getSelectionModel().selected?.items ?? []))
		);
		return selectedRecords;
	}

	getSelectButtonConfig(): AnyObject {
		const me = this;
		return {
			cls: BUTTON_CLS.primary,
			text: edi.i18n.getMessage(me.selectButtonText),
			glyph: edi.constants.ICONS.DONE,
			handler: function () {
				const selectedRecord = me.collectSelectedRecords();
				me.modal.close();
				me.props.callback(selectedRecord);
			}
		};
	}

	createSelectButton(): PackageAddExistingDocWindow['selectButton'] {
		const me = this;
		me.selectButton = createButton(me.getSelectButtonConfig()) as ExtComponent;
		return me.selectButton;
	}

	createModalButtons(): ExtComponent[] {
		const me = this;
		return [me.createSelectButton()];
	}

	fireSearch() {
		const me = this;
		Object.values(me.modalFilterObjects).forEach((filterObject) => filterObject.filter());
	}

	createModalFilter(_gridName: string): ExtComponent {
		const me = this;
		const { formItemsMap, items } = filterMethods.createAddExistingDocFormItems();
		return createModuleFilterForm(
			{
				region: 'north',
				toggleAutoSearch: false,
				autosearchEnabled: false,
				searchOnFilterCollapse: true,
				bodyPadding: 0,
				formItemsMap,
				items,
				setFormDefaults: function () {
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]?.activatePeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
				}
			},
			me._fireSearch
		) as ExtComponent;
	}

	getFilterFormByName(gridName: keyof PackageAddExistingDocWindow['modalGrids']): ExtComponent {
		const me = this;
		return me.modalFilters[gridName] || me.modalFilters['default'];
	}

	createModalGrid(gridName: string): ExtComponent {
		const me = this;

		const totalsLabel = createPanel({
			layout: {
				type: 'hbox',
				align: 'middle'
			},
			items: []
		});

		return createGrid({
			proxyConfig: {
				type: 'ajax'
			},
			storeConfig: {
				pageSize: edi.constants.ITEMS_PER_PAGE,
				model: edi.models.getModel('DOCUMENT'),
				groupField: null,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				columns: edi.columns.get(DOCUMENT_PACKAGES_ADD_WINDOW_COLUMNS_NAME),
				selModel: createGridCheckboxSelectionModel({})
			},
			viewConfig: {
				getRowClass: errorsAndWarningsMethods.getRowDocumentClass,
				emptyTextTplOptions: {
					enabled: true,
					title: edi.i18n.getMessage('grid.empty.documents'),
					buttonText: edi.i18n.getMessage('grid.empty.expand.filter'),
					buttonClickHandler: function () {
						me.getFilterFormByName(gridName).toggleHandler();
					}
				}
			},
			pagingBarConfig: {
				simpleMode: true,
				changeModeBtn: true,
				itemsPerPage: edi.constants.ITEMS_PER_PAGE,
				totalsLabel: totalsLabel
			}
		}) as ExtComponent;
	}

	createModalFilterObjects() {
		const me = this;
		Object.entries(me.modalGrids).forEach(([gridName, grid]) => {
			me.modalFilterObjects[gridName] = edi.filters.createGridFilter(
				edi.rest.services.DOCUMENTS.GET,
				me.getFilterFormByName(gridName),
				grid,
				(values: AnyObject, gridFilter: AnyObject) =>
					filterMethods.createArgsAddExistingDoc(values, gridFilter, me.props.toOrgId),
				{ gridName }
			);
		});
	}

	createModalFilters(): ExtComponent[] {
		const me = this;
		me.modalFilters = {
			default: me.createModalFilter('default')
		};
		return [me.modalFilters['default']];
	}

	createModalGrids() {
		const me = this;
		me.modalGrids = {
			notarchived: me.createModalGrid('notarchived'),
			incoming: me.createModalGrid('incoming'),
			outgoing: me.createModalGrid('outgoing')
		};
	}

	createModalTabPanel(): PackageAddExistingDocWindow['modalTabPanel'] {
		const me = this;
		me.createModalGrids();
		me.modalTabPanel = createTabPanel({
			cls: TAB_PANEL_CLS.simpleWithoutPadding,
			region: 'center'
		}) as ExtComponent;

		const getOnActivate = function (gridName: string) {
			return function () {
				const activeFilter = me.getFilterFormByName(gridName);
				Object.entries(me.modalFilters).forEach(([_name, filterForm]) => {
					filterForm.updateAddFilterMenu();
					filterForm.setVisible(filterForm === activeFilter);
				});
			};
		};

		let isFirst = true;
		Object.entries(me.modalGrids).forEach(([gridName, grid]) => {
			initTabWithGrid(
				gridName,
				grid,
				isFirst,
				me.modalTabPanel,
				{ callback: getOnActivate(gridName) },
				{ badgeCount: true }
			);
			isFirst = false;
		});

		return me.modalTabPanel;
	}

	createModalItems(): ExtComponent[] {
		const me = this;
		const items = [...me.createModalFilters(), me.createModalTabPanel()];
		me.createModalFilterObjects();
		return items;
	}

	getModalConfig(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage(me.modalTitle),
			width: MODAL_SIZE.widthLarge,
			items: [
				createPanel({
					layout: 'border',
					height: 500,
					items: me.createModalItems()
				})
			],
			buttonsBefore: me.createModalButtons()
		};
	}

	createModal(): PackageAddExistingDocWindow['modal'] {
		const me = this;
		me.modal = createModalPanel(me.getModalConfig());
		return me.modal;
	}

	showModal() {
		const me = this;
		if (me.modal && !me.modal.destroyed) {
			me.modal.show();
			me.fireSearch();
		}
	}
}
