// @ts-ignore
import { createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { createTextField } from '@UIkit/components/fields';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { POA_CONST } from '../configuration';

export interface GuidImportHandlerMethods extends AnyObject {
	showModal: () => ExtComponent;
	createForm: () => ExtComponent;
	createNumberField: () => { poaNumberField: ExtComponent; poaNumberInput: ExtComponent };
	createRepresentativeInnField: () => {
		representativeInnField: ExtComponent;
		representativeInnInput: ExtComponent;
	};
	sendRequest: (modal: ExtComponent, formPanel: ExtComponent) => void;
	collectPayload: (formPanel: ExtComponent) => string;
}

export const guidImportHandlerMethods: GuidImportHandlerMethods = {
	showModal: function () {
		const formPanel: ExtComponent = guidImportHandlerMethods.createForm();

		const createBtn = createButton({
			text: edi.i18n.getMessage('add.power.of.attorney.btn'),
			glyph: edi.constants.ICONS.SAVE,
			cls: BUTTON_CLS.primary,
			handler: () => guidImportHandlerMethods.sendRequest(modal, formPanel),
			bindToForm: formPanel
		});

		const modal = createModalPanel({
			title: edi.i18n.getMessage('power.of.attorney.import.by.guid'),
			width: MODAL_SIZE.widthMedium,
			items: [formPanel],
			buttonsBefore: [createBtn],
			closeButtonOptions: {
				hidden: true
			}
		});
		guidImportHandlerMethods.importModal = modal;

		formPanel.isValid();
		modal.show();
		return modal;
	},
	createForm: function () {
		const { poaNumberField } = guidImportHandlerMethods.createNumberField();
		const { representativeInnField } = guidImportHandlerMethods.createRepresentativeInnField();

		return createModalForm({
			submitEmptyText: false,
			items: [poaNumberField, representativeInnField]
		}) as ExtComponent;
	},
	createNumberField: function () {
		const poaNumberInput = createTextField({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.import.by.guid.label'),
			regex: POA_CONST.NUMBER_VALIDATOR,
			name: 'poaNumber',
			allowBlank: false,
			allowManualInput: true
		}) as ExtComponent;

		const poaNumberField = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			items: [poaNumberInput]
		}) as ExtComponent;

		return { poaNumberField, poaNumberInput };
	},
	createRepresentativeInnField: function () {
		const representativeInnInput = createTextField({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.inn.short'),
			regex: edi.constants.VALIDATORS.INN,
			regexText: edi.i18n.getMessage('company.inn.error.full'),
			maskRe: edi.constants.VALIDATORS.MASK_RE_INN,
			name: 'representativeInn',
			allowBlank: false
		}) as ExtComponent;

		const representativeInnField = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			items: [representativeInnInput]
		}) as ExtComponent;

		return { representativeInnField, representativeInnInput };
	},
	sendRequest: function (modal, formPanel) {
		edi.rest.sendRequest(
			edi.rest.services.POWER_OF_ATTORNEY.IMPORT_BY_GUID.POST,
			'POST',
			guidImportHandlerMethods.collectPayload(formPanel),
			function () {
				modal.close();
			},
			function (resp: AnyObject) {
				edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.getting.data'), null, null, {
					okBtnConfig: {
						text: edi.i18n.getMessage('btn.understand'),
						glyph: edi.constants.ICONS.DONE
					},
					title: 'poa.not.loaded'
				});
			}
		);
	},
	collectPayload: function (formPanel): string {
		const values = edi.utils.collectFormValues(formPanel.getForm());
		return Ext.encode({
			allSystems: true,
			poaNumber: values.poaNumber,
			representativeInn: values.representativeInn
		});
	}
};
