// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createModalForm } from '@UIkit/components/panels';
import { DOCUMENT_PACKAGES_DETAILS_WINDOW_COLUMNS_NAME } from './columns';
import { DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME } from './models';
import { documentPackageApi } from './entities';
import { createActionsColumnConfig, createGrid } from '@Components/grid';

export interface PackageInfoModalProps extends AnyObject {
	packageId: number;
}

Ext.namespace('edi.components');
Ext.define('edi.components.DocumentPackageInfoModal', {
	extend: 'UI.components.Modal',
	packageId: null,
	packageHeader: null,
	packageDocs: null,
	title: 'documents.package.window.title',
	width: MODAL_SIZE.widthLarge,
	minHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGE,
	maxHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,

	modifyConfig: function (externalCfg: PackageInfoModalProps): AnyObject {
		const me = this;
		me.packageId = externalCfg.packageId;
		return me.callParent([externalCfg]);
	},

	afterInit: function () {
		const me = this;
		me.callParent();
		me.on('afterrender', me.initModalContent);
	},

	setModalLoading: function () {
		const me = this;
		!me.destroyed && me.setLoading(...arguments);
	},

	initModalContent: async function () {
		const me = this;
		if (!(me.packageHeader && me.packageDocs)) {
			me.setModalLoading(true);
			const { packageData, packageItems, error } = await documentPackageApi.fetchFullPackageData(me.packageId);
			if (error) {
				edi.rest.getErrorHandler(null, () => me.close())(error);
				return;
			} else {
				me.packageHeader = packageData;
				me.packageDocs = packageItems;
			}
			me.setModalLoading(false);
		}
		me.setTitle(edi.i18n.getMessage('documents.package.window.title') + ' №' + (me.packageHeader.number ?? ''));
		me.createModalContent();
	},

	createModalContent: function (): void {
		const me = this;
		me.add(
			createModalForm({
				layout: 'fit',
				items: [me.createModalGrid()]
			})
		);
	},

	getProxyConfig: function (): AnyObject {
		return {
			type: 'memory'
		};
	},

	getStoreConfig: function (): AnyObject {
		const me = this;
		return {
			model: edi.models.getModel(DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME),
			data: me.packageDocs
		};
	},

	getActionsColumn: function (): AnyObject {
		const me = this;
		return createActionsColumnConfig({
			align: 'center',
			items: [me.createDetailsColumnAction()]
		});
	},

	canOpenDetails: function (record: ExtRecord<DocumentHeader>): boolean {
		return edi.document.actions.checkDocumentActionPermission(
			record.get('type'),
			edi.constants.DOCUMENT_ACTIONS.DETAILS
		);
	},

	openDocDetails: function (record: ExtRecord<DocumentHeader>) {
		const me = this;
		me.close();
		let recordData = record.getData();
		edi.document.actions.openDetailsModule(recordData.type, recordData);
	},

	createDetailsColumnAction(): AnyObject {
		const me = this;
		return {
			glyph: edi.constants.ICONS.DETAILS,
			iconCls: 'edi-grid-row-button-details',
			testCls: 'test-action-icon-details',
			handler: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colindex: number,
				_actionItem: unknown,
				_event: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				me.openDocDetails(record);
			},
			isActionDisabled: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colIndex: number,
				_item: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				return !me.canOpenDetails(record);
			}
		};
	},

	getGridColumns: function (): AnyObject[] {
		const me = this;
		const cols = edi.columns.get(DOCUMENT_PACKAGES_DETAILS_WINDOW_COLUMNS_NAME);
		cols.push(me.getActionsColumn());
		return cols;
	},

	getGridConfig: function (): AnyObject {
		const me = this;
		return {
			columns: me.getGridColumns(),
			listeners: {
				celldblclick: function (
					_view: ExtComponent,
					_td: unknown,
					_cellIndex: number,
					record: ExtRecord<DocumentHeader>
				) {
					if (me.canOpenDetails(record)) {
						me.openDocDetails(record);
					}
				}
			}
		};
	},

	getPagingBarConfig(): AnyObject {
		return {
			allowExport: false
		};
	},

	createModalGrid: function (): ExtComponent {
		const me = this;
		me.grid = createGrid({
			enableTextSelection: true,
			proxyConfig: me.getProxyConfig(),
			storeConfig: me.getStoreConfig(),
			gridConfig: me.getGridConfig(),
			pagingBarConfig: me.getPagingBarConfig()
		}) as ExtComponent;
		return me.grid;
	}
});

export const createPackageInfoModal = function (props: PackageInfoModalProps): ExtComponent {
	return Ext.create('edi.components.DocumentPackageInfoModal', props);
};
