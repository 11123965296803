// @ts-ignore
import { createContainer, createFieldBlockForDetails } from '@UIkit/components/panels';
// @ts-ignore
import { createLabelBlockForDetails } from '@UIkit/components/fields';

export interface SignerAndPoaProps {
	documentData: AnyObject;

	[key: string]: any;
}
export class SignerAndPoa {
	signerSecond: ExtComponent | null = null;
	signerContainer: AnyObject;

	sposPodtPolnomStoreData = [
		{
			id: 1,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.1')
		},
		{
			id: 2,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.2')
		},
		{
			id: 3,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.3')
		},
		{
			id: 4,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.4')
		},
		{
			id: 5,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.5')
		},
		{
			id: 6,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.6')
		}
	];

	props: SignerAndPoaProps;

	constructor(props: SignerAndPoaProps, overrides?: Partial<SignerAndPoa>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: SignerAndPoaProps, overrides?: Partial<SignerAndPoa>) {
		const me = this;

		Ext.merge(me, overrides);
		me.props = props;
	}

	path: string = 'dokument.podpisant';

	createSignerFields(signer: AnyObject, isSecond: boolean, signerTitle?: string | null) {
		const me = this;

		const store = edi.stores.createInlineStore(me.sposPodtPolnomStoreData, 'SIMPLE');
		const value = edi.utils.getObjectProperty(signer, 'sposPodtPolnom');
		const record = store.findRecord('id', value);
		const textSposPodtPolnom = record?.get('name');
		return createFieldBlockForDetails({
			title: signerTitle ?? edi.i18n.getMessage('company.signer') + (isSecond ? ' 2' : ''),
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						// Способ подтверждения полномочий
						{
							title: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom'),
							text: textSposPodtPolnom
						},
						//Должность
						{
							title: edi.i18n.getMessage('job.title'),
							text: edi.utils.getObjectProperty(signer, 'dolzhn'),
							isNewLine: true
						},
						//TODO: уточнить ФИО (блок всегда скрыт на UI) podpisant.fio

						// Дополнительные сведения
						{
							title: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.dopSvedPodp'),
							text: edi.utils.getObjectProperty(signer, 'dopSvedPodp'),
							isNewLine: true
						}
					]
				}),
				me.createBumPOA(signer)
			]
		});
	}

	createSignerItems() {
		const me = this;

		me.signerContainer = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 16
			}
		});
		let signer = edi.utils.getObjectProperty(me.props.documentData, 'dokument.podpisant');
		if (signer && Array.isArray(signer) && signer.length > 0) {
			let signersLength = signer.length;

			for (let i = 0; i < signersLength; i++) {
				const signerTitle =
					signersLength > 1 ? `${edi.i18n.getMessage('company.signer')}` + (i > 0 ? ' 2' : '') : null;
				let fields = me.signerContainer.add(me.createSignerFields(signer[i], !!i, signerTitle));
				if (!!i) {
					me.signerSecond = fields;
				}
			}
		}

		me.signerContainer.layout.redoLayout();
		return me.signerContainer;
	}

	//Бумажная МЧД
	createBumPOA(signer: AnyObject) {
		//по json svDoverBum массив
		const svDoverBum = edi.utils.getObjectProperty(signer, 'svDoverBum');
		let contents: AnyObject[] = [];
		if (svDoverBum && Array.isArray(svDoverBum) && svDoverBum.length > 0) {
			for (let i = 0; i < svDoverBum.length; i++) {
				contents.push({
					title: edi.i18n.getMessage('field.name.number'),
					text: edi.utils.getObjectProperty(svDoverBum[i], 'vnNomDover'),
					isNewLine: true
				});
				contents.push({
					title: edi.i18n.getMessage('column.date.of.issue'),
					text: edi.utils.getObjectProperty(svDoverBum[i], 'dataVidDover')
				});
				contents.push({
					title: edi.i18n.getMessage('power.of.attorney.principal.surname'),
					text: edi.utils.getObjectProperty(svDoverBum[i], 'fio.familiya'),
					isNewLine: true
				});
				contents.push({
					title: edi.i18n.getMessage('first.name'),
					text: edi.utils.getObjectProperty(svDoverBum[i], 'fio.imya')
				});
				contents.push({
					title: edi.i18n.getMessage('patronymic.name'),
					text: edi.utils.getObjectProperty(svDoverBum[i], 'fio.otchestvo')
				});
				contents.push({
					title: edi.i18n.getMessage('documents.fns_upd_5_02n.poa.svIdDover'),
					text: edi.utils.getObjectProperty(svDoverBum[i], 'svIdDover'),
					isNewLine: true
				});
			}
		}
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('company.signer.poa'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: contents
				})
			]
		});
	}
}

export const createSignerAndPoaForDetail = (props: SignerAndPoaProps, overrides?: Partial<SignerAndPoa>) => {
	const signerAndPoa = new SignerAndPoa(props, overrides);
	return signerAndPoa.createSignerItems() as ExtComponent;
};
