import { powerOfAttorneyAPI } from '../rest';

export interface LoadParentalPoaProps {
	doverRegNumber: string;
	principalInn: number;
	confidantInn: number;
}
export type LoadParentalPoaResult = Promise<{ success: true; poa: AnyObject } | { success: false }>;
export const loadParentalPoa = async function ({
	doverRegNumber,
	principalInn,
	confidantInn
}: LoadParentalPoaProps): Promise<{ success: true; poa: AnyObject } | { success: false }> {
	const { response, success } = await edi.rest.asyncSendRequest({
		url: edi.utils.compileURL(powerOfAttorneyAPI.CONTENT.GET, {
			doverRegNumber,
			principalInn,
			confidantInn
		})
	});

	if (success && response) {
		return { poa: response.data, success: true };
	}

	return { success: false };
};
