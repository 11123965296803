import { realtimeEvents } from '../realtime_messages';
import { onGetPoAFromFNSError, onPoAUploadWithError } from '@Edi/modules/power_of_attorney/methods';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { showConfirm } from '@UIkit/components/modal/MessageBox';

Ext.Object.merge(edi.realtime.messages, {
	eventByMsgType: {
		//данные пользователя изменились через МД
		//поэтому очень желательно перезагрузить страницу
		USER_DATA_MODIFICATION: function (msgData) {
			showConfirm({
				title: 'warning.user.data.changed.title',
				msgText: 'warning.user.data.changed.text',
				width: MODAL_SIZE.widthMedium,
				yesBtnConfig: {
					text: edi.i18n.getMessage('warning.user.data.changed.refresh'),
					handler: function () {
						window.location.reload();
					}
				},
				noBtnConfig: {
					text: edi.i18n.getMessage('uikit.action.close')
				}
			});
		},
		//событие загрузки документа
		EDI_WEB_UPLOAD_COMPLETED: function (msgData) {
			edi.events.uploadDocument.fireEvent('change', msgData);
			if (msgData?.data?.content?.type === edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY) {
				edi.events.uploadDocument.fireEvent('poa_change', msgData);
				edi.events.powerOfAttorney.fireEvent('change');
			}
		},
		EDI_WEB_UPLOAD_PROCESSED_WITH_ERROR: function (msgData) {
			if (msgData?.data?.content?.type === edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY) {
				onPoAUploadWithError({ realtimeMsgData: msgData });
			} else {
				edi.events.uploadDocument.fireEvent('change', msgData);
			}
		},
		//печатная форма готова
		PRINTFORM_COMPLETED: function (msgData) {
			realtimeEvents.fireEvent('printFormComplete', msgData);
		},
		GROUP_PRINTFORM_COMPLETED: function (msgData) {
			realtimeEvents.fireEvent('groupPrintFormComplete', msgData);
		},
		GET_POA_RESPONSE: function (msgData) {
			if (msgData?.data?.content?.Success) {
				edi.core.showInfo(
					edi.i18n.getMessage('power.of.attorney.imported.successfully', {
						poaNumber: msgData.data.content.GUID
					})
				);
				edi.events.uploadDocument.fireEvent('poa_change', msgData);
				edi.events.powerOfAttorney.fireEvent('change');
			} else {
				onGetPoAFromFNSError({ realtimeMsgData: msgData });
			}
		},
		//событие когда каталог загружен
		CATALOG_UPLOAD_COMPLETED: function (msgData) {
			if (msgData?.data?.content?.Success) {
				let text = edi.i18n.getMessage('documents.upload.catalog.success');
				if (msgData?.data?.content?.type) {
					text = text + ': ' + edi.i18n.getMessage('documents.doctype.' + msgData.data.content.type);
				}
				if (msgData?.data?.content?.id) {
					text = text + ' №' + msgData?.data?.content?.id;
				}
				if (msgData?.data?.content?.fileName) {
					text =
						text +
						'</br>' +
						edi.i18n.getMessage('file.details.title') +
						': ' +
						msgData.data.content.fileName;
				}
				edi.core.showInfo(text);
			} else {
				let text = edi.i18n.getMessage('documents.upload.catalog.error');
				if (msgData?.data?.content?.type) {
					text = text + ': ' + edi.i18n.getMessage('documents.doctype.' + msgData.data.content.type);
				}
				if (msgData?.data?.content?.fileName) {
					text =
						text +
						'</br>' +
						edi.i18n.getMessage('file.details.title') +
						': ' +
						msgData.data.content.fileName;
				}
				if (msgData?.data?.content?.Error) {
					text += '</br><span style="color: #ff0000">' + msgData.data.content.Error + '</span>';
				}
				edi.core.showError(text);
			}
			edi.events.catalog.fireEvent('change');
		},
		SIGN_VERIFICATION_SUCCESS: function (msgData) {
			edi.methods.documents.updateAsyncSignStatus(msgData);
		},
		SIGN_VERIFICATION_FAILED: function (msgData) {
			edi.methods.documents.updateAsyncSignStatus(msgData);
		},
		PACKET_STATE_CHANGED: function (msgData) {
			edi.events.documentPackage.fireEvent('update', { packageId: msgData?.data?.meta?.mainObjId });
		}
	}
});
