// @ts-ignore
import { createGrid } from '@UIkit/components/grid';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createContainer, createFieldBlock, createModalForm } from '@UIkit/components/panels';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createLabelWithIcon } from '@Components/LabelWithIcon/LabelWithIcon';
// @ts-ignore
import { createCombo, createDateField, createHiddenField, createTextField } from '@UIkit/components/fields';
import { Podpisant } from '../definitions/podpisant';

export interface SignerAndPoaProps {
	setModuleLoading?: (load: boolean | AnyObject | string) => void;
	signers?: Partial<Podpisant>[];
	isSCHFSelected: boolean;
	isSCHFDOPSelected: boolean;
	isDOPSelected: boolean;

	[key: string]: any;
}
export class SignerAndPoa {
	bumPOAFieldBlock: ExtComponent;
	signerSecond: ExtComponent | null = null;
	clearSecondSignerBtn: ExtComponent | null;
	signerContainer: ExtComponent;

	sposPodtPolnomStoreData = [
		{
			id: 1,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.1')
		},
		{
			id: 2,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.2')
		},
		{
			id: 3,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.3')
		},
		{
			id: 4,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.4')
		},
		{
			id: 5,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.5')
		},
		{
			id: 6,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom.6')
		}
	];

	props: SignerAndPoaProps;

	constructor(props: SignerAndPoaProps, overrides?: Partial<SignerAndPoa>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: SignerAndPoaProps, overrides?: Partial<SignerAndPoa>) {
		const me = this;

		Ext.merge(me, overrides);
		me.props = props;
	}

	path: string = 'podpisant';

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		return [me.path, ...name].filter(Boolean).join('.');
	}

	setLoading(load: boolean | AnyObject | string) {
		const me = this;
		if (typeof me.props.setModuleLoading === 'function') me.props.setModuleLoading(load);
	}

	clearSecondSigner() {
		const me = this;

		const filds = [
			'podpisant.individualPerson.name.firstName',
			'podpisant.individualPerson.name.lastName',
			'podpisant.individualPerson.name.middleName'
		];

		if (me.signerSecond && me.signerSecond.length) {
			for (let j = 0; j < filds.length; j++) {
				for (let i = 0, len = me.signerSecond.length; i < len; i++) {
					if (!me.signerSecond || me.signerSecond[i]) {
						let elm: ExtComponent | null = me.signerSecond[i].down('[name=' + filds[j] + ']');
						if (elm) {
							elm.setValue('');
						}
					}
				}
			}
		}
		me.clearSecondSignerBtn?.setDisabled(true);
	}

	showModalListOfSecondSigner() {
		const me = this;
		const rowClick = function (record: AnyObject) {
			me.setLoading(true);
			const data = record.getData(),
				mapper: { [key: string]: string } = {
					'subjectName.position': 'podpisant.legalEntity.position',
					'subjectName.firstname': 'podpisant.individualPerson.name.firstName',
					'subjectName.lastname': 'podpisant.individualPerson.name.lastName',
					'subjectName.middlename': 'podpisant.individualPerson.name.middleName'
				};

			if (data && data.user && data.user.id) {
				me.clearSecondSignerBtn?.setDisabled(false);
				edi.rest.sendRequest(
					edi.rest.services.SIGNER.SIGNERS.GET,
					'GET',
					{
						direction: edi.constants.DIRECTIONS.OUTBOX,
						userId: data.user.id,
						docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
					},
					function (headerData: AnyObject) {
						let prop: string, elm: ExtComponent | null;
						if (me.signerSecond && me.signerSecond.length) {
							for (prop in mapper) {
								for (let i = 0; !me.signerSecond || i < me.signerSecond.length; i++) {
									if (mapper.hasOwnProperty(prop)) {
										if (!me.signerSecond || me.signerSecond[i]) {
											if (me.signerSecond) {
												elm = me.signerSecond[i].down('[name=' + mapper[prop] + ']');
												if (elm) {
													elm.setValue(edi.utils.getObjectProperty(data, prop));
													break;
												}
											}
										}
									}
								}
							}
						}
					},
					undefined
				);
			}
			me.setLoading(false);
			modal.close();
		};

		let grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.utils.formatString(edi.rest.services.USER.CERTIFICATE.CERTIFICATES.ACTIVE.GET, {
					type: 'EDI_FNS_UPD'
				}),
				extraParams: {
					excludeCurrentUser: true
				}
			},
			storeConfig: {
				model: edi.models.getModel('USER_CERT'),
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'id',
					direction: 'ASC'
				}
			},
			gridConfig: {
				cls: 'modal-grid',
				region: 'center',
				columns: edi.columns.get('user_cert'),
				disablePaging: false,
				disableSelection: false,
				enableTextSelection: true,
				checkboxes: false,
				listeners: {
					select: function (comp: ExtComponent, record: AnyObject) {
						rowClick(record);
					}
				},
				dockedItems: undefined
			}
		});

		let modal = createModalPanel(
			{
				cls: 'edi-modal-documents-selector',
				title: edi.i18n.getMessage('btn.tooltip.secondSigner'),
				width: MODAL_SIZE.widthLarge,
				height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
				layout: 'fit',
				items: [
					createModalForm({
						items: [grid]
					})
				],
				buttonsBefore: []
			},
			false
		);
		modal.show();
	}

	checkSignerPositionMandatory() {
		const me = this;

		let fieldInput: ExtComponent = me.signerContainer?.down(`[name="${me.createFieldName('dolzhn')}"]`);
		if (fieldInput && fieldInput.isVisible()) {
			fieldInput.allowBlank = !(me.props.isDOPSelected || me.props.isSCHFDOPSelected);
		}
	}

	createSignerFields(signer: AnyObject, isSecond: boolean, signerTitle?: string | null) {
		const me = this;

		const hasPermission =
			edi.permissions.hasPermission('READ_CERTIFICATE_DOCUMENT') &&
			edi.permissions.hasPermission('CLIENT_READ_EDI_SIGNER');

		me.clearSecondSignerBtn = hasPermission
			? (createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.reset'),
					text: '',
					disabled: true,
					glyph: edi.constants.ICONS.HIGHLIGHT_OFF,
					handler: me.clearSecondSigner
			  }) as ExtComponent)
			: null;

		const topText = isSecond
			? createLabelWithIcon({
					padding: 16,
					text: edi.i18n.getMessage('documents.fns_upd.signer.beforeSecondSigner'),
					glyph: edi.constants.ICONS.REJECT
			  })
			: null;

		const getSecondSignerBtn = isSecond
			? createButton({
					width: 200,
					margin: '8 0 16 0',
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.secondSigner'),
					text: edi.i18n.getMessage('form.btn.select.product'),
					disabled: false,
					glyph: edi.constants.ICONS.SEARCH,
					handler: me.showModalListOfSecondSigner
			  })
			: null;

		// Способ подтверждения полномочий
		const sposPodtPolnomField = createCombo({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom'),
			name: me.createFieldName('sposPodtPolnom'),
			allowBlank: false,
			displayField: 'name',
			valueField: 'id',
			store: edi.stores.createInlineStore(me.sposPodtPolnomStoreData, 'SIMPLE'),
			value: edi.utils.getObjectProperty(signer, 'sposPodtPolnom'),
			listeners: {
				change: me.setVisibleBumPOA.bind(me),
				afterrender: me.setVisibleBumPOA.bind(me)
			}
		});

		//Должность
		const signerPosition = createTextField({
			fieldLabel: edi.i18n.getMessage('job.title'),
			maxLength: 255,
			name: me.createFieldName('dolzhn'),
			value: edi.utils.getObjectProperty(signer, 'dolzhn')
		});

		// ФИО (блок всегда скрыт на UI)
		const signerSection = createContainer({
			items: [
				createHiddenField({
					value: edi.utils.getObjectProperty(signer, 'fio.familiya'),
					name: me.createFieldName(`fio.familiya`)
				}),
				createHiddenField({
					value: edi.utils.getObjectProperty(signer, 'fio.imya'),
					name: me.createFieldName(`fio.imya`)
				}),
				createHiddenField({
					value: edi.utils.getObjectProperty(signer, 'fio.otchestvo'),
					name: me.createFieldName(`fio.otchestvo`)
				})
			]
		});

		// Дополнительные сведения
		const dopSvedPodpField = createTextField({
			name: me.createFieldName('dopSvedPodp'),
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.dopSvedPodp'),
			maxLength: 255,
			value: edi.utils.getObjectProperty(signer, 'dopSvedPodp')
		});

		return [
			topText,
			createFieldBlock({
				title: signerTitle ?? edi.i18n.getMessage('company.signer') + (isSecond ? ' 2' : ''),
				cls: 'edi-form-maxwidth',
				layout: {
					type: 'grid',
					gap: 16,
					area: [6, 3, 6]
				},
				items: [
					getSecondSignerBtn,
					sposPodtPolnomField,
					signerPosition,
					dopSvedPodpField,
					signerSection,
					me.createBumPOA(signer)
				]
			})
		];
	}

	createSignerItems() {
		const me = this;

		me.signerContainer = createContainer({
			checkSignerPositionMandatory: me.checkSignerPositionMandatory.bind(me),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 16
			}
		}) as ExtComponent;

		let signer = me.props.signers;
		if (signer && Array.isArray(signer) && signer.length > 0) {
			let signersLength = signer.length;

			for (let i = 0; i < signersLength; i++) {
				const signerTitle =
					signersLength > 1 ? `${edi.i18n.getMessage('company.signer')}` + (i > 0 ? ' 2' : '') : null;
				let fields = me.signerContainer.add(me.createSignerFields(signer[i], !!i, signerTitle));
				if (!!i) {
					me.signerSecond = fields;
				}
			}
		} else {
			me.signerContainer.add(me.createSignerFields({}, false));
		}

		me.signerContainer.layout.redoLayout();
		return me.signerContainer;
	}

	setVisibleBumPOA(comp: ExtComponent) {
		const me = this;
		const value: string = comp.getValue();
		const bumPoaSposPodtPolnomArray = ['5'];
		const fields = me.bumPOAFieldBlock.items.items;
		const isVisible = bumPoaSposPodtPolnomArray.includes(value);
		me.bumPOAFieldBlock.setVisible(isVisible);
		fields.forEach((field: ExtComponent) => {
			field.setVisible(isVisible);
			field.setDisabled(!isVisible);
			if (Array.isArray(field?.items?.items)) {
				field.items.items.forEach((item: ExtComponent) => {
					item.setVisible(isVisible);
					item.setDisabled(!isVisible);
				});
			}
		});
	}

	//Бумажная МЧД
	createBumPOA(signer: AnyObject) {
		const me = this;
		const svDoverBum = signer.svDoverBum?.[0];
		const vnNomDoverField = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			name: me.createFieldName('svDoverBum.vnNomDover'),
			maxLength: 50,
			allowBlank: false,
			value: edi.utils.getObjectProperty(svDoverBum, 'vnNomDover')
		});

		const dataVidDoverField = createDateField({
			fieldLabel: edi.i18n.getMessage('column.date.of.issue'),
			name: me.createFieldName('svDoverBum.dataVidDover'),
			allowBlank: false,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			value: edi.utils.getObjectProperty(svDoverBum, 'dataVidDover')
		});

		const poaFIOSection = createContainer({
			layout: {
				type: 'grid',
				gap: 16,
				area: [[3, 3, 3]]
			},
			items: [
				createTextField({
					allowBlank: false,
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.surname'),
					name: me.createFieldName('svDoverBum.fio.familiya'),
					value: edi.utils.getObjectProperty(svDoverBum, 'fio.familiya')
				}),
				createTextField({
					allowBlank: false,
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('first.name'),
					name: me.createFieldName('svDoverBum.fio.imya'),
					value: edi.utils.getObjectProperty(svDoverBum, 'fio.imya')
				}),
				createTextField({
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('patronymic.name'),
					name: me.createFieldName('svDoverBum.fio.otchestvo'),
					value: edi.utils.getObjectProperty(svDoverBum, 'fio.otchestvo')
				})
			]
		});

		const svIdDoverField = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.poa.svIdDover'),
			name: me.createFieldName('svDoverBum.svIdDover'),
			maxLength: 1000,
			value: edi.utils.getObjectProperty(svDoverBum, 'svIdDover')
		});

		return (me.bumPOAFieldBlock = createFieldBlock({
			hidden: true,
			title: edi.i18n.getMessage('company.signer.poa'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: 16,
				area: [[3, 3], 12, 6]
			},
			items: [vnNomDoverField, dataVidDoverField, poaFIOSection, svIdDoverField]
		}));
	}
}

export const createSignerAndPoa = (props: SignerAndPoaProps, overrides?: Partial<SignerAndPoa>) => {
	const signerAndPoa = new SignerAndPoa(props, overrides);
	return signerAndPoa.createSignerItems() as ExtComponent;
};
