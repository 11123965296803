import { realtimeEvents } from '../realtime_messages';
import { changePOATariffEventHandler, changeTariffEventHandler } from '@Ediweb/modules/ORG_PROFILE/tabs/TARIFFS/utils';

Ext.Object.merge(edi.realtime.messages, {
	eventByMsgType: {
		// купленный тариф доступен для использования
		TARIFF_MODIFIED: function (msgData) {
			if (msgData.success) {
				let tariffData = edi.utils.getObjectProperty(msgData, 'data.content.tariffs');
				edi.events.all_tariffs.fireEvent('change', tariffData);
			}
		},
		// изменение остатка документов в действующем тарифе
		ACTUAL_TARIFF_DOCUMENT_MODIFIED: function (msgData) {
			changeTariffEventHandler(msgData);
		},
		// изменение остатка МЧД в действующем тарифе
		ACTUAL_TARIFF_POA_MODIFIED: function (msgData) {
			changePOATariffEventHandler(msgData);
		},
		//все тарифы израсходованы
		NO_ACTUAL_TARIFF_CHANGE: function (msgData) {
			edi.constants.IS_CAN_CREATE_DOCUMENT = true;
			changeTariffEventHandler(msgData);
		},
		// обновлять грид документов, когда документ пришел, например, по интеграции и пр
		DOCUMENT_RECEIVED: function (msgData) {
			edi.events.documents.fireEvent('change', msgData);
		},
		// обновление партнеров
		PARTNER_CHANGE: function (msgData) {
			edi.events.partners.fireEvent('change', msgData);
		},
		NEW_PRODUCTS: function (msgData) {
			let products = edi.utils.getObjectProperty(msgData, 'data.content.products');

			edi.events.main_product.fireEvent('change', products, true);
		},
		REMOVE_PRODUCTS: function (msgData) {
			let products = edi.utils.getObjectProperty(msgData, 'data.content.products');

			edi.events.main_product.fireEvent('change', products, false);
		}
	}
});
