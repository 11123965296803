import {
	createFieldBlock,
	FIELD_BLOCK_CLS,
	createModalForm,
	createMaxWidthContainerForDetail,
	createFieldBlockForDetails
} from '@UIkit/components/panels';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createDetailLabel,
	createLabelBlockForDetails,
	createTextField
} from '@UIkit/components/fields';
import { createModalPanel } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createProxyConfig, createStore } from '@Components/storeComponents';
import { createOrgAutocomplete } from '@Ediweb/components/OrgAutocomplete/OrgAutocomplete';
import { showErrorToast } from '@Ediweb/core';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import createPreviewPanel from '@Components/previewPanel/previewPanel';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createFormForModule } from '@Components/panels';
import { ApprovalDocumentDetailsFns_upd_5_01nModule } from '@Ediweb/modules/APPROVAL_DOCUMENTS/DOC_TYPES/EDI_FNS_UPD/approval.document.details.fns_upd_5_01n';
import { ApprovalDocumentDetailsFns_ukd_5_01nModule } from '@Ediweb/modules/APPROVAL_DOCUMENTS/DOC_TYPES/EDI_FNS_UKD/approval.document.details.fns_ukd_5_01n';
import { applicationAPI } from '@Ediweb/modules/CONTROL_CENTER/tabs/APPLICATION_TO_FNS/rest';

const isInSigners = function (signers, userData) {
	return (signers || []).some((signer) => {
		let employee = (signer.employee ? signer.employee : signer) || {};
		return employee.userId === userData.id || employee.user?.id === userData.id;
	});
};

const getExecutor = function (executor, signers, userData) {
	if (executor.employee) {
		return executor.employee.userId === userData.id || executor.employee.user?.id === userData.id ? executor : null;
	}
	if (executor.subdivision) {
		let currentExecutor = (executor.subdivisionEmployees || []).find((employee) => {
			return employee.userId === userData.id || employee.user?.id === userData.id;
		});

		return currentExecutor && !isInSigners(signers, userData) ? executor : null;
	}
};

const getCurrentExecutor = function (executors, signers) {
	var userData = edi.core.getUserData();

	if (executors.length === 1) {
		var executor = executors[0];
		return !isInSigners(signers, userData) ? getExecutor(executor, signers, userData) : null;
	} else {
		var currentExecutor = executors.filter(function (executor) {
			return !!getExecutor(executor, signers, userData);
		})[0];

		return !isInSigners(signers, userData) ? currentExecutor : null;
	}
};

const isCurrentEmployeeExecutor = function (executors, signers) {
	var userData = edi.core.getUserData();

	if (executors.length === 1) {
		var executor = executors[0];
		const employee = getExecutor(executor, signers, userData);
		return employee?.employee?.user?.id === userData.id;
	} else {
		return false;
	}
};

/**
 * Checks approval stage and matches executor and current user with it
 * @param	{Object}	documentData
 * @param	{String}	requiredAction
 * @returns	{boolean}	TRUE = current user suites current approval stage
 */
const checkExecutorMatchesApprovalStage = function (documentData, requiredAction) {
	let currentUserId = edi.core.getUserData().id;
	let signers = (documentData.signers || []).filter((s) => {
		return s.stage?.stageNumber === documentData.currentStageNumber;
	});
	let currentExecutor = getCurrentExecutor(documentData.executors, signers);

	let executorId = null;
	if (currentExecutor?.employee) {
		executorId = currentExecutor.employee.userId || currentExecutor.employee.user?.id;
	} else if (Array.isArray(currentExecutor?.subdivisionEmployees)) {
		let employee = currentExecutor.subdivisionEmployees.find((e) => {
			return e.userId === currentUserId || e.user?.id === currentUserId;
		});
		executorId = employee?.userId || employee?.user?.id;
	}
	let executorIsOk = executorId && currentExecutor?.actions?.some((a) => a === requiredAction);

	let currentStageNum = Ext.isNumber(documentData.currentStageNumber) ? documentData.currentStageNumber : null;
	let currentStage = Ext.isNumber(currentStageNum)
		? documentData.route.stages.find((stage) => stage.stageNumber === currentStageNum)
		: null;

	let executorMatchesCurrentStage = false;
	if (executorIsOk && Array.isArray(currentStage.parties)) {
		executorMatchesCurrentStage = currentStage.parties.some((p) => {
			return (
				p.employee?.userId === executorId ||
				p.employee?.user?.id === executorId ||
				p.subdivisionEmployees?.some((e) => e.userId === executorId || e.user?.id === executorId)
			);
		});
	}

	return executorMatchesCurrentStage;
};

const getPartItem = function (values) {
	var part = values.hasOwnProperty('parties') ? values.parties : values;
	if (values.hasOwnProperty('parties')) {
		if (values.parties.length > 1) {
			return {
				employeesArray: values.parties
			};
		}
		part = values.parties[0];
	} else {
		part = values;
	}

	if (part.employee) {
		let employee = part.employee;
		let employeeValues = edi.renderers.getUserInfo(part.employee);
		return {
			id: employee.id,
			fullName: `${employeeValues.lastName || ''} ${employeeValues.firstName || ''} ${
				employeeValues.middleName || ''
			}`,
			position: employee.position,
			comment: part.comment,
			approveComment: part.approveComment,
			reasignComment: part.reasignComment,
			actions: part.actions
		};
	} else if (part.subdivision) {
		let subdivision = part.subdivision;
		return {
			id: subdivision.id,
			fullName: edi.i18n.getMessage('ew.documents.approvals.sidebar.subdivision.title', [subdivision.name]),
			allEmployeesNeeded: part.allEmployeesNeeded,
			subdivisionEmployees: part.subdivisionEmployees,
			comment: part.comment,
			approveComment: part.approveComment,
			reasignComment: part.reasignComment,
			actions: part.actions
		};
	} else {
		let employeeValues = edi.renderers.getUserInfo(part);
		return {
			id: part.id,
			fullName: `${employeeValues.lastName || ''} ${employeeValues.firstName || ''} ${
				employeeValues.middleName || ''
			}`,
			position: part.position,
			comment: part.comment,
			approveComment: part.approveComment,
			reasignComment: part.reasignComment,
			actions: part.actions
		};
	}
};

const getExecutorInSigners = function (executor, signers) {
	var isSigning = [];
	var getSigner = function (member) {
		return signers.filter(function (item) {
			if (member.hasOwnProperty('employeesArray')) {
				return member.employeesArray.find((memItem) => item.employee.id === memItem.employee.id);
			}
			return item.employee.id === member.id;
		});
	};
	if (executor.subdivisionEmployees && executor.subdivisionEmployees.length) {
		executor.subdivisionEmployees.forEach(function (subdivisionEmployee) {
			if (getSigner(subdivisionEmployee).length) {
				isSigning = getSigner(subdivisionEmployee);
			}
		});
	} else {
		isSigning = getSigner(executor);
	}

	return isSigning.length > 0 ? isSigning[0] : null;
};

const getStatusInSidebar = function (values, mainData, member) {
	let employeeMember = getPartItem(values);
	let signers = (mainData.signers || []).filter((s) => s.stage?.stageNumber === values.stageNumber);
	let executor = getExecutorInSigners(employeeMember, signers);
	member = member && member.hasOwnProperty('actions') ? member : employeeMember;
	let action = member.actions ? member.actions[0] : undefined;

	if (executor) {
		if (executor.status && executor.status !== edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED) {
			return executor.status;
		}
		if (action === 'APPROVAL_REFUSAL') {
			return executor.status === edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED
				? edi.constants.APPROVAL_DOCUMENTS.SIDEBAR_STATE.APPROVAL
				: edi.constants.APPROVAL_DOCUMENTS.SIDEBAR_STATE.APPROVAL_AWAITING;
		} else if (action === 'SIGNING_REFUSAL') {
			return executor.status === edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED
				? edi.constants.APPROVAL_DOCUMENTS.SIDEBAR_STATE.SIGN
				: edi.constants.APPROVAL_DOCUMENTS.SIDEBAR_STATE.SIGN_AWAITING;
		}
	} else {
		if (action === 'APPROVAL_REFUSAL') {
			return edi.constants.APPROVAL_DOCUMENTS.SIDEBAR_STATE.APPROVAL_AWAITING;
		}
		if (action === 'SIGNING_REFUSAL') {
			return edi.constants.APPROVAL_DOCUMENTS.SIDEBAR_STATE.SIGN_AWAITING;
		}
	}
};

const getApprovalDocTitle = function (docValues) {
	const docNumber = docValues.number || docValues?.invoice?.invoiceNumber || '';

	return `${getDocTypeName(docValues)} № ${docNumber}`;
};

const showApprovalModal = function (docValues, moduleData, saveFn, type) {
	let createdApproval; //нужен глобально что б в конце (успешного или не успешного) открыть модуль просмотра
	let employeeAutocomplete;
	let openApprovalDetails = function () {
		if (createdApproval) {
			if (createdApproval?.route?.routeType === 'DYNAMIC') {
				edi.core.openModule('approval.employees.details', createdApproval, null, null, null, {
					title: createdApproval.title
				});
			} else {
				edi.core.openModule('approval.documents.details', createdApproval, null, null, null, {
					title: createdApproval.title
				});
			}
		}
	};

	let failure = edi.document.actions.defaultFailureHandler(null, 'error.getting.data', function () {
		if (!moduleData.tab.isDestroyed) {
			moduleData.tab.setLoading(false);
		}
		openApprovalDetails();
		modal.close();
	});

	let createApproval = function (modalValues, dsfId) {
		let success = function (resp) {
			createdApproval = resp.data;

			edi.events.approval_documents.fireEvent('change', { id: createdApproval.id });
			edi.events.documents.fireEvent('change', { id: dsfId });

			edi.document.actions.getDocumentHeaderData(dsfId, function (data) {
				//если мы согласовывали документ из формы создания, то закроем ее и откроем просмотр

				let moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[data.type];
				if (data.versionId) {
					moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[[data.type, data.versionId].join('_')];
				}
				if (moduleData.modName === moduleName) {
					moduleData.isChanged = false;
					edi.modulesHandler.removeModule(moduleData);
					edi.document.actions.openDetailsModule(data.type, data);
				}
				//и сразу же отправляем согласование
				pushApproval(createdApproval.id, dsfId);
			});
		};

		let payload = {
			route: modalValues.route,
			title: modalValues.approvalTitle
		};
		if (modalValues.comment) {
			payload.comment = modalValues.comment;
		}

		let url = edi.utils.formatString(
			edi.rest.services.APPROVAL_DOCUMENTS.BASED_ON_DOCUMENT.POST,
			{ docId: dsfId },
			true
		);
		modal.setLoading(true);
		edi.rest.sendRequest(url, 'POST', Ext.encode(payload), success, failure);
	};

	let pushApproval = function (approvalId) {
		let success = function () {
			edi.events.approval_documents.fireEvent('change', { id: approvalId });
			if (!moduleData.tab.isDestroyed) {
				moduleData.tab.setLoading(false);
			}
			openApprovalDetails();
			modal.close();
		};

		let url = edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.PUSH.PUT, {
			id: approvalId
		});
		edi.rest.sendRequest(url, 'PUT', null, success, failure);
	};

	const titleText = getApprovalDocTitle(docValues);
	const docDate =
		edi.utils.formatDate(+docValues?.date, edi.constants.DATE_FORMAT.FNS) ||
		docValues?.invoice?.invoiceDate ||
		docValues?.invoiceCorrectionInfo?.invoiceCorrectionDate ||
		'';

	var document = createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('document.data'),
		items: [
			createTextField({
				value: `${titleText} ${edi.i18n.getMessage('from')} ${docDate}`,
				readOnly: true,
				name: 'document'
			})
		]
	});

	var date = createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('column.creation.date'),
		items: [
			createDateField({
				grid: {
					col: 2
				},
				name: 'date',
				value: new Date()
			})
		]
	});

	var approvalTitle = createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('ew.documents.approvals.title'),
		items: [
			createTextField({
				value: edi.i18n.getMessage('ew.documents.approvals.modal.title', [titleText]),
				readOnly: true,
				name: 'approvalTitle'
			})
		]
	});

	var routeName = createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		title: edi.i18n.getMessage('ew.documents.approvals.routeName'),
		items: [
			createCombo({
				name: 'route',
				store: createStore({
					proxy: createProxyConfig({
						type: 'ajax',
						url: edi.rest.services.ORG_PROFILE.ROUTES.GLOBAL.GET,
						extraParams: {
							excludeError: true
						}
					}),
					model: edi.models.getModel('EW_ROUTES')
				}),
				queryMode: 'remote',
				queryParam: 'name',
				minChars: 0,
				displayField: 'name',
				valueField: 'id',
				forceSelection: true,
				allowBlank: false
			})
		]
	});

	const checkPermissions = function (employee) {
		let failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
				moduleData.tab.setLoading(false);
			});
		};

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.CHECK_PERMISSIONS.GET, {
				id: employee.get('id')
			}),
			'GET',
			null,
			function (data) {
				if (!data.data) {
					employeeAutocomplete.clearValue();
					showErrorToast(
						edi.i18n.getMessage('approval.cannot.save.error.title'),
						edi.i18n.getMessage(
							'controller.approval.cannot.save.cause.no.permission.found.for.employee.error',
							[employee.get('displayname')]
						),
						{
							showCloseBtn: true
						}
					);
				}
			},
			failure
		);
	};

	var employee = createFieldBlock({
		items: [
			(employeeAutocomplete = createOrgAutocomplete(
				{
					fieldLabel: edi.i18n.getMessage('org.profile.routes.employee'),
					name: 'employee',
					queryMode: 'remote',
					queryParam: 'name',
					displayField: 'displayname',
					valueField: 'id',
					forceSelection: true,
					anyMatch: true,
					allowBlank: false,
					listeners: {
						change: function (comp, value) {
							if (value) {
								checkPermissions(comp.lastSelectedRecords[0]);
							}
						},
						beforeQuery: function (plan) {
							plan.query = plan.query.trim();
						},
						afterrender: function (cmp) {
							const picker = cmp.getPicker();

							picker.on('viewlist', function (__self, record, itemEl) {
								const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

								var tpl = new Ext.XTemplate(
									'<tpl if="position">',
									'<span class="{[this.$comp.cls]}-text">{position}</span>',
									'</tpl>'
								);
								tpl.$comp = cmp;
								var el = tpl.append(wrapEl, record.getData());
							});
						}
					}
				},
				{
					sorters: [],
					proxy: createProxyConfig({
						type: 'ajax',
						url: edi.utils.compileURL(edi.rest.services.ORG_PROFILE.EMPLOYEES.GET, {
							gridName: 'active'
						})
					}),
					model: edi.models.getModel('EW_EMPLOYEES')
				}
			))
		]
	});

	var actions = createFieldBlock({
		items: [
			createCombo({
				name: 'actions',
				fieldLabel: edi.i18n.getMessage('ediweb.employees.actions'),
				allowBlank: false,
				store: edi.stores.createSimpleInlineStore(
					['APPROVAL_REFUSAL', 'SIGNING_REFUSAL'],
					function (id) {
						return edi.i18n.getMessage('org.profile.routes.actions.' + id);
					},
					false
				),
				forceSelection: true,
				anyMatch: true
			})
		]
	});

	var comment = createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		items: [
			createTextField({
				fieldLabel: edi.i18n.getMessage('ew.documents.approvals.comment'),
				name: 'comment',
				maxLength: 1000,
				rowsHtmlAttributeValue: 4,
				isTextarea: true
			})
		]
	});
	let items = [];
	let area = [];
	if (type === edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS) {
		items = items.concat([document, date, approvalTitle, routeName]);
		area = area.concat([12, 5]);
	}
	if (type === edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES) {
		items = items.concat([approvalTitle, employee, actions]);
		area.push(12);
	}
	items.push(comment);

	var modalForm = createModalForm({
		layout: {
			type: 'grid',
			gap: [24],
			area: area
		},
		items: items
	});
	var modal = createModalPanel({
		title: edi.i18n.getMessage(`approval.document.${type}`),
		items: [modalForm],
		buttons: [
			createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('document.send.to.approve.document'),
				glyph: edi.constants.ICONS.SEND,
				handler: function () {
					if (!edi.utils.setFocusToDocumentsWithGrid(modalForm, ['route'], [], null, false)) {
						return;
					}
					var modalValues = edi.utils.collectFormValues(modalForm);

					if (type === edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES) {
						modalValues.route = {
							dynamic: true,
							stagesCount: 1,
							stages: [
								{
									stageNumber: 1,
									parties: [
										{
											partyNumber: 1,
											employee: modalValues.employee,
											actions: [modalValues.actions]
										}
									]
								}
							]
						};
					}
					moduleData.tab.setLoading();
					modal.setLoading(true);
					if (typeof saveFn === 'function') {
						saveFn(
							docValues,
							(dsfId) => createApproval(modalValues, dsfId),
							(moduleData) => {
								modal.close();
								if (moduleData?.tab && !moduleData.tab.destroyed) {
									moduleData.tab.setLoading(false);
								}
							},
							{ isSendingToApproval: true }
						);
					} else {
						createApproval(modalValues, docValues.id);
					}
				}
			}),
			createButton({
				cls: BUTTON_CLS.secondary,
				text: edi.i18n.getMessage('form.btn.cancel'),
				handler: function () {
					modal.close();
				}
			})
		]
	});
	modal.show();
};

const getApprovalDocumentTitle = function (value, title) {
	if (edi.utils.getAttributeByName(value.attributes, 'CORRECTION') === 'true') {
		title = `${edi.i18n.getMessage('ediweb.approval.correct.title')} - ${title}`;
	}
	return title;
};

const getDocTypeName = function (docValues) {
	const docType = docValues.type || docValues.docType || docValues.bpName;
	return edi.i18n.getMessage('documents.doctype.' + docType);
};

const getApprovalHeaderPanel = function (moduleData, documentData, openSideBar) {
	const approvalDocumentRoute = (data) => {
		return createFieldBlock({
			cls: `${FIELD_BLOCK_CLS.small} edi-fieldcontainer-with-overflow`,
			title: edi.i18n.getMessage('ew.documents.approvals.routeName'),
			grid: {
				col: edi.constants.DEFAULT.DOCUMENT_HEADER.itemCols
			},
			layout: {
				type: 'hbox',
				align: 'center'
			},
			items: [
				createButton({
					cls: `${BUTTON_CLS.light} ${BUTTON_CLS.icon}`,
					tooltip: edi.i18n.getMessage('ew.documents.approvals.routeName'),
					glyph: edi.constants.ICONS.DETAILS,
					handler: () => openSideBar(data)
				})
			]
		});
	};
	const approvalDocumentState = (data) => {
		var approvalDocumentStateHTML = edi.renderers.baseStateRenderer(
			data.state,
			edi.i18n.getMessage('ew.documents.approvals.state.' + data.state)
		);

		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('ew.documents.approvals.state'),
			grid: {
				col: edi.constants.DEFAULT.DOCUMENT_HEADER.itemCols
			},
			items: [
				createLabel({
					html: approvalDocumentStateHTML
				})
			]
		});
	};
	const approvalDocumentId = (data) => {
		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('ew.documents.approvals.ID'),
			grid: {
				col: edi.constants.DEFAULT.DOCUMENT_HEADER.itemCols
			},
			items: [
				createLabel({
					text: data.id
				})
			]
		});
	};

	const approvalDocumentRevokeReason = (data) => {
		const revokeReasonValue = edi.utils.getAttributeByName(data?.attributes, 'REVOKED_REASON');
		return revokeReasonValue
			? createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					padding: '0 0 24 0',
					title: edi.i18n.getMessage('document.revoke.reason.title'),
					grid: {
						scol: 1,
						col: 12,
						srow: 3,
						row: 5
					},
					items: [
						createLabel({
							text: revokeReasonValue
						})
					]
			  })
			: null;
	};

	return createDocumentHeaderPanel(moduleData.initData, {
		noUsage: true,
		noStatus: true,
		noSignatures: true,
		noLog: true,
		noId: true,
		getAdditionFields: function () {
			return [
				approvalDocumentRoute(documentData),
				approvalDocumentState(documentData),
				approvalDocumentId(documentData),
				approvalDocumentRevokeReason(documentData)
			];
		}
	});
};

const getApprovalHeadData = function (documentData) {
	const parentDocType = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
	let docType = documentData.data.docType;
	let typeStr = docType;

	if (docType.match(/FNS_UPD/)) {
		docType = parentDocType;
		typeStr = `${edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD}.${docType}`;
	} else if (docType.match(/FNS_UKD/)) {
		docType = parentDocType;
		typeStr = `${docType}`;
	}

	var headerLabelText = [
		{
			title: edi.i18n.getMessage('documents.form.type'),
			text: edi.i18n.getMessage(`documents.doctype.${typeStr}`)
		},
		{
			title: edi.i18n.getMessage('document.form.number'),
			text: documentData.data.docNumber
		},
		{
			title: edi.i18n.getMessage('documents.column.date'),
			text: edi.utils.formatDate(
				edi.utils.getObjectProperty(documentData, 'data.docDate'),
				edi.constants.DATE_FORMAT.FNS,
				edi.constants.DATE_FORMAT.SERVER
			)
		}
	];

	var approvalComment = createFieldBlockForDetails({
		title: edi.i18n.getMessage('document.dsf.commentText'),
		userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
		items: [
			createLabelBlockForDetails({
				contents: [
					{
						text: edi.utils.getObjectProperty(documentData, 'comment')
					}
				]
			})
		]
	});

	var headerLabel = createLabelBlockForDetails({
		contents: headerLabelText
	});

	var headData = createFieldBlockForDetails({
		title: edi.i18n.getMessage('document.data'),
		cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
		userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
		items: [headerLabel]
	});

	return [approvalComment, headData];
};

const getDSFDetailPanel = function (documentData, moduleData, parentDocData, openSideBar, options) {
	var filePanel = null;
	if (documentData.data.file) {
		var fileName = documentData.data.file.fileName;
		const previewIsAllowed = edi.permissions.hasPermission('CLIENT_READ_ATTACHMENT_VIEW');
		const fileDropper = createFileDropper({
			cls: previewIsAllowed ? 'edi-component-clickable drag-file' : 'drag-file',
			readOnly: true,
			fileData: {
				fileName: documentData.data.file.fileName,
				fileDate: edi.utils.formatDate(documentData.data.file.modifyDate, edi.constants.DATE_FORMAT.FNS),
				fileTime: edi.utils.formatDate(documentData.data.file.modifyDate, edi.constants.DATE_FORMAT.TIME),
				fileSize: edi.utils.formatFileSize(documentData.data.file.fileSize)
			},
			listeners: {
				afterrender: previewIsAllowed
					? function (cmp) {
							cmp.el.dom.addEventListener('click', function () {
								if (options.isImagesLoaded) {
									const url = edi.utils.formatString(
										edi.rest.services.APPROVAL_DOCUMENTS.EXPORT.ATTACHMENT,
										{
											id: moduleData.initData.id
										}
									);
									createPreviewPanel(
										{
											fileId: moduleData.initData.id,
											fileName,
											imagesBase64Data: options.imagesData.data?.map(({ data }) => data)
										},
										{
											url
										}
									);
								} else if (options.isImagesLoadedError) {
									edi.core.showInfo('document.dsf.load.preview.error');
								} else {
									edi.core.showInfo('document.dsf.load.preview.waiting');
								}
							});
					  }
					: undefined
			}
		});

		filePanel = createFieldBlock({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('document.file'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [fileDropper]
		});
	}

	var createOS = function (selectorConf) {
		Object.assign(selectorConf, {
			is_valid: true,
			readOnly: true,
			valuesByMap: true,
			fieldValues: parentDocData
		});

		return createOrgSelector(selectorConf);
	};

	var sender = !!parentDocData
		? createFieldBlock({
				cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
				title: edi.i18n.getMessage('document.dsf.sender.' + parentDocData.type),
				items: [
					createOS({
						fieldsMap: edi.selectors.getDSFFieldsMap('sender')
					})
				]
		  })
		: null;

	var receiver = !!parentDocData
		? createFieldBlock({
				cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
				title: edi.i18n.getMessage('document.dsf.receiver.' + parentDocData.type),
				items: [
					createOS({
						fieldsMap: edi.selectors.getDSFFieldsMap('receiver')
					})
				]
		  })
		: null;

	var comment = !!parentDocData
		? createFieldBlock({
				cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
				title: edi.i18n.getMessage('remarks'),
				items: [
					createDetailLabel({
						contentTextArray: [
							{
								content: edi.utils.getObjectProperty(parentDocData, 'comment')
							}
						]
					})
				]
		  })
		: null;

	var receiverSignatureField = !!parentDocData
		? createFieldBlock({
				cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
				layout: 'grid',
				items: [
					createCheckbox({
						grid: {
							col: 4
						},
						boxLabel: edi.i18n.getMessage('column.expected.recipients.signature.under.document'),
						checked:
							edi.utils.getObjectProperty(parentDocData, 'reference.receiverSignatureExpected') ===
							'true',
						disabled: true,
						uncheckedValue: false
					})
				]
		  })
		: null;

	var orgnizationsTwoColumnsLayout = createTwoColumnsLayout([sender], [receiver], 0.5, {
		margin: '17 0 0 0',
		items2Conf: {
			margin: '0 0 0 10'
		}
	});

	return createFormForModule({
		cls: 'edi-details-panel',
		items: [
			getApprovalHeaderPanel(moduleData, documentData, openSideBar),
			createMaxWidthContainerForDetail({
				items: [
					...getApprovalHeadData(documentData),
					orgnizationsTwoColumnsLayout,
					filePanel,
					comment,
					receiverSignatureField
				]
			})
		]
	});
};

const getUPDDetailPanel = function (documentData, moduleData, parentDocData, openSideBar, options) {
	const me = this;

	if (Array.isArray(parentDocData)) {
		const part2 = parentDocData.find(function (item) {
			return item.hasOwnProperty('buyerInfo');
		});

		const part1 = parentDocData.find(function (item) {
			return !item.hasOwnProperty('buyerInfo');
		});

		const versionId = edi.utils.getAttributeByName(documentData.attributes, 'versionId');

		let approvalDocClass = new ApprovalDocumentDetailsFns_upd_5_01nModule(part1, part2);

		const detailsPanel = approvalDocClass._detailsPanel();
		const detailsPanelItems = detailsPanel?.items?.items || {};
		const updHeadData = detailsPanel.down('[itemId="updHeadData"]');
		const updCurrency = detailsPanel.down('[itemId="updCurrency"]');
		updHeadData.hidden = true;

		const currency = createFieldBlockForDetails({
			padding: '8 0 0 0',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [updCurrency]
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				getApprovalHeaderPanel(moduleData, documentData, openSideBar),
				createMaxWidthContainerForDetail({
					items: [...getApprovalHeadData(documentData), currency]
				}),
				...detailsPanelItems
			]
		});
	}
};

const getUKDDetailPanel = function (documentData, moduleData, parentDocData, openSideBar, options) {
	const me = this;

	if (Array.isArray(parentDocData)) {
		const part2 = parentDocData.find(function (item) {
			return item.hasOwnProperty('buyerInfo');
		});

		const part1 = parentDocData.find(function (item) {
			return !item.hasOwnProperty('buyerInfo');
		});

		const versionId = edi.utils.getAttributeByName(documentData.attributes, 'versionId');

		let approvalDocClass = new ApprovalDocumentDetailsFns_ukd_5_01nModule(part1, part2);

		const detailsPanel = approvalDocClass._detailsPanel();
		const detailsPanelItems = detailsPanel?.items?.items || {};
		const ukdHeadData = detailsPanel.down('[itemId="ukdHeadData"]');
		const ukdCurrency = detailsPanel.down('[itemId="ukdCurrency"]');
		ukdHeadData.hidden = true;

		const currency = createFieldBlockForDetails({
			padding: '8 0 0 0',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [ukdCurrency]
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				getApprovalHeaderPanel(moduleData, documentData, openSideBar),
				createMaxWidthContainerForDetail({
					items: [...getApprovalHeadData(documentData), currency]
				}),
				...detailsPanelItems
			]
		});
	}
};

const openApprovalPreviewModal = function (documentData, parentDocId) {
	let checkReportTimer, previewModal;

	var getPreviewFile = function () {
		var failure = function (resp) {
			if (previewModal && !previewModal.isDestroyed) {
				previewModal.close();
			}
			edi.rest.getErrorHandler('error.getting.data')(resp);
		};
		var success = function (resp) {
			if (!resp || !resp.data || !resp.data.id) {
				failure(resp);
			}
			checkReportStatus(resp.data.id);
		};
		let url = edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.REPORTS.VIEW.POST, {
			id: parentDocId
		});
		let queryParams = {};

		//Под клиентом при формировании ПФ должны отображаться только шаблоны,
		//в которых указан язык выбранный в профиле текущего пользователя
		const userLanguage = edi.core.getUserData()?.language;
		if (userLanguage) {
			queryParams.templateLanguage = userLanguage;
		}

		url = edi.utils.compileURL(url, queryParams);

		edi.rest.sendRequest(url, 'POST', {}, success, failure);
	};

	/**
	 * create preview modal panel
	 */
	var openPreviewModal = function () {
		const bpName = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
		const versionId = edi.utils.getAttributeByName(documentData.attributes, 'versionId');

		const fileName = getApprovalDocTitle({ bpName, versionId, number: documentData.data.docNumber });
		previewModal = createPreviewPanel(
			{
				fileId: parentDocId,
				fileName: fileName
			},
			{
				modalConf: {
					tools: []
				}
			}
		);
		previewModal.setLoading(true);
		getPreviewFile();
	};

	let setFileDataToModal = function (reportId) {
		previewModal.add({
			xtype: 'component',
			layout: 'fit',
			flex: 1,
			autoEl: {
				tag: 'iframe',
				frameborder: 0,
				style: {
					height: '100%',
					width: '100%'
				},
				src:
					edi.utils.formatString(edi.rest.services.DOCUMENTS.FD.PRINT_FORM.GET, {
						reportId: reportId
					}) + '?authType=EDI'
			}
		});

		previewModal.center();
		previewModal.setLoading(false);
	};
	var checkReportStatus = function (requestId) {
		var failure = function (resp) {
			edi.rest.getErrorHandler('error.getting.data')(resp);
		};
		var success = function (resp) {
			if (!resp || !resp.items || !resp.items[0]) {
				failure(resp);
				return;
			}
			const report = resp.items.find((item) => {
				return item.requestId === requestId && item.reportStatus === 'READY';
			});
			if (report) {
				setFileDataToModal(report.requestId);
			} else if (previewModal && !previewModal.isDestroyed) {
				if (checkReportTimer) {
					clearTimeout(checkReportTimer);
				}
				checkReportTimer = setTimeout(function () {
					checkReportStatus(requestId);
				}, 1000);
			}
		};
		var url = edi.utils.formatString(applicationAPI.TEMPLATES_AND_STATUSES.GET, {
			docId: parentDocId,
			format: 'PDF'
		});
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	return openPreviewModal();
};

export {
	getCurrentExecutor,
	getPartItem,
	getExecutorInSigners,
	getStatusInSidebar,
	showApprovalModal,
	getApprovalDocumentTitle,
	checkExecutorMatchesApprovalStage,
	isCurrentEmployeeExecutor,
	getDocTypeName,
	getDSFDetailPanel,
	getUPDDetailPanel,
	getUKDDetailPanel,
	getApprovalDocTitle,
	openApprovalPreviewModal
};
