// @ts-ignore
import { createLabelBlockForDetails } from '@UIkit/components/fields';
import {
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createMaxWidthContainerForDetail
	// @ts-ignore
} from '@UIkit/components/panels';
import * as scCodes from '../json/sc_codes.json';
import { UpdOrgSelectors } from '../selectors';
import { createNamedDocumentBlockForDetail } from '../documentBlock/createDocumentBlockForDetail';
import { VidObyaz } from '../definitions/svSchFakt';

export interface AdditionalInformationProps {
	documentData: AnyObject;

	//Для Фактора
	createOS: (selectorConfig: AnyObject) => ExtComponent;
	selectorConfigsBuilder: UpdOrgSelectors;
	setNameForCompanySelector: (cmp: ExtComponent, title: string) => void;

	[key: string]: any;
}

export class AdditionalInformation {
	view: ExtComponent;

	props: AdditionalInformationProps;

	path: string = 'dokument.svSchFakt.dopSvFHZH1';

	constructor(props: AdditionalInformationProps) {
		const me = this;
		me.modifyConfig(props);
	}

	modifyConfig(props: AdditionalInformationProps) {
		const me = this;

		me.props = props;
	}

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		return [me.path, ...name].filter(Boolean).join('.');
	}

	createGovernmentContractIdField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('column.identifier'),
			text: edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('idGosKon'))
		};
	}

	createGovernmentContractNumberField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('column.license.number'),
			text: edi.utils.getObjectProperty(
				me.props.documentData,
				me.createFieldName('infProdZaGosKazn.nomerGosKont')
			)
		};
	}

	createGovernmentContractDateField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('date'),
			text: edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('infProdZaGosKazn.dataGosKont'))
		};
	}

	createGovernmentContractFieldBlock(): ExtComponent {
		const me = this;

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.govContract'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						me.createGovernmentContractIdField(),
						me.createGovernmentContractNumberField(),
						me.createGovernmentContractDateField()
					]
				})
			]
		});
	}

	createSellerAccountNumberField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.accountSeller'),
			text: edi.utils.getObjectProperty(
				me.props.documentData,
				me.createFieldName('infProdZaGosKazn.litsSchetProd')
			)
		};
	}

	createSellerBudgetClassificationCodeField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.code'),
			text: edi.utils.getObjectProperty(
				me.props.documentData,
				me.createFieldName('infProdZaGosKazn.kodProdByudzhKlass')
			)
		};
	}

	createSellerTargetClassificationCodeField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.codeGoal'),
			text: edi.utils.getObjectProperty(
				me.props.documentData,
				me.createFieldName('infProdZaGosKazn.kodTSeliProd')
			)
		};
	}

	createSellerFieldBlock(): ExtComponent {
		const me = this;

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.seller'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						me.createSellerAccountNumberField(),
						me.createSellerBudgetClassificationCodeField(),
						me.createSellerTargetClassificationCodeField()
					]
				})
			]
		});
	}

	createSellerTreasuryOrgCodeField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('column.code'),
			text: edi.utils.getObjectProperty(
				me.props.documentData,
				me.createFieldName('infProdZaGosKazn.kodKaznachProd')
			)
		};
	}

	createSellerTreasuryOrgNameField(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('column.short.name'),
			text: edi.utils.getObjectProperty(
				me.props.documentData,
				me.createFieldName('infProdZaGosKazn.naimKaznachProd')
			)
		};
	}

	sellerTreasuryFieldBlock(): ExtComponent {
		const me = this;
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.sellerTreasury'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [me.createSellerTreasuryOrgCodeField(), me.createSellerTreasuryOrgNameField()]
				})
			]
		});
	}

	createFactorInfoFieldBlock(): ExtComponent {
		const me = this;

		const name = me.createFieldName('svFaktor');
		const factorInfo = edi.utils.getObjectProperty(me.props.documentData, name);
		const isFactorInfoDefined = !!factorInfo;

		const factor = me.props.createOS({
			itemId: 'factorInfo',
			readOnly: true,
			fieldValues: isFactorInfoDefined ? me.props.documentData : undefined,
			selectedOrgValues: isFactorInfoDefined ? me.props.documentData : undefined,
			fieldsMap: me.props.selectorConfigsBuilder.getFactorFieldsMap(name)
		});
		me.props.setNameForCompanySelector(factor, 'documents.fns_upd.additionalInfo.factorInfo');

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.factorInfo'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [factor]
		});
	}

	createAssignmentBasisDocumentBlock(): ExtComponent | null {
		const me = this;

		const name = me.createFieldName('osnUstDenTreb');
		return createNamedDocumentBlockForDetail(
			{
				title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.assignmentBasis'),
				data: edi.utils.getObjectProperty(me.props.documentData, name, true),
				name
			},
			{
				isSingleDocument: true,
				isReadOnly: true
			},
			{ readOnly: true }
		);
	}

	createTypeOfObligation(): ExtComponent {
		const me = this;

		const values = edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('vidObyaz'), true);

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.obligation.type'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: values.map(({ kodVidObyaz, naimVidObyaz }: VidObyaz) => ({
						text: `${kodVidObyaz} — ${naimVidObyaz}`,
						isNewLine: true
					}))
				})
			]
		});
	}

	createDOPspecialConditionsField(): ExtComponent {
		const me = this;

		const storeItems: { id: string; name: string }[] = scCodes;
		const store = edi.stores.createInlineStore(storeItems, 'AUTHORITY_AREA');
		const value = edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('spObstFDOP'));
		const record = store.findRecord('id', value);
		const text = record?.get('name');

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.dop.special.conditions'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						{
							text: text || value
						}
					]
				})
			]
		});
	}

	createSCHFDOPspecialConditionsField(): ExtComponent {
		const me = this;

		const storeItems: { id: string; name: string }[] = scCodes;
		const store = edi.stores.createInlineStore(storeItems, 'AUTHORITY_AREA');
		const value = edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('spObstFSCHFDOP'));
		const record = store.findRecord('id', value);
		const text = record?.get('name');

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.schfdop.special.conditions'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						{
							text: text || value
						}
					]
				})
			]
		});
	}

	createSCHFspecialConditionsStoreItems() {
		return [
			{
				id: 1,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.1')
			},
			{
				id: 2,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.2')
			},
			{
				id: 3,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.3')
			},
			{
				id: 4,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.4')
			},
			{
				id: 5,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.5')
			},
			{
				id: 6,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.6')
			}
		];
	}
	createSCHFspecialConditionsField(): ExtComponent {
		const me = this;

		const storeItems: { id: number; name: string }[] = me.createSCHFspecialConditionsStoreItems();
		const store = edi.stores.createInlineStore(storeItems, 'AUTHORITY_AREA');
		const value = edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('spObstFSCHF'));
		const record = store.findRecord('id', value);
		const text = record?.get('name');

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						{
							text: text || value
						}
					]
				})
			]
		});
	}

	createSupportingDocumentBlock(): ExtComponent | null {
		const me = this;

		const name = me.createFieldName('soprDokFHZH');
		return createNamedDocumentBlockForDetail(
			{
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.supporting.document.details'),
				data: edi.utils.getObjectProperty(me.props.documentData, name, true),
				name
			},
			{ isReadOnly: true },
			{ readOnly: true }
		);
	}

	createView(): ExtComponent {
		const me = this;
		return (me.view = createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo'),
			collapsible: true,
			collapsed: true,
			layout: {
				type: 'grid',
				gap: [24, 16]
			},
			items: [
				createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: [
						me.createGovernmentContractFieldBlock(),
						me.createSellerFieldBlock(),
						me.sellerTreasuryFieldBlock(),
						me.createFactorInfoFieldBlock()
					]
				}),
				me.createAssignmentBasisDocumentBlock(),
				createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: [me.createTypeOfObligation(), me.createDOPspecialConditionsField()]
				}),
				me.createSupportingDocumentBlock(),
				createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: [me.createSCHFDOPspecialConditionsField(), me.createSCHFspecialConditionsField()]
				})
			]
		}) as ExtComponent);
	}
}

export const createAdditionalInformationFieldSetForDetail = (props: AdditionalInformationProps): ExtComponent => {
	const additionalInformation = new AdditionalInformation(props);
	return additionalInformation.createView();
};
