// @ts-ignore
import { PTYPE_APPEND_ICONBUTTON } from '@UIkit/plugins/AppendSibling/AppendIconButton';
// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
// @ts-ignore
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
// @ts-ignore
import { showConfirm } from '@UIkit/components/modal/MessageBox';
import { LinkDocumentsToPackageWindow } from './linkDocumentToPackageWindow';
import { documentPackageApi, DocumentPackageHeader } from './entities';
import { ActionRuleCheckOptions } from '@App/js/action';

export const documentPackageMethods = {
	eventsObserver: new Ext.util.Observable(),
	ALLOWED_DOCUMENT_TYPES: [
		edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
		edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
		edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
		edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
		edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT
	] as string[],
	PACKAGE_STATES_FOR_RENDERER: {
		DRAFT: 'DRAFT',
		SENT: 'SENT',
		PROCESSING: 'PROCESSING',
		ON_ANNUL: 'ON_ANNUL',
		CANCELED: 'CANCELED',
		REJECTED: 'REJECTED',
		COMPLETED: 'COMPLETED',
		default: 'PROCESSING'
	} as { [state: string]: string },
	packageStateRenderer: function (packageHeader: DocumentPackageHeader): string {
		const state = packageHeader.state;
		const map = documentPackageMethods.PACKAGE_STATES_FOR_RENDERER;
		const stateByMap = map[state] ?? map.default;
		return edi.renderers.status(Object.assign({}, packageHeader, { state: stateByMap }));
	},
	/**
	 * Проверяет может ли документ быть использован в пакетах
	 */
	isPackagingAllowed: function (docType: string): boolean {
		return documentPackageMethods.ALLOWED_DOCUMENT_TYPES.includes(docType);
	},
	/**
	 * Условие для actionRules, которое проверяет, что документ не находится в закрытом пакете, в этом случае действие
	 * блочится, т.к. должно выполняться из пакета одинаково для всех документов
	 */
	isDocActionBlocked: function (checkOptions: ActionRuleCheckOptions): boolean {
		if (edi.constants.ALLOW_ENCLOSED_PACKAGE_ACTIONS === true) return false;

		const docHeader = checkOptions.record.getData();
		const docInClosedPackage =
			!!docHeader.packageId && edi.utils.getAttributeByName(docHeader.attributes, 'packageEnclosed') === 'true';
		return (
			checkOptions.actionFromPackageModule !== true &&
			docInClosedPackage &&
			documentPackageMethods.isPackagingAllowed(checkOptions.docType)
		);
	},
	/**
	 * Открывает окно для добавления документов в существующий пакет
	 */
	addDocsToPackage: function (
		docIds: number[],
		toOrgId: number,
		success?: (resp: AnyObject) => {},
		failure?: (resp: AnyObject) => {}
	) {
		new LinkDocumentsToPackageWindow({
			documents: docIds,
			toOrgId,
			success,
			failure
		}).show();
	},
	/**
	 * Открывает модуль создания пакета с уже добавленным документом
	 */
	createPackageWithDocuments(docHeaders: DocumentHeader[]) {
		edi.core.openModule('create.documentPackage', { addConf: { preselectedDocuments: docHeaders } });
	},
	/**
	 * Отвязывает документ от пакета по айди документа
	 */
	unlinkDocFromPackage: function (
		packageId: number,
		docId: number,
		success?: (resp: AnyObject) => {},
		failure?: (resp: AnyObject) => {}
	) {
		showConfirm({
			msgText: 'documents.package.delete.document.confirm',
			success: async function () {
				const result = await documentPackageApi.unlinkDocuments(packageId, [docId]);
				if (result.success) {
					documentPackageMethods.eventsObserver.fireEvent('changeDocuments', { packageId });
					success?.(result.response);
				} else {
					failure =
						'function' == typeof failure
							? failure
							: edi.rest.getErrorHandler('document.error.in.package.delete');
					failure?.(result.response);
				}
			}
		});
	},
	createPackageTypeHintPlugin: function (refEl: string): AnyObject {
		return {
			ptype: PTYPE_APPEND_ICONBUTTON,
			refEl: refEl,
			hintConfig: {
				popupAlign: 'r',
				layout: {
					type: 'grid',
					gap: [16, 16]
				},
				items: [
					createLabel({
						text: edi.i18n.getMessage('documentPackage.type.hint.title'),
						typography: 'heading_01'
					}),
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documentPackage.type.hint.title.opened'),
						items: [
							createLabel({
								text: edi.i18n.getMessage('documentPackage.type.hint.body.opened'),
								typography: 'body-long_01'
							})
						]
					}),
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documentPackage.type.hint.title.closed'),
						items: [
							createLabel({
								text: edi.i18n.getMessage('documentPackage.type.hint.body.closed'),
								typography: 'body-long_01'
							})
						]
					})
				]
			}
		};
	}
};
edi.events.documentPackage = documentPackageMethods.eventsObserver;
