// @ts-ignore
import { createModalPanel } from '@UIkit/components/modal';
// @ts-ignore
import { createFieldBlock, createModalForm } from '@UIkit/components/panels';
// @ts-ignore
import { createCombo } from '@UIkit/components/fields';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { documentPackageApi, DocumentPackageHeader, DocumentPackageParty } from './entities';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';

export interface CreateDocForPackageWindowProps {
	packageId: DocumentPackageHeader['id'];
	receiverOrgId: DocumentPackageParty['id'];
}

export interface DocTypeConfig {
	id: string;
	name: string;
	moduleName: string;
	isAvailable: (cfg: DocTypeConfig) => boolean;
}

export class CreateDocForPackageWindow {
	props: CreateDocForPackageWindowProps;
	modal: ExtComponent;
	modalForm: ExtComponent;
	docTypeCombo: ExtComponent;
	saveButton: ExtComponent;
	modalTitleText = 'documentPackage.add.new';
	saveButtonText = 'form.btn.create';

	constructor(props: CreateDocForPackageWindowProps) {
		this.props = props;
		this.createModal();
	}

	getTypes(): DocTypeConfig[] {
		return [
			{
				id: edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				name: edi.i18n.getMessage('document.dsf'),
				moduleName: edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED],
				isAvailable: () => true
			}
		];
	}

	createDocTypeCombo(): ExtComponent {
		const me = this;
		const availableTypes = me.getTypes().filter((cfg) => cfg.isAvailable(cfg));
		me.docTypeCombo = createCombo({
			allowBlank: false,
			store: edi.stores.createInlineStore(availableTypes)
		}) as ExtComponent;
		return createFieldBlock({
			title: edi.i18n.getMessage('document.type'),
			items: [me.docTypeCombo]
		});
	}

	createFormItems(): ExtComponent[] {
		const me = this;
		return [me.createDocTypeCombo()];
	}

	async afterDocCreate(docId: DocumentHeader['id']) {
		const me = this;
		const { success, response } = await documentPackageApi.linkDocuments(me.props.packageId, [docId]);
		if (success) {
			documentPackageMethods.eventsObserver.fireEvent('uploadNewDocument', { packageId: me.props.packageId });
		} else {
			edi.rest.getErrorHandler('documentPackage.error.link')(response);
		}
	}

	saveButtonHandler() {
		const me = this;
		const type = me.docTypeCombo.getValue();
		const moduleName = me.getTypes().find((cfg) => cfg.id === type)?.moduleName;
		if (moduleName) {
			edi.core.openModule(moduleName, null, null, false, null, {
				packageOptions: {
					allowedDsfTypes: documentPackageMethods.ALLOWED_DOCUMENT_TYPES.filter((type) =>
						type.includes('DSF')
					),
					receiverOrgId: me.props.receiverOrgId,
					afterCreate: me.afterDocCreate.bind(me)
				}
			});
			me.modal.close();
		}
	}

	createModalButtons(): ExtComponent[] {
		const me = this;
		return [
			(me.saveButton = createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage(me.saveButtonText),
				bindToForm: me.modalForm,
				handler: () => me.saveButtonHandler()
			}) as ExtComponent)
		];
	}

	createModal(): CreateDocForPackageWindow['modal'] {
		const me = this;
		return (me.modal = createModalPanel({
			title: edi.i18n.getMessage(me.modalTitleText),
			items: [
				(me.modalForm = createModalForm({
					items: [
						createFieldBlock({
							title: edi.i18n.getMessage(),
							items: me.createFormItems()
						})
					]
				}))
			],
			buttonsBefore: me.createModalButtons()
		}) as ExtComponent);
	}

	showModal() {
		this.modal?.show();
	}
}
