import { SvedTov } from './definitions/producLineDef';
import { Dokument } from './definitions/commonsDef';
import { SvProdPer } from './definitions/svProdPer';
import { SvedOrg } from './definitions/svedOrg';
import { SvSchFakt } from './definitions/svSchFakt';

export const converterFrom502Nto501N = {
	product: (productValues502N: Dokument['tablSchFakt']) => {
		return {
			productInfo: productValues502N?.svedTov?.map((product: SvedTov) => {
				return {
					excise: {
						sumExcise: product?.aktsiz && 'sumAktsiz' in product.aktsiz ? product.aktsiz.sumAktsiz : null,
						withoutExcise:
							product?.aktsiz && 'bezAktsiz' in product.aktsiz ? product.aktsiz.bezAktsiz : null
					},
					taxSum: {
						taxSum: 'sumNal' in product?.sumNal ? product.sumNal.sumNal : null,
						withoutTax: 'bezNDS' in product?.sumNal ? product.sumNal.bezNDS : null,
						defTax: null
					},
					customsDeclarationInfo: product?.svDT?.map(({ kodProish, nomerDT }) => ({
						countryOrigin: kodProish,
						defCountryOrigin: null,
						number: nomerDT
					})),
					additionalProductInfo: {
						feature: product?.dopSvedTov?.prTovRab,
						featureAdditionalInfo: product?.dopSvedTov?.dopPrizn,
						unitOfMeasure: product?.okeiTov,
						countryOfOrigin: product?.svDT
							?.map(({ kodProish }) => edi.utils.getCountryFULLByISO(null, null, null, kodProish))
							.join(','), // Список стран деклараций
						numberShouldRelease: product?.dopSvedTov?.nadlOtp,
						productDesc: product?.dopSvedTov?.harakterTov,
						productSort: product?.dopSvedTov?.sortTov,
						productReference: product?.dopSvedTov?.artikulTov,
						productCode: product?.dopSvedTov?.kodTov,
						catalogCode: product?.dopSvedTov?.kodKat,
						productTypeCode: product?.dopSvedTov?.kodVidTov,
						infoTracking: product?.dopSvedTov?.svedProslezh?.map((track) => ({
							trackingProdNum: track?.nomTovProslezh,
							trackingUnitMes: track?.edIzmProslezh,
							trackingUnitMesName: track?.naimEdIzmProslezh,
							trackingProdQuan: track?.kolVEdProslezh,
							trackingAdd: track?.dopInfProslezh
						})),
						identifyProductToolNumbers: product?.dopSvedTov?.nomSredIdentTov?.map((tool) => ({
							kiz: tool?.kiz,
							packNumber: tool?.nomUpak,
							packId: tool?.identTransUpak
						}))
					},
					textInfos: product?.infPolFHZH2?.map((info) => ({
						identifier: info?.identif,
						value: info?.znachen
					})),
					lineNumber: product?.nomStr,
					productName: product?.naimTov,
					productOkei: product?.okeiTov,
					defProductOkei: null,
					count: product?.kolTov,
					cost: product?.tsenaTov,
					costWithoutNDS: product?.stTovBezNDS,
					tax: product?.nalSt,
					info: product?.stTovUchNal,
					defInfo: null
				};
			}),
			totalPay: {
				totalTaxAmount: {
					taxSum:
						productValues502N?.vsegoOpl?.sumNalVsego && 'sumNal' in productValues502N.vsegoOpl.sumNalVsego
							? productValues502N.vsegoOpl.sumNalVsego.sumNal
							: null,
					withoutTax:
						productValues502N?.vsegoOpl?.sumNalVsego && 'bezNDS' in productValues502N.vsegoOpl.sumNalVsego
							? productValues502N.vsegoOpl.sumNalVsego.bezNDS
							: null,
					defTax: null
				},
				totalNumber: productValues502N?.vsegoOpl?.kolNettoVs,
				totalWithoutNDS: productValues502N?.vsegoOpl?.stTovBezNDSVsego,
				totalWithNDS: productValues502N?.vsegoOpl?.stTovUchNalVsego,
				defTotalWithNDS: null
			}
		};
	},
	org: (org: SvedOrg) => ({
		identification: {
			individualPerson:
				org?.idSv && 'svIP' in org.idSv && org.idSv.svIP
					? {
							name: {
								lastName: org.idSv.svIP.fio.familiya,
								firstName: org.idSv.svIP.fio.imya,
								middleName: org.idSv.svIP.fio.otchestvo
							},
							tin: org.idSv.svIP.innfl,
							defTin: null,
							certificateRegistrationIP: org.idSv.svIP.svGosRegIP,
							additInfo: org.idSv.svIP.inieSved
					  }
					: null,
			legalEntity:
				org?.idSv && 'svYULUch' in org.idSv && org.idSv.svYULUch
					? {
							orgName: org.idSv.svYULUch.naimOrg,
							tin: org.idSv.svYULUch.innyul,
							defTin: null,
							kpp: org.idSv.svYULUch.kpp
					  }
					: null,
			informationAboutForeigner:
				org?.idSv && 'svInNeUch' in org.idSv && org.idSv.svInNeUch
					? {
							name: org.idSv.svInNeUch.naim,
							identifier: org.idSv.svInNeUch.identif,
							additionalInfo: org.idSv.svInNeUch.inieSved
					  }
					: null,
			individualParticipant:
				org?.idSv && 'svFLUch' in org.idSv && org.idSv.svFLUch
					? {
							name: {
								lastName: org.idSv.svFLUch.fio?.familiya,
								firstName: org.idSv.svFLUch.fio?.imya,
								middleName: org.idSv.svFLUch.fio?.otchestvo
							},
							requisites: org.idSv.svFLUch.inieSved,
							tin: org.idSv.svFLUch.innfl,
							additInfo: org.idSv.svFLUch.inieSved
					  }
					: null
		},
		address: {
			addressRu:
				org.adres && 'adrRF' in org.adres && org.adres.adrRF
					? {
							index: org.adres.adrRF.indeks,
							regionCode: org.adres.adrRF.kodRegion,
							area: org.adres.adrRF.rayon,
							city: org.adres.adrRF.gorod,
							settlement: org.adres.adrRF.naselPunkt,
							street: org.adres.adrRF.ulitsa,
							house: org.adres.adrRF.dom,
							housing: org.adres.adrRF.korpus,
							flat: org.adres.adrRF.kvart
					  }
					: null,
			addressInfo:
				org?.adres && 'adrInf' in org.adres && org.adres.adrInf
					? {
							codeOfCountry: org.adres.adrInf.kodStr,
							addressText: org.adres.adrInf.adrTekst
					  }
					: null,
			codeStateAddressRegister: org.adres && 'adrGAR' in org.adres ? org.adres.adrGAR?.idNom : null
		},
		contact: {
			telephone: org.kontakt?.tlf?.[0],
			mail: org.kontakt?.elPochta?.[0]
		},
		bankInfo: {
			bank: org.bankRekv?.svBank
				? {
						bankName: org.bankRekv.svBank.naimBank,
						bankCode: org.bankRekv.svBank.bik,
						coreAccountNumber: org.bankRekv.svBank.korSchet
				  }
				: null,
			accountNumber: org.bankRekv?.nomerScheta
		},
		okpo: org.okpo,
		structuralSubdivision: org.struktPodr,
		infoForMembers: org.infDlyaUchast,
		shortName: org.sokrNaim
	}),
	seller: (svProd: Dokument['svSchFakt']['svProd']) => svProd.map((seller) => converterFrom502Nto501N.org(seller)),
	buyer: (svPokup: Dokument['svSchFakt']['svPokup']) => svPokup.map((buyer) => converterFrom502Nto501N.org(buyer)),
	cargoFrom: (gruzOt: SvSchFakt['gruzOt']) => {
		if (!gruzOt) return null;
		return gruzOt.map((gruzOtpr) =>
			'onZHe' in gruzOtpr
				? {
						alsoKnownAs: gruzOtpr.onZHe
				  }
				: {
						cargoSender: converterFrom502Nto501N.org(gruzOtpr.gruzOtpr)
				  }
		);
	},
	cargoReceiver: (gruzPoluch: Dokument['svSchFakt']['gruzPoluch']) =>
		gruzPoluch && gruzPoluch.map((cargo) => converterFrom502Nto501N.org(cargo)),
	corrections: (isprDok: Dokument['svSchFakt']['isprDok']) => ({
		Number: isprDok?.nomIspr,
		Date: isprDok?.dataIspr
	}),
	info: (infPolFHZH1: Dokument['svSchFakt']['infPolFHZH1']) => {
		return {
			textInfos: infPolFHZH1?.tekstInf?.map((tekstInf) => ({
				identifier: tekstInf?.identif,
				value: tekstInf?.znachen
			})),
			fileId: infPolFHZH1?.idFaylInfPol
		};
	},
	paymentInformation: (svPRD: Dokument['svSchFakt']['svPRD']) =>
		svPRD?.map((prd) => ({
			number: prd.nomerPRD,
			date: prd.dataPRD,
			sum: prd.summaPRD
		})),
	factorInfo: (svFaktor: SvedOrg) => converterFrom502Nto501N.org(svFaktor),
	additionalInfo: function (dopSvFHZH1: Dokument['svSchFakt']['dopSvFHZH1']) {
		const me = this;
		return {
			additionalInfoSeller: {
				dateGovContract: dopSvFHZH1?.infProdZaGosKazn?.dataGosKont,
				numberGovCon: dopSvFHZH1?.infProdZaGosKazn?.nomerGosKont,
				accountSeller: dopSvFHZH1?.infProdZaGosKazn?.litsSchetProd,
				codeSeller: dopSvFHZH1?.infProdZaGosKazn?.kodProdByudzhKlass,
				codeSellerGoal: dopSvFHZH1?.infProdZaGosKazn?.kodTSeliProd,
				codeSellerTreasury: dopSvFHZH1?.infProdZaGosKazn?.kodKaznachProd,
				nameSellerTreasury: dopSvFHZH1?.infProdZaGosKazn?.naimKaznachProd
			},
			factorInfo: dopSvFHZH1?.svFaktor ? me.factorInfo(dopSvFHZH1.svFaktor) : null,
			assignmentBasis: {
				name: null,
				number: null,
				date: null,
				additionalInfo: null,
				baseId: null
			},
			govAgreementId: dopSvFHZH1?.idGosKon,
			currencyName: null,
			exchangeRate: null,
			infoInvoice: null
		};
	},
	transferInformation: function (svPer: SvProdPer['svPer']) {
		const rabOrgProd = svPer?.svLitsPer && 'rabOrgProd' in svPer.svLitsPer ? svPer.svLitsPer.rabOrgProd : null;
		const predOrgPer =
			svPer?.svLitsPer &&
			'inLitso' in svPer.svLitsPer &&
			svPer.svLitsPer.inLitso &&
			'predOrgPer' in svPer.svLitsPer.inLitso
				? svPer.svLitsPer.inLitso.predOrgPer
				: null;
		const flPer =
			svPer?.svLitsPer &&
			'inLitso' in svPer.svLitsPer &&
			svPer.svLitsPer.inLitso &&
			'flPer' in svPer.svLitsPer.inLitso
				? svPer.svLitsPer.inLitso.flPer
				: null;
		return {
			baseShipmentOfGoods:
				svPer && 'osnPer' in svPer
					? svPer.osnPer?.map((osn) => ({
							name: osn.rekvNaimDok,
							number: osn.rekvNomerDok,
							date: osn.rekvDataDok,
							additionalInfo: null,
							baseId: osn.rIdSistHranD
					  }))
					: null,
			personHandingGoods: {
				sellerOrganizationWorker: {
					fullName: {
						lastName: rabOrgProd?.fio?.familiya,
						firstName: rabOrgProd?.fio?.imya,
						middleName: rabOrgProd?.fio?.otchestvo
					},
					position: rabOrgProd?.dolzhnost,
					additInfo: rabOrgProd?.inieSved,
					baseAuthority: null
				},
				anotherPerson: {
					shippedGoodsWorker: {
						fullName: {
							lastName: predOrgPer?.fio?.familiya,
							firstName: predOrgPer?.fio?.imya,
							middleName: predOrgPer?.fio?.otchestvo
						},
						position: predOrgPer?.dolzhnost,
						additionalInfo: predOrgPer?.inieSved,
						orgName: predOrgPer?.naimOrgPer,
						rightPresentResults: null,
						authority: null
					},
					individualPersonShipsGoogs: {
						fullName: {
							lastName: flPer?.fio?.familiya,
							firstName: flPer?.fio?.imya,
							middleName: flPer?.fio?.otchestvo
						},
						additionalInfo: flPer?.inieSved,
						authority: null
					}
				}
			},
			transportAndCargo: {
				wayBill: [],
				info: null
			},
			thingTransferInfo: {
				date: svPer?.svPerVeschi?.dataPerVesch,
				info: svPer?.svPerVeschi?.svPerVesch
			},
			contentAction: svPer?.sodOper,
			typeAction: svPer?.vidOper,
			shippingDate: svPer?.dataPer,
			startingDate: svPer?.dataNachPer,
			endingDate: svPer?.dataOkonPer
		};
	},
	informationOfTheFactOfeconomicLife: (infPolFHZH3: SvProdPer['infPolFHZH3']) => {
		return {
			textInfos: infPolFHZH3?.tekstInf?.map((tekstInf) => ({
				identifier: tekstInf?.identif,
				value: tekstInf?.znachen
			})),
			fileId: infPolFHZH3?.idFaylInfPol
		};
	},
	signer: (podpisant: Dokument['podpisant']) => {
		return podpisant.map((podpisant) => {
			const orgType = edi.core.getUserData().org.orgType;
			switch (orgType) {
				case edi.constants.ORGANIZATION_TYPES.LEGAL_ENTITY: {
					return {
						legalEntity: {
							fullName: {
								lastName: podpisant?.fio?.familiya,
								firstName: podpisant?.fio?.imya,
								middleName: podpisant?.fio?.otchestvo
							},
							certificateRegistrationIP: null,
							tin: null,
							orgName: null,
							position: podpisant?.dolzhn,
							additionalData: null
						}
					};
				}
				case edi.constants.ORGANIZATION_TYPES.INDIVIDUAL: {
					return {
						individual: {
							name: {
								lastName: podpisant.fio.familiya,
								firstName: podpisant.fio.imya,
								middleName: podpisant.fio.otchestvo
							},
							certificateRegistrationIP: null,
							tin: null,
							additInfo: null
						}
					};
				}
				case edi.constants.ORGANIZATION_TYPES.PERSON: {
					return {
						individualPerson: {
							name: {
								lastName: podpisant.fio.familiya,
								firstName: podpisant.fio.imya,
								middleName: podpisant.fio.otchestvo
							},
							tin: null,
							defTin: null,
							certificateRegistrationIP: null,
							additInfo: null
						}
					};
				}
			}
		});
	},
	document: (dokument: Dokument) => ({
		invoiceTable: converterFrom502Nto501N.product(dokument?.tablSchFakt),
		invoice: {
			sellerInfo: converterFrom502Nto501N.seller(dokument?.svSchFakt.svProd),
			buyerInfo: converterFrom502Nto501N.buyer(dokument?.svSchFakt.svPokup),
			cargoFrom: converterFrom502Nto501N.cargoFrom(dokument?.svSchFakt.gruzOt),
			cargoReceiver: converterFrom502Nto501N.cargoReceiver(dokument?.svSchFakt.gruzPoluch),
			corrections: converterFrom502Nto501N.corrections(dokument?.svSchFakt.isprDok),
			info: converterFrom502Nto501N.info(dokument?.svSchFakt.infPolFHZH1),
			invoiceNumber: dokument?.svSchFakt?.nomerDok,
			invoiceDate: dokument?.svSchFakt?.dataDok,
			okv: dokument?.svSchFakt?.denIzm?.kodOKV,
			paymentInformation: converterFrom502Nto501N.paymentInformation(dokument?.svSchFakt.svPRD),
			additionalInfo: converterFrom502Nto501N.additionalInfo(dokument?.svSchFakt.dopSvFHZH1)
		},
		receiptPartDoc: {
			transferInformation:
				dokument?.svProdPer && 'svPer' in dokument.svProdPer
					? converterFrom502Nto501N.transferInformation(dokument.svProdPer.svPer)
					: null,
			informationOfTheFactOfeconomicLife:
				dokument?.svProdPer && 'infPolFHZH3' in dokument.svProdPer
					? converterFrom502Nto501N.informationOfTheFactOfeconomicLife(dokument.svProdPer.infPolFHZH3)
					: null
		},
		signer: converterFrom502Nto501N.signer(dokument?.podpisant),
		knd: dokument?.knd,
		function: dokument?.funktsiya,
		economiclifeDocName: dokument?.poFaktHZH,
		primaryDocName: dokument?.naimDokOpr,
		fileInvoiceCreationDate: dokument?.dataInfPr,
		fileInvoiceCreationTime: dokument?.vremInfPr,
		nameEconomicSubject: dokument?.naimEkonSubSost,
		baseFileInvoiceCreation: null,
		agreedStructureAdditionalInfo: dokument?.soglStrDopInf
	})
};
