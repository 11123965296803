import { PRICAT_EANCOM_V2_DOC_FLOW_TYPES } from '@Edi/modules/documents/PRICAT_EANCOM/configuration';

const actionRulesForPricatEancomModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {
		OUTGOING: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
					BUSINESS_STATE: [
						edi.constants.STATE.SENT,
						edi.constants.STATE.RECEIVER_REVIEW,
						edi.constants.STATE.COMPLETED,
						edi.constants.STATE.REJECTED,
						edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER
					]
				}
			]
		},
		INCOMING: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
					BUSINESS_STATE: [
						edi.constants.STATE.SENT,
						edi.constants.STATE.RECEIVER_REVIEW,
						edi.constants.STATE.COMPLETED,
						edi.constants.STATE.REJECTED,
						edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER
					]
				}
			]
		},
		INCOMING_FACTOR: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
					BUSINESS_STATE: [
						edi.constants.STATE.SENT,
						edi.constants.STATE.RECEIVER_REVIEW,
						edi.constants.STATE.COMPLETED,
						edi.constants.STATE.REJECTED,
						edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER
					]
				}
			]
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			],
			SINGLE_APPLY: []
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM],
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.PRICAT_EANCOM_DP_UTOCH_RECEIVER
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_IZVPOL]
			},
			{
				states: [
					edi.constants.STATE.DEBTOR_REVIEW,
					edi.constants.STATE.RECEIVER_REVIEW,
					edi.constants.STATE.READ
				],
				allow: function (documentData) {
					return (
						documentData.needSignatures ||
						edi.constants.LEGACY_DOCUMENTS.concat(edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM).some(
							(it) => it === documentData.docType
						)
					);
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.WAIT_SIGNATURE,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.SIGNED_BY_CONSUMER
			]
		},
		OUTGOING: {
			BUSINESS_STATE: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	CLARIFY: {},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		LOOP: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		INCOMING: {
			DOCUMENTS: [],
			BUSINESS_STATE: []
		},
		permissionChanging: [],
		conditions: []
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					//при получении дока по интеграции в этот атрибут пишется его номер 1, 2, 3...
					//вот так вот косвенно мы определяем что это интеграционный документ =\
					const isFromIntegration = !!edi.utils.getAttributeByName(
						documentData.attributes,
						'PRICAT_EANCOM_NUMBER'
					);
					const bpName = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
					const prohibited = isFromIntegration && bpName === edi.constants.DOCUMENT_BP_NAMES.PRICAT_EANCOM_V2;
					return !prohibited;
				}
			}
		]
	},
	COMPLETE: {},
	READ: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM],
				states: [edi.constants.STATE.WAIT_RECEIVER_DECISION],
				allow: true
			}
		]
	},
	READ_AND_COMPLETE: {},
	SEND: {},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	},
	CONVERT_TO_DRAFT: {},
	COPY: {
		OUTGOING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					const docFlowType = edi.utils.getAttributeByName(documentData?.attributes, 'doc_flow_type');
					const isPricatCorrection = docFlowType === PRICAT_EANCOM_V2_DOC_FLOW_TYPES.CORRECTION;
					const isPricatCancellation = docFlowType === PRICAT_EANCOM_V2_DOC_FLOW_TYPES.CANCELLATION;
					return !(isPricatCorrection || isPricatCancellation);
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM]: actionRulesForPricatEancomModule,
	[edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_IZVPOL]: actionRulesForPricatEancomModule
});

export { actionRulesForPricatEancomModule };
