import { loadPoaChain, LoadPoaChainProps, LoadPoaChainResult } from './loadPoaChain';
import { checkPoaChainState, CheckPoaChainStateProps, CheckPoaChainStateResult } from './checkPoaChainState';
import { convertChainPoaData, ConvertChainPoaDataProps, ConvertChainPoaDataResult } from './convertChainPoaData';
import {
	loadParentalPoa,
	LoadParentalPoaProps,
	LoadParentalPoaResult
} from '@Edi/modules/power_of_attorney/services/loadParentalPoa';

interface powerOfAttorneyServices {
	loadPoaChain: (props: LoadPoaChainProps) => LoadPoaChainResult;
	checkPoaChainState: (props: CheckPoaChainStateProps) => CheckPoaChainStateResult;
	convertChainPoaData: (props: ConvertChainPoaDataProps) => ConvertChainPoaDataResult;
	loadParentalPoa: (props: LoadParentalPoaProps) => LoadParentalPoaResult;
}
export const powerOfAttorneyServices: powerOfAttorneyServices = {
	loadPoaChain,
	checkPoaChainState,
	loadParentalPoa,
	convertChainPoaData
};
