import { createContainer } from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { BADGE_ALIGN, BADGE_PRESETS, PTYPE_BADGE } from '@UIkit/components/badge/Badge';
import { createVersionTooltipContent } from '@Components/topPanel';
import './HeaderControls.scss';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';

Ext.apply(BUTTON_CLS, {
	nav_button: 'ui-button-nav',
	documents_button: 'ui-button-document',
	notifications_button: 'ui-button-notifications',
	orgs_switch_button: 'ui-button-orgs_switch_button',
	user_button: 'ui-button-user_button'
});

const baseCls = 'ui-headercontrols';

Ext.define('UI.components.HeaderControls', {
	extend: 'Ext.container.Container',
	baseCls,

	layout: {
		type: 'hbox',
		align: 'middle'
	},

	constructor(config) {
		this.callParent([config]);
	},
	initComponent: function () {
		var me = this;

		me.beforeInit();
		me.callParent(arguments);
		me.afterInit();
	},

	beforeInit: function () {},
	afterInit: function () {
		const me = this;
		edi.events.modules.on('modulesLoad', function (eventData) {
			// Кнопка навигации
			me.add(me.createNavButton());
			// Лого
			me.add(me.getLogo());
			// отбивка к правому краю
			me.add({ xtype: 'tbspacer', flex: 1 });
			if (!window.buildVersion.isAdmin) {
				// кнопка документов
				me.add(me.createDocumentsButton());
				// кнопка уведомлений
				me.add(edi.notifications ? edi.notifications.getActionButton() : null);
			}
			// переключение организаций
			me.add(me.createOrganizationButton(eventData));
			// кнопка user info
			me.add(me.createUserButton());
		});
	},

	createNavButton: function () {
		var me = this;

		const navButton =
			edi.constants.LEFT_NAVIGATION_V2 && !me.isHideNavButton
				? createButton({
						cls: [BUTTON_CLS.light, BUTTON_CLS.rounded, BUTTON_CLS.nav_button],
						tooltip: edi.i18n.getMessage('navigation.expand.btn'),
						glyph: edi.constants.ICONS.MENU,
						handler: function () {
							var nav = edi.core.getNavigationPanel();
							this.setTooltip(
								edi.i18n.getMessage(nav.opened ? 'navigation.expand.btn' : 'navigation.collapse.btn')
							);
							nav.toggleNavigation();
						}
				  })
				: null;

		me.navButton = navButton;

		return createContainer({
			margin: '0 0 0 8',
			items: [navButton]
		});
	},

	getLogo: function () {
		return createContainer({
			html: edi.constants.DEFAULT.COMPONENTS_SETTINGS.HEADER_BAR_LOGO || edi.core.getBuildVersion().title,
			listeners: {
				afterrender(comp) {
					// клик по лого вызывает переход к модулю из константы
					if (edi.constants.DEFAULT.COMPONENTS_SETTINGS.HEADER_BAR_LOGO_MODULE_CLICK) {
						setTimeout(function () {
							const header = comp.el.dom;
							if (header) {
								comp.addCls('pointer');
								header.addEventListener('click', function () {
									edi.core.openModule(
										edi.constants.DEFAULT.COMPONENTS_SETTINGS.HEADER_BAR_LOGO_MODULE_CLICK
									);
								});
							}
						}, 1);
					}
				}
			}
		});
	},

	createUserButton: function () {
		const me = this;
		const userData = edi.core.getUserData();
		let userOrg, orgValues;
		if (!edi.constants.HIDE_USER_ORG_DATA) {
			userOrg = edi.core.getUserData().org;
			orgValues = edi.converters.convertOrgToPartie(userOrg);
			edi.methods.header.bankAccountInfo(userOrg, orgValues);
		}
		const additonalConf = edi.methods.hasOwnProperty('getAdditionalFNSModalConf')
			? edi.methods.getAdditionalFNSModalConf()
			: {};
		const FNSModalConf = Ext.merge(
			edi.selectors.getFNSModalConf(
				{},
				{
					main: {
						company_data: {
							fields: {
								fns_id: edi.selectors.defaults.fns_id
							}
						}
					}
				}
			),
			additonalConf
		);

		const userBtn = createButton({
			width: 200,
			cls: [BUTTON_CLS.light, BUTTON_CLS.rounded, BUTTON_CLS.user_button],
			glyph: edi.constants.ICONS.USER_CIRCLE,
			tooltip: edi.i18n.getMessage('user.profile'),
			text: edi.utils.formatString('{0} {1}', [userData.firstName, userData.lastName]),
			menu: {
				plain: true,
				hideMode: 'display',
				items: [
					edi.core.getModule('user.profile') && edi.permissions.hasPermission('READ_USER_PROFILE')
						? {
								text: edi.i18n.getMessage('user.profile'),
								handler: function () {
									edi.core.openModule('user.profile', { id: 'user.profile' });
								}
						  }
						: null,
					edi.core.getModule('powers.of.attorney.list') &&
					edi.permissions.hasPermission('READ_POWER_OF_ATTORNEY') &&
					!edi.constants.IS_EDIWEB_CLIENT
						? {
								text: edi.i18n.getMessage('powers.of.attorney.list'),
								handler: function () {
									edi.core.openModule('powers.of.attorney.list');
								}
						  }
						: null,
					edi.constants.HIDE_USER_ORG_DATA
						? null
						: {
								text: edi.i18n.getMessage('company.details'),
								handler: function () {
									const company = createOrgSelector({
										title: 'company.details',
										modalConf: Object.assign({ readOnly: true }, FNSModalConf),
										readOnly: true,
										fieldValues: orgValues,
										selectedOrgValues: orgValues
									});
									company.showModalCompanyControl();
								}
						  },
					{
						text: edi.i18n.getMessage('version.version') + ': ' + edi.core.getBuildVersion().version,
						handler: function () {
							edi.core.showInfo(createVersionTooltipContent());
						}
					},
					edi.debug && edi.core.getDebugConfig().enabled
						? {
								text: edi.i18n.getMessage('debug.tools'),
								handler: edi.debug.showDebugWindow
						  }
						: null,
					{
						text: edi.i18n.getMessage('user.logout'),
						handler: edi.login.logout
					}
				]
			}
		});

		me.userBtn = userBtn;

		return userBtn;
	},

	createOrganizationButton: function (eventData) {
		if (eventData && eventData.failure) {
			return;
		}

		const me = this,
			userData = edi.core.getUserData(),
			orgs = edi.methods.filiations.sortOrgsByFiliations(userData.orgs),
			menuItems = [],
			currentOrg = orgs.filter((i) => i.id === edi.core.getUserOrgID())[0];

		//Перенесла старую логику КП отображения комбобокса с Орг
		if (
			orgs.length === 1 &&
			(orgs[0].orgType === edi.constants.ORGANIZATION_TYPES.PERSON ||
				orgs[0].orgType === edi.constants.ORGANIZATION_TYPES.SELF_EMPLOYED)
		) {
			return;
		}

		orgs.forEach((item) => {
			menuItems.push({
				orgId: item.id,
				text: item.visualName,
				handler: function (__self) {
					const orgId = __self.orgId;

					if (orgId && orgId != edi.core.getUserOrgID()) {
						edi.login.setCurrentOrganization(
							orgId,
							function (failed) {
								if (!failed) {
									document.location.reload();
								} else {
									orgBtn.setText(__self.text);
								}
							},
							undefined,
							true
						);
					}
				}
			});
		});

		const orgBtn = createButton({
			width: 200,
			cls: [BUTTON_CLS.light, BUTTON_CLS.rounded, BUTTON_CLS.orgs_switch_button],
			text: currentOrg.visualName,
			menu: {
				plain: true,
				hideMode: 'display',
				items: menuItems
			}
		});

		me.orgBtn = orgBtn;

		return orgBtn;
	},

	createDocumentsButton: function () {
		const me = this,
			documentsBtn = createButton({
				cls: [BUTTON_CLS.light, BUTTON_CLS.rounded, BUTTON_CLS.documents_button],
				plugins: [
					{
						ptype: PTYPE_BADGE,
						cls: BADGE_PRESETS.buttonCounter,
						fixed: true,
						align: BADGE_ALIGN.trc
					}
				],
				glyph: edi.constants.ICONS.SIGN_NEW,
				handler: function () {
					sendLastNotifyTime();
					edi.core.openModule('document.statuses');
				}
			});

		let counter = 0;
		const updateCounter = (data) => {
			if (data.total != 0) {
				counter = data.total;
			}
		};
		const getCountDocuments = new Promise((resolve, reject) => {
			if (edi.permissions.hasPermission('READ_UNSIGNED_NOTICES')) {
				edi.rest.sendRequest(
					edi.rest.services.COUNT_NOTICES.GET,
					'GET',
					null,
					function (data) {
						if (data.success) {
							resolve(data);
						}
					},
					reject
				);
			}
		});

		if (edi.permissions.hasPermission('READ_UNSIGNED_NOTICES')) {
			getCountDocuments.then(updateCounter).finally(() => {
				documentsBtn.badgeCmp.setText(counter > 0 ? counter : null);
			});
		}

		// Ожидается рефакторинг и замена компонента на tooltip из UIKit
		const signNotifyTooltip = Ext.create('Ext.container.Container', {
			hidden: true,
			// renderTo: documentsBtn.getEl(),
			width: 30,
			margin: 0,
			items: [
				Ext.create('Ext.Component', {
					cls: 'edi-tooltip-notify',
					html: '<span>' + edi.i18n.getMessage('tlp.msg.sign.notify') + '</span>',
					listeners: {
						afterrender: function (component) {
							component.getEl().on('click', function (event) {
								event.stopPropagation();
								sendLastNotifyTime();
							});
						}
					}
				}),
				createButton({
					cls: [BUTTON_CLS.icon, 'edi-tooltip-notify-close-btn'],
					glyph: edi.constants.ICONS.CLOSE,
					handler(btn, event) {
						event.stopPropagation();
						sendLastNotifyTime();
						edi.events.notificationTimer.fireEvent('update');
					}
				})
			]
		});
		// ---

		var sendLastNotifyTime = function () {
			if (window.buildVersion.isAdmin === true) {
				return;
			}

			const lastNotifyTime = new Date().getTime();
			edi.core.setExtraData('user.lastNotifyTime', lastNotifyTime, () => {
				signNotifyTooltip.setVisible(false);
			});
		};
		if (edi.permissions.hasPermission('ACCEPT_UNSIGNED_NOTICES_GROUP')) {
			const signNotifyInterval = edi.utils.getObjectProperty(
				edi.core.getUserData(),
				'org.attributes.signNotifyInterval.value'
			);
			let updateTimeoutSignNotifyInterval = signNotifyInterval * 60000;

			let timer;
			const updateNotifications = function () {
				getCountDocuments
					.then((data) => {
						counter += data.total;

						signNotifyTooltip.setVisible(counter > 0);
						edi.events.notificationTimer.on('update', function () {
							timer && window.clearTimeout(timer);
							timer = window.setTimeout(updateNotifications, updateTimeoutSignNotifyInterval);
						});

						timer && window.clearTimeout(timer);
						timer = window.setTimeout(updateNotifications, updateTimeoutSignNotifyInterval);
					})
					.finally(() => {
						documentsBtn.badgeCmp.setText(counter > 0 ? counter : null);
					});
			};

			if (
				signNotifyInterval &&
				signNotifyInterval !== '0' &&
				edi.permissions.hasPermission('READ_UNSIGNED_NOTICES')
			) {
				updateNotifications();
			}
		}

		return createContainer({
			margin: '0 24 0 0',
			items: [!edi.constants.hasOwnProperty('PERMISSION_GROUP_TAG') ? documentsBtn : null, signNotifyTooltip]
		});
	}
});

const createHeaderControls = (config) => new UI.components.HeaderControls(config);

export { createHeaderControls };
