import { documentPackageSelectors } from '@Edi/modules/documentPackages/selectors';

const superDocumentPackageSelectors = documentPackageSelectors;
const superGetFieldsMap = documentPackageSelectors.getFieldsMap;
const superGetModalConf = documentPackageSelectors.getModalConf;

superDocumentPackageSelectors.getFieldsMap = function (fieldsMapPrefix: string): AnyObject {
	return Object.assign({}, superGetFieldsMap(fieldsMapPrefix), {
		individual_inn: `${fieldsMapPrefix}.inn`,
		individual_lastname: `${fieldsMapPrefix}.lastName`,
		individual_firstname: `${fieldsMapPrefix}.firstName`,
		individual_patronymicname: `${fieldsMapPrefix}.middleName`
	});
};

superDocumentPackageSelectors.getModalConf = function (): AnyObject {
	const defaults = edi.selectors.defaults;

	return {
		title: edi.i18n.getMessage('documents.order.buyer'),
		tabs: {
			main: Object.assign({}, superGetModalConf()?.tabs?.main, {
				company_name: Object.assign({}, defaults.company_name, {
					allowBlank: true
				}),
				company_inn: Object.assign({}, defaults.company_inn, {
					allowBlank: true
				})
			})
		}
	};
};
