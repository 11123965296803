// @ts-ignore
import { createCombo, createDateField, createNumberField, createTextField } from '@UIkit/components/fields';
import { documentFunctionsComboItems } from '../../methods';
// @ts-ignore
import { createContainer, createFieldBlock } from '@UIkit/components/panels';

export interface MainDataBlockProps {
	documentContent: AnyObject;
	getCurrentUPDState: () => {
		isEdit: boolean;
		isCorrect: boolean;
		isCopy: boolean;
		bpName: string;
		isNumberAutoGenerated: boolean;
	};
	onUPDFunctionChange?: (updFunction: string) => void;
	onUPDNumberChange?: (number: string) => void;
	onUPDDateChange?: (date: Date) => void;
	[key: string]: any;
}
export class MainDataBlock {
	fields: {
		invoiceDate?: ExtComponent;
		invoiceCurrency?: ExtComponent;
		invoiceType?: ExtComponent;
		invoiceNumber?: ExtComponent;
		correctionNumber?: ExtComponent;

		[fieldName: string]: ExtComponent | undefined;
	};

	currencyName: string;
	props: MainDataBlockProps;

	constructor(props: MainDataBlockProps) {
		const me = this;
		me.modifyConfig(props);
	}

	modifyConfig(props: MainDataBlockProps) {
		const me = this;

		me.fields = {};
		me.props = props;
	}

	createInvoiceDateField(): ExtComponent {
		const me = this;
		const { isEdit, isCorrect } = me.props.getCurrentUPDState();

		return (me.fields.invoiceDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			readOnly: isCorrect,
			value:
				isEdit || isCorrect
					? edi.utils.getObjectProperty(me.props.documentContent, 'dokument.svSchFakt.dataDok')
					: new Date(),
			name: 'dokument.svSchFakt.dataDok',
			listeners: {
				change: function (comp: ExtComponent, newValue: Date) {
					if (typeof me.props.onUPDDateChange === 'function') me.props.onUPDDateChange(newValue);
				}
			}
		}));
	}

	createInvoiceCurrencyField(): ExtComponent {
		const me = this;

		return (me.fields.invoiceCurrency = createCombo({
			fieldLabel: edi.i18n.getMessage('invoice.currency'),
			valueSrc: me.props.documentContent,
			value:
				edi.utils.getObjectProperty(me.props.documentContent, 'dokument.svSchFakt.denIzm.kodOKV') ||
				edi.utils.getOkv({
					charCode: edi.constants.DEFAULT.CURRENCY
				}).codeStr,
			name: 'dokument.svSchFakt.denIzm.kodOKV',
			store: edi.stores.initOkvCurrencyStore(),
			anyMatch: true,
			displayField: 'displayName',
			valueField: 'codeStr',
			allowBlank: false,
			listeners: {
				select: function (combo: ExtComponent, record: AnyObject) {
					if (record && record.get) {
						me.currencyName = record.get('name');
					}
				},
				afterrender: function (combo: ExtComponent) {
					const record = combo.getSelectedRecord();
					if (record && record.get) {
						me.currencyName = record.get('name');
					}
				}
			}
		}));
	}

	createInvoiceTypeField(): ExtComponent | null {
		const me = this;
		const { isEdit, isCorrect, isCopy, bpName } = me.props.getCurrentUPDState();

		return !((isEdit || isCorrect || isCopy) && bpName)
			? (me.fields.invoiceType = createCombo({
					fieldLabel: edi.i18n.getMessage('document.type'),
					emptyText: '',
					displayField: 'name',
					valueField: 'id',
					allowBlank: false,
					store: edi.stores.createSimpleInlineStore(documentFunctionsComboItems(), function (id: string) {
						return edi.i18n.getMessage('documents.fns_upd.type.' + id);
					}),
					listeners: {
						change: (combo: ExtComponent, value: string) => {
							if (typeof me.props.onUPDFunctionChange === 'function') {
								me.props.onUPDFunctionChange(value);
							}
						}
					},
					name: 'dokument.funktsiya'
			  }))
			: null;
	}

	createInvoiceNumberField(): ExtComponent {
		const me = this;
		const { isNumberAutoGenerated, isCorrect, isEdit, isCopy } = me.props.getCurrentUPDState();
		return (me.fields.invoiceNumber = createTextField({
			fieldLabel: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('document.form.number'),
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			maxLength: 35,
			readOnly: isCorrect,
			value:
				isEdit || isCorrect || isCopy
					? edi.utils.getObjectProperty(me.props.documentContent, 'dokument.svSchFakt.nomerDok') +
					  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
					: undefined,
			name: 'dokument.svSchFakt.nomerDok',
			listeners: {
				change: function (comp: ExtComponent, newValue: string) {
					if (typeof me.props.onUPDNumberChange === 'function') {
						me.props.onUPDNumberChange(newValue);
					}
				}
			}
		}));
	}

	createCorrectionNumberField(): ExtComponent {
		const me = this;
		const { isEdit, isCopy } = me.props.getCurrentUPDState();
		const name = 'dokument.svSchFakt.isprDok.nomIspr';
		const correctionNumber = edi.utils.getObjectProperty(me.props.documentContent, name);

		return (me.fields.correctionNumber = createNumberField({
			fieldLabel: edi.i18n.getMessage('documents.column.correction.number'),
			maxLength: 3,
			allowBlank: false,
			value: isEdit || isCopy ? correctionNumber : correctionNumber + 1,
			name
		}));
	}

	createCorrectionNumberDate(): ExtComponent {
		const me = this;
		const { isEdit, isCopy } = me.props.getCurrentUPDState();

		const name = 'dokument.svSchFakt.isprDok.dataIspr';
		return createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			value: isEdit || isCopy ? edi.utils.getObjectProperty(me.props.documentContent, name) : new Date(),
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			name
		});
	}

	createView(): ExtComponent {
		const me = this;
		const { bpName, isCopy, isEdit } = me.props.getCurrentUPDState();
		return createFieldBlock({
			title: isCopy || isEdit ? edi.i18n.getMessage('documents.fns_upd.type.' + bpName) : undefined,
			layout: {
				type: 'grid',
				gap: 16,
				area: [[3, 4, 2], [4]]
			},
			items: [
				me.createInvoiceTypeField(),
				me.createInvoiceNumberField(),
				me.createInvoiceDateField(),
				me.createInvoiceCurrencyField()
			]
		});
	}

	createCorrectionView(): ExtComponent {
		const me = this;
		const { bpName } = me.props.getCurrentUPDState();
		return createContainer({
			layout: {
				type: 'grid',
				area: [12, 12, 4]
			},
			items: [
				createFieldBlock({
					title: bpName ? edi.i18n.getMessage('documents.fns_upd.type.' + bpName) : undefined,
					layout: {
						type: 'grid',
						gap: 16,
						area: [[4, 2]]
					},
					items: [me.createInvoiceNumberField(), me.createInvoiceDateField()]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('documents.fns_upd.invoice.corrections'),
					layout: {
						type: 'grid',
						gap: 16,
						area: [[4, 2]]
					},
					items: [me.createCorrectionNumberField(), me.createCorrectionNumberDate()]
				}),
				me.createInvoiceCurrencyField()
			]
		}) as ExtComponent;
	}
}
