import { createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createMenuItem } from '@Components/miscComponents';
import { createCreateSaveButton } from '@Components/buttons';
import {
	createCombo,
	createTextField,
	createDateField,
	createDateRangeField,
	createLabel
} from '@UIkit/components/fields';
import { moduleMethods } from './methods';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import {
	createContainer,
	createFieldBlock,
	createFieldSet,
	createFormContainer,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createRowsBlock } from '@Components/createRows/create.rows';
import { createHiddenField } from '@UIkit/components/fields_old/Hidden';
import { createSimpleSelector } from '@UIkit/components/selectors';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createRowWithNestedRows } from '@Components/RowWithNestedRows/RowWithNestedRows';
import { createHelpElementPlugin, createInfoElementPlugin } from '@Core/methods';
import { ReTrustBlock, ReTrustViewModelPoaStates } from '../components/reTrustBlock/reTrustBlock';

/**
 * Класс для создания и редактирования машиночитаемых доверенностей (МЧД)
 * @author Petr Manyahin
 */
Ext.namespace('edi.modules');
edi.modules['power.of.attorney.create'] = function () {
	let moduleData,
		docId,
		docHeader,
		docContent,
		isEdit = false,
		isCopy = false,
		isPeredov = false,
		parentalPoA,
		reTrustBlockInstance,
		validityDateRange,
		form,
		userData = edi.core.getUserData(),
		confidantIndividualForm,
		confidantCompanyForm,
		principalIndivEntrepreneurForm,
		principalOptionalCompanyDataSSBlock,
		principalCompanyPersonSSBlock,
		principalCompanyForm,
		saveBtn,
		validLabel,
		poaCollectivePowersWarn,
		poaCollectivePowersCombo;

	/**
	 * Main module initialization methodДо
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		//когда открываем из грида, то там своя кастомная вьюха и айди лежит в docId
		//а когда открываем из формы просмотра, то там уже нормальный хэдер
		docId = moduleData.initData.data?.docId || moduleData.initData.data?.id;
		isCopy = !!moduleData.initData.isCopy;
		if (isCopy) {
			docHeader = Ext.clone(moduleData.initData.data);
			docContent = docHeader.data;
			if (!!docContent?.document?.dover?.svDov?.doverRegNumber) {
				delete docContent.document.dover.svDov.doverRegNumber;
			}
			if (!!docContent?.document?.dover?.svDov?.vnNomDover) {
				docContent.document.dover.svDov.vnNomDover += '-' + edi.i18n.getMessage('document.number.copied');
			}
			const { dover, svDoverit } = moduleMethods.getNameKeys(isPeredov);
			let principalBlock = docContent?.document?.[dover]?.[svDoverit];
			if (principalBlock) {
				if (!Array.isArray(principalBlock)) {
					principalBlock = [principalBlock];
				}
				principalBlock.forEach(function (item) {
					delete item.podpisant;
				});
			}
		} else {
			isEdit = !!docId;
		}

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const setIsPeredovState = function (isPeredovState) {
		isPeredov = isPeredovState;
	};

	const checkPrincipalValid = function () {
		let formData = form?.getForm();
		if (formData) {
			const { dover, svDoverit, tipDover: tipDoverKey } = moduleMethods.getNameKeys(isPeredov);
			let fields = formData.getFields().getRange() || [],
				tipDover = fields.find((field) => field.name === `data.document.${dover}.${svDoverit}.${tipDoverKey}`),
				svRosOrg = form.down("[itemId ='svRosOrg']"),
				litsoBezDov = form.down("[itemId ='litsoBezDov']"),
				ipDover = form.down("[itemId ='ipDover']"),
				svedFL = form.down("[itemId ='svedFL']");

			return !!(
				tipDover &&
				((svRosOrg?.isValid() && litsoBezDov?.isValid()) || (ipDover?.isValid() && svedFL?.isValid()))
			);
		}
		return false;
	};

	const checkConfidantValid = function () {
		let formData = form?.getForm();

		if (formData) {
			const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
			let fields = formData.getFields().getRange() || [],
				tipPred = fields.find((field) => field.name === `data.document.${dover}.${svUpPred}.tipPred`),
				svedFizL = form.down("[itemId ='svedFizL']"),
				svFl = form.down("[itemId ='svOrgsvFL']"),
				svOrgTip = form.down("[itemId ='svOrgSvOrgTip']");

			return !!(tipPred && (svedFizL?.isValid() || (svFl?.isValid() && svOrgTip?.isValid())));
		}
		return false;
	};

	const checkValid = function () {
		if (form) {
			form.isValid();
		}

		let isValid = form
			? checkPrincipalValid() &&
			  checkConfidantValid() &&
			  !form.hasInvalidField() &&
			  !poaCollectivePowersWarn.isVisible()
			: true;
		saveBtn.setDisabled(!isValid);

		return isValid;
	};

	/********
	 * структура массива доверенных лиц (svUpPred)
	 *  нужна как для массива, так и для одиночной записи
	 ********/

	const confidantDataIndividual = [
		'svedFizL.snils',
		'svedFizL.innfl',
		'svedFizL.svedFL.gender',
		'svedFizL.fio.firstName',
		'svedFizL.fio.middleName',
		'svedFizL.fio.lastName',
		'svedFizL.svedFL.dateOfBirth',
		'svedFizL.svedFL.grazhdanstvo',
		'svedFizL.svedFL.prGrazhd',
		'svedFizL.svedFL.contactTelephone',
		'svedFizL.svedFL.adrMZH.adrRF',
		'svedFizL.svedFL.adrMZH.region',
		'svedFizL.svedFL.placeOfBirth',
		'svedFizL.svedFL.udLichnFL.documentCode',
		'svedFizL.svedFL.udLichnFL.serNomDok',
		'svedFizL.svedFL.udLichnFL.dataDok',
		'svedFizL.svedFL.udLichnFL.vidDok',
		'svedFizL.svedFL.udLichnFL.kodVidDok'
	];

	const confidantDataIndividualRequired = [
		'svedFizL.fio.lastName',
		'svedFizL.fio.firstName',
		'svedFizL.snils',
		'svedFizL.innfl',
		'svedFizL.svedFL.gender',
		'svedFizL.svedFL.dateOfBirth',
		'svedFizL.svedFL.prGrazhd'
	];

	const confidantDataCompany = [
		'svOrg.svOrgTip.naimOrg',
		'svOrg.svOrgTip.innyul',
		'svOrg.svOrgTip.kpp',
		'svOrg.svOrgTip.ogrn',
		'svOrg.svOrgTip.adrReg.region',
		'svOrg.svOrgTip.adrReg.adrRF',
		'svOrg.svOrgTip.adrMNFakt.region',
		'svOrg.svOrgTip.adrMNFakt.adrRF',
		'svOrg.svOrgTip.naimUchrDok',
		'svOrg.svOrgTip.kontaktTlf',
		'svOrg.svOrgTip.regNomer',
		'svOrg.svFL.fio.firstName',
		'svOrg.svFL.fio.middleName',
		'svOrg.svFL.fio.lastName',
		'svOrg.svFL.innfl',
		'svOrg.svFL.snils',
		'svOrg.svFL.svedFL.gender',
		'svOrg.svFL.svedFL.placeOfBirth',
		'svOrg.svFL.svedFL.adrMZH.region',
		'svOrg.svFL.svedFL.adrMZH.adrRF',
		'svOrg.svFL.svedFL.dateOfBirth',
		'svOrg.svFL.svedFL.grazhdanstvo',
		'svOrg.svFL.svedFL.contactTelephone',
		'svOrg.svFL.svedFL.prGrazhd',
		'svOrg.svFL.svedFL.udLichnFL.documentCode',
		'svOrg.svFL.svedFL.udLichnFL.serNomDok',
		'svOrg.svFL.svedFL.udLichnFL.dataDok',
		'svOrg.svFL.svedFL.udLichnFL.vidDok',
		'svOrg.svFL.svedFL.udLichnFL.kodVidDok'
	];

	const confidantDataCompanyRequired = [
		'svOrg.svOrgTip.naimOrg',
		'svOrg.svOrgTip.innyul',
		'svOrg.svOrgTip.kpp',
		'svOrg.svOrgTip.ogrn',
		'svOrg.svFL.fio.lastName',
		'svOrg.svFL.fio.firstName',
		'svOrg.svFL.fio.middleName',
		'svOrg.svFL.innfl',
		'svOrg.svFL.snils',
		'svOrg.svFL.svedFL.gender',
		'svOrg.svFL.svedFL.dateOfBirth',
		'svOrg.svFL.svedFL.prGrazhd'
	];

	const confidantDataIndivEntrepreneur = [
		'svedIP.naimIP',
		'svedIP.innfl',
		'svedIP.snils',
		'svedIP.ogrnip',
		'svedIP.fio.firstName',
		'svedIP.fio.middleName',
		'svedIP.fio.lastName',
		'svedIP.svedFL.gender',
		'svedIP.svedFL.placeOfBirth',
		'svedIP.svedFL.adrMZH.region',
		'svedIP.svedFL.adrMZH.adrRF',
		'svedIP.svedFL.contactTelephone',
		'svedIP.svedFL.dateOfBirth',
		'svedIP.svedFL.grazhdanstvo',
		'svedIP.svedFL.prGrazhd',
		'svedIP.svedFL.udLichnFL.documentCode',
		'svedIP.svedFL.udLichnFL.serNomDok',
		'svedIP.svedFL.udLichnFL.dataDok',
		'svedIP.svedFL.udLichnFL.vidDok',
		'svedIP.svedFL.udLichnFL.kodVidDok'
	];

	const principalDataCompany = [
		'rosOrgDover.svRosOrg.naimOrg',
		'rosOrgDover.svRosOrg.innyul',
		'rosOrgDover.svRosOrg.kpp',
		'rosOrgDover.svRosOrg.ogrn',
		'rosOrgDover.svRosOrg.adrRF',
		'rosOrgDover.svRosOrg.adrMNFakt',
		'rosOrgDover.svRosOrg.naimUchrDok',
		'rosOrgDover.svRosOrg.tlf',
		'rosOrgDover.litsoBezDov.svFL.svedFL.gender',
		'rosOrgDover.litsoBezDov.svFL.svedFL.placeOfBirth',
		'rosOrgDover.litsoBezDov.svFL.svedFL.adrMZH.region',
		'rosOrgDover.litsoBezDov.svFL.svedFL.adrMZH.adrRF',
		'rosOrgDover.litsoBezDov.svFL.svedFL.dateOfBirth',
		'rosOrgDover.litsoBezDov.svFL.svedFL.grazhdanstvo',
		'rosOrgDover.litsoBezDov.svFL.svedFL.contactTelephone',
		'rosOrgDover.litsoBezDov.svFL.svedFL.prGrazhd',
		'rosOrgDover.litsoBezDov.svFL.svedFL.udLichnFL.documentCode',
		'rosOrgDover.litsoBezDov.svFL.svedFL.udLichnFL.serNomDok',
		'rosOrgDover.litsoBezDov.svFL.svedFL.udLichnFL.dataDok',
		'rosOrgDover.litsoBezDov.svFL.svedFL.udLichnFL.vidDok',
		'rosOrgDover.litsoBezDov.svFL.svedFL.udLichnFL.kodVidDok',
		'rosOrgDover.litsoBezDov.svFL.dolzhnost',
		'rosOrgDover.litsoBezDov.svFL.snils',
		'rosOrgDover.litsoBezDov.svFL.innfl',
		'rosOrgDover.litsoBezDov.svFL.naimDokPoln',
		'rosOrgDover.litsoBezDov.svFL.rekvDokPdtv.dataVid',
		'rosOrgDover.litsoBezDov.svFL.rekvDokPdtv.kemVid',
		'rosOrgDover.litsoBezDov.svFL.rekvDokPdtv.svUdDok',

		'rosOrgDover.litsoBezDov.svOrg.adrMNFakt.adrRF',
		'rosOrgDover.litsoBezDov.svOrg.adrMNFakt.region',
		'rosOrgDover.litsoBezDov.svOrg.adrReg.adrRF',
		'rosOrgDover.litsoBezDov.svOrg.adrReg.region',
		'rosOrgDover.litsoBezDov.svOrg.innyul',
		'rosOrgDover.litsoBezDov.svOrg.kontaktTlf',
		'rosOrgDover.litsoBezDov.svOrg.kpp',
		'rosOrgDover.litsoBezDov.svOrg.naimOrg',
		'rosOrgDover.litsoBezDov.svOrg.naimUchrDok',
		'rosOrgDover.litsoBezDov.svOrg.ogrn',
		'rosOrgDover.litsoBezDov.svOrg.regNomer',

		'rosOrgDover.litsoBezDov.svOrg.optCompanyBlock'
	];

	const peredovPrincipalDataCompany = [
		'rosOrgPrdPoln.naimOrg',
		'rosOrgPrdPoln.innyul',
		'rosOrgPrdPoln.kpp',
		'rosOrgPrdPoln.ogrn',
		'rosOrgPrdPoln.adrRF',
		'rosOrgPrdPoln.adrMNFakt',
		'rosOrgPrdPoln.naimUchrDok',
		'rosOrgPrdPoln.tlf'
	];

	const principalDataCompanyRequired = [
		'rosOrgDover.svRosOrg.naimOrg',
		'rosOrgDover.svRosOrg.innyul',
		'rosOrgDover.svRosOrg.kpp',
		'rosOrgDover.svRosOrg.ogrn',
		'rosOrgDover.svRosOrg.adrRF',
		'rosOrgDover.litsoBezDov.svFL.svedFL.gender',
		'rosOrgDover.litsoBezDov.svFL.svedFL.dateOfBirth',
		'rosOrgDover.litsoBezDov.svFL.svedFL.grazhdanstvo',
		'rosOrgDover.litsoBezDov.svFL.dolzhnost',
		'rosOrgDover.litsoBezDov.svFL.snils',
		'rosOrgDover.litsoBezDov.svFL.naimDokPoln'
	];

	const peredovPrincipalDataCompanyRequired = ['rosOrgPrdPoln.naimOrg', 'rosOrgPrdPoln.innyul', 'rosOrgPrdPoln.kpp'];

	const getPrincipalDataCompany = () => {
		return isPeredov ? peredovPrincipalDataCompany : principalDataCompany;
	};

	const getPrincipalDataCompanyRequired = () => {
		return isPeredov ? peredovPrincipalDataCompanyRequired : principalDataCompanyRequired;
	};

	const principalDataIndividual = [
		'flDover.prDeesp',
		'flDover.dokNedeesp',
		'flDover.innfl',
		'flDover.snils',
		'flDover.fio.lastName',
		'flDover.fio.firstName',
		'flDover.fio.middleName',
		'flDover.svedFL.dateOfBirth',
		'flDover.svedFL.placeOfBirth',
		'flDover.svedFL.contactTelephone',
		'flDover.svedFL.gender',
		'flDover.svedFL.prGrazhd',
		'flDover.svedFL.grazhdanstvo',
		'flDover.svedFL.adrMZH.region',
		'flDover.svedFL.adrMZH.adrRF',
		'flDover.svedFL.udLichnFL.documentCode',
		'flDover.svedFL.udLichnFL.serNomDok',
		'flDover.svedFL.udLichnFL.dataDok',
		'flDover.svedFL.udLichnFL.vidDok',
		'flDover.svedFL.udLichnFL.kodVidDok',
		'flDover.svZakPred.svPoFL.innfl',
		'flDover.svZakPred.svPoFL.snils',
		'flDover.svZakPred.svPoFL.dokNedeesp',
		'flDover.svZakPred.svPoFL.rekvDokPdtv.dataVid',
		'flDover.svZakPred.svPoFL.rekvDokPdtv.kemVid',
		'flDover.svZakPred.svPoFL.rekvDokPdtv.svUdDok',
		'flDover.svZakPred.svPoFL.svedFL.dateOfBirth',
		'flDover.svZakPred.svPoFL.svedFL.placeOfBirth',
		'flDover.svZakPred.svPoFL.svedFL.contactTelephone',
		'flDover.svZakPred.svPoFL.svedFL.gender',
		'flDover.svZakPred.svPoFL.svedFL.prGrazhd',
		'flDover.svZakPred.svPoFL.svedFL.grazhdanstvo',
		'flDover.svZakPred.svPoFL.svedFL.adrMZH.region',
		'flDover.svZakPred.svPoFL.svedFL.adrMZH.adrRF',
		'flDover.svZakPred.svPoFL.svedFL.udLichnFL.documentCode',
		'flDover.svZakPred.svPoFL.svedFL.udLichnFL.serNomDok',
		'flDover.svZakPred.svPoFL.svedFL.udLichnFL.dataDok',
		'flDover.svZakPred.svPoFL.svedFL.udLichnFL.vidDok',
		'flDover.svZakPred.svPoFL.svedFL.udLichnFL.kodVidDok',

		'flDover.svZakPred.svOrg.adrMNFakt.adrRF',
		'flDover.svZakPred.svOrg.adrMNFakt.region',
		'flDover.svZakPred.svOrg.adrReg.adrRF',
		'flDover.svZakPred.svOrg.adrReg.region',
		'flDover.svZakPred.svOrg.innyul',
		'flDover.svZakPred.svOrg.kontaktTlf',
		'flDover.svZakPred.svOrg.kpp',
		'flDover.svZakPred.svOrg.naimOrg',
		'flDover.svZakPred.svOrg.naimUchrDok',
		'flDover.svZakPred.svOrg.ogrn',
		'flDover.svZakPred.svOrg.regNomer',

		'flDover.svZakPred.svOrg.optCompanyBlock'
	];

	const principalDataIndivEntrepreneur = [
		'ipDover.naimIP',
		'ipDover.ogrnip',
		'ipDover.innfl',
		'ipDover.snils',
		'ipDover.svedFL.gender',
		'ipDover.svedFL.placeOfBirth',
		'ipDover.svedFL.adrMZH.region',
		'ipDover.svedFL.adrMZH.adrRF',
		'ipDover.svedFL.contactTelephone',
		'ipDover.svedFL.dateOfBirth',
		'ipDover.svedFL.grazhdanstvo',
		'ipDover.svedFL.prGrazhd',
		'ipDover.svedFL.udLichnFL.documentCode',
		'ipDover.svedFL.udLichnFL.serNomDok',
		'ipDover.svedFL.udLichnFL.dataDok',
		'ipDover.svedFL.udLichnFL.vidDok',
		'ipDover.svedFL.udLichnFL.kodVidDok'
	];

	const peredovPrincipalDataIndivEntrepreneur = [
		'ipPrdPoln.naimIP',
		'ipPrdPoln.ogrnip',
		'ipPrdPoln.innfl',
		'ipPrdPoln.snils',
		'ipPrdPoln.svedFL.gender',
		'ipPrdPoln.svedFL.placeOfBirth',
		'ipPrdPoln.svedFL.adrMZH.region',
		'ipPrdPoln.svedFL.adrMZH.adrRF',
		'ipPrdPoln.svedFL.contactTelephone',
		'ipPrdPoln.svedFL.dateOfBirth',
		'ipPrdPoln.svedFL.grazhdanstvo',
		'ipPrdPoln.svedFL.prGrazhd',
		'ipPrdPoln.svedFL.udLichnFL.documentCode',
		'ipPrdPoln.svedFL.udLichnFL.serNomDok',
		'ipPrdPoln.svedFL.udLichnFL.dataDok',
		'ipPrdPoln.svedFL.udLichnFL.vidDok',
		'ipPrdPoln.svedFL.udLichnFL.kodVidDok'
	];

	const principalDataIndivEntrepreneurRequired = [
		'ipDover.naimIP',
		'ipDover.ogrnip',
		'ipDover.innfl',
		'ipDover.snils',
		'ipDover.svedFL.dateOfBirth',
		'ipDover.svedFL.gender',
		'ipDover.svedFL.prGrazhd'
	];

	const peredovPrincipalDataIndivEntrepreneurRequired = [
		'ipPrdPoln.naimIP',
		'ipPrdPoln.ogrnip',
		'ipPrdPoln.innfl',
		'ipPrdPoln.snils',
		'ipPrdPoln.svedFL.dateOfBirth',
		'ipPrdPoln.svedFL.gender',
		'ipPrdPoln.svedFL.prGrazhd'
	];

	const getPrincipalDataIndivEntrepreneur = () => {
		return isPeredov ? peredovPrincipalDataIndivEntrepreneur : principalDataIndivEntrepreneur;
	};

	const getPrincipalDataIndivEntrepreneurRequired = () => {
		return isPeredov ? peredovPrincipalDataIndivEntrepreneurRequired : principalDataIndivEntrepreneurRequired;
	};

	const optionalCompanyRequired = ['naimOrg', 'kpp', 'innyul', 'ogrn'];

	const fixIndividual = function (recordData) {
		if (recordData.prGrazhd === '1') {
			recordData.grazhdanstvo = '643';
		} else if (recordData.prGrazhd === '3') {
			recordData.grazhdanstvo = '000';
		}

		if (!recordData.adrMZH.adrRF && !recordData.adrMZH.region) {
			recordData.adrMZH = null;
		}

		if (!recordData.contactTelephone) {
			recordData.contactTelephone = null;
		}

		if (!recordData.placeOfBirth) {
			recordData.placeOfBirth = null;
		}

		if (
			!recordData.udLichnFL.documentCode &&
			!recordData.udLichnFL.serNomDok &&
			!recordData.udLichnFL.dataDok &&
			!recordData.udLichnFL.vidDok &&
			!recordData.udLichnFL.kodVidDok
		) {
			recordData.udLichnFL = undefined;
		}
	};

	const fixCompany = function (companyData) {
		if (!companyData.adrReg.adrRF && !companyData.adrReg.region) {
			companyData.adrReg = null;
		}

		if (!companyData.adrMNFakt.adrRF && !companyData.adrMNFakt.region) {
			companyData.adrMNFakt = null;
		}

		if (!companyData.kontaktTlf) {
			companyData.kontaktTlf = null;
		}

		if (!companyData.tlf) {
			companyData.tlf = null;
		}

		if (!companyData.naimUchrDok) {
			companyData.naimUchrDok = undefined;
		}

		if (!companyData.regNomer) {
			companyData.regNomer = undefined;
		}
	};

	const additionalProcessingIndividual = function (individualData) {
		fixIndividual(individualData.svedFizL.svedFL);
		return individualData;
	};

	const additionalProcessingCompany = function (companyData) {
		fixIndividual(companyData.svOrg.svFL.svedFL);
		fixCompany(companyData.svOrg.svOrgTip);
		companyData.svOrg.svFL = [companyData.svOrg.svFL];
		return companyData;
	};

	const additionalProcessingIndivEntrepreneur = function (indivEntData) {
		fixIndividual(indivEntData.svedIP.svedFL);
		return indivEntData;
	};

	const collectConfidants = function (formData, dataPath) {
		let confidantTypes = formData[dataPath + '.tipPred'];
		let countIndividuals = 0;
		let countCompanies = 0;
		let countIndivEntrepreneurs = 0;
		let postProcessFn;
		let result = [];

		for (let i = 0; i < confidantTypes.length; ++i) {
			let currentType = confidantTypes[i];
			let confidantStruct;

			if (currentType === moduleMethods.defConfidantType.INDIVIDUAL) {
				confidantStruct = confidantDataIndividual;
			} else if (currentType === moduleMethods.defConfidantType.COMPANY) {
				confidantStruct = confidantDataCompany;
			} else if (currentType === moduleMethods.defConfidantType.INDIV_ENTREPRENEUR) {
				confidantStruct = confidantDataIndivEntrepreneur;
			}

			let isAloneConfidant = !Array.isArray(formData[dataPath + '.' + confidantStruct[0]]);

			if (isAloneConfidant) {
				result.push(getAloneConfidant(formData, dataPath, currentType));
			} else {
				let confidantElem = {
					tipPred: currentType
				};
				let elemIndex;
				if (currentType === moduleMethods.defConfidantType.INDIVIDUAL) {
					postProcessFn = additionalProcessingIndividual;
					elemIndex = countIndividuals;
					countIndividuals += 1;
				} else if (currentType === moduleMethods.defConfidantType.COMPANY) {
					postProcessFn = additionalProcessingCompany;
					elemIndex = countCompanies;
					countCompanies += 1;
				} else if (currentType === moduleMethods.defConfidantType.INDIV_ENTREPRENEUR) {
					postProcessFn = additionalProcessingIndivEntrepreneur;
					elemIndex = countIndivEntrepreneurs;
					countIndivEntrepreneurs += 1;
				}

				for (let j = 0; j < confidantStruct.length; ++j) {
					let splitPath = confidantStruct[j].split('.');
					let finalObj = confidantElem;
					for (let k = 0; k < splitPath.length - 1; ++k) {
						if (!finalObj.hasOwnProperty(splitPath[k])) {
							finalObj[splitPath[k]] = {};
						}
						finalObj = finalObj[splitPath[k]];
					}

					finalObj[splitPath[splitPath.length - 1]] =
						formData[dataPath + '.' + confidantStruct[j]]?.[elemIndex] || null;
				}

				confidantElem = postProcessFn(confidantElem);

				result.push(confidantElem);
			}
		}

		return result;
	};

	const getAloneConfidant = function (formData, dataPath, type) {
		let result = {
			tipPred: type
		};
		let confidantStruct;
		let postProcessFn;

		if (type === moduleMethods.defConfidantType.INDIVIDUAL) {
			confidantStruct = confidantDataIndividual;
			postProcessFn = additionalProcessingIndividual;
		} else if (type === moduleMethods.defConfidantType.COMPANY) {
			confidantStruct = confidantDataCompany;
			postProcessFn = additionalProcessingCompany;
		} else if (type === moduleMethods.defConfidantType.INDIV_ENTREPRENEUR) {
			confidantStruct = confidantDataIndivEntrepreneur;
			postProcessFn = additionalProcessingIndivEntrepreneur;
		}

		for (let i = 0; i < confidantStruct.length; ++i) {
			let splitPath = confidantStruct[i].split('.');
			let finalObj = result;
			let splitPathAmount = splitPath.length - 1;
			for (let j = 0; j < splitPathAmount; ++j) {
				if (!finalObj.hasOwnProperty(splitPath[j])) {
					finalObj[splitPath[j]] = {};
				}
				finalObj = finalObj[splitPath[j]];
			}

			let path = dataPath + '.' + confidantStruct[i];
			let partSplitPath = splitPath[splitPathAmount];
			let splitPartPath = path.split('.' + partSplitPath)[0];
			if (formData[path]) {
				finalObj[partSplitPath] = formData[path];
			} else if (formData[splitPartPath]) {
				finalObj[partSplitPath] = formData[splitPartPath][partSplitPath];
			}
		}

		result = postProcessFn(result);

		return result;
	};

	const makeOneConfidant = function (formData, dataPath) {
		let confidantType = formData[dataPath + '.tipPred'];

		return getAloneConfidant(formData, dataPath, confidantType);
	};

	const additionalProcessingPrincipalIndividual = function (dataObj) {
		if (dataObj?.flDover?.svedFL) {
			fixIndividual(dataObj.flDover.svedFL);
		}

		if (dataObj?.flDover?.fio) {
			if (!dataObj.flDover.fio.lastName && !dataObj.flDover.fio.firstName && !dataObj.flDover.fio.middleName) {
				dataObj.flDover.fio = undefined;
			}
		}

		if (dataObj?.flDover?.prDeesp === moduleMethods.validitySign.VALID) {
			dataObj.flDover.dokNedeesp = undefined;
			dataObj.flDover.svZakPred = undefined;
		} else {
			if (dataObj?.flDover?.svZakPred?.svPoFL?.svedFL) {
				fixIndividual(dataObj.flDover.svZakPred.svPoFL.svedFL);
			}

			if (dataObj?.flDover?.svZakPred?.svOrg) {
				if (!dataObj.flDover.svZakPred.svOrg.naimOrg) {
					dataObj.flDover.svZakPred.svOrg = undefined;
				} else {
					fixCompany(dataObj.flDover.svZakPred.svOrg);
				}
			}

			if (dataObj?.flDover?.svZakPred?.svPoFL?.rekvDokPdtv) {
				if (
					!dataObj.flDover.svZakPred.svPoFL.rekvDokPdtv.dataVid &&
					!dataObj.flDover.svZakPred.svPoFL.rekvDokPdtv.kemVid &&
					!dataObj.flDover.svZakPred.svPoFL.rekvDokPdtv.svUdDok
				) {
					dataObj.flDover.svZakPred.svPoFL.rekvDokPdtv = undefined;
				}
			}
		}
		return dataObj;
	};

	const additionalProcessingPrincipalCompany = function (dataObj) {
		if (dataObj?.rosOrgDover?.litsoBezDov?.svFL?.svedFL) {
			fixIndividual(dataObj.rosOrgDover.litsoBezDov.svFL.svedFL);
		}

		if (dataObj?.rosOrgDover?.litsoBezDov?.svFL?.rekvDokPdtv) {
			if (
				!dataObj.rosOrgDover.litsoBezDov.svFL.rekvDokPdtv.dataVid &&
				!dataObj.rosOrgDover.litsoBezDov.svFL.rekvDokPdtv.kemVid &&
				!dataObj.rosOrgDover.litsoBezDov.svFL.rekvDokPdtv.svUdDok
			) {
				dataObj.rosOrgDover.litsoBezDov.svFL.rekvDokPdtv = undefined;
			}
		}

		if (dataObj?.rosOrgDover?.litsoBezDov?.svOrg) {
			if (!dataObj.rosOrgDover.litsoBezDov.svOrg.naimOrg) {
				dataObj.rosOrgDover.litsoBezDov.svOrg = undefined;
			} else {
				fixCompany(dataObj.rosOrgDover.litsoBezDov.svOrg);
			}
		}

		if (dataObj?.rosOrgDover?.svRosOrg) {
			if (!dataObj.rosOrgDover.svRosOrg.naimUchrDok) {
				dataObj.rosOrgDover.svRosOrg.naimUchrDok = undefined;
			}

			if (!dataObj.rosOrgDover.svRosOrg.adrMNFakt) {
				dataObj.rosOrgDover.svRosOrg.adrMNFakt = undefined;
			}

			if (!dataObj.rosOrgDover.svRosOrg.kontaktTlf) {
				dataObj.rosOrgDover.svRosOrg.kontaktTlf = undefined;
			}
		}

		if (dataObj?.rosOrgDover?.litsoBezDov?.svFL) {
			if (!dataObj.rosOrgDover.litsoBezDov.svFL.innfl) {
				dataObj.rosOrgDover.litsoBezDov.svFL.innfl = undefined;
			}

			if (!dataObj.rosOrgDover.litsoBezDov.svFL.naimDokPoln) {
				dataObj.rosOrgDover.litsoBezDov.svFL.naimDokPoln = undefined;
			}
		}

		if (dataObj?.rosOrgDover?.svRosOrg) {
			if (!dataObj.rosOrgDover.svRosOrg?.tlf) {
				dataObj.rosOrgDover.svRosOrg.tlf = undefined;
			}
		}

		return dataObj;
	};

	const additionalProcessingPrincipalIndivEntrepreneur = function (dataObj) {
		const { ipDover } = moduleMethods.getNameKeys(isPeredov);
		if (dataObj?.[ipDover]?.svedFL) {
			fixIndividual(dataObj[ipDover].svedFL);
		}
		return dataObj;
	};

	/****
	 * компоновка данных по доверителю (для сохранения)
	 */
	const makeOnePrincipal = function (formData, dataPath) {
		const { dover, svDoverit, tipDover } = moduleMethods.getNameKeys(isPeredov);

		let type = formData[dataPath + `.${isPeredov ? 'tipLitsPrdPoln' : 'tipDover'}`];
		let result = {
			[tipDover]: type
		};
		let principalStruct;
		let postProcessFn;

		if (type === moduleMethods.defPrincipalType.INDIVIDUAL) {
			principalStruct = principalDataIndividual;
			postProcessFn = additionalProcessingPrincipalIndividual;
		} else if (type === moduleMethods.defPrincipalType.COMPANY) {
			principalStruct = getPrincipalDataCompany();
			postProcessFn = additionalProcessingPrincipalCompany;
		} else if (type === moduleMethods.defPrincipalType.INDIV_ENTREPRENEUR) {
			principalStruct = getPrincipalDataIndivEntrepreneur();
			postProcessFn = additionalProcessingPrincipalIndivEntrepreneur;
		}

		for (let i = 0; i < principalStruct.length; ++i) {
			let splitPath = principalStruct[i].split('.');
			let finalObj = result;
			let splitPathAmount = splitPath.length - 1;
			for (let j = 0; j < splitPathAmount; ++j) {
				if (!finalObj.hasOwnProperty(splitPath[j])) {
					finalObj[splitPath[j]] = {};
				}
				finalObj = finalObj[splitPath[j]];
			}

			let path = dataPath + '.' + principalStruct[i];
			let partSplitPath = splitPath[splitPathAmount];
			let splitPartPath = path.split('.' + partSplitPath)[0];
			if (formData[path]) {
				finalObj[partSplitPath] = formData[path];
			} else if (formData[splitPartPath]) {
				finalObj[partSplitPath] = formData[splitPartPath][partSplitPath];
			}
		}

		result = postProcessFn(result);

		result.podpisant = {
			lastName:
				docContent?.document?.[dover]?.[svDoverit]?.[0]?.podpisant?.lastName ||
				formData[`data.document.${dover}.${svDoverit}.podpisant.lastName`] ||
				'-',
			firstName:
				docContent?.document?.[dover]?.[svDoverit]?.[0]?.podpisant?.firstName ||
				formData[`data.document.${dover}.${svDoverit}.podpisant.firstName`] ||
				'-',
			middleName:
				docContent?.document?.[dover]?.[svDoverit]?.[0]?.podpisant?.middleName ||
				formData[`data.document.${dover}.${svDoverit}.podpisant.middleName`] ||
				'-'
		};

		return result;
	};

	/****
	 * Генерация полей ФИО для вставки в грид
	 * @param	{String}	docPath		корень
	 * @param	{Object}	partData	соотвествующие данные
	 * @param	{Object}	defaults	значения по умолчанию
	 * @param	{Number}	startRow	для вставки в грид - строка, в которой будут ФИО
	 * @returns {[node, node, node]}
	 */
	const createFioPart = function (docPath, partData, defaults, startRow, required) {
		let mandatory = required || false;
		partData = partData || {};
		let lastName, firstName, middleName, lastNameInput, firstNameInput, middleNameInput;
		const MAX_LENGTH = 60;

		const changeName = function () {
			let allInputsEmpty = !lastNameInput.getValue() && !firstNameInput.getValue() && !middleNameInput.getValue();

			lastNameInput.allowBlank = allInputsEmpty && !mandatory;
			firstNameInput.allowBlank = allInputsEmpty && !mandatory;

			lastNameInput.isValid();
			firstNameInput.isValid();
		};

		lastName = createFieldBlock({
			title: edi.i18n.getMessage('last.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(lastNameInput = createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'lastName') || defaults?.lastName,
					name: docPath + '.lastName',
					maxLength: MAX_LENGTH,
					listeners: {
						change: function () {
							changeName();
						}
					}
				}))
			]
		});

		firstName = createFieldBlock({
			title: edi.i18n.getMessage('first.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(firstNameInput = createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'firstName') || defaults?.firstName,
					name: docPath + '.firstName',
					maxLength: MAX_LENGTH,
					listeners: {
						change: function () {
							changeName();
						}
					}
				}))
			]
		});

		middleName = createFieldBlock({
			title: edi.i18n.getMessage('patronymic.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(middleNameInput = createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'middleName') || defaults?.middleName,
					name: docPath + '.middleName',
					maxLength: MAX_LENGTH,
					listeners: {
						change: function () {
							changeName();
						}
					}
				}))
			]
		});

		return [lastName, firstName, middleName];
	};

	/*****
	 * Формирование блока адреса (заголовок + 2 поля) для вставки в грид
	 * @param	{String}	docPath		полный путь в рамках формы
	 * @param	{Object}	partData	данные для заполнения
	 * @param	{Object}	[defaults]	данные по умолчанию
	 * @param	{Object}	addrLabel	заголовок адреса
	 * @param	{Number}	[startRow]	ряд в гриде для заполнения
	 * @returns {[undefined, undefined, undefined]}		массив полей с заголовком и полями адреса
	 */
	const createRusAddressBlock = function (docPath, partData, defaults, addrLabel, startRow) {
		let initRow = startRow || 1;
		partData = partData || {};

		let region, addrText, regionInput, addrInput;

		const changeAddress = function () {
			let allFieldsEmpty = !regionInput.getValue() && !addrInput.getValue();

			regionInput.allowBlank = allFieldsEmpty;
			addrInput.allowBlank = allFieldsEmpty;

			regionInput.isValid();
			addrInput.isValid();
		};

		region = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.rus.region'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(regionInput = createCombo({
					disabled: true,
					name: docPath + '.region',
					store: edi.stores.createInlineStore(edi.stores.data.regions, 'REGION', undefined, {
						addEmptyRecord: true
					}),
					value: getFieldValue(partData, docPath, 'region') || defaults?.region,
					listeners: {
						change: function () {
							changeAddress();
						}
					}
				}))
			]
		});

		addrText = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.rus.address'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(addrInput = createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'adrRF') || defaults?.adrRF,
					name: docPath + '.adrRF',
					maxLength: 1000,
					listeners: {
						change: function () {
							changeAddress();
						}
					}
				}))
			]
		});

		let addrTitle = createLabel({
			text: addrLabel,
			hidden: true,
			padding: '24 0 0 0',
			grid: {
				srow: initRow,
				row: 1,
				scol: 1,
				col: 12
			}
		});

		return [addrTitle, region, addrText];
	};

	/*******
	 * Генерация сведений о физлице для заданного корня (то, что лежит в svedFL)
	 *
	 * @param	{String}	docPath		полный путь к полю в рамках формы
	 * @param	{Object}	partData	данные для заполнения
	 * @param	{Object}	[defaults]	данные по умолчанию
	 * @param	{Number}	[startRow]	начальный ряд (в гриде)
	 * @returns {*[]}
	 */
	const createIndividualDataPart = function (docPath, partData, defaults, startRow) {
		let initRow = startRow || 1;
		partData = partData || {};
		let gender = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('power.of.attorney.confidant.gender'),
			items: [
				createCombo({
					disabled: true,
					name: docPath + '.gender',
					store: edi.stores.createSimpleInlineStore(['1', '2'], function (id) {
						return edi.i18n.getMessage('power.of.attorney.gender.' + id);
					}),
					value: getFieldValue(partData, docPath, 'gender') || defaults?.gender
				})
			]
		});

		let birthday = createFieldBlock({
			title: edi.i18n.getMessage('person.birthday'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createDateField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'dateOfBirth') || defaults?.dateOfBirth,
					name: docPath + '.dateOfBirth',
					validator: (value) => {
						const birthdate = Ext.Date.parse(value, edi.constants.DATE_FORMAT.FNS);

						if (birthdate) {
							const currentDate = new Date();
							const age = Ext.Date.diff(birthdate, currentDate, 'y');
							if (age < 18 || age > 120) {
								return edi.i18n.getMessage(
									'document.cant.create.power.of.attorney.have.not.correct.date.of.birthday.value'
								);
							}
						}
						return true;
					},
					maxValue: new Date(),
					srcFormat: edi.constants.DATE_FORMAT.CLIENT
				})
			]
		});

		let phone = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.contact.phone'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'contactTelephone') || defaults?.contactTelephone,
					name: docPath + '.contactTelephone',
					maxLength: 50
				})
			]
		});

		let citizenship = String(partData[docPath + '.prGrazhd'] || defaults?.prGrazhd || '1');
		let citizenshipNodeCombo, countryCombo;
		const showHideCountry = function () {
			// нужен, чтобы country успело отрендериться
			setTimeout(function () {
				let isVisible = citizenshipNode.isVisible() && citizenshipNodeCombo.getValue() === '2';
				let ctryInput = countryCombo;
				ctryInput.allowBlank = !isVisible;
				ctryInput.isValid();
				country.setVisible(isVisible);
			}, 1);
		};

		let country;

		let citizenshipNode = createFieldBlock({
			title: edi.i18n.getMessage('person.citizenship'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(citizenshipNodeCombo = createCombo({
					width: '100%',
					disabled: true,
					name: docPath + '.prGrazhd',
					store: edi.stores.createSimpleInlineStore(['1', '2', '3'], function (id) {
						return edi.i18n.getMessage('power.of.attorney.citizenship.' + id);
					}),
					value: citizenship,
					listeners: {
						change: function () {
							showHideCountry();
						}
					}
				}))
			],
			listeners: {
				show: showHideCountry,
				hide: showHideCountry
			}
		});

		country = createFieldBlock({
			title: edi.i18n.getMessage('person.country'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(countryCombo = createCombo({
					width: '100%',
					disabled: true,
					name: docPath + '.grazhdanstvo',
					store: edi.stores.createInlineStore(edi.stores.data.countries_full, 'COUNTRY', null, {
						addEmptyRecord: true
					}),
					valueField: 'iso_number_3',
					displayField: 'name',
					allowBlank: citizenship !== '2',
					value: getFieldValue(partData, docPath, 'grazhdanstvo') || defaults?.grazhdanstvo || '643'
				}))
			],
			listeners: {
				afterrender: showHideCountry
			}
		});

		return [birthday, gender, citizenshipNode, country, phone];
	};

	/******
	 * Данные о физическом лице (svedFizL)
	 */
	const createIndividualPersonDataBlock = function (docPath, partData, defaults, startRow, fioRequired) {
		let initRow = startRow || 1;
		let [lastName, firstName, middleName] = createFioPart(
			docPath + '.fio',
			partData?.fio ?? partData,
			defaults?.fio,
			initRow + 1,
			fioRequired
		);

		let inn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'innfl') || defaults?.innfl,
					name: docPath + '.innfl',
					regex: edi.constants.VALIDATORS.INDI_INN
				})
			]
		});

		let snils = createFieldBlock({
			title: edi.i18n.getMessage('person.snils'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'snils') || defaults?.snils,
					name: docPath + '.snils',
					regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
					regexText: edi.i18n.getMessage('invalid.snils.format'),
					inputMask: edi.constants.FIELD_MASK.SNILS
				})
			]
		});

		let individualData = createIndividualDataPart(
			docPath + '.svedFL',
			partData?.svedFL ?? partData,
			defaults?.svedFL,
			initRow + 2
		);

		return [lastName, firstName, middleName, inn, snils, ...individualData];
	};

	/******
	 * Данные о компании - часть, относящаяся к компании (svOrg.svOrgTip)
	 */
	const createRusCompanyDataPart = function (docPath, partData, defaults, startRow) {
		let initRow = startRow || 1;
		let registrationAddr = createRusAddressBlock(
			docPath + '.adrReg',
			edi.utils.isEmptyObject(partData?.adrReg) ? partData : partData?.adrReg,
			defaults?.adrReg,
			edi.i18n.getMessage('power.of.attorney.registration.address'),
			initRow + 2
		);

		let orgName = createFieldBlock({
			title: edi.i18n.getMessage('company.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'naimOrg') || defaults?.naimOrg,
					name: docPath + '.naimOrg',
					maxLength: 1000
				})
			]
		});

		let orgOgrn = createFieldBlock({
			title: edi.i18n.getMessage('column.register.number'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'ogrn') || defaults?.ogrn,
					name: docPath + '.ogrn',
					regex: edi.constants.VALIDATORS.OGRN
				})
			]
		});

		let orgInn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'innyul') || defaults?.innyul,
					name: docPath + '.innyul',
					regex: edi.constants.VALIDATORS.INN
				})
			]
		});

		let orgKpp = createFieldBlock({
			title: edi.i18n.getMessage('company.kpp.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'kpp') || defaults?.kpp,
					name: docPath + '.kpp',
					regex: edi.constants.VALIDATORS.KPP
				})
			]
		});

		let phone = createFieldBlock({
			title: edi.i18n.getMessage('company.contact.phone'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'kontaktTlf') || defaults?.tlf,
					name: docPath + '.kontaktTlf',
					maxLength: 50
				})
			]
		});

		return [orgName, orgInn, orgOgrn, orgKpp, ...registrationAddr, phone];
	};

	const getFieldValue = function (partData, docPath, name) {
		if (edi.utils.isEmptyObject(partData)) return null;
		const key = [docPath, name].filter(Boolean).join('.');
		if (!!partData[key]) {
			return partData[key];
		} else if (!!edi.utils.getObjectProperty(partData, name)) {
			return edi.utils.getObjectProperty(partData, name);
		} else if (!!edi.utils.getObjectProperty(partData, key)) {
			return edi.utils.getObjectProperty(partData, key);
		}
	};

	/****
	 * Часть svRosOrg доверителя - ЮЛ (уникальна своими адресами в одну текстовую строку)
	 */
	const createPrincipalCompanyPart = function (docPath, partData, defaults, isCompany) {
		partData = partData || {};
		let orgName = createFieldBlock({
			title: edi.i18n.getMessage('company.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					readOnly: isCompany,
					value: getFieldValue(partData, docPath, 'naimOrg') || defaults?.naimOrg,
					name: docPath + '.naimOrg',
					maxLength: 1000
				})
			]
		});

		let orgOgrn = createFieldBlock({
			title: edi.i18n.getMessage('column.register.number'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: getFieldValue(partData, docPath, 'ogrn') || defaults?.ogrn,
					name: docPath + '.ogrn',
					regex: edi.constants.VALIDATORS.OGRN
				})
			]
		});

		let orgInn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					readOnly: isCompany,
					value: getFieldValue(partData, docPath, 'innyul') || defaults?.innyul,
					name: docPath + '.innyul',
					regex: edi.constants.VALIDATORS.INN
				})
			]
		});

		let orgKpp = createFieldBlock({
			title: edi.i18n.getMessage('column.org.kpp'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					readOnly: isCompany,
					value: getFieldValue(partData, docPath, 'kpp') || defaults?.kpp,
					name: docPath + '.kpp',
					regex: edi.constants.VALIDATORS.KPP
				})
			]
		});

		let phone = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.contact.phone'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: getFieldValue(partData, docPath, 'tlf') || defaults?.tlf,
					name: docPath + '.tlf',
					maxLength: 50
				})
			]
		});

		let registrationAddr = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.registration.address'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: getFieldValue(partData, docPath, 'adrRF') || defaults?.adrRF,
					name: docPath + '.adrRF',
					maxLength: 1000
				})
			]
		});

		return [orgName, orgOgrn, orgInn, orgKpp, registrationAddr, phone];
	};

	/****
	 * Часть доверителя - ЮЛ, относящаяся к лицу без доверенности, представляющему ЮЛ
	 *  (блок litsoBezDov)
	 */
	const createPrincipalCompanyPersonPart = function (docPath, partData, defaults, startRow) {
		let initRow = startRow || 1;

		let principalPersonInn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'svFL.innfl') || defaults?.svFL?.innfl,
					name: docPath + '.svFL.innfl',
					regex: edi.constants.VALIDATORS.INDI_INN
				})
			]
		});

		let principalPersonSnils = createFieldBlock({
			title: edi.i18n.getMessage('person.snils'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'svFL.snils') || defaults?.svFL?.snils,
					name: docPath + '.svFL.snils',
					regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
					regexText: edi.i18n.getMessage('invalid.snils.format'),
					inputMask: edi.constants.FIELD_MASK.SNILS
				})
			]
		});

		let principalPersonPosition = createFieldBlock({
			title: edi.i18n.getMessage('job.title'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'svFL.dolzhnost'),
					name: docPath + '.svFL.dolzhnost',
					maxLength: 50
					// allowBlank: false
				})
			]
		});

		let principalPersonPowerDoc = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.principal.delegate.power.document'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'svFL.naimDokPoln'),
					name: docPath + '.svFL.naimDokPoln',
					maxLength: 100
				})
			]
		});

		let individualPart = createIndividualDataPart(
			docPath + '.svFL.svedFL',
			partData?.svFL?.svedFL ?? partData,
			defaults?.svFL?.svedFL,
			initRow + 3
		);

		return [
			principalPersonInn,
			principalPersonSnils,
			...individualPart,
			principalPersonPosition,
			principalPersonPowerDoc
		];
	};

	/***
	 * Блок ...svDoverit.ipDover (доверителль - ИП)
	 */
	const createPrincipalIndivEntrepreneurDataBlock = function (docPath, partData, defaults, startRow) {
		let initRow = startRow || 1;
		partData = partData || {};
		let ipInn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'innfl') || defaults?.innfl,
					name: docPath + '.innfl',
					regex: edi.constants.VALIDATORS.INDI_INN
				})
			]
		});

		let ipSnils = createFieldBlock({
			title: edi.i18n.getMessage('person.snils'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'snils') || defaults?.snils,
					name: docPath + '.snils',
					regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
					regexText: edi.i18n.getMessage('invalid.snils.format'),
					inputMask: edi.constants.FIELD_MASK.SNILS
				})
			]
		});

		let ipName = createFieldBlock({
			title: edi.i18n.getMessage('company.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'naimIP') || defaults?.naimIP,
					name: docPath + '.naimIP',
					maxLength: 1000
				})
			]
		});

		let ipOgrn = createFieldBlock({
			title: edi.i18n.getMessage('company.ogrn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'ogrnip') || defaults?.ogrnip,
					name: docPath + '.ogrnip',
					regex: edi.constants.VALIDATORS.INDI_OGRN
				})
			]
		});

		return [ipName, ipOgrn, ipInn, ipSnils];
	};

	const createOptionalCompanyPart = function (docPath, partData, defaults, startRow, isReturnOnlyFields) {
		let initRow = startRow || 1;

		let isShownDelegateCompany = !!partData?.naimOrg || isReturnOnlyFields;

		let delegateCompanyName = createFieldBlock({
			title: edi.i18n.getMessage('company.name'),
			cls: FIELD_BLOCK_CLS.small,
			hidden: !isShownDelegateCompany,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'naimOrg') || defaults?.naimOrg,
					name: docPath + '.naimOrg',
					maxLength: 1000
				})
			]
		});

		let delegateCompanyInn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			hidden: !isShownDelegateCompany,
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'innyul') || defaults?.innyul,
					name: docPath + '.innyul',
					regex: edi.constants.VALIDATORS.INN
				})
			]
		});

		let delegateCompanyKpp = createFieldBlock({
			title: edi.i18n.getMessage('company.kpp.short'),
			hidden: !isShownDelegateCompany,
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'kpp') || defaults?.kpp,
					name: docPath + '.kpp',
					regex: edi.constants.VALIDATORS.KPP
				})
			]
		});

		let delegateCompanyOgrn = createFieldBlock({
			title: edi.i18n.getMessage('company.ogrn.short'),
			hidden: !isShownDelegateCompany,
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					width: '100%',
					disabled: true,
					value: getFieldValue(partData, docPath, 'ogrn') || defaults?.ogrn,
					name: docPath + '.ogrn',
					regex: edi.constants.VALIDATORS.OGRN
				})
			]
		});

		let delegateCompanyContactPhone = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.contact.phone'),
			hidden: !isShownDelegateCompany,
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					disabled: true,
					value: getFieldValue(partData, docPath, 'kontaktTlf') || defaults?.kontaktTlf,
					name: docPath + '.kontaktTlf',
					maxLength: 50
				})
			]
		});

		let [regAddrTitle, regAddrRegion, regAddrText] = createRusAddressBlock(
			docPath + '.adrReg',
			edi.utils.isEmptyObject(partData?.adrReg) ? partData : partData?.adrReg,
			defaults?.adrReg,
			edi.i18n.getMessage('power.of.attorney.registration.address'),
			4
		);

		regAddrTitle.hidden = !isShownDelegateCompany;
		regAddrRegion.hidden = !isShownDelegateCompany;
		regAddrText.hidden = !isShownDelegateCompany;

		const delegateCompanyFieldList = [
			delegateCompanyName,
			delegateCompanyOgrn,
			delegateCompanyInn,
			delegateCompanyKpp,
			regAddrRegion,
			regAddrText,
			delegateCompanyContactPhone
		];

		let removeDelegateCompanyButton;
		let addDelegateCompanyButton;

		const showCompanyFields = function () {
			removeDelegateCompanyButton.show();

			for (let i = 0; i < delegateCompanyFieldList.length; ++i) {
				delegateCompanyFieldList[i].show();
				// EW start
				let elemInput = Ext.isFunction(delegateCompanyFieldList[i].getItems)
					? delegateCompanyFieldList[i].getItems()[0]
					: undefined;
				// EW end
				if (elemInput) {
					elemInput.enable();
					let pureName = elemInput.name.substring(elemInput.name.lastIndexOf('.') + 1);
					if (optionalCompanyRequired.indexOf(pureName) !== -1) {
						elemInput.allowBlank = false;
					}
				}
			}

			addDelegateCompanyButton.hide();
		};

		const hideCompanyFields = function () {
			removeDelegateCompanyButton.hide();

			for (let i = 0; i < delegateCompanyFieldList.length; ++i) {
				delegateCompanyFieldList[i].hide();
				let elemInput = Ext.isFunction(delegateCompanyFieldList[i].getInput)
					? delegateCompanyFieldList[i].getInput()
					: undefined;
				if (elemInput) {
					elemInput.disable();
					elemInput.allowBlank = true;
				}
			}

			addDelegateCompanyButton.show();
		};

		addDelegateCompanyButton = createButton({
			cls: BUTTON_CLS.secondary,
			tooltip: edi.i18n.getMessage('power.of.attorney.add.delegate.company'),
			glyph: edi.constants.ICONS.ADD,
			padding: '0 10 0 0',
			hidden: isShownDelegateCompany,
			handler: function () {
				showCompanyFields();
				checkValid();
			},
			grid: {
				srow: 1,
				row: 1,
				scol: 1,
				col: 2
			}
		});

		removeDelegateCompanyButton = createButton({
			cls: BUTTON_CLS.secondary,
			tooltip: edi.i18n.getMessage('power.of.attorney.remove.delegate.company'),
			glyph: edi.constants.ICONS.REMOVE,
			padding: '0 10 0 0',
			hidden: !isShownDelegateCompany,
			handler: function () {
				hideCompanyFields();
				checkValid();
			},
			grid: {
				srow: 1,
				row: 1,
				scol: 9,
				col: 2
			}
		});
		if (isReturnOnlyFields) {
			showCompanyFields();
			checkValid();
			return [...delegateCompanyFieldList];
		} else {
			return [
				createPanel({
					layout: 'grid',
					width: '100%',
					hidden: true,
					name: docPath + '.optCompanyBlock',
					grid: {
						srow: initRow,
						scol: 1,
						row: 1,
						col: 12
					},
					items: [addDelegateCompanyButton, removeDelegateCompanyButton, ...delegateCompanyFieldList],
					listeners: {
						show: function () {
							let nameInput = delegateCompanyName.getInput();
							if (!!nameInput.getValue()) {
								addDelegateCompanyButton.hide();
								removeDelegateCompanyButton.show();
								showCompanyFields();
							} else {
								addDelegateCompanyButton.show();
								removeDelegateCompanyButton.hide();
								hideCompanyFields();
							}
						}
					}
				})
			];
		}
	};

	const checkFieldsDependentOnParentPoA = function () {
		if (validityDateRange) {
			validityDateRange.dateFromField.isValid();
			validityDateRange.dateToField.isValid();
		}
	};

	const onParentalPoAStateChanged = function (state) {
		form.suspendLayouts();
		checkFieldsDependentOnParentPoA();
		const dependentFields = form.getNamedItems();
		if (dependentFields)
			Object.values(dependentFields).forEach((f) => f.fireEvent('parentalPoAStateChanged', f, state));
		form.resumeLayouts();
	};

	const defaultFieldParentalPoAStateChangedHandler = ({ field, value, state }) => {
		if (field && !field.isDestroyed && isPeredov) {
			let resultValue;
			switch (state) {
				case ReTrustViewModelPoaStates.NOT_FOUND:
				case ReTrustViewModelPoaStates.PENDING:
					resultValue = null;
					break;
				case ReTrustViewModelPoaStates.MATCHED:
					resultValue = value;
					break;
			}

			field.setValue(resultValue);
		}
	};

	const defaultSimpleSelectorParentalPoAStateChangedHandler = ({ selector, values, state }) => {
		if (selector && !selector.isDestroyed && isPeredov) {
			let resultValues;
			switch (state) {
				case ReTrustViewModelPoaStates.NOT_FOUND:
				case ReTrustViewModelPoaStates.PENDING:
					{
						resultValues = {};
					}
					break;
				case ReTrustViewModelPoaStates.MATCHED:
					{
						resultValues = values;
					}
					break;
			}

			selector.setValues(resultValues);
		}
	};

	const poaTypeChangedHandler = () => {
		const dependentFields = form.getNamedItems();
		form.suspendLayouts();
		if (dependentFields) {
			Object.values(dependentFields)
				.flat()
				.forEach((f) => f.fireEvent('poaTypeChanged', f));
		}
		form.resumeLayouts();
	};

	const defaultSimpleSelectorPoATypeChangedHandler = ({ selector, values = {} }) => {
		if (!selector || selector.isDestroyed) return;

		if (!isEdit) {
			if (isPeredov) {
				//Сохраняем значение филда в момент переключение в режим передоверия
				selector.originalValue = selector.getValues();

				selector.setValues(values);
			} else {
				selector.setValues(selector.originalValue);
			}
		}

		selector.actionsMenuBtn.setVisible(!isPeredov);
	};

	const defaultFieldPoATypeChangedHandler = ({ field, value, allowChange }) => {
		if (!field || field.isDestroyed) return;

		if (!isEdit) {
			if (isPeredov) {
				//Сохраняем значение филда в момент переключение в режим передоверия
				field.originalValue = field.getValue();

				field.setValue(value);
			} else {
				field.setValue(field.originalValue);
			}
		}

		if (!allowChange) field.setReadOnly(isPeredov);
	};

	const onPoATypeChanged = function (isPeredov) {
		setIsPeredovState(isPeredov);
		checkFieldsDependentOnParentPoA();
		poaTypeChangedHandler();
	};

	const createReTrustFieldBlock = function () {
		class ReTrustBlock002 extends ReTrustBlock {
			constructor() {
				super({
					onParentalPoAStateChanged: (state) => {
						parentalPoA = super.getParentalPoAContent();
						onParentalPoAStateChanged(state);
					},
					onPoATypeChanged,

					isReTrust: isPeredov,
					isEdit
				});
			}

			createParentalNumberField() {
				return super.createParentalNumberField({
					config: {
						name: 'peredov.svDovPer.svPereDover.nomDover',
						value: docHeader?.parentPoaNumber
					}
				});
			}

			createPrincipalInn() {
				const { dover, svDov } = moduleMethods.getNameKeys(isPeredov);
				const svDovers = docContent?.document?.[dover]?.[svDov]?.svOsnDover?.svDovers?.[0];
				return super.createPrincipalInn({
					config: {
						value: svDovers?.rosOrgDover?.innyul || svDovers?.ipDover?.innfl || svDovers?.flDover?.innfl
					}
				});
			}
		}

		reTrustBlockInstance = new ReTrustBlock002();

		return reTrustBlockInstance.createReTrustFieldSet({
			config: {
				listeners: {
					afterrender: () => {
						if (isEdit && isPeredov) poaTypeChangedHandler();
					}
				}
			}
		});
	};

	/****
	 * Шапка МЧД
	 */
	const createHeaderFields = function () {
		const { dover, svDoverit } = moduleMethods.getNameKeys(isPeredov);
		const doverData = docContent?.document?.[dover];
		const svDovData = isPeredov ? doverData?.svDovPer?.svPereDover : doverData?.svDov;
		const title = createLabel({
			typography: 'heading_02',
			text: edi.i18n.getMessage(isEdit ? 'power.of.attorney.title.edit' : 'power.of.attorney.title.create'),
			plugins: [
				createHelpElementPlugin({
					url: 'https://ediweb.com/ru-ru/support/kb/1832',
					tooltip: edi.i18n.getMessage('control.center.title.tooltip')
				})
			]
		});

		let internalNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.internal.number'),
			value: svDovData?.vnNomDover || '',
			name: 'vnNomDover',
			maxLength: 50,
			allowBlank: false,
			plugins: [
				createInfoElementPlugin({
					hintConfig: {
						html: edi.i18n.getMessage('power.of.attorney.internal.number.tooltip')
					},
					isField: true
				})
			]
		});

		let dateValidFromTo = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.date.validity'),
			layout: {
				gap: [16, 24],
				type: 'grid',
				area: [[4, 8]]
			},
			items: [
				(validityDateRange = createDateRangeField({
					fieldsConfig: {
						from: {
							fieldLabel: '',
							value: docContent ? new Date(svDovData?.dataVidDover) : '',
							name: 'dataVidDover',
							allowBlank: false,
							nameable: true,
							validator: function (values) {
								const dateFromValue = values?.from,
									dateToValue = values?.to,
									clearToday = Ext.Date.clearTime(new Date(), true);
								let res = true;

								if (Ext.isDate(dateFromValue)) {
									if (clearToday.getTime() > dateFromValue.getTime()) {
										res = edi.i18n.getMessage('power.of.attorney.issue.date.cannot.be.earlier');
									}
								}
								if (Ext.isDate(dateFromValue) && Ext.isDate(dateToValue)) {
									let validLabelText = edi.renderers.getTextDiffBetweenTwoDate(
										dateFromValue,
										dateToValue
									);
									validLabel.setVisible(validLabelText);
									if (validLabelText) {
										validLabelText = `${edi.i18n.getMessage(
											'power.of.attorney.valid.text'
										)} ${validLabelText}`;
										validLabel.setText(validLabelText);
									} else {
										validLabel.setText('');
									}
								}

								if (isPeredov && dateFromValue) {
									const parentalDateFromValue = this.getParentalPoaValue();

									if (parentalDateFromValue > Ext.Date.fromUTC(dateFromValue).valueOf()) {
										res = edi.i18n.getMessage(
											'power.of.attorney.issue.date.cannot.be.earlier.than.parental'
										);
									}
								}

								return res;
							},
							getParentalPoaValue: () =>
								moduleMethods.getPoaValue({
									path: 'document.dover.svDov.dataVidDover',
									poaData: isEdit ? docContent : parentalPoA
								}),
							listeners: {
								poaTypeChanged: function (field) {
									const value = field.getParentalPoaValue();

									defaultFieldPoATypeChangedHandler({
										field,
										allowChange: true,
										value: parentalPoA ? new Date(value) : ''
									});
								},
								parentalPoAStateChanged: function (field, state) {
									const value = field.getParentalPoaValue();

									defaultFieldParentalPoAStateChangedHandler({
										field,
										state,
										value: parentalPoA ? new Date(value) : ''
									});
								}
							}
						},
						to: {
							fieldLabel: '',
							value: docContent ? new Date(svDovData?.dataKonDover) : '',
							name: 'dataKonDover',
							nameable: true,
							validator: function (values) {
								const dateFromValue = values?.from,
									dateToValue = values?.to;
								let res = true;

								if (Ext.isDate(dateFromValue) && Ext.isDate(dateToValue)) {
									if (dateFromValue.getTime() > dateToValue.getTime()) {
										res = edi.i18n.getMessage('power.of.attorney.invalid.date.span.error');
									}
									let validLabelText = edi.renderers.getTextDiffBetweenTwoDate(
										dateFromValue,
										dateToValue
									);
									validLabel.setVisible(validLabelText);
									if (validLabelText) {
										validLabelText = `${edi.i18n.getMessage(
											'power.of.attorney.valid.text'
										)} ${validLabelText}`;
										validLabel.setText(validLabelText);
									} else {
										validLabel.setText('');
									}
								}

								if (isPeredov && dateToValue) {
									const parentalDateToValue = this.getParentalPoaValue();

									if (parentalDateToValue < Ext.Date.fromUTC(dateToValue).valueOf()) {
										res = edi.i18n.getMessage(
											'power.of.attorney.issue.date.cannot.be.later.than.parental'
										);
									}
								}
								return res;
							},
							getParentalPoaValue: () =>
								moduleMethods.getPoaValue({
									path: 'document.dover.svDov.dataKonDover',
									poaData: isEdit ? docContent : parentalPoA
								}),
							listeners: {
								poaTypeChanged: function (field) {
									const value = field.getParentalPoaValue();

									defaultFieldPoATypeChangedHandler({
										field,
										allowChange: true,
										value: parentalPoA ? new Date(value) : ''
									});
								},
								parentalPoAStateChanged: function (field, state) {
									const value = field.getParentalPoaValue();

									defaultFieldParentalPoAStateChangedHandler({
										field,
										state,
										value: parentalPoA ? new Date(value) : ''
									});
								}
							}
						}
					}
				})),
				(validLabel = createLabel({
					typography: 'body-short_01',
					padding: '14 0 0 0',
					hidden: true,
					text: ''
				}))
			]
		});

		let prUtrPolnCombo, prUtrPolnFieldBlock;
		const switchPrPeredov = function (prPeredovCombo) {
			const showPrUtrPoln = ['1', '3'].includes(prPeredovCombo.getValue());
			prUtrPolnFieldBlock.setVisible(showPrUtrPoln);
			prUtrPolnCombo.setDisabled(!showPrUtrPoln);
		};

		let additionConditions = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.addition.conditions'),
			layout: {
				type: 'grid',
				area: [[3, 2, 2, 2]],
				ignoreHiddenElements: true
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('power.of.attorney.reassignment'),
					cls: FIELD_BLOCK_CLS.small,
					items: [
						createCombo({
							name: 'prPeredov',
							store: edi.stores.createSimpleInlineStore(['1', '2', '3'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.reassignment.' + id);
							}),
							value: svDovData?.prPeredov || '2',
							allowBlank: false,
							nameable: true,
							getParentalPoaValue: () => {
								const parentalValue = moduleMethods.getPoaValue({
									path: 'document.dover.svDov.prPeredov',
									poaData: parentalPoA
								});

								//Если в родительской МЧД "Передоверие": однократное передоверие - устанавливаем "без права передоверия"
								const isOneTimeReTrust = parentalValue === '1';
								if (isOneTimeReTrust) return '2';

								return parentalValue;
							},
							listeners: {
								change: switchPrPeredov,
								afterrender: switchPrPeredov,
								poaTypeChanged: function (field) {
									const value = field.getParentalPoaValue();
									defaultFieldPoATypeChangedHandler({
										field,
										value: value ?? '2'
									});
								},
								parentalPoAStateChanged: function (field, state) {
									const value = field.getParentalPoaValue();
									defaultFieldParentalPoAStateChangedHandler({
										field,
										state,
										value: value ?? '2'
									});
								}
							},
							plugins: [
								createInfoElementPlugin({
									hintConfig: {
										html: edi.i18n.getMessage('power.of.attorney.reassignment.tooltip')
									},
									isField: true
								})
							]
						})
					]
				}),
				(prUtrPolnFieldBlock = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('power.of.attorney.loss.of.authority'),
					items: [
						(prUtrPolnCombo = createCombo({
							name: 'prUtrPoln',
							store: edi.stores.createSimpleInlineStore(['1', '2'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.loss.of.authority.' + id);
							}),
							value: svDovData?.prUtrPoln || '1',
							editable: false,
							forceSelection: false,
							nameable: true,
							getParentalPoaValue: () =>
								moduleMethods.getPoaValue({
									path: 'document.dover.svDov.prUtrPoln',
									poaData: parentalPoA
								}),
							listeners: {
								change: switchPrPeredov,
								afterrender: switchPrPeredov,
								poaTypeChanged: function (field) {
									defaultFieldPoATypeChangedHandler({
										field,
										value: field.getParentalPoaValue() ?? '1'
									});
								},
								parentalPoAStateChanged: function (field, state) {
									defaultFieldParentalPoAStateChangedHandler({
										field,
										state,
										value: field.getParentalPoaValue() ?? '1'
									});
								}
							}
						}))
					]
				})),
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('power.of.attorney.power.select'),
					items: [
						(poaCollectivePowersCombo = createCombo({
							name: 'prSovmPoln',
							store: edi.stores.createSimpleInlineStore(['1', '2'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.power.code.' + id);
							}),
							value: svDovData?.prSovmPoln || '1',
							allowBlank: false,
							nameable: true,
							getParentalPoaValue: () =>
								moduleMethods.getPoaValue({
									path: 'document.dover.svDov.prSovmPoln',
									poaData: parentalPoA
								}),
							listeners: {
								change() {
									showPoaCollectivePowers();
								},
								afterrender() {
									showPoaCollectivePowers();
								},
								poaTypeChanged: function (field) {
									defaultFieldPoATypeChangedHandler({
										field,
										value: field.getParentalPoaValue() ?? '1'
									});
								},
								parentalPoAStateChanged: function (field, state) {
									defaultFieldParentalPoAStateChangedHandler({
										field,
										state,
										value: field.getParentalPoaValue() ?? '1'
									});
								}
							},
							plugins: [
								createInfoElementPlugin({
									hintConfig: {
										html: edi.i18n.getMessage('power.of.attorney.power.select.tooltip')
									},
									isField: true
								})
							]
						}))
					]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('power.of.attorney.revoke.mark'),
					cls: FIELD_BLOCK_CLS.small,
					items: [
						createCombo({
							name: 'prBezotziv',
							store: edi.stores.createSimpleInlineStore(['1'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.revoke.mark.' + id);
							}),
							value: svDovData?.bezotziv?.prBezotziv || '1',
							readOnly: true,
							nameable: true,
							plugins: [
								createInfoElementPlugin({
									hintConfig: {
										html: edi.i18n.getMessage('power.of.attorney.revoke.mark.tooltip')
									},
									isField: true
								})
							],
							getParentalPoaValue: () =>
								moduleMethods.getPoaValue({
									path: 'document.dover.svDov.bezotziv.prBezotziv',
									poaData: parentalPoA
								}),
							listeners: {
								poaTypeChanged: function (field) {
									defaultFieldPoATypeChangedHandler({
										field,
										value: field.getParentalPoaValue() ?? '1'
									});
								},
								parentalPoAStateChanged: function (field, state) {
									defaultFieldParentalPoAStateChangedHandler({
										field,
										state,
										value: field.getParentalPoaValue() ?? '1'
									});
								}
							}
						})
					]
				})
			]
		});

		return createFormContainer({
			gap: [16, 24],
			area: [12, 12, 4, 12, 12],
			items: [title, createReTrustFieldBlock(), internalNumber, dateValidFromTo, additionConditions]
		});
	};

	const createPowersList = function () {
		const createPowerEditingBlock = function (isMainField, fieldData) {
			let kodPolnHidden, soderzhPolnHidden, kodPolnNoneHidden;

			let tekstPolnValue = '';
			if (!edi.utils.isEmptyObject(fieldData)) {
				tekstPolnValue = fieldData.tekstPoln ?? fieldData.kodPoln + ' - ' + fieldData.soderzhPoln;
			}
			return createContainer({
				layout: {
					type: 'grid',
					area: [[7, 5]]
				},
				items: [
					createFieldBlock({
						items: [
							createCombo({
								fieldLabel: edi.i18n.getMessage('power.of.attorney.power.select'),
								emptyText: edi.i18n.getMessage('poa.tekstPoln.fieldLabel'),
								name: 'tekstPoln',
								queryMode: 'local',
								triggerAction: 'all',
								value: tekstPolnValue,
								store: moduleMethods.createPoaCodesStore(),
								valueField: 'code_display_value',
								displayField: 'code_display_value',
								allowBlank: false,
								forceSelection: false,
								allowManualInput: true,
								anyMatch: true,
								plugins: [
									createInfoElementPlugin({
										hintConfig: {
											html: edi.i18n.getMessage('power.of.attorney.power.list.of.powers.tooltip'),
											popupAlign: 't'
										},
										isField: true
									})
								],
								listeners: {
									change(comp, value) {
										let store = comp.getStore();
										let record = store.findRecord('code_display_value', value);
										let isValueFromStore = !!record;
										kodPolnNoneHidden.submitValue = !isValueFromStore;
										kodPolnHidden.submitValue = isValueFromStore;
										if (isValueFromStore) {
											let recData = record.getData();
											kodPolnHidden.setValue(recData.submit_value);
											soderzhPolnHidden.setValue(recData.display_value);
										} else {
											kodPolnHidden.setValue(null);
										}
									},
									afterrender(comp) {
										let soderzhPolnValue = soderzhPolnHidden.getValue();
										let store = comp.getStore();
										let record = store.findRecord('display_value', soderzhPolnValue);
										let isValueFromStore = !!record;
										kodPolnNoneHidden.submitValue = !isValueFromStore;
										kodPolnHidden.submitValue = isValueFromStore;
									}
								}
							}),
							(kodPolnHidden = createHiddenField({
								value: fieldData ? fieldData?.kodPoln : null,
								submitValue: false,
								name: 'kodPoln'
							})),
							(kodPolnNoneHidden = createHiddenField({
								value: 'NONE',
								submitValue: false,
								name: 'kodPoln'
							})),
							(soderzhPolnHidden = createHiddenField({
								value: fieldData ? fieldData?.soderzhPoln : null,
								//submitValue: false,
								name: 'soderzhPoln'
							}))
						]
					}),
					createFieldBlock({
						items: [
							createTextField({
								fieldLabel: edi.i18n.getMessage('power.of.attorney.power.additional.info'),
								value: fieldData ? fieldData?.dopTekstPoln : '',
								name: 'dopTekstPoln',
								maxLength: 1000
							})
						]
					})
				]
			});
		};

		const { dover } = moduleMethods.getNameKeys(isPeredov);
		return createRowsBlock({
			createContentFieldsFn: createPowerEditingBlock,
			textAddButton: edi.i18n.getMessage('power.of.attorney.row.add.btn'),
			initialData: docContent?.document?.[dover]?.svPoln,
			contentCols: 10,
			buttonsCols: 3,
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});
	};

	const createModuleForm = function () {
		/**
		 * Создание формы документа - основной метод
		 */
		saveBtn = createCreateSaveButton(
			{
				disabled: true,
				handler: function () {
					const { dover, svDoverit } = moduleMethods.getNameKeys(isPeredov);
					if (
						!checkValid() ||
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							[
								`data.document.${dover}.${svDoverit}.flDover.svZakPred.optCompanyBlock`,
								`data.document.${dover}.${svDoverit}.rosOrgDover.optCompanyBlock`
							],
							[],
							undefined
						)
					) {
						return;
					}
					save();
				}
			},
			isEdit
		);
		form = createForm({
			autoScroll: true,
			cls: 'edi-form',
			bodyPadding: '24',
			nameHolder: true,
			items: [
				createFormContainer({
					gap: [16, 24],
					items: [
						createHeaderFields(),
						//доверитель + подписант
						getPrincipalFieldSet(),

						//уполномоченный представитель
						getConfidantFieldSet(),
						createFieldBlock({
							title: edi.i18n.getMessage('power.of.attorney.power.list'),
							items: [createPowersList()]
						})
					]
				})
			],
			buttonAlign: 'left',
			buttons: [saveBtn]
		});

		checkValid();

		form.on('validitychange', function (__self, valid) {
			checkValid();
		});

		return form;
	};

	/*****
	 * Получение адреса организации из userData в виде строки
	 */
	const getUserOrgAddress = function () {
		if (!userData.org.addresses || !Array.isArray(userData.org.addresses)) {
			return '';
		}

		let legalAddress = userData.org.addresses.find(function (element) {
			return element.type === 'LEGAL' || element.type === 'RU_LEGAL';
		});
		if (!legalAddress) {
			legalAddress = userData.org[0];
		}
		var address = [];
		if (legalAddress?.zip) {
			address.push(legalAddress.zip);
		}
		if (legalAddress?.area) {
			address.push(legalAddress.area);
		}
		if (legalAddress?.city) {
			address.push(edi.i18n.getMessage('address.city') + ' ' + legalAddress.city);
		}
		if (legalAddress?.street) {
			address.push(edi.i18n.getMessage('address.street') + ' ' + legalAddress.street);
		}
		if (legalAddress?.building) {
			address.push(edi.i18n.getMessage('address.building') + ' ' + legalAddress.building);
		}
		if (legalAddress?.block) {
			address.push(edi.i18n.getMessage('housing.short') + ' ' + legalAddress.block);
		}
		if (legalAddress?.flat) {
			address.push(edi.i18n.getMessage('company.flat') + ' ' + legalAddress.flat);
		}
		return address.join(', ');
	};
	const getPrincipalFieldSet = function () {
		const { dover, svDoverit, tipDover } = moduleMethods.getNameKeys(isPeredov);
		let principalData = docContent?.document?.[dover]?.[svDoverit] || null;
		let defaultData = null;
		let currentType = principalData?.[0]?.[tipDover] || '';
		let principalBlock;
		let defPrincipalTypes = [];
		if (!isEdit && !currentType) {
			switch (edi.core.getUserData().org.orgType) {
				case edi.constants.ORGANIZATION_TYPES.INDIVIDUAL:
					currentType = moduleMethods.defPrincipalType.INDIV_ENTREPRENEUR;
					break;
				case edi.constants.ORGANIZATION_TYPES.LEGAL_ENTITY:
					currentType = moduleMethods.defPrincipalType.COMPANY;
					break;
				//case edi.constants.ORGANIZATION_TYPES.FOREIGN_LEGAL_ENTITY:
				//	currentType = moduleMethods.defPrincipalType.INDIVIDUAL;
				//	break;
			}
		}

		if (!isEdit && !isCopy) {
			defaultData = {
				rosOrgDover: {
					svRosOrg: {
						naimOrg: userData.org.name,
						ogrn: userData.org.regNr,
						innyul: userData.org.inn,
						kpp: userData.org.kpp,
						adrRF: getUserOrgAddress(),
						tlf: userData.org.phones
					},
					litsoBezDov: {
						svFL: {
							snils: userData.personalId ? edi.converters.SNILS.convertForShow(userData.personalId) : null
						}
					}
				},
				ipDover: {
					naimIP: userData.org.name,
					ogrnip: userData.org.regNr,
					innfl: userData.org.inn,
					snils: userData.personalId ? edi.converters.SNILS.convertForShow(userData.personalId) : null
				},
				flDover: {
					snils: userData.personalId ? edi.converters.SNILS.convertForShow(userData.personalId) : null
				}
			};
		}

		const getPrincipalBlock = function () {
			const showBlockFields = function (blockRef, fieldList, requiredList) {
				const { dover, svDoverit } = moduleMethods.getNameKeys(isPeredov);
				for (let i = 0; i < fieldList.length; ++i) {
					let currentNode = blockRef.down(`[name=data.document.${dover}.${svDoverit}.` + fieldList[i] + ']');
					if (currentNode) {
						if (currentNode.xtype === 'panel') {
							currentNode.show();
						} else {
							currentNode.allowBlank = requiredList.indexOf(fieldList[i]) === -1;
							currentNode.enable();
						}
					}
				}
				checkValid();
			};

			/******
			 * Данные о юридическом лице с simpleSelector
			 */
			const createPrincipalCompanyDataSSBlock = function (
				docPath,
				partData,
				orgType,
				data,
				getRequired,
				defaultData
			) {
				partData =
					(isPeredov ? partData : partData?.svRosOrg) || (isPeredov ? defaultData : defaultData?.svRosOrg);
				let namePath = moduleMethods.createFieldName(docPath, isPeredov ? '' : 'svRosOrg');

				let companySelector = createFieldBlock({
					title: getOrgTypeName(orgType),
					items: [
						createSimpleSelector({
							itemId: 'svRosOrg',
							cls: 'ui-org-selector',
							nameable: true,
							name: namePath,
							fieldValues: edi.utils.flattenObject(partData),
							showSelectButton: false,
							showEditButton: true,
							showResetButton: false,
							showRequiredIfValid: false,
							allowBlank: false,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.company.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							callback: checkValid,
							isValidFunc: function (fieldValues) {
								const additionalPath = isPeredov ? '' : 'svRosOrg';
								fieldValues = edi.utils.unflattenObject(fieldValues ?? {});
								let requiredOrgTip = getRequired().filter((item) => item.includes(additionalPath));
								const { rosOrgDover } = moduleMethods.getNameKeys(isPeredov);
								const key = moduleMethods.createFieldName(rosOrgDover, additionalPath);
								return requiredOrgTip.every(function (item) {
									let shortName = item.replaceAll(`${key}.`, '');

									return (
										getFieldValue(fieldValues, docPath, item) ??
										getFieldValue(fieldValues, docPath, shortName) ??
										getFieldValue(fieldValues, namePath, shortName)
									);
								});
							},
							getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
							infoPanelComponentTpl: moduleMethods.getLegalEntityTpl(namePath),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [12, 7, [6, 6], 12, 12, 12, 7],
									padding: '0 24 8 24',
									items: [
										...createPrincipalCompanyPart(
											namePath,
											edi.utils.unflattenObject(objectData ?? {}),
											{},
											true
										)
									],
									listeners: {
										afterrender: function (comp) {
											showBlockFields(comp, data, getRequired());
										}
									}
								}),
							getParentalPoaValue: () => {
								const svUpPred = moduleMethods.getPoaValue({
									path: 'document.dover.svUpPred',
									poaData: parentalPoA
								});
								if (svUpPred?.length > 1) {
									const confidantInn = reTrustBlockInstance.fields?.confidantInn?.getValue();
									const currentSvUpPred = svUpPred.find((upPred) => {
										const upPredInn = edi.utils.getObjectProperty(upPred, 'svOrg.svOrgTip.innyul');
										return confidantInn === +upPredInn;
									});
									return edi.utils.getObjectProperty(currentSvUpPred, 'svOrg.svOrgTip');
								} else {
									return moduleMethods.getPoaValue({
										path: '0.svOrg.svOrgTip',
										poaData: svUpPred
									});
								}
							},
							listeners: {
								afterrender: function (comp) {
									comp.isValid();
								},
								poaTypeChanged: function (selector) {
									defaultSimpleSelectorPoATypeChangedHandler({
										selector,
										values: selector.getParentalPoaValue()
									});

									const [valueField] = Object.values(selector._hiddenFields);
									valueField.name = isPeredov
										? 'data.document.peredov.svLitsPeredPoln.rosOrgPrdPoln'
										: 'data.document.dover.svDoverit.rosOrgDover.svRosOrg';
								},
								parentalPoAStateChanged: function (selector, state) {
									defaultSimpleSelectorParentalPoAStateChangedHandler({
										selector,
										state,
										values: selector.getParentalPoaValue()
									});
								}
							}
						})
					]
				});
				return [companySelector];
			};
			/******
			 * Данные о юридическом лице без доверенности с simpleSelector
			 */
			const createPrincipalOptionalCompanyDataSSBlock = function (
				docPath,
				partData,
				orgType,
				data,
				required,
				defaultData
			) {
				partData = partData?.litsoBezDov?.svOrg || defaultData?.litsoBezDov?.svOrg;
				const additionalPath = 'litsoBezDov.svOrg';
				let namePath = `${docPath}.${additionalPath}`;

				principalOptionalCompanyDataSSBlock = createFieldBlock({
					title: edi.i18n.getMessage('power.of.attorney.principal.delegate.company.info'),
					plugins: [
						createInfoElementPlugin({
							hintConfig: {
								html: edi.i18n.getMessage('power.of.attorney.principal.delegate.company.tooltip')
							},
							refEl: 'titleEl',
							alignItems: 'start'
						})
					],
					items: [
						createSimpleSelector({
							itemId: 'litsoBezDovSvOrg',
							cls: 'ui-org-selector',
							nameable: true,
							name: namePath,
							fieldValues: edi.utils.flattenObject(isEdit || isCopy ? partData : {}),
							showSelectButton: false,
							showEditButton: true,
							allowBlank: true,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.company.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							callback: checkValid,
							isValidFunc: function (fieldValues) {
								//В МЧД с передоверием для ЮЛ нет информации о лице, действующем без доверенности
								if (isPeredov) return true;

								return (
									this.isEmptyValues() ||
									required.every(function (item) {
										return getFieldValue(fieldValues, namePath, item);
									})
								);
							},
							getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
							infoPanelComponentTpl: moduleMethods.getLitsoBezDovUlTpl(namePath),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [12, 7, [6, 6], 9, 12, 7],
									padding: '0 24 8 24',
									items: [
										...createOptionalCompanyPart(
											docPath + '.litsoBezDov.svOrg',
											edi.utils.unflattenObject(objectData ?? {}),
											{},
											1,
											true
										)
									]
								}),
							listeners: {
								poaTypeChanged: function (selector) {
									selector.getFieldBlock().setVisible(!isPeredov);
									selector.isValid();
								}
							}
						})
					]
				});
				return [principalOptionalCompanyDataSSBlock];
			};
			/******
			 * Данные о физическом лице с simpleSelector
			 */
			const createPrincipalCompanyPersonSSBlock = function (
				docPath,
				partData,
				orgType,
				data,
				getRequired,
				defaultData,
				blockTitle
			) {
				const additionalPath = 'litsoBezDov';
				let namePath = `${docPath}.${additionalPath}`;
				partData = partData?.litsoBezDov || defaultData;

				principalCompanyPersonSSBlock = createFieldBlock({
					title: blockTitle || getOrgTypeName(orgType),
					plugins: [
						createInfoElementPlugin({
							hintConfig: {
								html: edi.i18n.getMessage('power.of.attorney.principal.company.person.tooltip')
							},
							refEl: 'titleEl',
							alignItems: 'start'
						})
					],
					items: [
						createSimpleSelector({
							itemId: 'litsoBezDov',
							cls: 'ui-org-selector',
							name: namePath,
							fieldValues: edi.utils.flattenObject(isEdit || isCopy ? partData : {}),
							//заглушка для EW там будет выбор сотрудника
							showSelectButton: 'function' == typeof moduleMethods.selectionModalSvFL,
							selectionModalFunc:
								'function' == typeof moduleMethods.selectionModalSvFL
									? moduleMethods.selectionModalSvFL
									: null,
							showEditButton: true,
							showRequiredIfValid: false,
							allowBlank: false,
							nameable: true,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.individual.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							callback: checkValid,
							isValidFunc: function (fieldValues) {
								//В МЧД с передоверием для ЮЛ нет информации о лице, действующем без доверенности
								if (isPeredov) return true;

								let requiredOrgTip = getRequired().filter((item) => item.includes(additionalPath));
								return requiredOrgTip.every(function (item) {
									let shortName = item.replaceAll(`rosOrgDover.${additionalPath}.`, '');
									return (
										fieldValues[shortName] ||
										getFieldValue(fieldValues, shortName, item) ||
										getFieldValue(fieldValues, namePath, shortName)
									);
								});
							},
							getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
							infoPanelComponentTpl: moduleMethods.getLitsoBezDovFlTpl(namePath),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [12, [6, 6], [6, 6], 7, 8, 7, 12, 12],
									padding: '0 24 8 24',
									items: [
										...createPrincipalCompanyPersonPart(
											docPath + '.litsoBezDov',
											edi.utils.unflattenObject(objectData ?? {}) ?? objectData,
											{},
											5,
											true
										)
									],
									listeners: {
										afterrender: function (comp) {
											showBlockFields(comp, data, getRequired());
										}
									}
								}),
							listeners: {
								afterrender: function (comp) {
									comp.isValid();
								},
								poaTypeChanged: function (selector) {
									selector.getFieldBlock().setVisible(!isPeredov);
									selector.allowBlank = isPeredov;
									selector.isValid();
								}
							}
						})
					]
				});

				return [principalCompanyPersonSSBlock];
			};

			const principalCompanySection = function (fieldsValues) {
				const { dover, svDoverit, rosOrgDover } = moduleMethods.getNameKeys(isPeredov);
				return createForm({
					layout: {
						type: 'grid',
						area: [5, 5, 5],
						gap: [16, 24]
					},
					items: [
						...createPrincipalCompanyDataSSBlock(
							`data.document.${dover}.${svDoverit}.${rosOrgDover}`,
							fieldsValues?.[rosOrgDover],
							fieldsValues.principalOrgType,
							principalDataCompany,
							getPrincipalDataCompanyRequired,
							defaultData?.[rosOrgDover]
						),
						...createPrincipalCompanyPersonSSBlock(
							`data.document.${dover}.${svDoverit}.${rosOrgDover}`,
							fieldsValues?.[rosOrgDover],
							fieldsValues.principalOrgType,
							principalDataCompany,
							getPrincipalDataCompanyRequired,
							defaultData?.[rosOrgDover]?.litsoBezDov,
							edi.i18n.getMessage('power.of.attorney.principal.person.column')
						),
						...createPrincipalOptionalCompanyDataSSBlock(
							`data.document.${dover}.${svDoverit}.${rosOrgDover}`,
							fieldsValues?.[rosOrgDover],
							fieldsValues.principalOrgType,
							principalDataCompany,
							optionalCompanyRequired,
							defaultData?.litsoBezDov
						)
					]
				});
			};

			/******
			 * Данные о юридическом лице с simpleSelector
			 */
			const createPrincipalIndivEntrepreneurCompanyDataSSBlock = function (
				docPath,
				partData,
				orgType,
				data,
				getRequired,
				defaultData
			) {
				partData = partData || defaultData;
				let namePath = docPath;
				let companySelector = createFieldBlock({
					title: getOrgTypeName(orgType),
					items: [
						createSimpleSelector({
							itemId: 'ipDover',
							cls: 'ui-org-selector',
							nameable: true,
							name: namePath,
							fieldValues: edi.utils.flattenObject(isEdit || isCopy ? partData : {}),
							showSelectButton: false,
							showEditButton: true,
							showRequiredIfValid: false,
							allowBlank: false,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.individual.person.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							callback: checkValid,
							isValidFunc: function (fieldValues) {
								let requiredOrgTip = getRequired().filter((item) => !item.includes('svedFL'));
								const { ipDover } = moduleMethods.getNameKeys(isPeredov);
								return requiredOrgTip.every(function (item) {
									let shortName = item.replaceAll(`${ipDover}.`, '');
									return (
										getFieldValue(fieldValues, docPath, item) ||
										getFieldValue(fieldValues, docPath, shortName)
									);
								});
							},
							getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
							infoPanelComponentTpl: moduleMethods.getIpDoverTpl(namePath),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [12, 7, [6, 6], 12, 12, 12, 7],
									padding: '0 24 8 24',
									items: [
										...createPrincipalIndivEntrepreneurDataBlock(
											docPath,
											edi.utils.unflattenObject(objectData ?? {}),
											{},
											1
										)
									],
									listeners: {
										afterrender: function (comp) {
											showBlockFields(comp, data, getRequired());
										}
									}
								}),
							getParentalPoaValue: () =>
								moduleMethods.getPoaValue({
									path: 'document.dover.svUpPred.0.ipDover',
									poaData: parentalPoA
								}),
							listeners: {
								afterrender: function (comp) {
									comp.isValid();
								},
								poaTypeChanged: function (selector) {
									defaultSimpleSelectorPoATypeChangedHandler({
										selector,
										values: selector.getParentalPoaValue()
									});

									const [valueField] = Object.values(selector._hiddenFields);
									valueField.name = isPeredov
										? 'data.document.peredov.svLitsPeredPoln.ipPrdPoln'
										: 'data.document.dover.svDoverit.ipDover';
								},
								parentalPoAStateChanged: function (selector, state) {
									defaultSimpleSelectorParentalPoAStateChangedHandler({
										selector,
										state,
										values: selector.getParentalPoaValue()
									});
								}
							}
						})
					]
				});
				return [companySelector];
			};

			/******
			 * Данные о физическом лице с simpleSelector
			 */
			const createPrincipalIndivEntrepreneurPersonDastaSSBlock = function (
				docPath,
				partData,
				orgType,
				data,
				getRequired,
				defaultData,
				blockTitle
			) {
				const additionalPath = 'svedFL';
				let namePath = `${docPath}.${additionalPath}`;
				let companySelector = createFieldBlock({
					title: blockTitle || getOrgTypeName(orgType),
					items: [
						createSimpleSelector({
							itemId: 'svedFL',
							cls: 'ui-org-selector',
							nameable: true,
							name: namePath,
							fieldValues: edi.utils.flattenObject(isEdit || isCopy ? partData?.svedFL : {}),
							showSelectButton: false,
							showEditButton: true,
							allowBlank: false,
							showRequiredIfValid: false,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.individual.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							callback: checkValid,
							isValidFunc: function (fieldValues) {
								let requiredOrgTip = getRequired().filter((item) => item.includes(additionalPath));
								const { ipDover } = moduleMethods.getNameKeys(isPeredov);
								return requiredOrgTip.every(function (item) {
									let shortName = item.replaceAll(`${ipDover}.${additionalPath}.`, '');
									return (
										getFieldValue(fieldValues, namePath, shortName) ||
										getFieldValue(fieldValues, docPath, shortName)
									);
								});
							},
							getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
							infoPanelComponentTpl: moduleMethods.getIpDoverFlTpl(namePath),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [[5, 7], 8, 12, 7],
									padding: '0 24 8 24',
									items: [
										...createIndividualDataPart(
											docPath + '.svedFL',
											edi.utils.unflattenObject(objectData ?? {}),
											{},
											5,
											true
										)
									],
									listeners: {
										afterrender: function (comp) {
											showBlockFields(comp, data, getRequired());
										}
									}
								}),

							getParentalPoaValue: () =>
								moduleMethods.getPoaValue({
									path: 'document.dover.svUpPred.0.ipDover.svedFL',
									poaData: parentalPoA
								}),
							listeners: {
								afterrender: function (comp) {
									comp.isValid();
								},
								poaTypeChanged: function (selector) {
									defaultSimpleSelectorPoATypeChangedHandler({
										selector,
										values: selector.getParentalPoaValue()
									});

									const [valueField] = Object.values(selector._hiddenFields);
									valueField.name = isPeredov
										? 'data.document.peredov.svLitsPeredPoln.ipPrdPoln.svedFL'
										: 'data.document.dover.svDoverit.ipDover.svedFL';
								},
								parentalPoAStateChanged: function (selector, state) {
									defaultSimpleSelectorParentalPoAStateChangedHandler({
										selector,
										state,
										values: selector.getParentalPoaValue()
									});
								}
							}
						})
					]
				});

				return [companySelector];
			};

			const principalIndivEntrepreneurSection = function (fieldsValues) {
				const { dover, svDoverit, ipDover } = moduleMethods.getNameKeys(isPeredov);
				return createForm({
					layout: {
						type: 'grid',
						area: [5, 5],
						gap: [16, 24]
					},
					items: [
						...createPrincipalIndivEntrepreneurCompanyDataSSBlock(
							`data.document.${dover}.${svDoverit}.ipDover`,
							fieldsValues?.[ipDover],
							fieldsValues.principalOrgType,
							principalDataIndivEntrepreneur,
							getPrincipalDataIndivEntrepreneurRequired,
							defaultData?.[ipDover]
						),
						...createPrincipalIndivEntrepreneurPersonDastaSSBlock(
							`data.document.${dover}.${svDoverit}.ipDover`,
							fieldsValues?.[ipDover],
							fieldsValues.principalOrgType,
							principalDataIndivEntrepreneur,
							getPrincipalDataIndivEntrepreneurRequired,
							defaultData?.[ipDover],
							edi.i18n.getMessage('power.of.attorney.individual.data')
						)
					]
				});
			};

			const createPrincipalForm = function (fieldsValues) {
				fieldsValues = fieldsValues || {};
				let form,
					items = [];

				let principalOrgType = fieldsValues.principalOrgType || currentType;
				fieldsValues.principalOrgType = principalOrgType;

				const { dover, svDoverit, tipDover } = moduleMethods.getNameKeys(isPeredov);
				let tipDoverHiddenField = createHiddenField({
					name: `data.document.${dover}.${svDoverit}.${tipDover}`,
					store: moduleMethods.createPrincipalTypesStore(),
					nameable: true,
					value: principalOrgType,
					listeners: {
						poaTypeChanged: (field) => {
							const { dover, svDoverit, tipDover } = moduleMethods.getNameKeys(isPeredov);
							field.name = `data.document.${dover}.${svDoverit}.${tipDover}`;
						}
					}
				});
				items.push(tipDoverHiddenField);
				switch (principalOrgType) {
					case moduleMethods.defPrincipalType.INDIV_ENTREPRENEUR:
						principalIndivEntrepreneurForm = principalIndivEntrepreneurSection(fieldsValues);
						items = items.concat(principalIndivEntrepreneurForm);
						break;
					case moduleMethods.defPrincipalType.COMPANY:
						principalCompanyForm = principalCompanySection(fieldsValues);
						items = items.concat(principalCompanyForm);
						break;
				}
				form = createForm({
					submitEmptyText: false,
					cls: 'edi-form',
					layout: {
						type: 'grid',
						gap: [16, 24]
					},
					items: items
				});
				return form;
			};

			const getKeyByValue = function (object, value) {
				return Object.keys(object).find((key) => object[key] === value);
			};

			moduleMethods.getSideTypes().forEach(function (item) {
				if (
					item.id === moduleMethods.defPrincipalType.COMPANY ||
					item.id === moduleMethods.defPrincipalType.INDIV_ENTREPRENEUR
				) {
					let menuItem = createMenuItem({
						text: item.name,
						itemId: getKeyByValue(moduleMethods.defPrincipalType, item.id),
						handler: function (comp) {
							let externalAddBtn = comp.up('button');
							externalAddBtn.buttonData = {
								principalOrgType: item.id
							};
							principalBlock.internalAddBtnHandler(externalAddBtn);
						}
					});

					defPrincipalTypes.push(menuItem);
				}
			});

			let config = {
				externalTitle: null,
				internalTitle: null,
				showCount: false,
				internalName: svDoverit,
				isCreate: !isEdit,
				internalInitialData: docContent?.document?.[dover] ? [docContent.document[dover]] : [],
				internalFormFn: createPrincipalForm,
				outerInternalAddBtnHandler: () => {},
				externalAddBtnConfig: {
					permanentlyHidden: true
				},
				internalAddBtnConfig: {
					permanentlyHidden: true
				},
				internalRemoveBtnConfig: {
					permanentlyDisabledInFirstRow: false
				},
				externalRowPadding: '0',
				internalRowPadding: '0',
				createExternalBlockAction: function () {
					return [
						createButton({
							glyph: edi.constants.ICONS.PLUS,
							name: 'item.add.button',
							padding: '0 0 24 24',
							itemId: 'externalActionAddBtn',
							cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
							text: edi.i18n.getMessage('power.of.attorney.add.principal'),
							menu: new Ext.menu.Menu({
								items: defPrincipalTypes
							})
						})
					];
				},
				externalBlockChange: function (me, externalBlock) {
					let internalRows = externalBlock.query('[itemId="internalRow"]');
					let externalActionAddBtn = externalBlock.down('[itemId="externalActionAddBtn"]');

					externalActionAddBtn.setVisible(internalRows.length === 0);
				}
			};
			principalBlock = createRowWithNestedRows(config);

			return [principalBlock];
		};

		return createFieldSet({
			title: edi.i18n.getMessage('power.of.attorney.principal.info'),
			collapsible: true,
			items: getPrincipalBlock()
		});
	};

	/*******
	 * Получить тип организации  (массив из ЮЛ, ФЛ, ИП)
	 */
	const getOrgTypeName = function (id) {
		return moduleMethods.getSideTypes().find(function (item) {
			return item.id === id;
		})?.name;
	};
	/*******
	 * Формирование блока доверенных лиц (массив из ЮЛ, ФЛ, ИП)
	 */
	const getConfidantFieldSet = function () {
		let defConfidantTypes = [],
			confidantBlock;
		// формирование одной записи доверенного лица (на редактирование)
		const getConfidantBlock = function (isFirstRow, recordData) {
			const showBlockFields = function (blockRef, fieldList, requiredList) {
				const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
				for (let i = 0; i < fieldList.length; ++i) {
					let currentNode = blockRef.down(`[name=data.document.${dover}.${svUpPred}.` + fieldList[i] + ']');
					if (currentNode) {
						currentNode.allowBlank = requiredList.indexOf(fieldList[i]) === -1;
						currentNode.enable();
					}
				}
				checkValid();
			};
			/******
			 * Данные о юридическом  лице с simpleSelector (svUpPred)
			 */

			const createCompanyDataBlock = function (docPath, partData, orgType, data, required, defaultData) {
				const additionalPath = 'svOrg.svOrgTip';
				let namePath = `${docPath}.${additionalPath}`;

				let companySelector = createFieldBlock({
					title: getOrgTypeName(orgType),
					items: [
						createSimpleSelector({
							itemId: 'svOrgSvOrgTip',
							cls: 'ui-org-selector',
							name: namePath,
							fieldValues: edi.utils.flattenObject(isEdit || isCopy ? partData : {}),
							showSelectButton: false,
							showEditButton: true,
							allowBlank: false,
							showRequiredIfValid: false,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.company.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							callback: checkValid,
							isValidFunc: function (fieldValues) {
								let requiredOrgTip = required.filter((item) => item.includes(additionalPath));
								return requiredOrgTip.every(function (item) {
									let shortName = item.replaceAll(`${additionalPath}.`, '');
									return (
										getFieldValue(fieldValues, docPath, item) ||
										getFieldValue(fieldValues, docPath, shortName)
									);
								});
							},
							getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
							infoPanelComponentTpl: moduleMethods.getSvUpPredTpl(namePath),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [12, 7, [6, 6], 12, 9, 12, 7],
									padding: '0 24 8 24',
									items: [
										...createRusCompanyDataPart(
											namePath,
											edi.utils.unflattenObject(objectData ?? {}),
											{},
											1
										)
									],
									listeners: {
										afterrender: function (comp) {
											showBlockFields(comp, data, required);
										}
									}
								}),
							listeners: {
								afterrender: function (comp) {
									comp.isValid();
								}
							}
						})
					]
				});
				return [companySelector];
			};

			/******
			 * Данные о физическом лице с simpleSelector (svedFizL)
			 */
			const createIndividualPersonDataSSBlock = function (
				docPath,
				additionalPath,
				partData,
				orgType,
				data,
				required,
				defaultData,
				blockTitle
			) {
				let namePath = `${docPath}.${additionalPath}`;
				let companySelector = createFieldBlock({
					title: blockTitle || getOrgTypeName(orgType),
					items: [
						createSimpleSelector({
							itemId: additionalPath.replace('.', ''),
							cls: 'ui-org-selector',
							name: namePath,
							fieldValues: edi.utils.flattenObject(isEdit || isCopy ? partData : {}),
							//заглушка для EW там будет выбор сотрудника
							showSelectButton: 'function' == typeof moduleMethods.selectionModalFl,
							selectionModalFunc:
								'function' == typeof moduleMethods.selectionModalFl
									? moduleMethods.selectionModalFl
									: null,
							showEditButton: true,
							showRequiredIfValid: false,
							allowBlank: false,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.individual.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							callback: checkValid,
							isValidFunc: function (fieldValues) {
								fieldValues = edi.utils.unflattenObject(fieldValues ?? {});
								let requiredOrgTip = required.filter((item) => item.includes(additionalPath));
								return requiredOrgTip.every(function (item) {
									let shortName = item.replaceAll(`${additionalPath}.`, '');

									return (
										getFieldValue(fieldValues, docPath, item) ??
										getFieldValue(fieldValues, docPath, shortName) ??
										getFieldValue(fieldValues, namePath, shortName)
									);
								});
							},
							getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
							infoPanelComponentTpl: moduleMethods.getSvUpPredFlTpl(namePath),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [12, [6, 6], [6, 6], 7, 8, 7, 7],
									padding: '0 24 8 24',
									items: [
										...createIndividualPersonDataBlock(
											namePath,
											edi.utils.unflattenObject(objectData ?? {}),
											{},
											5,
											true
										)
									],
									listeners: {
										afterrender: function (comp) {
											showBlockFields(comp, data, required);
										}
									}
								}),
							listeners: {
								afterrender: function (comp) {
									comp.isValid();
								}
							}
						})
					]
				});

				return [companySelector];
			};

			const confidantCompanySection = function (fieldsValues) {
				const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
				return createForm({
					layout: {
						type: 'grid',
						area: [5, 5],
						gap: [16, 24]
					},
					items: [
						...createCompanyDataBlock(
							`data.document.${dover}.${svUpPred}`,
							fieldsValues?.svOrg?.svOrgTip,
							fieldsValues.confidantOrgType,
							confidantDataCompany,
							confidantDataCompanyRequired,
							undefined
						),
						...createIndividualPersonDataSSBlock(
							`data.document.${dover}.${svUpPred}`,
							'svOrg.svFL',
							fieldsValues?.svOrg?.svFL?.[0],
							fieldsValues.confidantOrgType,
							confidantDataCompany,
							confidantDataCompanyRequired,
							undefined,
							edi.i18n.getMessage('power.or.attorney.individual.data')
						)
					]
				});
			};

			const confidantIndividualSection = function (fieldsValues) {
				const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
				return createForm({
					layout: {
						type: 'grid',
						area: [5],
						gap: [16, 24]
					},
					items: [
						...createIndividualPersonDataSSBlock(
							`data.document.${dover}.${svUpPred}`,
							'svedFizL',
							fieldsValues?.svedFizL,
							fieldsValues.confidantOrgType,
							confidantDataIndividual,
							confidantDataIndividualRequired,
							undefined,
							undefined
						)
					]
				});
			};

			const createConfidantForm = function (fieldsValues) {
				fieldsValues = fieldsValues || {};
				let form,
					items = [];
				let confidantOrgType = fieldsValues.confidantOrgType || fieldsValues.tipPred;
				fieldsValues.confidantOrgType = confidantOrgType;
				const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
				let tipPredHiddenField = createHiddenField({
					name: `data.document.${dover}.${svUpPred}.tipPred`,
					store: moduleMethods.createSidesStore(),
					nameable: true,
					shareableName: true,
					value: confidantOrgType,
					listeners: {
						poaTypeChanged: (field) => {
							const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
							field.name = `data.document.${dover}.${svUpPred}.tipPred`;
						}
					}
				});
				items.push(tipPredHiddenField);
				switch (confidantOrgType) {
					case moduleMethods.defConfidantType.INDIVIDUAL:
						confidantIndividualForm = confidantIndividualSection(fieldsValues);
						items = items.concat(confidantIndividualForm);
						break;
					case moduleMethods.defConfidantType.COMPANY:
						confidantCompanyForm = confidantCompanySection(fieldsValues);
						items = items.concat(confidantCompanyForm);
						break;
				}
				form = createForm({
					submitEmptyText: false,
					layout: {
						type: 'grid',
						gap: [16, 24]
					},
					items: items
				});
				return form;
			};

			const getKeyByValue = function (object, value) {
				return Object.keys(object).find((key) => object[key] === value);
			};

			moduleMethods.getSideTypes().forEach(function (item) {
				if (
					item.id === moduleMethods.defPrincipalType.COMPANY ||
					item.id === moduleMethods.defPrincipalType.INDIVIDUAL
				) {
					let menuItem = createMenuItem({
						text: item.name,
						itemId: getKeyByValue(moduleMethods.defConfidantType, item.id),
						handler: function (comp) {
							let externalAddBtn = comp.up('button');
							externalAddBtn.buttonData = {
								confidantOrgType: item.id
							};
							confidantBlock.internalAddBtnHandler(externalAddBtn);
						}
					});

					defConfidantTypes.push(menuItem);
				}
			});

			const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
			let config = {
				externalTitle: null,
				internalTitle: null,
				showCount: false,
				internalName: svUpPred,
				isCreate: !isEdit,
				internalInitialData: docContent?.document?.[dover] ? [docContent?.document?.[dover]] : [],
				internalFormFn: createConfidantForm,
				outerInternalAddBtnHandler: () => {},
				isAddFirstDefaultRow: isEdit || isCopy,
				externalAddBtnConfig: {
					permanentlyHidden: true
				},
				externalRemoveBtnConfig: {
					setDisabledButton: function (me, externalRemoveBtn) {
						let rows = me.items.items;
						rows.forEach((externalRow) => {
							let externalRowContent = externalRow.down('[itemId="externalRowContent"]');
							if (!!externalRowContent) {
								let internalRows = externalRowContent.items.items;
								if (internalRows.length > 1) {
									externalRemoveBtn.setDisabled(false);
								}
							}
						});
					}
				},
				internalAddBtnConfig: {
					permanentlyHidden: true
				},
				internalRemoveBtnConfig: {
					permanentlyDisabledInFirstRow: false
				},
				isShowInternalDeleteBtn: !(isEdit || isCopy),
				externalRowPadding: '0',
				internalRowPadding: '0',
				createExternalBlockAction: function () {
					return [
						createContainer({
							layout: 'vbox',
							items: [
								(poaCollectivePowersWarn = createLabel({
									cls: 'poa-collective-powers-warn',
									text: edi.i18n.getMessage('power.of.attorney.collective.powers.warning')
								})),
								createButton({
									glyph: edi.constants.ICONS.PLUS,
									name: 'item.add.button',
									padding: '0 0 24 24',
									itemId: 'externalActionAddBtn',
									cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
									text: edi.i18n.getMessage('power.of.attorney.add.confidant'),
									menu: new Ext.menu.Menu({
										items: defConfidantTypes
									})
								})
							]
						})
					];
				},
				externalBlockChange: function (me, externalBlock) {
					let externalActionAddBtn = externalBlock.down('[itemId="externalActionAddBtn"]');
					externalActionAddBtn.setVisible(!(isEdit || isCopy));
					showPoaCollectivePowers();
				}
			};
			confidantBlock = createRowWithNestedRows(config);

			return [confidantBlock];
		};

		return createFieldSet({
			title: edi.i18n.getMessage('power.of.attorney.confidant.info'),
			collapsible: true,
			items: getConfidantBlock()
		});
	};

	const showPoaCollectivePowers = function () {
		let formValues = form.getValues();
		const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
		let confidantTypeValue = formValues[`data.document.${dover}.${svUpPred}.tipPred`];
		confidantTypeValue = Array.isArray(confidantTypeValue) ? confidantTypeValue : [confidantTypeValue];
		let poaCollectivePowersComboValue = poaCollectivePowersCombo.getValue();
		if (poaCollectivePowersComboValue === '2') {
			poaCollectivePowersWarn.setVisible(confidantTypeValue.length < 2);
		} else {
			poaCollectivePowersWarn.setVisible(false);
		}
		checkValid();
	};

	const save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));

		let formValues = form.getValues();
		let confidantForm, principalForm;
		const { dover, svDoverit, tipDover, svUpPred } = moduleMethods.getNameKeys(isPeredov);

		let confidantTypeValue = formValues[`data.document.${dover}.${svUpPred}.tipPred`];
		switch (formValues[`data.document.${dover}.${svDoverit}.${tipDover}`]) {
			case moduleMethods.defPrincipalType.INDIV_ENTREPRENEUR:
				principalForm = principalIndivEntrepreneurForm;
				break;
			case moduleMethods.defPrincipalType.COMPANY:
				principalForm = principalCompanyForm;
				break;
		}

		let principalFormValues = principalForm.getValues();

		const createPlainObj = (nested, path = '') =>
			Object.entries(nested).reduce((obj, [k, v]) => {
				const newPath = `${path}${path ? '.' : ''}${k}`;
				return Object.assign(obj, v instanceof Object ? createPlainObj(v, newPath) : { [newPath]: v });
			}, {});

		const addRowWithNestedRowsValuesToFormValues = function (values) {
			for (let key in values) {
				let valuesObj = values[key] || {};
				let keys = Object.keys(valuesObj);
				let isEveryContainKey = keys.every(function (item) {
					return item.includes(key);
				});
				if (isEveryContainKey) {
					Object.assign(formValues, !edi.utils.isEmptyObject(valuesObj) ? valuesObj : values);
				} else {
					Object.assign(formValues, createPlainObj(valuesObj, key));
				}
			}
		};

		let confidantTypes;

		const checkIsEveryContainKey = function (data, path) {
			let keys = Object.keys(data);
			let isEveryContainKey = keys.every(function (item) {
				return item.includes(path);
			});

			return isEveryContainKey;
		};
		const reconfigureConfidantFormValues = function (type, index) {
			switch (type) {
				case moduleMethods.defConfidantType.INDIVIDUAL:
					confidantForm = confidantIndividualForm;
					confidantTypes = ['svedFizL'];
					break;
				case moduleMethods.defConfidantType.COMPANY:
					confidantForm = confidantCompanyForm;
					confidantTypes = ['svOrg.svFL', 'svOrg.svOrgTip'];
					break;
			}
			if (Array.isArray(confidantTypes)) {
				const { dover, svUpPred } = moduleMethods.getNameKeys(isPeredov);
				confidantTypes.forEach(function (type) {
					let path = `data.document.${dover}.${svUpPred}.` + type;
					let confFormValues = formValues[path];
					if (confFormValues && Array.isArray(confFormValues)) {
						confFormValues = confFormValues.map(function (item) {
							let isEveryContainKey = checkIsEveryContainKey(item, path);

							if (!isEveryContainKey) {
								return createPlainObj(item, path);
							} else {
								return item;
							}
						});

						const convertConfFormValues = function (arr) {
							const keys = [...new Set(arr.flatMap(Object.keys))];
							const result = arr.reduce(
								(acc, n, i) => (keys.forEach((k) => (acc[k][i] = n[k] ?? null)), acc),
								Object.fromEntries(keys.map((n) => [n, []]))
							);

							return result;
						};
						Object.assign(formValues, convertConfFormValues(confFormValues));
						delete formValues[path];
					} else {
						let isEveryContainKey = formValues[path]
							? checkIsEveryContainKey(formValues[path], path)
							: true;
						if (!isEveryContainKey) {
							Object.assign(formValues, createPlainObj(formValues[path], path));
						} else {
							Object.assign(formValues, formValues[path]);
						}
						delete formValues[path];
					}
				});
			}
		};

		if (Array.isArray(confidantTypeValue)) {
			confidantTypeValue.forEach(function (type, index) {
				reconfigureConfidantFormValues(type, index);
			});
		} else {
			reconfigureConfidantFormValues(confidantTypeValue, null);
		}

		addRowWithNestedRowsValuesToFormValues(principalFormValues);

		let powerPack = [];

		if (Array.isArray(formValues.soderzhPoln) && Array.isArray(formValues.kodPoln)) {
			for (let i = 0; i < formValues.soderzhPoln.length; ++i) {
				powerPack.push({
					kodPoln: formValues.kodPoln[i] === 'NONE' ? null : formValues.kodPoln[i],
					soderzhPoln: formValues.kodPoln[i] === 'NONE' ? null : formValues.soderzhPoln[i],
					tekstPoln: formValues.kodPoln[i] !== 'NONE' ? null : formValues.tekstPoln[i],
					dopTekstPoln: formValues.dopTekstPoln[i] ? formValues.dopTekstPoln[i] : undefined
				});
			}
		} else {
			powerPack.push({
				kodPoln: formValues.kodPoln === 'NONE' ? null : formValues.kodPoln,
				soderzhPoln: formValues.kodPoln === 'NONE' ? null : formValues.soderzhPoln,
				tekstPoln: formValues.kodPoln !== 'NONE' ? null : formValues.tekstPoln,
				dopTekstPoln: formValues.dopTekstPoln ? formValues.dopTekstPoln : undefined
			});
		}

		let principalPack = [makeOnePrincipal(formValues, `data.document.${dover}.${svDoverit}`)];

		let confidantPack;

		if (Array.isArray(formValues[`data.document.${dover}.${svUpPred}.tipPred`])) {
			confidantPack = collectConfidants(formValues, `data.document.${dover}.${svUpPred}`);
		} else {
			confidantPack = [makeOneConfidant(formValues, `data.document.${dover}.${svUpPred}`)];
		}

		if (!formValues.dataKonDover) {
			//Если не указана "Действительна по" (dataKonDover) берем значение "Действительна с" (dataVidDover) + 1 год.
			const vidDoverDate = new Date(formValues.dataVidDover);
			vidDoverDate.setFullYear(vidDoverDate.getFullYear() + 1);
			formValues.dataKonDover = edi.utils.formatDate(vidDoverDate);
		}

		const doverData = docContent?.document?.[dover];
		const svDovData = isPeredov ? doverData?.svDovPer?.svPereDover : doverData?.svDov;
		const svDov = {
			// сведения о доверенности
			bezotziv: {
				// opisUslOt: 'revoking',
				prBezotziv: formValues.prBezotziv
				// prPerBezDov: '1',
				// uslOtziva: '1'
			},
			svedSistOtm: svDovData?.svedSistOtm || 'https://m4d.nalog.gov.ru/',
			doverRegNumber: svDovData?.doverRegNumber || moduleMethods.DEFAULT_FNS_NUMBER,
			vnNomDover: formValues.vnNomDover,
			dataVnRegDover: svDovData?.dataVnRegDover || Ext.Date.format(new Date(), 'Y-m-d'),
			dataVidDover: formValues.dataVidDover,
			dataKonDover: formValues.dataKonDover,
			prPeredov: formValues.prPeredov,
			prUtrPoln: formValues.prUtrPoln,
			prSovmPoln: formValues.prSovmPoln
		};
		const doverSaveObj = !isPeredov
			? {
					svDov,
					svDoverit: principalPack,
					svUpPred: confidantPack,
					svPoln: powerPack
					// и ещё тут будет svNotUd - нотариальное удостоверение
			  }
			: null;
		const isParentalPeredov = !!parentalPoA?.document?.peredov;
		const svDoveritData = isEdit
			? edi.utils.getObjectProperty(docContent, 'document.peredov.svDovPer.svOsnDover.svDovers', true)
			: isParentalPeredov
			? edi.utils.getObjectProperty(parentalPoA, 'document.peredov.svDovPer.svOsnDover.svDovers', true)
			: edi.utils.getObjectProperty(parentalPoA, 'document.dover.svDoverit', true).map((dover) => {
					if (dover.ipDover && !dover.ipDover.fio) {
						dover.ipDover.fio = {
							lastName: '-',
							firstName: '-',
							middleName: '-'
						};
					}

					return {
						inOrgDover: dover.inOrgDover,
						tipDover: dover.tipDover,
						flDover: dover.flDover,
						rosOrgDover: dover.rosOrgDover?.svRosOrg,
						ipDover: dover.ipDover
					};
			  });
		const peredovSaveObj = isPeredov
			? {
					svLitsPeredPoln: principalPack.map((principal) => {
						if (!isEdit && !isCopy) {
							principal.podpisant = moduleMethods.getPoaValue({
								path: 'document.dover.svDoverit.0.podpisant',
								poaData: parentalPoA
							});
						}
						return principal;
					}),
					svDovPer: {
						svOsnDover: {
							//Номер первоначальной доверенности
							doverNumberMain: isEdit
								? docContent.document?.peredov?.svDovPer?.svOsnDover?.doverNumberMain
								: isParentalPeredov
								? edi.utils.getObjectProperty(parentalPoA, 'document.peredov.svDovPer.nomDoverN')
								: edi.utils.getObjectProperty(parentalPoA, 'document.dover.svDov.doverRegNumber'),
							svDovers: svDoveritData
						},
						//Номер родительской доверенности
						nomDoverN: isEdit
							? docContent?.document?.peredov?.svDovPer?.nomDoverN
							: moduleMethods.getPoaValue({
									path: 'document.dover.svDov.doverRegNumber',
									poaData: parentalPoA
							  }),
						svPereDover: svDov
					},
					svPoln: powerPack,
					svLitsPoluchPoln: confidantPack
			  }
			: null;

		const saveObj = {
			docType: edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY,
			toOrgId: userData.org.id,
			versionId: '002',
			data: {
				inSved: docContent?.inSved || 'other data',
				fileId: docContent?.fileId || 'SdLbXjXOMXaG7jr',
				versForm: docContent?.versForm || '002',
				document: {
					okud: docContent?.document?.okud || '0251222',
					peredov: peredovSaveObj,
					dover: doverSaveObj
				}
			}
		};

		saveObj.data = Ext.encode(saveObj.data);

		const success = function (resp) {
			moduleData.tab.setLoading(false);
			moduleData.tab.close();
			let docData = isEdit ? docHeader : resp.data;
			let docId = isEdit ? docHeader.id : resp.data.id;
			edi.events.powerOfAttorney.fireEvent('change', {
				id: docData?.docId
			});
			const titlePostfix = docContent?.document?.dover?.svDov?.vnNomDover;
			if (userData?.personalId) {
				edi.core.openModule('power.of.attorney.details', docData, titlePostfix, isEdit, docId);
			}
		};

		const failure = function (response) {
			moduleData.tab.setLoading(false);
			edi.core.showError(edi.utils.formatComplexServerError(response, 'power.of.attorney.error.saving'));
		};

		if (!!docId && !isCopy) {
			let updateObj = {
				UPDATE: true,
				UPDATE_PARAMS: saveObj
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.PUT, {
					documentId: docId
				}),
				'PUT',
				Ext.encode(updateObj),
				success,
				failure,
				null
			);
		} else {
			edi.rest.sendRequest(
				edi.rest.services.POWER_OF_ATTORNEY.POST,
				'POST',
				Ext.encode(saveObj),
				success,
				failure,
				null
			);
		}
	};

	const renderData = function (initCallBack) {
		const finishRender = function () {
			let modulePanel = createAddModulePanel();

			modulePanel.add(createModuleForm());
			moduleData.tab.add(modulePanel);

			if (initCallBack && typeof initCallBack === 'function') {
				initCallBack();
			}
		};

		if (docId && !isCopy) {
			moduleMethods
				.fetchData({
					poaId: docId,
					fromSignature: moduleData.initData.fromSignature,
					docId: moduleData.initData.signedWithPoaDocId
				})
				.then(function ({ header, content }) {
					docHeader = header;
					docContent = content;
					moduleData.initData.data = docHeader;
					isPeredov = !Ext.Object.isEmpty(docContent?.document?.peredov);

					finishRender();
				})
				.catch(function (resp) {
					moduleData.tab.close();
					edi.rest.getErrorHandler()(resp);
				});
		} else {
			finishRender();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
