import { createFieldBlockForDetails } from '@UIkit/components/panels';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { CONTAINER_COMMONPRICAT_LINE_MODEL, getAddressesModelName } from './models';
import { CONTAINER_COMMONPRICAT_LINE_COLUMN, getAddressesColumns } from './columns';
import { containerCommonPricatSign } from './containerCommonPricatSign';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
/**
 * Class for container for common_pricat details
 * @author Konstantin Starikov
 */
Ext.namespace('edi.modules');
edi.modules['document.details.container_common_pricat'] = function () {
	let moduleData, docHeader;
	let childrenDocuments = [];
	let firstChildContent = {};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called after initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data || {};

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates actions panel
	 * @return	{Object}	panel with actions buttons
	 */
	let createModuleActionsPanel = function () {
		let actionsPanel = createActionsPanel();

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: docHeader,
			direction: edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg),
			moduleData: moduleData,
			needSignatures: edi.document.actions.getSignCount(docHeader),
			actionProps: {
				SIGN: {
					label: edi.i18n.getMessage('document.sign.document'),
					methodAddOptions: {
						buttonProps: {
							handler() {
								containerCommonPricatSign(docHeader, moduleData.tab);
							}
						}
					}
				},
				REFRESH: {
					handler: changeHandler
				},
				DELETE: {
					success() {
						moduleData.tab.close();
						edi.events.documents.fireEvent('change', {
							id: docHeader.id
						});
					}
				},
				REJECT: {
					success() {
						edi.events.documents.fireEvent('change', {
							id: docHeader.id
						});
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					hideDefaultExport: true,
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					addExtendedExport: true,
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: docHeader.id
					})
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Creates grid with addresses
	 * @return	{Object}	addresses grid
	 */
	let createAddressesGrid = function () {
		let addresses = childrenDocuments
			.filter(
				(child) =>
					edi.utils.getAttributeByName(child.attributes, 'OrganizationName') ||
					edi.utils.getAttributeByName(child.attributes, 'OrganizationAddress')
			)
			.map((child) => ({
				name: edi.utils.getAttributeByName(child.attributes, 'OrganizationName'),
				address: edi.utils.getAttributeByName(child.attributes, 'OrganizationAddress')
			}));

		let columns = getAddressesColumns();
		columns.push(createActionsColumnConfig({}));

		return createGrid({
			storeConfig: {
				proxy: createProxyConfig({
					type: 'memory',
					data: addresses
				}),
				model: getAddressesModelName()
			},
			gridConfig: {
				title: edi.i18n.getMessage('document.details.container_common_pricat.addr_grid.title'),
				columns: columns,
				disablePaging: true,
				maxHeight: 300
			}
		});
	};

	/**
	 * Creates grid with product values
	 * @return	{Object}	products grid
	 */
	let createProductsGrid = function () {
		let lines = edi.utils.getObjectProperty(firstChildContent, 'PriceCatalogue-Lines.Line', true);
		let products = (Array.isArray(lines) && lines.map((l) => l['Line-Item'])) || [];

		return createProductGridBase({
			title: edi.i18n.getMessage('document.common.pricat.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: CONTAINER_COMMONPRICAT_LINE_MODEL,
			gridColumnConfig: CONTAINER_COMMONPRICAT_LINE_COLUMN,
			hasTotal: false,
			data: products,
			readOnly: true,
			modalFormConfig: {
				title: 'grid.line.items',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'BASIC',
						items: [
							{
								title: edi.i18n.getMessage('document.slsfct.column.column.barCode'),
								name: 'EAN'
							},
							{
								title: edi.i18n.getMessage('common.pricat.productName'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitOfMeasure'),
								maxLength: 175,
								type: 'combo',
								store: edi.stores.createSimpleInlineStore(
									['PCE', 'KGM', 'MGM', 'MLT', 'MMT'],
									function (id) {
										return edi.i18n.getMessage('common.pricat.unitOfMeasure.' + id);
									}
								),
								name: 'UnitOfMeasure'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.brand'),
								name: 'Brand'
							},
							{
								title: edi.i18n.getMessage('line.item.сampaign'),
								type: 'combo',
								store: edi.stores.initYesOrNoStore(),
								name: 'Campaign'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						customFieldTab: 'PRICES',
						items: [
							{
								title: edi.i18n.getMessage('common.pricat.price.segment'),
								name: 'SegmentPrice'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.shop.incoming.price'),
								name: 'UnitNetPrice'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'UnitGrossPrice'
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.initUsnTaxRatesWithFractStore()
							},
							{
								title: edi.i18n.getMessage('line.item.discount'),
								name: 'Discount'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.suggested.price'),
								name: 'SuggestedPrice'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.special.price'),
								name: 'SpecialPrice'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.data.packing'),
						customFieldTab: 'PACKAGING',
						items: [
							{
								title: edi.i18n.getMessage('line.item.min.ordered.quantity'),
								name: 'PackDetails.MinOrderedQuantity'
							},
							{
								title: edi.i18n.getMessage('pricat.product.weight'), // !!!
								name: 'PackDetails.Weight'
							},
							{
								title: edi.i18n.getMessage('pricat.product.length'), // !!!
								name: 'PackDetails.Length'
							},
							{
								title: edi.i18n.getMessage('pricat.product.width'), // !!!
								name: 'PackDetails.Width'
							},
							{
								title: edi.i18n.getMessage('pricat.product.height'), // !!!
								name: 'PackDetails.Height'
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitPacksize'), // !!!
								name: 'PackDetails.UnitPacksize'
							}
						]
					}
				]
			}
		});
	};

	/**
	 * Creates panel with fields and grids
	 * @returns	{Object}	panel with fields and grids
	 */
	let createDetailsPanel = function () {
		const numberDataBlock = function () {
			const headerLabelText = [
				{
					title: edi.i18n.getMessage('common.pricat.number'),
					text: edi.utils.getObjectProperty(headData, 'PriceCatalogueNumber')
				},
				{
					title: edi.i18n.getMessage('column.date'),
					text: edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(headData, 'PriceCatalogueDate'))
				}
			];

			return createFieldBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						contents: headerLabelText
					})
				]
			});
		};

		const createPeriodDataBlock = function () {
			let dateStart = edi.utils.getObjectProperty(firstChildContent, 'PriceCatalogue-Header.PeriodStartDate');
			let dateEnd = edi.utils.getObjectProperty(firstChildContent, 'PriceCatalogue-Header.PeriodEndDate');

			return createFieldBlockForDetails({
				title: edi.i18n.getMessage('pricat.document.period'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelForDetails({
						date: [edi.renderers.fnsDateFromClient(dateStart), edi.renderers.fnsDateFromClient(dateEnd)]
					})
				]
			});
		};

		const createAdditionalDataBlock = function () {
			const headerLabelText = [
				{
					title: edi.i18n.getMessage('common.pricat.price.segment'),
					text: priceSegmentText
				},
				{
					title: edi.i18n.getMessage('column.catalog.type'),
					text: edi.utils.getObjectProperty(headData, 'DocumentNameCode')
				},
				{
					title: edi.i18n.getMessage('functional.document.code'),
					text: edi.i18n.getMessage(
						'common.pricat.functional.document.code.' +
							edi.utils.getObjectProperty(firstChildContent, 'PriceCatalogue-Header.DocumentFunctionCode')
					),
					isNewLine: true
				},
				{
					title: edi.i18n.getMessage('documents.column.contractNumber'),
					text: edi.utils.getObjectProperty(
						firstChildContent,
						'PriceCatalogue-Header.Reference.ContractNumber'
					),
					isNewLine: true
				},
				{
					title: edi.i18n.getMessage('document.sign.until'),
					text: signUntilElement['Reference-Date'],
					isNewLine: true
				}
			];

			return createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.ordersp.section.additional'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						contents: headerLabelText
					})
				]
			});
		};

		let headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing)
		});

		let headData = edi.utils.getObjectProperty(firstChildContent, 'PriceCatalogue-Header') || {};

		let pickReferenceElementByType = function (elemArray, typeName) {
			let result = {};
			elemArray?.forEach(function (elem) {
				let found = false;
				if (elem['Reference-Type'] === typeName) {
					result = elem;
					found = true;
				}
				return !found;
			});
			return result;
		};

		let refElements = edi.utils.getObjectProperty(headData, 'Reference.Reference-Elements');

		const remarks = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(firstChildContent, 'PriceCatalogue-Header.Remarks')
				})
			]
		});

		let priceSegmentElement = pickReferenceElementByType(refElements, 'SBA');
		let priceSegmentText = priceSegmentElement.AdditionalData
			? priceSegmentElement.AdditionalData.Content || ''
			: '';

		let signUntilElement = pickReferenceElementByType(refElements, '175');

		let buyer = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.document.recipient'),
			items: [
				createOrgSelector({
					readOnly: true,
					valuesByMap: false,
					is_valid: true,
					fieldValues: edi.converters.convertOrgToPartie(docHeader.toOrg)
				})
			]
		});

		let seller = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.document.sender'),
			items: [
				createOrgSelector({
					readOnly: true,
					valuesByMap: false,
					is_valid: true,
					fieldValues: edi.converters.convertOrgToPartie(docHeader.fromOrg)
				})
			]
		});

		let productsGrid = createProductsGrid();

		return createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6]]
			},
			items: [
				headPanel,
				numberDataBlock(),
				seller,
				buyer,
				createPeriodDataBlock(),
				createAdditionalDataBlock(),
				remarks,
				createAddressesGrid(),
				productsGrid
			]
		});
	};

	/**
	 * Change handler, that will initiate refresh of module
	 */
	let changeHandler = function () {
		edi.document.actions.changeHandler(
			docHeader,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				docHeader = headerData.data;
			},
			renderData
		);
	};

	/**
	 * Returns children of this container
	 * @return	{Promise<Object[]>}		children's headers
	 */
	let getChildren = () =>
		new Promise((resolve, reject) => {
			let success = function (respData) {
				if (respData && respData.data.children && respData.data.children.length) {
					resolve(respData.data.children);
				} else {
					reject(respData);
				}
			};
			let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: docHeader.id
			});
			edi.rest.sendRequest(url, 'GET', undefined, success, reject);
		});

	/**
	 * Returns first child's content
	 * @return	{Promise<Object>}	first child's content
	 */
	let getFirstChildrenContent = () =>
		new Promise((resolve, reject) => {
			let success = function (response) {
				if (response && response.data) {
					resolve(response.data);
				} else {
					reject(response);
				}
			};
			let commonPricat = childrenDocuments.find((x) => x.type === edi.constants.DOCUMENT_TYPES.COMMON_PRICAT);
			if (!commonPricat) {
				reject({});
			}
			let url = edi.document.actions.formatDetailsUri(commonPricat);
			edi.rest.sendRequest(url, 'GET', {}, success, reject);
		});

	/**
	 * Renders module layout
	 * @param	{Function}	initCallBack	callback that must be called after initialization
	 */
	let renderData = async function (initCallBack) {
		moduleData.tab.removeAll();
		moduleData.tab.setLoading();

		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', () =>
			edi.modulesHandler.removeModule(moduleData)
		);

		try {
			childrenDocuments = await getChildren();
		} catch (response) {
			failure(response);
			return;
		}

		try {
			firstChildContent = await getFirstChildrenContent();
		} catch (response) {
			failure(response);
			return;
		}

		moduleData.tab.add(createModuleActionsPanel());
		let modulePanel = createDetailsModulePanel();
		modulePanel.add(createDetailsPanel());
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		} else {
			moduleData.tab.setLoading(false);
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return	{Boolean}	false to stop module destroy
	 */
	let onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		return true;
	};
};
