import { createIndividualModal, getClientTariffCard } from '@Ediweb/modules/ORG_PROFILE/tabs/TARIFFS/utils';
import { showInfoToast, showWarningToast } from '@Ediweb/core';
import { createCarousel } from '@Ediweb/components/carousel';
import { createModalPanel, showConfirmWithException } from '@UIkit/components/modal';
import { createLabel } from '@UIkit/components/fields';
import { createContainer, createModalForm, createPanel } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { showConfirm } from '@UIkit/components/modal/MessageBox';
import { createExceptionBlock } from '@UIkit/components/blocks/ExceptionBlock';

const createTariffsPage = function () {
	let panel,
		actualPOATariffs,
		isLastUsedUpPoaTariff = true,
		needNewTariffLabel,
		tariffCarousel,
		myTariffsCardsLabel,
		actualTariffCarousel,
		myPoaTariffCarousel,
		poaTariffCarousel,
		poaTariffCarouselTitle,
		needNewPoaTariffLabel,
		myPoaTariffsCardsLabel,
		confirmWithException,
		buyPoaModal;
	let failure = function (data) {
		edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
	};

	let createActualCarouselPanel = function (actualTariffs) {
		if (!!panel.isDestroyed) {
			return;
		}

		let myCards = [];

		let userData = edi.core.getUserData();
		let withoutTariff = edi.utils.getAttributeByName(userData?.org?.attributes, 'WITHOUT_TARIFF') === 'true';
		if (withoutTariff) {
			myTariffsCardsLabel.setVisible(false);
			actualTariffCarousel.setVisible(false);
			needNewTariffLabel.setVisible(false);
			edi.constants.IS_CAN_CREATE_DOCUMENT = true;
			edi.constants.IS_EXPIRED_DOCUMENT_TARIFF = false;
			return;
		}

		myTariffsCardsLabel.setVisible(actualTariffs.length > 0);
		actualTariffCarousel.setVisible(actualTariffs.length > 0);
		let isLastUsedUpTariff =
			actualTariffs.length === 1 && actualTariffs[0].remainDoc === 0 && !actualTariffs[0]?.tariff?.unlimited;
		needNewTariffLabel.setVisible(isLastUsedUpTariff);
		actualTariffs.forEach(function (tariffData, index) {
			if (tariffData.tariff) {
				let tariff = tariffData.tariff;
				let state = null;
				if (new Date(tariffData?.duration?.periodFrom) > new Date()) {
					state = 'FUTURE';
				} else if (index === 0 && !tariffData.wasted) {
					state = 'ACTIVE';
				}
				if (tariff.tariffCost === 0 && index > 0) {
					state = 'PROMO';
				}
				if ((tariffData.remainDoc === 0 && !tariff.unlimited) || tariffData.wasted) {
					state = 'SPEND';
				}
				if (tariff.expired) {
					state = 'EXPIRED';
				}
				let data = {
					remainDoc: tariffData.remainDoc,
					documentsNumber: tariff.documentsNumber,
					documentCost: tariff.documentCost,
					unlimited: tariff.unlimited,
					duration: tariffData.duration,
					wasted: tariffData.wasted,
					state: state
				};
				let cardTitle = tariff.tariffUiName;
				let myTariffCard = getClientTariffCard(cardTitle, data, isLastUsedUpTariff);

				myCards.push(myTariffCard);
			}
		});

		actualTariffCarousel.cards = myCards;
		actualTariffCarousel.createCards();
	};

	const onAfterCheckSuccess = function (classification, data) {
		if (classification === 'POWER_OF_ATTORNEY') {
			buyPoaTariff(data);
		} else {
			showBuyConfirmWithException(classification, data);
		}
	};

	const checkBeforeBuyTariff = function (classification, data) {
		const toastTitle = edi.i18n.getMessage('ediweb.tariffs.check.buy.request.toast.title');
		const toastContent = edi.i18n.getMessage('ediweb.tariffs.check.buy.request.toast.content');
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.TARIFFS.CHECK.GET, {
				id: data.id
			}),
			'GET',
			null,
			function (resp) {
				if (resp && resp.data) {
					const documents = resp.data?.documents || [];
					const requests = resp.data?.requests || [];

					const isWaitInvoice = requests.find((item) => item?.tariff?.id === data?.id);

					if (!documents.length && !requests.length) {
						onAfterCheckSuccess(classification, data);
					} else if (requests.length) {
						if (isWaitInvoice) {
							showWarningToast(toastTitle, toastContent, {
								showCloseBtn: true,
								showButton: true,
								buttonText: edi.i18n.getMessage('button.go'),
								buttonHandler: function () {
									edi.core.openModule(
										'documents',
										{
											activeTab: 'incoming'
										},
										undefined,
										undefined,
										undefined,
										undefined,
										function () {
											edi.events.invitations.fireEvent('activatetab', 'incoming');
										}
									);
								}
							});
						} else {
							onAfterCheckSuccess(classification, data);
						}
					} else if (documents.length) {
						const isSendDsf = documents.find((item) => item.state === edi.constants.STATE.SENT);
						if (isSendDsf) {
							showWarningToast(toastTitle, toastContent, {
								showCloseBtn: true,
								showButton: true,
								buttonText: edi.i18n.getMessage('button.go'),
								buttonHandler: function () {
									edi.core.openModule(
										'documents',
										{
											activeTab: 'incoming'
										},
										undefined,
										undefined,
										undefined,
										undefined,
										function () {
											edi.events.invitations.fireEvent('activatetab', 'incoming');
										}
									);
								}
							});
						} else {
							onAfterCheckSuccess(classification, data);
						}
					}
				}
			},
			failure
		);
	};

	const buyTariff = function (classification, data) {
		let toastTitle = edi.i18n.getMessage('ediweb.tariffs.toast.title');
		let toastContent = edi.i18n.getMessage('ediweb.tariffs.toast.content');
		if (classification === 'POWER_OF_ATTORNEY') {
			toastTitle = edi.i18n.getMessage('ediweb.tariffs.toast.title');
			toastContent = edi.i18n.getMessage('ediweb.poa.tariffs.toast.content');
		}
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.TARIFFS.PUT, {
				id: data.id,
				orgId: edi.core.getUserData().org.id
			}),
			'PUT',
			Ext.encode({}),
			function () {
				confirmWithException?.close();
				buyPoaModal?.close();
				showInfoToast(toastTitle, toastContent, { showCloseBtn: true });
			},
			failure
		);
	};

	const showBuyConfirmWithException = function (classification, data) {
		confirmWithException = showConfirmWithException({
			exceptionType: 'warning',
			title: edi.i18n.getMessage('ediweb.tariffs.info.modal.title'),
			msgText: edi.i18n.getMessage('ediweb.tariffs.info.modal.content', [
				data.documentsNumber,
				data.tariffCost,
				data.documentCost
			]),
			exceptionText: edi.i18n.getMessage('ediweb.tariffs.info.modal.content.exceptionText'),
			buttons: [
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('ediweb.tariffs.info.modal.button'),
					handler() {
						buyTariff(classification, data);
					}
				}),
				createButton({
					text: edi.i18n.getMessage('ediweb.cancel.btn'),
					cls: BUTTON_CLS.secondary,
					handler: function () {
						confirmWithException?.close();
					}
				})
			]
		});
	};

	const buyPoaTariff = function (data) {
		buyPoaModal = createModalPanel({
			title: edi.i18n.getMessage('ediweb.tariffs.info.modal.title'),
			items: [
				createModalForm({
					items: [
						createLabel({
							typography: 'body-short_01',
							html: edi.i18n.getMessage('ediweb.poa.tariffs.info.modal.content', [
								data.tariffUiName,
								data.tariffCost
							])
						})
					]
				})
			],
			buttons: [
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('ediweb.tariffs.info.modal.button'),
					handler() {
						buyTariff('POWER_OF_ATTORNEY', data);
					}
				}),
				createButton({
					text: edi.i18n.getMessage('ediweb.cancel.btn'),
					cls: BUTTON_CLS.secondary,
					handler: function () {
						buyPoaModal?.close();
					}
				})
			]
		});
		buyPoaModal.show();
	};

	let createCarouselPanel = function (tariffs) {
		if (!!panel.isDestroyed) {
			return;
		}

		let cards = [];
		tariffs.forEach(function (data) {
			let card = createContainer({
				cls: 'edi-ediweb-tariffs-carousel-card-container',
				layout: {
					type: 'vbox'
				},
				margin: '0 8 10 8',
				width: 189,
				items: [
					createLabel({
						typography: 'heading_01',
						text: edi.i18n.getMessage('ediweb.tariffs.carousel.title', [data.documentsNumber])
					}),
					createLabel({
						typography: 'caption_01',
						color: '--color-grey-50',
						text: edi.i18n.getMessage('ediweb.tariffs.carousel.subtitle', [data.documentCost]),
						margin: '4 0 0 0'
					}),
					createLabel({
						typography: 'heading_03',
						text: edi.i18n.getMessage('ediweb.tariffs.carousel.content', [
							new String(data.tariffCost).replace(/\B(?=(?:\d{3})*$)/g, ' ')
						]),
						margin: '16 0'
					}),
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('ediweb.tariffs.carousel.button'),
						handler() {
							checkBeforeBuyTariff('DOCUMENT', data);
						}
					})
				]
			});

			cards.push(card);
		});

		let individualCard = createContainer({
			cls: 'edi-ediweb-tariffs-carousel-card-container',
			layout: {
				type: 'vbox'
			},
			width: 189,
			height: 174,
			margin: '0 8 10 8',
			items: [
				createLabel({
					typography: 'heading_01',
					cls: 'edi-ediweb-tariffs-carousel-card-container-title',
					padding: '25 0 32 0',
					html: edi.i18n.getMessage('ediweb.tariffs.individual.title')
				}),
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('ediweb.tariffs.individual.button'),
					handler() {
						createIndividualModal();
					}
				})
			]
		});

		cards.push(individualCard);

		tariffCarousel.cards = cards;
		tariffCarousel.createCards();
	};

	let createActualPoaTariffsPanel = function (actualTariffs) {
		if (!!panel.isDestroyed) {
			return;
		}
		actualPOATariffs = actualTariffs;

		let myCards = [];

		let userData = edi.core.getUserData();
		let withoutTariff = edi.utils.getAttributeByName(userData?.org?.attributes, 'WITHOUT_TARIFF') === 'true';
		if (withoutTariff) {
			myPoaTariffsCardsLabel.setVisible(false);
			myPoaTariffCarousel.setVisible(false);
			edi.constants.IS_CAN_CREATE_POA = true;
			edi.constants.IS_EXPIRED_POA_TARIFF = false;
			return;
		}

		myPoaTariffsCardsLabel.setVisible(actualTariffs.length > 0);
		myPoaTariffCarousel.setVisible(actualTariffs.length > 0);
		isLastUsedUpPoaTariff =
			actualTariffs.length === 1 && actualTariffs[0].remainDoc === 0 && !actualTariffs[0]?.tariff?.unlimited;
		needNewPoaTariffLabel.setVisible(isLastUsedUpPoaTariff);
		actualTariffs.forEach(function (tariffData, index) {
			if (tariffData.tariff) {
				let tariff = tariffData.tariff;
				let state = null;
				if (new Date(tariffData?.duration?.periodFrom) > new Date()) {
					state = 'FUTURE';
				} else if (index === 0 && !tariffData.wasted) {
					state = 'ACTIVE';
				}
				if (tariff.tariffCost === 0 && index > 0) {
					state = 'PROMO';
				}
				if ((tariffData.remainDoc === 0 && !tariff.unlimited) || tariffData.wasted) {
					state = 'SPEND';
				}
				if (tariff.expired) {
					state = 'EXPIRED';
				}
				let data = {
					remainDoc: tariffData.remainDoc,
					documentsNumber: tariff.documentsNumber,
					documentCost: tariff.documentCost,
					unlimited: tariff.unlimited,
					duration: tariffData.duration,
					wasted: tariffData.wasted,
					state: state
				};
				let cardTitle = tariff.tariffUiName;
				let myTariffCard = getClientTariffCard(cardTitle, data, isLastUsedUpPoaTariff, true);

				myCards.push(myTariffCard);
			}
		});

		myPoaTariffCarousel.cards = myCards;
		myPoaTariffCarousel.createCards();
	};

	let createPoaCarouselPanel = function (tariffs) {
		if (!!panel.isDestroyed) {
			return;
		}
		poaTariffCarouselTitle.setVisible(tariffs.length > 0);

		let cards = [];
		tariffs.forEach(function (data) {
			let card = createContainer({
				cls: 'edi-ediweb-tariffs-carousel-card-container',
				layout: {
					type: 'vbox'
				},
				margin: '0 8 10 8',
				width: 189,
				items: [
					createLabel({
						typography: 'heading_02',
						text: edi.i18n.getMessage('ediweb.poa.tariffs.carousel.title', [data.documentsNumber])
					}),
					createLabel({
						typography: 'caption_01',
						color: '--color-grey-50',
						text: edi.i18n.getMessage('ediweb.poa.tariffs.carousel.subtitle', [data.documentCost]),
						margin: '16 0 0 0'
					}),
					createLabel({
						typography: 'heading_03',
						text: edi.i18n.getMessage('ediweb.tariffs.carousel.content', [
							new String(data.tariffCost).replace(/\B(?=(?:\d{3})*$)/g, ' ')
						]),
						margin: '16 0'
					}),
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('ediweb.tariffs.carousel.button'),
						handler() {
							const isHasActualUseTariff =
								actualPOATariffs.length > 0 &&
								!isLastUsedUpPoaTariff &&
								!(
									(actualPOATariffs[0]?.remainDoc === 0 && !actualPOATariffs[0]?.tariff?.unlimited) ||
									actualPOATariffs[0]?.wasted
								) &&
								!actualPOATariffs[0]?.tariff?.expired;

							if (isHasActualUseTariff) {
								showConfirm({
									title: edi.i18n.getMessage('ediweb.tariff.poa.has.actual.tariff.title'),
									msgText: edi.i18n.getMessage('ediweb.tariff.poa.has.actual.tariff.msgText'),
									yesBtnConfig: {
										glyph: edi.constants.ICONS.DONE
									},
									success: function () {
										checkBeforeBuyTariff('POWER_OF_ATTORNEY', data);
									}
								});
							} else {
								checkBeforeBuyTariff('POWER_OF_ATTORNEY', data);
							}
						}
					})
				]
			});

			cards.push(card);
		});

		poaTariffCarousel.cards = cards;
		poaTariffCarousel.createCards();
	};

	const filterTariffsByClassificationAndCreateCarousel = function (allTariffs) {
		const edoTariffs = allTariffs.filter((item) => item.classification === 'DOCUMENT');
		const poaTariffs = allTariffs.filter((item) => item.classification === 'POWER_OF_ATTORNEY');

		createCarouselPanel(edoTariffs);
		createPoaCarouselPanel(poaTariffs);
	};
	let renderData = function (actualTariffs, allTariffs, poaActualTariffs) {
		createActualCarouselPanel(actualTariffs);
		createActualPoaTariffsPanel(poaActualTariffs);
		filterTariffsByClassificationAndCreateCarousel(allTariffs);

		if (!!poaTariffCarousel?.id) {
			const element = document.getElementById(poaTariffCarousel.id);
			element.scrollIntoView(false);
		}
	};

	edi.events.actual_poa_tariffs.on('change', createActualPoaTariffsPanel);
	edi.events.actual_tariffs.on('change', createActualCarouselPanel);
	edi.events.all_tariffs.on('change', filterTariffsByClassificationAndCreateCarousel);

	panel = createPanel({
		cls: 'module-panel tariff-module-panel',
		layout: {
			type: 'vbox',
			align: 'stretch'
		},
		autoScroll: true,
		tabTitle: 'ediweb.tariffs',
		bodyPadding: 24,
		items: [
			(myTariffsCardsLabel = createLabel({
				typography: 'heading_02',
				text: edi.i18n.getMessage('ediweb.tariffs.title.edo'),
				margin: '0 0 24 0'
			})),
			(needNewTariffLabel = createLabel({
				typography: 'body-short_01',
				text: edi.i18n.getMessage('tariff.need.new.tariff'),
				margin: '0 0 16 0'
			})),
			(actualTariffCarousel = createCarousel({
				cls: 'edi-ediweb-tariffs-carousel',
				hidden: true,
				cards: []
			})),
			(myPoaTariffsCardsLabel = createLabel({
				typography: 'heading_02',
				text: edi.i18n.getMessage('ediweb.tariffs.title.poa'),
				margin: '0 0 22 0'
			})),
			(needNewPoaTariffLabel = createLabel({
				typography: 'body-short_01',
				text: edi.i18n.getMessage('tariff.need.new.tariff'),
				margin: '0 0 16 0'
			})),
			(myPoaTariffCarousel = createCarousel({
				cls: 'edi-ediweb-tariffs-carousel',
				hidden: true,
				cards: []
			})),
			createLabel({
				typography: 'heading_02',
				text: edi.i18n.getMessage('ediweb.tariffs.buy.tariff'),
				margin: '8 0 16 0'
			}),
			createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('ediweb.tariffs.buy.tariff.subtitle'),
				margin: '8 0 16 0'
			}),
			createLabel({
				typography: 'body-short_01',
				text: edi.i18n.getMessage('ediweb.tariffs.buy.tariff.incoming.free'),
				margin: '0 0 16 0'
			}),
			createContainer({
				margin: '0 0 16 0',
				layout: {
					type: 'hbox',
					align: 'middle',
					pack: 'start'
				},
				items: [
					createExceptionBlock({
						exceptionType: 'warning',
						exceptionText: edi.i18n.getMessage('ediweb.tariffs.buy.tariff.info.link')
					})
				]
			}),
			(tariffCarousel = createCarousel({
				cls: 'edi-ediweb-tariffs-carousel',
				cards: []
			})),
			(poaTariffCarouselTitle = createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('ediweb.poa.tariffs.buy.tariff'),
				margin: '24 0 16 0'
			})),
			(poaTariffCarousel = createCarousel({
				cls: 'edi-ediweb-tariffs-carousel',
				cards: []
			}))
		],
		listeners: {
			destroy() {
				edi.events.actual_poa_tariffs.un('change', createActualCarouselPanel);
				edi.events.all_tariffs.un('change', filterTariffsByClassificationAndCreateCarousel);
			},
			boxready: function (comp) {
				actualTariffCarousel.setCardHeight('0.el.dom');
				myPoaTariffCarousel.setCardHeight('0.el.dom');
			}
		}
	});

	const getAllTariffs = function (actualTariffs, poaActualTariffs) {
		let callback = function (resp) {
			let allTariffs = edi.utils.getObjectProperty(resp, 'items');
			allTariffs = Array.isArray(allTariffs) ? allTariffs : [];

			if (!panel.isDestroyed) {
				renderData(actualTariffs, allTariffs, poaActualTariffs);
			}
		};
		edi.rest.sendRequest(edi.rest.services.TARIFFS.GET, 'GET', null, callback, callback);
	};

	edi.methods.tariffs
		.getActualTariffs()
		.then((actualResponseData) => {
			edi.methods.tariffs
				.getActualPoaTariffs()
				.then((poaActualResponseData) => {
					if (!panel.isDestroyed) {
						let actualTariffs = edi.utils.getObjectProperty(actualResponseData, 'items');
						actualTariffs = Array.isArray(actualTariffs) ? actualTariffs : [];
						let poaActualTariffs = edi.utils.getObjectProperty(poaActualResponseData, 'items');
						poaActualTariffs = Array.isArray(poaActualTariffs) ? poaActualTariffs : [];
						getAllTariffs(actualTariffs, poaActualTariffs);
					}
				})
				.catch((error) => {
					failure(error);
				});
		})
		.catch((error) => {
			failure(error);
		});

	return panel;
};

export { createTariffsPage };
