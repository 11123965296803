const actionRulesForLegacyDeljitModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		conditions: []
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {},
	REJECT: {},
	CLARIFY: {
		conditions: []
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		conditions: []
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			STATE: [edi.constants.STATE.READ]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		OUTGOING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	READ: {},
	READ_AND_COMPLETE: {
		INCOMING: {
			DOCUMENTS: edi.constants.LEGACY_DELJIT,
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: []
	},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		permissionChanging: [],
		conditions: []
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	},
	CONVERT_TO_DRAFT: {},
	COPY: {
		OUTGOING: {
			DOCUMENTS: []
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: []
		}
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT]: actionRulesForLegacyDeljitModule
});

export { actionRulesForLegacyDeljitModule };
