import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { convertDesadvProductsToDesadvCrossProducts, flattenLineValues } from './methods';
import { Packing, Product, Tree } from './definitions';
import { AMOUNT_FIELD_NAME, createProductsTree } from './createTree';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { PRODUCT_REFERENCE_COLUMNS_CONFIG_NAME, PRODUCT_REFERENCE_ELEMENT_COLUMNS_CONFIG_NAME } from './columns';
import { PRODUCT_REFERENCE_ELEMENT_MODEL, PRODUCT_REFERENCE_MODEL } from '@Edi/modules/documents/LEGACY_DES_ADV/models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createCombo, createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import {
	createContainer,
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createMaxWidthContainerForDetail
	// @ts-ignore
} from '@UIkit/components/panels';
import { desadvSelectors } from '@Edi/modules/documents/LEGACY_DES_ADV/selectors';
import { coreMethods } from '@Core/commons';

Ext.namespace('edi.modules');
edi.modules['document.details.desadv'] = function () {
	let moduleData: ModuleData<DocumentHeader>,
		docContent: AnyObject,
		children: AnyObject[],
		taxCalculationMethod: string,
		headerFields: AnyObject[],
		productFields: AnyObject[],
		productsTreeGrid: Tree,
		customFieldsContainer: ExtComponent,
		costDecimals: number;
	let unconvertedProducts: AnyObject[] = [];
	let customFieldsObj: AnyObject = {};
	let topPath = '//Document-DespatchAdvice/';

	/**
	 * Main module initialization method
	 */
	this.init = function (data: ModuleData<DocumentHeader>, initCallBack: ModuleInitCallback) {
		moduleData = data;
		costDecimals = coreMethods.getUserOrgCostDecimals();
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const createMainDataBlock = function (document: AnyObject) {
		const headerLabelText = [
			{
				title: edi.i18n.getMessage('document.des.adv.notification.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchAdviceNumber')
			},
			{
				title: edi.i18n.getMessage('document.des.adv.notification.date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchAdviceDate')
				)
			}
		];

		const headerData = createFieldBlockForDetails({
			items: [
				createLabelBlockForDetails({
					contents: headerLabelText
				})
			]
		});

		//to order
		const toOrderLabelText = [
			{
				title: edi.i18n.getMessage('buyer.order.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.BuyerOrderNumber')
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.BuyerOrderDate')
				)
			},
			{
				title: edi.i18n.getMessage('invoice.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.InvoiceNumber'),
				isNewLine: true
			},
			{
				title: edi.i18n.getMessage('document.select.contract'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.Reference.ContractNumber')
			},
			{
				title: edi.i18n.getMessage('functional.document.code'),
				text: edi.i18n.getMessage(
					'functional.document.code.' +
						edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DocumentFunctionCode')
				)
			}
		];

		const toOrderData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('to.order'),
			items: [
				createLabelBlockForDetails({
					contents: toOrderLabelText
				})
			]
		});

		return createMaxWidthContainerForDetail({
			layout: {
				type: 'grid'
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [headerData, toOrderData]
		});
	};

	const createPartiesBlock = function (parties: AnyObject) {
		const createOS = function (selectorConfig: AnyObject) {
			Object.assign(selectorConfig, {
				is_valid: true,
				readOnly: true,
				showDetailsButton: true,
				valuesByMap: true,
				fieldValues: parties
			});

			return createOrgSelector(selectorConfig);
		};

		let buyer = createOS({
			selectedOrg: edi.utils.getOrg({
				orgId: moduleData.initData.data.toOrg.id
			}),
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			fieldsMap: desadvSelectors.getBuyerFieldsMap(),
			modalConf: desadvSelectors.getBuyerModalConf()
		});

		let seller = createOS({
			selectedOrg: edi.utils.getOrg({
				orgId: moduleData.initData.data.fromOrg.id
			}),
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			fieldsMap: desadvSelectors.getSellerFieldsMap(),
			modalConf: desadvSelectors.getSellerModalConf()
		});

		let delivery = createOS({
			fieldsMap: desadvSelectors.getDeliveryFieldsMap(),
			modalConf: desadvSelectors.getDeliveryModalConf()
		});

		let shipFrom = createOS({
			fieldsMap: desadvSelectors.getShipFromFieldsMap(),
			modalConf: desadvSelectors.getShipFromModalConf()
		});

		let ultimateCustomer = createOS({
			fieldsMap: desadvSelectors.getUltimateCustomerFieldsMap(),
			modalConf: desadvSelectors.getUltimateCustomerModalConf()
		});

		let carrier = createOS({
			fieldsMap: desadvSelectors.getCarrierFieldsMap(),
			modalConf: desadvSelectors.getCarrierModalConf()
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('document.des.adv.section.parties'),
			collapsible: true,
			items: createMaxWidthContainerForDetail({
				layout: {
					type: 'grid',
					gap: 24,
					area: [
						[6, 6],
						[6, 6],
						[6, 6]
					]
				},
				items: [
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.seller'),
						items: [seller]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.buyer'),
						items: [buyer]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.ship.from'),
						items: [shipFrom]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.delivery'),
						items: [delivery]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('documents.ultimate.customer'),
						items: [ultimateCustomer]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.carrier'),
						items: [carrier]
					})
				]
			})
		});
	};

	const createTransportationInformationBlock = function (document: AnyObject) {
		//sending Cargo
		const sendingCargoLabelText = [
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchDate')
				)
			},
			{
				title: edi.i18n.getMessage('time'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchTime')
			}
		];

		const sendingCargoData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('sending.cargo'),
			items: [
				createLabelBlockForDetails({
					contents: sendingCargoLabelText
				})
			]
		});

		const expectedDeliveryData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.des.adv.expected.delivery'),
			items: [
				createLabelForDetails({
					title: edi.i18n.getMessage('date'),
					text: edi.renderers.fnsDateFromClient(
						edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.EstimatedDeliveryDate')
					)
				})
			]
		});

		const deliveryPeriod: ExtComponent = createFieldBlockForDetails({
			title: edi.i18n.getMessage('period.of.delivery'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.DeliveryPeriod')
				})
			]
		});

		//transport
		const capacityValue = edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.Capacity');
		const temperature = edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.Temperature', true).length
			? edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.Temperature')
			: 'NO';
		const transportStore = edi.stores.initModeOfTransportStore();
		const modeOfTransportValue = transportStore.findRecord(
			'id',
			edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.ModeOfTransport')
		);
		const transportLabelText = [
			{
				title: edi.i18n.getMessage('document.des.adv.TranspType'),
				text: modeOfTransportValue?.get('name')
			},
			{
				title: edi.i18n.getMessage('line.item.tab.package.VehicleHeight'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.VehicleHeight')
			},
			{
				title: edi.i18n.getMessage('vehicle.type'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.VehicleType'),
				isNewLine: true
			},
			{
				title: edi.i18n.getMessage('document.des.adv.transport.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.ConveyanceReferenceNumber')
			},
			{
				title: edi.i18n.getMessage('carrier.trailer.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.TrailerNumber')
			},
			{
				title: edi.i18n.getMessage('document.details.des.adv.carrying.capacity'),
				text: capacityValue
					? capacityValue + ' ' + edi.i18n.getMessage('common.pricat.unitOfMeasure.KGM')
					: undefined
			},
			{
				title: edi.i18n.getMessage('presence.dropside'),
				text: edi.renderers.existNo(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.Dropside') === 'YES'
				)
			},
			{
				title: edi.i18n.getMessage('document.des.adv.temperature.conditions'),
				text: edi.i18n.getMessage('temperature.conditions.code.' + temperature),
				isNewLine: true
			},
			{
				title: edi.i18n.getMessage('type.of.shipment'),
				text: (function () {
					let value = edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.PartialShipment');
					if ('' === value) {
						return edi.i18n.getMessage('form.combo.not.selected');
					}
					return edi.i18n.getMessage('partial.shipment.code.' + value);
				})()
			},
			{
				title: edi.i18n.getMessage('seal.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.SealNumber')
			}
		];

		const transportData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.des.adv.transport'),
			items: [
				createLabelBlockForDetails({
					contents: transportLabelText
				})
			]
		});

		//driver
		const driverLabelText = [
			{
				title: edi.i18n.getMessage('full.name.abbr'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.CarrierName')
			},
			{
				title: edi.i18n.getMessage('general.phone'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.Phone')
			}
		];

		const driverData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.des.adv.driver'),
			items: [
				createLabelBlockForDetails({
					contents: driverLabelText
				})
			]
		});

		//termsOfDelivery
		const termsOfDeliveryRawValue = edi.utils.getObjectProperty(
			document,
			'DespatchAdvice-Transport.TermsOfDelivery'
		);
		const termsOfDeliveryData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('terms.of.delivery'),
			items: [
				createLabelForDetails({
					text: termsOfDeliveryRawValue && edi.i18n.getMessage('terms.of.delivery.' + termsOfDeliveryRawValue)
				})
			]
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('transportation.information'),
			collapsible: true,
			items: createMaxWidthContainerForDetail({
				layout: {
					type: 'grid'
				},
				items: [
					sendingCargoData,
					expectedDeliveryData,
					deliveryPeriod,
					transportData,
					driverData,
					termsOfDeliveryData
				]
			})
		});
	};

	const createSectionAdditionalBlock = function (document: AnyObject, docHeaderFields: AnyObject[]) {
		//invoice
		const invoiceLabelText = [
			{
				title: edi.i18n.getMessage('field.name.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.WaybillNumber')
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.WaybillDate')
				)
			}
		];

		const invoiceData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.des.adv.invoice'),
			items: [
				createLabelBlockForDetails({
					contents: invoiceLabelText
				})
			]
		});

		//transport invoice
		const transportInvoiceLabelText = [
			{
				title: edi.i18n.getMessage('field.name.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.BillOfLadingNumber')
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.BillOfLadingDate')
				)
			}
		];

		const transportInvoiceData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.des.adv.transport.invoice'),
			items: [
				createLabelBlockForDetails({
					contents: transportInvoiceLabelText
				})
			]
		});

		//upd
		const updLabelText = [
			{
				title: edi.i18n.getMessage('field.name.number'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.UTDnumber')
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.UTDDate')
				)
			}
		];

		const updData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('action.upd'),
			items: [
				createLabelBlockForDetails({
					contents: updLabelText
				})
			]
		});

		//egais
		const egaisLabelText = [
			{
				title: edi.i18n.getMessage('document.des.adv.fix.egais.code'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.CodeFixEGAIS')
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DateFixEGAIS')
				)
			},
			{
				title: edi.i18n.getMessage('document.des.adv.ttn.reg.id'),
				text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.TTNRegId')
			}
		];

		const egaisData = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.des.adv.egais'),
			items: [
				createLabelBlockForDetails({
					contents: egaisLabelText
				})
			]
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('document.des.adv.section.additional'),
			collapsible: true,
			layout: {
				type: 'grid'
			},
			items: [invoiceData, transportInvoiceData, updData, egaisData].concat(docHeaderFields)
		});
	};

	/**
	 * Дополнительные поля
	 */
	const createCustomFieldsContainer = function () {
		return (customFieldsContainer = createContainer({}) as ExtComponent);
	};

	const createRemarkBlock = function (document: AnyObject) {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[8]]
			},
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.Remarks')
				})
			]
		});
	};

	const createTreeGrid = function (document: AnyObject, parties: AnyObject) {
		let isUltimateCustomerDefined = !!edi.utils.getObjectProperty(parties, 'UltimateCustomer');
		let lines: AnyObject[] = edi.utils.getObjectProperty(
			document,
			'DespatchAdvice-Consignment.Packing-Sequence.Line',
			true
		);

		unconvertedProducts = [];
		if (Array.isArray(lines)) {
			lines.forEach((line) => {
				let lineValues = flattenLineValues(line);
				lineValues['TaxRate'] = edi.renderers.taxRateString(lineValues['TaxRate']);

				if (Array.isArray(productFields) && productFields.length) {
					productFields.forEach((productField) => {
						let fieldName = productField.field;
						if (lineValues[fieldName]) {
							let productFieldValues = productField.values;
							if (Array.isArray(productFieldValues) && productFieldValues.length) {
								let productFieldValue = productFieldValues.find(
									(v) => v.code === lineValues[fieldName]
								);
								if (productFieldValue) {
									lineValues[fieldName] = productFieldValue.value;
								}
							}
						}
					});
				}
				unconvertedProducts.push(lineValues);
			});
		}

		return createProductsTree({
			allowExportPackings: isUltimateCustomerDefined,
			readOnly: true,
			productValues: [],
			packingList: children,
			modalFormConfig: {
				title: 'document.desadv.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'PRODUCT',
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.orders'),
								name: 'OrderedQuantity'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.despatched'),
								allowDecimals: true,
								name: AMOUNT_FIELD_NAME,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.all.despatched'),
								name: 'ProductQuantityDespatched',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								name: 'UnitOfMeasure',
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.product.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('org.document.LEGACY_DES_ADV.part.docLines.ReasonCode'),
								isHidden: function () {
									let showProductField = (productFields || []).some((f) => f.field === 'ReasonCode');
									return !showProductField;
								},
								name: 'ReasonCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price.desadv'),
						customFieldTab: 'TAX_AND_PRICE_DESADV',
						items: [
							{
								title: edi.i18n.getMessage('line.item.unit.net.price'),
								allowDecimals: true,
								name: 'UnitNetPrice',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								allowDecimals: true,
								name: 'UnitGrossPrice',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.rate'),
								name: 'TaxRate'
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								allowDecimals: true,
								name: 'ProductNetAmount',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								allowDecimals: true,
								name: 'ProductTaxAmount',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								allowDecimals: true,
								name: 'ProductGrossAmount',
								type: 'number'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery.desadv'),
						customFieldTab: 'PACKAGE_AND_DELIVERY_DESADV',
						items: [
							{
								title: edi.i18n.getMessage('desadv.line.item.serial.number'),
								name: 'SerialNumber'
							},
							{
								title: edi.i18n.getMessage('desadv.line.item.batch.number'),
								name: 'BatchNumber',
								maxLength: 175
							},
							{
								title: edi.i18n.getMessage('line.item.production.date'),
								name: 'ProductionDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.certificate.number'),
								name: 'CertificateNumber',
								isHidden: function (field: ExtComponent, record: ExtRecord<AnyObject>, value: string) {
									return !value;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.boxes.quantity'),
								name: 'BoxesQuantity'
							},
							{
								title: edi.i18n.getMessage('desadv.line.item.vsd.number'),
								name: 'VSDNumber'
							},
							{
								title: edi.i18n.getMessage('line.item.line.party.name'),
								name: 'LinePartyName'
							},
							{
								title: edi.i18n.getMessage('line.item.expiration.date'),
								name: 'ExpirationDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.certificate.date'),
								name: 'CertificateDateOfIssue',
								isHidden: function (field: ExtComponent, record: ExtRecord<AnyObject>, value: string) {
									return !value;
								}
							},
							{
								type: 'editableGrid',
								name: 'Line-Reference.Reference-Elements.Reference-Element',
								config: {
									columnsConfig: PRODUCT_REFERENCE_ELEMENT_COLUMNS_CONFIG_NAME,
									fields: [
										{
											title: 'desadv.reference.column.type',
											name: 'Reference-Type'
										},
										{
											title: 'desadv.reference.column.id',
											name: 'Reference-Id'
										}
									],
									limit: undefined,
									model: PRODUCT_REFERENCE_ELEMENT_MODEL,
									modalWidth: MODAL_SIZE.widthLarge,
									gridConfig: {
										cls: 'modal-grid modal-grid-with-header',
										minHeight: 200,
										title: edi.i18n.getMessage('desadv.reference.elements.grid.title')
									},
									storeConfig: {
										remoteSort: false
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.sv.td'),
						customFieldTab: 'SV_TD',
						items: [
							{
								title: edi.i18n.getMessage('desadv.line.item.invoice.number'),
								name: 'Line-AdditionalInformation.InvoiceNumber',
								type: 'label'
							},
							{
								title: edi.i18n.getMessage('line.item.country.of.origin'),
								type: function (cfg: AnyObject, values: AnyObject) {
									return createCombo({
										name: 'Line-AdditionalInformation.CountryOfOrigin',
										valueSrc: values,
										readOnly: true,
										store: edi.stores.initValidCountryFullStore(),
										valueField: 'iso_2',
										displayField: 'display_name'
									});
								}
							},
							{
								type: 'editableGrid',
								name: 'Line-Reference.Reference-Elements.Customs-Declarations',
								config: {
									columnsConfig: PRODUCT_REFERENCE_COLUMNS_CONFIG_NAME,
									fields: [
										{
											title: 'column.kod.proiskh',
											name: 'countryOrigin',
											type: 'combo',
											store: edi.stores.initValidCountryFullStore(),
											valueField: 'iso_number_3',
											displayField: 'display_name'
										},
										{
											title: 'column.nomer.td',
											name: 'Reference-Id',
											type: 'text'
										}
									],
									limit: undefined,
									model: PRODUCT_REFERENCE_MODEL,
									modalWidth: MODAL_SIZE.widthLarge,
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200,
										title: edi.i18n.getMessage('line.item.tab.sv.td')
									},
									storeConfig: {
										remoteSort: false
									}
								}
							}
						]
					}
				]
			},
			pagingBarConfig: {
				items: [
					createButton({
						text: '',
						cls: [BUTTON_CLS.light, 'edi-grid-paginator-download-button'],
						tooltip: edi.i18n.getMessage('action.export'),
						glyph: edi.constants.ICONS.FILE_DOWNLOAD,
						handler: function () {
							const data = {
								headers: [] as string[],
								data: [] as (string | string[])[]
							};
							const grid = productsTreeGrid;
							const table = grid.getView();
							const allNodes = table.getNodes();

							if (grid.columnManager.headerCt && grid.columnManager.headerCt.items.items) {
								const items = grid.columnManager.headerCt.items.items;
								for (let i = 0; i < items.length; i++) {
									const aColumn = items[i];
									if (!aColumn.hidden && aColumn.cName) {
										const text = aColumn.text;
										if (aColumn.items.items.length) {
											for (let j = 0; j < aColumn.items.items.length; j++) {
												const aCol = aColumn.items.items[j];
												data.headers.push(text + ' ' + String(aCol.text).toLowerCase());
											}
										} else {
											data.headers.push(text);
										}
									}
								}
							}

							const continueExport = function () {
								data.data.push(['BORDER']);
								const selection = allNodes;
								for (let i = 0; i < selection.length; i++) {
									const row = selection[i];
									const rec = table.getRecord(row);
									if (rec?.data?.depth === 1) {
										data.data.push(['BORDER']);
									}
									const rowData = [];
									let tdCells = row.querySelectorAll('td') || [];
									for (let j = 0; j < tdCells.length; j++) {
										const cell = tdCells[j];
										if (
											!cell.hidden &&
											cell.localName === 'td' &&
											-1 === cell.className.indexOf('x-grid-cell-special') &&
											-1 === cell.className.indexOf('x-action-col-cell')
										) {
											let value = (cell.innerText || cell.textContent || '').split('\n');
											value[value.length - 1] === '' ? value.splice(value.length - 1, 1) : null;
											rowData.push(value.join('\n'));
										}
									}
									data.data.push(rowData);
								}

								data.data.push(['BORDER']);
								//edi.debug.debugTableData(data);
								const fileId = String(Ext.id()).replace('ext-gen', '').replace('ext-', '');

								const exportUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS_EXPORT.LIST.POST, {
									id: fileId
								});
								const exportUrlWithBorders = edi.utils.compileURL(exportUrl, {
									withBorders: true
								});

								edi.rest.downloadFile(exportUrlWithBorders, fileId, data);
							};

							if (allNodes.length) {
								continueExport();
							} else {
								edi.core.showInfo('nothing.to.export');
							}
						}
					})
				]
			}
		});
	};

	/**
	 * Creates despatch advice form
	 * @returns {Object}
	 */
	const createDetailsPanel = function (document: AnyObject) {
		let headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing)
		});
		taxCalculationMethod = edi.utils.getTaxCalculationMethod(moduleData.initData.data.toOrg.attributes);
		let parties = edi.utils.getObjectProperty(document, 'DespatchAdvice-Parties');

		let docHeaderFields = [],
			val,
			i,
			j;
		if (headerFields) {
			for (i = 0; i < headerFields.length; i++) {
				val = edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.' + headerFields[i].field);
				if (headerFields[i].values && headerFields[i].values.length) {
					for (j = 0; j < headerFields[i].values.length; j++) {
						if (headerFields[i].values[j].code == val) {
							val = headerFields[i].values[j].value;
							break;
						}
					}
				}
				docHeaderFields.push(
					createFieldBlockForDetails({
						title: edi.i18n.getMessage(
							'org.document.LEGACY_DES_ADV.part.docHeader.' + headerFields[i].field
						),
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						items: [
							createLabelForDetails({
								text: val
							})
						]
					})
				);
			}
		}

		productsTreeGrid = createTreeGrid(document, parties);

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				headPanel,
				createMainDataBlock(document),
				createPartiesBlock(parties),
				createTransportationInformationBlock(document),
				createSectionAdditionalBlock(document, docHeaderFields),
				createRemarkBlock(document),
				createCustomFieldsContainer(),
				productsTreeGrid.wrapper
			]
		});
	};

	/**
	 * Creates action pane
	 */
	let createModuleActionsPanel = function (docContent: AnyObject) {
		let data: DocumentHeader = moduleData.initData.data;
		docContent.id = data.id;
		let actionsPanel = createActionsPanel() as ExtComponent;
		let direction: string = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		let needSignatures: number = edi.document.actions.getSignCount(data);
		let needSignatures2: number =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.SUPPLIER);
		let signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero

		const customButtons = [];
		let createDocConfig = [
			{
				permission: 'CREATE_LEGACY_REC_ADV',
				title: 'action.recadv',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
				config: {
					isFromTransformation: true,
					transformUri: edi.rest.services.DOCUMENTS.TRANSFORMATION_WITH_CUSTOM_FIELDS.POST,
					buyerOrg: data.toOrg
				}
			},
			{
				permission: 'CREATE_LEGACY_ALCDES',
				title: 'action.alcdes',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
				config: {
					isFromTransformation: true,
					buyerOrg: data.toOrg
				}
			},
			{
				permission: 'CREATE_LEGACY_INVOICE',
				title: 'action.invoice',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
				config: {
					isFromTransformation: true,
					buyerOrg: data.toOrg
				}
			},
			{
				permission: 'CREATE_EDI_FNS_UPD',
				title: 'action.upd_5_01n',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				createCustomMethod: edi.document.actions.methodCreateUPDfromDocuments(
					data.id,
					direction,
					unconvertedProducts,
					'UnitGrossPrice',
					moduleData
				),
				config: {
					isFromTransformation: false,
					buyerOrg: data.toOrg
				}
			},
			{
				permission: 'CREATE_EDI_FNS_UPD',
				title: 'action.upd_5_02n',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				createCustomMethod: edi.document.actions.methodCreateUPDfromDocuments(
					data.id,
					direction,
					unconvertedProducts,
					'UnitGrossPrice',
					moduleData,
					{
						version: '5.02-N',
						getFunction: (documentContent: AnyObject) => documentContent?.dokument?.funktsiya
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: data.toOrg
				}
			}
		];

		let basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_FROM',
			data,
			moduleData,
			docContent,
			'desadv',
			createDocConfig
		);

		if (Array.isArray(basedDocuments) && basedDocuments.length > 0) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.desadv'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					hideDefaultExport: true,
					addOriginalExport: true,
					addTransitExport: true
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	let changeHandler = function (data?: AnyObject) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData: { data: DocumentHeader }) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	/**
	 * Prepare and load products values in tree
	 */
	let loadProductsIntoTree = function () {
		let products: (Product | Packing)[] = [];
		if (unconvertedProducts.length) {
			unconvertedProducts.forEach((line, index) => {
				let packs: AnyObject[] =
					line && line['Package-Identification'] && line['Package-Identification']['Goods-Identity']
						? line['Package-Identification']['Goods-Identity']
						: [];
				if (customFieldsObj.customPackingFields && Object.keys(customFieldsObj.customPackingFields).length) {
					if (customFieldsObj.customFieldsData) {
						Object.entries(customFieldsObj.customFieldsData).forEach(([key, val]) => {
							let regexp = new RegExp(`\\[${index + 1}\\]`);
							if (key.match(regexp)) {
								key = key.replace(regexp, '[@]');
								let lineKey = customFieldsObj.xPathMap[key];
								packs.forEach(function (pack) {
									lineKey ? (pack[lineKey] = val) : null;
								});
								lineKey ? (line[lineKey] = val) : null;
							}
						});
					}
				}
			});
			let convertedProducts = convertDesadvProductsToDesadvCrossProducts(
				unconvertedProducts,
				taxCalculationMethod,
				costDecimals,
				productsTreeGrid
			);
			products = convertedProducts.products;
		}

		productsTreeGrid.loadProducts(products);
		if (docContent && docContent['DespatchAdvice-Summary']) {
			productsTreeGrid.loadTotalValues([docContent['DespatchAdvice-Summary']]);
		}
	};

	/**
	 * Renders module layout
	 */
	let renderData = function (initCallBack?: ModuleInitCallback) {
		moduleData.tab.setLoading();
		let data = moduleData.initData.data;

		let completeRender = function () {
			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
			loadProductsIntoTree();
		};

		let loadCustomFields = function () {
			let modulePanel = createDetailsModulePanel() as ExtComponent;
			modulePanel.add(createDetailsPanel(docContent));
			moduleData.tab.add(createModuleActionsPanel(docContent));
			moduleData.tab.add(modulePanel);

			if (data?.toOrg?.id && data?.fromOrg?.id) {
				edi.methods.custom_fields.initCustomFields({
					docType: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
					toOrgId: data.toOrg.id,
					fromOrgId: data.fromOrg.id,
					docId: docContent.id,
					container: customFieldsContainer,
					grid: productsTreeGrid,
					isTree: true,
					readOnly: true,
					topPath: topPath,
					insertionCallback(obj: AnyObject) {
						customFieldsObj = obj;
						completeRender();
					},
					failCallback: completeRender
				});
			} else {
				completeRender();
			}
		};

		let loadDocumentPartFields = function () {
			edi.document.actions.getOrgDocumentPartFields(
				data.toOrg.id,
				edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
				function (organizationDocPartFields: AnyObject[]) {
					let docHeader =
						organizationDocPartFields.find(function (item) {
							return item.docPart === 'docHeader';
						}) || {};
					let docLines =
						organizationDocPartFields.find(function (item) {
							return item.docPart === 'docLines';
						}) || {};
					headerFields = edi.utils.getObjectProperty(docHeader, 'fields', true);
					productFields = edi.utils.getObjectProperty(docLines, 'fields', true);
					loadCustomFields();
				}
			);
		};

		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', () =>
			edi.modulesHandler.removeModule(moduleData)
		);

		let loadChildren = function () {
			edi.rest.sendRequest(
				edi.document.actions.formatLinkedChildrenUri(moduleData.initData),
				'GET',
				undefined,
				function (responseData: AnyObject) {
					if (responseData?.items?.length) {
						children = [];
						for (let i = 0; i < responseData.items.length; i++) {
							if (responseData.items[i].type !== edi.constants.DOCUMENT_TYPES.LEGACY_APERAK) {
								children.push(responseData.items[i]);
							}
						}
					}
					loadDocumentPartFields();
				},
				failure
			);
		};

		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data: AnyObject) {
				if (data && data.data) {
					docContent = data.data;
					loadChildren();
				} else {
					failure(data);
				}
			},
			failure
		);
	};

	/**
	 * Routine that must be done before module destroy
	 * @returns    {Boolean}    false to stop module destroy
	 */
	let onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name, 'debug');
		return true;
	};
};
