import { createGrid } from '@UIkit/components/grid';
import { createProxyConfig } from '@Components/storeComponents';

const createTariffGrid = function (data) {
	const model = edi.models.getModel('TARIFF_EDI_GRID');
	const columns = edi.columns.get('tariff_edi_grid');

	const tariffsData = data?.details || [];

	return createGrid({
		storeConfig: {
			pageSize: edi.constants.MAX_PAGE_SIZE,
			sorters: {
				property: 'documentsCost',
				direction: 'ASC'
			},
			remoteSort: true,
			sortOnLoad: true,
			model: model,
			proxy: createProxyConfig({
				type: 'memory',
				data: {
					items: tariffsData
				}
			})
		},
		viewConfig: {
			cls: 'edi-grid-mark-empty-red',
			emptyText: edi.i18n.getMessage('grid.empty.mandatory')
		},
		gridConfig: {
			columns: columns,
			disablePaging: true,
			hideSettingsButton: true
		}
	});
};

export { createTariffGrid };
