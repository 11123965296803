// @ts-ignore
import { createNamedDocumentBlock, DocumentBlock } from './createDocumentBlock';
export interface DocumentBlockProps {
	data: AnyObject[];
	title: string;
	[key: string]: any;
}

interface NamedDocumentBlockProps extends DocumentBlockProps {
	name: string;
}
export const createNamedDocumentBlockForDetail = function (
	props: NamedDocumentBlockProps,
	overrides?: Partial<DocumentBlock>,
	blockHolderCfg?: AnyObject
): ExtComponent | null {
	if (blockHolderCfg?.readOnly && (!props?.data || props.data.length == 0)) {
		return null;
	}
	return createNamedDocumentBlock(
		props,
		{
			isReadOnly: true,
			...overrides
		},
		{
			readOnly: true,
			allowBlank: true,
			...blockHolderCfg
		}
	);
};
