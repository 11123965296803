import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createEditableProductsTreeGrid } from '@Components/product.tree.grid.editable';
import {
	createPanel,
	createFieldSetForDetails,
	createFieldBlockForDetails,
	createContainer,
	FIELD_BLOCK_CLS,
	createFormContainer,
	createFieldBlock,
	createMaxWidthContainerForDetail
} from '@UIkit/components/panels';
import { createTab, createTabPanel } from '@UIkit/components/tab';
import { createTextField, createLabelForDetails, createLabelBlockForDetails } from '@UIkit/components/fields';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createActionsButton } from '@Components/buttons';
import { createEditableGrid } from '@Components/editableGrid/EditableGrid';
import { createUKDLineModalForm, isKSCHF_BussinessProcess } from './methods';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { MODAL_SIZE } from '@UIkit/components/modal';

export class DocumentDetailsFns_ukd_5_01nModule {
	moduleData;
	part1;
	part2;
	part1Head;
	part2Head;
	bpName;
	utochReasonText = null;
	annulDoc;
	ukdRoamingStatus6;
	isVersion2;
	infoColumnsConfig;
	rejectReasonText;
	textNoticeUtoch;
	rejectReasonTextValue;

	constructor() {
		const me = this;

		me.positionsStore = edi.stores.initPositionsStore();
		me.areaStore = edi.stores.initAuthorityAreasStore();
		me.signerStatusesStore = edi.stores.initSignerStatusesStore();
		me.signerBuyerStatusesStore = edi.stores.initSignerBuyerStatusesStore();
		me.baseOfCorrectionsContsName = 'Без документа-основания';
		me.isKSCHF = isKSCHF_BussinessProcess;

		//Биндим методы, т.к. они будут передаваться как колбэки и вызываться опосредовано без контекста
		me._changeHandler = me.changeHandler.bind(this);
		me._signHandler = me.signHandler.bind(this);

		me._detailsPanel = me.createDetailsPanel.bind(this);
	}

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data, initCallBack) {
		const me = this;
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.documents.on('change', me._changeHandler);
		edi.events.documents.on('sign', me._signHandler);
	}

	getPositionShortName(value) {
		const me = this;
		let target = me.positionsStore.findRecord('id', value, 0, false, false, true);
		return target ? target.get('shortName') : value;
	}

	createProductsGrid() {
		const me = this;

		const lines = edi.utils.getObjectProperty(me.part1, 'document.cinvoiceTable'),
			productValues = edi.converters.convertUKDProductToUKDGridValues(lines, undefined, '5.01-N');

		const gridTotalData = {
			Increase: productValues.totalIncrease,
			Decrease: productValues.totalDecrease
		};

		let columnsRegen;
		if (me.infoColumnsConfig) {
			const renderer = function (value, metaData, record) {
				const arrInf = record.get('InfPolStr');
				if (Array.isArray(arrInf) && arrInf.length) {
					let i, elm;
					for (i = 0; i < arrInf.length; i++) {
						elm = arrInf[i];
						if (elm.Identif && elm.Identif === metaData.column.infoId) {
							return elm.Znachen;
						}
					}
				}
				return '';
			};
			const newConfig = edi.methods.createInfoColumns(me.infoColumnsConfig, renderer);

			const valuesColumnConfigurator = function (config) {
				Ext.merge(config, newConfig);
				return config;
			};
			columnsRegen = edi.columns.get('fns_ukd_lines_5_01n' + (me.isVersion2 ? '_02' : ''), function (config) {
				return valuesColumnConfigurator(config);
			});
		}

		let grid = createEditableProductsTreeGrid({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			totalsGridHeaderLabel: 'grid.title.total',
			gridModel: 'FNS_UKD_PRODUCT',
			lineModel: 'FNS_UKD_PRODUCT',
			gridColumnConfig: me.infoColumnsConfig
				? columnsRegen
				: 'fns_ukd_lines_5_01n' + (me.isVersion2 ? '_02' : ''),
			totalModel: 'FNS_UKD_SUMMARY',
			margin: '20 0 20 0',
			readOnly: true,
			totalColumnConfig: 'corrected_fns_invoice_summary',
			totalData: gridTotalData,
			lineNumberFieldName: 'lineNum',
			viewConfig: {
				layout: 'fit',
				maxHeight: edi.constants.DEFAULT.FORM_GRID.SCROLLABLE_DESADV,
				scrollable: {
					x: 'auto',
					y: 'auto'
				}
			},
			isValidRecord: function (record) {
				const state = record.get('State');
				return state === 'Increase' || state === 'Decrease' || record.get('Count') >= 0;
			},
			productDetailsHandler: function (record) {
				let infoGrid,
					infoTrackingGrid,
					identifyProductToolNumbersBeforeGrid,
					identifyProductToolNumbersAfterGrid,
					lineModalFormConfig = [
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productDesc'),
							minLength: 1,
							maxLength: 1000,
							readOnly: true,
							name: 'productDesc',
							value: record.get('productDesc')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productSort'),
							minLength: 1,
							maxLength: 10,
							readOnly: true,
							name: 'productSort',
							value: record.get('productSort')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productReference'),
							minLength: 1,
							maxLength: 50,
							readOnly: true,
							name: 'productReference',
							value: record.get('productReference')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productCode'),
							minLength: 1,
							maxLength: 100,
							readOnly: true,
							name: 'productCode',
							value: record.get('productCode')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productTypeCode'),
							minLength: 10,
							maxLength: 10,
							readOnly: true,
							name: 'productTypeCode',
							value: record.get('productTypeCode')
						})
					];
				infoGrid = createEditableGrid(
					{
						fields: [
							{
								name: 'Identif',
								type: 'text'
							},
							{
								name: 'Znachen',
								type: 'text'
							}
						],
						limit: 20,
						readOnly: true,
						model: 'FIELD_LINE_INFORMATION',
						gridConfig: {
							autoScroll: true,
							maxHeight: 234
						}
					},
					record.get('InfPolStr')
				);

				const tabPanel = createTabPanel({
					items: [
						createTab({
							title: edi.i18n.getMessage('line.item.tab.additional.data'),
							closable: false,
							autoScroll: true,
							items: createFormContainer({
								padding: 24,
								gap: [32, 16],
								area: [8, 4, 4, [4, 4]],
								items: lineModalFormConfig
							})
						}),
						createTab({
							title: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
							padding: 24,
							closable: false,
							items: [infoGrid]
						}),
						createTab({
							title: edi.i18n.getMessage('line.item.infoTracking'),
							padding: 24,
							closable: false,
							items: [
								(infoTrackingGrid = createEditableGrid(
									{
										fields: [
											{
												title: edi.i18n.getMessage('line.item.infoTracking.trackingProdNum'),
												name: 'trackingProdNum',
												type: 'text'
											},
											{
												title: edi.i18n.getMessage('line.item.infoTracking.trackingUnitMes'),
												name: 'trackingUnitMes',
												type: 'text'
											},
											{
												title: edi.i18n.getMessage(
													'line.item.infoTracking.trackingProdQuanBefore'
												),
												name: 'trackingProdQuanDiff.trackingProdQuanBefore',
												type: 'text',
												renderer: function (value, meta, record) {
													return edi.utils.getObjectProperty(
														record.get('trackingProdQuanDiff'),
														'trackingProdQuanBefore'
													);
												}
											},
											{
												title: edi.i18n.getMessage(
													'line.item.infoTracking.trackingProdQuanAfter'
												),
												name: 'trackingProdQuanDiff.trackingProdQuanAfter',
												type: 'text',
												renderer: function (value, meta, record) {
													return edi.utils.getObjectProperty(
														record.get('trackingProdQuanDiff'),
														'trackingProdQuanAfter'
													);
												}
											},
											{
												title: edi.i18n.getMessage('line.item.infoTracking.trackingAdd'),
												name: 'trackingAdd',
												type: 'text'
											}
										],
										limit: 0,
										isHideAddBtn: true,
										readOnly: true,
										model: 'UPD_PRODUCT_INFO_TRACKING',
										modalWidth: MODAL_SIZE.widthLarge,
										gridConfig: {
											autoScroll: true,
											maxHeight: 234
										}
									},
									record.get('infoTracking')
								))
							]
						}),
						createTab({
							title: edi.i18n.getMessage('line.item.identifyProductToolNumbers'),
							padding: 24,
							closable: false,
							autoScroll: true,
							layout: {
								type: 'vbox',
								align: 'stretch'
							},
							items: [
								createContainer({
									items: [
										(identifyProductToolNumbersBeforeGrid = createEditableGrid(
											{
												fields: [
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.packId'
														),
														name: 'packId',
														type: 'text'
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.type'
														),
														name: 'type',
														type: 'text'
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.numbers'
														),
														name: 'numbers',
														type: 'text'
													}
												],
												columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
												limit: 0,
												isHideAddBtn: true,
												readOnly: true,
												autoScroll: true,
												model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
												gridConfig: {
													autoScroll: true,
													maxHeight: 234,
													title: edi.i18n.getMessage(
														'line.item.identifyProductToolNumbersBefore'
													)
												}
											},
											record.get('identifyProductToolNumbersBefore')
										)),
										(identifyProductToolNumbersAfterGrid = createEditableGrid(
											{
												columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
												fields: [
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.packId'
														),
														name: 'packId',
														type: 'text'
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.type'
														),
														name: 'type',
														type: 'text'
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.numbers'
														),
														name: 'numbers',
														type: 'text'
													}
												],
												limit: 20,
												readOnly: true,
												autoScroll: true,
												model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
												recordsUniqueByField: 'packId',
												duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
												gridConfig: {
													autoScroll: true,
													maxHeight: 234,
													margin: '24 0 0 0',
													title: edi.i18n.getMessage(
														'line.item.identifyProductToolNumbersAfter'
													)
												}
											},
											record.get('identifyProductToolNumbersAfter')
										))
									]
								})
							]
						})
					]
				});

				const dataFormGridConfig = {
					InfPolStr: infoGrid,
					infoTracking: infoTrackingGrid,
					identifyProductToolNumbersBefore: identifyProductToolNumbersBeforeGrid,
					identifyProductToolNumbersAfter: identifyProductToolNumbersAfterGrid
				};

				createUKDLineModalForm(tabPanel, record, dataFormGridConfig, false);
			},
			pagingBarConfig: {
				border: 0,
				items: [
					createButton({
						text: '',
						cls: [
							BUTTON_CLS.light,
							'edi-label-button no-text-decoration edi-grid-paginator-download-button'
						],
						margin: '0 30 0 5',
						tooltip: edi.i18n.getMessage('action.export'),
						glyph: edi.constants.ICONS.FILE_DOWNLOAD,
						handler: function () {
							let exportPack = {
								headers: [],
								data: []
							};
							let headerIndices = [];
							const borderLine = ['BORDER'];

							if (grid.columnManager.headerCt && grid.columnManager.headerCt.items.items) {
								let items = grid.columnManager.headerCt.items.items;
								for (let i = 0; i < items.length; ++i) {
									let aColumn = items[i];
									if (!aColumn.hidden && aColumn.cName) {
										let text = aColumn.text;
										if (aColumn.items.items.length) {
											for (let j = 0; j < aColumn.items.items.length; ++j) {
												let aCol = aColumn.items.items[j];
												exportPack.headers.push(text + ' ' + String(aCol.text).toLowerCase());
												headerIndices.push(aCol.cName);
											}
										} else {
											exportPack.headers.push(text);
											headerIndices.push(aColumn.cName);
										}
									}
								}
							}

							let sublines = ['OriginalData', 'NewData', 'Increase', 'Decrease'];
							let subtitles = [
								edi.i18n.getMessage('corrected.product.state.OriginalData'),
								edi.i18n.getMessage('corrected.product.state.NewData'),
								edi.i18n.getMessage('corrected.product.state.Increase'),
								edi.i18n.getMessage('corrected.product.state.Decrease')
							];
							let headerStateIndex = headerIndices.indexOf('State');

							const extractLineData = function (dataPack) {
								let renderers = {
									ProductOkei: function (value) {
										let rec = edi.stores.initOkeiStore().findRecord('id', value);
										return rec ? rec.get('name') : '';
									}
								};
								let pack = [];

								for (let i = 0; i < headerIndices.length; ++i) {
									if (dataPack.hasOwnProperty(headerIndices[i])) {
										if (renderers.hasOwnProperty(headerIndices[i])) {
											pack.push(renderers[headerIndices[i]](dataPack[headerIndices[i]]));
										} else {
											pack.push(dataPack[headerIndices[i]]);
										}
									} else {
										pack.push('');
									}
								}

								return pack;
							};

							exportPack.data.push(borderLine);
							for (let i = 0; i < productValues.products.length; ++i) {
								let prod = productValues.products[i];
								exportPack.data.push(extractLineData(prod));

								for (let j = 0; j < sublines.length; ++j) {
									let subline = extractLineData(prod[sublines[j]]);
									subline[headerStateIndex] = subtitles[j];
									exportPack.data.push(subline);
								}

								//if (i < productValues.products.length - 1) {
								//	let emptyline = [];
								//	for (let j = 0; j < headerIndices.length; ++j) {
								//		emptyline.push('');
								//	}
								//	exportPack.data.push(emptyline);
								//}
								exportPack.data.push(borderLine);
							}

							let fileId = String(Ext.id()).replace('ext-gen', '');
							fileId = String(fileId).replace('ext-', '');
							const exportUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS_EXPORT.LIST.POST, {
								id: fileId
							});
							const exportUrlWithBorders = edi.utils.compileURL(exportUrl, {
								withBorders: true
							});
							edi.rest.downloadFile(exportUrlWithBorders, fileId, exportPack);
						}
					})
				]
			}
		});

		if (productValues.products.length) {
			for (let i = 0; i < productValues.products.length; i++) {
				let product = productValues.products[i];
				if (product && product.OriginalData && product.NewData && product.OriginalData.InfPolStr) {
					product.NewData.InfPolStr = product.OriginalData.InfPolStr;
				}
				grid.createPallet(product, false);
			}
		}
		return grid;
	}

	createDetailsPanel() {
		const me = this;

		let mainData = Ext.clone(me?.moduleData?.initData?.data) || {};
		mainData.countSignatures =
			(me.part2Head ? me.part2Head.countSignatures : 0) + (me.part1Head ? me.part1Head.countSignatures : 0);
		mainData.ids = [me.part1Head?.id];
		if (me.part2Head) {
			mainData.ids.push(me.part2Head.id);
		}

		const invoiceInfoData =
			edi.utils.getObjectProperty(me.part1, 'document.invoiceCorrectionInfo.invoiceInfo') || [];

		let currency = edi.utils.getObjectProperty(me.part1, 'document.invoiceCorrectionInfo.okv');
		if (currency) {
			const curObj = edi.utils.getOkv({
				code: currency
			});
			currency = curObj.charCode + ' - ' + curObj.codeStr + ' - ' + curObj.name;
		}

		const headData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			itemId: 'ukdHeadData',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('documents.form.type'),
							text: edi.utils.getObjectProperty(me.part1, 'document.function')
						},
						{
							title: edi.i18n.getMessage('document.form.number'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoiceCorrectionInfo.invoiceCorrectionNumber'
							)
						},
						{
							title: edi.i18n.getMessage('documents.column.date'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoiceCorrectionInfo.invoiceCorrectionDate'
							)
						},
						{
							title: edi.i18n.getMessage('invoice.currency'),
							itemId: 'ukdCurrency',
							isBlock: true,
							text: currency
						}
					]
				})
			]
		});

		const headData2 = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('documents.fns_ukd.invoiceInfoToUpd'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('document.form.number'),
							text: edi.utils.getObjectProperty(invoiceInfoData[0], 'number')
						},
						{
							title: edi.i18n.getMessage('documents.column.date'),
							date: edi.utils.getObjectProperty(invoiceInfoData[0], 'date')
						}
					]
				})
			]
		});

		const correctUkdField = edi.utils.getObjectProperty(
			me.part1,
			'document.invoiceCorrectionInfo.editedInvoiceCorrectionInfo'
		)
			? createFieldBlockForDetails({
					cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
					title: edi.i18n.getMessage('documents.fns_ukd.editedInvoiceCorrectionInfo'),
					items: [
						createLabelBlockForDetails({
							contents: [
								{
									title: edi.i18n.getMessage('document.form.number'),
									text: edi.utils.getObjectProperty(
										me.part1,
										'document.invoiceCorrectionInfo.editedInvoiceCorrectionInfo.numberEditedInvoiceCorrectionInfo'
									)
								},
								{
									title: edi.i18n.getMessage('documents.column.date'),
									date: edi.utils.getObjectProperty(
										me.part1,
										'document.invoiceCorrectionInfo.editedInvoiceCorrectionInfo.dateEditedInvoiceCorrectionInfo'
									)
								}
							]
						})
					]
			  })
			: null;

		const toCorrectedUpd = function (correctInfo) {
			let items = [];
			correctInfo = edi.utils.getObjectProperty(correctInfo[0], 'invoicesCorrectInfo');
			if (correctInfo && Array.isArray(correctInfo) && correctInfo.length > 0) {
				for (let i = 0; i < correctInfo.length; i++) {
					items.push(
						createFieldBlockForDetails({
							cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
							margin: '8 0 0 0',
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							items: [
								createLabelBlockForDetails({
									contents: [
										{
											title: edi.i18n.getMessage('documents.fns_ukd.invoicesCorrectInfo'),
											content: edi.utils.getObjectProperty(correctInfo[i], 'number')
										},
										{
											title: edi.i18n.getMessage('column.date'),
											date: edi.utils.getObjectProperty(correctInfo[i], 'date')
										}
									]
								})
							]
						})
					);
				}
			}
			return items;
		};

		const economiclifeDocName = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			hidden: isKSCHF_BussinessProcess(me.bpName),
			title: edi.i18n.getMessage('documents.fns_ukd.economiclifeDocName'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(me.part1, 'document.economiclifeDocName')
				})
			]
		});

		const primaryDocName = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			hidden: isKSCHF_BussinessProcess(me.bpName),
			title: edi.i18n.getMessage('documents.fns_ukd.primaryDocName'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(me.part1, 'document.primaryDocName')
				})
			]
		});

		const govAgreementId = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.govAgreementId'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(
						me.part1,
						'document.invoiceCorrectionInfo.additionalInfo.govagreementid'
					)
				})
			]
		});

		const createOS = function (selectorConfig) {
			Ext.merge(selectorConfig, {
				cls: '',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: me.part1
			});

			return createOrgSelector(selectorConfig);
		};

		const seller = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('document.seller'),
			items: [
				createOS({
					fieldsMap: edi.selectors.getUPDFieldsMap(
						'document.invoiceCorrectionInfo.sellerInfo',
						true,
						true,
						true
					)
				})
			]
		});

		const buyer = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('document.buyer'),
			items: [
				createOS({
					fieldsMap: edi.selectors.getUPDFieldsMap(
						'document.invoiceCorrectionInfo.buyerInfo',
						true,
						true,
						true
					)
				})
			]
		});

		let part2SignerFieldBlock = null;
		if (me.part2) {
			const signer = edi.utils.getObjectProperty(me.part2, 'buyerInfo.signers')[0];
			const legalEntityData = edi.utils.getObjectProperty(signer, 'legalEntity');
			const individualPersonData =
				edi.utils.getObjectProperty(signer, 'individualPerson') ||
				edi.utils.getObjectProperty(signer, 'individual') ||
				edi.utils.getObjectProperty(signer, 'individualInfo');

			const authArea = me.areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = me.signerBuyerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'state'),
					0,
					false,
					false,
					true
				);

			const part2SignerTestClassPrefix = 'part2signer';
			part2SignerFieldBlock = createFieldBlockForDetails({
				cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
				title: edi.i18n.getMessage('document.fns_upd.secondary.data'),
				margin: '32 0 0 0',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						gap: [16, 16],
						margin: '8 0 0 0',
						contents: [
							{
								typography: 'body-short_02',
								text: edi.i18n.getMessage('documents.create.torg.title.receive.description'),
								isBlock: true
							},
							{
								title: edi.i18n.getMessage('column.position'),
								textCls: `${part2SignerTestClassPrefix}-position`,
								hidden: individualPersonData,
								text: me.getPositionShortName(
									edi.utils.getObjectProperty(legalEntityData || individualPersonData, 'position')
								)
							},

							{
								title: edi.i18n.getMessage('last.name'),
								textCls: `${part2SignerTestClassPrefix}-lastName`,
								text:
									edi.utils.getObjectProperty(legalEntityData, 'fullName.lastName') ||
									edi.utils.getObjectProperty(individualPersonData, 'name.lastName') ||
									edi.utils.getObjectProperty(individualPersonData, 'fullName.lastName')
							},
							{
								title: edi.i18n.getMessage('first.name'),
								textCls: `${part2SignerTestClassPrefix}-firstName`,
								text:
									edi.utils.getObjectProperty(legalEntityData, 'fullName.firstName') ||
									edi.utils.getObjectProperty(individualPersonData, 'name.firstName') ||
									edi.utils.getObjectProperty(individualPersonData, 'fullName.firstName')
							},
							{
								title: edi.i18n.getMessage('patronymic.name'),
								textCls: `${part2SignerTestClassPrefix}-middleName`,
								text:
									edi.utils.getObjectProperty(legalEntityData, 'fullName.middleName') ||
									edi.utils.getObjectProperty(individualPersonData, 'name.middleName') ||
									edi.utils.getObjectProperty(individualPersonData, 'fullName.middleName')
							},
							{
								title: edi.i18n.getMessage('upd.receive.date'),
								isBlock: true,
								textCls: `${part2SignerTestClassPrefix}-receiveDate`,
								text: edi.utils.getObjectProperty(
									me.part2,
									'buyerInfo.contentFactsEconomicLife.agreementInfo.dateAgreement'
								)
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
								textCls: `${part2SignerTestClassPrefix}-authorityArea`,
								isBlock: true,
								text: authArea
									? authArea.get('name')
									: edi.utils.getObjectProperty(signer, 'authorityArea')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.status'),
								textCls: `${part2SignerTestClassPrefix}-state`,
								isBlock: true,
								text: signerState
									? signerState.get('name')
									: edi.utils.getObjectProperty(signer, 'state')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
								textCls: `${part2SignerTestClassPrefix}-baseAuthory`,
								text: edi.utils.getObjectProperty(signer, 'baseAuthory')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
								textCls: `${part2SignerTestClassPrefix}-baseOrgAuthory`,
								text: edi.utils.getObjectProperty(signer, 'baseOrgAuthory')
							}
						]
					})
				]
			});
		}

		let productsGrid = me.createProductsGrid();

		let mainFields = [headData, headData2, correctUkdField];
		mainFields = mainFields.concat(
			toCorrectedUpd(edi.utils.getObjectProperty(me.part1, 'document.invoiceCorrectionInfo.invoiceInfo')),
			economiclifeDocName,
			primaryDocName,
			govAgreementId
		);

		const organizationsTwoColumnsLayout = createMaxWidthContainerForDetail({
			items: [
				createTwoColumnsLayout([seller], [buyer], 0.5, {
					items2Conf: {
						margin: '0 0 0 10'
					}
				})
			]
		});

		let fields = [];
		fields = fields.concat(
			organizationsTwoColumnsLayout,
			me.createInvoiceTextInfosFieldSet(),
			me.createBaseShipmentOfGoodsFieldSet()
		);

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				me.createModuleHeadPanel(mainData),
				createMaxWidthContainerForDetail({
					items: [...mainFields]
				}),
				...fields,
				productsGrid.wrapper,
				me.createSignerItems(),
				part2SignerFieldBlock
			]
		});
	}

	createModuleHeadPanel(mainData) {
		const me = this;

		return createDocumentHeaderPanel(me.moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			utochReasonText: me.utochReasonText,
			showAnnulLog: true,
			rejectReasonText: me.textNoticeUtoch,
			summTitle: 'document.ukd.summ',
			isReceipts: !(me.moduleData.initData && me.moduleData.initData.isDocumentSharing),
			usingReport: true,
			isCrptStatus: true,
			showCRPTPrintBtn: edi.methods.checkAllowToShowCrptPrintBtn(me.moduleData.initData.data.attributes),
			defaultFormat: edi.constants.REPORT_FORMATS.XLS,
			noUsage: !!(me.moduleData.initData && me.moduleData.initData.isDocumentSharing),
			costDecimal: edi.constants.UKD_PRICE_PRECISION
		});
	}

	createInvoiceTextInfosFieldSet() {
		const me = this;

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.fns_ukd.special_identifiers'),
			collapsible: true,
			margin: '24 0 0 0',
			hidden: !edi.utils.getObjectProperty(me.part1, 'document.invoiceCorrectionInfo.info.textInfos').length,
			items: [createTwoColumnsLayout(me.createInvoiceTextInfosField(), [], 1)]
		});
	}

	createInvoiceTextInfosField() {
		const me = this;

		let items = [];
		const fieldData = edi.utils.getObjectProperty(me.part1, 'document.invoiceCorrectionInfo.info.textInfos');
		for (let i = 0; i < fieldData.length; i++) {
			items.push(
				createFieldBlockForDetails({
					cls: FIELD_BLOCK_CLS.default,
					items: [
						createLabelForDetails({
							title: edi.utils.getObjectProperty(fieldData[i], 'identifier'),
							text: edi.utils.getObjectProperty(fieldData[i], 'value')
						})
					]
				})
			);
		}

		return items;
	}

	createBaseShipmentOfGoodsFieldSet() {
		const me = this;

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.fns_ukd.informationOnCoordination'),
			collapsible: true,
			margin: '24 0 0 0',
			items: [createTwoColumnsLayout(me.createBaseOfCorrectionsFields(), [], 1)]
		});
	}

	createBaseOfCorrectionsFields() {
		const me = this;

		let items = [];
		const fieldData = edi.utils.getObjectProperty(
			me.part1,
			'document.informationOnCoordination.docBaseOfCorrections'
		);
		for (let i = 0; i < fieldData.length; i++) {
			let panel = createFieldBlockForDetails({
				cls: FIELD_BLOCK_CLS.default,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						contents: [
							{
								title: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.name'),
								text: edi.utils.getObjectProperty(fieldData[i], 'name')
							},
							{
								title: edi.i18n.getMessage('column.license.number'),
								text: edi.utils.getObjectProperty(fieldData[i], 'number')
							},
							{
								title: edi.i18n.getMessage('date'),
								date: edi.utils.getObjectProperty(fieldData[i], 'date')
							},
							{
								title: edi.i18n.getMessage('column.identifier'),
								text: edi.utils.getObjectProperty(fieldData[i], 'identifier')
							},
							{
								title: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.info'),
								text: edi.utils.getObjectProperty(fieldData[i], 'info')
							}
						]
					})
				]
			});
			//если строка  "Без основания" то выводим только ее и не выводим остальные
			let value = edi.utils.getObjectProperty(fieldData[i], 'name');
			let isWithoutBase = value && value.toLowerCase() === me.baseOfCorrectionsContsName.toLowerCase();
			if (isWithoutBase) {
				items = [];
				items.push(panel);
				break;
			} else {
				items.push(panel);
			}
		}

		const fieldDataTransferDoc = edi.utils.getObjectProperty(
			me.part1,
			'document.informationOnCoordination.transferDoc'
		);
		for (let j = 0; j < fieldDataTransferDoc.length; j++) {
			items.push(
				createFieldBlockForDetails({
					cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
					margin: '8 0 0 0',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createLabelBlockForDetails({
							contents: [
								{
									title: edi.i18n.getMessage('documents.fns_ukd.transferDoc'),
									text: edi.utils.getObjectProperty(fieldDataTransferDoc[j], 'name')
								},
								{
									title: edi.i18n.getMessage('column.license.number'),
									text: edi.utils.getObjectProperty(fieldDataTransferDoc[j], 'number')
								},
								{
									title: edi.i18n.getMessage('date'),
									date: edi.utils.getObjectProperty(fieldDataTransferDoc[j], 'date')
								},
								{
									title: edi.i18n.getMessage('column.identifier'),
									text: edi.utils.getObjectProperty(fieldDataTransferDoc[j], 'identifier')
								},
								{
									title: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.info'),
									text: edi.utils.getObjectProperty(fieldDataTransferDoc[j], 'info')
								}
							]
						})
					]
				})
			);
		}

		items.push(
			createFieldBlockForDetails({
				cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
				margin: '8 0 0 0',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelForDetails({
						title: edi.i18n.getMessage('documents.fns_ukd.operContent'),
						text: edi.utils.getObjectProperty(me.part1, 'document.informationOnCoordination.operContent')
					})
				]
			})
		);

		return items;
	}

	createSignerItems() {
		const me = this;

		let fieldData = edi.utils.getObjectProperty(me.part1, 'document.signers');
		if (!Array.isArray(fieldData)) {
			fieldData = [fieldData];
		}

		const createFields = function (fieldData) {
			const legalEntityData = edi.utils.getObjectProperty(fieldData, 'legalEntity');
			const individualPersonData =
				edi.utils.getObjectProperty(fieldData, 'individualPerson') ||
				edi.utils.getObjectProperty(fieldData, 'individual');

			const authArea = me.areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = me.signerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'state'),
					0,
					false,
					false,
					true
				);

			const signerTestClassPrefix = 'signer';
			return createLabelBlockForDetails({
				gap: [16, 16],
				margin: '8 0 0 0',
				contents: [
					{
						title: edi.i18n.getMessage('column.position'),
						hidden: !legalEntityData,
						textCls: `${signerTestClassPrefix}-position`,
						text: me.getPositionShortName(edi.utils.getObjectProperty(legalEntityData, 'position'))
					},
					{
						title: edi.i18n.getMessage('last.name'),
						textCls: `${signerTestClassPrefix}-lastName`,
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'nameType.lastName')
					},
					{
						title: edi.i18n.getMessage('first.name'),
						textCls: `${signerTestClassPrefix}-firstName`,
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'nameType.firstName')
					},
					{
						title: edi.i18n.getMessage('patronymic.name'),
						textCls: `${signerTestClassPrefix}-middleName`,
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.middleName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.middleName') ||
							edi.utils.getObjectProperty(individualPersonData, 'nameType.middleName')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
						textCls: `${signerTestClassPrefix}-authorityArea`,
						isBlock: true,
						text: authArea ? authArea.get('name') : edi.utils.getObjectProperty(fieldData, 'authorityArea')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.status'),
						textCls: `${signerTestClassPrefix}-state`,
						isBlock: true,
						text: signerState ? signerState.get('name') : edi.utils.getObjectProperty(fieldData, 'state')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						textCls: `${signerTestClassPrefix}-baseAuthory`,
						text: edi.utils.getObjectProperty(fieldData, 'baseAuthory')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
						textCls: `${signerTestClassPrefix}-baseOrgAuthory`,
						text: edi.utils.getObjectProperty(fieldData, 'baseOrgAuthory')
					}
				]
			});
		};

		return createContainer({
			items: fieldData.map((signer, i) =>
				createFieldBlockForDetails({
					cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					title:
						fieldData.length > 1 && i > 0
							? `${edi.i18n.getMessage('company.signer')} ${++i}`
							: edi.i18n.getMessage('company.signer'),
					items: [createFields(signer)]
				})
			)
		});
	}

	/**
	 * Creates action pane
	 */
	createModuleActionsPanel() {
		const me = this;

		const data = me.moduleData.initData.data;

		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg),
			isOutGoingDocument = edi.constants.DIRECTIONS.OUTGOING === direction;
		const hasPart2 = !!edi.utils.getAttributeByName(data.attributes, 'hasPart2') || !!me.part2Head;
		const totalSignaturesCount = edi.document.actions.getSignCount(data);

		const neededSignaturesCount = isOutGoingDocument
			? totalSignaturesCount - me.part1Head.countSignatures
			: totalSignaturesCount - (hasPart2 && me.part2Head ? me.part2Head.countSignatures : 0);
		let actionsPanel = createActionsPanel();
		let excludeActions = {};

		const editCmp = edi.document.actions.createEditActionButton('document.create.fns_ukd_5_01n', data, data.number);

		const readCmp = createActionsButton({
			text: edi.i18n.getMessage('document.mark.read'),
			glyph: edi.constants.ICONS.READ,
			handler: function () {
				edi.core.confirm(
					edi.i18n.getMessage('document.mark.read'),
					edi.i18n.getMessage('document.mark.read.question'),
					function () {
						me.moduleData.tab.setLoading();
						const success = function () {
							me.moduleData.tab.setLoading(false);
							edi.events.documents.fireEvent('change', {
								id: data.id
							});
						};
						const failure = edi.document.actions.defaultFailureHandler(
							me.moduleData.tab,
							'document.error.mark.read'
						);
						const markRead = function () {
							let postData = {};
							postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = edi.constants.STATE.SENT;
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: data.id
									},
									true
								),
								'PUT',
								Ext.encode(postData),
								success,
								failure
							);
						};
						if (me.ukdRoamingStatus6) {
							edi.utils.sign(
								me.ukdRoamingStatus6,
								me.moduleData.tab,
								function (failed) {
									if (failed) {
										failure();
									} else {
										success();
									}
								},
								undefined,
								undefined,
								true,
								null,
								{
									signRefuse: () => me.moduleData.tab?.setLoading(false)
								}
							);
						} else {
							markRead();
						}
					}
				);
			}
		});

		let customButtons = [];
		let checkOptions = edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data));
		if (edi.action.isAvailable('CREATE_CUD', checkOptions) && edi.permissions.hasPermission('CREATE_EDI_FNS_UKD')) {
			let actionCreateCUD = edi.document.actions.createConfigForDocumentCreateButton(
				'action.cukd',
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				undefined,
				undefined,
				{ id: me.part1Head?.id },
				me.moduleData,
				{
					isFromTransformation: true,
					postData: {
						docType: 'EDI_FNS_UKD_P1',
						docFlowType: 'CORRECTION_UKD',
						version: '5.01-N'
					},
					addData: {
						meta: Ext.clone(data),
						versionId: '5.01-N',
						isCorrect: true
					}
				}
			);

			customButtons.push(
				createActionsButton({
					showInFirstHalf: true,
					order: 500,
					text: edi.i18n.getMessage('action.correct.or.fix'),
					glyph: edi.constants.ICONS.ADD,
					allowMultiClick: true,
					menu: {
						plain: true,
						hideMode: 'display',
						items: [actionCreateCUD]
					}
				})
			);
		}

		let customButtonsOptions = {
			// EW start
			direction,
			hasPart2,
			neededSignaturesCount
			// EW end
		};

		customButtons = customButtons.concat(me.addCustomButtons(customButtonsOptions));
		excludeActions = { ...excludeActions, ...me.addExcludeButtons() };

		const actionProps = {
			SIGN: {
				methodAddOptions: {
					contentId:
						edi.constants.DIRECTIONS.OUTGOING === direction || edi.constants.DIRECTIONS.LOOP === direction
							? me.part1Head.id
							: me.part2Head
							? me.part2Head.id
							: null,
					useBeforeAction:
						data.state === edi.constants.STATE.DRAFT ||
						data.state === edi.constants.STATE.READ ||
						data.state === edi.constants.STATE.READ
				}
			},
			EDIT: {
				component: editCmp
			},
			READ: {
				component: readCmp
			},
			REFRESH: {
				handler: function () {
					me.changeHandler(data);
				}
			},
			ANNUL: {
				methodAddOptions: me.annulDoc
			},
			EXPORT: {
				label: edi.i18n.getMessage('action.export.document'),
				exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
				exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE_WITH_SIGN, {
					documentId: data.id
				}),
				extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
				extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON_ALL_DOCUMENT, {
					documentId: data.id
				}),
				addExtendedExport: true,
				xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
				addXmlExport: true
			},
			CUSTOM_BUTTONS: {
				buttons: customButtons
			}
		};

		// Костыль, чтобы была возможность подписать УКД в статусе UTOCH
		// т.к. при UTOCH действие READ должно быть скрыто, но оно нужно для подписания по БП (если state = sent)
		// мы разрешаем его в actionRule, но скрываем в excludeActions, чтобы нельзя было прочитать на UI
		if (data.businessState === edi.constants.STATE.UTOCH) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.READ] = true;
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: me.moduleData,
			needSignatures: neededSignaturesCount,
			hasPart2: hasPart2,
			excludeActions: excludeActions,
			actionProps: actionProps
		});

		return actionsPanel;
	}

	addCustomButtons(options) {
		return [];
	}

	addExcludeButtons(options) {
		return {};
	}

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	changeHandler(data) {
		const me = this;

		if (
			data &&
			data.id &&
			(data.id == me.moduleData.initData.data.id ||
				(me.part1Head && data.id == me.part1Head.id) ||
				(me.part2Head && data.id == me.part2Head.id))
		) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(me.moduleData);
			} else {
				me.renderData();
			}
		}
	}

	//проверяет есть ли в продукте данные в "Номера средств идентификации"  (до или после изменений)
	checkProductHasKiz(product) {
		let identsBefore = product?.identifyProductToolNumbersBefore || [];
		let identsAfter = product?.identifyProductToolNumbersAfter || [];
		let allIdents = [].concat(identsBefore, identsAfter);
		/*return allIdents.some(ident => {
			return Array.isArray(ident?.kiz) && ident.kiz.length > 0;
		});*/
		return allIdents.length > 0;
	}

	signHandler(data) {
		const me = this;

		if (data?.id && String(data.id) === String(me.moduleData.initData.data?.id)) {
			let markNotification =
				edi.utils.getObjectProperty(edi.core.getUserData(), 'org.attributes.markNotification.value') === 'true';
			let productInfo = edi.utils.getObjectProperty(me.part1, 'document.cinvoiceTable.productInfo');
			if (markNotification && Array.isArray(productInfo) && productInfo.some(me.checkProductHasKiz)) {
				edi.methods.checkMarkingPartners();
			}
		}
	}

	renderData(initCallBack) {
		const me = this;

		me.moduleData.tab.setLoading();
		const failure = edi.document.actions.defaultFailureHandler(
			me.moduleData.tab,
			'error.getting.data',
			function () {
				edi.modulesHandler.removeModule(me.moduleData);
			}
		);
		let documentHeaderData = me.moduleData.initData.data;

		edi.document.actions.updateDocumentHeaderData(me.moduleData, function () {
			documentHeaderData = me.moduleData.initData.data;
			me.rejectReasonTextValue =
				edi.utils.getObjectProperty(documentHeaderData.attributes, 'utochCreated').value === 'true';

			edi.rest.sendRequest(
				edi.document.actions.formatLinkedUri(me.moduleData.initData),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						let i,
							ids = [];
						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
								me.rejectReasonText = edi.utils.getObjectProperty(
									data.data.children[i],
									'attributes.REJECT_REASON.value'
								);
							}
						}
						const latestAnnulRejectReason = data.data.children
							.filter((child) => child.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL)
							.reduce((latestAnnulRejectReason, child) => {
								return latestAnnulRejectReason?.modifyDate > child.modifyDate
									? latestAnnulRejectReason
									: child;
							}, null);
						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1) {
								ids.push(data.data.children[i].id);
							} else if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P2) {
								ids.push(data.data.children[i].id);
							}
							if (ids.length === 2) {
								break;
							}
						}
						if (ids.length) {
							edi.rest.sendRequest(
								edi.document.actions.formatBatchHeaderUri(me.moduleData.initData),
								'POST',
								Ext.encode(ids),
								function (data) {
									me.part2Head = null;
									for (let i = 0; i < data.items.length; i++) {
										if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1) {
											me.part1Head = data.items[i];
											edi.document.actions.changeTabTitle(me.moduleData.tab, me.part1Head.number);
										} else if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P2) {
											me.part2Head = data.items[i];
										}
									}
									me.getPartsData(ids, documentHeaderData, initCallBack);
								},
								failure
							);
						} else {
							failure();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		});
		me.isVersion2 =
			edi.utils.getObjectProperty(me.moduleData, 'initData.fns_upd.formVersion') === '5.02' ||
			edi.utils.getObjectProperty(me.moduleData, 'initData.data.versionId') === '5.02';
	}

	addModuleActionsPanel() {
		const me = this;
		me.moduleData.tab.add(me.createModuleActionsPanel());
	}

	failure() {
		const me = this;

		return edi.document.actions.defaultFailureHandler(me.moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(me.moduleData);
		});
	}

	finishLoad(documentHeaderData, initCallBack) {
		const me = this;

		let modulePanel = createDetailsModulePanel();

		if (me.moduleData.tab.isDestroyed) {
			return;
		}
		modulePanel.add(me.createDetailsPanel());
		me.moduleData.tab.removeAll();

		me.addModuleActionsPanel();
		me.moduleData.tab.add(modulePanel);

		const isBlocked = edi.utils.getAttributeByName(documentHeaderData.attributes, 'isBlocked', undefined, true);
		if (
			!isBlocked &&
			me.annulDoc &&
			me.annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
			me.annulDoc.toOrg.id == edi.core.getUserOrgID()
		) {
			edi.methods.documents.showActiveAnnulModal(me.annulDoc, me.moduleData);
		}

		if ('function' == typeof initCallBack) {
			initCallBack();
		} else {
			me.moduleData.tab.setLoading(false);
		}
	}

	continueWithRendering(documentHeaderData, initCallBack) {
		const me = this;

		const direction = edi.utils.getDocumentDirection(documentHeaderData.toOrg, documentHeaderData.fromOrg);

		me.bpName = edi.utils.getAttributeByName(documentHeaderData.attributes, 'bpName');
		const businessState = edi.utils.getObjectProperty(documentHeaderData, 'businessState');

		me.annulDoc = null;
		me.utochReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'UTOCH_REASON');
		const isAnnulUtoch =
			me.utochReasonText ||
			businessState === edi.constants.STATE.ON_ANNUL ||
			businessState === edi.constants.STATE.ANNUL_SIGN;
		me.rejectReasonTextValue =
			edi.utils.getObjectProperty(documentHeaderData.attributes, 'utochCreated').value === 'true';

		const isIncomingRoaming =
			edi.constants.DIRECTIONS.INCOMING === direction &&
			documentHeaderData.state === edi.constants.STATE.SENT &&
			me.bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_ROAMING_DECREE_14;

		if (isAnnulUtoch || isIncomingRoaming) {
			edi.document.actions
				.getLinkedAnnulDocForUpd(documentHeaderData)
				.then(({ success, error, linkedDocs, annulDoc: annul }) => {
					if (success) {
						if (annul) {
							me.annulDoc = annul;
						}

						let utochId;
						if (Array.isArray(linkedDocs)) {
							linkedDocs.forEach((doc) => {
								if (
									isAnnulUtoch &&
									doc.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH
								) {
									utochId = doc.id;
								} else if (
									isIncomingRoaming &&
									doc.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL &&
									doc.state === edi.constants.STATE.RECEIVER_UKD_RECEIVED_ACK
								) {
									me.ukdRoamingStatus6 = doc;
								}
							});
						}
						if (!me.utochId) {
							me.finishLoad(documentHeaderData, initCallBack);
						} else {
							edi.rest.sendRequest(
								edi.document.actions.formatDetailsUri(me.moduleData.initData, utochId, true),
								'GET',
								{},
								function (responseData) {
									if (responseData.success && responseData.data) {
										me.utochReasonText = edi.utils.getObjectProperty(
											responseData.data,
											'Document.DataOfUvUtoch.TextNoticeUtoch'
										);
									}
									me.finishLoad(documentHeaderData, initCallBack);
								},
								me.failure
							);
						}
					} else {
						me.failure(error);
					}
				});
		} else {
			me.finishLoad(documentHeaderData, initCallBack);
		}
	}

	getRejectReason(documentHeaderData, initCallBack) {
		const me = this;

		edi.rest.sendRequest(
			edi.document.actions.formatRejectReasonUri(me.moduleData.initData),
			'GET',
			null,
			function (data) {
				me.textNoticeUtoch = data && data.data && data.data.TextNoticeUtoch ? data.data.TextNoticeUtoch : null;
				me.continueWithRendering(documentHeaderData, initCallBack);
			},
			function () {
				return me.continueWithRendering(documentHeaderData, initCallBack);
			}
		);
	}

	getOrganizationAdditionalFields(orgId, documentHeaderData, initCallBack) {
		const me = this;

		const failure = function (responseData) {
			const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
			edi.core.showWarn(errorMsg, function () {
				return me.continueWithRendering(documentHeaderData, initCallBack);
			});
		};

		edi.rest.sendRequest(
			edi.document.actions.formatDocDataUri(me.moduleData.initData, {
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				orgId: orgId
			}),
			'GET',
			null,
			function (data) {
				if (data && data.items) {
					me.infoColumnsConfig = data.items.length ? data.items : null;
					if (me.rejectReasonTextValue) {
						me.getRejectReason(documentHeaderData, initCallBack);
					} else {
						me.continueWithRendering(documentHeaderData, initCallBack);
					}
				} else {
					failure();
				}
			},
			failure
		);
	}

	getPartsData(ids, documentHeaderData, initCallBack) {
		const me = this;

		return edi.rest.sendRequest(
			edi.document.actions.formatBatchContentUri(me.moduleData.initData),
			'POST',
			Ext.encode(ids),
			function (data) {
				me.part2 = null;
				if (data && data.items) {
					for (let i = 0; i < data.items.length; i++) {
						if (me.part1Head.id == data.items[i].header) {
							me.part1 = data.items[i];
						} else if (me.part2Head && me.part2Head.id == data.items[i].header) {
							me.part2 = data.items[i];
						}
					}
					me.getOrganizationAdditionalFields(documentHeaderData.toOrg.id, documentHeaderData, initCallBack);
				} else {
					me.failure(data);
				}
			},
			me.failure
		);
	}

	onDestroy() {
		const me = this;

		edi.events.documents.un('change', me.changeHandler);
		edi.events.documents.un('sign', me.signHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

Ext.namespace('edi.modules');
edi.modules['document.details.fns_ukd_5_01n'] = DocumentDetailsFns_ukd_5_01nModule;
