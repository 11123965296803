// @ts-ignore
import { createModalPanel } from '@UIkit/components/modal';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { createModalForm } from '@UIkit/components/panels';
// @ts-ignore
import { createCombo } from '@UIkit/components/fields';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';
import { RealtimeMessage } from '@App/js/definitions/request';

export interface UploadDocToPackageWindowProps {
	packageId: number;
	receiverOrgId: number;
	callback?: (eventData: { packageId: UploadDocToPackageWindowProps['packageId'] }) => void;
}

export class UploadDocToPackageWindow {
	props: UploadDocToPackageWindowProps;
	modal: ExtComponent;
	modalTitle = 'web.upload.document.modal.title';
	form: ExtComponent;
	uploadButton: ExtComponent;
	uploadButtonText = 'form.btn.save';
	fileDropper: ExtComponent;
	maxFileSize: number = 10;
	doctypeCombo: ExtComponent;
	_onFileUploaded: UploadDocToPackageWindow['onFileUploaded'];
	_correlationId: number | null = null;

	constructor(props: UploadDocToPackageWindowProps) {
		const me = this;
		me.props = props;
		me._onFileUploaded = me.onFileUploaded.bind(me);
		me.createWindow();
	}

	setModalLoading(loading: boolean | string | AnyObject) {
		const me = this;
		!me.modal.destroyed && me.modal.setLoading(loading);
	}

	getTypes(): string[] {
		return [];
	}

	docTypeChangeHandler(combo: ExtComponent, newValue: string | null, oldValue: string | null): void {}

	createDoctypeCombo(): UploadDocToPackageWindow['doctypeCombo'] {
		const me = this;
		const types = me.getTypes();
		me.doctypeCombo = createCombo({
			name: 'docType',
			store: edi.stores.createSimpleInlineStore(types, function (type: string) {
				return edi.i18n.getMessage(`documents.doctype.${type}`);
			}),
			value: types[0],
			allowBlank: false,
			listeners: {
				change: me.docTypeChangeHandler.bind(me)
			}
		}) as ExtComponent;
		return me.doctypeCombo;
	}

	createUploadPanel(): UploadDocToPackageWindow['fileDropper'] {
		const me = this;
		me.fileDropper = createFileDropper({
			name: 'attachment',
			itemId: 'fileDropper',
			allowBlank: false,
			maxFileSize: me.maxFileSize,
			checkMaxFileSize: true,
			getAllowedFileTypesText: function () {
				return edi.i18n.getMessage('fileDropper.allow.file.types.xml', {
					limit: me.maxFileSize
				});
			},
			afterMarkInvalid() {
				me.form?.isValid();
			}
		}) as ExtComponent;
		return me.fileDropper;
	}

	createModalItems(): ExtComponent[] {
		const me = this;
		me.form = createModalForm({
			items: [me.createDoctypeCombo(), me.createUploadPanel()]
		});
		return [me.form];
	}

	completeUpload() {
		const me = this;
		me.modal.close();
		documentPackageMethods.eventsObserver.fireEvent('uploadNewDocument', { packageId: me.props.packageId });
		typeof me.props.callback === 'function' && me.props.callback({ packageId: me.props.packageId });
	}

	onFileUploaded(msg: RealtimeMessage) {
		const me = this;
		if (msg.data.meta.mainObjId === me._correlationId) {
			edi.events.uploadDocument.un('change', me._onFileUploaded);
			if (msg.data.meta.messageType === 'EDI_WEB_UPLOAD_COMPLETED') {
				me.completeUpload();
			} else {
				me.setModalLoading(false);
				const errorText = msg.data.content.Error;
				edi.core.showError(edi.i18n.getMessage('file.processed.PROCESSED_WITH_ERROR'));
			}
		}
	}

	getPayload(): AnyObject {
		const me = this;
		const file = me.fileDropper._fileField.fileInputEl.el.dom.files[0];
		return {
			byUserId: edi.core.getUserData().id,
			byOrgId: edi.core.getUserData().org.id,
			org_id_receiver: me.props.receiverOrgId,
			docType: me.doctypeCombo.getValue(),
			attachment: file,
			fileName: file.name,
			packageId: me.props.packageId
		};
	}

	async uploadButtonHandler() {
		const me = this;
		me._correlationId = null;
		me.setModalLoading(true);
		const { success, data } = await edi.rest.uploadFormAsync({
			url: edi.rest.services.WEB_UPLOAD_DOCUMENT.UPLOAD,
			method: 'POST',
			formData: me.getPayload()
		});
		if (success) {
			me._correlationId = data.data.id;
			edi.events.uploadDocument.on('change', me._onFileUploaded);
		} else {
			me.setModalLoading(false);
			edi.rest.getErrorHandler()(data);
		}
	}

	createUploadButton(): UploadDocToPackageWindow['uploadButton'] {
		const me = this;
		me.uploadButton = createButton({
			cls: BUTTON_CLS.primary,
			text: edi.i18n.getMessage(me.uploadButtonText),
			handler: me.uploadButtonHandler.bind(me),
			bindToForm: me.form
		}) as ExtComponent;
		return me.uploadButton;
	}

	createModalButtons(): ExtComponent[] {
		const me = this;
		return [me.createUploadButton()];
	}

	createWindow(): UploadDocToPackageWindow['modal'] {
		const me = this;
		me.modal = createModalPanel({
			title: edi.i18n.getMessage(me.modalTitle),
			items: me.createModalItems(),
			buttonsBefore: me.createModalButtons()
		}) as ExtComponent;
		return me.modal;
	}

	continueRendering(maxFileSize: number) {
		const me = this;
		me.maxFileSize = maxFileSize;
		me.fileDropper.setMaxFileSize(me.maxFileSize);
	}

	show() {
		const me = this;
		me.modal.show();
		edi.methods.loadSettingsAndContinue(me.continueRendering.bind(me), null, 'LIMIT_SIZE');
	}
}
