import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { COMMONPRICAT_LINE_MODEL, COMMONPRICAT_PRICE_REGIONS_MODEL } from './models';
import { COMMONPRICAT_LINE_COLUMN, COMMONPRICAT_PRICE_REGIONS_COLUMN } from './columns';
import { getCommonPricatFieldsMap } from './selectors';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import {
	createContainer,
	createFieldBlockForDetails,
	createMaxWidthContainerForDetail
} from '@UIkit/components/panels';

/**
 * Class for commonpricat details
 * @author Anastasiya Zholudeva
 */
Ext.namespace('edi.modules');
edi.modules['document.details.commonpricat'] = function () {
	let moduleData, compricat, bpName, docHeader, izvPolSignModal;
	let productValues = {
		products: []
	};
	let priceRegionsGrid,
		priceRegionsValues = [],
		customFieldsContainer,
		productsGrid,
		topPath = '//Document-PriceCatalogue/';
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		if (moduleData.initData.data) {
			compricat = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			docHeader = moduleData.initData.data;
			bpName = edi.utils.getObjectProperty(docHeader, 'attributes.bpName.value');
		}

		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const createMainDataBlock = function (header) {
		const headerLabelText = [
			{
				title: edi.i18n.getMessage('common.pricat.bp.name'),
				text: bpName ? edi.i18n.getMessage('documents.doctype.bp.' + bpName) : ''
			},
			{
				title: edi.i18n.getMessage('document.form.number'),
				text: edi.utils.getObjectProperty(header, 'PriceCatalogueNumber')
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(header, 'PriceCatalogueDate'))
			}
		];
		return createFieldBlockForDetails({
			cls: 'edi-from-maxwidth',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: headerLabelText
				})
			]
		});
	};

	const createPartiesBlock = function (parties) {
		const createOS = function (selectorConfig) {
			Object.assign(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: parties
			});

			return createOrgSelector(selectorConfig);
		};

		let buyer = createOS({
			fieldsMap: getCommonPricatFieldsMap('Buyer')
		});

		let seller = createOS({
			fieldsMap: getCommonPricatFieldsMap('Seller', {
				license_type: null,
				license_name: null
			})
		});

		return createMaxWidthContainerForDetail({
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.seller'),
					items: [seller]
				}),
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.buyer'),
					items: [buyer]
				})
			]
		});
	};

	const createCatalogPeriodBlock = function (document) {
		const startDate = edi.utils.getObjectProperty(document, 'PriceCatalogue-Header.PeriodStartDate');
		const endDate = edi.utils.getObjectProperty(document, 'PriceCatalogue-Header.PeriodEndDate');

		const formatDate = function (value) {
			return edi.renderers.fnsDateFromClient(value) || null;
		};

		return createFieldBlockForDetails({
			cls: 'edi-from-maxwidth',
			title: edi.i18n.getMessage('pricat.document.period'),
			layout: {
				type: 'grid'
			},
			items: [
				createLabelForDetails({
					title: edi.i18n.getMessage('date'),
					date: [formatDate(startDate), formatDate(endDate)]
				})
			]
		});
	};

	const createAdditionalInformationBlock = function (document, header) {
		const additionalInformationLabelText = [
			{
				title: edi.i18n.getMessage('column.catalog.type'),
				text: edi.i18n.getMessage(
					'common.pricat.document.code.' + edi.utils.getObjectProperty(header, 'DocumentNameCode')
				)
			},
			{
				title: edi.i18n.getMessage('functional.document.code'),
				text: edi.i18n.getMessage(
					'common.pricat.functional.document.code.' +
						edi.utils.getObjectProperty(document, 'PriceCatalogue-Header.DocumentFunctionCode')
				)
			},
			{
				title: edi.i18n.getMessage('ordrsp.currency'),
				text: edi.utils.getObjectProperty(document, 'PriceCatalogue-Header.PriceCatalogueCurrency')
			}
		];

		return createFieldBlockForDetails({
			cls: 'edi-from-maxwidth',
			title: edi.i18n.getMessage('document.common.pricat.section.additional'),
			layout: {
				type: 'grid'
			},
			items: [
				createLabelBlockForDetails({
					contents: additionalInformationLabelText
				})
			]
		});
	};

	/**
	 * Дополнительные поля
	 */
	const createCustomFieldsContainer = function () {
		return (customFieldsContainer = createContainer({}));
	};

	const createAgreementBlock = function (document) {
		const agreementLabelText = [
			{
				title: edi.i18n.getMessage('documents.create.common.pricat.nr'),
				text: edi.utils.getObjectProperty(document, 'PriceCatalogue-Header.Reference.ContractNumber')
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.renderers.fnsDateFromClient(
					edi.utils.getObjectProperty(document, 'PriceCatalogue-Header.Reference.ContractDate')
				)
			}
		];

		return createFieldBlockForDetails({
			cls: 'edi-from-maxwidth',
			title: edi.i18n.getMessage('document.common.pricat.section.agreement'),
			layout: {
				type: 'grid'
			},
			items: [
				createLabelBlockForDetails({
					contents: agreementLabelText
				})
			]
		});
	};

	const createPriceSegmentBlock = function (document) {
		let parties = edi.utils.getObjectProperty(document ? document : compricat, 'PriceCatalogue-Parties');
		return createFieldBlockForDetails({
			cls: 'edi-from-maxwidth',
			title: edi.i18n.getMessage('common.pricat.price.segment'),
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(parties, 'PriceRegions.PriceRegionText')
				})
			]
		});
	};

	const createRemarkBlock = function (document) {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			layout: {
				type: 'grid',
				area: [[8]]
			},
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(document, 'PriceCatalogue-Header.Remarks')
				})
			]
		});
	};

	const createPriceRegionsGrid = function (parties) {
		if (edi.utils.getObjectProperty(parties, 'PriceRegions.PriceRegionsList', true).length > 0) {
			priceRegionsValues = edi.utils.getObjectProperty(parties, 'PriceRegions.PriceRegionsList', true);
		}

		priceRegionsGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.pricat.price.priceRegions'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: COMMONPRICAT_PRICE_REGIONS_MODEL,
			gridColumnConfig: COMMONPRICAT_PRICE_REGIONS_COLUMN,
			allowBlank: true,
			data: priceRegionsValues,
			readOnly: true,
			hasTotal: false,
			docType: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
			docData: parties,
			modalFormConfig: {
				title: 'documents.common.pricat.unloadingPoint',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('column.code'),
								name: 'Code'
							},
							{
								title: edi.i18n.getMessage('column.name'),
								name: 'Name'
							},
							{
								title: edi.i18n.getMessage('documents.column.remark'),
								maxLength: 140,
								name: 'Remarks'
							}
						]
					}
				]
			}
		});

		return priceRegionsGrid;
	};

	const createProductGrid = function (document) {
		let lines = edi.utils.getObjectProperty(document, 'PriceCatalogue-Lines.Line', true),
			i;
		if ('string' != typeof lines) {
			for (i = 0; i < lines.length; i++) {
				let values = lines[i]['Line-Item'],
					priceRegions = lines[i]['Line-PriceRegions'];

				if (priceRegions) {
					values['PriceRegionText'] = priceRegions.PriceRegionText;
				}
				productValues.products.push(values);
			}
		}

		return (productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.common.pricat.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: COMMONPRICAT_LINE_MODEL,
			gridColumnConfig: COMMONPRICAT_LINE_COLUMN,
			hasTotal: false,
			data: productValues.products,
			readOnly: true,
			docType: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
			docData: document,
			modalFormConfig: {
				title: 'grid.line.items',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'BASIC',
						items: [
							{
								title: edi.i18n.getMessage('document.slsfct.column.column.barCode'),
								name: 'EAN'
							},
							{
								title: edi.i18n.getMessage('common.pricat.productName'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitOfMeasure'),
								name: 'UnitOfMeasure'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.brand'),
								name: 'Brand'
							},
							{
								title: edi.i18n.getMessage('line.item.сampaign'),
								type: 'combo',
								store: edi.stores.initYesOrNoStore(),
								name: 'Campaign'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						customFieldTab: 'PRICES',
						items: [
							{
								title: edi.i18n.getMessage('common.pricat.price.segment'),
								name: 'PriceRegionText'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.net.price'),
								name: 'UnitNetPrice'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'UnitGrossPrice'
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.initUsnTaxRatesWithFractStore()
							},
							{
								title: edi.i18n.getMessage('line.item.discount'),
								name: 'Discount'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.data.packing'),
						customFieldTab: 'PACKAGING',
						items: [
							{
								title: edi.i18n.getMessage('line.item.min.ordered.quantity'),
								name: 'PackDetails.MinOrderedQuantity'
							},
							{
								title: edi.i18n.getMessage('pricat.product.weight'),
								name: 'PackDetails.Weight'
							},
							{
								title: edi.i18n.getMessage('pricat.product.length'),
								name: 'PackDetails.Length'
							},
							{
								title: edi.i18n.getMessage('pricat.product.width'),
								name: 'PackDetails.Width'
							},
							{
								title: edi.i18n.getMessage('pricat.product.height'),
								name: 'PackDetails.Height'
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitPacksize'),
								name: 'PackDetails.UnitPacksize'
							}
						]
					}
				]
			}
		}));
	};

	/**
	 * Creates receiving advice form
	 * @returns {Object}
	 */
	const createDetailsPanel = function (document) {
		const attrs = docHeader.attributes;
		const utoch_reason = edi.utils.getAttributeByName(attrs, 'UTOCH_REASON');
		let headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing),
			rejectReasonText: utoch_reason,
			rejectReasonTitle: utoch_reason ? 'document.reject.reason' : undefined
		});
		let header = edi.utils.getObjectProperty(document ? document : compricat, 'PriceCatalogue-Header');
		let parties = edi.utils.getObjectProperty(document, 'PriceCatalogue-Parties');
		//parties.Buyer.Type = moduleData.initData.data.fromOrg.orgType === "INDIVIDUAL" ? 1 : 0;
		//parties.Seller.Type = moduleData.initData.data.toOrg.orgType === "INDIVIDUAL" ? 1 : 0;

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				headPanel,
				createMainDataBlock(header),
				createPartiesBlock(parties),
				createCatalogPeriodBlock(document),
				createAdditionalInformationBlock(document, header),
				createAgreementBlock(document),
				createPriceSegmentBlock(document),
				createRemarkBlock(document),
				createCustomFieldsContainer(),
				createPriceRegionsGrid(parties),
				createProductGrid(document)
			]
		});
	};

	/**
	 * Creates action pane
	 */
	const createModuleActionsPanel = function (document) {
		let data = moduleData.initData.data;
		document.id = data.id;
		let direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		let needSignatures = edi.document.actions.getSignCount(data);
		let actionsPanel = createActionsPanel();
		let signContentUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.SIGN_TRANSFORM_CONTENT.PUT, {
			documentId: document.id
		});

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: needSignatures,
			actionProps: {
				//так захотел бизнес, что бы копирование было перед печатью (и из-за этого вылезло в основные действия)
				COPY: data.state === edi.constants.STATE.DRAFT ? { order: 205, showInFirstHalf: true } : undefined,
				SIGN: {
					methodAddOptions: {
						signProperties: {
							signContentUrl: signContentUrl,
							signContentUrlMethod: 'PUT'
						},
						contentId: document.id,
						useBeforeAction:
							data.state === edi.constants.STATE.DRAFT ||
							data.state === edi.constants.STATE.RECEIVER_APPROVAL
					}
				},
				EDIT: {
					moduleName: 'document.create.commonpricat'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				docHeader = headerData.data;
				productValues = {
					products: []
				};
			},
			renderData
		);
	};

	let showIzvPolSignModal = function (izvPol) {
		return edi.core.showWarn('dsf.need.sign.izv.pol', function () {
			edi.utils.sign(
				docHeader,
				moduleData.tab,
				function (failed, responseData, certificate) {
					if (failed) {
						edi.core.showError(edi.utils.formatComplexServerError(responseData));
					} else {
						edi.events.documents.fireEvent('change', { id: docHeader.id });
					}
				},
				undefined,
				undefined,
				undefined,
				undefined,
				{
					signContentUrl: edi.utils.formatString(
						'AB' === edi.login.getAuthType()
							? edi.rest.services.DOCUMENTS.SIGN_AB.GET
							: edi.rest.services.DOCUMENTS.SIGN.GET,
						{ documentId: izvPol.id }
					),
					signContentUrlMethod: 'PUT'
				}
			);
		});
	};

	let getDocContent = function (callback) {
		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		let success = function (resp) {
			if (resp && resp.data) {
				callback(resp.data);
			} else {
				failure(resp);
			}
		};
		let url = edi.document.actions.formatDetailsUri(moduleData.initData);
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	let getIzvPol = function (callback) {
		let dir = edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg);
		if (
			docHeader.state === edi.constants.STATE.COMMON_PRICAT_DP_IZVPOL_RECEIVER &&
			dir === edi.constants.DIRECTIONS.INCOMING
		) {
			let fail = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			});
			let success = function (resp) {
				let docs = Array.isArray(resp.items) ? resp.items : [];
				let izvPol = docs.find((d) => {
					return (
						d.type === edi.constants.DOCUMENT_TYPES.COMMON_PRICAT_DP_IZVPOL &&
						d.state === edi.constants.STATE.COMMON_PRICAT_DP_IZVPOL_RECEIVER
					);
				});
				callback(izvPol);
			};
			let url = edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
				depth: edi.constants.DEFAULT.TREE_DEPTH
			});
			edi.rest.sendRequest(url, 'GET', {}, success, fail);
		} else {
			callback(null);
		}
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();

		edi.document.actions.updateDocumentHeaderData(moduleData, function () {
			getDocContent(function (docContent) {
				getIzvPol(function (izvPol) {
					moduleData.tab.removeAll();

					let modulePanel = createDetailsModulePanel();
					modulePanel.add(createDetailsPanel(docContent));
					moduleData.tab.add(createModuleActionsPanel(docContent));
					moduleData.tab.add(modulePanel);
					if (docHeader?.toOrg?.id && docHeader?.fromOrg?.id) {
						edi.methods.custom_fields.initCustomFields({
							docType: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
							toOrgId: docHeader.toOrg.id,
							fromOrgId: docHeader.fromOrg.id,
							docId: docContent.id,
							container: customFieldsContainer,
							grid: productsGrid,
							topPath: topPath,
							readOnly: true
						});
					}
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}

					if (izvPol) {
						izvPolSignModal = showIzvPolSignModal(izvPol);
					} else if (typeof izvPolSignModal?.hide === 'function') {
						izvPolSignModal.hide();
					}
				});
			});
		});
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
