import {
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createMaxWidthContainer,
	createMaxWidthContainerForDetail
	// @ts-ignore
} from '@UIkit/components/panels';
// @ts-ignore
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { createPersonHandingGoodsBlockForDetail } from '../personHandingGoodsBlock/createPersonHandingGoodsBlockForDetail';
import { createNamedDocumentBlockForDetail } from '../documentBlock/createDocumentBlockForDetail';

export interface TransportAndCargoProps {
	documentData: AnyObject;
	initialData: AnyObject;
	[key: string]: any;
}

export class TransportAndCargo {
	props: TransportAndCargoProps;

	path: string = 'dokument.svProdPer.svPer';

	constructor(props: TransportAndCargoProps, overrides?: Partial<TransportAndCargo>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: TransportAndCargoProps, overrides?: Partial<TransportAndCargo>) {
		const me = this;

		Ext.merge(me, overrides);
		me.props = props;
	}

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		return [me.path, ...name].filter(Boolean).join('.');
	}

	createShipmentGoodsReasonDocumentBlock(): ExtComponent | null {
		const me = this;
		const name = me.createFieldName('osnPer');
		return createNamedDocumentBlockForDetail(
			{
				data: edi.utils.getObjectProperty(me.props.documentData, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods')
			},
			{ isReadOnly: true },
			{ readOnly: true }
		);
	}

	createTransportationView(): ExtComponent {
		const me = this;

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.transferInfo.transportation'),
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						{
							title: edi.i18n.getMessage('documents.fns_upd_5_02n.transferInfo.transportation.inkoterms'),
							text: edi.utils.getObjectProperty(
								me.props.documentData,
								me.createFieldName('tran.inkoterms')
							)
						},
						{
							title: edi.i18n.getMessage(
								'documents.fns_upd_5_02n.transferInfo.transportation.verInkoterms'
							),
							text: edi.utils.getObjectProperty(
								me.props.documentData,
								me.createFieldName('tran.verInkoterms')
							)
						},
						{
							title: edi.i18n.getMessage('documents.fns_upd_5_02n.transferInfo.transportation.svTran'),
							text: edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('tran.svTran')),
							isBlock: true
						}
					]
				})
			]
		});
	}

	createTransferConfirmationDocument(): ExtComponent | null {
		const me = this;
		const name = me.createFieldName('svPerVeschi.dokPerVesch');
		return createNamedDocumentBlockForDetail(
			{
				data: edi.utils.getObjectProperty(me.props.documentData, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.transfer.confirmation.document')
			},
			{
				isSingleDocument: true,
				isReadOnly: true
			},
			{ readOnly: true }
		);
	}

	createContractItemTransferInfoView(): ExtComponent {
		const me = this;
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.contract.item.transfer'),
			layout: {
				type: 'grid',
				gap: [24]
			},
			items: [
				createLabelBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						{
							title: edi.i18n.getMessage('date'),
							text: edi.utils.getObjectProperty(
								me.props.documentData,
								me.createFieldName('svPerVeschi.dataPerVesch')
							)
						},
						{
							title: edi.i18n.getMessage('documents.fns_upd.transferInfo.info'),
							text: edi.utils.getObjectProperty(
								me.props.documentData,
								me.createFieldName('svPerVeschi.svPerVesch')
							)
						}
					]
				}),
				me.createTransferConfirmationDocument()
			]
		});
	}

	createContentActionField(): ExtComponent {
		const me = this;
		const contentActionItems: {
			[key: string]: string;
		} = {
			itemsTransferred: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.itemsTransferred'),
			workPassed: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed'),
			servicesRendered: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered')
		};
		let contentActionValue = '';
		const name = me.createFieldName('sodOper');

		for (let key in contentActionItems) {
			if (contentActionItems[key] === edi.utils.getObjectProperty(me.props.documentData, name)) {
				contentActionValue = edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.' + key);
			} else {
				contentActionValue = edi.utils.getObjectProperty(me.props.documentData, name);
			}
		}

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction'),
			items: createLabelBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				contents: [
					{
						text: contentActionValue
					}
				]
			})
		});
	}

	createTypeActionField(): ExtComponent {
		const me = this;

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.typeAction'),
			items: createLabelBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				contents: [
					{
						text: edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('vidOper'))
					}
				]
			})
		});
	}

	createShippingDateField(): ExtComponent {
		const me = this;

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.shippingDate'),
			items: createLabelBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				contents: [
					{
						text: edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('dataPer'))
					}
				]
			})
		});
	}

	createTransportAndCargoFieldSet(): ExtComponent {
		const me = this;
		const dataNachPer = edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('dataNachPer'));
		const dataOkonPer = edi.utils.getObjectProperty(me.props.documentData, me.createFieldName('dataOkonPer'));
		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'),
			collapsible: true,
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [
				me.createShipmentGoodsReasonDocumentBlock(),
				createPersonHandingGoodsBlockForDetail({
					data: me.props.documentData
				}),
				createMaxWidthContainer({
					items: me.createTransportationView()
				}),
				me.createContractItemTransferInfoView(),
				createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [
						me.createContentActionField(),
						me.createTypeActionField(),
						me.createShippingDateField(),
						createFieldBlockForDetails({
							title: edi.i18n.getMessage('documents.fns_upd.transferInfo.starting'),
							items: createLabelForDetails({
								text: [dataNachPer, dataOkonPer].filter(Boolean).join('–')
							})
						})
					]
				})
			]
		}) as ExtComponent;
	}
}

export const createTransportAndCargoForDetail = (
	props: TransportAndCargoProps,
	overrides?: Partial<TransportAndCargo>
) => {
	const transportAndCargo = new TransportAndCargo(props, overrides);
	return transportAndCargo.createTransportAndCargoFieldSet() as ExtComponent;
};
