const actionRulesForx5AgreementContainer = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER],
			STATE: [
				edi.constants.STATE.READ,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT
			]
		},
		permissionChanging: [],
		conditions: []
	},
	REJECT: {
		INCOMING: {
			STATE: [
				edi.constants.STATE.READ,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
				edi.constants.STATE.SIGNED_BY_CONSUMER
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER],
				directions: [edi.constants.DIRECTIONS.INCOMING],
				allow(docData) {
					let state = docData.state;
					return (
						state === edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT ||
						state === edi.constants.STATE.SIGNED_BY_CONSUMER ||
						state === edi.constants.STATE.READ
					);
				}
			}
		]
	},
	ROUTE_REJECT: {},
	ROUTE_CONFIRM: {},
	CLARIFY: {},
	DELETE: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [],
		conditions: []
	},
	EDIT: {},
	COMPLETE: {},
	READ: {},
	READ_AND_COMPLETE: {},
	SEND: {},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {},
	CONVERT_TO_DRAFT: {},
	COPY: {},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER]: actionRulesForx5AgreementContainer
});

export { actionRulesForx5AgreementContainer };
