const powerOfAttorneyAPI = {
	FROM_DOCUMENT: {
		CONTENT: {
			GET: edi.rest.prfx.c + 'poa/{docId}/{poaId}/content'
		},
		HEADER: {
			GET: edi.rest.prfx.c + 'poa/{docId}/{poaId}'
		},
		SIGNERS: {
			GET: edi.rest.prfx.c + 'poa/{docId}/{poaId}/infoSigners'
		},
		PDF: {
			GET: edi.rest.prfx.c + 'reports/statuses/{docId}/{poaId}/PDF'
		}
	},
	CONTENT: {
		GET: edi.rest.prfx.c + 'poa/data'
	},
	CHAIN: {
		POST: edi.rest.prfx.c + 'poa/chain',
		GET: edi.rest.prfx.c + 'poa/chain/{id}'
	}
};

export { powerOfAttorneyAPI };
