Ext.namespace('edi.models');

edi.models.setModels({
	NOTIFICATIONS: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'objectId',
				type: 'number'
			},
			{
				name: 'objectType',
				type: 'string'
			},
			{
				name: 'info',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'docNumber',
				type: 'string'
			},
			{
				name: 'read',
				type: 'boolean'
			},
			{
				name: 'action'
				// type: 'object'
			},
			{
				name: 'attributes'
				// type: 'object'
			},
			{
				name: 'org'
				// type: 'object'
			},
			{
				name: 'fromOrg'
				// type: 'object'
			},
			{
				name: 'toOrg'
				// type: 'object'
			}
		]
	},
	PROJECT_LIST: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'label',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			}
		]
	},
	CONTAINER: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'creationDate',
				type: 'number'
			}
		]
	},
	ADS: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'contentType',
				type: 'string'
			}
		]
	},
	EW_INVITATIONS_ROLE: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'product',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'disabled',
				type: 'boolean'
			}
		]
	},
	EW_INVITATIONS_ORGS: {
		fields: [
			{
				name: 'id',
				type: 'string',
				convert: function (value, record) {
					return [record.get('inn'), record.get('kpp')].join('_');
				}
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'nameWithKpp',
				type: 'string',
				convert: function (value, record) {
					return record.get('name')
						? record.get('name') +
								(record.get('kpp')
									? ` (${edi.i18n.getMessage('company.kpp.short')}: ${record.get('kpp')})`
									: '')
						: value || '';
				}
			},
			{
				name: 'nameId',
				type: 'string',
				convert: function (value, record) {
					return record.get('name') ? `${record.get('name')}|${record.get('kpp')}` : value || '';
				}
			},
			{
				name: 'inn',
				type: 'number'
			},
			{
				name: 'iln',
				type: 'number'
			},
			{
				name: 'kpp',
				type: 'string'
			}
		]
	},
	EW_INVITATIONS: {
		fields: [
			{
				name: 'id',
				mapping: 'invitation.header',
				type: 'number'
			},
			{
				name: 'ediDocument'
				// type: 'object'
			},
			{
				name: 'invitation'
				// type: 'object'
			},
			{
				name: 'product',
				mapping: 'invitation.roleModel.productRole',
				type: 'string'
			},
			{
				name: 'creationDate',
				mapping: 'ediDocument.creationDate',
				type: 'string'
			},
			{
				name: 'doctime',
				mapping: 'ediDocument.doctime',
				type: 'number'
			},
			{
				name: 'modifyDate',
				mapping: 'ediDocument.modifyDate',
				type: 'string'
			},
			{
				name: 'docType',
				mapping: 'ediDocument.type',
				type: 'string'
			},
			{
				name: 'receiverName',
				mapping: 'invitation.parties.receiver.name'
			},
			{
				name: 'receiverKpp',
				mapping: 'invitation.parties.receiver.kpp'
			},
			{
				name: 'receiverInn',
				mapping: 'invitation.parties.receiver.inn'
			},
			{
				name: 'state',
				mapping: 'ediDocument.state',
				type: 'string'
			}
		]
	},
	EW_INVITATIONS_INCOMING: {
		fields: [
			{
				name: 'id',
				mapping: 'invitation.header',
				type: 'number'
			},
			{
				name: 'rejectReason',
				mapping: 'invitation.rejectReason',
				type: 'string'
			},
			{
				name: 'ediDocument'
				// type: 'object'
			},
			{
				name: 'doctime',
				mapping: 'ediDocument.doctime',
				type: 'number'
			},
			{
				name: 'invitation'
				// type: 'object'
			},
			{
				name: 'product',
				mapping: 'invitation.roleModel.productRole',
				type: 'string'
			},
			{
				name: 'creationDate',
				mapping: 'ediDocument.creationDate',
				type: 'string'
			},
			{
				name: 'modifyDate',
				mapping: 'ediDocument.modifyDate',
				type: 'string'
			},
			{
				name: 'docType',
				mapping: 'ediDocument.type',
				type: 'string'
			},
			{
				name: 'senderName',
				mapping: 'invitation.parties.sender.name'
			},
			{
				name: 'senderKpp',
				mapping: 'invitation.parties.sender.kpp'
			},
			{
				name: 'senderInn',
				mapping: 'invitation.parties.sender.inn'
			},
			{
				name: 'state',
				mapping: 'ediDocument.state',
				type: 'string'
			}
		]
	},
	EW_INVITATIONS_MASS: {
		fields: [
			{
				name: 'massInvitation'
				// type: 'object'
			},
			{
				name: 'id',
				mapping: 'massInvitation.header',
				type: 'number'
			},
			{
				name: 'invitationName',
				mapping: 'massInvitation.nameOfInvitationList',
				type: 'string'
			},
			{
				name: 'invitationCount',
				mapping: 'massInvitation'
				// type: 'object'
			},
			{
				name: 'ediDocument'
				// type: 'object'
			},
			{
				name: 'creationDate',
				mapping: 'ediDocument.creationDate',
				type: 'string'
			},
			{
				name: 'modifyDate',
				mapping: 'ediDocument.modifyDate',
				type: 'string'
			},
			{
				name: 'state',
				mapping: 'ediDocument.state',
				type: 'string'
			},
			{
				name: 'product',
				mapping: 'massInvitation.roleModel.productRole',
				type: 'string'
			}
		]
	},
	MASS_INVITATIONS_LIST: {
		fields: [
			{
				name: 'id',
				mapping: 'header',
				type: 'string'
			},
			{
				name: 'organizationName',
				mapping: 'parties.receiver.name',
				type: 'string'
			},
			{
				name: 'invitationInn',
				mapping: 'parties.receiver.inn',
				type: 'string'
			},
			{
				name: 'invitationKpp',
				mapping: 'parties.receiver.kpp',
				type: 'string'
			},
			{
				name: 'invitationEmail',
				mapping: 'parties.receiver.email',
				type: 'string'
			},
			{
				name: 'workOnEdiweb',
				type: 'boolean'
			},
			{
				name: 'comments'
				// type: 'object'
			},
			{
				name: 'invitationComment',
				mapping: 'comments.invitationText',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'errors'
				// type: 'object'
			}
		]
	},
	INVITATIONS_BULK: {
		fields: [
			{
				name: 'org'
				// type: 'object'
			},
			{
				name: 'kpp',
				type: 'string'
			},
			{
				name: 'inn',
				type: 'string'
			},
			{
				name: 'workIn',
				type: 'boolean'
			},
			{
				name: 'email',
				type: 'string'
			},
			{
				name: 'comments',
				type: 'string'
			}
		]
	},
	ORGANIZATIONS: {
		extend: 'edi.models.ORGANIZATIONS',
		fields: [
			{
				name: 'errorText',
				type: 'string'
			}
		]
	},
	EW_SUBDIVISIONS: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'employees',
				type: 'array'
			},
			{
				name: 'children',
				type: 'array'
			},
			{
				name: 'headdivision'
				// type: 'object'
			},
			{
				name: 'employees'
				// type: 'object'
			},
			{
				name: 'count',
				type: 'number'
			},
			{
				name: 'level',
				type: 'number'
			}
		]
	},
	EW_EMPLOYEES: {
		fields: [
			{
				name: 'user'
			},
			{
				name: 'position',
				type: 'string'
			},
			{
				name: 'firstName',
				type: 'string'
			},
			{
				name: 'middleName',
				type: 'string'
			},
			{
				name: 'lastName',
				type: 'string'
			},
			{
				name: 'subdivisionName',
				type: 'string'
			},
			{
				name: 'status',
				type: 'string'
			},
			{
				name: 'subdivision'
				// type: 'object'
			},
			{
				name: 'userAdmin',
				type: 'boolean'
			},
			{
				name: 'email',
				type: 'string'
			},
			{
				name: 'actions',
				type: 'array'
			},
			{
				name: 'productInfos',
				type: 'array',
				convert: function (value, record) {
					if (!!value && Array.isArray(value)) {
						let EWIndex = value.map((e) => e.productType).indexOf('EDIWEB');
						var element = value[EWIndex];
						value.splice(EWIndex, 1);
						value.splice(0, 0, element);
					}

					return value;
				}
			},
			{
				name: 'displayname',
				convert: function (value, record) {
					let user = edi.renderers.getUserInfo(record);
					return `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`;
				}
			}
		]
	},
	EW_ROUTES: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'stagesCount',
				type: 'string'
			},
			{
				name: 'orderOfAgreement',
				type: 'string'
			},
			{
				name: 'routeType',
				type: 'string'
			},
			{
				name: 'partner'
				// type: 'object'
			},
			{
				name: 'employee'
				// type: 'object'
			},
			{
				name: 'responsibleEmployee'
				// type: 'object'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'docState',
				type: 'string'
			},
			{
				name: 'information',
				type: 'array'
			},
			{
				name: 'stages',
				type: 'array'
			},
			{
				name: 'parties',
				type: 'array'
			}
		]
	},
	EW_APPROVAL_DOCUMENTS: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'currentStageNumber',
				type: 'number'
			},
			{
				name: 'currentPartyNumber',
				type: 'number'
			},
			{
				name: 'sender'
				// type: 'object'
			},
			{
				name: 'attributes'
				// type: 'object'
			},
			{
				name: 'executors',
				type: 'array'
			},
			{
				name: 'signers',
				type: 'array'
			},
			{
				name: 'document'
				// type: 'object'
			},
			{
				name: 'route'
				// type: 'object'
			},
			{
				name: 'data'
				// type: 'object'
			},
			{
				name: 'title',
				type: 'string'
			},
			{
				name: 'header'
				// type: 'object'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'routeName',
				mapping: 'route.name',
				type: 'string'
			},
			{
				name: 'stagesCount',
				mapping: 'route.stagesCount',
				type: 'string'
			},
			{
				name: 'routeId',
				mapping: 'route.id',
				type: 'number'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'docNumber',
				mapping: 'data.docNumber'
				// type: 'object'
			},
			{
				name: 'stages',
				mapping: 'route.stages',
				type: 'array'
			},
			{
				name: 'parties',
				mapping: 'route.stages.parties',
				type: 'array'
			},
			{
				name: 'currentStageNumber',
				type: 'number'
			},
			{
				name: 'comment',
				type: 'string'
			}
		]
	},
	EW_APPROVAL_DOCUMENTS_ARCHIVE: {
		extend: 'edi.models.EW_APPROVAL_DOCUMENTS',
		fields: [
			{
				name: 'sender',
				type: 'string'
			},
			{
				name: 'docNumber',
				type: 'string'
			},
			{
				name: 'routeName',
				type: 'string'
			},
			{
				name: 'headerId',
				type: 'number'
			}
		]
	},
	TARIFF_EDI_GRID: {
		fields: [
			{
				name: 'documentsCost',
				type: 'number'
			},
			{
				name: 'documentsNumber'
			}
		]
	}
});
