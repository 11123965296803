import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import {
	convertDesadvProductsToDesadvCrossProducts,
	flattenLineValues,
	isAdditionalProcessingNeeded,
	separateCustomAndNormalFields,
	templateCreateDesadv,
	templateCreateDesadvParties
} from './methods';
import { AMOUNT_FIELD_NAME, createProductsTree, getFormFields, PRODUCT_AMOUNT_DECIMAL_PRECISION } from './createTree';
import { getProductFormValues } from './productModal';
import {
	createLabel,
	createCombo,
	createDateField,
	createTextField,
	createCheckbox,
	createTimeField,
	createNumberField
	// @ts-ignore
} from '@UIkit/components/fields';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { Packing, Product, Tree } from './definitions';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';
import { PRODUCT_REFERENCE_COLUMNS_CONFIG_NAME, PRODUCT_REFERENCE_ELEMENT_COLUMNS_CONFIG_NAME } from './columns';
import { PRODUCT_REFERENCE_ELEMENT_MODEL, PRODUCT_REFERENCE_MODEL } from '@Edi/modules/documents/LEGACY_DES_ADV/models';
import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createFieldSet,
	createMaxWidthContainer,
	createRangeContainer
	// @ts-ignore
} from '@UIkit/components/panels';
import { desadvSelectors } from '@Edi/modules/documents/LEGACY_DES_ADV/selectors';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

/**
 * Class for new desadv creation
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.create.desadv'] = function () {
	let moduleData: ModuleData<DocumentHeader>,
		id: number,
		isEdit: boolean,
		isCopy = false,
		isBasedOnDoc: boolean,
		buyerId: number,
		buyerOrg: AnyObject | null,
		buyerOrgValues: AnyObject | null,
		deliveryId,
		isNumberAutoGenerated = false,
		taxRatesStore: ExtComponent,
		availableTaxRates = [] as AnyObject[],
		documentData: AnyObject,
		costDecimals: number,
		desadv: AnyObject,
		header: AnyObject,
		buyer: ExtComponent,
		seller: ExtComponent,
		delivery: ExtComponent,
		shipFrom: ExtComponent,
		ultimateCustomer: ExtComponent,
		carrier: ExtComponent,
		form: ExtComponent,
		initialData: AnyObject,
		parentId: number,
		productsTreeGrid: Tree,
		userData = edi.core.getUserData(),
		unconvertedProducts = [] as AnyObject[],
		productConvertationResult = true,
		taxCalculationMethod: string = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE,
		documentPartFieldContainers = {} as AnyObject,
		documentPartFields = {} as AnyObject,
		documentPartFieldValues = {} as AnyObject,
		customFieldsContainer: ExtComponent,
		topPath = '//Document-DespatchAdvice/';
	let documentLinePartFields: AnyObject = {
		ReasonCode: {
			title: edi.i18n.getMessage('org.document.LEGACY_DES_ADV.part.docLines.ReasonCode'),
			isHidden: true,
			name: 'ReasonCode'
		}
	};
	let customFieldsObj: AnyObject = {},
		previousCustomFieldsObj: AnyObject = {},
		customFieldsFromTransformation: AnyObject;
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 */
	this.init = function (data: ModuleData<DocumentHeader>, initCallBack: ModuleInitCallback) {
		moduleData = data;
		customFieldsFromTransformation = moduleData.initData?.data?.data?.customFields;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;

		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			isBasedOnDoc = true;
			desadv = moduleData.initData.data.data;
			initialData = desadv;
			delete initialData.fileId;
			buyerOrg = moduleData.initData.data.buyerOrg;
		}

		if (buyerOrg && buyerOrg.id) {
			if (!buyerOrg.hasOwnProperty('attributes')) {
				buyerOrg = edi.utils.getOrg({
					orgId: buyerOrg.id
				});
			}
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg?.attributes);
			buyerOrgValues = edi.converters.convertOrgToLegacyPartie(buyerOrg);
			buyerId = buyerOrg?.id;
		}

		costDecimals = coreMethods.getUserOrgCostDecimals();

		renderData(initCallBack);

		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Calculates price and tax summary according to tax recalculation method
	 * @param    {Object}     values              product data
	 * @param    {Object}     [calcFieldsData]      object with additional data that could override/extend values returned by gatherValuesRow
	 * @param    {Boolean}    [isTransformation]    true means do not recalculate fields passed from another document(according to tax recalculation method logic)
	 * @param    {Boolean}    [roundResults]        true to round calculated values
	 * @returns {*}
	 */
	const calculateValues = function (
		values: Product,
		calcFieldsData?: AnyObject,
		isTransformation = false,
		roundResults = false
	) {
		let data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV);
		if (calcFieldsData && 'object' == typeof calcFieldsData) {
			Ext.merge(data, calcFieldsData);
		}
		let calcData = edi.methods.product.price.recalculation(
			{
				taxRate: data.rateVal,
				amount: data.amount,
				netPrice: data.price,
				grossPrice: data.grossPrice,
				netSum: values.ProductNetAmount || values.NetAmount,
				taxSum: values.ProductTaxAmount || values.TaxAmount,
				grossSum: values.ProductGrossAmount || values.GrossAmount
			},
			taxCalculationMethod,
			isTransformation,
			+data.amount === 0,
			costDecimals
		);
		values.GrossAmount = calcData.grossSum;
		values.TaxAmount = calcData.taxSum;
		values.NetAmount = calcData.netSum;
		values.UnitGrossPrice = calcData.grossPrice;
		values.UnitNetPrice = calcData.netPrice;
		if (roundResults) {
			values = roundProductValues(values);
		}
		return values;
	};

	/**
	 * Rounds values
	 * @returns {*}
	 */
	const roundProductValues = function (values: Product, precision?: number) {
		precision = precision ? precision : costDecimals;
		values.NetAmount = Ext.isNumeric(values.NetAmount) ? edi.utils.roundTo(values.NetAmount, precision) : '';
		values.GrossAmount = Ext.isNumeric(values.GrossAmount) ? edi.utils.roundTo(values.GrossAmount, precision) : '';
		values.TaxAmount = Ext.isNumeric(values.TaxAmount) ? edi.utils.roundTo(values.TaxAmount, precision) : '';
		values.UnitGrossPrice = Ext.isNumeric(values.UnitGrossPrice)
			? edi.utils.roundTo(values.UnitGrossPrice, precision)
			: '';
		values.UnitNetPrice = Ext.isNumeric(values.UnitNetPrice)
			? edi.utils.roundTo(values.UnitNetPrice, precision)
			: '';
		return values;
	};

	/**
	 * Process values before setting them to the grid
	 */
	const postProcessValues = function (values: Product, taxCalculationMethod: string) {
		let price;
		if (isAdditionalProcessingNeeded(values, taxCalculationMethod)) {
			if (Ext.isNumeric(values.ProductGrossAmount)) {
				price = +values.ProductGrossAmount / +values.ProductQuantityDespatched;
				values.GrossAmount = String(price * +values[AMOUNT_FIELD_NAME]);
			}
			if (Ext.isNumeric(values.ProductNetAmount)) {
				price = +values.ProductNetAmount / +values.ProductQuantityDespatched;
				values.NetAmount = String(price * +values[AMOUNT_FIELD_NAME]);
			}
			if (Ext.isNumeric(values.ProductTaxAmount)) {
				price = +values.ProductTaxAmount / +values.ProductQuantityDespatched;
				values.TaxAmount = String(price * +values[AMOUNT_FIELD_NAME]);
			}
		}
		return roundProductValues(values);
	};

	const createMainDataBlock = function (
		documentData?: AnyObject,
		docData?: AnyObject,
		header?: AnyObject
	): ExtComponent {
		let numberDocument: ExtComponent = createTextField({
			fieldLabel: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('field.name.number'),
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			maxLength: 35,
			value: documentData
				? edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Header.DespatchAdviceNumber') +
				  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
				: undefined,
			name: 'DespatchAdvice-Header.DespatchAdviceNumber'
		});

		const adviceDate: ExtComponent = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			value: docData && !isCopy ? edi.utils.getObjectProperty(header, 'DespatchAdviceDate') : new Date(),
			name: 'DespatchAdvice-Header.DespatchAdviceDate'
		});

		const autogenCheckbox: ExtComponent | undefined = isNumberAutoGenerated
			? createCheckbox({
					boxLabel: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
					inputValue: true,
					checked: isNumberAutoGenerated,
					name: 'numberAutoGenerated',
					listeners: {
						change: function (checkbox: ExtComponent, isChecked: boolean) {
							numberDocument.setEmptyText(
								isChecked
									? edi.i18n.getMessage('document.generation.numbers.text')
									: edi.i18n.getMessage('nr')
							);
							numberDocument.setValue('');
							numberDocument.setDisabled(isChecked);
							numberDocument.allowBlank = isChecked;
							numberDocument.validate();
						}
					}
			  })
			: undefined;

		const invoiceNumberValue = edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Header.InvoiceNumber');
		const invoiceNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('invoice.number'),
			valueSrc: documentData,
			name: 'DespatchAdvice-Header.InvoiceNumber',
			readOnly: invoiceNumberValue && isBasedOnDoc,
			maxLength: 35
		});

		const documentFunctionCodeValue = edi.utils.getObjectProperty(
			docData,
			'DespatchAdvice-Header.DocumentFunctionCode'
		);
		const functionalDocumentCode: ExtComponent = createCombo({
			fieldLabel: edi.i18n.getMessage('functional.document.code'),
			name: 'DespatchAdvice-Header.DocumentFunctionCode',
			store: edi.stores.createSimpleInlineStore(['9', '4', '3'], function (id: string) {
				return edi.i18n.getMessage('functional.document.code.' + id);
			}),
			value: docData && documentFunctionCodeValue !== 'O' ? documentFunctionCodeValue : '9',
			anyMatch: true,
			allowBlank: false
		});

		const toOrderLabel: ExtComponent = createLabel({
			typography: 'heading_01',
			text: edi.i18n.getMessage('to.order')
		});

		const orderNumberValue = edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Header.BuyerOrderNumber');
		const orderNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('buyer.order.number'),
			valueSrc: documentData,
			name: 'DespatchAdvice-Header.BuyerOrderNumber',
			allowBlank: false,
			readOnly: orderNumberValue && isBasedOnDoc,
			maxLength: 35
		});

		const orderDateValue = edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Header.BuyerOrderDate');

		const orderDate: ExtComponent = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			valueSrc: documentData,
			name: 'DespatchAdvice-Header.BuyerOrderDate',
			allowBlank: true,
			readOnly: orderDateValue && isBasedOnDoc,
			maxLength: 35
		});

		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				area: [isNumberAutoGenerated ? [4, 2, 2] : [4, 2], [4, 3], 12, [4, 2]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				numberDocument,
				adviceDate,
				autogenCheckbox,
				invoiceNumber,
				functionalDocumentCode,
				toOrderLabel,
				orderNumber,
				orderDate
			]
		}) as ExtComponent;
	};

	const createOS = function (selectorConfig: AnyObject) {
		Ext.applyIf(selectorConfig, {
			useHiddenFields: true,
			fieldsMapOnly: true,
			valuesByMap: true,
			forceChangeFields: {
				company_iln: true
			},
			processValues: orgConverter
		});

		return createOrgSelector(selectorConfig);
	};

	const createPartiesBlock = function (parties?: AnyObject, docData?: AnyObject) {
		const buyerGLN = edi.utils.getObjectProperty(parties, 'Buyer.ILN'),
			buyerHasData = !!buyerGLN,
			isBuyerDefined = !isEdit && buyerOrg;
		const buyerID = moduleData.initData?.data?.toOrg?.id ?? moduleData.initData?.data?.parties?.toOrg?.id;
		buyer = createOS({
			itemId: 'buyer',
			relationsOnly: true,
			allowBlank: false,
			readOnly: isEdit,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: buyerID,
				converter: orgConverter
			}),
			is_valid: buyerHasData,
			relations: isBuyerDefined ? buyerOrg : edi.relations.getRelations(),
			allowReset: !buyerHasData,
			fieldValues: parties ? parties : undefined,
			selectedOrgValues: isBuyerDefined
				? buyerOrgValues
				: edi.utils.getObjectProperty(parties, 'Buyer.ILN')
				? parties
				: undefined,
			selectedOrg:
				buyerOrg ||
				edi.utils.getOrg({
					orgId: moduleData.initData.data?.toOrg?.id
				}),
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			selectedRelationByMap: !isBuyerDefined,
			callback: function (_values: AnyObject, org: AnyObject) {
				const orgId = org ? org.id : null;
				const continueFn = function () {
					form.isValid();

					if (orgId && orgId !== buyerId) {
						availableTaxRates = edi.methods.taxRates.getRatesByCountry(org.country, true);
						taxRatesStore.loadData(availableTaxRates);
					}

					buyerId = orgId;

					productsTreeGrid.setPartnerId(orgId);
					if (delivery.getOrgIdForDelcat() != orgId) {
						delivery.reset();
						delivery.setOrgIdForDelcat(orgId);
						delivery.setPartnerOrg(org);
					}
					if (ultimateCustomer && ultimateCustomer.getOrgIdForDelcat() != orgId) {
						ultimateCustomer.setOrgIdForDelcat(orgId);
					}
					if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
						buyerOrg = org;
						taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
						buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
						edi.document.actions.getOrgDocumentPartFields(
							buyerOrg.id,
							edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
							updateDocPartFields
						);
					} else if (!orgId) {
						buyerOrg = null;
						taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
						buyerOrgValues = null;
						edi.document.actions.getOrgDocumentPartFields(
							null,
							edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
							updateDocPartFields
						);
					}

					removeOldCustomFieldsInTree();
					productsTreeGrid.rebuildTree();

					checkValid();
				};

				let sellerId = seller.getValues().id || seller.selectedOrg?.id;
				if (orgId && sellerId) {
					edi.methods.custom_fields.initCustomFields({
						customFieldsObj,
						initialCustomFieldsData: customFieldsFromTransformation,
						docType: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
						toOrgId: orgId,
						fromOrgId: sellerId,
						container: customFieldsContainer,
						grid: productsTreeGrid,
						isTree: true,
						topPath: topPath,
						insertionCallback(newObj: AnyObject, previousObj: AnyObject) {
							customFieldsObj = newObj;
							previousCustomFieldsObj = previousObj;
							continueFn();
						},
						failCallback: continueFn
					});
				} else {
					continueFn();
				}
			},
			fieldsMap: desadvSelectors.getBuyerFieldsMap(),
			modalConf: desadvSelectors.getBuyerModalConf()
		}) as ExtComponent;

		const sellerGLN = edi.utils.getObjectProperty(parties, 'Seller.ILN');
		const isSellerDefined = !!sellerGLN;
		const sellerOrg = edi.utils.getOrg({ orgILN: sellerGLN }) || userData.org;
		const sellerData = edi.converters.convertOrgToLegacyPartie(sellerOrg);
		const sellerID =
			moduleData.initData?.data?.fromOrg?.id ??
			moduleData.initData?.data?.parties?.fromOrg?.id ??
			userData.org?.id;
		seller = createOS({
			itemId: 'seller',
			selectedRelationByMap: false,
			readOnly: isEdit,
			is_valid: isSellerDefined || (!desadv && !docData),
			valuesByMap: parties,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: sellerID,
				converter: orgConverter
			}),
			fieldValues: parties || sellerData,
			selectedOrgValues: sellerData,
			selectedOrg: sellerOrg,
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			callback: function (_values: AnyObject, org: AnyObject) {
				checkValid();
				const sellerId = org ? org.id : null;
				if (shipFrom.getOrgIdForLoccat() != sellerId) {
					shipFrom.reset();
					shipFrom.setOrgIdForLoccat(sellerId);
				}
			},
			fieldsMap: desadvSelectors.getSellerFieldsMap(),
			modalConf: desadvSelectors.getSellerModalConf()
		}) as ExtComponent;

		const deliveryGLN = edi.utils.getObjectProperty(parties, 'DeliveryPoint.ILN'),
			isDeliveryDefined = !!deliveryGLN;

		delivery = createOS({
			itemId: 'delivery',
			tooltip: 'document.delivery.tooltip',
			selectedRelationByMap: false,
			ownCatalog: false,
			is_valid: isDeliveryDefined,
			partnerOrg: !!(docData || !isDeliveryDefined),
			partnerOrgText: 'form.btn.buyer.org',
			partnerOrgValues: buyerId
				? edi.utils.getOrg({
						orgId: buyerId
				  })
				: undefined,
			allowBlank: false,
			alwaysShowSelect: !isDeliveryDefined,
			allowReset: true,
			fieldValues: isDeliveryDefined ? parties : undefined,
			selectedOrgValues: isDeliveryDefined ? parties : undefined,
			relationsFromDelcatByOrgId: buyerId,
			callback: function (values: AnyObject, org: AnyObject) {
				deliveryId = org ? org.id : null;
				initialData
					? edi.utils.setObjectProperty(initialData, 'DespatchAdvice-Parties.DeliveryPoint', null)
					: null;
				if (ultimateCustomer && org) {
					ultimateCustomer.setPartnerOrg(org);
				} else if (ultimateCustomer && values.company_iln) {
					ultimateCustomer.setPartnerOrg(edi.converters.convertValuesToOrg(values));
				}
				checkValid();
			},
			fieldsMap: desadvSelectors.getDeliveryFieldsMap(),
			modalConf: desadvSelectors.getDeliveryModalConf()
		}) as ExtComponent;

		const partiesShipper = edi.utils.getObjectProperty(parties, 'ShipFrom'),
			isShipFromDefined = !!(partiesShipper && partiesShipper.ILN);

		shipFrom = createOS({
			itemId: 'shipFrom',
			tooltip: 'document.ship.from.tooltip',
			selectedRelationByMap: false,
			is_valid: true,
			allowBlank: true,
			alwaysShowSelect: false,
			allowReset: true,
			fieldValues: isShipFromDefined ? parties : undefined,
			selectedOrgValues: isShipFromDefined ? parties : undefined,
			relationsFromLoccatByOrgId: sellerData?.id || sellerOrg?.id,
			ownOrg: false,
			ownCatalog: false,
			callback: checkValid,
			fieldsMap: desadvSelectors.getShipFromFieldsMap(),
			modalConf: desadvSelectors.getShipFromModalConf()
		}) as ExtComponent;

		const partiesCustomer = edi.utils.getObjectProperty(parties, 'UltimateCustomer'),
			isUltimateCustomerDefined = !!(partiesCustomer && partiesCustomer.ILN);

		ultimateCustomer = createOS({
			itemId: 'ultimateCustomer',
			tooltip: 'documents.ultimate.customer.tooltip',
			selectedRelationByMap: false,
			is_valid: true,
			allowBlank: true,
			alwaysShowSelect: !partiesCustomer,
			allowReset: true,
			fieldValues: isUltimateCustomerDefined ? parties : undefined,
			selectedOrgValues: isUltimateCustomerDefined ? parties : undefined,
			ownCatalog: false,
			relationsFromDelcatByOrgId: buyerId,
			callback: function () {
				initialData
					? edi.utils.setObjectProperty(initialData, 'DespatchAdvice-Parties.UltimateCustomer', null)
					: null;
				checkValid();
			},
			fieldsMap: desadvSelectors.getUltimateCustomerFieldsMap(),
			modalConf: desadvSelectors.getUltimateCustomerModalConf()
		}) as ExtComponent;

		const partiesCarrier = edi.utils.getObjectProperty(parties, 'Carrier'),
			isCarrierDefined = !!(partiesCarrier && partiesCarrier.ILN);

		carrier = createOS({
			itemId: 'carrier',
			tooltip: 'document.carrier.tooltip',
			selectedRelationByMap: false,
			is_valid: true,
			allowBlank: true,
			alwaysShowSelect: false,
			allowReset: true,
			fieldValues: isCarrierDefined ? parties : undefined,
			selectedOrgValues: isCarrierDefined ? parties : undefined,
			ownOrg: true,
			callback: checkValid,
			fieldsMap: desadvSelectors.getCarrierFieldsMap(),
			modalConf: desadvSelectors.getCarrierModalConf()
		}) as ExtComponent;

		return createFieldSet({
			title: edi.i18n.getMessage('document.des.adv.section.parties'),
			collapsible: true,
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: 24,
						area: [
							[6, 6],
							[6, 6],
							[6, 6]
						]
					},
					items: [
						createFieldBlock({
							title: edi.i18n.getMessage('document.seller'),
							items: [seller]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('document.buyer'),
							items: [buyer]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('document.ship.from'),
							items: [shipFrom]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('document.delivery'),
							items: [delivery]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('documents.ultimate.customer'),
							items: [ultimateCustomer]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('document.carrier'),
							items: [carrier]
						})
					]
				})
			]
		});
	};

	const createTransportationInformationBlock = function () {
		const despatchDate = createDateField({
			fieldLabel: edi.i18n.getMessage('despatch.date'),
			valueSrc: documentData,
			name: 'DespatchAdvice-Header.DespatchDate'
		}) as ExtComponent;

		const despatchTime = createTimeField({
			fieldLabel: edi.i18n.getMessage('time'),
			name: 'DespatchAdvice-Header.DespatchTime',
			valueSrc: documentData
		}) as ExtComponent;

		const sendingCargo = createFieldBlock({
			title: edi.i18n.getMessage('sending.cargo'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[2, 2]]
			},
			items: [despatchDate, despatchTime]
		}) as ExtComponent;

		const estimatedDeliveryDate: ExtComponent = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			valueSrc: documentData,
			name: 'DespatchAdvice-Header.EstimatedDeliveryDate'
		});

		const expectedDelivery: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('document.des.adv.expected.delivery'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[2]]
			},
			items: [estimatedDeliveryDate]
		});

		const deliveryPeriodValue = edi.utils.getObjectProperty(
			documentData,
			'DespatchAdvice-Transport.DeliveryPeriod'
		);
		//Период доставки хранится на бэке в виде одной строки "hh:mm-hh:mm"
		if (deliveryPeriodValue) {
			const deliveryPeriodValuesArr = deliveryPeriodValue.split('-');
			edi.utils.setObjectProperty(
				documentData,
				'DespatchAdvice-Transport.DeliveryPeriodFrom',
				deliveryPeriodValuesArr[0]
			);
			edi.utils.setObjectProperty(
				documentData,
				'DespatchAdvice-Transport.DeliveryPeriodTo',
				deliveryPeriodValuesArr[1]
			);
		}

		let deliveryPeriodFrom: ExtComponent, deliveryPeriodTo: ExtComponent;
		const updateDeliveryPeriodMandatory = function () {
			const atLeastOneValue = deliveryPeriodFrom.getValue() || deliveryPeriodTo.getValue();
			deliveryPeriodFrom.allowBlank = deliveryPeriodTo.allowBlank = !atLeastOneValue;
			deliveryPeriodFrom.isValid();
			deliveryPeriodTo.isValid();
		};

		const deliveryPeriod = createFieldBlock({
			title: edi.i18n.getMessage('period.of.delivery'),
			layout: {
				type: 'grid',
				area: [4]
			},
			items: [
				createRangeContainer({
					fieldFrom: (deliveryPeriodFrom = createTimeField({
						name: 'DespatchAdvice-Transport.DeliveryPeriodFrom',
						value: documentData
							? edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Transport.DeliveryPeriodFrom')
							: undefined,
						increment: 30,
						minWidth: 50,
						flex: 2,
						listeners: {
							change: updateDeliveryPeriodMandatory
						}
					}) as ExtComponent),
					fieldTo: (deliveryPeriodTo = createTimeField({
						name: 'DespatchAdvice-Transport.DeliveryPeriodTo',
						value: documentData
							? edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Transport.DeliveryPeriodTo')
							: undefined,
						increment: 30,
						minWidth: 50,
						flex: 2,
						listeners: {
							change: updateDeliveryPeriodMandatory
						}
					}) as ExtComponent)
				})
			]
		});

		//transport
		const modeOfTransport: ExtComponent = createCombo({
			fieldLabel: edi.i18n.getMessage('document.des.adv.TranspType'),
			name: 'DespatchAdvice-Transport.ModeOfTransport',
			value: edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Transport.ModeOfTransport'),
			store: edi.stores.initModeOfTransportStore()
		});

		const vehicleHeight: ExtComponent = createNumberField({
			fieldLabel: edi.i18n.getMessage('line.item.tab.package.VehicleHeight'),
			name: 'DespatchAdvice-Transport.VehicleHeight',
			value: edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Transport.VehicleHeight'),
			decimalPrecision: 3,
			allowDecimals: true,
			vtype: 'numberN12_3'
		});

		const vehicleType: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('vehicle.type'),
			name: 'DespatchAdvice-Transport.VehicleType',
			valueSrc: documentData,
			maxLength: 175
		});

		const transportNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('document.des.adv.transport.number'),
			name: 'DespatchAdvice-Transport.ConveyanceReferenceNumber',
			valueSrc: documentData,
			maxLength: 35
		});

		const carrierTrailerNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('carrier.trailer.number'),
			name: 'DespatchAdvice-Transport.TrailerNumber',
			valueSrc: documentData,
			maxLength: 20
		});

		const carryingCapacity: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('document.des.adv.carrying.capacity'),
			name: 'DespatchAdvice-Transport.Capacity',
			valueSrc: documentData,
			regex: edi.constants.VALIDATORS.DECIMAL_FOUR_AFTER_POINT
		});

		const presenceDropside: ExtComponent = createCheckbox({
			margin: '8 0 0 0',
			boxLabel: edi.i18n.getMessage('presence.dropside'),
			name: 'DespatchAdvice-Transport.Dropside',
			checked: edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Transport.Dropside') === 'YES',
			inputValue: 'YES',
			uncheckedValue: 'NO'
		});

		const temperatureConditions: ExtComponent = createCombo({
			fieldLabel: edi.i18n.getMessage('document.des.adv.temperature.conditions'),
			name: 'DespatchAdvice-Transport.Temperature',
			store: edi.stores.createSimpleInlineStore(['5', '17', '517', 'NO'], function (id: string) {
				return edi.i18n.getMessage('temperature.conditions.code.' + id);
			}),
			value: edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Transport.Temperature') || 'NO'
		});

		const typeShipment: ExtComponent = createCombo({
			fieldLabel: edi.i18n.getMessage('type.of.shipment'),
			name: 'DespatchAdvice-Transport.PartialShipment',
			value: edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Transport.PartialShipment'),
			store: edi.stores.createSimpleInlineStore(['NO', '0', '1', '2'], function (id: string) {
				if ('NO' === id) {
					return edi.i18n.getMessage('form.combo.not.selected');
				}
				return edi.i18n.getMessage('partial.shipment.code.' + id);
			})
		});

		const sealNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('seal.number'),
			name: 'DespatchAdvice-Transport.SealNumber',
			valueSrc: documentData,
			maxLength: 175
		});

		const transport: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('document.des.adv.transport'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [
					[3, 2],
					[3, 2, 2, 2, 3],
					[3, 3, 3]
				]
			},
			items: [
				modeOfTransport,
				vehicleHeight,
				vehicleType,
				transportNumber,
				carrierTrailerNumber,
				carryingCapacity,
				presenceDropside,
				temperatureConditions,
				typeShipment,
				sealNumber
			]
		});

		//driver
		const carrierName: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('full.name.abbr'),
			name: 'DespatchAdvice-Transport.CarrierName',
			valueSrc: documentData,
			maxLength: 175
		});

		const carrierPhoneNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('general.phone'),
			inputMask: '+7 000 000-00-00',
			name: 'DespatchAdvice-Transport.Phone',
			valueSrc: documentData,
			maxLength: 35
		});

		const driver: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('document.des.adv.driver'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[3, 2]]
			},
			items: [carrierName, carrierPhoneNumber]
		});

		//termsOfDelivery
		const termsOfDelivery: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('terms.of.delivery'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[6]]
			},
			items: [
				createCombo({
					name: 'DespatchAdvice-Transport.TermsOfDelivery',
					valueSrc: documentData,
					store: edi.stores.initTermsOfDeliveryStore(),
					forceSelection: true
				})
			]
		});

		return createFieldSet({
			title: edi.i18n.getMessage('transportation.information'),
			collapsible: true,
			items: [
				createMaxWidthContainer({
					items: [sendingCargo, expectedDelivery, deliveryPeriod, transport, driver, termsOfDelivery],
					layout: {
						type: 'grid',
						gap: 24
					}
				})
			]
		});
	};

	const createSectionAdditionalBlock = function () {
		const contractNumber: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('document.select.contract'),
			layout: {
				type: 'grid',
				area: [[3]]
			},
			items: [
				createTextField({
					name: 'DespatchAdvice-Header.Reference.ContractNumber',
					valueSrc: documentData,
					maxLength: 35
				})
			]
		});

		//invoice
		const waybillNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			name: 'DespatchAdvice-Header.WaybillNumber',
			valueSrc: documentData,
			maxLength: 35
		});

		const waybillDate: ExtComponent = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'DespatchAdvice-Header.WaybillDate',
			valueSrc: documentData
		});

		const invoice: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('document.des.adv.invoice'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[3, 2]]
			},
			items: [waybillNumber, waybillDate]
		});

		//transport invoice
		const billOfLading: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			name: 'DespatchAdvice-Header.BillOfLadingNumber',
			valueSrc: documentData,
			maxLength: 35
		});

		const billOfLadingDate: ExtComponent = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'DespatchAdvice-Header.BillOfLadingDate',
			valueSrc: documentData
		});

		const transportInvoice: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('document.des.adv.transport.invoice'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[3, 2]]
			},
			items: [billOfLading, billOfLadingDate]
		});

		//upd
		const updNumber: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			name: 'DespatchAdvice-Header.UTDnumber',
			valueSrc: documentData,
			maxLength: 35,
			minLength: 1
		});

		const updDate: ExtComponent = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'DespatchAdvice-Header.UTDDate',
			valueSrc: documentData
		});

		const upd: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('action.upd'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[3, 2]]
			},
			items: [updNumber, updDate]
		});

		//egais
		const fixEGAIS: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('document.des.adv.fix.egais.code'),
			name: 'DespatchAdvice-Header.CodeFixEGAIS',
			valueSrc: documentData,
			maxLength: 175
		});

		const fixEGAISDate: ExtComponent = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'DespatchAdvice-Header.DateFixEGAIS',
			valueSrc: documentData
		});

		const ttnRegId: ExtComponent = createTextField({
			fieldLabel: edi.i18n.getMessage('document.des.adv.ttn.reg.id'),
			name: 'DespatchAdvice-Header.TTNRegId',
			valueSrc: documentData,
			maxLength: 50
		});

		const egais: ExtComponent = createFieldBlock({
			title: edi.i18n.getMessage('document.des.adv.egais'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[3, 2, 3]]
			},
			items: [fixEGAIS, fixEGAISDate, ttnRegId]
		});

		return createFieldSet({
			title: edi.i18n.getMessage('document.des.adv.section.additional'),
			collapsible: true,
			items: createMaxWidthContainer({
				layout: {
					type: 'grid',
					gap: 24
				},
				items: [contractNumber, invoice, transportInvoice, upd, egais]
			})
		});
	};

	/**
	 * Дополнительные поля
	 */
	const createCustomFieldsContainer = function () {
		return (customFieldsContainer = createContainer({}) as ExtComponent);
	};

	const createRemarkBlock = function () {
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.column.remark'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [
				createTextField({
					name: 'DespatchAdvice-Header.Remarks',
					valueSrc: documentData,
					maxLength: 500,
					isTextarea: true
				})
			]
		});
	};

	const checkAndSave = function (formValues: AnyObject, sendDoc = false) {
		let products: AnyObject[] = productsTreeGrid.getValues().products;
		const showCountryWarning = products.some((product) => {
			return (
				edi.utils.getObjectProperty(product, 'Line-AdditionalInformation.CountryOfOrigin') === '' &&
				edi.utils.getObjectProperty(product, 'Line-Reference.Reference-Elements.Customs-Declarations').length
			);
		});
		if (showCountryWarning) {
			edi.core.confirm(
				edi.i18n.getMessage('warn'),
				edi.i18n.getMessage('document.desadv.customsDeclarations.country.question'),
				function () {
					save(formValues, sendDoc);
				}
			);
		} else {
			save(formValues, sendDoc);
		}
	};

	const createCreateButton = function () {
		return createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom', 'delivery', 'ultimateCustomer', 'carrier'],
							[buyer, seller, shipFrom, delivery, ultimateCustomer, carrier],
							productsTreeGrid,
							!productsTreeGrid.getLinesAmount()
						)
					) {
						return;
					}
					const values = edi.utils.collectFormValues(form);
					checkAndSave(values);
				}
			},
			isEdit
		);
	};

	const createSendButton = function () {
		return edi.permissions.hasPermission(
			edi.document.actions.getActionPermission(
				edi.constants.DOCUMENT_ACTIONS.SEND,
				edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV
			)
		)
			? createCreateAndSendButton(
					function () {
						if (
							!edi.utils.setFocusToDocumentsWithGrid(
								form,
								['buyer', 'seller', 'shipFrom', 'delivery', 'ultimateCustomer', 'carrier'],
								[buyer, seller, shipFrom, delivery, ultimateCustomer, carrier],
								productsTreeGrid,
								!productsTreeGrid.getLinesAmount()
							)
						) {
							return;
						}
						const values = edi.utils.collectFormValues(form);
						checkAndSave(values, true);
					},
					null,
					isEdit
			  )
			: null;
	};

	/**
	 * Creates despatch advise form
	 */
	const createModuleForm = function (docData?: AnyObject): ExtComponent {
		documentData = docData ? docData : desadv;
		header = edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Header');
		let parties = edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Parties');
		edi.utils.clearEmptyValues(parties);

		availableTaxRates = edi.methods.taxRates.getRatesByCountry(buyerOrg ? buyerOrg.country : 'RUS', true);
		taxRatesStore = edi.stores.createInlineStore(availableTaxRates);

		//дополнительные поля
		documentPartFieldValues.ReasonCode = edi.utils.getObjectProperty(
			documentData,
			'DespatchAdvice-Header.ReasonCode'
		);
		documentPartFieldContainers.ReasonCodeContainer = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			hidden: true,
			items: []
		});

		documentPartFieldValues.ReasonDelayCode = edi.utils.getObjectProperty(
			documentData,
			'DespatchAdvice-Header.ReasonDelayCode'
		);
		documentPartFieldContainers.ReasonDelayCodeContainer = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			hidden: true,
			items: []
		});

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: [
				createLabel({
					typography: 'heading_02',
					text: moduleData.title
				}),
				createMainDataBlock(documentData, docData, header),
				createPartiesBlock(parties, docData),
				createTransportationInformationBlock(),
				createSectionAdditionalBlock(),
				createRemarkBlock(),
				documentPartFieldContainers.ReasonCodeContainer,
				documentPartFieldContainers.ReasonDelayCodeContainer,
				createCustomFieldsContainer()
			],
			buttons: createButtonContainer({
				items: [createSendButton(), createCreateButton()]
			})
		});

		const createTreeGrid = function () {
			let lines: AnyObject[] = edi.utils.getObjectProperty(
				documentData,
				'DespatchAdvice-Consignment.Packing-Sequence.Line',
				true
			);
			unconvertedProducts = [];
			if (Array.isArray(lines)) {
				lines.forEach((line) => {
					let lineValues = flattenLineValues(line);

					if (line['Line-Order']) {
						lineValues['Line-Order'] = line['Line-Order'];
					}

					let calculated = Ext.clone(lineValues) as Product;
					calculateValues(calculated, undefined, isBasedOnDoc, true);
					lineValues.NetAmount = lineValues.NetAmount ? lineValues.NetAmount : calculated.NetAmount;
					lineValues.GrossAmount = lineValues.GrossAmount ? lineValues.GrossAmount : calculated.GrossAmount;
					lineValues.TaxAmount = lineValues.TaxAmount ? lineValues.TaxAmount : calculated.TaxAmount;
					if (isBasedOnDoc) {
						lineValues.UnitGrossPrice = lineValues.UnitGrossPrice
							? lineValues.UnitGrossPrice
							: calculated.UnitGrossPrice;
						lineValues.UnitNetPrice = lineValues.UnitNetPrice
							? lineValues.UnitNetPrice
							: calculated.UnitNetPrice;
					}

					unconvertedProducts.push(lineValues);
				});
			}

			let setTaxFields = function (values: Product, fields: AnyObject) {
				calculateValues(values, {
					amount: values.ProductQuantityDespatched
				});
				values.ProductNetAmount = values.NetAmount;
				values.ProductGrossAmount = values.GrossAmount;
				values.ProductTaxAmount = values.TaxAmount;
				if (fields.ProductNetAmount) {
					fields.ProductNetAmount.setValue(
						Ext.isNumeric(values.ProductNetAmount)
							? edi.utils.roundTo(values.ProductNetAmount, costDecimals)
							: ''
					);
				}
				if (fields.ProductTaxAmount) {
					fields.ProductTaxAmount.setValue(
						Ext.isNumeric(values.ProductTaxAmount)
							? edi.utils.roundTo(values.ProductTaxAmount, costDecimals)
							: ''
					);
				}
				if (fields.ProductGrossAmount) {
					fields.ProductGrossAmount.setValue(
						Ext.isNumeric(values.ProductGrossAmount)
							? edi.utils.roundTo(values.ProductGrossAmount, costDecimals)
							: ''
					);
				}

				values = calculateValues(values, undefined, false, true);

				fields.NetAmount ? fields.NetAmount.setValue(values.NetAmount) : null;
				fields.GrossAmount ? fields.GrossAmount.setValue(values.GrossAmount) : null;
				fields.TaxAmount ? fields.TaxAmount.setValue(values.TaxAmount) : null;
				fields.UnitNetPrice ? fields.UnitNetPrice.setValue(values.UnitNetPrice) : null;
				fields.UnitGrossPrice ? fields.UnitGrossPrice.setValue(values.UnitGrossPrice) : null;
			};

			let validateCountryOfOriginField = function () {
				let fields = getFormFields(productsTreeGrid.openedModal);
				if (fields['Line-AdditionalInformation.CountryOfOrigin']) {
					fields['Line-AdditionalInformation.CountryOfOrigin'].isValid();
				}
			};

			let productTabs = ['PRODUCT', 'TAX_AND_PRICE_DESADV', 'PACKAGE_AND_DELIVERY_DESADV', 'SV_TD'];
			let packTabs = ['DATA_PACKAGING', 'DIMENSIONS_PACKING', 'WEIGHT_CHARACTERISTICS'];

			productsTreeGrid = createProductsTree({
				lockIfNoPartner: true,
				productValues: [],
				valuesCalculator: calculateValues,
				postProcessValues: function (values: Product) {
					return postProcessValues(values, taxCalculationMethod);
				},
				isValidProduct: function (prod: Product) {
					let taxValid = edi.methods.taxRates.isValidRate(prod.TaxRate, availableTaxRates, true);
					let productAndTaxIsOk = +prod[AMOUNT_FIELD_NAME] === 0 || (!!prod[AMOUNT_FIELD_NAME] && taxValid);
					let customFieldsAreOk = edi.methods.custom_fields.checkGridRow(customFieldsObj, prod, {
						tabsForCheck: productTabs
					});
					return productAndTaxIsOk && customFieldsAreOk;
				},
				isValidPack: function (pack: Packing) {
					return edi.methods.custom_fields.checkGridRow(customFieldsObj, pack, { tabsForCheck: packTabs });
				},
				callback: function () {
					moduleData.isChanged = true;
					checkValid();
				},
				totalsHandlerCallback: function () {
					checkValid();
				},
				modalFormConfig: {
					title: 'document.desadv.line',
					modalFields: [
						{
							title: edi.i18n.getMessage('line.item.tab.product'),
							customFieldTab: 'PRODUCT',
							items: [
								{
									title: edi.i18n.getMessage('line.item.ean'),
									name: 'EAN',
									allowBlank: true,
									maskRe: /\d/i,
									validator: edi.methods.validators.ean
								},
								{
									title: edi.i18n.getMessage('line.item.quantity.orders'),
									name: 'OrderedQuantity'
								},
								{
									title: edi.i18n.getMessage('line.item.quantity.despatched'),
									name: AMOUNT_FIELD_NAME,
									type: 'number',
									validator: edi.methods.validators.positiveZero,
									allowDecimals: true,
									maxLength: 10,
									allowBlank: false,
									decimalPrecision: PRODUCT_AMOUNT_DECIMAL_PRECISION,
									minValue: 0,
									listeners: {
										change: function (field: ExtComponent, newValue: number, oldValue: number) {
											newValue ||= 0;
											oldValue ||= 0;
											let modal = productsTreeGrid.openedModal;
											let values = getProductFormValues(modal, true);
											let fields = getFormFields(modal);
											let valueDiff = newValue - oldValue;
											valueDiff = parseFloat(valueDiff.toFixed(PRODUCT_AMOUNT_DECIMAL_PRECISION));

											field.setValue(
												parseFloat(newValue.toFixed(PRODUCT_AMOUNT_DECIMAL_PRECISION))
											);
											if (fields.ProductQuantityDespatched) {
												let oldVal = +values.ProductQuantityDespatched || 0;
												let newVal = oldVal + valueDiff;
												if (newVal < 0) {
													newVal = 0;
												}
												const newV = String(
													parseFloat(newVal.toFixed(PRODUCT_AMOUNT_DECIMAL_PRECISION))
												);
												fields.ProductQuantityDespatched.setValue(newV);
												values.ProductQuantityDespatched = newV;
											}

											setTaxFields(values, fields);
										}
									}
								},
								{
									title: edi.i18n.getMessage('line.item.quantity.all.despatched'),
									name: 'ProductQuantityDespatched',
									type: 'textfield',
									readOnly: true,
									disableIfReadonly: false
								},
								{
									title: edi.i18n.getMessage('line.item.unit.of.measure'),
									type: 'okeiCode',
									anyMatch: true
								},
								{
									title: edi.i18n.getMessage('line.item.item.type'),
									name: 'ItemType',
									type: 'combo',
									anyMatch: true,
									value: 'CU',
									store: edi.stores.initItemTypeStore()
								},
								{
									title: edi.i18n.getMessage('line.item.product.description'),
									maxLength: 512,
									name: 'ItemDescription'
								},
								{
									title: edi.i18n.getMessage('line.item.buyer.item.code'),
									maxLength: 35,
									name: 'BuyerItemCode'
								},
								{
									title: edi.i18n.getMessage('line.item.supplier.item.code'),
									maxLength: 175,
									name: 'SupplierItemCode'
								},
								documentLinePartFields.ReasonCode
							]
						},
						{
							title: edi.i18n.getMessage('line.item.tab.tax.and.price.desadv'),
							customFieldTab: 'TAX_AND_PRICE_DESADV',
							items: [
								{
									title: edi.i18n.getMessage('organization.taxCalculationMethod'),
									type: 'label',
									text: function () {
										return edi.i18n.getMessage(
											'organization.taxCalculationMethod.' + taxCalculationMethod
										);
									}
								},
								{
									title: edi.i18n.getMessage('line.item.unit.net.price'),
									name: 'UnitNetPrice',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals,
									maxLength: 10,
									listeners: {
										change(field: ExtComponent, newVal: string, oldVal: string) {
											if (+newVal === +oldVal) {
												return;
											}
											let modal = productsTreeGrid.openedModal;
											let values = getProductFormValues(modal, true);
											let fields = getFormFields(modal);
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE ||
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT
												? setTaxFields(values, fields)
												: null;
											if (fields.UnitGrossPrice) {
												fields.UnitGrossPrice.isValid();
											}
										}
									},
									validator: function (value: string) {
										let valid = true;
										let modal = productsTreeGrid.openedModal;
										if (modal && !modal.isDestroyed) {
											let fields = getFormFields(modal);
											if (fields.UnitGrossPrice) {
												if (!value && fields.UnitGrossPrice.getValue()) {
													valid = edi.i18n.getMessage('error.value.notvalid');
												}
											}
										}

										return valid;
									}
								},
								{
									title: edi.i18n.getMessage('line.item.unit.gross.price'),
									name: 'UnitGrossPrice',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals,
									maxLength: 10,
									listeners: {
										change(field: ExtComponent, newVal: string, oldVal: string) {
											if (+newVal === +oldVal) {
												return;
											}
											let modal = productsTreeGrid.openedModal;
											let values = getProductFormValues(modal, true);
											let fields = getFormFields(modal);
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE
												? setTaxFields(values, fields)
												: null;
											if (fields.UnitNetPrice) {
												fields.UnitNetPrice.isValid();
											}
										}
									},
									validator: function (value: string) {
										let valid = true;
										let modal = productsTreeGrid.openedModal;
										if (modal && !modal.isDestroyed) {
											let fields = getFormFields(modal);
											if (fields.UnitNetPrice) {
												if (
													(!value && fields.UnitNetPrice.getValue()) ||
													Number(value) < Number(fields.UnitNetPrice.getValue())
												) {
													valid = edi.i18n.getMessage('error.value.notvalid');
												}
											}
										}
										return valid;
									}
								},
								{
									title: edi.i18n.getMessage('line.item.fns.tax.rate'),
									name: 'TaxRate',
									type: 'combo',
									store: taxRatesStore,
									forceSelection: !isEdit,
									valueInitialize: true,
									validator: isEdit
										? function (value: string) {
												return (
													edi.methods.taxRates.isValidRate(value, availableTaxRates, true) ||
													edi.i18n.getMessage('form.field.tax.invalid')
												);
										  }
										: null,
									listeners: {
										change: function () {
											let modal = productsTreeGrid.openedModal;
											let values = getProductFormValues(modal, true);
											let fields = getFormFields(modal);
											setTaxFields(values, fields);
										}
									}
								},
								{
									title: edi.i18n.getMessage('line.item.net.amount'),
									name: 'ProductNetAmount',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals,
									value: 0
								},
								{
									title: edi.i18n.getMessage('line.item.tax.amount'),
									name: 'ProductTaxAmount',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals
								},
								{
									title: edi.i18n.getMessage('line.item.gross.amount'),
									name: 'ProductGrossAmount',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals
								},
								{
									name: 'NetAmount',
									type: 'hidden'
								},
								{
									name: 'TaxAmount',
									type: 'hidden'
								},
								{
									name: 'GrossAmount',
									type: 'hidden'
								}
							]
						},
						{
							title: edi.i18n.getMessage('line.item.tab.package.and.delivery.desadv'),
							customFieldTab: 'PACKAGE_AND_DELIVERY_DESADV',
							items: [
								{
									title: edi.i18n.getMessage('desadv.line.item.serial.number'),
									name: 'SerialNumber',
									maxLength: 100
								},
								{
									title: edi.i18n.getMessage('desadv.line.item.batch.number'),
									name: 'BatchNumber',
									maxLength: 175
								},
								{
									title: edi.i18n.getMessage('line.item.production.date'),
									name: 'ProductionDate',
									type: 'date'
								},
								{
									title: edi.i18n.getMessage('line.item.certificate.number'),
									name: 'CertificateNumber',
									maxLength: 35,
									enforceMaxLength: true,
									isHidden: function (
										field: ExtComponent,
										record: ExtRecord<AnyObject>,
										value: string
									) {
										return !value;
									}
								},
								{
									title: edi.i18n.getMessage('line.item.boxes.quantity'),
									name: 'BoxesQuantity',
									maxLength: 9,
									regex: edi.constants.VALIDATORS.INT,
									enforceMaxLength: true
								},
								{
									title: edi.i18n.getMessage('desadv.line.item.vsd.number'),
									name: 'VSDNumber',
									maxLength: 175
								},
								{
									title: edi.i18n.getMessage('line.item.line.party.name'),
									name: 'LinePartyName',
									maxLength: 175,
									enforceMaxLength: true
								},
								{
									title: edi.i18n.getMessage('line.item.expiration.date'),
									name: 'ExpirationDate',
									type: 'date'
								},
								{
									title: edi.i18n.getMessage('line.item.certificate.date'),
									name: 'CertificateDateOfIssue',
									type: 'date',
									isHidden: function (
										field: ExtComponent,
										record: ExtRecord<AnyObject>,
										value: string
									) {
										return !value;
									}
								},
								{
									type: 'editableGrid',
									name: 'Line-Reference.Reference-Elements.Reference-Element',
									config: {
										columnsConfig: PRODUCT_REFERENCE_ELEMENT_COLUMNS_CONFIG_NAME,
										fields: [
											{
												title: 'desadv.reference.column.type',
												name: 'Reference-Type',
												maxLength: 50,
												enforceMaxLength: true,
												allowBlank: false
											},
											{
												title: 'desadv.reference.column.id',
												maxLength: 150,
												enforceMaxLength: true,
												name: 'Reference-Id',
												allowBlank: false
											}
										],
										limit: undefined,
										model: PRODUCT_REFERENCE_ELEMENT_MODEL,
										modalWidth: MODAL_SIZE.widthLarge,
										gridConfig: {
											cls: 'modal-grid modal-grid-with-header',
											minHeight: 200,
											title: edi.i18n.getMessage('desadv.reference.elements.grid.title')
										},
										storeConfig: {
											remoteSort: false
										}
									}
								}
							]
						},
						{
							title: edi.i18n.getMessage('line.item.tab.sv.td'),
							customFieldTab: 'SV_TD',
							items: [
								{
									title: edi.i18n.getMessage('desadv.line.item.invoice.number'),
									name: 'Line-AdditionalInformation.InvoiceNumber',
									maxLength: 100
								},
								{
									title: edi.i18n.getMessage('line.item.country.of.origin'),
									type: function (cfg: AnyObject, values: AnyObject) {
										return createCombo({
											name: 'Line-AdditionalInformation.CountryOfOrigin',
											valueSrc: values,
											columnWidth: 0.5,
											listeners: {
												change: function (field: ExtComponent, val: string) {
													let fields = getFormFields(productsTreeGrid.openedModal);
													let country = edi.utils.getCountryFULLByISO(val);
													let grid =
														fields[
															'Line-Reference.Reference-Elements.Customs-Declarations'
														];
													let store = grid.getStore();
													let records: ExtRecord<AnyObject>[] = store.getRange();
													records.forEach(function (record) {
														record.set(
															'countryOrigin',
															country ? country.display_name : null
														);
														record.commit();
													});
												}
											},
											store: edi.stores.createInlineStore(
												edi.stores.data.countries_full,
												'COUNTRY',
												null,
												{
													addEmptyRecord: true
												}
											),
											valueField: 'iso_2',
											displayField: 'display_name',
											editable: false
										});
									}
								},
								{
									type: 'editableGrid',
									name: 'Line-Reference.Reference-Elements.Customs-Declarations',
									config: {
										columnsConfig: PRODUCT_REFERENCE_COLUMNS_CONFIG_NAME,
										fields: [
											{
												title: 'column.kod.proiskh',
												type: 'combo',
												name: 'countryOrigin',
												columnWidth: 0.5,
												listeners: {
													afterrender: function (elm: ExtComponent) {
														if (elm && elm.readOnly) {
															Ext.create('Ext.tip.ToolTip', {
																target: elm.getEl(),
																trackMouse: true,
																html: edi.i18n.getMessage(
																	'documents.country.code.tooltip'
																)
															});
														}
													}
												},
												store: edi.stores.createInlineStore(
													edi.stores.data.countries_full,
													'COUNTRY',
													null,
													{
														addEmptyRecord: true
													}
												),
												anyMatch: true,
												valueField: 'iso_number_3',
												displayField: 'display_name',
												allowBlank: false,
												disableIfReadonly: false,
												readOnly: function () {
													let fields = getFormFields(productsTreeGrid.openedModal),
														countryCodeField = fields
															? fields['Line-AdditionalInformation.CountryOfOrigin']
															: null;
													return countryCodeField ? !!countryCodeField.getValue() : false;
												},
												value: function () {
													let res = null,
														fields = getFormFields(productsTreeGrid.openedModal),
														countryCodeField = fields
															? fields['Line-AdditionalInformation.CountryOfOrigin']
															: null;
													if (countryCodeField && countryCodeField.getValue()) {
														let country = edi.utils.getCountryFULLByISO(
															countryCodeField.getValue()
														);
														country ? (res = country.iso_number_3) : null;
													}
													return res;
												}
											},
											{
												title: 'column.nomer.td',
												name: 'Reference-Id',
												type: 'text',
												minLength: 1,
												maxLength: 150,
												allowBlank: false
											}
										],
										limit: undefined,
										model: PRODUCT_REFERENCE_MODEL,
										modalWidth: MODAL_SIZE.widthLarge,
										gridConfig: {
											cls: 'modal-grid',
											minHeight: 200,
											title: edi.i18n.getMessage('line.item.tab.sv.td'),
											listeners: {
												render() {
													validateCountryOfOriginField();
												}
											}
										},
										storeConfig: {
											remoteSort: false,
											listeners: {
												update(store: ExtComponent, record: ExtRecord<AnyObject>) {
													let fields = getFormFields(productsTreeGrid.openedModal),
														countryCodeField = fields
															? fields['Line-AdditionalInformation.CountryOfOrigin']
															: null;
													if (!isEdit) {
														countryCodeField.setReadOnly(store.getCount());
													}

													if (countryCodeField && !countryCodeField.getValue()) {
														let country = edi.utils.getCountryFULLByISO(
															null,
															null,
															null,
															record.get('countryOrigin')
														);
														country ? countryCodeField.setValue(country.iso_2) : null;

														validateCountryOfOriginField();
													}
												},
												remove(store: ExtComponent) {
													let fields = getFormFields(productsTreeGrid.openedModal),
														countryCodeField = fields
															? fields['Line-AdditionalInformation.CountryOfOrigin']
															: null;

													if (countryCodeField) {
														let countryCode =
															store.getCount() === 1
																? store.getAt(0).get('countryOrigin')
																: null;
														if (countryCode || !store.getCount()) {
															let country = edi.utils.getCountryFULLByISO(
																null,
																null,
																null,
																countryCode
															);
															country ? countryCodeField.setValue(country.iso_2) : null;
														}
														countryCodeField.setReadOnly(store.getCount());

														validateCountryOfOriginField();
													}
												}
											}
										}
									}
								}
							]
						}
					]
				}
			});

			if (buyerId) {
				productsTreeGrid.setPartnerId(buyerId);
			}

			return productsTreeGrid.wrapper;
		};

		form.add(createTreeGrid());

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Removes old custom fields from products and packages in tree
	 */
	let removeOldCustomFieldsInTree = function () {
		let oldXpathMap: { [key: string]: keyof (Product | Packing) } = previousCustomFieldsObj?.xPathMap;
		if (!oldXpathMap || Object.keys(oldXpathMap).length === 0) {
			return;
		}

		//выберем поля, которые надо удалить
		let fieldsToRemove = Object.keys(oldXpathMap).filter((f) => f.match(/@/));

		Object.values(productsTreeGrid.maps).forEach((map: { [key: string]: Product | Packing }) => {
			Object.values(map).forEach((productOrPacking) => {
				fieldsToRemove
					.map((key) => oldXpathMap[key])
					.forEach((key) => {
						delete productOrPacking[key];
					});
			});
		});
	};

	/**
	 * Updates additional info fields
	 */
	const updateDocPartFields = function (organizationDocPartFields: AnyObject[]) {
		let docHeader =
				organizationDocPartFields.find(function (item) {
					return item.docPart === 'docHeader';
				}) || {},
			docLines =
				organizationDocPartFields.find(function (item) {
					return item.docPart === 'docLines';
				}) || {},
			headerFields = edi.utils.getObjectProperty(docHeader, 'fields', true),
			container,
			productFields = edi.utils.getObjectProperty(docLines, 'fields', true),
			i;

		const hasValue = function (values: AnyObject[], value: string): boolean {
			let found = false;
			for (let i = 0; i < values.length; i++) {
				if (values[i].code == value) {
					found = true;
					break;
				}
			}
			return found;
		};
		const renderHeaderField = function (fValues: AnyObject, value?: any) {
			let values = fValues.values;
			if (values.length) {
				if (value && values.length > 1) {
					value = hasValue(values, value) ? value : null;
				}
				if (1 === values.length) {
					value = values[0].code;
				}
			}
			documentPartFields[fValues.field] = values.length
				? createCombo({
						filedLabel: edi.i18n.getMessage('org.document.LEGACY_DES_ADV.part.docHeader.' + fValues.field),
						name: 'DespatchAdvice-Header.' + fValues.field,
						store: edi.stores.createInlineStore(values, 'ORG_DOCUMENT_DATA_FIELD_LINE_VALUES'),
						value: value,
						displayField: 'value',
						valueField: 'code',
						anyMatch: true,
						allowBlank: !fValues.required,
						forceSelection: true
				  })
				: createTextField({
						filedLabel: edi.i18n.getMessage('org.document.LEGACY_DES_ADV.part.docHeader.' + fValues.field),
						maxLength: 20,
						name: 'DespatchAdvice-Header.' + fValues.field,
						allowBlank: !fValues.required,
						value: value
				  });
			return documentPartFields[fValues.field];
		};
		const setProductFieldConfig = function (fValues: AnyObject) {
			let comboConf = {
					title: edi.i18n.getMessage('org.document.LEGACY_DES_ADV.part.docLines.' + fValues.field),
					type: 'combo',
					isHidden: false,
					name: fValues.field,
					displayField: 'value',
					valueField: 'code',
					anyMatch: true,
					forceSelection: true,
					allowBlank: !fValues.required,
					store: edi.stores.createInlineStore(fValues.values, 'ORG_DOCUMENT_DATA_FIELD_LINE_VALUES')
				},
				textConf = {
					title: edi.i18n.getMessage('org.document.LEGACY_DES_ADV.part.docLines.' + fValues.field),
					isHidden: false,
					maxLength: 20,
					type: 'text',
					name: fValues.field,
					allowBlank: !fValues.required
				};
			if (documentLinePartFields[fValues.field as string]) {
				let conf: AnyObject = fValues.values && fValues.values.length ? comboConf : textConf;
				for (let i in documentLinePartFields[fValues.field]) {
					if (documentLinePartFields[fValues.field].hasOwnProperty(i) && !conf.hasOwnProperty(i)) {
						documentLinePartFields[fValues.field][i] = undefined;
						delete documentLinePartFields[fValues.field][i];
					}
				}
				for (let i in conf) {
					if (conf.hasOwnProperty(i)) {
						documentLinePartFields[fValues.field][i] = conf[i];
					}
				}
			}
		};
		for (i in documentPartFieldContainers) {
			if (documentPartFieldContainers.hasOwnProperty(i)) {
				documentPartFieldContainers[i].setVisible(false);
			}
		}
		for (i = 0; i < headerFields.length; i++) {
			container = documentPartFieldContainers[headerFields[i].field + 'Container'];
			if (container) {
				let currentValue: any;
				if (documentPartFields[headerFields[i].field]) {
					currentValue = documentPartFields[headerFields[i].field].getInput().getValue();
					container.removeAll();
				} else {
					currentValue = documentPartFieldValues[headerFields[i].field];
				}
				container.add(renderHeaderField(headerFields[i], currentValue));
				container.setVisible(true);
			}
		}
		for (i in documentLinePartFields) {
			if (documentLinePartFields.hasOwnProperty(i)) {
				documentLinePartFields[i].isHidden = true;
			}
		}
		for (i = 0; i < productFields.length; i++) {
			setProductFieldConfig(productFields[i]);
		}
		form.isValid();
	};

	/**
	 * Prepare and load products values in tree (only initial loading into tree)
	 */
	const loadProductsIntoTree = function () {
		let products: (Product | Packing)[] = [];
		if (unconvertedProducts.length) {
			unconvertedProducts.forEach((line, index) => {
				let packs =
					line && line['Package-Identification'] && line['Package-Identification']['Goods-Identity']
						? line['Package-Identification']['Goods-Identity']
						: [];
				if (customFieldsObj.customFieldsData) {
					Object.entries(customFieldsObj.customFieldsData).forEach(function ([key, val]) {
						let regexp = new RegExp(`\\[${index + 1}\\]`);
						if (key.match(regexp)) {
							key = key.replace(regexp, '[@]');
							let lineKey = customFieldsObj.xPathMap[key];
							packs.forEach(function (pack: AnyObject) {
								lineKey ? (pack[lineKey] = val) : null;
							});
							lineKey ? (line[lineKey] = val) : null;
						}
					});
				}
			});
			let convertedProducts = convertDesadvProductsToDesadvCrossProducts(
				unconvertedProducts,
				taxCalculationMethod,
				costDecimals,
				productsTreeGrid
			);
			productConvertationResult = convertedProducts.result;
			products = convertedProducts.products;
		}

		productsTreeGrid.loadProducts(products);
		if (documentData && documentData['DespatchAdvice-Summary']) {
			productsTreeGrid.loadTotalValues(Ext.clone([documentData['DespatchAdvice-Summary']]));
		}
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		let productLinesCount = productsTreeGrid.getLinesAmount(),
			isValidGrid = productsTreeGrid.isValid(),
			isValidCompanies = validateCompanySelectors(),
			isValidForm = !form.hasInvalidField();

		return productLinesCount && isValidForm && isValidCompanies && isValidGrid;
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	const validateCompanySelectors = function () {
		let isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isDeliveryValid = delivery.isValid(),
			isShipFromValid = shipFrom ? shipFrom.isValid() : true,
			isUltimateCustomerValid = ultimateCustomer ? ultimateCustomer.isValid() : true,
			isCarrierValid = carrier ? carrier.isValid() : true;

		return (
			isBuyerValid &&
			isSellerValid &&
			isDeliveryValid &&
			isShipFromValid &&
			isUltimateCustomerValid &&
			isCarrierValid
		);
	};

	/**
	 * Saves desadv
	 */
	const save = function (formValues: AnyObject, sendDoc = false) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));

		let allValues = formValues || {};
		let numberAutoGenerated = allValues.numberAutoGenerated;
		delete allValues.numberAutoGenerated;

		let productValues = productsTreeGrid.getValues();
		let { customFieldsData, clearValues, clearProducts } = separateCustomAndNormalFields(
			customFieldsObj,
			allValues,
			productValues.products,
			topPath
		);
		productValues.products = clearProducts;

		let additionalData = edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Header.AdditionalData');
		edi.utils.setObjectProperty(clearValues, 'DespatchAdvice-Header.AdditionalData', additionalData);

		productValues.products.forEach((product: Product) => {
			let customDeclarations: AnyObject[] = edi.utils.getObjectProperty(
				product,
				'Line-Reference.Reference-Elements.Customs-Declarations'
			);
			let referenceElements = edi.utils.getObjectProperty(
				product,
				'Line-Reference.Reference-Elements.Reference-Element'
			);
			if (customDeclarations.length > 0) {
				let customDeclaration = customDeclarations.map((item) => ({
					'Reference-Id': item['Reference-Id'],
					'Reference-Type': 'ABT'
				}));
				let referenceElementsConcat = referenceElements.concat(customDeclaration);
				edi.utils.setObjectProperty(
					product,
					'Line-Reference.Reference-Elements.Reference-Element',
					referenceElementsConcat
				);

				delete product['Line-Reference']['Reference-Elements']['Customs-Declarations'];
			}
			return referenceElements;
		});

		if (clearValues['DespatchAdvice-Transport']['ModeOfTransport'] === '0') {
			clearValues['DespatchAdvice-Transport']['ModeOfTransport'] = '';
		}
		if (clearValues['DespatchAdvice-Transport']['PartialShipment'] === 'NO') {
			clearValues['DespatchAdvice-Transport']['PartialShipment'] = null;
		}

		let docContent = templateCreateDesadv({
			'DespatchAdvice-Header': clearValues['DespatchAdvice-Header'],
			'DespatchAdvice-Transport': clearValues['DespatchAdvice-Transport'],
			parties: templateCreateDesadvParties({
				Buyer: clearValues['Buyer'],
				Seller: clearValues['Seller'],
				DeliveryPoint: clearValues['DeliveryPoint'],
				ShipFrom: clearValues['ShipFrom'],
				Carrier: clearValues['Carrier'],
				UltimateCustomer: clearValues['UltimateCustomer']
			}),
			productValues: productValues
		});

		docContent = edi.document.actions.mergeDataBeforeSave(initialData, docContent, [
			{
				path: 'DespatchAdvice-Consignment.Packing-Sequence.Line'
			}
		]);

		if (
			clearValues['DespatchAdvice-Transport'].DeliveryPeriodFrom &&
			clearValues['DespatchAdvice-Transport'].DeliveryPeriodTo
		) {
			docContent[
				'DespatchAdvice-Transport'
			].DeliveryPeriod = `${docContent['DespatchAdvice-Transport'].DeliveryPeriodFrom}-${docContent['DespatchAdvice-Transport'].DeliveryPeriodTo}`;
		} else {
			docContent['DespatchAdvice-Transport'].DeliveryPeriod = null;
		}
		delete docContent['DespatchAdvice-Transport'].DeliveryPeriodFrom;
		delete docContent['DespatchAdvice-Transport'].DeliveryPeriodTo;

		edi.utils.clearEmptyValues(docContent);

		let headerData: AnyObject = {
			data: Ext.encode(docContent),
			date: edi.utils.getObjectProperty(docContent, 'DespatchAdvice-Header.DespatchAdviceDate')
		};
		if (customFieldsData && Object.keys(customFieldsData).length > 0) {
			headerData.customFields = customFieldsData;
		}

		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(docContent, 'DespatchAdvice-Header.DespatchAdviceNumber');
		}

		const onSuccessSave = function (responseData: AnyObject) {
			let documentId = isEdit ? id : responseData.data.id;
			const send = function () {
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.SEND.PUT,
						{
							documentId: documentId
						},
						true
					),
					'PUT',
					Ext.encode({}),
					finishSaving,
					edi.document.actions.defaultFailureHandler(
						moduleData.tab,
						'document.error.in.sending.process',
						finishSaving
					)
				);
			};

			const finishSaving = function () {
				const finish = function () {
					moduleData.isChanged = false;
					moduleData.tab.setLoading(false);
					edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
					if (id && !isCopy) {
						edi.events.documents.fireEvent('change', {
							id: id
						});
					} else {
						edi.events.documents.fireEvent('create');
					}
				};
				if (sendDoc) {
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData: AnyObject) {
						headerData ? (responseData.data = headerData) : null;
						finish();
					});
				} else {
					finish();
				}
			};
			const isWarning = edi.utils.getAttributeByName(responseData.data?.attributes, 'isWarning') === 'true';
			const isBlocked = edi.utils.getAttributeByName(responseData.data?.attributes, 'isBlocked') === 'true';
			const continueFn = !isWarning && !isBlocked && sendDoc ? send : finishSaving;
			if (!isEdit) {
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.PUT,
						{
							documentId: documentId
						},
						true
					),
					'PUT',
					Ext.encode({
						UPDATE: true,
						UPDATE_PARAMS: {
							id: documentId
						}
					}),
					continueFn,
					edi.document.actions.defaultFailureHandler(
						moduleData.tab,
						'document.error.in.cross.docking.process',
						continueFn
					)
				);
			} else {
				continueFn();
			}
		};

		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV
		);
		edi.document.actions.processDocument(
			buyerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
			parentId,
			isEdit ? id : undefined,
			onSuccessSave,
			failure,
			headerData
		);
	};

	/**
	 * Renders module
	 */
	const renderData = function (initCallBack?: ModuleInitCallback) {
		let data = moduleData.initData.data;
		id = data && data.id;

		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', () =>
			edi.modulesHandler.removeModule(moduleData)
		);

		const showInvalidPackingStructureWarn = function () {
			edi.core.showWarn(edi.i18n.getMessage('desadv.products.convertation.failed'));
		};

		let createModulePanel = function (docData?: AnyObject): void {
			let modulePanel = createAddModulePanel() as ExtComponent;
			modulePanel.add(createModuleForm(docData));
			moduleData.tab.add(modulePanel);

			buyer.presetFromRelation(function () {
				if ('function' == typeof initCallBack) {
					initCallBack();
				}

				const productsWithInvalidRates =
					isEdit || isBasedOnDoc
						? edi.methods.taxRates.getProductsWithInvalidRates(unconvertedProducts, availableTaxRates, {
								allowBlankRate: true
						  })
						: [];

				if (productsWithInvalidRates.length) {
					edi.methods.taxRates.showInvalidProductsWarn(productsWithInvalidRates, availableTaxRates, {
						columns: {
							EAN: {
								title: 'column.ean'
							},
							TaxRate: {
								title: 'column.tax.rate'
							}
						},
						callback: !productConvertationResult ? showInvalidPackingStructureWarn : null
					});
				} else if (!productConvertationResult) {
					showInvalidPackingStructureWarn();
				}

				if (buyer.selectedOrg || buyerId) {
					edi.document.actions.getOrgDocumentPartFields(
						buyer.selectedOrg ? buyer.selectedOrg.id : buyerId,
						edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
						updateDocPartFields
					);
				}
			});

			let toOrg = isBasedOnDoc ? buyer.selectedOrg : data?.toOrg;
			let fromOrg = isBasedOnDoc ? seller.selectedOrg : data?.fromOrg;
			if (toOrg?.id && fromOrg?.id) {
				edi.methods.custom_fields.initCustomFields({
					customFieldsObj,
					initialCustomFieldsData: customFieldsFromTransformation,
					docType: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
					toOrgId: toOrg.id,
					fromOrgId: fromOrg.id,
					docId: id,
					container: customFieldsContainer,
					grid: productsTreeGrid,
					isTree: true,
					topPath: topPath,
					failCallback() {
						form.isValid();
						loadProductsIntoTree();
						checkValid();
					},
					insertionCallback(newObj: AnyObject, previousObj: AnyObject) {
						customFieldsObj = newObj;
						previousCustomFieldsObj = previousObj;
						loadProductsIntoTree();
					}
				});
			} else {
				loadProductsIntoTree();
			}
		};

		if (id) {
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			buyerOrg = data.toOrg;
			if (!buyerOrg?.hasOwnProperty('attributes')) {
				buyerOrg = edi.utils.getOrg({ orgId: buyerOrg.id });
			}
			buyerId = buyerOrg?.id;
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(data.toOrg.attributes);

			let getContentUrl = edi.utils.formatString(
				edi.rest.services.DOCUMENTS.CONTENT.GET,
				{
					documentId: id
				},
				true
			);
			edi.rest.sendRequest(
				getContentUrl,
				'GET',
				{},
				function (response: AnyObject) {
					if (response && response.data) {
						initialData = response.data;
						createModulePanel(response.data);
					} else {
						failure(response);
					}
				},
				failure
			);
		} else {
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				let numerationUrl = edi.utils.formatString(
					edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET,
					{ doctype: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV }
				);
				edi.rest.sendRequest(
					numerationUrl,
					'GET',
					undefined,
					function (docNumberSettings: AnyObject) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
