const actionRulesForDsfClientModule = {
	QUICK_ANSWER_CONFIRM: {},
	QUICK_ANSWER_REJECT: {},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {}
	},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		conditions: [
			{
				allow: true
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		UNKNOWN: {},
		permissionChanging: []
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		conditions: [
			{
				directions: [edi.constants.DIRECTIONS.OUTGOING],
				allow: function (checkOptions) {
					return +edi.utils.getAttributeByName(checkOptions.attributes, 'senderSignaturesCount') > 0;
				}
			},
			{
				directions: [edi.constants.DIRECTIONS.INCOMING],
				allow: function (checkOptions) {
					return +edi.utils.getAttributeByName(checkOptions.attributes, 'receiverSignaturesCount') > 0;
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.COMPLETED
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					let hasOption =
						edi.utils.getAttributeByName(checkOptions.attributes, 'cancellationUsePosible') === 'true';
					return hasOption && !checkOptions.record.get('archived');
				}
			}
		]
	},
	CLARIFY: {},
	DELETE: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		}
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		}
	},
	COMPLETE: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW]
		},
		permissionChanging: [
			{
				change: function () {
					return 'SIGN_DSF_CLIENT';
				}
			}
		],
		conditions: [
			{
				allow: function (checkOptions) {
					return +edi.utils.getAttributeByName(checkOptions.attributes, 'receiverSignaturesCount') === 0;
				}
			}
		]
	},
	READ: {},
	SEND: {
		OUTGOING: {},
		permissionChanging: [
			{
				states: [edi.constants.STATE.DRAFT],
				change: function () {
					return 'SIGN_DSF_CLIENT';
				}
			}
		],
		conditions: [
			{
				directions: [edi.constants.DIRECTIONS.OUTGOING],
				states: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW],
				allow: function (checkOptions) {
					return +edi.utils.getAttributeByName(checkOptions.attributes, 'senderSignaturesCount') === 0;
				}
			}
		]
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {},
	CONVERT_TO_DRAFT: {},
	COPY: {
		OUTGOING: {},
		LOOP: {}
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					let hasOption =
						edi.utils.getAttributeByName(checkOptions.attributes, 'cancellationUsePosible') === 'true';
					return hasOption && !checkOptions.record.get('archived');
				}
			}
		]
	},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.DSF_CLIENT]: actionRulesForDsfClientModule
});

export { actionRulesForDsfClientModule };
