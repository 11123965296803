// @ts-ignore
import { createDateField, createLabel, createRadio, createTextField } from '@UIkit/components/fields';
import {
	createButtonContainer,
	createFieldBlock,
	createMaxWidthContainer
	// @ts-ignore
} from '@UIkit/components/panels';
// @ts-ignore
import { createGrid, createToolBar } from '@UIkit/components/grid';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { showConfirm } from '@UIkit/components/modal/MessageBox';
import { Org } from '@App/js/definitions/org';
import { DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME } from './models';
import { DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME } from './columns';
import { documentPackageSelectors } from './selectors';
import { CreatePackageValues, documentPackageApi, DocumentPackageHeader, PackageType } from './entities';
import { documentPackageMethods } from './methods';
import { PackageAddExistingDocWindow } from './addExistingDocWindow';
import { UploadDocToPackageWindow } from './uploadDocToPackageWindow';
import { CreateDocForPackageWindow } from './createDocForPackageWindow';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createActionsColumnConfig } from '@Components/grid';
import { createCreateSaveButton } from '@Components/buttons';

export class DocumentPackageCreateModule {
	moduleData: ModuleData<DocumentPackageHeader>;
	modulePanel: ExtComponent;
	moduleForm: ExtComponent;
	senderField: ExtComponent;
	receiverField: ExtComponent;
	moduleGrid: ExtComponent;
	addButton: ExtComponent;
	saveBtn: ExtComponent;
	packageHeader: DocumentPackageHeader | null;
	packageDocs: DocumentHeader[];
	isEdit: boolean;
	isEditNotInDraft: boolean;
	_updateGridEventHandler: DocumentPackageCreateModule['updateGridEventHandler'];
	_deleteEventHandler: DocumentPackageCreateModule['deleteEventHandler'];
	_updateModuleHandler: DocumentPackageCreateModule['updateModuleHandler'];

	constructor() {
		const me = this;
		me._updateGridEventHandler = me.updateGridEventHandler.bind(me);
		me._deleteEventHandler = me.deleteEventHandler.bind(me);
		me._updateModuleHandler = me.updateModuleHandler.bind(me);
	}

	init(data: ModuleData<DocumentPackageHeader>, initCallBack: Function): () => boolean {
		const me = this;
		me.moduleData = data;
		me.isEdit = !!me.moduleData.initData.data?.id;
		me.packageHeader = me.isEdit ? me.moduleData.initData.data : null;
		me.packageDocs = me.moduleData.initData.preselectedDocuments ?? [];
		me.renderData(initCallBack).catch(me.moduleErrorHandler.bind(me));
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		if (me.isEdit) {
			documentPackageMethods.eventsObserver.on('uploadNewDocument', me._updateGridEventHandler);
			documentPackageMethods.eventsObserver.on('changeDocuments', me._updateGridEventHandler);
			documentPackageMethods.eventsObserver.on('delete', me._deleteEventHandler);
			documentPackageMethods.eventsObserver.on('update', me._updateModuleHandler);
		}
	}

	deleteEventHandler({ packageId }: { packageId: number }) {
		const me = this;
		if (packageId === me.packageHeader?.id) {
			me.moduleData.isChanged = false;
			edi.modulesHandler.removeModule(me.moduleData);
		}
	}

	updateGridEventHandler({ packageId }: { packageId: number }) {
		const me = this;
		if (packageId === me.packageHeader?.id) {
			me.reloadGrid();
		}
	}

	updateModuleHandler({ packageId }: { packageId: number }) {
		const me = this;
		if (packageId === me.packageHeader?.id) {
			me.renderData();
		}
	}

	setModuleLoading(loading: boolean | string | AnyObject) {
		const me = this;
		!me.moduleData.tab.destroyed && me.moduleData.tab.setLoading(loading);
	}

	moduleErrorHandler(err?: AnyObject) {
		const me = this;
		edi.rest.getErrorHandler(null, () => edi.modulesHandler.removeModule(me.moduleData))(err);
	}

	createTitleBlock(): ExtComponent {
		const me = this;
		return createLabel({
			typography: 'heading_02',
			text: edi.i18n.getMessage(me.isEdit ? 'documentPackage.title.edit' : 'documentPackage.title.create')
		});
	}

	createPackageTypeBlock(): ExtComponent {
		const me = this;
		const isClosed = me.packageHeader?.type === PackageType.ENCLOSED;
		return createFieldBlock({
			title: edi.i18n.getMessage('documentPackage.type'),
			layout: 'hbox',
			plugins: [documentPackageMethods.createPackageTypeHintPlugin('titleEl')],
			items: [
				createRadio({
					readOnly: me.isEdit,
					name: 'type',
					boxLabel: edi.i18n.getMessage('documentPackage.type.opened'),
					inputValue: PackageType.OPEN,
					uncheckedValue: PackageType.ENCLOSED,
					checked: !isClosed
				}),
				createRadio({
					readOnly: me.isEdit,
					name: 'type',
					margin: '0 0 0 24',
					inputValue: PackageType.ENCLOSED,
					uncheckedValue: PackageType.OPEN,
					checked: isClosed,
					boxLabel: edi.i18n.getMessage('documentPackage.type.closed')
				})
			]
		});
	}

	createNumberDateBlock(): ExtComponent {
		const me = this;
		return createFieldBlock({
			cls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [
				createTextField({
					readOnly: me.isEditNotInDraft,
					fieldLabel: edi.i18n.getMessage('field.name.number'),
					name: 'number',
					value: me.packageHeader?.number,
					allowBlank: false,
					maxLength: 50
				}),
				createDateField({
					readOnly: me.isEditNotInDraft,
					fieldLabel: edi.i18n.getMessage('column.date'),
					name: 'docTime',
					value: edi.utils.formatDate(me.packageHeader?.docTime, edi.constants.DATE_FORMAT.FNS),
					format: edi.constants.DATE_FORMAT.FNS,
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					allowBlank: false
				})
			]
		});
	}

	createSenderBlock(): ExtComponent {
		const me = this;
		const senderOrg = me.isEdit ? me.packageHeader?.fromOrg : edi.core.getUserData().org;
		const senderOrgValues = orgSelectorMethods.getDefaultOrgConverter()(senderOrg);
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.sender'),
			items: [
				(me.senderField = createOrgSelector({
					notReturnedVoid: true,
					itemId: 'sender',
					validateOnRender: true,
					readOnly: true,
					useHiddenFields: true,
					fieldsMapOnly: true,
					fieldValues: senderOrgValues,
					selectedOrg: senderOrg,
					selectedOrgValues: senderOrgValues,
					fieldsMap: documentPackageSelectors.getFieldsMap('fromOrg'),
					modalConf: documentPackageSelectors.getModalConf()
				}) as ExtComponent)
			]
		}) as ExtComponent;
	}

	createReceiverBlock(): ExtComponent {
		const me = this;
		let receiverOrg;
		if (me.isEdit) {
			receiverOrg = me.packageHeader?.toOrg;
		} else if (me.packageDocs.length > 0) {
			receiverOrg = edi.relations.getRelations().find((org: Org) => org.id === me.packageDocs[0].toOrg.id);
		} else {
			receiverOrg = undefined;
		}
		const receiverOrgValues = receiverOrg ? orgSelectorMethods.getDefaultOrgConverter()(receiverOrg) : undefined;
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.receiver'),
			items: [
				(me.receiverField = createOrgSelector({
					notReturnedVoid: true,
					itemId: 'receiver',
					validateOnRender: true,
					readOnly: me.isEdit,
					useHiddenFields: true,
					manualChangeDisabled: true,
					relations: edi.relations.getRelations(),
					allowReset: true,
					fieldsMapOnly: true,
					fieldValues: receiverOrgValues,
					selectedOrg: receiverOrg,
					selectedOrgValues: receiverOrgValues,
					fieldsMap: documentPackageSelectors.getFieldsMap('toOrg'),
					modalConf: documentPackageSelectors.getModalConf(),
					resetConfirmText: 'documentPackage.grid.clear.warning.text',
					beforeSelectAction: function (continueFn: Function) {
						if (me.moduleGrid.getStore().getCount() > 0) {
							showConfirm({
								msgText: 'documentPackage.grid.clear.warning.text',
								success: continueFn
							});
						} else {
							continueFn();
						}
					},
					callback: function (_values: AnyObject, org: Org | null) {
						if (org?.id !== me.moduleGrid._prevReceiverId && me.moduleGrid.getStore().getCount() > 0) {
							me.moduleGrid.getStore().removeAll();
						}
						me.addButton.setDisabled(!org?.id);
						me.moduleGrid._prevReceiverId = org?.id;
					}
				}) as ExtComponent)
			]
		}) as ExtComponent;
	}

	createRemarksBlock(): ExtComponent {
		const me = this;
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.column.remark'),
			items: [
				createTextField({
					readOnly: me.isEditNotInDraft,
					name: 'remark',
					value: me.packageHeader?.remark,
					isTextarea: true,
					rowsHtmlAttributeValue: 4,
					maxLength: 512
				})
			]
		}) as ExtComponent;
	}

	async reloadGrid() {
		const me = this;
		if (me.packageHeader?.id) {
			const { success, items, errorResponse } = await documentPackageApi.fetchPackageItems(me.packageHeader.id);
			if (success) {
				me.moduleGrid.getStore().loadData(items);
			} else if (edi.modulesHandler.getActiveModule() === me.moduleData) {
				edi.rest.getErrorHandler()(errorResponse);
			}
		}
	}

	getProxyConfig(): AnyObject {
		return {
			type: 'memory'
		};
	}

	getStoreConfig(): AnyObject {
		const me = this;
		return {
			pageSize: edi.constants.ITEMS_PER_PAGE,
			model: edi.models.getModel(DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME),
			data: me.packageDocs
		};
	}

	canOpenDetails(record: ExtRecord<DocumentHeader>): boolean {
		return edi.document.actions.checkDocumentActionPermission(
			record.get('type'),
			edi.constants.DOCUMENT_ACTIONS.DETAILS
		);
	}

	openDocDetails(record: ExtRecord<DocumentHeader>) {
		let recordData = record.getData();
		edi.document.actions.openDetailsModule(recordData.type, recordData);
	}

	canRemoveFromPackage(record: ExtRecord<DocumentHeader>): boolean {
		const me = this;
		return me.isEdit ? record.get('state') === edi.constants.STATE.DRAFT : true;
	}

	async removeDocFromPackage(record: ExtRecord<DocumentHeader>) {
		const me = this;
		if (me.isEdit && me.packageHeader?.id) {
			const { success, response } = await documentPackageApi.unlinkDocuments(me.packageHeader.id, [
				record.get('id')
			]);
			if (success) {
				documentPackageMethods.eventsObserver.fireEvent('changeDocuments', { packageId: me.packageHeader.id });
			} else {
				edi.rest.getErrorHandler()(response);
			}
		} else {
			me.moduleGrid.getStore().remove(record);
		}
	}

	createDetailsColumnAction(): AnyObject {
		const me = this;
		return {
			glyph: edi.constants.ICONS.DETAILS,
			iconCls: 'edi-grid-row-button-details',
			testCls: 'test-action-icon-details',
			handler: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colindex: number,
				_actionItem: unknown,
				_event: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				me.openDocDetails(record);
			},
			isActionDisabled: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colIndex: number,
				_item: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				return !me.canOpenDetails(record);
			}
		};
	}

	createRemoveColumnAction(): AnyObject {
		const me = this;
		return {
			glyph: edi.constants.ICONS.DELETE,
			iconCls: 'edi-grid-row-button-delete',
			testCls: 'test-action-icon-delete',
			handler: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colindex: number,
				_actionItem: unknown,
				_event: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				showConfirm({
					msgText: 'documentPackage.warn.before.document.unlink',
					success: () => me.removeDocFromPackage(record)
				});
			},
			isActionDisabled: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colIndex: number,
				_item: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				return !me.canRemoveFromPackage(record);
			}
		};
	}

	getActionsColumn(): AnyObject {
		const me = this;
		return createActionsColumnConfig({
			items: [me.createDetailsColumnAction(), me.createRemoveColumnAction()]
		});
	}

	getGridColumns(): AnyObject[] {
		const me = this;
		const cols = edi.columns.get(DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME);
		cols.push(me.getActionsColumn());
		return cols;
	}

	getAddMenuActions(): AnyObject[] {
		const me = this;
		return [
			{
				text: edi.i18n.getMessage('documentPackage.add.existing'),
				handler: me.addExistingDoc.bind(me)
			},
			{
				text: edi.i18n.getMessage('documentPackage.add.new'),
				handler: me.createAndAddDoc.bind(me),
				disabled: !me.isEdit,
				tooltip: !me.isEdit ? edi.i18n.getMessage('documentPackage.tooltip.add.blocked.edit') : undefined
			},
			{
				text: edi.i18n.getMessage('documentPackage.add.upload'),
				handler: me.uploadAndAddDoc.bind(me),
				disabled: !me.isEdit,
				tooltip: !me.isEdit ? edi.i18n.getMessage('documentPackage.tooltip.add.blocked.edit') : undefined
			}
		];
	}

	createAddMenuButton(): ExtComponent {
		const me = this;
		const receiverIsNotSelected = !me.receiverField.selectedOrg?.id;
		me.addButton = createButton({
			cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
			glyph: edi.constants.ICONS.PLUS,
			text: edi.i18n.getMessage('form.btn.add'),
			disabled: receiverIsNotSelected,
			tooltip: receiverIsNotSelected
				? edi.i18n.getMessage('documentPackage.tooltip.add.blocked.receiver')
				: undefined,
			menu: me.getAddMenuActions()
		}) as ExtComponent;
		return me.addButton;
	}

	addExistingDoc() {
		const me = this;
		new PackageAddExistingDocWindow({
			toOrgId: me.receiverField.selectedOrg.id,
			callback: async function (records) {
				if (me.isEdit && me.packageHeader?.id) {
					const packageId = me.packageHeader.id;
					const recordIds = records.map((rec) => rec.get('id'));
					const { success, response } = await documentPackageApi.linkDocuments(packageId, recordIds);
					if (success) {
						documentPackageMethods.eventsObserver.fireEvent('changeDocuments', { packageId });
					} else {
						edi.rest.getErrorHandler()(response);
					}
				} else {
					me.moduleGrid.getStore().add(records);
				}
			}
		}).showModal();
	}

	createAndAddDoc() {
		const me = this;
		if (me.packageHeader?.id) {
			new CreateDocForPackageWindow({
				packageId: me.packageHeader.id,
				receiverOrgId: me.receiverField.selectedOrg.id
			}).showModal();
		}
	}

	uploadAndAddDoc() {
		const me = this;
		if (me.packageHeader?.id) {
			new UploadDocToPackageWindow({
				packageId: me.packageHeader.id,
				receiverOrgId: me.receiverField.selectedOrg.id
			}).show();
		}
	}

	createGridBottomToolbar(): ExtComponent {
		const me = this;
		return createToolBar({
			dock: 'bottom',
			items: [me.createAddMenuButton()]
		}) as ExtComponent;
	}

	getGridConfig(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('documentPackage.grid.title'),
			disablePaging: true,
			columns: me.getGridColumns(),
			maxHeight: 480,
			dockedItems: [me.createGridBottomToolbar()],
			listeners: {
				celldblclick: function (
					_view: ExtComponent,
					_td: unknown,
					_cellIndex: number,
					record: ExtRecord<DocumentHeader>
				) {
					if (me.canOpenDetails(record)) {
						me.openDocDetails(record);
					}
				}
			}
		};
	}

	createDocsGrid(): ExtComponent {
		const me = this;
		me.moduleGrid = createGrid({
			proxyConfig: me.getProxyConfig(),
			storeConfig: me.getStoreConfig(),
			gridConfig: me.getGridConfig()
		}) as ExtComponent;
		me.moduleGrid._prevReceiverId = me.receiverField.selectedOrg?.id;
		return me.moduleGrid;
	}

	warnAboutEmptyGrid(next: () => {}) {
		const me = this;
		if (!me.isEdit && me.moduleGrid.getStore().getCount() === 0) {
			showConfirm({
				msgText: 'documentPackage.create.empty.warning',
				success: next
			});
		} else {
			next();
		}
	}

	createSaveButton(): ExtComponent {
		const me = this;
		me.saveBtn = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							me.moduleForm,
							['sender', 'receiver'],
							[me.senderField, me.receiverField]
						)
					) {
						return;
					}
					me.warnAboutEmptyGrid(() => me.savePackage().catch(me.moduleErrorHandler.bind(me)));
				}
			},
			me.isEdit
		) as ExtComponent;
		return me.saveBtn;
	}

	createFormButtonsContainer(): ExtComponent | undefined {
		const me = this;
		return !me.packageHeader || me.packageHeader.state === edi.constants.STATE.DRAFT
			? createButtonContainer({
					items: [me.createSaveButton()]
			  })
			: undefined;
	}

	replaceEmptyStringWithNull(obj: AnyObject) {
		const me = this;
		Object.entries(obj).forEach(([key, value]) => {
			if (value === '') {
				obj[key] = null;
			} else if (Ext.isObject(value)) {
				me.replaceEmptyStringWithNull(value);
			}
		});
	}

	collectValuesForSave(): CreatePackageValues {
		const me = this;
		const values = edi.utils.collectFormValues(me.moduleForm);
		me.replaceEmptyStringWithNull(values);
		const documents: number[] = me.moduleGrid
			.getStore()
			.getRange()
			.map((rec: ExtRecord<DocumentHeader>) => rec.get('id'));
		return {
			type: values.type,
			number: values.number,
			docTime: +values.docTime,
			remark: values.remark,
			documents: documents,
			fromOrg: +values.fromOrg.id,
			toOrg: +values.toOrg.id
		};
	}

	async savePackage() {
		const me = this;
		const values = me.collectValuesForSave();
		me.setModuleLoading(true);
		const { success, response } =
			me.isEdit && me.packageHeader?.id
				? await documentPackageApi.updatePackage(me.packageHeader.id, values)
				: await documentPackageApi.createPackage(values);
		me.setModuleLoading(false);
		if (success) {
			me.moduleData.isChanged = false;
			edi.modulesHandler.removeModule(me.moduleData);
			const packageHeader = me.isEdit ? me.packageHeader : response.data;
			documentPackageMethods.eventsObserver.fireEvent('update', { packageId: packageHeader.id });
			edi.document.actions.openDetailsModule(edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE, packageHeader);
		} else {
			edi.rest.getErrorHandler(null, null)(response);
		}
	}

	createModuleForm(): ExtComponent {
		const me = this;
		me.moduleForm = createFormForModule({
			region: 'center',
			buttons: me.createFormButtonsContainer(),
			items: [
				me.createTitleBlock(),
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [12, 12, [6, 6], 4]
					},
					items: [
						me.createPackageTypeBlock(),
						me.createNumberDateBlock(),
						me.createSenderBlock(),
						me.createReceiverBlock(),
						me.createRemarksBlock()
					]
				}),
				me.createDocsGrid()
			]
		});
		!me.isEdit && me.receiverField.presetFromRelation();
		return me.moduleForm;
	}

	createModuleView() {
		const me = this;
		if (me.moduleData.tab && !me.moduleData.tab.destroyed) {
			me.moduleData.tab.removeAll();
			me.modulePanel = createAddModulePanel({
				items: [me.createModuleForm()]
			}) as ExtComponent;
			me.moduleData.tab.add(me.modulePanel);
		}
	}

	/**
	 * Renders page layout
	 */
	async renderData(initCallBack?: Function) {
		const me = this;

		if (me.isEdit && me.packageHeader?.id) {
			me.setModuleLoading(true);
			const { packageData, packageItems, error } = await documentPackageApi.fetchFullPackageData(
				me.packageHeader.id
			);
			if (error) {
				edi.rest.getErrorHandler(null, () => me.moduleData.tab.close())(error);
				return;
			} else {
				me.packageHeader = packageData;
				me.packageDocs = packageItems;
				me.isEditNotInDraft = me.isEdit && me.packageHeader?.state !== edi.constants.STATE.DRAFT;
			}
			me.setModuleLoading(false);
		}

		me.createModuleView();

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
	}

	/**
	 * Routine that must be done before module destroy
	 */
	onDestroy() {
		const me = this;
		if (me.isEdit) {
			documentPackageMethods.eventsObserver.un('uploadNewDocument', me._updateGridEventHandler);
			documentPackageMethods.eventsObserver.un('changeDocuments', me._updateGridEventHandler);
			documentPackageMethods.eventsObserver.un('delete', me._deleteEventHandler);
			documentPackageMethods.eventsObserver.un('update', me._updateModuleHandler);
		}
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

edi.modules['create.documentPackage'] = DocumentPackageCreateModule;
edi.modulesCfg['create.documentPackage'] = {
	title: 'documentPackage.create',
	modName: 'create.documentPackage',
	folder: 'edi/modules/documentPackages',
	permissions: ['CREATE_PACKAGE_OBJECT'],
	permissionsEdit: ['CREATE_PACKAGE_OBJECT']
};
