import { OkofRecord, PowerLimitRecord, UPD502productIdentifyRecordData } from './definitions/records';

const POWER_LIMITS_MODEL = 'POWER_LIMITS';
const OKOF_MODEL = 'OKOF';
const UPD_5_02_LINE_MODEL = 'UPD_5_02_LINE';
const UPD_5_02_PRODUCT_INFO_TRACKING_MODEL = 'UPD_5_02_PRODUCT_INFO_TRACKING';
const UPD_5_02_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS_MODEL = 'UPD_5_02_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS';
const UPD_5_02_DECLARATION_INFORMATION_MODEL = 'UPD_5_02_DECLARATION_INFORMATION';
const UPD_5_02_PRODUCT_TEXT_INFO_MODEL = 'UPD_5_02_PRODUCT_TEXT_INFO';
const UPD_5_02_SUMMARY_MODEL = 'UPD_5_02_SUMMARY';

edi.models.setModels({
	[POWER_LIMITS_MODEL]: {
		fields: [
			{
				name: 'CODE_VALUE',
				type: 'string'
			},
			{
				name: 'NAME_VALUE',
				type: 'string'
			},
			{
				name: 'CODE',
				type: 'string'
			},
			{
				name: 'displayValue',
				type: 'string',
				convert: function (value: string, record: ExtRecord<PowerLimitRecord>) {
					const name = record.get('NAME_VALUE');
					const code = record.get('CODE_VALUE');
					return `${code} - UI Platform Maven Webapp`;
				}
			}
		]
	},
	[OKOF_MODEL]: {
		idProperty: 'code',
		fields: [
			{
				name: 'number',
				type: 'number'
			},
			{
				name: 'code',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'displayName',
				type: 'string',
				convert: function (value: string, record: ExtRecord<OkofRecord>) {
					return `${record.get('code')} - ${record.get('name')}`;
				}
			}
		]
	},
	[UPD_5_02_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'nomStr',
				type: 'number'
			},
			{
				name: 'naimTov',
				type: 'string'
			},
			{
				name: 'kolTov',
				type: 'number'
			},
			{
				name: 'tsenaTov',
				type: 'number'
			},
			{
				name: 'okeiTov',
				type: 'string'
			},
			{
				name: 'nalSt',
				type: 'string'
			},
			{
				name: 'aktsiz'
				//type: 'object'
			},
			{
				name: 'sumNal'
				//type: 'object'
			},
			{
				name: 'stTovBezNDS',
				type: 'number'
			},
			{
				name: 'stTovUchNal',
				type: 'number'
			},
			{
				name: 'dopSvedTov'
				//type: 'object'
			},
			{
				name: 'infPolFHZH2'
				//type: 'array'
			},
			{
				name: 'svDT'
				//type: 'array'
			}
		],
		idProperty: 'lineNumber'
	},
	[UPD_5_02_PRODUCT_INFO_TRACKING_MODEL]: {
		fields: [
			{
				name: 'nomTovProslezh',
				type: 'string'
			},
			{
				name: 'edIzmProslezh',
				type: 'string'
			},
			{
				name: 'naimEdIzmProslezh',
				type: 'string'
			},
			{
				name: 'kolVEdProslezh',
				type: 'number'
			},
			{
				name: 'stTovBezNDSProslezh',
				type: 'number'
			},
			{
				name: 'dopInfProslezh',
				type: 'string'
			}
		]
	},
	[UPD_5_02_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS_MODEL]: {
		fields: [
			{
				name: 'identTransUpak',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string',
				convert: function (value: string, record: ExtRecord<UPD502productIdentifyRecordData>) {
					if (value) return value;
					if (!!record.get('kiz')) return 'kiz';
					if (!!record.get('nomUpak')) return 'packNumber';
				}
			},
			{
				name: 'kiz'
				//type: 'array'
			},
			{
				name: 'nomUpak'
				//type: 'array'
			},
			{
				name: 'numbers',
				convert: function (value: string[] = [], record: ExtRecord<UPD502productIdentifyRecordData>) {
					const kiz = record.get('kiz') as UPD502productIdentifyRecordData['kiz'];
					const nomUpak = record.get('nomUpak') as UPD502productIdentifyRecordData['nomUpak'];
					return (!!value.length && value) || (!!kiz?.length && kiz) || (!!nomUpak?.length && nomUpak) || [];
				}
				//type: 'array'
			},
			{
				name: 'kolVedMark',
				type: 'number'
			},
			{
				name: 'GTIN',
				type: 'string'
			},
			{
				name: 'useSortAccounting',
				type: 'boolean'
			}
		]
	},
	[UPD_5_02_DECLARATION_INFORMATION_MODEL]: {
		fields: [
			{
				name: 'kodProish',
				type: 'string'
			},
			{
				name: 'nomerDT',
				type: 'string'
			}
		]
	},
	[UPD_5_02_PRODUCT_TEXT_INFO_MODEL]: {
		idProperty: 'identif',
		fields: [
			{
				name: 'identif',
				type: 'string'
			},
			{
				name: 'znachen',
				type: 'string'
			}
		]
	},
	[UPD_5_02_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'nomStrVsego',
				type: 'number'
			},
			{
				name: 'stTovBezNDSVsego',
				type: 'number'
			},
			{
				name: 'sumNalVsego',
				type: 'number'
			},
			{
				name: 'stTovUchNalVsego',
				type: 'float',
				defaultValue: 0
			},
			{
				name: 'kolNettoVs',
				type: 'number'
			}
		]
	}
});

export {
	POWER_LIMITS_MODEL,
	OKOF_MODEL,
	UPD_5_02_LINE_MODEL,
	UPD_5_02_PRODUCT_INFO_TRACKING_MODEL,
	UPD_5_02_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS_MODEL,
	UPD_5_02_DECLARATION_INFORMATION_MODEL,
	UPD_5_02_PRODUCT_TEXT_INFO_MODEL,
	UPD_5_02_SUMMARY_MODEL
};
